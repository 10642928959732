import React, { FC } from 'react';
import { AnswerData, Answer } from 'src/store/types';

interface TipsHelpProps {
  onChange: (text: string) => void;
  value: string;
  updateAnswer: (answerData: AnswerData) => void;
  answer: Answer;
}

export const TipsHelp: FC<TipsHelpProps> = ({
  onChange,
  value,
  updateAnswer,
  answer,
}) => {
  return (
    <p>
      (This interview has some tips, they will appear on the sidebar when
      completing the interview.)
    </p>
  );
};
