/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { call, takeEvery, put } from 'redux-saga/effects';
import {
  INTERVIEW_SAVE_REQUEST,
  INTERVIEW_UPDATE_REQUEST,
  INTERVIEW_LOAD_ANSWERS_REQUEST,
  INTERVIEW_FILE_DOWNLOAD,
  INTERVIEW_LOAD_ACTIVITY_LOG_REQUEST,
  ANSWER_LOAD_ACTIVITY_LOG_REQUEST,
  INTERVIEW_CREATE_ASSIGNEE_REQUEST,
  INTERVIEW_DELETE_ASSIGNEE_REQUEST,
  INTERVIEW_LOAD_ASSIGNEES_REQUEST,
  INTERVIEW_ASSIGN_OWNER_REQUEST,
  INTERVIEW_REMOVE_OWNER_REQUEST,
  InterviewState,
  InterviewSaveRequestAction,
  InterviewUpdateRequestAction,
  InterviewLoadAnswersRequestAction,
  AnswerFromResponse,
  InterviewFileDownloadRequestAction,
  InterviewLoadActivityLogRequestAction,
  AnswerLoadActivityLogRequestAction,
  ActivityLogResponse,
  InterviewCreateAssigneeRequestAction,
  InterviewDeleteAssigneeRequestAction,
  InterviewLoadAssigneesRequestAction,
  InterviewAssignOwnerRequestAction,
  InterviewRemoveOwnerRequestAction,
  AssigneeFromResponse,
  InterviewFromResponse,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';
import s3 from 'src/services/downloadFile';

export const saveInterview = function* async({
  interview: interviewData,
  companyId,
}: InterviewSaveRequestAction): Generator {
  try {
    const interview = yield call(api.saveInterview, interviewData);
    yield put(
      actions.interviewSaveSuccess(interview as InterviewState, companyId),
    );
  } catch (error) {
    yield put(
      actions.interviewSaveError('Failed to save interview', error as Error),
    );
  }
};

export const loadInterviewAnswers = function* async({
  interviewId,
}: InterviewLoadAnswersRequestAction): Generator {
  try {
    const answers = yield call(api.getInterviewAnswers, interviewId);
    yield put(
      actions.interviewLoadAnswersSuccess(answers as AnswerFromResponse[]),
    );
  } catch (error) {
    yield put(
      actions.interviewLoadAnswersError(
        "Failed to load interview's answers",
        error as Error,
      ),
    );
  }
};

export const loadInterviewActivityLog = function* async({
  fromDate,
  toDate,
  email,
  page,
  pageSize,
}: InterviewLoadActivityLogRequestAction): Generator {
  try {
    const reconciliation = yield call(
      api.getInterviewReconciliation,
      fromDate,
      toDate,
      email,
      page,
      pageSize,
    );
    yield put(
      actions.interviewLoadActivityLogSuccess(
        reconciliation as ActivityLogResponse,
      ),
    );
  } catch (error) {
    yield put(
      actions.interviewLoadActivityLogError(
        "Failed to load interview's reconciliation",
        error as Error,
      ),
    );
  }
};

export const loadAnswerActivityLog = function* async({
  interviewId,
  fromDate,
  toDate,
  email,
  page,
  pageSize,
}: AnswerLoadActivityLogRequestAction): Generator {
  try {
    const reconciliation = yield call(
      api.getAnswerReconciliation,
      interviewId,
      fromDate,
      toDate,
      email,
      page,
      pageSize,
    );
    yield put(
      actions.answerLoadActivityLogSuccess(
        reconciliation as ActivityLogResponse,
      ),
    );
  } catch (error) {
    yield put(
      actions.answerLoadActivityLogError(
        "Failed to load answer's activity log",
        error as Error,
      ),
    );
  }
};

export const updateInterview = function* async({
  interview: interviewData,
  companyId,
  answers,
  metadata,
}: InterviewUpdateRequestAction): Generator {
  try {
    const interview = yield call(
      api.updateInterview,
      interviewData,
      answers,
      metadata,
    );
    yield put(
      actions.interviewUpdateSuccess(
        (interview as any).interview as InterviewState,
        companyId,
      ),
    );
  } catch (error) {
    yield put(
      actions.interviewUpdateError(
        'Failed to update interview',
        error as Error,
      ),
    );
  }
};

export const fileDownload = function* async({
  file,
}: InterviewFileDownloadRequestAction): Generator {
  try {
    if (file.xhr) {
      const { key, bucket } = file.xhr;
      void s3.handleFileDownload(key, bucket);
      // // eslint-disable-next-line no-underscore-dangle
      // const data: any = yield call(s3._getFile, key);
      // if (data && data.ContentLength) {
      //   const link = document.createElement('a');
      //   link.href = window.URL.createObjectURL(new Blob([data.Body], { type: data.ContentType }));
      //   link.download = file.name;
      //   link.click();
      // }
    }
  } catch (error) {
    yield put(
      actions.showErrorMessage('Failed to download file', error as string),
    );
  }
};

export const createAssignee = function* async({
  interviewId,
  assigneeUserId,
  questionId,
}: InterviewCreateAssigneeRequestAction): Generator {
  try {
    const assignee = yield call(
      api.createAssignee,
      interviewId,
      assigneeUserId,
      questionId,
    );
    yield put(
      actions.interviewCreateAssigneeSuccessAction(
        assignee as AssigneeFromResponse,
      ),
    );
  } catch (error) {}
};

export const deleteAssignee = function* async({
  interviewId,
  assigneeId,
}: InterviewDeleteAssigneeRequestAction): Generator {
  try {
    yield call(api.deleteAssignee, interviewId, assigneeId);
    yield put(
      actions.interviewDeleteAssigneeSuccessAction(interviewId, assigneeId),
    );
  } catch (error) {}
};

export const getAssignees = function* async({
  interviewId,
}: InterviewLoadAssigneesRequestAction): Generator {
  try {
    const assignees = yield call(api.getAssignees, interviewId);
    yield put(
      actions.interviewLoadAssigneesSuccessAction(
        assignees as AssigneeFromResponse[],
      ),
    );
  } catch (error) {}
};

export const assignOwner = function* async({
  ownerId,
  interviewId,
}: InterviewAssignOwnerRequestAction): Generator {
  try {
    const interview = yield call(api.assignOwner, interviewId, ownerId);
    yield put(
      actions.interviewAssignOwnerSuccessAction(
        interview as InterviewFromResponse,
      ),
    );
  } catch (error) {}
};

export const removeOwner = function* async({
  interviewId,
  ownerUserId,
}: InterviewRemoveOwnerRequestAction): Generator {
  try {
    yield call(api.removeOwnerFromInterview, interviewId, ownerUserId);
    yield put(actions.interviewRemoveOwnerSuccessAction(interviewId));
  } catch (error) {}
};

export default function* (): Generator {
  return [
    yield takeEvery(INTERVIEW_SAVE_REQUEST, saveInterview),
    yield takeEvery(INTERVIEW_UPDATE_REQUEST, updateInterview),
    yield takeEvery(INTERVIEW_LOAD_ANSWERS_REQUEST, loadInterviewAnswers),
    yield takeEvery(
      INTERVIEW_LOAD_ACTIVITY_LOG_REQUEST,
      loadInterviewActivityLog,
    ),
    yield takeEvery(ANSWER_LOAD_ACTIVITY_LOG_REQUEST, loadAnswerActivityLog),
    yield takeEvery(INTERVIEW_FILE_DOWNLOAD, fileDownload),
    yield takeEvery(INTERVIEW_CREATE_ASSIGNEE_REQUEST, createAssignee),
    yield takeEvery(INTERVIEW_DELETE_ASSIGNEE_REQUEST, deleteAssignee),
    yield takeEvery(INTERVIEW_LOAD_ASSIGNEES_REQUEST, getAssignees),
    yield takeEvery(INTERVIEW_ASSIGN_OWNER_REQUEST, assignOwner),
    yield takeEvery(INTERVIEW_REMOVE_OWNER_REQUEST, removeOwner),
  ];
}
