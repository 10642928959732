import {
  Loader,
  VariableState,
  AssessmentTypeState,
  VariableListingIndexedItem,
  DocumentDraftSaveSuccessAction,
  DocumentDraftUpdateRequestAction,
  ShowErrorAction,
} from 'src/store/types';
import { TemplateLoader } from './loader';

export const TEMPLATE_LOAD_REQUEST = 'TEMPLATE_LOAD_REQUEST';
export const TEMPLATE_LOAD_SUCCESS = 'TEMPLATE_LOAD_SUCCESS';
export const TEMPLATE_LOAD_ERROR = 'TEMPLATE_LOAD_ERROR';

export const TEMPLATE_UPDATE_REQUEST = 'TEMPLATE_UPDATE_REQUEST';
export const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS';
export const TEMPLATE_UPDATE_ERROR = 'TEMPLATE_UPDATE_ERROR';

export const TEMPLATE_SAVE_REQUEST = 'TEMPLATE_SAVE_REQUEST';
export const TEMPLATE_SAVE_SUCCESS = 'TEMPLATE_SAVE_SUCCESS';
export const TEMPLATE_SAVE_ERROR = 'TEMPLATE_SAVE_ERROR';

export const TEMPLATE_DELETE_REQUEST = 'TEMPLATE_DELETE_REQUEST';
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS';
export const TEMPLATE_DELETE_ERROR = 'TEMPLATE_DELETE_ERROR';

export const TEMPLATE_CLONE_REQUEST = 'TEMPLATE_CLONE_REQUEST';
export const TEMPLATE_CLONE_SUCCESS = 'TEMPLATE_CLONE_SUCCESS';
export const TEMPLATE_CLONE_ERROR = 'TEMPLATE_CLONE_ERROR';

export enum TemplateProcessingState {
  PROCESSING = 'Processing',
  READY = 'Ready',
}

export interface TemplateState {
  id: number;
  title: string;
  body: string;
  assessmentType?: AssessmentTypeState;
  variables?: VariableState[];
  folder: string;
  parentTemplate?: Partial<TemplateState>;
  needsReview: boolean;
  mergeBody: string;
  processingStatus: TemplateProcessingState;
}

export interface TemplateInput {
  title: string;
  assessmentTypeId: number;
  folder?: string;
  body?: string;
}

export interface SelectedTemplateState {
  template: TemplateState;
  loader: TemplateLoader;
  variables: VariableListingIndexedItem;
  variablesLoader: Loader;
}

export interface TemplateFilters {
  type?: string;
  title?: string;
  folder?: string;
}

export interface TemplateLoadRequestAction {
  type: typeof TEMPLATE_LOAD_REQUEST;
  templateId: number;
}

export interface TemplateLoadSuccessAction {
  type: typeof TEMPLATE_LOAD_SUCCESS;
  template: TemplateState;
}
export type TemplateLoadErrorAction = ShowErrorAction<
  typeof TEMPLATE_LOAD_ERROR
>;

export interface TemplateUpdateRequestAction {
  type: typeof TEMPLATE_UPDATE_REQUEST;
  templateId: number;
  template: Partial<TemplateState>;
}

export interface TemplateUpdateSuccessAction {
  type: typeof TEMPLATE_UPDATE_SUCCESS;
  updatedTemplate: Partial<TemplateState>;
}

export type TemplateUpdateErrorAction = ShowErrorAction<
  typeof TEMPLATE_UPDATE_ERROR
>;

export interface TemplateSaveRequestAction {
  type: typeof TEMPLATE_SAVE_REQUEST;
  template: TemplateInput;
}

export interface TemplateSaveSuccessAction {
  type: typeof TEMPLATE_SAVE_SUCCESS;
  template: TemplateState;
}

export type TemplateSaveErrorAction = ShowErrorAction<
  typeof TEMPLATE_SAVE_ERROR
>;

export interface TemplateDeleteRequestAction {
  type: typeof TEMPLATE_DELETE_REQUEST;
  templateId: number;
}

export interface TemplateDeleteSuccessAction {
  type: typeof TEMPLATE_DELETE_SUCCESS;
  templateId: number;
}

export type TemplateDeleteErrorAction = ShowErrorAction<
  typeof TEMPLATE_DELETE_ERROR
>;

export interface TemplateCloneRequestAction {
  type: typeof TEMPLATE_CLONE_REQUEST;
  template: TemplateInput;
  templateId: number;
  onlyDuplicate: boolean;
}

export interface TemplateCloneSuccessAction {
  type: typeof TEMPLATE_CLONE_SUCCESS;
  template: TemplateState;
}

export type TemplateCloneErrorAction = ShowErrorAction<
  typeof TEMPLATE_CLONE_ERROR
>;

export type TemplateActionsTypes =
  | TemplateLoadRequestAction
  | TemplateLoadSuccessAction
  | TemplateLoadErrorAction
  | TemplateUpdateRequestAction
  | TemplateUpdateSuccessAction
  | TemplateUpdateErrorAction
  | TemplateSaveRequestAction
  | TemplateSaveSuccessAction
  | TemplateSaveErrorAction
  | TemplateDeleteRequestAction
  | TemplateDeleteSuccessAction
  | TemplateDeleteErrorAction
  | TemplateCloneRequestAction
  | TemplateCloneSuccessAction
  | TemplateCloneErrorAction
  | DocumentDraftSaveSuccessAction
  | DocumentDraftUpdateRequestAction;
