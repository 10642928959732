import { Modal as ModalAntD, Tooltip } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ToolbarItemProps {
  children?: React.ReactNode;
  childrenVisible?: boolean;
  setChildrenVisible?: (val: boolean) => void;
  icon: React.SVGProps<SVGSVGElement>;
  execute?: () => void;
  tooltip?: string;
}

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `1px solid ${theme.colors.grayscale.middleGray}`};
  border-radius: 2px;
  height: 20px;
  max-width: 20px;
  padding: 2px;
  cursor: pointer;
`;

const Modal = styled(ModalAntD)`
  border: ${({ theme }) => `1px solid ${theme.colors.grayscale.middleGray}`};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.grayscale.lightGray};
`;

/**
 * Base ToolbarItem for Template Editor.
 * There are two ways for triggering side effect from it:
 *  1. Providing an execute function that will be invoked on click.
 *  2. Providing a children with custom behavior.
 * @param children - optional children to display on click
 * @param execute - onClick action, this is required if no children with custom execute logic is supplied.
 */
export const ToolbarItem: FC<ToolbarItemProps> = ({
  children,
  childrenVisible,
  setChildrenVisible,
  icon,
  execute,
  tooltip,
}) => {
  const toggleModal = () =>
    setChildrenVisible && setChildrenVisible(!childrenVisible);

  const handleOnClick = () => {
    if (children && !childrenVisible) {
      toggleModal();
    } else {
      execute && execute();
    }
  };

  return (
    <MainContainer onClick={handleOnClick}>
      <Tooltip title={tooltip}>{icon as any /**TODO remove any */}</Tooltip>
      {children && childrenVisible && (
        <Modal
          visible={childrenVisible}
          footer={null}
          title={tooltip}
          onCancel={toggleModal}
        >
          {children}
        </Modal>
      )}
    </MainContainer>
  );
};
