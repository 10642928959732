import {
  CompanyState,
  COMPANIES_LOAD_REQUEST,
  COMPANIES_LOAD_SUCCESS,
  COMPANIES_LOAD_ERROR,
  COMPANY_ARCHIVE_SUCCESS,
  ADD_COMPANY_TO_LIST,
  CompanyListingActionTypes,
  CompanyListingState,
  Loader,
  CompanyListingIndexedItem,
  CompanyFromResponse,
  CompaniesLoadSuccessAction,
  ArchiveCompanySuccessAction,
  AddCompanyToListAction,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const companyListingInitialState: CompanyListingState = {
  companies: {},
  loader: loaderInitialState,
};

const formatCompany = ({
  assessments,
  createdAt,
  id,
  legacyId,
  name,
  updatedAt,
  typeOfTraining,
  address,
  employees,
  licenses,
  state,
  companyType,
  trainingType,
}: CompanyFromResponse) => {
  const formattedCompany: CompanyState = {
    createdAt,
    id,
    legacyId,
    name,
    updatedAt,
    typeOfTraining,
    address,
    employees,
    licenses,
    state,
    companyType,
    trainingType,
  };
  if (assessments) {
    const assessmentIds = assessments.map((a) => a.id);
    formattedCompany['assessmentIds'] = assessmentIds;
    formattedCompany['lastAssessmentId'] = assessmentIds[0];
  }
  return formattedCompany;
};

export const companyListingReducer = (
  state: CompanyListingState = companyListingInitialState,
  action: CompanyListingActionTypes,
): CompanyListingState => {
  switch (action.type) {
    case COMPANIES_LOAD_REQUEST: {
      return { ...state, loader: { ...loaderInitialState, loading: true } };
    }
    case COMPANIES_LOAD_SUCCESS: {
      const list = (
        action as any as CompaniesLoadSuccessAction
      ).companies.reduce(
        (list: CompanyListingIndexedItem, company: CompanyFromResponse) => {
          return {
            ...list,
            [company.id]: formatCompany(company),
          };
        },
        {},
      );
      return {
        companies: list,
        loader: { ...loaderInitialState, success: true, completed: true },
      };
    }
    case COMPANIES_LOAD_ERROR: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: false,
          completed: true,
          error: action.message.toString(),
        },
      };
    }
    case COMPANY_ARCHIVE_SUCCESS: {
      const newState = { ...state };
      delete newState.companies[
        (action as any as ArchiveCompanySuccessAction).archivedCompany.id
      ];
      return newState;
    }
    case ADD_COMPANY_TO_LIST: {
      const { company } = action as any as AddCompanyToListAction;
      return {
        ...state,
        companies: {
          ...state.companies,
          [company.id]: formatCompany(company),
        },
      };
    }
    default:
      return state;
  }
};
