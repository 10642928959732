import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { centeredFlex, device, semiboldOpenSans } from 'src/theme';

export const TrainingReleaseDateContainer = styled.div`
  ${centeredFlex}
  padding: ${remConvert(24)} ${remConvert(32)};
  flex-direction: column;
  gap: ${remConvert(32)};
  background: ${({ theme }) => theme.colors.white.main};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;

  @media ${device.sm} {
    flex-direction: row;
    height: ${remConvert(178)};
    width: ${remConvert(714)};
  }

  h2 {
    ${semiboldOpenSans}
    ${centeredFlex}
    color: ${({ theme }) => theme.colors.black.shark};
    font-size: 18px;

    @media ${device.sm} {
      font-size: 23px;
    }
  }

  .trainingReleaseDateSubtitle {
    ${semiboldOpenSans}
    ${centeredFlex}
    line-height: ${remConvert(25)};
    color: ${({ theme }) => theme.colors.black.shark};
    font-size: 12px;

    @media ${device.sm} {
      font-size: 18px;
    }
  }

  > div:first-of-type {
    display: none;

    @media ${device.sm} {
      display: block;
    }
  }
`;
