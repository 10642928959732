import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { TextInput } from 'src/components/TextInput';
import { RootState, Loader } from 'src/store/types';
import { getUserLoader } from 'src/store/selectors/user';
import { resetPasswordRequest } from 'src/store/actions/user';
import { info } from 'src/shared/utils/toast';
import * as S from './Styles';

export const PasswordRecoveryForm: FC = () => {
  const [username, setUsername] = useState<string>('');
  const [canShowMessage, setCanShowMessage] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userLoader = useSelector((state: RootState): Loader | undefined =>
    getUserLoader(state),
  );

  useEffect(() => {
    if (userLoader?.completed && canShowMessage) {
      info(
        'Your reset password link has been sent.',
        'Please check your email',
      );
      setCanShowMessage(false);
    }
    if (userLoader?.loading) {
      setCanShowMessage(true);
    }
  }, [userLoader]);

  const clearInputs = (): void => {
    setUsername('');
  };

  const handleSubmit = (): void => {
    dispatch(resetPasswordRequest(username));
    clearInputs();
  };

  return (
    <>
      <S.FormFieldsContainer>
        <TextInput
          autoFocus
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setUsername(e.target.value);
          }}
          placeholder="Email"
          value={username}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSubmit();
            }
          }}
          float
          autoComplete="off"
        />
      </S.FormFieldsContainer>
      <S.FormSubmitContainer>
        <Button
          className="btn"
          title="Send password reset link"
          onClick={handleSubmit}
          loading={userLoader?.loading}
        />
      </S.FormSubmitContainer>
    </>
  );
};
