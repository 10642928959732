import {
  VariableState,
  VariableInput,
  VARIABLE_SAVE_REQUEST,
  VARIABLE_SAVE_SUCCESS,
  VARIABLE_SAVE_ERROR,
  VariableSaveRequestAction,
  VariableSaveSuccessAction,
  VariableSaveErrorAction,
  VARIABLE_UPDATE_REQUEST,
  VARIABLE_UPDATE_SUCCESS,
  VARIABLE_UPDATE_ERROR,
  VARIABLE_DELETE_REQUEST,
  VARIABLE_DELETE_SUCCESS,
  VARIABLE_DELETE_ERROR,
  VariableDeleteSuccessAction,
  VariableDeleteErrorAction,
  VariableDeleteRequestAction,
  VariableUpdateErrorAction,
  VariableUpdateSuccessAction,
  VariableUpdateRequestAction,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const variableSaveRequest = (
  variable: VariableInput,
): VariableSaveRequestAction => ({
  type: VARIABLE_SAVE_REQUEST,
  variable,
});

export const variableSaveSuccess = (
  variable: VariableState,
): VariableSaveSuccessAction => ({
  type: VARIABLE_SAVE_SUCCESS,
  variable,
});

export const variableSaveError = (
  errorMessage: string,
  originalError: Error,
): VariableSaveErrorAction =>
  showErrorMessage(VARIABLE_SAVE_ERROR, errorMessage, originalError);

export const variableUpdateRequest = (
  variableId: number,
  variable: VariableInput,
): VariableUpdateRequestAction => ({
  type: VARIABLE_UPDATE_REQUEST,
  variable,
  variableId,
});

export const variableUpdateSuccess = (
  variable: VariableState,
): VariableUpdateSuccessAction => ({
  type: VARIABLE_UPDATE_SUCCESS,
  variable,
});

export const variableUpdateError = (
  errorMessage: string,
  originalError: Error,
): VariableUpdateErrorAction =>
  showErrorMessage(VARIABLE_UPDATE_ERROR, errorMessage, originalError);

export const variableDeleteRequest = (
  variableId: number,
): VariableDeleteRequestAction => ({
  type: VARIABLE_DELETE_REQUEST,
  variableId,
});

export const variableDeleteSuccess = (
  variableId: number,
): VariableDeleteSuccessAction => ({
  type: VARIABLE_DELETE_SUCCESS,
  variableId,
});

export const variableDeleteError = (
  errorMessage: string,
  originalError: Error,
): VariableDeleteErrorAction =>
  showErrorMessage(VARIABLE_DELETE_ERROR, errorMessage, originalError);
