import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { Form, Popconfirm, Spin } from 'antd';

import {
  RootState,
  FormState,
  InterviewState,
  FileData,
} from 'src/store/types';
import { Button } from 'src/components/Button';
import { TopNavbar } from 'src/components/TopNavbar';
import {
  getCompanyFromState,
  getFormById,
  getSelectedForm,
  getInterviewByAssessmentAndFormId,
  getSelectedInterview,
  getAnswersValuesByQuestions,
  getSelectedInterviewAnswersFromState,
  getUser,
  getCompanyLoaderFromState,
  getCompanyAssessmentRiskAnalysis,
} from 'src/store/selectors';
import {
  setSelectedForm,
  formLoadQuestionsRequest,
} from 'src/store/actions/form';
import {
  setSelectedInterview,
  interviewUpdateRequest,
  interviewLoadAnswersRequest,
  clearSelectedInterview,
  interviewLoadAssigneesRequestAction,
} from 'src/store/actions/interview';
import { assessmentLoadSuggestionsRequest } from 'src/store/actions/assessment';
import { questionTypeLoadRequest } from 'src/store/actions/questionType';
import { InterviewWorkbench } from 'src/features/risk-assessment/components/InterviewWorkbench';
import { InterviewProgressCard } from 'src/features/risk-assessment/components/InterviewProgressCard';
import { InterviewTipsCard } from 'src/features/risk-assessment/components/InterviewTipsCard/InterviewTipsCard';
import { VideoCard } from 'src/features/risk-assessment/components/VideoCard';
import { isEmptyObject } from 'src/shared/utils';
import { Progress } from 'src/components/Progress';
import * as S from './Styles';

interface RouteParams {
  formId: string;
  assessmentId: string;
}

export const InterviewPage: FC<unknown> = () => {
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loadingInterview, setLoadingInterview] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [interviewForm] = Form.useForm();
  const { formId, assessmentId } = useParams<RouteParams>();
  const form = useSelector((state: RootState): FormState | undefined =>
    getFormById(state, formId),
  );
  const interview = useSelector(
    (state: RootState): InterviewState | undefined =>
      getInterviewByAssessmentAndFormId(state, formId, assessmentId),
  );
  const selectedForm = useSelector(getSelectedForm);
  const selectedInterview = useSelector(getSelectedInterview);
  const answerList = useSelector(getSelectedInterviewAnswersFromState);
  const answersValues = useSelector(getAnswersValuesByQuestions);
  const company = useSelector(getCompanyFromState);
  const companyLoader = useSelector(getCompanyLoaderFromState);
  const user = useSelector(getUser);
  const assessmentRiskAnalysis = useSelector((state: RootState) =>
    getCompanyAssessmentRiskAnalysis(state),
  );

  useEffect(() => {
    if (interview?.id) {
      dispatch(interviewLoadAssigneesRequestAction(interview?.id));
    }
  }, [interview?.id]);

  useEffect(() => {
    if (!isEmptyObject(answersValues)) {
      interviewForm.setFieldsValue(answersValues);
    }
  }, [answersValues]);

  useEffect(() => {
    if (selectedInterview.metadata.saveCompleted === true) {
      dispatch(clearSelectedInterview());
      history.push('/risk-assessment');
    }
  }, [selectedInterview.metadata.saveCompleted]);

  useEffect(() => {
    const { completed, success } = selectedInterview.loader;

    if (completed && success) {
      setLoadingInterview(false);
    }
  }, [selectedInterview.loader]);

  useEffect(() => {
    const { completed, success } = selectedForm.loader;

    if (completed && success) {
      setLoadingForm(false);
    }
  }, [selectedForm.loader]);

  useEffect(() => {
    if (form && form.id !== selectedForm.form.id) {
      setLoadingForm(true);
      dispatch(setSelectedForm(form));
      dispatch(formLoadQuestionsRequest(form.id, +assessmentId));
      dispatch(questionTypeLoadRequest());
    }
  }, [form]);

  useEffect(() => {
    if (interview?.id) {
      setLoadingInterview(true);
      dispatch(interviewLoadAnswersRequest(interview.id));
      assessmentId &&
        dispatch(assessmentLoadSuggestionsRequest(+assessmentId, interview.id));

      if (
        !selectedInterview ||
        interview.id !== selectedInterview.interview.id
      ) {
        dispatch(setSelectedInterview(interview));
      }
    }
  }, [interview]);

  useEffect(() => {
    window.onbeforeunload = (e: Event) => {
      if (!selectedInterview.metadata.isPristine) {
        e.preventDefault();
        return true;
      }
    };
  }, [selectedInterview.metadata.isPristine]);

  useLayoutEffect(() => {
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'no');
    });
  });

  if (!company || company.id === 0) {
    history.push('/risk-assessment');
    return null;
  }

  const handleInterviewSave = () => {
    selectedInterview &&
      company &&
      dispatch(
        interviewUpdateRequest(
          selectedInterview.interview,
          false,
          company.id,
          answerList,
          selectedInterview.metadata,
        ),
      );
  };

  const handleInterviewFinish = () => {
    interviewForm.submit();
  };

  const filesAreBeingUploaded = () => {
    return Object.values(selectedInterview.metadata.files).some(
      (f: FileData) => f.status === 'uploading',
    );
  };

  const saveAndContinueLaterButton = (functionalButton = false) => {
    return (
      <Button
        onClick={functionalButton ? handleInterviewSave : () => undefined}
        title="Save for later"
        key={1}
        variant="secondary"
        disabled={selectedInterview && selectedInterview.metadata.isPristine}
        loading={companyLoader?.loading}
      />
    );
  };

  const saveButton = (
    <Button title="Mark as complete" loading={companyLoader.loading} />
  );

  const getExtraOptions = () => {
    return filesAreBeingUploaded()
      ? [
          <Popconfirm
            title="Files that have not finished uploading will be lost, are you sure?"
            onConfirm={handleInterviewSave}
            okText="Yes"
            cancelText="No"
            key={1}
          >
            {saveAndContinueLaterButton()}
          </Popconfirm>,
          <Popconfirm
            title="Files that have not finished uploading will be lost, are you sure you want to complete this interview?"
            onConfirm={handleInterviewFinish}
            okText="Yes"
            cancelText="No"
            key={2}
          >
            {saveButton}
          </Popconfirm>,
        ]
      : [
          saveAndContinueLaterButton(true),
          <Popconfirm
            title="Are you sure you want to complete this interview?"
            onConfirm={handleInterviewFinish}
            okText="Yes"
            cancelText="No"
            key={2}
          >
            {saveButton}
          </Popconfirm>,
        ];
  };

  const areFormsAndInterviewLoading = (): boolean =>
    loadingForm || loadingInterview;

  const percent =
    selectedInterview.metadata.questionsCount === 0
      ? 0
      : selectedInterview.answers.length /
            selectedInterview.metadata.questionsCount <
          1
        ? Math.round(
            (selectedInterview.answers.length /
              selectedInterview.metadata.questionsCount) *
              100,
          )
        : 100;

  return (
    <>
      <Prompt
        when={
          filesAreBeingUploaded() ||
          (!selectedInterview.metadata.isPristine &&
            !selectedInterview.metadata.saveCompleted)
        }
        message={
          filesAreBeingUploaded()
            ? 'Files that have not finished uploading will be lost, are you sure?'
            : 'Unsaved changes will be lost, are you sure?'
        }
      />
      <TopNavbar
        title={selectedForm.form.label}
        prevRoute={{ name: 'Back', url: `/risk-assessment` }}
        extraOptions={getExtraOptions()}
        sticky
      />
      {(areFormsAndInterviewLoading() || companyLoader.loading) && (
        <Spin size="large" tip="Loading form and answers..." />
      )}
      {!areFormsAndInterviewLoading() && (
        <>
          <div>{selectedForm.form.label} Progress</div>
          <Progress size="default" percent={percent} />
          <S.PageContentContainer>
            <S.BigColumn>
              <InterviewWorkbench
                key={interview?.id}
                form={interviewForm}
                selectedForm={selectedForm}
                selectedInterview={selectedInterview}
                company={company}
                assessmentRiskAnalysis={assessmentRiskAnalysis}
              />
            </S.BigColumn>
            <S.SmallColumn>
              <div>
                {form && interview && (
                  <InterviewProgressCard
                    className="progress-card"
                    selectedForm={selectedForm}
                    selectedInterview={selectedInterview}
                    assessmentRiskAnalysis={assessmentRiskAnalysis}
                    user={user}
                  />
                )}
                <VideoCard selectedForm={selectedForm} />
                <InterviewTipsCard selectedForm={selectedForm} />
              </div>
            </S.SmallColumn>
          </S.PageContentContainer>
        </>
      )}
    </>
  );
};
