import { Loader } from './loader';
import { QuestionType } from './questionType';
import { Question } from './question';
import { AssessmentTypeFromResponse } from './assessmentType';
import { CategoryFromResponse } from './category';
import { SortableItem } from './utils';
import { ShowErrorAction } from './error';

export const FORM_SAVE_REQUEST = 'FORM_SAVE_REQUEST';
export const FORM_SAVE_SUCCESS = 'FORM_SAVE_SUCCESS';
export const FORM_SAVE_ERROR = 'FORM_SAVE_ERROR';

export const FORM_UPDATE_REQUEST = 'FORM_UPDATE_REQUEST';
export const FORM_UPDATE_SUCCESS = 'FORM_UPDATE_SUCCESS';
export const FORM_UPDATE_ERROR = 'FORM_UPDATE_ERROR';

export const FORM_SET_SELECTED = 'FORM_SET_SELECTED';
export const FORM_ADD_NEW_QUESTION = 'FORM_ADD_NEW_QUESTION';

export const FORM_LOAD_QUESTIONS_REQUEST = 'FORM_LOAD_QUESTIONS_REQUEST';
export const FORM_LOAD_QUESTIONS_SUCCESS = 'FORM_LOAD_QUESTIONS_SUCCESS';
export const FORM_LOAD_QUESTIONS_ERROR = 'FORM_LOAD_QUESTIONS_ERROR';

export const FORM_SET_QUESTION_SELECTED = 'FORM_SET_QUESTION_SELECTED';
export const FORM_SET_QUESTION_SELECTED_RESET =
  'FORM_SET_QUESTION_SELECTED_RESET';
export const FORM_SAVE_EDITED_QUESTION = 'FORM_SAVE_EDITED_QUESTION';

export const FORM_MARK_QUESTION_AS_DELETED = 'FORM_MARK_QUESTION_AS_DELETED';
export const FORM_MARK_AS_TOUCHED = 'FORM_MARK_AS_TOUCHED';

export const FORM_COPY_QUESTION = 'FORM_COPY_QUESTION';

export const FORM_CLONE_REQUEST = 'FORM_CLONE_REQUEST';
export const FORM_CLONE_SUCCESS = 'FORM_CLONE_SUCCESS';
export const FORM_CLONE_ERROR = 'FORM_CLONE_ERROR';

export const FORM_DELETE_REQUEST = 'FORM_DELETE_REQUEST';
export const FORM_DELETE_SUCCESS = 'FORM_DELETE_SUCCESS';
export const FORM_DELETE_ERROR = 'FORM_DELETE_ERROR';

export const FORM_SET_STATUS_REQUEST = 'FORM_SET_STATUS_REQUEST';
export const FORM_SET_STATUS_SUCCESS = 'FORM_SET_STATUS_SUCCESS';
export const FORM_SET_STATUS_ERROR = 'FORM_SET_STATUS_ERROR';

export const FORM_UPDATE_QUESTIONS = 'FORM_UPDATE_QUESTIONS';

export interface FormState extends SortableItem {
  id: number;
  label: string;
  categoryFolder?: CategoryFromResponse;
  creator: number;
  description: string;
  updateReason?: string;
  assessment: number;
  required: boolean;
  isActive: boolean;
  isDraft: boolean;
  questions?: Question[];
  selectedQuestion?: Question;
  assessmentType?: AssessmentTypeFromResponse;
}

export type FormInput = FormState;

export interface FormMetadataState {
  deletedQuestions: number[];
  isPristine: boolean;
  saveCompleted: boolean;
}

export interface SelectedFormState {
  form: FormState;
  loader: Loader;
  questions: Question[];
  metadata: FormMetadataState;
}

export interface FormSaveRequestAction {
  type: typeof FORM_SAVE_REQUEST;
  form: Partial<FormState>;
}

export interface FormSaveSuccessAction {
  type: typeof FORM_SAVE_SUCCESS;
  form: FormState;
}

export type FormSaveErrorAction = ShowErrorAction<typeof FORM_SAVE_ERROR>;

export interface FormUpdateRequestAction {
  type: typeof FORM_UPDATE_REQUEST;
  form: FormState;
  questions?: Question[];
  metadata?: FormMetadataState;
}

export interface FormUpdateSuccessAction {
  type: typeof FORM_UPDATE_SUCCESS;
  form: FormState;
  questions?: Question[];
}

export type FormUpdateErrorAction = ShowErrorAction<typeof FORM_UPDATE_ERROR>;

export interface FormSetSelectedAction {
  type: typeof FORM_SET_SELECTED;
  form: FormState;
}

export interface FormSetQuestionSelectedAction {
  type: typeof FORM_SET_QUESTION_SELECTED;
  question: Question;
}

export interface FormSetQuestionSelectedResetAction {
  type: typeof FORM_SET_QUESTION_SELECTED_RESET;
}

export interface FormAddNewQuestionAction {
  type: typeof FORM_ADD_NEW_QUESTION;
  questionType: QuestionType;
  formId: number;
}

export interface FormSaveEditedQuestionAction {
  type: typeof FORM_SAVE_EDITED_QUESTION;
  partialQuestion: Partial<Question>;
  originalQuestion: Question;
}

export interface FormLoadQuestionsRequestAction {
  type: typeof FORM_LOAD_QUESTIONS_REQUEST;
  formId: number;
  assessmentId?: number;
}

export interface FormLoadQuestionsSuccessAction {
  type: typeof FORM_LOAD_QUESTIONS_SUCCESS;
  questions: Question[];
}

export type FormLoadQuestionsErrorAction = ShowErrorAction<
  typeof FORM_LOAD_QUESTIONS_ERROR
>;

export interface FormMarkQuestionAsDeletedAction {
  type: typeof FORM_MARK_QUESTION_AS_DELETED;
  questionId?: number;
  questionInternalId?: string;
}

export interface FormMarkAsTouchedAction {
  type: typeof FORM_MARK_AS_TOUCHED;
}

export interface FormCopyQuestionAction {
  type: typeof FORM_COPY_QUESTION;
  formId: number;
  questionId?: number;
  questionInternalId?: string;
}

export interface FormCloneRequestAction {
  type: typeof FORM_CLONE_REQUEST;
  formId: number;
}

export interface FormCloneSuccessAction {
  type: typeof FORM_CLONE_SUCCESS;
}

export type FormCloneErrorAction = ShowErrorAction<typeof FORM_CLONE_ERROR>;

export interface FormDeleteRequestAction {
  type: typeof FORM_DELETE_REQUEST;
  formId: number;
}

export interface FormDeleteSuccessAction {
  type: typeof FORM_DELETE_SUCCESS;
  formId: number;
}

export type FormDeleteErrorAction = ShowErrorAction<typeof FORM_DELETE_ERROR>;

export interface FormSetStatusRequestAction {
  type: typeof FORM_SET_STATUS_REQUEST;
  status: boolean;
  formId: number;
}

export interface FormSetStatusSuccessAction {
  type: typeof FORM_SET_STATUS_SUCCESS;
  form: FormState;
}

export type FormSetStatusErrorAction = ShowErrorAction<
  typeof FORM_SET_STATUS_ERROR
>;

export enum FormStatusFilter {
  all = 'All',
  active = 'Active',
  inactive = 'Inactive',
  draft = 'Draft',
}

export interface FormFilters {
  offset?: number;
  limit?: number;
  status?: string;
  name?: string;
  assessmentTypeLabel?: string;
  category?: string;
}

export interface FormUpdateQuestionsSuccessAction {
  type: typeof FORM_UPDATE_QUESTIONS;
  questions: Question[];
}

export type FormActionsTypes =
  | FormSaveRequestAction
  | FormSaveSuccessAction
  | FormSaveErrorAction
  | FormSetSelectedAction
  | FormAddNewQuestionAction
  | FormSetQuestionSelectedAction
  | FormSetQuestionSelectedResetAction
  | FormSaveEditedQuestionAction
  | FormUpdateRequestAction
  | FormUpdateSuccessAction
  | FormUpdateErrorAction
  | FormLoadQuestionsRequestAction
  | FormLoadQuestionsSuccessAction
  | FormLoadQuestionsErrorAction
  | FormMarkAsTouchedAction
  | FormMarkQuestionAsDeletedAction
  | FormCopyQuestionAction
  | FormCloneRequestAction
  | FormCloneSuccessAction
  | FormCloneErrorAction
  | FormSetStatusRequestAction
  | FormSetStatusSuccessAction
  | FormSetStatusErrorAction
  | FormUpdateQuestionsSuccessAction
  | FormDeleteRequestAction
  | FormDeleteSuccessAction
  | FormDeleteErrorAction;
