import React, { FC, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { useAuth } from 'src/hooks/useAuth';
import { THIconGear } from 'src/theme/media';
import {} from 'src/components/Progress';
import { PasswordChangeForm } from 'src/features/auth/components';
import { getUserOnboardingRequest } from 'src/store/actions/user';
import { ProgressMeter } from 'src/components/ProgressMeter';
import { DecodedToken } from 'src/types/common';
import { Button } from 'src/components/Button';
import { validateAuthToken } from './validateAuthToken';
import { LoaderComponent } from 'src/components/Loader';
import * as S from './Styles';

export const PasswordChangePage: FC<unknown> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { hasPendingOnboarding, user, isAdmin } = useAuth();
  const query = new URLSearchParams(search);
  const authToken = query.get('token');
  const id = query.get('id');
  const resetPassword = query.get('reset_password');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthTokenExpired, setIsAuthTokenExpired] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserOnboarding = async () => {
      setIsLoading(true);
      try {
        if (authToken) {
          const isValid = await validateAuthToken(authToken);
          setIsAuthTokenExpired(!isValid);
        } else if (id && !isAdmin) {
          dispatch(getUserOnboardingRequest(+id));
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserOnboarding();
  }, [authToken, id, isAdmin, dispatch]);

  useEffect(() => {
    if (user?.id > 0 && hasPendingOnboarding && !resetPassword) {
      history.push(`/onboarding/welcome${search}`);
    }
  }, [user, hasPendingOnboarding, resetPassword, history, search]);

  const handleNewPass = () => {
    history.push(`/forgotten_password`);
  };

  if (!authToken || !id) {
    history.push('/');
    return null;
  }

  if (isAuthTokenExpired) {
    history.push('/login?warning=link_expired');
    return null;
  }

  const decodedToken = jwtDecode<DecodedToken>(authToken);
  const isTokenExpired =
    decodedToken.exp * 1000 < Date.now() || isAuthTokenExpired;

  if (isLoading) {
    return <LoaderComponent />;
  }

  const expiredTokenPageContent = (
    <S.PageContainer>
      <S.PageContentContainer>
        <S.PageTitle>Link expired</S.PageTitle>
        <S.PageContentContainer>
          <p>
            The link that you received is expired, please request a new one
            below.
          </p>
        </S.PageContentContainer>
        <S.PageContentContainer style={{ width: '100%' }}>
          <Button title="Reset password" onClick={handleNewPass} />
        </S.PageContentContainer>
      </S.PageContentContainer>
    </S.PageContainer>
  );

  const resetPasswordPageContent = (
    <S.PageContainer>
      <S.PageTitle>
        {!hasPendingOnboarding
          ? 'Change your password'
          : "Let's finish setting up your account"}
      </S.PageTitle>
      <S.PageContentContainer>
        <p>
          {!hasPendingOnboarding
            ? 'Please choose a new password and enter it below.'
            : 'Please enter a password to secure your account.'}
        </p>
        {authToken && id && (
          <PasswordChangeForm authToken={authToken} userId={id} />
        )}
        <S.PageContentBottomContainer>
          <ProgressMeter hasOnboarding={hasPendingOnboarding} step={2} />
        </S.PageContentBottomContainer>
      </S.PageContentContainer>
      <S.GearImage src={THIconGear} />
    </S.PageContainer>
  );

  return isTokenExpired ? expiredTokenPageContent : resetPasswordPageContent;
};
