import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { FrownTwoTone } from 'src/theme/icons';
import { NotFoundContainer } from './Styles';

export const NotFound = () => {
  const history = useHistory();

  const handleGoHome = () => {
    history.push('/');
  };

  return (
    <NotFoundContainer>
      <h1>
        Oops! <FrownTwoTone />
      </h1>
      <h2>404 -Page not found</h2>
      <p style={{ fontSize: '20px' }}>
        Uh oh, we can't seem to find the page you're looking for. Try going back
        to the dashboard page.
      </p>
      <Button
        variant="primary"
        altered
        title="Back Home"
        onClick={handleGoHome}
      />
    </NotFoundContainer>
  );
};
