import { Moment } from 'moment';
import { Loader } from './loader';
import {
  FormState,
  Answer,
  AnswerList,
  AnswerData,
  AnswerFromResponse,
  AssessmentState,
  User,
  FileData,
  ShowErrorAction,
  ActivityLogResponse,
} from '../types';

export const INTERVIEW_SAVE_REQUEST = 'INTERVIEW_SAVE_REQUEST';
export const INTERVIEW_SAVE_SUCCESS = 'INTERVIEW_SAVE_SUCCESS';
export const INTERVIEW_SAVE_ERROR = 'INTERVIEW_SAVE_ERROR';

export const INTERVIEW_UPDATE_REQUEST = 'INTERVIEW_UPDATE_REQUEST';
export const INTERVIEW_UPDATE_SUCCESS = 'INTERVIEW_UPDATE_SUCCESS';
export const INTERVIEW_UPDATE_ERROR = 'INTERVIEW_UPDATE_ERROR';

export const INTERVIEW_LOAD_ANSWERS_REQUEST = 'INTERVIEW_LOAD_ANSWERS_REQUEST';
export const INTERVIEW_LOAD_ANSWERS_SUCCESS = 'INTERVIEW_LOAD_ANSWERS_SUCCESS';
export const INTERVIEW_LOAD_ANSWERS_ERROR = 'INTERVIEW_LOAD_ANSWERS_ERROR';

export const INTERVIEW_LOAD_ACTIVITY_LOG_REQUEST =
  'INTERVIEW_LOAD_ACTIVITY_LOG_REQUEST';
export const INTERVIEW_LOAD_ACTIVITY_LOG_SUCCESS =
  'INTERVIEW_LOAD_ACTIVITY_LOG_SUCCESS';
export const INTERVIEW_LOAD_ACTIVITY_LOG_ERROR =
  'INTERVIEW_LOAD_ACTIVITY_LOG_ERROR';

export const ANSWER_LOAD_ACTIVITY_LOG_REQUEST =
  'ANSWER_LOAD_ACTIVITY_LOG_REQUEST';
export const ANSWER_LOAD_ACTIVITY_LOG_SUCCESS =
  'ANSWER_LOAD_ACTIVITY_LOG_SUCCESS';
export const ANSWER_LOAD_ACTIVITY_LOG_ERROR = 'ANSWER_LOAD_ACTIVITY_LOG_ERROR';

export const INTERVIEW_SET_SELECTED = 'INTERVIEW_SET_SELECTED';
export const INTERVIEW_CLEAR_SELECTED = 'INTERVIEW_CLEAR_SELECTED';
export const INTERVIEW_MARK_ANSWER_AS_DELETED =
  'INTERVIEW_MARK_ANSWER_AS_DELETED';
export const INTERVIEW_SAVE_ANSWER = 'INTERVIEW_SAVE_ANSWER';

export const INTERVIEW_SET_QUESTIONS_COUNT = 'INTERVIEW_SET_QUESTIONS_COUNT';
export const INTERVIEW_SET_VISIBLE_QUESTIONS =
  'INTERVIEW_SET_VISIBLE_QUESTIONS';

export const INTERVIEW_FILE_DOWNLOAD = 'INTERVIEW_FILE_DOWNLOAD';

export const INTERVIEW_ADD_FILE = 'INTERVIEW_ADD_FILE';
export const INTERVIEW_REMOVE_FILE = 'INTERVIEW_REMOVE_FILE';

export const INTERVIEW_CREATE_ASSIGNEE_REQUEST =
  'INTERVIEW_CREATE_ASSIGNEE_REQUEST';
export const INTERVIEW_CREATE_ASSIGNEE_SUCCESS =
  'INTERVIEW_CREATE_ASSIGNEE_SUCCESS';
export const INTERVIEW_CREATE_ASSIGNEE_ERROR =
  'INTERVIEW_CREATE_ASSIGNEE_ERROR';

export const INTERVIEW_DELETE_ASSIGNEE_REQUEST =
  'INTERVIEW_DELETE_ASSIGNEE_REQUEST';
export const INTERVIEW_DELETE_ASSIGNEE_SUCCESS =
  'INTERVIEW_DELETE_ASSIGNEE_SUCCESS';
export const INTERVIEW_DELETE_ASSIGNEE_ERROR =
  'INTERVIEW_DELETE_ASSIGNEE_ERROR';

export const INTERVIEW_LOAD_ASSIGNEES_REQUEST =
  'INTERVIEW_LOAD_ASSIGNEES_REQUEST';
export const INTERVIEW_LOAD_ASSIGNEES_SUCCESS =
  'INTERVIEW_LOAD_ASSIGNEES_SUCCESS';
export const INTERVIEW_LOAD_ASSIGNEES_ERROR = 'INTERVIEW_LOAD_ASSIGNEES_ERROR';

export const INTERVIEW_ASSIGN_OWNER_REQUEST = 'INTERVIEW_ASSIGN_OWNER_REQUEST';
export const INTERVIEW_ASSIGN_OWNER_SUCCESS = 'INTERVIEW_ASSIGN_OWNER_SUCCESS';
export const INTERVIEW_ASSIGN_OWNER_ERROR = 'INTERVIEW_ASSIGN_OWNER_ERROR';

export const INTERVIEW_REMOVE_OWNER_REQUEST = 'INTERVIEW_REMOVE_OWNER_REQUEST';
export const INTERVIEW_REMOVE_OWNER_SUCCESS = 'INTERVIEW_REMOVE_OWNER_SUCCESS';
export const INTERVIEW_REMOVE_OWNER_ERROR = 'INTERVIEW_REMOVE_OWNER_ERROR';

export interface InterviewFromResponse {
  id: number;
  createdAt: string;
  updatedAt: string;
  completedAt?: string;
  form: FormState;
  assessment: AssessmentState;
  creator?: User;
  owner?: User;
  answers?: AnswerList;
}

export interface AssigneeState {
  id: number;
  interviewId: number;
  questionId: number;
  assigneeId: number;
  assignerId: number;
  assigneeName: string;
}

export type AssigneeFromResponse = AssigneeState;

export interface InterviewState {
  id?: number;
  createdAt: string;
  updatedAt: string;
  completedAt?: string | null;
  formId: number;
  creatorId: number;
  ownerId?: number;
  ownerName?: string;
  assessmentId: number;
  answers?: AnswerList;
  visibleQuestionsIds?: number[];
}

export interface InterviewMetadataState {
  isPristine: boolean;
  saveCompleted: boolean;
  deletedAnswers: number[];
  questionsCount: number;
  files: FilesIndexedItem;
}

export interface AnswerSuggestionsMap {
  [key: string]: string[];
}

export interface FilesIndexedItem {
  [key: string]: FileData;
}

export interface AnswerSuggestionsState {
  selectedInterviewSmartAnswers: { [key: string]: Answer | AnswerData };
  suggestions: AnswerSuggestionsMap;
}

export interface SelectedInterviewState {
  interview: InterviewState;
  loader: Loader;
  metadata: InterviewMetadataState;
  answers: AnswerList;
  questionAssignee: AssigneeFromResponse[];
  reconciliation: ActivityLogResponse;
  answerSuggestions: AnswerSuggestionsState;
}

export interface InterviewListingIndexedItem {
  [key: string]: InterviewState;
}

export interface InterviewListingState {
  interviews: InterviewListingIndexedItem;
}

export interface InterviewSaveRequestAction {
  type: typeof INTERVIEW_SAVE_REQUEST;
  interview: Partial<InterviewState>;
  companyId: number;
}

export interface InterviewSaveSuccessAction {
  type: typeof INTERVIEW_SAVE_SUCCESS;
  interview: InterviewState;
  companyId: number;
}

export type InterviewSaveErrorAction = ShowErrorAction<
  typeof INTERVIEW_SAVE_ERROR
>;

export interface InterviewSetSelectedAction {
  type: typeof INTERVIEW_SET_SELECTED;
  interview: InterviewState;
}

export interface InterviewClearSelectedAction {
  type: typeof INTERVIEW_CLEAR_SELECTED;
}

export interface InterviewUpdateRequestAction {
  type: typeof INTERVIEW_UPDATE_REQUEST;
  interview: InterviewState;
  companyId: number;
  answers: AnswerList;
  metadata: InterviewMetadataState;
}

export interface InterviewUpdateSuccessAction {
  type: typeof INTERVIEW_UPDATE_SUCCESS;
  interview: InterviewState;
  companyId: number;
}

export type InterviewUpdateErrorAction = ShowErrorAction<
  typeof INTERVIEW_UPDATE_ERROR
>;

export interface InterviewMarkAnswerAsDeletedAction {
  type: typeof INTERVIEW_MARK_ANSWER_AS_DELETED;
  answerId?: number;
  answerInternalId?: string;
}

export interface InterviewSaveAnswerAction {
  type: typeof INTERVIEW_SAVE_ANSWER;
  interviewId: number;
  questionId: number;
  userId: number;
  answerData: AnswerData;
}

export interface InterviewLoadAnswersRequestAction {
  type: typeof INTERVIEW_LOAD_ANSWERS_REQUEST;
  interviewId: number;
}

export interface InterviewLoadAnswersSuccessAction {
  type: typeof INTERVIEW_LOAD_ANSWERS_SUCCESS;
  answers: AnswerFromResponse[];
}

export type InterviewLoadAnswersErrorAction = ShowErrorAction<
  typeof INTERVIEW_LOAD_ANSWERS_ERROR
>;

export interface InterviewLoadActivityLogRequestAction {
  type: typeof INTERVIEW_LOAD_ACTIVITY_LOG_REQUEST;
  fromDate: Moment;
  toDate: Moment;
  email: string;
  page: number;
  pageSize: number;
}

export interface InterviewLoadActivityLogSuccessAction {
  type: typeof INTERVIEW_LOAD_ACTIVITY_LOG_SUCCESS;
  reconciliation: ActivityLogResponse;
}

export type InterviewLoadActivityLogErrorAction = ShowErrorAction<
  typeof INTERVIEW_LOAD_ACTIVITY_LOG_ERROR
>;

export interface AnswerLoadActivityLogRequestAction {
  type: typeof ANSWER_LOAD_ACTIVITY_LOG_REQUEST;
  interviewId: number;
  fromDate: Moment;
  toDate: Moment;
  email: string;
  page: number;
  pageSize: number;
}

export interface AnswerLoadActivityLogSuccessAction {
  type: typeof ANSWER_LOAD_ACTIVITY_LOG_SUCCESS;
  reconciliation: ActivityLogResponse;
}

export type AnswerLoadActivityLogErrorAction = ShowErrorAction<
  typeof ANSWER_LOAD_ACTIVITY_LOG_ERROR
>;

export interface InterviewSetQuestionsCountAction {
  type: typeof INTERVIEW_SET_QUESTIONS_COUNT;
  value: number;
}

export interface InterviewSetVisibleQuestionsAction {
  type: typeof INTERVIEW_SET_VISIBLE_QUESTIONS;
  ids: number[];
}

export interface InterviewFileDownloadRequestAction {
  type: typeof INTERVIEW_FILE_DOWNLOAD;
  file: FileData;
}

export interface InterviewAddFileAction {
  type: typeof INTERVIEW_ADD_FILE;
  file: FileData;
}

export interface InterviewRemoveFileAction {
  type: typeof INTERVIEW_REMOVE_FILE;
  file: FileData;
}

export interface InterviewCreateAssigneeRequestAction {
  type: typeof INTERVIEW_CREATE_ASSIGNEE_REQUEST;
  assigneeUserId: number;
  questionId: number;
  interviewId: number;
}

export interface InterviewCreateAssigneeSuccessAction {
  type: typeof INTERVIEW_CREATE_ASSIGNEE_SUCCESS;
  assignee: AssigneeFromResponse;
}

export type InterviewCreateAssigneeErrorAction = ShowErrorAction<
  typeof INTERVIEW_CREATE_ASSIGNEE_ERROR
>;

export interface InterviewDeleteAssigneeRequestAction {
  type: typeof INTERVIEW_DELETE_ASSIGNEE_REQUEST;
  interviewId: number;
  assigneeId: number;
}

export interface InterviewDeleteAssigneeSuccessAction {
  type: typeof INTERVIEW_DELETE_ASSIGNEE_SUCCESS;
  interviewId: number;
  assigneeId: number;
}

export type InterviewDeleteAssigneeErrorAction = ShowErrorAction<
  typeof INTERVIEW_DELETE_ASSIGNEE_ERROR
>;

export interface InterviewLoadAssigneesRequestAction {
  type: typeof INTERVIEW_LOAD_ASSIGNEES_REQUEST;
  interviewId: number;
}

export interface InterviewLoadAssigneesSuccessAction {
  type: typeof INTERVIEW_LOAD_ASSIGNEES_SUCCESS;
  assignees: AssigneeFromResponse[];
}

export type InterviewLoadAssigneesErrorAction = ShowErrorAction<
  typeof INTERVIEW_LOAD_ASSIGNEES_ERROR
>;

export interface InterviewAssignOwnerRequestAction {
  type: typeof INTERVIEW_ASSIGN_OWNER_REQUEST;
  ownerId: number;
  interviewId: number;
}

export interface InterviewAssignOwnerSuccessAction {
  type: typeof INTERVIEW_ASSIGN_OWNER_SUCCESS;
  interview: InterviewFromResponse;
}

export type InterviewAssignOwnerErrorAction = ShowErrorAction<
  typeof INTERVIEW_ASSIGN_OWNER_ERROR
>;

export interface InterviewRemoveOwnerRequestAction {
  type: typeof INTERVIEW_REMOVE_OWNER_REQUEST;
  interviewId: number;
  ownerUserId: number;
}

export interface InterviewRemoveOwnerSuccessAction {
  type: typeof INTERVIEW_REMOVE_OWNER_SUCCESS;
  interviewId: number;
}

export type InterviewRemoveOwnerErrorAction = ShowErrorAction<
  typeof INTERVIEW_REMOVE_OWNER_ERROR
>;

export type InterviewActionsTypes =
  | InterviewSaveRequestAction
  | InterviewSaveSuccessAction
  | InterviewSaveErrorAction
  | InterviewSetSelectedAction
  | InterviewUpdateRequestAction
  | InterviewUpdateSuccessAction
  | InterviewUpdateErrorAction
  | InterviewMarkAnswerAsDeletedAction
  | InterviewSaveAnswerAction
  | InterviewLoadAnswersErrorAction
  | InterviewLoadAnswersRequestAction
  | InterviewLoadAnswersSuccessAction
  | InterviewLoadActivityLogRequestAction
  | InterviewLoadActivityLogErrorAction
  | InterviewLoadActivityLogSuccessAction
  | AnswerLoadActivityLogRequestAction
  | AnswerLoadActivityLogErrorAction
  | AnswerLoadActivityLogSuccessAction
  | InterviewSetQuestionsCountAction
  | InterviewSetVisibleQuestionsAction
  | InterviewClearSelectedAction
  | InterviewAddFileAction
  | InterviewRemoveFileAction
  | InterviewAssignOwnerRequestAction
  | InterviewAssignOwnerSuccessAction
  | InterviewAssignOwnerErrorAction
  | InterviewRemoveOwnerRequestAction
  | InterviewRemoveOwnerSuccessAction
  | InterviewRemoveOwnerErrorAction;

export type InterviewAnswerSuggestionsTypes =
  | InterviewLoadAnswersSuccessAction
  | InterviewSaveAnswerAction;

export type InterviewAssigneeActionTypes =
  | InterviewCreateAssigneeRequestAction
  | InterviewCreateAssigneeSuccessAction
  | InterviewCreateAssigneeErrorAction
  | InterviewDeleteAssigneeRequestAction
  | InterviewDeleteAssigneeSuccessAction
  | InterviewDeleteAssigneeErrorAction
  | InterviewLoadAssigneesRequestAction
  | InterviewLoadAssigneesSuccessAction
  | InterviewLoadAssigneesErrorAction;
