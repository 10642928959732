import { Loader } from 'src/store/types';
import {
  TrainingPeriodTypes,
  TrainingPeriodState,
  TRAINING_PERIOD_CREATE_FAILED,
  TRAINING_PERIOD_CREATE_REQUEST,
  TRAINING_PERIOD_CREATE_SUCCESS,
  TRAINING_PERIOD_RESET,
  TRAINING_PERIOD_LOAD_REQUEST,
  TRAINING_PERIOD_LOAD_SUCCESS,
  TRAINING_PERIOD_LOAD_FAILED,
  TRAINING_PERIOD_UPDATE_REQUEST,
  TRAINING_PERIOD_UPDATE_SUCCESS,
  TRAINING_PERIOD_UPDATE_FAILED,
  TRAINING_PERIOD_COMPLETE_REQUEST,
  TRAINING_PERIOD_COMPLETE_SUCCESS,
  TRAINING_PERIOD_COMPLETE_FAILED,
  TRAINING_PERIOD_COMPANY_LOAD_REQUEST,
  TRAINING_PERIOD_COMPANY_LOAD_SUCCESS,
  TRAINING_PERIOD_COMPANY_LOAD_FAILED,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const trainingPeriodInitialState: TrainingPeriodState = {
  trainingPeriod: undefined,
  loader: loaderInitialState,
};

export const trainingPeriodReducer = (
  state = trainingPeriodInitialState,
  action: TrainingPeriodTypes,
): TrainingPeriodState => {
  switch (action.type) {
    case TRAINING_PERIOD_COMPANY_LOAD_REQUEST:
    case TRAINING_PERIOD_COMPLETE_REQUEST:
    case TRAINING_PERIOD_CREATE_REQUEST:
    case TRAINING_PERIOD_LOAD_REQUEST:
    case TRAINING_PERIOD_UPDATE_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case TRAINING_PERIOD_COMPANY_LOAD_SUCCESS:
    case TRAINING_PERIOD_COMPLETE_SUCCESS:
    case TRAINING_PERIOD_CREATE_SUCCESS:
    case TRAINING_PERIOD_LOAD_SUCCESS: {
      return {
        trainingPeriod: action.trainingPeriod,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_PERIOD_UPDATE_SUCCESS: {
      return {
        ...state,
        trainingPeriod: action.trainingPeriod,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_PERIOD_COMPANY_LOAD_FAILED: {
      return {
        ...trainingPeriodInitialState,
        loader: {
          ...trainingPeriodInitialState.loader,
          error: action.error,
          success: false,
        },
      };
    }
    case TRAINING_PERIOD_COMPLETE_FAILED:
    case TRAINING_PERIOD_CREATE_FAILED:
    case TRAINING_PERIOD_LOAD_FAILED:
    case TRAINING_PERIOD_RESET: {
      return {
        ...trainingPeriodInitialState,
      };
    }
    case TRAINING_PERIOD_UPDATE_FAILED: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
        },
      };
    }
    default:
      return state;
  }
};
