import {
  DocumentState,
  DocumentFromResponse,
  DocumentLoadSuccessAction,
  DOCUMENT_LOAD_REQUEST,
  DocumentLoadRequestAction,
  DOCUMENT_LOAD_SUCCESS,
  DocumentLoadErrorAction,
  DOCUMENT_LOAD_ERROR,
  DOCUMENT_APPROVE_REQUEST,
  DocumentApproveRequestAction,
  DOCUMENT_APPROVE_SUCCESS,
  DocumentApproveSuccessAction,
  DOCUMENT_APPROVE_ERROR,
  DocumentApproveErrorAction,
  DOCUMENT_CLOSE_REQUEST,
  DocumentCloseRequestAction,
  DOCUMENT_CLOSE_SUCCESS,
  DocumentCloseSuccessAction,
  DOCUMENT_CLOSE_ERROR,
  DocumentCloseErrorAction,
  SET_SELECTED_DOCUMENT,
  SetSelectedDocumentAction,
  Answer,
  DOCUMENT_ANSWER_UPDATE,
  DocumentAnswerUpdateAction,
  DOCUMENT_SAVE_ERROR,
  DocumentSaveErrorAction,
  DOCUMENT_SAVE_SUCCESS,
  DocumentSaveSuccessAction,
  DOCUMENT_SAVE_REQUEST,
  DocumentSaveRequestAction,
  DOCUMENT_PP_SAVE_ERROR,
  DOCUMENT_PP_SAVE_REQUEST,
  DOCUMENT_PP_SAVE_SUCCESS,
  DocumentDraftState,
  DocumentPPSaveRequestAction,
  DocumentPPSaveSuccessAction,
  DocumentPPSaveErrorAction,
  DocumentSetInClientReviewRequestAction,
  DOCUMENT_SET_IN_CLIENT_REVIEW_REQUEST,
  DocumentSetInClientReviewErrorAction,
  DOCUMENT_SET_IN_CLIENT_REVIEW_ERROR,
  DocumentSetInClientReviewSuccessAction,
  DOCUMENT_SET_IN_CLIENT_REVIEW_SUCCESS,
  DocumentAnswerUpdateFailedAction,
  DOCUMENT_ANSWER_UPDATE_FAILED,
  DOCUMENT_ACCEPT_REQUEST,
  DOCUMENT_ACCEPT_SUCCESS,
  DOCUMENT_ACCEPT_ERROR,
  DocumentAcceptRequestAction,
  DocumentAcceptSuccessAction,
  DocumentAcceptErrorAction,
  DOCUMENT_FINALIZE_REQUEST,
  DOCUMENT_FINALIZE_SUCCESS,
  DOCUMENT_FINALIZE_ERROR,
  DocumentFinalizeRequestAction,
  DocumentFinalizeSuccessAction,
  DocumentFinalizeErrorAction,
} from '../types';
import { showErrorMessage } from './error';

export const documentLoadRequest = (
  documentId: number,
): DocumentLoadRequestAction => ({
  type: DOCUMENT_LOAD_REQUEST,
  documentId,
});

export const documentLoadSuccess = (
  document: DocumentFromResponse,
): DocumentLoadSuccessAction => ({
  type: DOCUMENT_LOAD_SUCCESS,
  document,
});

export const documentLoadError = (
  errorMessage: string,
  originalError: Error,
): DocumentLoadErrorAction =>
  showErrorMessage(DOCUMENT_LOAD_ERROR, errorMessage, originalError);

export const documentApproveRequest = (
  documentId: number,
): DocumentApproveRequestAction => ({
  type: DOCUMENT_APPROVE_REQUEST,
  documentId,
});

export const documentApproveSuccess = (
  documentId: number,
): DocumentApproveSuccessAction => ({
  type: DOCUMENT_APPROVE_SUCCESS,
  documentId,
});

export const documentApproveError = (
  errorMessage: string,
  originalError: Error,
): DocumentApproveErrorAction =>
  showErrorMessage(DOCUMENT_APPROVE_ERROR, errorMessage, originalError);

export const documentFinalizeRequest = (
  documentId: number,
): DocumentFinalizeRequestAction => ({
  type: DOCUMENT_FINALIZE_REQUEST,
  documentId,
});

export const documentFinalizeSuccess = (
  documentId: number,
): DocumentFinalizeSuccessAction => ({
  type: DOCUMENT_FINALIZE_SUCCESS,
  documentId,
});

export const documentFinalizeError = (
  errorMessage: string,
  originalError: Error,
): DocumentFinalizeErrorAction =>
  showErrorMessage(DOCUMENT_FINALIZE_ERROR, errorMessage, originalError);

export const documentSetInClientReviewRequest = (
  documentId: number,
): DocumentSetInClientReviewRequestAction => ({
  type: DOCUMENT_SET_IN_CLIENT_REVIEW_REQUEST,
  documentId,
});

export const documentSetInClientReviewSuccess = (
  documentId: number,
): DocumentSetInClientReviewSuccessAction => ({
  type: DOCUMENT_SET_IN_CLIENT_REVIEW_SUCCESS,
  documentId,
});

export const documentSetInClientReviewError = (
  errorMessage: string,
  originalError: Error,
): DocumentSetInClientReviewErrorAction =>
  showErrorMessage(
    DOCUMENT_SET_IN_CLIENT_REVIEW_ERROR,
    errorMessage,
    originalError,
  );

export const documentMarkAcceptedRequest = (
  documentId: number,
): DocumentAcceptRequestAction => ({
  type: DOCUMENT_ACCEPT_REQUEST,
  documentId,
});

export const documentMarkAcceptedSuccess = (
  documentId: number,
): DocumentAcceptSuccessAction => ({
  type: DOCUMENT_ACCEPT_SUCCESS,
  documentId,
});

export const documentMarkAcceptedError = (
  errorMessage: string,
  originalError: Error,
): DocumentAcceptErrorAction =>
  showErrorMessage(DOCUMENT_ACCEPT_ERROR, errorMessage, originalError);

export const documentCloseRequest = (
  documentId: number,
): DocumentCloseRequestAction => ({
  type: DOCUMENT_CLOSE_REQUEST,
  documentId,
});

export const documentCloseSuccess = (
  documentId: number,
): DocumentCloseSuccessAction => ({
  type: DOCUMENT_CLOSE_SUCCESS,
  documentId,
});

export const documentCloseError = (
  errorMessage: string,
  originalError: Error,
): DocumentCloseErrorAction =>
  showErrorMessage(DOCUMENT_CLOSE_ERROR, errorMessage, originalError);

export const setSelectedDocument = (
  document: DocumentState,
): SetSelectedDocumentAction => ({
  type: SET_SELECTED_DOCUMENT,
  document,
});

export const documentSaveRequest = (
  document: DocumentState,
): DocumentSaveRequestAction => ({
  type: DOCUMENT_SAVE_REQUEST,
  document,
});

export const documentSaveSuccess = (
  document: DocumentState,
): DocumentSaveSuccessAction => ({
  type: DOCUMENT_SAVE_SUCCESS,
  document,
});

export const documentSaveError = (
  errorMessage: string,
  originalError: Error,
): DocumentSaveErrorAction =>
  showErrorMessage(DOCUMENT_SAVE_ERROR, errorMessage, originalError);

export const documentPPSaveRequest = (
  documentDraft: DocumentDraftState,
  assessmentId: number,
): DocumentPPSaveRequestAction => ({
  type: DOCUMENT_PP_SAVE_REQUEST,
  documentDraft,
  assessmentId,
});

export const documentPPSaveSuccess = (): DocumentPPSaveSuccessAction => ({
  type: DOCUMENT_PP_SAVE_SUCCESS,
});

export const documentPPSaveError = (
  errorMessage: string,
  originalError: Error,
): DocumentPPSaveErrorAction =>
  showErrorMessage(DOCUMENT_PP_SAVE_ERROR, errorMessage, originalError);

export const updateDocumentAnswer = (
  answerId: number,
  interviewId: number,
  answerUpdates: Partial<Answer>,
): DocumentAnswerUpdateAction => ({
  type: DOCUMENT_ANSWER_UPDATE,
  answerId,
  interviewId,
  answerUpdates,
});

export const updateDocumentAnswerFailed = (
  error: string,
): DocumentAnswerUpdateFailedAction => ({
  type: DOCUMENT_ANSWER_UPDATE_FAILED,
  error,
});
