import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TopNavbar } from 'src/components/TopNavbar';
import styled from 'styled-components';
import actions from 'src/store/actions';
import { getCertificateListingResponse } from 'src/store/selectors';
import { CertificateListingTable } from '../../components';

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const CertificateListPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const certificateListingResponse = useSelector(getCertificateListingResponse);

  useEffect(() => {
    dispatch(actions.certificateListingLoadRequest());
  }, []);

  return (
    <>
      <TopNavbar title="HIPAA Training Certificates" />
      <PageContentContainer>
        <CertificateListingTable dataSource={certificateListingResponse} />
      </PageContentContainer>
    </>
  );
};
