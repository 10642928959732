import {
  UserSummaryFromResponse,
  GetUserSummaryErrorAction,
  GetUserSummaryRequestAction,
  GetUserSummarySuccessAction,
  USER_SUMMARY_LOAD_REQUEST,
  USER_SUMMARY_LOAD_SUCCESS,
  USER_SUMMARY_LOAD_ERROR,
} from '../types';

import { showErrorMessage } from './error';

export const userSummaryLoadRequest = (
  companyId: number,
): GetUserSummaryRequestAction => ({
  type: USER_SUMMARY_LOAD_REQUEST,
  companyId,
});

export const userSummaryLoadSuccess = (
  users: UserSummaryFromResponse[],
): GetUserSummarySuccessAction => ({
  type: USER_SUMMARY_LOAD_SUCCESS,
  users,
});

export const userSummaryLoadError = (
  errorMessage: string,
  originalError: Error,
): GetUserSummaryErrorAction =>
  showErrorMessage(USER_SUMMARY_LOAD_ERROR, errorMessage, originalError);
