import { Form, FormInstance } from 'antd';
import React, { FC, useState } from 'react';
import moment from 'moment';
import { Button } from 'src/components/Button';
import { DatePicker } from 'src/components/DatePicker';
import { Dropdown, Option } from 'src/components/Dropdown';
import { Loader } from 'src/store/types';
import {
  DueDateType,
  TrainingPeriodType,
} from '../../routes/SetTrainingPeriodPage';
import * as S from './Styles';

interface SetTrainingPeriodFormProps {
  form: FormInstance;
  onSubmit: (values: any) => void;
  trainingPeriodLoader: Loader;
}

interface FormValues {
  releaseDateType: string;
  customReleaseDate?: Date | undefined;
  dueDateType: string;
  customDueDate?: Date | undefined;
}

export const SetTrainingPeriodForm: FC<SetTrainingPeriodFormProps> = ({
  form,
  trainingPeriodLoader,
  onSubmit,
}) => {
  const [openReleaseDate, setOpenReleaseDate] = useState<boolean>(false);
  const [openDueDate, setOpenDueDate] = useState<boolean>(false);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [releaseDate, setReleaseDate] = useState<Date | null>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [disableAbleDueDate, setDisableAbleDueDate] = useState(true);

  const handleFormValuesChange = (
    _: any,
    { releaseDateType, dueDateType }: FormValues,
  ) => {
    setIsButtonDisabled(!releaseDateType || !dueDateType);
  };

  const getReleaseDateOptions = (): Option[] => {
    const options: Option[] = [
      {
        text: 'Today (Training will be released now)',
        value: TrainingPeriodType.TODAY,
      },
    ];

    if (moment().add(4, 'weeks').year() === moment().year()) {
      options.push({
        text: '4 weeks (release training 4 weeks from today)',
        value: TrainingPeriodType.WEEKS_4,
      });
    }

    options.push({
      text: 'Select date (within calendar year)',
      value: TrainingPeriodType.CUSTOM,
    });

    return options;
  };

  const getDueDateOptions = (): Option[] => {
    const options: Option[] = [];

    if (moment().add(30, 'days').year() === moment().year()) {
      options.push({
        text: '30 days (about 30 minutes per week)',
        value: DueDateType.DAYS_30,
      });
    }
    if (moment().add(60, 'days').year() === moment().year()) {
      options.push({
        text: '60 days (about 15 minutes per week)',
        value: DueDateType.DAYS_60,
      });
    }

    options.push({
      text: 'Select date (within calendar year)',
      value: DueDateType.CUSTOM,
    });

    return options;
  };

  const onReleaseDateDropdownChange = (value: string): void => {
    if (value === undefined) {
      setOpenReleaseDate(false);
      setReleaseDate(null);
      setDisableAbleDueDate(true);
      setDueDate(null);
      setOpenDueDate(false);
      form.resetFields();
      return;
    }

    let newReleaseDate = null;

    if (value === TrainingPeriodType.CUSTOM) {
      setOpenReleaseDate(true);
      newReleaseDate = releaseDate;
    } else if (value === TrainingPeriodType.TODAY) {
      newReleaseDate = moment().toDate();
    } else if (value === TrainingPeriodType.WEEKS_4) {
      newReleaseDate = moment().add(4, 'weeks').toDate();
    }

    setReleaseDate(newReleaseDate);
    setDisableAbleDueDate(false);

    if (dueDate && newReleaseDate) {
      let newDueDate;
      if (form.getFieldValue('dueDateType') === DueDateType.DAYS_30) {
        newDueDate = moment(newReleaseDate).add(30, 'days').toDate();
      } else if (form.getFieldValue('dueDateType') === DueDateType.DAYS_60) {
        newDueDate = moment(newReleaseDate).add(60, 'days').toDate();
      } else {
        newDueDate = dueDate;
      }
      setDueDate(newDueDate);
    }
  };

  const onDueDateDropdownChange = (value: string): void => {
    if (value === undefined) {
      setOpenDueDate(false);
      setDueDate(null);
      return;
    }

    if (value === DueDateType.CUSTOM) {
      setOpenDueDate(true);
    }

    if (value === DueDateType.DAYS_30) {
      setDueDate(moment(releaseDate).add(30, 'days').toDate());
    } else if (value === DueDateType.DAYS_60) {
      setDueDate(moment(releaseDate).add(60, 'days').toDate());
    } else {
      setDueDate(moment().toDate());
    }
  };

  const onTrainingDatePickerChange = (selectedReleaseDate: any): void => {
    setReleaseDate(selectedReleaseDate);
    setOpenReleaseDate(false);
  };

  const onDueDatePickerChange = (selectedDueDate: any): void => {
    setDueDate(selectedDueDate);
    setOpenDueDate(false);
  };

  const handleSubmit = (): void => {
    form.submit();
  };

  const disableInvalidDates = (currentDate: moment.Moment): boolean =>
    moment().add(-1, 'days') >= currentDate ||
    moment().year() < currentDate.year();

  const disableInvalidDatesForDueTraining = (
    currentDueDate: moment.Moment,
  ): boolean => {
    if (
      moment().add(-1, 'days') >= currentDueDate ||
      moment().year() < currentDueDate.year()
    ) {
      return true;
    }
    if (releaseDate && currentDueDate.isBefore(releaseDate, 'day')) {
      return true;
    }
    return false;
  };

  return (
    <S.FormContainer>
      <S.FormWrapper>
        <Form
          form={form}
          onFinish={onSubmit}
          onValuesChange={handleFormValuesChange}
        >
          <div>
            <S.DropdownLabel>
              Set training release date.{' '}
              <span style={{ fontStyle: 'italic' }}>
                (Officers can begin training immediately)
              </span>
            </S.DropdownLabel>
            <Form.Item name={'releaseDateType'}>
              <Dropdown
                placeholder={'Training release date'}
                onChange={onReleaseDateDropdownChange}
                options={getReleaseDateOptions()}
              />
            </Form.Item>
            <Form.Item
              name={'customReleaseDate'}
              style={{ visibility: 'hidden', width: 0, height: 0, margin: 0 }}
            >
              <DatePicker
                open={openReleaseDate}
                style={{ visibility: 'hidden', width: 0, height: 0 }}
                onChange={onTrainingDatePickerChange}
                disabledDate={disableInvalidDates}
              />
            </Form.Item>
          </div>
          <div>
            <S.DropdownLabel>
              Set training due date. (You can change this later.)
            </S.DropdownLabel>
            <Form.Item name={'dueDateType'}>
              <Dropdown
                placeholder={'Training due date'}
                onChange={onDueDateDropdownChange}
                options={getDueDateOptions()}
                disabled={disableAbleDueDate}
              />
            </Form.Item>
            <Form.Item
              name={'customDueDate'}
              style={{ visibility: 'hidden', width: 0, height: 0, margin: 0 }}
            >
              <DatePicker
                open={openDueDate}
                style={{ visibility: 'hidden', width: 0, height: 0 }}
                onChange={onDueDatePickerChange}
                disabledDate={disableInvalidDatesForDueTraining}
              />
            </Form.Item>
          </div>
        </Form>
        <S.TrainingDates>
          <S.Label>Training release date:</S.Label>{' '}
          <span>
            {releaseDate ? `${moment(releaseDate).format('MMMM D')}th` : '-'}
          </span>
        </S.TrainingDates>
        <S.TrainingDates>
          <S.Label>Training due date:</S.Label>
          <span>{dueDate ? `${moment(dueDate).format('MMMM D')}th` : '-'}</span>
        </S.TrainingDates>
        <S.ButtonWrapper>
          <Button
            disabled={isButtonDisabled}
            title="Set training period"
            onClick={handleSubmit}
            loading={trainingPeriodLoader.loading}
          />
        </S.ButtonWrapper>
      </S.FormWrapper>
    </S.FormContainer>
  );
};
