import React, { FC } from 'react';
import styled from 'styled-components';
import { Table as TableAntd } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CheckOutlined } from 'src/theme/icons';
import { IconButton } from 'src/components/Button';

export enum TemplateFunctions {
  A_AN = 'a_an',
  THE_NOUN = 'the_noun',
  CAPITALIZE = 'capitalize',
  POSSESSIVE = 'possessive',
  LOWER_CASE = 'lower_case',
  GET_ADDRESS_STATE = 'get_address_state',
  GET_ADDRESS_ZIP = 'get_address_zip',
  GET_ADDRESS_STREET = 'get_address_street',
  GET_ADDRESS_EXTRA = 'get_address_street_extra',
  GET_ADDRESS_CITY = 'get_address_city',
  GET_ADDRESS_FULL = 'get_address_full',
  GET_CONTACT_TITLE = 'get_contact_title',
  GET_CONTACT_FIRST_NAME = 'get_contact_firstname',
  GET_CONTACT_LAST_NAME = 'get_contact_lastname',
  GET_CONTACT_EMAIL = 'get_contact_email',
  GET_CONTACT_PHONE = 'get_contact_phone',
}

interface TemplateFunction {
  key: TemplateFunctions;
  description: string;
}

interface TemplateEditorFunctionSelectorWindowProps {
  onSelection: (functionName: string) => void;
  onClose: () => void;
}

const Container = styled.div`
  width: 100%;
`;

const Table = styled<any>(TableAntd)`
  tr {
    th {
      font-size: 14px;

      &:last-child {
        text-align: right;
      }
    }
    td {
      font-size: 13px;
      .actions {
        text-align: right;
        button {
          font-size: 13px;
        }
      }
    }
  }
`;

export const TemplateEditorFunctionSelectorWindow: FC<
  TemplateEditorFunctionSelectorWindowProps
> = ({ onSelection, onClose }) => {
  const availableFunctions: TemplateFunction[] = [
    { key: TemplateFunctions.A_AN, description: "Prefix with 'a' or 'an'" },
    { key: TemplateFunctions.THE_NOUN, description: "Prefix with 'the'" },
    {
      key: TemplateFunctions.CAPITALIZE,
      description: 'Make first letter uppercase',
    },
    { key: TemplateFunctions.POSSESSIVE, description: "Add apostrophe 's'" },
    { key: TemplateFunctions.LOWER_CASE, description: 'Convert to lowercase' },
    {
      key: TemplateFunctions.GET_ADDRESS_CITY,
      description: "Get address's city",
    },
    {
      key: TemplateFunctions.GET_ADDRESS_STATE,
      description: "Get address's state",
    },
    {
      key: TemplateFunctions.GET_ADDRESS_STREET,
      description: "Get address's street",
    },
    {
      key: TemplateFunctions.GET_ADDRESS_EXTRA,
      description: "Get address's street extra",
    },
    {
      key: TemplateFunctions.GET_ADDRESS_ZIP,
      description: "Get address's zip",
    },
    {
      key: TemplateFunctions.GET_ADDRESS_FULL,
      description: 'Get address full',
    },
    {
      key: TemplateFunctions.GET_CONTACT_TITLE,
      description: "Get contact's title",
    },
    {
      key: TemplateFunctions.GET_CONTACT_EMAIL,
      description: "Get contact's email",
    },
    {
      key: TemplateFunctions.GET_CONTACT_FIRST_NAME,
      description: "Get contact's first key",
    },
    {
      key: TemplateFunctions.GET_CONTACT_LAST_NAME,
      description: "Get contact's last key",
    },
    {
      key: TemplateFunctions.GET_CONTACT_PHONE,
      description: "Get contact's phone",
    },
  ];

  const handleSelection = (functionName: string) => {
    if (functionName) {
      onSelection(functionName);
      onClose();
    }
  };

  const columns: ColumnsType<string> = [
    {
      title: 'Name',
      dataIndex: 'key',
      render: (key: string) => <p>{key}</p>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (description: string) => <p>{description}</p>,
    },
    {
      title: 'Actions',
      render: (templateFunc: TemplateFunction) => {
        return (
          <div className="actions">
            <IconButton
              onClick={() => handleSelection(templateFunc.key)}
              icon={<CheckOutlined />}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      <Table columns={columns} dataSource={availableFunctions} size="small" />
    </Container>
  );
};
