import React, { useState, useEffect } from 'react';
import { Form, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { TextArea } from 'src/components/TextArea';
import { Button, IconButton } from 'src/components/Button';
import { CloseCircleOutlined } from 'src/theme/icons';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { commentSaveRequest } from 'src/store/actions/comment';
import {
  FileData,
  CommentInput,
  UserListingIndexedItem,
  RootState,
  Comment,
} from 'src/store/types';
import {
  getCompanyUsers,
  getCommentById,
  getCommentLoaderFromState,
  getIsCommentLoaderSuccessful,
} from 'src/store/selectors';
import { CommentAttachmentInput } from './AttachmentInput';
import { TagUserInput } from './TagUserInput';

export interface CreateCommentFormProps {
  form: FormInstance;
  assessmentId?: number;
  documentDraftId?: number;
  threadId?: number;
  questionId?: number;
  parentId?: number;
  refKey?: string;
  taggedUsersIds?: number[];
  onSavePress: () => void;
  closePanel: () => void;
  onClose: () => void;
  companyId: number;
  documentId: number;
  replyingToId?: number;
}

interface CommentValues {
  message: string;
  taggedUsersIds: number[];
  adminOnly: boolean;
  attachments: FileData[];
}

const MainContainer = styled.div<{ isNewComment: boolean }>`
  overflow-y: auto;
  position: absolute;
  bottom: 0px;
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ isNewComment }) => (isNewComment ? '100%' : '70%')};

  h3 {
    color: ${({ theme }) => theme.colors.deepBlue.main};
    font-size: 14px;
    margin: 10px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 11px;
  }

  .title {
    font-size: 16px;
  }

  blockquote {
    padding: 0px 22px;
    opacity: 0.7;

    p {
      padding-left: 2px;
      border-left: 5px solid gray;
      margin: 0px;
    }
  }
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 4px 0;

  .ant-row {
    width: 100%;
    margin: 0;
  }

  .ant-form-item {
    margin-bottom: 4px;
  }

  .ant-upload {
    padding: 10px 10px 10px 11px;
  }
  .ant-btn-text {
    font-size: 14px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }

  .text-form-item {
    border: ${({ theme }) => `1px solid ${theme.colors.grayscale.middleGray}`};
    width: 90% !important;
    align-self: center;
    padding: 4px;
  }
  .ant-select-show-search {
    padding: 0px;
  }
  .special-form-item {
    width: 90% !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h3 {
    margin: 10px;
    margin-bottom: 0px;
  }
  button {
    margin: 10px;
    margin-bottom: 0px;
  }
`;

const FooterContainer = styled.div`
  background-color: white;
  padding: 8px 24px;
  box-shadow: 0px -2px 10px rgba(0, 35, 11, 0.1);
  border-radius: 0 0 10px 10px;
  width: 100%;
`;

const WideButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  margin: 0px 0px 0px 1px; !important
  padding: 4px 12px;
  &:focus {
    font-size: 14px;
    padding: 4px 12px;
  }
`;

const CreateCommentFormFR: React.ForwardRefRenderFunction<
  HTMLDivElement,
  CreateCommentFormProps
> = (
  {
    form,
    assessmentId,
    threadId,
    questionId,
    parentId,
    replyingToId,
    onSavePress,
    onClose,
    documentDraftId,
    refKey,
    companyId,
    documentId,
    closePanel,
  },
  ref,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taggedUsers, setTaggedUsers] = useState<string[]>([]);
  const [tagUserInput, setTagUserInput] = useState<string | undefined>(
    undefined,
  );
  const [tagAdmins, setTagAdmins] = useState(false);

  const { user, isAdmin } = useAuth();

  const companyUsers = useSelector(
    (state: RootState): UserListingIndexedItem => getCompanyUsers(state),
  );

  const commentLoader = useSelector(getCommentLoaderFromState);
  const isCommentLoaderSuccessful = useSelector(getIsCommentLoaderSuccessful);

  const parentComment = useSelector((state: RootState): Comment | undefined =>
    getCommentById(state, replyingToId),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading && isCommentLoaderSuccessful) {
      form.resetFields();
      closePanel();
    }
  }, [isLoading, closePanel, isCommentLoaderSuccessful, form]);

  const handleSubmit = (values: CommentValues) => {
    setIsLoading(true);

    const comment: CommentInput = {
      comment: values?.message,
      adminOnly: values?.adminOnly,
      authorId: user?.id,
      parentId: parentId,
      threadId: threadId,
      questionId: questionId,
      taggedUsersIds: taggedUsers.map((userId: string) => Number(userId)),
      tagAdmins,
      assessmentId,
      documentDraftId,
      companyId,
      documentId,
      refKey,
    };

    if (values?.attachments?.length > 0) {
      comment.files = JSON.stringify(values.attachments);
    }

    dispatch(commentSaveRequest(comment));

    return null;
  };

  const tagUser = (value: string) => {
    setTagUserInput('');
    if (value === 'admins') {
      setTagAdmins(true);
    } else {
      setTaggedUsers([...taggedUsers, value]);
    }
  };

  const removeTaggedUser = (value: string) => {
    setTagUserInput('');
    if (value === 'admins') {
      setTagAdmins(false);
    } else {
      setTaggedUsers(taggedUsers.filter((option: string) => option !== value));
    }
  };

  return (
    <MainContainer ref={ref} isNewComment={!parentComment}>
      <HeaderContainer>
        <h3 className="title">Write a Comment</h3>
        <IconButton onClick={onClose} icon={<CloseCircleOutlined />} />
      </HeaderContainer>

      {parentComment && (
        <blockquote>
          <p>Replying to: "{parentComment.comment}".</p>
        </blockquote>
      )}

      <FormContainer
        form={form}
        onFinish={(values) => handleSubmit(values as CommentValues)}
      >
        <Form.Item
          name="message"
          rules={[{ required: true, message: 'Please input the message' }]}
        >
          <TextArea className="text-form-item" placeholder="Message" rows={6} />
        </Form.Item>
        <CommentAttachmentInput />
        <TagUserInput
          taggedUsers={taggedUsers}
          tagAdmins={tagAdmins}
          value={tagUserInput}
          onChange={setTagUserInput}
          onSelect={tagUser}
          users={companyUsers}
          removeTaggedUser={removeTaggedUser}
        />
        {isAdmin && (
          <Form.Item
            name="adminOnly"
            rules={[{ required: false }]}
            valuePropName="checked"
          >
            <Checkbox className="special-form-item">
              Make comment private to TotalHIPAA
            </Checkbox>
          </Form.Item>
        )}
      </FormContainer>
      <FooterContainer>
        <WideButton
          title="Submit"
          onClick={onSavePress}
          loading={commentLoader.loading}
        />
      </FooterContainer>
    </MainContainer>
  );
};

export const CreateCommentForm = React.forwardRef(CreateCommentFormFR);
