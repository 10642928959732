import moment, { Moment } from 'moment';
import { choiceValue, Condition } from 'src/store/types';

export const checkConditionWithValue = (
  condition: Condition,
  value: string | string[] | Moment | choiceValue | undefined,
): boolean => {
  if (!value) return false;

  let expected = '';
  if (condition.condition) {
    expected = condition.condition ? condition.condition : '';
  } else {
    if (condition.choice && condition.choice.id) {
      expected = condition.choice.label ? condition.choice.label : '';
    }
  }

  if (Array.isArray(value)) {
    return checkConditionForArrays(condition, expected, value);
  } else {
    if (moment.isMoment(value) && moment(expected).isValid()) {
      return checkConditionForDates(condition, moment(expected), value);
    }
    return checkConditionForString(condition, expected, String(value));
  }
};

const checkConditionForDates = (
  condition: Condition,
  expected: Moment,
  value: Moment,
): boolean => {
  switch (condition.comparator) {
    case 'IS': {
      return value.isSame(expected);
    }
    case 'IS_NOT': {
      return !value.isSame(expected);
    }
    case 'GREATER_THAN': {
      return value.isBefore(expected);
    }
    case 'LESS_THAN': {
      return value.isAfter(expected);
    }
    default:
      return false;
  }
};

const checkConditionForArrays = (
  condition: Condition,
  expected: string,
  value: string[],
): boolean => {
  switch (condition.comparator) {
    case 'IS': {
      return value.includes(expected);
    }
    case 'IS_NOT': {
      return !value.includes(expected);
    }
    default:
      return false;
  }
};

const checkConditionForString = (
  condition: Condition,
  expected: string,
  answer: string,
): boolean => {
  switch (condition.comparator) {
    case 'IS': {
      return answer === expected;
    }
    case 'IS_NOT': {
      return answer !== expected;
    }
    case 'GREATER_THAN': {
      const num: number = expected ? +expected : 0;
      const numVal: number = +answer;
      return numVal > num;
    }
    case 'LESS_THAN': {
      const num: number = expected ? +expected : 0;
      const numVal: number = answer ? +answer : 0;
      return numVal < num;
    }
    case 'CONTAINS': {
      if (!answer.length || !expected.length) {
        return false;
      }
      if (answer.length < expected.length) {
        return false;
      }
      return answer.includes(expected);
    }
    case 'STARTS_WITH': {
      if (!answer.length || !expected.length) {
        return false;
      }
      if (answer.length < expected.length) {
        return false;
      }
      return answer.startsWith(expected);
    }
    case 'ENDS_WITH': {
      if (!answer.length || !expected.length) {
        return false;
      }
      if (answer.length < expected.length) {
        return false;
      }
      return answer.endsWith(expected);
    }
    default:
      return false;
  }
};
