/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Select as SelectAntD } from 'antd';
import { Question, AnswerData, Answer } from 'src/store/types';
import { AutoComplete as AutoCompleteBase } from 'src/features/risk-assessment/components/AutoCompleteInput';
import { remConvert } from 'src/theme/utils';

const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export interface Address {
  street?: string;
  streetExtra?: string;
  city?: string;
  state?: string;
  zip?: string;
}

interface AddressFieldProps {
  onChange: (values: Address) => void;
  value: Address;
  question: Question;
  updateAnswer: (answerData: AnswerData) => void;
  deleteAnswer: (answerData: AnswerData) => void;
  answer: Answer;
  suggestions: string[];
}

const AutoComplete = styled(AutoCompleteBase)`
  && {
    margin-bottom: ${remConvert(15)};
  }
`;

const InputGroup = styled(Input.Group)`
  width: ${remConvert(312)};
`;

const Select = styled(SelectAntD)`
  &&&&&& {
    height: ${remConvert(40)};
    margin-bottom: ${remConvert(15)};
    width: ${remConvert(312)};

    .ant-select-selector {
      border: none;
      height: ${remConvert(40)};
      padding-top: ${remConvert(5)};
      padding-left: ${remConvert(15)};
    }
  }
`;

export const AddressField: FC<AddressFieldProps> = ({
  onChange,
  value,
  question,
  updateAnswer,
  deleteAnswer,
  answer,
  suggestions,
}) => {
  const [blurIgnoreUpdate, setBlurIgnoreUpdate] = useState(false);

  const triggerSelectChange = (changedState: string) => {
    onChange({ ...value, state: changedState });
    triggerAnswerUpdate({ state: changedState });
  };

  const triggerAnswerUpdate = (selectVals?: {
    state?: string;
    street?: string;
    streetExtra?: string;
    city?: string;
    zip?: string;
  }) => {
    const answerValue = value ? { ...value } : {};

    if (selectVals?.state) {
      return updateAnswer({
        value: JSON.stringify({ ...answerValue, state: selectVals.state }),
        id: answer?.id,
        internalId: answer?.internalId,
      });
    }

    if (selectVals?.street) {
      return updateAnswer({
        value: JSON.stringify({ ...answerValue, street: selectVals.street }),
        id: answer?.id,
        internalId: answer?.internalId,
      });
    }

    if (selectVals?.streetExtra) {
      return updateAnswer({
        value: JSON.stringify({
          ...answerValue,
          streetExtra: selectVals.streetExtra,
        }),
        id: answer?.id,
        internalId: answer?.internalId,
      });
    }

    if (selectVals?.city) {
      return updateAnswer({
        value: JSON.stringify({ ...answerValue, city: selectVals.city }),
        id: answer?.id,
        internalId: answer?.internalId,
      });
    }

    if (selectVals?.zip) {
      return updateAnswer({
        value: JSON.stringify({ ...answerValue, zip: selectVals.zip }),
        id: answer?.id,
        internalId: answer?.internalId,
      });
    }

    if (
      !value ||
      (!value.state &&
        !value.city &&
        !value.street &&
        !value.streetExtra &&
        !value.zip)
    ) {
      return deleteAnswer?.({ id: answer?.id, internalId: answer?.internalId });
    }

    updateAnswer({
      value: JSON.stringify(answerValue),
      id: answer?.id,
      internalId: answer?.internalId,
    });
  };

  const onBlur = () => {
    if (blurIgnoreUpdate) {
      setBlurIgnoreUpdate(false);
      return;
    }

    setTimeout(() => triggerAnswerUpdate(), 100);
  };

  const onSelect = (key: string) => (value: string) => {
    triggerAnswerUpdate({ [key]: value });
    setBlurIgnoreUpdate(true);
  };

  const disableSuggestions = !question || !question.isSmart;
  const options: Address[] =
    disableSuggestions || !suggestions
      ? []
      : suggestions.map((s) => JSON.parse(s) as Address);

  return (
    <>
      <InputGroup>
        <Form.Item
          noStyle
          name={[question?.id || question.internalId || 1, 'street']}
        >
          <AutoComplete
            optionData={options}
            optionKey="street"
            placeholder="Address 1"
            onBlur={onBlur}
            onSelect={onSelect('street')}
          />
        </Form.Item>
      </InputGroup>
      <InputGroup>
        <Form.Item
          noStyle
          name={[question?.id || question.internalId || 1, 'streetExtra']}
        >
          <AutoComplete
            optionData={options}
            optionKey="streetExtra"
            placeholder="Address 2"
            onBlur={onBlur}
            onSelect={onSelect('streetExtra')}
          />
        </Form.Item>
      </InputGroup>
      <InputGroup>
        <Form.Item
          noStyle
          name={[question?.id || question.internalId || 1, 'city']}
        >
          <AutoComplete
            optionData={options}
            optionKey="city"
            placeholder="City"
            onBlur={onBlur}
            onSelect={onSelect('city')}
          />
        </Form.Item>
      </InputGroup>
      <InputGroup>
        <Form.Item
          noStyle
          name={[question?.id || question.internalId || 1, 'state']}
        >
          <Select
            className="overriden-select"
            placeholder="Select a state"
            onChange={triggerSelectChange as any}
            showSearch
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {states.map((state: string, idx: number) => (
              <Select.Option value={state} key={idx}>
                {state}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </InputGroup>
      <InputGroup>
        <Form.Item
          noStyle
          name={[question?.id || question.internalId || 1, 'zip']}
        >
          <AutoCompleteBase
            optionData={options}
            optionKey="zip"
            placeholder="ZIP"
            onBlur={onBlur}
            onSelect={onSelect('zip')}
            numericInput
          />
        </Form.Item>
      </InputGroup>
    </>
  );
};
