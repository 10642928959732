import React, { FC, useEffect, useMemo } from 'react';
import {
  TrainingListingElement,
  TrainingListingResponse,
  TrainingSubmissionStatus,
} from 'src/store/types';
import { TrainingCollapsiblePanelRow } from '../TrainingCollapsiblePanelRow';
import * as S from './Styles';

interface TrainingCollapsiblePanelProps {
  trainingList: TrainingListingResponse;
  handleLoadTraining: (training: TrainingListingElement) => void;
  handlePlayVideo: () => void;
}

export const TrainingCollapsiblePanel: FC<TrainingCollapsiblePanelProps> = ({
  trainingList,
  handleLoadTraining,
  handlePlayVideo,
}) => {
  const trainingsByTestType = useMemo(() => {
    return trainingList.records
      .sort((elementA: any, elementB: any) => {
        if (elementA.testTypeOrder - elementB.testTypeOrder === 0) {
          return elementA.chapter - elementB.chapter;
        }
        return elementA.testTypeOrder - elementB.testTypeOrder;
      })
      .reduce(
        (
          data: {
            testTypeMap: { [key: string]: TrainingListingElement[] };
            werePassed: boolean;
          },
          element: any,
        ) => {
          element.isAvailable = data.werePassed;
          if (data.testTypeMap[element.testTypeName]) {
            data.testTypeMap[element.testTypeName] = [
              ...data.testTypeMap[element.testTypeName],
              element,
            ];
          } else {
            data.testTypeMap[element.testTypeName] = [element];
          }
          return {
            testTypeMap: data.testTypeMap,
            werePassed:
              data.werePassed &&
              element.lastSubmissionStatus === TrainingSubmissionStatus.PASSED,
          };
        },
        { testTypeMap: {}, werePassed: true },
      ).testTypeMap;
  }, [trainingList]);

  const testTypeStatusMap = useMemo(() => {
    return Object.keys(trainingsByTestType).reduce(
      (testTypeStatusMap: Map<string, boolean>, testTypeKey: string) => {
        const testTypeTrainings = trainingsByTestType[testTypeKey];
        testTypeStatusMap.set(
          testTypeKey,
          testTypeTrainings.every(
            (tt) => tt.lastSubmissionStatus === TrainingSubmissionStatus.PASSED,
          ),
        );

        return testTypeStatusMap;
      },
      new Map<string, boolean>(),
    );
  }, [trainingList]);

  const showDisplayInstructions = useMemo(() => {
    for (const testTypeKey of Object.keys(trainingsByTestType)) {
      if (
        trainingsByTestType[testTypeKey].some((tt) => tt.lastSubmissionStatus)
      ) {
        return false;
      }
    }

    return true;
  }, [trainingList]);

  const nextTrainingElement = useMemo(() => {
    let nextPendingTestTypeKey: string | undefined = undefined;
    let nextTrainingListingElement: TrainingListingElement | undefined =
      undefined;

    for (const testTypeKey of Object.keys(trainingsByTestType)) {
      if (testTypeStatusMap.has(testTypeKey)) {
        const testTypeStatusCompleted = testTypeStatusMap.get(testTypeKey);
        if (!testTypeStatusCompleted) {
          nextPendingTestTypeKey = testTypeKey;
          break;
        }
      }
    }

    if (nextPendingTestTypeKey) {
      for (const training of trainingsByTestType[nextPendingTestTypeKey]) {
        if (
          !training.lastSubmissionStatus ||
          training.lastSubmissionStatus === TrainingSubmissionStatus.FAILED ||
          training.lastSubmissionStatus === TrainingSubmissionStatus.IN_PROGRESS
        ) {
          nextTrainingListingElement = training;
          break;
        }
      }
    }

    return nextTrainingListingElement;
  }, [trainingList]);

  useEffect(() => {
    if (nextTrainingElement) {
      handleLoadTraining(nextTrainingElement);
    } else {
      if (trainingList && trainingList.records.length > 0) {
        handleLoadTraining(trainingList.records[0]);
      }
    }
  }, [nextTrainingElement, trainingList]);

  return (
    <>
      <S.TrainingCollapsiblePanelContainer>
        <TrainingCollapsiblePanelRow
          sectionNumber={0}
          sectionName={'~Instructions'}
          sectionTrainings={[]}
          sectionOpened={showDisplayInstructions}
          nextTrainingId={nextTrainingElement?.id}
        />
        {Object.keys(trainingsByTestType).map(
          (trainingType: string, index: number) => {
            return (
              <TrainingCollapsiblePanelRow
                key={index + 1}
                sectionNumber={index + 1}
                sectionName={trainingType}
                sectionTrainings={trainingsByTestType[trainingType]}
                sectionOpened={!testTypeStatusMap.get(trainingType)!}
                nextTrainingId={nextTrainingElement?.id}
                handlePlayVideo={handlePlayVideo}
              />
            );
          },
        )}
      </S.TrainingCollapsiblePanelContainer>
    </>
  );
};
