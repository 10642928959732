import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import styled from 'styled-components';
import { Button } from 'src/components/Button';
import { TopNavbar } from 'src/components/TopNavbar';
import { TemplateListingTable } from 'src/features/risk-assessment/components/TemplateListingTable';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { FormCreateTemplate } from 'src/features/risk-assessment/components/FormCreateTemplate';
import { SearchInput } from 'src/components/SearchInput';
import { Dropdown } from 'src/components/Dropdown';
import { templatesLoadRequest } from 'src/store/actions/templateListing';
import { assessmentTypesLoadAllRequest } from 'src/store/actions/assessmentTypes';
import { TemplatesListingIndexedInterface, RootState } from 'src/store/types';
import {
  getTemplateLoader,
  getAssessmentTypes,
  getTemplateFoldersOptions,
  getTemplatesTree,
  getTemplatesFromState,
  getTemplatesTypesOptions,
} from 'src/store/selectors';

const TemplateListingContainer = styled.div`
  height: 65%;
  overflow: auto;
  margin-bottom: 10px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

const FormContainer = styled(Form)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  && .ant-select-selector {
    border: none;
    min-width: 200px;
    padding-top: 5px;
    height: 40px;

    && .ant-select-selection-search-input {
      height: 40px;
    }
  }
`;

export const TemplateListingPage: FC<unknown> = () => {
  const [templateSearchName, setTemplateSearchName] = useState('');
  const [folderSearchName, setFolderSearchName] = useState<string>('');
  const [templateTypeSearch, setTemplateTypeSearch] = useState<string>('');
  const [formPanelVisible, setFormPanelVisible] = useState(false);
  const [formPanelTitle, setFormPanelTitle] = useState('Create a Template');
  const [saveLabel, setSaveLabel] = useState<string>('Create');
  const [parentTemplateId, setParentTemplateId] = useState<number | null>(null);
  const [onlyDuplicate, setOnlyDuplicate] = useState<boolean>(false);

  const templates = useSelector(
    (state: RootState): TemplatesListingIndexedInterface =>
      getTemplatesTree(state, {
        title: templateSearchName,
        folder: folderSearchName,
        type: templateTypeSearch,
      }),
  );

  const allTemplates = useSelector((state: RootState) =>
    getTemplatesFromState(state),
  );
  const templateLoader = useSelector(getTemplateLoader);
  const assessmentTypes = useSelector(getAssessmentTypes);
  const templateFolders = useSelector(getTemplateFoldersOptions);
  const templateTypesOptions = useSelector(getTemplatesTypesOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(templatesLoadRequest());
    dispatch(assessmentTypesLoadAllRequest());
  }, []);

  useEffect(() => {
    if (templateLoader.completed && templateLoader.success) {
      setFormPanelVisible(false);
      templateForm.resetFields();
    }
  }, [templateLoader.completed]);

  const [templateForm] = Form.useForm();

  const handleSaveButtonPress = () => {
    templateForm.submit();
  };

  const showCloneTemplatePanel = (
    templateId: number,
    onlyDuplicate: boolean,
  ): void => {
    const template = allTemplates.find(({ id }) => id === templateId);
    if (template?.assessmentType)
      templateForm.setFieldsValue({
        assessmentTypeId: template.assessmentType.id,
      });
    setFormPanelTitle(
      onlyDuplicate ? 'Duplicate a template' : 'Create a new template',
    );
    setSaveLabel(onlyDuplicate ? 'Duplicate' : 'Create');
    setParentTemplateId(templateId);
    setOnlyDuplicate(onlyDuplicate);
    setFormPanelVisible(true);
  };

  return (
    <>
      <TopNavbar
        title="Templates"
        prevRoute={null}
        extraOptions={[
          <Button
            onClick={() => {
              setFormPanelTitle('Create a Template');
              setSaveLabel('Create');
              setParentTemplateId(null);
              setFormPanelVisible(true);
            }}
            title="Create Template"
            key={1}
          />,
        ]}
      />
      <PageContentContainer>
        <FormContainer layout="inline">
          <Form.Item label="Type">
            <Dropdown
              placeholder="Select a type"
              options={templateTypesOptions}
              onChange={(selectedValue) => setTemplateTypeSearch(selectedValue)}
            />
          </Form.Item>
          <Form.Item label="Folder">
            <Dropdown
              placeholder="Select a folder"
              options={templateFolders}
              onChange={(selectedValue) => setFolderSearchName(selectedValue)}
            />
          </Form.Item>
          <Form.Item label="Search">
            <SearchInput
              onChange={(e) => {
                setTemplateSearchName(e.target.value);
              }}
              placeholder="Template name"
            />
          </Form.Item>
        </FormContainer>

        <TemplateListingContainer>
          <TemplateListingTable
            templates={templates}
            showCloneTemplatePanel={showCloneTemplatePanel}
          />
        </TemplateListingContainer>
        <SlidingPanel
          title={formPanelTitle}
          visible={formPanelVisible}
          saveLabel={saveLabel}
          onClose={() => {
            setFormPanelVisible(false);
            templateForm.resetFields();
          }}
          onSave={handleSaveButtonPress}
          loading={templateLoader.loading}
        >
          <FormCreateTemplate
            templateForm={templateForm}
            assessmentTypes={assessmentTypes}
            parentTemplateId={parentTemplateId}
            templateFolders={templateFolders}
            onlyDuplicate={onlyDuplicate}
          />
        </SlidingPanel>
      </PageContentContainer>
    </>
  );
};
