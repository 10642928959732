/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getLoadedForms, getLoadedTemplate } from 'src/store/selectors';
import { FormQuestionsState, QuestionList } from 'src/store/types';
import { Button } from 'src/components/Button';
import { AutoComplete } from 'src/features/risk-assessment/components/FormFieldSettings/Autocomplete';
import { api } from 'src/api/services/ThApi';

interface TemplateEditorQuestionSelectorWindowProps {
  onSelection: (id: number) => void;
  onClose: () => void;
}

const Container = styled.div`
  width: 100%;

  .download-btn {
    margin-top: 5px;
    font-size: 12px;
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-weight: bold;
`;

const CustomAutocomplete = styled(AutoComplete)`
  width: 100%;
`;

export const TemplateEditorQuestionSelectorWindow: FC<
  TemplateEditorQuestionSelectorWindowProps
> = ({ onSelection, onClose }) => {
  const [questions, setQuestions] = useState<QuestionList>([]);
  const formWithQuestions = useSelector(getLoadedForms);
  const selectedTemplate = useSelector(getLoadedTemplate);

  const getMappedQuestions = (): QuestionList => {
    if (!formWithQuestions || formWithQuestions?.length === 0) {
      return [];
    }

    return formWithQuestions
      ?.filter((f: FormQuestionsState) => f.questions.length > 0)
      .reduce((tempQuestions: any, f: FormQuestionsState) => {
        return [...tempQuestions, ...f.questions];
      }, [] as QuestionList);
  };

  useEffect(() => {
    setQuestions(getMappedQuestions());
  }, [formWithQuestions]);

  const handleSelection = (id: number | undefined) => {
    if (id) {
      onSelection(id);
      onClose();
    }
  };

  return (
    <Container>
      <Title>Select a question to add</Title>

      {questions && questions.length > 0 && (
        <CustomAutocomplete
          initialTextValue=""
          handleQuestionChange={handleSelection}
          questions={questions}
        />
      )}

      <Button
        className="download-btn"
        title="Download question list"
        disabled={!selectedTemplate?.assessmentType}
        onClick={() =>
          selectedTemplate?.assessmentType &&
          api.getAssessmentTypeContentsAsJson(
            selectedTemplate.assessmentType.id,
          )
        }
      />
    </Container>
  );
};
