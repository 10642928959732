import React from 'react';
import { Dispatch } from 'redux';
import styled, { useTheme } from 'styled-components';
import { Menu as MenuAntD, Dropdown } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SettingOutlined } from 'src/theme/icons';
import { confirmDialog } from 'src/shared/utils';
import { RoutesEnum } from 'src/shared/enums';
import { ExamListingElement } from 'src/store/types';
import actions from 'src/store/actions';

export interface Props {
  exam: ExamListingElement;
}

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  width: 180px;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

const Menu = styled(MenuAntD)`
  border-radius: 4px;
  margin-top: -35px;
  margin-left: 12px;
  width: 180px;

  .ant-dropdown-menu-item:hover {
    &.title {
      background-color: white;
      cursor: default;
    }
  }

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightBlue.main}`};

    > span {
      font-weight: 600;
    }

    .title-icon {
      color: ${({ theme }) => theme.colors.lightBlue.main};
      font-size: 20px;
    }
  }

  .title-divider {
    color: red;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0px 4px;
    width: 172px;
  }

  span {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }
`;

const menu = (exam: ExamListingElement, dispatch: Dispatch, history: any) => (
  <Menu>
    <Menu.Item className="title" key="0">
      <span>Settings</span>
      <SettingOutlined className="title-icon" />
    </Menu.Item>
    <Menu.Item
      key="1"
      onClick={() => {
        if (exam.status !== 'ACTIVE') {
          history.push(`${RoutesEnum.EXAM_CONFIG}/${exam.id}`);
          return;
        }
        confirmDialog({
          title: 'This exam may already contain submissions',
          text: 'You can still edit name and fix typos but you cannot add, remove questions or choices. If you want to add or remove questions or choices, make a copy',
          onOk: () => {
            history.push(`${RoutesEnum.EXAM_CONFIG}/${exam.id}`);
          },
        });
      }}
    >
      <span>Edit content</span>
    </Menu.Item>
    <Menu.Item
      key="2"
      onClick={() => {
        confirmDialog({
          title: 'Make a copy',
          text: 'Making a copy will create a new draft exam.',
          onOk: () => {
            dispatch(actions.examCloneRequest(exam.id));
          },
        });
      }}
    >
      <span>Make a copy</span>
    </Menu.Item>
    {exam.status === 'ACTIVE' ? (
      <Menu.Item
        key="3"
        onClick={() => {
          confirmDialog({
            text: 'Ths action will archive the selected exam',
            onOk: () => {
              dispatch(actions.examArchiveRequest(exam.id));
            },
          });
        }}
      >
        <span>Archive</span>
      </Menu.Item>
    ) : (
      <Menu.Item
        key="3"
        onClick={() => {
          confirmDialog({
            text: 'Activating this exam version archives the current version with the same training type',
            onOk: () => {
              dispatch(actions.examActivateRequest(exam.id));
            },
          });
        }}
      >
        <span>Activate</span>
      </Menu.Item>
    )}
    <Menu.Divider />
    {exam.status === 'DRAFT' && (
      <Menu.Item
        key="4"
        onClick={() => {
          confirmDialog({
            text: 'Deleting a exam permanently removes it and its contents.',
            onOk: () => {
              dispatch(actions.examDeleteRequest(exam.id));
            },
          });
        }}
      >
        <span>Delete</span>
      </Menu.Item>
    )}
  </Menu>
);

const ExamSettingsMenu = ({ exam }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  return (
    <Dropdown
      disabled={exam.status === 'ARCHIVE'}
      // TODO: Update overlay to use the menu function
      overlay={menu(exam, dispatch, history)}
      trigger={['click']}
    >
      <DropdownIcon>
        <SettingOutlined
          style={{
            color:
              exam.status === 'ARCHIVE' ? 'gray' : theme.colors.lightBlue.main,
          }}
        />
      </DropdownIcon>
    </Dropdown>
  );
};

export default ExamSettingsMenu;
