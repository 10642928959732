import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { message, Divider, Input } from 'antd';
import { Choice } from 'src/store/types';
import { Button, IconButton } from 'src/components/Button';
import { CloseCircleOutlined, PlusOutlined } from 'src/theme/icons';
import { generateId } from 'src/shared/utils';

import { LinkButton } from './LinkButton';

export interface Tip {
  summary: string;
  details: string;
}

export interface TipsSettingsProps {
  value?: Choice[] | undefined;
  onChange?: (value: Choice[]) => void;
}

const Wrapper = styled.div`
  width: 100%;

  .newTipsContainer {
    > div,
    input {
      flex-grow: 1;
      width: 100%;
    }

    .linkButton {
      margin: 10px 0;
    }

    .actions {
      display: flex;
      justify-content: space-between;

      button {
        font-size: 14px;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const NewChoiceInput = styled(Input.TextArea)`
  margin: 10px 0;
  padding-left: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grayscale.middleGray};
`;

const NewTipDescriptionInput = styled(Input.TextArea)`
  margin: 10px 0;
  padding-left: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grayscale.middleGray};
`;

const emptyTip: Tip = {
  summary: '',
  details: '',
};

export const TipsSettings: FC<TipsSettingsProps> = ({ value, onChange }) => {
  const [newTip, setNewTip] = useState<string>('');
  const [newTipExtended, setNewTipExtended] = useState<string>('');
  const [newTipsForm, setNewTipsForm] = useState(false);

  const [currentTips, setCurrentTips] = useState(value ? value : []);

  const addNewTip = () => {
    if (!newTip || newTip.length === 0) {
      void message.error('Invalid Tip');
      return;
    }
    const newTipsObj = {
      internalId: generateId(),
      label: newTip.trim(),
      moreInfo: newTipExtended.trim(),
    };
    const newValue = [...currentTips, newTipsObj as Choice];
    setCurrentTips(newValue);
    triggerChange(newValue);
    // Clean up
    resetForm();
  };

  const resetForm = () => {
    setNewTipsForm(false);
    setNewTip('');
    setNewTipExtended('');
  };

  const deleteTip = (id: number) => {
    const newValues = currentTips.filter((c: Choice) => {
      if (c.id) {
        return c.id !== id;
      } else {
        return c.internalId !== String(id);
      }
    });
    setCurrentTips(newValues);
    triggerChange(newValues);
  };

  const showNewTipForm = () => {
    setNewTipsForm(true);
  };

  useEffect(() => {
    setCurrentTips(value ? value : []);
  }, [value]);

  const triggerChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Wrapper>
      {currentTips.map((c: Choice) => {
        const id = c.id ? c.id : c.internalId;
        return (
          <Row key={id}>
            <div>
              <span>{c.label}</span>
              {c.moreInfo && <p>{c.moreInfo}</p>}
            </div>
            <IconButton
              icon={<CloseCircleOutlined />}
              onClick={() => {
                deleteTip(id as number);
              }}
            />
          </Row>
        );
      })}
      {!newTipsForm && (
        <LinkButton
          icon={<PlusOutlined />}
          text="Add new Tip"
          onClick={() => {
            showNewTipForm();
          }}
        />
      )}
      {newTipsForm && (
        <>
          <Divider />
          <div className="newTipsContainer">
            <NewChoiceInput
              placeholder="Tip summary"
              autoFocus
              onChange={(e) => setNewTip(e.target.value)}
            />
            <NewChoiceInput
              placeholder="Tip extended (optional)"
              onChange={(e) => setNewTipExtended(e.target.value)}
            />

            <div className="actions">
              <Button
                variant="secondary"
                title="Cancel"
                onClick={() => {
                  setNewTipsForm(false);
                }}
              />
              <Button
                variant="secondary"
                title="Add"
                onClick={() => {
                  addNewTip();
                }}
              />
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};
