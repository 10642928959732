import moment from 'moment';
import { Rule } from 'antd/lib/form';

const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
export const questionToInputRulesMapping: { [k: string]: Rule } = {
  website: {
    pattern:
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    message: 'Must be a valid url',
  },
  email: { type: 'email' },
  phone: {
    transform: (value: string) => {
      const formattedValue = value.toString().replace(/\D/g, '');
      if (phoneRegex.test(formattedValue)) {
        return formattedValue.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
      } else if (formattedValue.length === 10) {
        return formattedValue.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
      } else if (formattedValue.length === 12) {
        return formattedValue.replace(
          /^(\d{3})-(\d{3})-(\d{4})$/,
          '($1) $2-$3',
        );
      } else {
        return value;
      }
    },
    pattern: phoneRegex,
    message: 'Must have (XXX) XXX-XXXX format',
  },
  number: {
    type: 'number',
    transform: (value: string): number => Number(value),
    message: 'Must be a number in valid range',
  },
  date: {
    transform: (value: moment.Moment): string =>
      value && value.format('MM/DD/YYYY'),
  },
  file: { type: 'array' },
  address: { type: 'object' },
  'full-name': { type: 'object' },
  radio: { type: 'object' },
  'yes-no': { type: 'string' },
  'contact-info': { type: 'object' },
  select: { type: 'string' },
  checkbox: { type: 'object' },
};
