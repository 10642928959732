import styled, { css } from 'styled-components';
import { Input as InputAntd } from 'antd';
import { remConvert } from 'src/theme/utils';
import { InputProps } from './TextInput';

export const baseInputStyle = css<InputProps>`
  background: ${({ theme }): string => theme.colors.white.main};
  border: ${remConvert(1)} solid rgba(0, 0, 0, 0.3);
  border-radius: ${remConvert(4)};
  height: ${remConvert(40)};
`;

export const InputStyled = styled(InputAntd)<InputProps>`
  ${baseInputStyle}
`;

export const InputPasswordStyled = styled(InputStyled.Password)<InputProps>`
  ${baseInputStyle}
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;

  .as-placeholder,
  .as-label {
    z-index: 10;
  }

  .as-placeholder {
    color: ${({ theme }): string => theme.colors.grayscale.darkGray};
  }

  .as-label {
    top: -8px;
    font-size: 12px !important;
    background: ${({ theme }): string => theme.colors.white.main};
    padding: 0 ${remConvert(4)};
    margin-left: -4px;
    font-family: ${({ theme }): string => theme.fontFamilies.primary};
    font-style: ${({ theme }): string => theme.fontStyles.normal};
    font-weight: ${({ theme }): number => theme.fontWeights.semiBold};
    font-size: 12px;
    line-height: ${remConvert(16)};
    color: rgba(28, 36, 40, 0.65);
  }

  label {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    position: absolute;
    pointer-events: none;
    left: ${remConvert(12)};
    top: ${remConvert(11)};
    transition: 0.2s ease all;
  }

  > div {
    display: flex;
    align-items: flex-start;
    height: ${remConvert(14)};
    margin-top: ${remConvert(8)};
  }

  .error {
    color: ${({ theme }) => theme.colors.red.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 12px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    letter-spacing: 0.05em;
    line-height: ${remConvert(14)};
  }
`;
