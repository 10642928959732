/* eslint-disable no-console */
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt, useHistory, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { message, Form } from 'antd';
import { Button } from 'src/components/Button';
import { TopNavbar } from 'src/components/TopNavbar';
import {
  FormState,
  RootState,
  QuestionType,
  AssessmentTypeState,
  Loader,
} from 'src/store/types';
import {
  getFormById,
  getSelectedForm,
  getSelectedQuestion,
  getFormLoader,
} from 'src/store/selectors/form';
import {
  getFormsFromState,
  getFormListingLoader,
} from 'src/store/selectors/formListing';
import { SidePanel } from 'src/components/SidePanel';
import { FieldsCatalogue } from 'src/features/risk-assessment/components/FieldsCatalogue';
import { QuestionWorkbench } from 'src/features/risk-assessment/components/QuestionWorkbench';
import {
  setSelectedForm,
  formAddNewQuestion,
  formSetQuestionSelectedReset,
  formSaveEditedQuestion,
  formUpdateRequest,
  formLoadQuestionsRequest,
  formMarkAsTouched,
} from 'src/store/actions/form';
import { formsLoadRequest } from 'src/store/actions/formListing';
import { questionTypeLoadRequest } from 'src/store/actions/questionType';
import {
  FormFieldSettings,
  questionToSettingsMapping,
} from 'src/features/risk-assessment/components/FormFieldSettings';
import { EyeOutlined } from 'src/theme/icons';
import { questionsLoadRequest } from 'src/store/actions/questionListing';
import { constantsLoadRequest } from 'src/store/actions/constantListing';
import {
  assessmentTypesLoadDelegatesRequest,
  assessmentTypesLoadLabelsRequest,
} from 'src/store/actions/assessmentTypes';

interface RouteParams {
  id: string;
}

const Columns = styled.div`
  display: flex;
`;

const PreviewContainer = styled.div`
  span {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 16px;
  }

  .link {
    margin-left: 10px;
  }
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const FormEditPage: FC<unknown> = () => {
  const [selectedNewQuestion, setSelectedNewQuestion] =
    useState<QuestionType | null>(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const [fieldSettingsForm] = Form.useForm();

  const { id } = useParams<RouteParams>();
  const form = useSelector((state: RootState): FormState | undefined =>
    getFormById(state, id),
  );
  const forms = useSelector((state: RootState): FormState[] =>
    getFormsFromState(state),
  );
  const formListingLoader = useSelector(
    (state: RootState): Loader => getFormListingLoader(state),
  );
  const selectedForm = useSelector(getSelectedForm);
  const selectedQuestion = useSelector(getSelectedQuestion);
  const formLoader = useSelector(getFormLoader);
  const isEditing = selectedQuestion !== undefined;

  useEffect(() => {
    window.onbeforeunload = (e: Event) => {
      if (!selectedForm.metadata.isPristine) {
        e.preventDefault();
        return true;
      }
    };
  });

  useEffect(() => {
    if (forms.length === 0) {
      dispatch(formsLoadRequest(true));
    }
    const formId = Number(id);
    if (selectedForm?.form.id !== formId) {
      dispatch(formLoadQuestionsRequest(formId));
    }
    dispatch(constantsLoadRequest());
  }, []);

  useEffect(() => {
    if (form?.id && form.id !== selectedForm.form.id) {
      dispatch(setSelectedForm(form));
      const formAssessmentType = form.assessmentType as AssessmentTypeState;
      dispatch(questionsLoadRequest(formAssessmentType.id));
      dispatch(questionTypeLoadRequest());
      dispatch(assessmentTypesLoadLabelsRequest(formAssessmentType.id));
      dispatch(assessmentTypesLoadDelegatesRequest(formAssessmentType.id));
    }
  }, [form]);

  useEffect(() => {
    if (
      selectedForm.metadata.saveCompleted &&
      form?.id === selectedForm.form.id
    ) {
      void message.success('Form saved successfully');
      dispatch(formLoadQuestionsRequest(selectedForm.form.id));
    }
  }, [selectedForm.metadata.saveCompleted]);

  // Updating form values when changing selection
  useEffect(() => {
    if (selectedQuestion) {
      const qs = questionToSettingsMapping(selectedQuestion);
      fieldSettingsForm.setFieldsValue(qs);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (formListingLoader.completed && (!form || form.id === 0)) {
      history.push('/forms');
    }
  }, [formListingLoader.completed]);

  const onCancel = (): void => {
    history.push('/forms');
  };

  const handleAdd = () => {
    if (selectedNewQuestion && form) {
      dispatch(formAddNewQuestion(selectedNewQuestion, form.id));
    }
  };

  const handleSelection = (newQuestion: QuestionType | null) => {
    setSelectedNewQuestion(newQuestion);
  };

  const handleFieldEdit = () => {
    fieldSettingsForm.submit();
  };

  const handleSettingSubmission = (question: any) => {
    if (selectedQuestion) {
      dispatch(formSaveEditedQuestion(selectedQuestion, question));
      dispatch(formMarkAsTouched());
      resetQuestionSelection();
    }
  };

  const resetQuestionSelection = () => {
    dispatch(formSetQuestionSelectedReset());
  };

  const handleSaveForm = () => {
    dispatch(
      formUpdateRequest(
        selectedForm.form,
        selectedForm.questions,
        selectedForm.metadata,
      ),
    );
    resetQuestionSelection();
  };

  const handleClientNavigation = (location: { pathname: string }, _: any) =>
    location.pathname.startsWith('/forms/preview/')
      ? true
      : 'You have unsaved changes. Are you sure you want to leave?';

  return (
    <>
      <Prompt
        when={!selectedForm.metadata.isPristine}
        message={handleClientNavigation}
      />
      <TopNavbar
        title={form?.label || ''}
        prevRoute={{ name: 'Forms', url: '/forms' }}
        extraOptions={[
          <Button
            onClick={onCancel}
            title="Cancel"
            variant="secondary"
            key={1}
          />,
          <Button
            onClick={handleSaveForm}
            title="Save"
            key={2}
            disabled={selectedForm.metadata.isPristine}
            loading={formLoader.loading}
          />,
        ]}
        sticky
      />
      <PageContentContainer>
        <PreviewContainer>
          <EyeOutlined />
          <Link to={`/forms/preview/${id}`}>
            <span className="link">Preview</span>
          </Link>
        </PreviewContainer>
        <Columns>
          <QuestionWorkbench form={selectedForm} />
          <SidePanel
            fixed
            onConfirm={isEditing ? handleFieldEdit : handleAdd}
            confirmLabel={isEditing ? 'Save' : 'Add to form'}
            confirmEnabled={selectedNewQuestion !== null || isEditing}
          >
            {isEditing && selectedQuestion ? (
              <FormFieldSettings
                form={fieldSettingsForm}
                key={selectedQuestion.id || selectedQuestion.internalId}
                onSubmit={handleSettingSubmission}
                onBackPress={resetQuestionSelection}
                question={selectedQuestion}
              />
            ) : (
              <FieldsCatalogue onSelection={handleSelection} />
            )}
          </SidePanel>
        </Columns>
      </PageContentContainer>
    </>
  );
};
