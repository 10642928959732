/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { interviewFileDownloadRequest } from 'src/store/actions/interview';
import {
  getAnswerValueByQuestionType,
  getAnswersMapByQuestions,
} from 'src/store/selectors';
import {
  AnswerListingIndexedItem,
  FileData,
  Question,
  QuestionTypes,
  RootState,
  SelectedFormState,
} from 'src/store/types';
import { DownloadOutlined } from 'src/theme/icons';
import styled from 'styled-components';

interface InterviewWorkbenchProps {
  selectedForm: SelectedFormState;
}

const InterviewWorkbenchPropsContainer = styled.div`
  width: calc(100% - ${({ theme }) => `${theme.sidebar.width}px`});
  padding: 24px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 22px;
  font-weight: bold;
`;

const Row = styled.div`
  p {
    margin-bottom: 2px;
  }

  .question {
    font-weight: bold;
    margin-top: 10px;
  }

  .answer {
    span {
    }
  }

  .download {
    cursor: pointer;
  }

  .no-answer {
    font-style: italic;
    color: #444;
  }

  .condition-hidden {
    text-decoration: line-through;
  }
`;

export const InterviewReport: FC<InterviewWorkbenchProps> = ({
  selectedForm,
}) => {
  const dispatch = useDispatch();
  const answers = useSelector(
    (state: RootState): AnswerListingIndexedItem =>
      getAnswersMapByQuestions(state),
  );
  const questions = selectedForm.questions || [];

  const handleDownload = (f: FileData): undefined => {
    dispatch(interviewFileDownloadRequest(f));
    return undefined;
  };

  const renderQuestionReport = () => {
    const render = questions.map((q: Question) => {
      if (!q.id) {
        return null;
      }
      const existingAnswer = answers[q?.id];
      let answer = <p className="no-answer">(No answer)</p>;
      if (existingAnswer) {
        if (existingAnswer.value) {
          switch (q?.type.key) {
            case QuestionTypes.FILE: {
              const files = existingAnswer.value
                ? (JSON.parse(existingAnswer.value) as Array<FileData>)
                : [];

              if (files && files.length) {
                const fileLinks = files.map((f: any) => {
                  return (
                    <p className="answer download" key={f.key}>
                      <DownloadOutlined />{' '}
                      <span onClick={() => handleDownload(f)}>
                        Download {f.name}
                      </span>
                    </p>
                  );
                });
                answer = <>{fileLinks}</>;
              }

              break;
            }
            case QuestionTypes.ADDRESS: {
              const obj = existingAnswer.value
                ? JSON.parse(existingAnswer.value)
                : {};
              answer = (
                <div className="answer">
                  <ul>
                    <li>
                      <span>Street:</span> {obj.street}
                    </li>
                    <li>
                      <span>Line 2:</span> {obj.streetExtra}
                    </li>
                    <li>
                      <span>City:</span> {obj.city}
                    </li>
                    <li>
                      <span>State:</span> {obj.state}
                    </li>
                    <li>
                      <span>Zip code:</span> {obj.zip}
                    </li>
                  </ul>
                </div>
              );
              break;
            }
            case QuestionTypes.CONTACT_INFO: {
              const obj = existingAnswer.value
                ? JSON.parse(existingAnswer.value)
                : {};
              answer = (
                <div className="answer">
                  <ul>
                    <li>
                      <span>Name:</span> {obj.firstName}, {obj.lastName}
                    </li>
                    <li>
                      <span>Title:</span> {obj.title}
                    </li>
                    <li>
                      <span>Email:</span> {obj.email}
                    </li>
                    <li>
                      <span>Phone:</span> {obj.phone}
                    </li>
                  </ul>
                </div>
              );
              break;
            }
            case QuestionTypes.RADIO: {
              const realAnswer = getAnswerValueByQuestionType(
                q.type,
                existingAnswer,
              );
              answer = (
                <p className="answer">
                  {realAnswer as any /*TODO remove any*/}
                </p>
              );
              break;
            }
            case QuestionTypes.CHECKBOX: {
              const realAnswer = getAnswerValueByQuestionType(
                q.type,
                existingAnswer,
              );
              const arrayValue =
                realAnswer && Array.isArray(realAnswer)
                  ? realAnswer.join(', ')
                  : `${realAnswer}`;
              answer = <p className="answer">{arrayValue}</p>;
              break;
            }
            default: {
              answer = <p className="answer">{existingAnswer.value}</p>;
            }
          }
        }
      }
      return (
        <Row key={q?.id}>
          {q?.question && <p className="question">{q.question}</p>}
          {q?.description && <p className="description">{q.description}</p>}
          {answer}
        </Row>
      );
    });
    return render;
  };

  return (
    <InterviewWorkbenchPropsContainer>
      <Title>{selectedForm.form.label}</Title>
      {renderQuestionReport()}
    </InterviewWorkbenchPropsContainer>
  );
};
