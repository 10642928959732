import {
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_REQUEST_FAILED,
  CATEGORY_CREATE_REQUEST_SUCCESS,
  CATEGORY_DELETE_FAILED,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LOAD_REQUEST,
  CATEGORY_LOAD_REQUEST_FAILED,
  CATEGORY_LOAD_REQUEST_SUCCESS,
  CATEGORY_REORDER_FAILED,
  CATEGORY_REORDER_REQUEST,
  CATEGORY_REORDER_SUCCESS,
  CATEGORY_UPDATE_FAILED,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CategoryCreateRequestAction,
  CategoryCreateRequestFailedAction,
  CategoryCreateRequestSuccessAction,
  CategoryDeleteFailedAction,
  CategoryDeleteRequestAction,
  CategoryDeleteSuccessAction,
  CategoryFromResponse,
  CategoryLoadRequestAction,
  CategoryLoadRequestFailedAction,
  CategoryLoadRequestSuccessAction,
  CategoryReOrderFailedAction,
  CategoryReOrderRequestAction,
  CategoryReOrderSuccessAction,
  CategoryState,
  CategoryUpdateFailedAction,
  CategoryUpdateRequestAction,
  CategoryUpdateSuccessAction,
} from '../types';
import { showErrorMessage } from './error';

export const categoryCreateRequest = (
  name: string,
): CategoryCreateRequestAction => ({
  type: CATEGORY_CREATE_REQUEST,
  name,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const categoryCreateRequestSuccess = (
  category: any,
): CategoryCreateRequestSuccessAction => ({
  type: CATEGORY_CREATE_REQUEST_SUCCESS,
  category,
});

export const categoryCreateRequestFailed = (
  errorMessage: string,
  originalError: Error,
): CategoryCreateRequestFailedAction =>
  showErrorMessage(CATEGORY_CREATE_REQUEST_FAILED, errorMessage, originalError);

export const categoryLoadRequest = (): CategoryLoadRequestAction => ({
  type: CATEGORY_LOAD_REQUEST,
});

export const categoryLoadRequestSuccess = (
  categories: any[],
): CategoryLoadRequestSuccessAction => ({
  type: CATEGORY_LOAD_REQUEST_SUCCESS,
  categories,
});

export const categoryLoadRequestFailed = (
  errorMessage: string,
  originalError: Error,
): CategoryLoadRequestFailedAction =>
  showErrorMessage(CATEGORY_LOAD_REQUEST_FAILED, errorMessage, originalError);

export const categoryReOrder = (
  categories: CategoryState[],
): CategoryReOrderRequestAction => ({
  type: CATEGORY_REORDER_REQUEST,
  categories,
});

export const categoryReOrderSuccess = (): CategoryReOrderSuccessAction => ({
  type: CATEGORY_REORDER_SUCCESS,
});

export const categoryReOrderFailed = (
  errorMessage: string,
  originalError: Error,
): CategoryReOrderFailedAction =>
  showErrorMessage(CATEGORY_REORDER_FAILED, errorMessage, originalError);

export const categoryDeleteRequest = (
  categoryId: number,
): CategoryDeleteRequestAction => ({
  type: CATEGORY_DELETE_REQUEST,
  categoryId,
});

export const categoryDeleteSuccess = (
  categoryId: number,
): CategoryDeleteSuccessAction => ({
  type: CATEGORY_DELETE_SUCCESS,
  categoryId,
});

export const categoryDeleteFailed = (
  errorMessage: string,
  originalError: Error,
): CategoryDeleteFailedAction =>
  showErrorMessage(CATEGORY_DELETE_FAILED, errorMessage, originalError);

export const categoryUpdateRequest = (
  categoryId: number,
  name: string,
): CategoryUpdateRequestAction => ({
  type: CATEGORY_UPDATE_REQUEST,
  categoryId,
  name,
});

export const categoryUpdateSuccess = (
  category: CategoryFromResponse,
): CategoryUpdateSuccessAction => ({
  type: CATEGORY_UPDATE_SUCCESS,
  category,
});

export const categoryUpdateFailed = (
  errorMessage: string,
  originalError: Error,
): CategoryUpdateFailedAction =>
  showErrorMessage(CATEGORY_UPDATE_FAILED, errorMessage, originalError);
