import { showErrorMessage } from 'src/store/actions/error';
import {
  TrainingTypesLoadErrorAction,
  TrainingTypesLoadRequestAction,
  TrainingTypesLoadSuccessAction,
  TrainingTypesResponse,
  TRAINING_TYPES_LOAD_FAILED,
  TRAINING_TYPES_LOAD_REQUEST,
  TRAINING_TYPES_LOAD_SUCCESS,
} from '../types';

export const trainingTypesLoadRequest = (): TrainingTypesLoadRequestAction => ({
  type: TRAINING_TYPES_LOAD_REQUEST,
});

export const trainingTypesLoadSuccess = (
  trainingTypes: TrainingTypesResponse,
): TrainingTypesLoadSuccessAction => ({
  type: TRAINING_TYPES_LOAD_SUCCESS,
  trainingTypes,
});

export const trainingTypesLoadError = (
  errorMessage: string,
  originalError?: Error,
): TrainingTypesLoadErrorAction =>
  showErrorMessage(TRAINING_TYPES_LOAD_FAILED, errorMessage, originalError);
