import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { centeredFlex, device, semiboldOpenSans } from 'src/theme';

export const RiskAssessmentCompletedContainer = styled.div`
  ${centeredFlex}
  flex-direction: column;
  padding: ${remConvert(24)} ${remConvert(32)};
  gap: ${remConvert(32)};
  background: ${({ theme }) => theme.colors.white.main};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;

  > div:first-of-type {
    display: none;

    @media ${device.xl} {
      display: block;
    }
  }

  @media ${device.xl} {
    flex-direction: row;
    width: ${remConvert(714)};
    min-height: ${remConvert(200)};
  }

  h2 {
    ${semiboldOpenSans}
    ${centeredFlex}
    color: ${({ theme }) => theme.colors.black.shark};
    font-size: 23px;
  }

  .riskAssessmentReport {
    display: flex;
    justify-content: space-between;
  }

  .riskAssessmentSubtitle {
    ${semiboldOpenSans}
    ${centeredFlex}
    line-height: ${remConvert(25)};
    color: ${({ theme }) => theme.colors.black.shark};
    font-size: 18px;
  }
`;
