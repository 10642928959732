import styled from 'styled-components';
import { TextInput } from 'src/components/TextInput';
import { centeredFlex, device } from 'src/theme';
import { remConvert } from 'src/theme/utils';

export const QRImage = styled.img`
  margin: ${remConvert(0)} !important;
  border: ${remConvert(1)} solid rgba(0, 0, 0, 0.1);
`;

export const AppList = styled.ul`
  list-style: none;
  padding: ${remConvert(0)} !important;
`;

export const AppListItem = styled.li`
  font-size: 11px;
  padding: ${remConvert(5)} ${remConvert(0)} ${remConvert(5)} ${remConvert(0)};
`;

export const QRContainer = styled.div`
  width: 100%;
  ${centeredFlex}
  flex-direction: column;
`;

export const AppIconImage = styled.img`
  margin: ${remConvert(0)} !important;
`;

export const HelpStepContainer = styled.div`
  display: flex;
  margin-bottom: ${remConvert(15)};
`;

export const HelpStepDescriptor = styled.span`
  ${centeredFlex}
  justify-content: center;
  padding-left: ${remConvert(10)};
  padding-right: ${remConvert(0)};
`;

export const HelpLastStepContainer = styled.div`
  display: flex;
`;

export const HelpStepNumber = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${remConvert(68)};
  font-size: 50px;
  text-align: center;
  color: rgba(28, 36, 40, 0.5);
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center !important;
  padding: ${remConvert(0)} !important;
  height: ${remConvert(200)};
`;

export const TextInputWrapper = styled(TextInput)`
  margin: auto;
  display: block;
  margin-bottom: ${remConvert(32)};
  margin-top: ${remConvert(10)};
`;

export const HelpSectionContainer = styled.div`
  padding: ${remConvert(0)} !important;
  ${centeredFlex}
  flex-direction: column;

  @media ${device.md} {
    flex-direction: row;
  }
`;

export const AppListTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 11px;
  line-height: ${remConvert(25)};
  color: ${({ theme }) => theme.colors.black.shark};
`;

export const AppContainer = styled.div`
  width: 100%;

  @media ${device.lg} {
    width: ${remConvert(519)};
  }

  p {
    margin: ${remConvert(10)} ${remConvert(0)};
  }

  img {
    margin: ${remConvert(20)};
  }

  > div {
    padding: ${remConvert(16)} ${remConvert(0)};
    display: flex;
    align-items: flex-start;

    input,
    .ant-input-affix-wrapper {
      width: 100%;

      @media ${device.md} {
        width: ${remConvert(400)};
      }
    }
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: ${remConvert(16)};
  cursor: pointer;
  text-align: center;
  width: 100%;

  @media ${device.lg} {
    text-align: right;
  }
`;
