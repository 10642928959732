import { createSelector } from 'reselect';
import {
  RootState,
  User,
  UserListingFilters,
  UserListingIndexedItem,
  UserRole,
} from '../types';

export const getAdminUsersFromState = (state: RootState): User[] => {
  const users: User[] = Object.values(state.userListing.users);
  return users;
};

export const getAdminUsers = createSelector(
  (state: RootState) => getAdminUsersFromState(state),
  (_: RootState, { offset }: UserListingFilters) => offset || 0,
  (_: RootState, { limit }: UserListingFilters) => limit,
  (users: User[], offset: number, limit: number) => {
    const indexedUsers: UserListingIndexedItem = users
      .slice(offset, limit)
      .reduce((users: UserListingIndexedItem, user: User) => {
        return {
          ...users,
          [user.id]: user,
        };
      }, {});

    return {
      data: indexedUsers,
      totalItems: users.length,
    };
  },
);

export const getSelectedAdminUser = createSelector(
  (state: RootState) => state.userListing.users,
  (_: RootState, id: number | undefined) => id ?? 0,
  (indexedUsers: UserListingIndexedItem, id: number) => indexedUsers[id],
);
