import { Loader, ShowErrorAction } from '.';
import { FormState } from './form';
import { SortableItem } from './utils';

export const CATEGORY_LOAD_REQUEST = 'CATEGORY_LOAD_REQUEST';
export const CATEGORY_LOAD_REQUEST_SUCCESS = 'CATEGORY_LOAD_REQUEST_SUCCESS';
export const CATEGORY_LOAD_REQUEST_FAILED = 'CATEGORY_LOAD_REQUEST_FAILED';
export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST';
export const CATEGORY_CREATE_REQUEST_SUCCESS =
  'CATEGORY_CREATE_REQUEST_SUCCESS';
export const CATEGORY_CREATE_REQUEST_FAILED = 'CATEGORY_CREATE_REQUEST_FAILED';
export const CATEGORY_REORDER_REQUEST = 'CATEGORY_REORDER_REQUEST';
export const CATEGORY_REORDER_SUCCESS = 'CATEGORY_REORDER_SUCCESS';
export const CATEGORY_REORDER_FAILED = 'CATEGORY_REORDER_FAILED';
export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAILED = 'CATEGORY_DELETE_FAILED';
export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAILED = 'CATEGORY_UPDATE_FAILED';

export interface CategoryFromResponse extends SortableItem {
  id: number;
  name: string;
}

export interface Category {
  id: number;
  name: string;
  weight: number;
}

export interface CategoryState extends Category {
  forms?: FormState[];
}

export type CategoryInput = Category;

export interface CategoryListingIndexedItem {
  [key: string]: CategoryState;
}

export interface CategoryListingState {
  categories: CategoryListingIndexedItem;
  loader: Loader;
}

export interface CategoryLoadRequestAction {
  type: typeof CATEGORY_LOAD_REQUEST;
}

export interface CategoryLoadRequestSuccessAction {
  type: typeof CATEGORY_LOAD_REQUEST_SUCCESS;
  categories: CategoryFromResponse[];
}

export type CategoryLoadRequestFailedAction = ShowErrorAction<
  typeof CATEGORY_LOAD_REQUEST_FAILED
>;

export interface CategoryCreateRequestAction {
  type: typeof CATEGORY_CREATE_REQUEST;
  name: string;
}

export interface CategoryCreateRequestSuccessAction {
  type: typeof CATEGORY_CREATE_REQUEST_SUCCESS;
  category: CategoryFromResponse;
}

export type CategoryCreateRequestFailedAction = ShowErrorAction<
  typeof CATEGORY_CREATE_REQUEST_FAILED
>;

export interface CategoryReOrderRequestAction {
  type: typeof CATEGORY_REORDER_REQUEST;
  categories: CategoryState[];
}

export interface CategoryReOrderSuccessAction {
  type: typeof CATEGORY_REORDER_SUCCESS;
}

export type CategoryReOrderFailedAction = ShowErrorAction<
  typeof CATEGORY_REORDER_FAILED
>;

export interface CategoryDeleteRequestAction {
  type: typeof CATEGORY_DELETE_REQUEST;
  categoryId: number;
}

export interface CategoryDeleteSuccessAction {
  type: typeof CATEGORY_DELETE_SUCCESS;
  categoryId: number;
}

export type CategoryDeleteFailedAction = ShowErrorAction<
  typeof CATEGORY_DELETE_FAILED
>;

export interface CategoryUpdateRequestAction {
  type: typeof CATEGORY_UPDATE_REQUEST;
  categoryId: number;
  name: string;
}

export interface CategoryUpdateSuccessAction {
  type: typeof CATEGORY_UPDATE_SUCCESS;
  category: CategoryFromResponse;
}

export type CategoryUpdateFailedAction = ShowErrorAction<
  typeof CATEGORY_UPDATE_FAILED
>;

export type CategoryActionTypes =
  | CategoryLoadRequestAction
  | CategoryLoadRequestSuccessAction
  | CategoryLoadRequestFailedAction
  | CategoryCreateRequestAction
  | CategoryCreateRequestSuccessAction
  | CategoryCreateRequestFailedAction
  | CategoryReOrderRequestAction
  | CategoryReOrderSuccessAction
  | CategoryReOrderFailedAction
  | CategoryDeleteRequestAction
  | CategoryDeleteSuccessAction
  | CategoryDeleteFailedAction
  | CategoryUpdateRequestAction
  | CategoryUpdateSuccessAction
  | CategoryUpdateFailedAction;
