import React, { FC } from 'react';
import { TagProps } from 'antd/lib/tag';
import { Tag } from './Styles';

interface GridTagsProps {
  type: string;
  children: React.ReactNode;
  extras?: TagProps[];
}

export const GridTag: FC<GridTagsProps> = (props) => (
  <Tag className={props.type} {...props.extras}>
    {props.children}
  </Tag>
);
