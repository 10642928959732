import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { TextArea } from 'src/components/TextArea';
import { TextInput } from 'src/components/TextInput';
import { formSaveRequest, formUpdateRequest } from 'src/store/actions/form';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import {
  AssessmentTypeListingIndexedItem,
  AssessmentTypeState,
  CategoryState,
  FormState,
} from 'src/store/types';

export interface FormCreateFormProps {
  form: FormInstance;
  formToUpdateId?: number;
  assessment: AssessmentTypeListingIndexedItem;
  categories: CategoryState[];
}

interface FormValues {
  label: string;
  description: string;
  categoryId: number;
  assessmentTypeId: number;
}

const FormContainer = styled(Form)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
      }
    }
  }
`;

export const FormCreateForm: FC<FormCreateFormProps> = ({
  form,
  assessment,
  formToUpdateId,
  categories,
}) => {
  const dispatch = useDispatch();

  const { user } = useAuth();

  const handleSubmit = (values: FormValues) => {
    const form = {
      label: values?.label.trim(),
      description: values?.description.trim(),
      assessmentTypeId: values?.assessmentTypeId,
      creator: user.id,
      categoryId: values.categoryId,
    };

    if (formToUpdateId) {
      dispatch(
        formUpdateRequest({
          id: formToUpdateId,
          ...form,
        } as unknown as FormState),
      );
    } else {
      dispatch(formSaveRequest(form));
    }
    return null;
  };

  return (
    <FormContainer
      form={form}
      onFinish={(values) => handleSubmit(values as FormValues)}
    >
      <Form.Item
        name="label"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please input the form title',
          },
        ]}
      >
        <TextInput placeholder="Title" />
      </Form.Item>
      <Form.Item
        name="description"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please write a short form description',
          },
        ]}
      >
        <TextArea placeholder="Description" rows={6} />
      </Form.Item>
      <Form.Item
        name="categoryId"
        rules={[
          {
            required: true,
            message: 'Please input the form category',
          },
        ]}
      >
        <Select placeholder="Select a valid category">
          {Object.values(categories).map((a: CategoryState) => (
            <Select.Option value={a.id} key={a.id}>
              {a.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="assessmentTypeId"
        rules={[
          { required: true, message: 'Please select a valid assessment' },
        ]}
      >
        <Select placeholder="Select a valid assessment type">
          {Object.values(assessment).map((a: AssessmentTypeState) => (
            <Select.Option value={a.id} key={a.id}>
              {a.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </FormContainer>
  );
};
