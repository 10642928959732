import { centeredFlex, device, normalOpenSans } from 'src/theme';
import { remConvert } from 'src/theme/utils';
import styled, { css } from 'styled-components';

const textStyles = css`
  ${normalOpenSans}
  font-weight: 600;
`;

export const FailureText = styled.span`
  ${textStyles}
  font-size: 35px;
  line-height: ${remConvert(48)};
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.colors.sunglo.main};
  margin-left: ${remConvert(18)};
`;

export const SuccessText = styled.span`
  ${textStyles}
  font-size: 35px;
  line-height: ${remConvert(48)};
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.colors.astral.main};
  margin-left: ${remConvert(18)};
`;

export const SummaryText = styled.div`
  ${textStyles}
  font-size: 20px;
  line-height: ${remConvert(27)};
  color: rgba(0, 0, 0, 0.6);
  margin-top: ${remConvert(24)};
  margin-bottom: ${remConvert(24)};

  @media ${device.md} {
    width: ${remConvert(340)};
  }
`;

export const SummaryStatus = styled.div`
  ${centeredFlex}
`;

export const QuizSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${remConvert(1)} solid lightgray;
  padding-bottom: ${remConvert(24)};
  flex-direction: column;

  @media ${device.lg} {
    flex-direction: row;
  }
`;

export const RadialChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
