import { FormInstance } from 'antd/lib/form';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  interviewDeleteAnswer,
  interviewSaveAnswer,
} from 'src/store/actions/interview';
import {
  Question,
  Answer,
  AssigneeState,
  AnswerData,
  AssessmentRiskAnalysisItem,
} from 'src/store/types';
import {
  InterviewQuestionFactory,
  QuestionFactoryType,
} from '../InterviewQuestions';

interface InterviewQuestionProps {
  question: Question;
  answer: Answer;
  suggestions: string[];
  userId: number;
  interviewId: number;
  assignee?: AssigneeState;
  form: FormInstance;
  assessmentRiskAnalysisItem?: AssessmentRiskAnalysisItem;
}

const InterviewQuestion = React.memo((props: InterviewQuestionProps) => {
  const {
    question,
    answer,
    suggestions,
    userId,
    interviewId,
    assignee,
    form,
    assessmentRiskAnalysisItem,
  } = props;
  const { id = 0, internalId, type } = question;

  const dispatch = useDispatch();
  const updateAnswer = useCallback(
    (answerData: AnswerData) => {
      if (answerData.value || answerData.fileIds || answerData.choiceIds) {
        return dispatch(
          interviewSaveAnswer(interviewId, id, userId, {
            ...answerData,
            question,
          }),
        );
      }
      if (answerData.id || answerData.internalId) {
        return dispatch(interviewDeleteAnswer(answerData));
      }
    },
    [dispatch, userId, interviewId],
  );

  const questionFactory = useMemo(
    () => InterviewQuestionFactory(type.key, QuestionFactoryType.interview),
    [type],
  );

  const Component = React.createElement(questionFactory, {
    question,
    key: id || internalId,
    form,
    answer,
    suggestions,
    interviewId,
    assignee,
    updateAnswer,
    assessmentRiskAnalysisItem,
  });

  return Component;
});

export default InterviewQuestion;
