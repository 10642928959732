import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { LoaderComponent } from 'src/components/Loader';
import {
  getCurrentSubmission,
  getCurrentTraining,
  getTrainingLoader,
} from 'src/store/selectors';
import actions from 'src/store/actions';
import { RoutesEnum } from 'src/shared/enums';
import { QuizResult } from '../../components/QuizResult';

interface RouteParams {
  id: string;
  submissionId: string;
}

export const TrainingResultPage: FC<unknown> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentTraining = useSelector(getCurrentTraining);
  const currentSubmission = useSelector(getCurrentSubmission);
  const trainingLoader = useSelector(getTrainingLoader);
  const { id: currentTrainingId, submissionId: currentTrainingSubmissionId } =
    useParams<RouteParams>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (
      currentTraining &&
      currentSubmission &&
      (currentSubmission.passedChoices || currentSubmission.failedChoices) &&
      !trainingLoader.loading &&
      trainingLoader.success &&
      trainingLoader.completed
    ) {
      setLoaded(true);
    }
  }, [currentTraining, currentSubmission, trainingLoader]);

  useEffect(() => {
    const trainingId = currentTraining
      ? currentTraining.id
      : Number(currentTrainingId);
    const submissionId = currentSubmission
      ? currentSubmission.id
      : Number(currentTrainingSubmissionId);
    dispatch(actions.trainingLoadRequest(trainingId));
    dispatch(actions.trainingLoadSubmissionRequest(trainingId, submissionId));
  }, []);

  const handleTakeAgain = (): void => {
    dispatch(actions.trainingSubmissionResponseReset());
    history.push(RoutesEnum.MY_TRAINING);
  };

  const handleKeepTraining = (): void => {
    dispatch(actions.trainingSubmissionResponseReset());
    history.push(RoutesEnum.MY_TRAINING);
  };

  const hasPassed = currentSubmission?.status === 'PASSED';
  const totalChoices =
    currentSubmission?.passedChoices + currentSubmission?.failedChoices;

  return (
    <>
      {!loaded ? (
        <LoaderComponent />
      ) : (
        <QuizResult
          hasPassed={hasPassed}
          passedChoices={currentSubmission?.passedChoices}
          failedChoices={currentSubmission?.failedChoices}
          totalChoices={totalChoices}
          passingScore={100}
          questions={currentTraining?.questions}
          responses={currentSubmission?.responses}
          takeAgainHandler={handleTakeAgain}
          keepTrainingHandler={handleKeepTraining}
        />
      )}
    </>
  );
};
