import React, { FC, useState } from 'react';
import { Checkbox, Divider, Form, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SelectValue } from 'antd/lib/select';
import { Question, Condition, QuestionTypes } from 'src/store/types';
import { PlusCircleOutlined } from 'src/theme/icons';
import { LinkButton } from './LinkButton';
import { ItemContainer } from './FormFieldSettings';

import {
  ConditionalRuleset,
  ConditionalRulesetFormValue,
} from './ConditionalRuleset';

export interface RulesSettingsProps {
  question: Question;
}

const Field = Form.Item;

export const RulesSettings: FC<RulesSettingsProps> = ({ question }) => {
  const hasConditions = question.conditions && question.conditions.length > 0;
  const questionType = question.type;
  const [showConditionalRules, setShowConditionalRules] =
    useState<boolean>(!!hasConditions);
  const [conditionConnector, setConditionConnector] = useState(
    hasConditions
      ? ((question.conditions as Condition[])[0].connector as string)
      : 'all',
  );

  const showAllowExtraOption = () => {
    switch (questionType.key) {
      case QuestionTypes.RADIO:
      case QuestionTypes.CHECKBOX:
        return true;
      default:
        return false;
    }
  };

  const showAllowMultipleOption = () => {
    switch (questionType.key) {
      case QuestionTypes.SELECT:
      case QuestionTypes.FILE:
        return true;
      default:
        return false;
    }
  };

  const showRequiredOption = () => questionType.key !== QuestionTypes.SECTION;

  const validateConditions = (
    rule: any,
    value: ConditionalRulesetFormValue,
  ) => {
    if (value) {
      if (!value.condition && !value.choice?.id && !value.choiceId) {
        return Promise.reject("There's no value to compare against");
      }
    } else {
      return Promise.reject('Condition is empty');
    }
    return Promise.resolve();
  };

  return (
    <ItemContainer>
      <span>Rules</span>
      {showRequiredOption() && (
        <Field
          name="required"
          noStyle
          rules={[{ required: false }]}
          valuePropName="checked"
        >
          <Checkbox>Required</Checkbox>
        </Field>
      )}
      {showAllowMultipleOption() && (
        <Field
          name="multiple"
          noStyle
          rules={[{ required: false }]}
          valuePropName="checked"
        >
          <Checkbox>Multiple</Checkbox>
        </Field>
      )}
      {showAllowExtraOption() && (
        <Field
          name="allowExtra"
          noStyle
          rules={[{ required: false }]}
          valuePropName="checked"
        >
          <Checkbox>Enable "Other" option</Checkbox>
        </Field>
      )}
      <Field
        name="hasConditions"
        noStyle
        rules={[{ required: false }]}
        valuePropName="checked"
      >
        <Checkbox
          onChange={(e: CheckboxChangeEvent) => {
            setShowConditionalRules(e.target.checked);
          }}
        >
          Enable Conditional Logic
        </Checkbox>
      </Field>
      {showConditionalRules && (
        <>
          <Divider />
          <Field name="conditionAction">
            <Select>
              <Select.Option value="SHOW">Show</Select.Option>
              <Select.Option value="HIDE">Hide</Select.Option>
            </Select>
          </Field>
          <p>This field if</p>
          <Field name="conditionConnector">
            <Select
              onChange={(value: SelectValue) => {
                setConditionConnector(value as string);
              }}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="any">Any</Select.Option>
            </Select>
          </Field>
          <p>of the following match: </p>
          <div className="conditionRules">
            <Form.List name="conditions">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field) => (
                      <Field
                        name={[field.name]}
                        key={field.key}
                        rules={[{ validator: validateConditions }]}
                      >
                        <ConditionalRuleset
                          field={field}
                          connector={conditionConnector}
                          remove={remove}
                        />
                      </Field>
                    ))}
                    <LinkButton
                      icon={<PlusCircleOutlined />}
                      text="Add Rule"
                      onClick={add}
                    />
                  </>
                );
              }}
            </Form.List>
          </div>
        </>
      )}
    </ItemContainer>
  );
};
