import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Menu as MenuAntd } from 'antd';
import { useAuth } from 'src/hooks/useAuth/useAuth';

import { BookOutlined, FileWordOutlined } from 'src/theme/icons';
import { ComponentsEnum, RoutesEnum } from 'src/shared/enums';
import { useTesting } from 'src/hooks/useTesting';

const Menu = styled(MenuAntd)`
  color: white;
  overflow: initial;
  &.ant-menu-vertical,
  &.ant-menu-inline {
    border-width: 0;
  }

  &.ant-menu-inline-collapsed {
    width: 70px;

    > .ant-menu-item {
      padding: 0px 25px;
    }
  }

  & .ant-menu-item {
    padding-left: 22px;
    height: 64px;
    line-height: 64px;
    color: #98bddc;
    overflow: initial;

    .ant-badge {
      color: inherit;
    }

    .ant-badge-count {
      background-color: white;
      color: black;
      font-family: ${({ theme }) => theme.fontFamilies.primary};
    }

    &[style] {
      padding-left: 22px !important;
    }

    &.ant-menu-item-active {
      color: ${({ theme }): string => theme.colors.darkBlue.main};
    }

    &.ant-menu-item-selected {
      color: ${({ theme }): string => theme.colors.darkBlue.main};
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

const TrainingMenu: FC = () => {
  const { getTestingProps } = useTesting(ComponentsEnum.TRAINING_MENU);
  const { isAdmin, is2FAauthenticated } = useAuth();

  return (
    <Menu mode="horizontal" defaultSelectedKeys={['trainings']}>
      {is2FAauthenticated && isAdmin && (
        <MenuAntd.Item
          {...getTestingProps('item-1')}
          key="trainings"
          icon={<BookOutlined />}
          title="Trainings"
        >
          <Link to={RoutesEnum.TRAINING_MANAGEMENT} />
          <span>Trainings</span>
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && isAdmin && (
        <MenuAntd.Item
          {...getTestingProps('item-2')}
          key="exams"
          defaultChecked
          icon={<FileWordOutlined />}
          title="Exams"
        >
          <Link to={RoutesEnum.EXAM_MANAGEMENT} />
          <span>Exams</span>
        </MenuAntd.Item>
      )}
    </Menu>
  );
};

export default TrainingMenu;
