import { call, takeEvery, put } from 'redux-saga/effects';
import { trainingPeriodService } from 'src/features/learning';
import actions from 'src/store/actions';
import {
  TrainingPeriodListingLoadRequestAction,
  TrainingPeriodListingResponse,
  TRAINING_PERIOD_LISTING_LOAD_REQUEST,
} from '../types';

export const loadTrainingPeriodListing = function* async({
  companyId,
}: TrainingPeriodListingLoadRequestAction): Generator {
  try {
    const result = yield call(trainingPeriodService.getAll, companyId);
    yield put(
      actions.trainingPeriodListingLoadSuccess(
        result as TrainingPeriodListingResponse,
      ),
    );
  } catch (err) {
    yield put(
      actions.trainingPeriodListingLoadError(
        'Failed to load trainingPeriod list',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(
      TRAINING_PERIOD_LISTING_LOAD_REQUEST,
      loadTrainingPeriodListing,
    ),
  ];
}
