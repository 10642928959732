import { centeredFlex, device, normalOpenSans } from 'src/theme';
import { remConvert } from 'src/theme/utils';
import styled, { css } from 'styled-components';

const flexLeft = css`
  display: flex;
  justify-content: left;
`;

export const ProgressSectionContainer = styled.div`
  ${flexLeft}
  flex-direction: column;

  @media ${device.sm} {
    flex-direction: row;
  }
`;

export const DueDateContainer = styled.div`
  ${flexLeft}
  flex-direction: column;
  width: 100%;
  margin-top: ${remConvert(10)};

  @media ${device.sm} {
    margin-top: ${remConvert(0)};
    width: ${remConvert(162)};
    justify-content: center;
    flex-direction: column;
  }

  div:first-of-type {
    ${normalOpenSans}
    font-weight: 600;
    font-size: 12px;
    line-height: ${remConvert(16)};
    text-align: left;
    color: rgba(28, 36, 40, 0.5);

    @media ${device.sm} {
      text-align: right;
    }
  }

  div:nth-of-type(2) {
    ${normalOpenSans}
    font-weight: 600;
    font-size: 17px;
    line-height: ${remConvert(23)};
    text-align: left;
    color: ${({ theme }) => theme.colors.astral.main};

    @media ${device.sm} {
      text-align: right;
    }
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;

  .ant-progress-bg,
  .ant-progress-inner {
    height: ${remConvert(13)} !important;
  }

  .ant-progress-text {
    ${normalOpenSans}
    ${centeredFlex}
    font-weight: 600;
    font-size: 20px;
    line-height: ${remConvert(27)};
    text-align: right;
    color: rgba(28, 36, 40, 0.5);
  }

  div:first-of-type {
    ${normalOpenSans}
    font-weight: 400;
    font-size: 14px;
    line-height: ${remConvert(19)};
    color: rgba(28, 36, 40, 0.75);
  }
`;

export const TrainingSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${remConvert(25)};

  @media ${device.lg} {
    flex-direction: row;
  }
`;

export const TrainingVideoContainer = styled.div`
  width: 100%;
  transition: all 0.2s;

  @media ${device.lg} {
    width: 65%;
  }
`;

export const VimeoVideoWrapper = styled.div`
  padding: ${remConvert(10)};
  background: black;
  position: relative;
  width: 100%;
`;

export const VimeoVideoOverlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  top: ${remConvert(0)};
  left: ${remConvert(0)};

  > img {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${remConvert(75)};
    height: ${remConvert(75)};
    margin-top: ${remConvert(-37.5)}; /* Half the height */
    margin-left: ${remConvert(-37.5)}; /* Half the width */

    @media ${device.sm} {
      width: ${remConvert(150)};
      height: ${remConvert(150)};
      margin-top: ${remConvert(-75)}; /* Half the height */
      margin-left: ${remConvert(-75)}; /* Half the width */
    }
  }
`;

export const TrainingVideoInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: ${remConvert(8)};
  margin-top: ${remConvert(16)};

  @media ${device.sm} {
    flex-direction: row;
  }
`;

export const TrainingVideoTitleContainer = styled.div`
  ${centeredFlex}

  > span:nth-of-type(1) {
    img {
      display: none;

      @media ${device.sm} {
        display: block;
      }
    }
  }

  > span:nth-of-type(2) {
    img {
      margin-right: ${remConvert(4)};
    }
  }
`;

export const TrainingVideoTitle = styled.div`
  ${normalOpenSans}
  font-weight: 400;
  font-size: 24px;
  line-height: ${remConvert(33)};
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black.shark};
`;

export const TrainingVideoSubTitle = styled.div`
  ${normalOpenSans}
  ${centeredFlex}
  font-weight: 400;
  font-size: 16px;
  line-height: ${remConvert(22)};
  color: rgba(28, 36, 40, 0.5);
`;

export const TrainingModulesContainer = styled.div`
  width: 100%;
  transition: all 0.2s;
  margin-top: ${remConvert(10)};

  @media ${device.lg} {
    margin-top: ${remConvert(0)};
    width: 35%;
  }
`;

export const TrainingButtonsContainer = styled.div`
  button:nth-of-type(1) {
    margin-right: ${remConvert(5)};
    margin-top: ${remConvert(5)};

    @media ${device.sm} {
      margin-top: ${remConvert(0)};
      flex-direction: row;
    }
  }
`;
