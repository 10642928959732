import React, { useEffect, useState } from 'react';
import { Table, Empty, Tag } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { TrainingPeriodStatus } from 'src/store/types/training';
import { TrainingPeriodListingElement } from 'src/store/types';
import { StickyTable } from './TrainingPeriodListingTable.styles';
import { TrainingPeriodSettingsMenu } from '..';

interface Props {
  dataSource: TrainingPeriodListingElement[];
  onEdit: (period: TrainingPeriodListingElement) => void;
  onManualCompletion: (period: TrainingPeriodListingElement) => void;
}

const { Column } = Table;
const renderStickyTable = (
  dataSource: TrainingPeriodListingElement[],
  onEdit: (period: TrainingPeriodListingElement) => void,
  onManualCompletion: (period: TrainingPeriodListingElement) => void,
) => {
  return (
    <StickyTable
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
    >
      {dataSource.every((tpl) => tpl.user) && (
        <Column<TrainingPeriodListingElement>
          title="Assignee"
          dataIndex="assignee"
          key="assignee"
          render={(_, { user }) => <span>{user?.name}</span>}
        />
      )}
      <Column<TrainingPeriodListingElement>
        title="Release date"
        dataIndex="releaseDate"
        key="releaseDate"
        render={(_, { releaseDate }) => (
          <span>{moment(releaseDate).format('MM-DD-YYYY')}</span>
        )}
      />
      <Column<TrainingPeriodListingElement>
        title="Due date"
        dataIndex="dueDate"
        key="dueDate"
        render={(_, { dueDate }) => (
          <span>{dueDate ? moment(dueDate).format('MM-DD-YYYY') : '--'}</span>
        )}
      />
      <Column<TrainingPeriodListingElement>
        title="Status"
        dataIndex="status"
        key="status"
        render={(_, { status }) => {
          return (
            <Tag
              color={
                status === TrainingPeriodStatus.COMPLETED ? 'error' : 'success'
              }
            >
              {status}
            </Tag>
          );
        }}
      />
      <Column<TrainingPeriodListingElement>
        title="Expires"
        dataIndex="expirationDate"
        key="expirationDate"
        render={(_, { expirationDate }) => (
          <span>{moment(expirationDate).format('MM-DD-YYYY')}</span>
        )}
      />
      <Column<TrainingPeriodListingElement>
        title="Created"
        dataIndex="createdAt"
        key="createdAt"
        render={(_, { createdAt }) => (
          <span>{moment(createdAt).format('MM-DD-YYYY')}</span>
        )}
      />
      <Column<TrainingPeriodListingElement>
        title="Action"
        dataIndex=""
        key="action"
        width="5%"
        render={(_, record: TrainingPeriodListingElement) => (
          <TrainingPeriodSettingsMenu
            canEdit={record.status !== TrainingPeriodStatus.COMPLETED}
            onEdit={() => onEdit(record)}
            onManualCompletion={() => onManualCompletion(record)}
          />
        )}
      />
    </StickyTable>
  );
};

const Container = styled.div`
  padding-bottom: 48px;
`;

const TrainingPeriodListingTable = ({
  dataSource,
  onEdit,
  onManualCompletion,
}: Props) => {
  const [companyTrainingPeriods, setCompanyTrainingPeriods] = useState<
    TrainingPeriodListingElement[]
  >([]);
  const [individualTrainingPeriods, setIndividualTrainingPeriods] = useState<
    TrainingPeriodListingElement[]
  >([]);

  useEffect(() => {
    // Sort the data by status and after it by release date
    const sortedData = [...dataSource].sort((a, b) => {
      if (
        a.status === TrainingPeriodStatus.OPEN &&
        b.status !== TrainingPeriodStatus.OPEN
      ) {
        return -1;
      } else if (
        a.status !== TrainingPeriodStatus.OPEN &&
        b.status === TrainingPeriodStatus.OPEN
      ) {
        return 1;
      } else {
        return moment(a.releaseDate).diff(moment(b.releaseDate));
      }
    });

    setCompanyTrainingPeriods(sortedData.filter((tpl) => !tpl.user));
    setIndividualTrainingPeriods(sortedData.filter((tpl) => tpl.user));
  }, [dataSource]);

  return (
    <>
      <Container>
        <h3 style={{ fontWeight: 'bold', color: '#6B6B6B' }}>
          Company Training Period
        </h3>
        <p>
          The Company training period is the time allocated for your
          organization to complete training
        </p>
        {renderStickyTable(companyTrainingPeriods, onEdit, onManualCompletion)}
      </Container>
      <Container>
        <h3 style={{ fontWeight: 'bold', color: '#6B6B6B' }}>
          Individual Training Periods
        </h3>
        <p>
          Individual training periods are for employees who are unavailable
          during the company training period
        </p>
        {renderStickyTable(
          individualTrainingPeriods,
          onEdit,
          onManualCompletion,
        )}
      </Container>
    </>
  );
};

export default TrainingPeriodListingTable;
