import { useLocation } from 'react-router-dom';

const useIsTrainingRoute = (): boolean => {
  const location = useLocation();
  return (
    location.pathname.includes('training') || location.pathname.includes('exam')
  );
};

export default useIsTrainingRoute;
