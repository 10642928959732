import React, { FC, useState } from 'react';
import { Progress } from 'src/components/Progress';
import { AssessmentState, AssessmentStatus } from 'src/store/types';
import { DownOutlined, UpOutlined } from 'src/theme/icons';
import { Col } from 'antd';

import { CompliantStatus } from '../CompliantStatus';
import * as S from './Styles';

interface RisksCollapsiblePanelProps {
  title: string;
  sections: number;
  completedForms: number;
  assessment: AssessmentState;
  risks: number;
  children: React.ReactNode;
}

export const RisksCollapsiblePanel: FC<RisksCollapsiblePanelProps> = ({
  children,
  title,
  completedForms,
  sections,
  assessment,
  risks,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const completionPercent = Math.round((100 * completedForms) / sections);

  return (
    <S.RisksCollapsibleContainer>
      <S.RisksCollapsibleHeader>
        <S.RisksCollapsibleHeaderFirstRow>
          <Col xs={18}>
            <S.RisksCollapsibleTitle>{title}</S.RisksCollapsibleTitle>
          </Col>
          <Col xs={6}>
            <Progress percent={completionPercent} />
          </Col>
        </S.RisksCollapsibleHeaderFirstRow>
        <S.RisksCollapsibleHeaderSecondRow>
          <Col xs={8}>
            {assessment.status === AssessmentStatus.MITIGATION && (
              <CompliantStatus risks={risks} />
            )}
          </Col>
          <Col xs={8}>
            <S.RiskSectionContentContainer>
              <S.RiskSectionsContent>{`${sections} Sections`}</S.RiskSectionsContent>
              {!collapsed && (
                <UpOutlined
                  style={{ fontSize: '16px' }}
                  onClick={() => setCollapsed(true)}
                />
              )}
              {collapsed && (
                <DownOutlined
                  style={{ fontSize: '16px' }}
                  onClick={() => setCollapsed(false)}
                />
              )}{' '}
            </S.RiskSectionContentContainer>
          </Col>
          <Col xs={8} />
        </S.RisksCollapsibleHeaderSecondRow>
      </S.RisksCollapsibleHeader>
      {!collapsed && (
        <S.RiskCollapsibleContent>{children}</S.RiskCollapsibleContent>
      )}
    </S.RisksCollapsibleContainer>
  );
};
