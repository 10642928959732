import React, { FC, useState } from 'react';
import { ToolbarItem } from './ToolbarItem';
import { TemplateEditorTitleSelectorWindow } from './TemplateEditorSelectorWindows';

interface TitleToolbarItemProps {
  updateEditorContent: (val: string) => void;
}

export const TitleToolbarItem: FC<TitleToolbarItemProps> = ({
  updateEditorContent,
}) => {
  const [childrenVisible, setChildrenVisible] = useState<boolean>(false);

  const execute = (titleLevel: number) => {
    updateEditorContent(
      `\n\n${'#'.repeat(titleLevel).split(' ').join('')} TITLE\n\n`,
    );
    setChildrenVisible(false);
  };

  return (
    <ToolbarItem
      icon={
        <svg width="12" height="12" viewBox="0 0 48 48">
          <path
            d="M6 8.00024V40.0002"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 8.00024V40.0002"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 24.0002H23"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32 24V40"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            d="M32 31.0237C32 28.4597 34 26.0002 37 26.0002C40 26.0002 42 28.3583 42 31.0237C42 32.8007 42 36.464 42 40.0136"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      }
      childrenVisible={childrenVisible}
      setChildrenVisible={setChildrenVisible}
      tooltip="Headers"
    >
      <TemplateEditorTitleSelectorWindow
        onSelection={(titleDepth: number) => execute(titleDepth)}
        onClose={() => setChildrenVisible(false)}
      />
    </ToolbarItem>
  );
};
