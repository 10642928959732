import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Input, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { AutoComplete as AutoCompleteBase } from 'src/features/risk-assessment/components/AutoCompleteInput';
import { AnswerData, Answer, Question } from 'src/store/types';
import { questionToInputRulesMapping } from 'src/features/risk-assessment/components/InterviewQuestions/InputRules';

export enum ContactInfoFields {
  title = 'title',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
}

export interface ContactValues {
  [ContactInfoFields.title]?: string;
  [ContactInfoFields.firstName]?: string;
  [ContactInfoFields.lastName]?: string;
  [ContactInfoFields.phone]?: string;
  [ContactInfoFields.email]?: string;
}

interface ContactInfoFieldProps {
  onChange: (values: ContactValues) => void;
  value: ContactValues;
  updateAnswer: (answerData: AnswerData) => void;
  deleteAnswer: (answerData: AnswerData) => void;
  answer: Answer;
  suggestions?: string[];
  question: Question;
  form: FormInstance;
}

const InputGroup = styled(Input.Group)`
  width: 312px;
`;

const AutoComplete = styled(AutoCompleteBase)`
  && {
    margin-bottom: 15px;
  }
`;

export const ContactField: FC<ContactInfoFieldProps> = ({
  onChange,
  value = {},
  updateAnswer,
  deleteAnswer,
  answer,
  question,
  suggestions,
}) => {
  const [blurIgnoreUpdate, setBlurIgnoreUpdate] = useState(false);

  const triggerAnswerUpdate = (selectedValue?: {
    [k in ContactInfoFields]?: string;
  }) => {
    const latestValues = { ...value };

    if (selectedValue) {
      // If selected auto-complete suggestion, update that value.
      const changedKey = Object.keys(selectedValue)[0] as ContactInfoFields;
      const changedValue = selectedValue[changedKey];

      latestValues[changedKey] = changedValue;

      onChange(latestValues);
    }

    if (!selectedValue && shouldDeleteAnswer()) {
      return deleteAnswer?.({ id: answer?.id, internalId: answer?.internalId });
    }

    return updateAnswer({
      value: JSON.stringify(latestValues),
      id: answer?.id,
      internalId: answer?.internalId,
    });
  };

  const shouldDeleteAnswer = (): boolean => {
    return (
      !value ||
      (!value[ContactInfoFields.title] &&
        !value[ContactInfoFields.firstName] &&
        !value[ContactInfoFields.lastName] &&
        !value[ContactInfoFields.email] &&
        !value[ContactInfoFields.phone])
    );
  };

  const onBlur = () => {
    if (blurIgnoreUpdate) {
      setBlurIgnoreUpdate(false);
      return;
    }

    setTimeout(() => triggerAnswerUpdate(), 100);
  };

  const onSuggestionSelect = (key: ContactInfoFields) => (value: string) => {
    triggerAnswerUpdate({ [key]: value });
    setBlurIgnoreUpdate(true);
  };

  const disableSuggestions = !question || !question.isSmart;
  const options: ContactValues[] =
    disableSuggestions || !suggestions
      ? []
      : suggestions.map(
          (rawSuggestion: string) => JSON.parse(rawSuggestion) as ContactValues,
        );

  return (
    <>
      <InputGroup>
        <Form.Item
          noStyle
          name={[
            question?.id || question.internalId || 1,
            ContactInfoFields.firstName,
          ]}
          rules={[{ required: true }]}
        >
          <AutoComplete
            optionData={options}
            optionKey={ContactInfoFields.firstName}
            placeholder="First Name"
            onBlur={onBlur}
            onSelect={onSuggestionSelect(ContactInfoFields.firstName)}
          />
        </Form.Item>
      </InputGroup>
      <InputGroup>
        <Form.Item
          noStyle
          name={[
            question?.id || question.internalId || 1,
            ContactInfoFields.lastName,
          ]}
          rules={[{ required: true }]}
        >
          <AutoComplete
            optionData={options}
            optionKey={ContactInfoFields.lastName}
            placeholder="Last Name"
            onBlur={onBlur}
            onSelect={onSuggestionSelect(ContactInfoFields.lastName)}
          />
        </Form.Item>
      </InputGroup>
      <InputGroup>
        <Form.Item
          noStyle
          name={[
            question?.id || question.internalId || 1,
            ContactInfoFields.title,
          ]}
          rules={[{ required: true }]}
        >
          <AutoComplete
            optionData={options}
            optionKey={ContactInfoFields.title}
            placeholder="Title"
            onBlur={onBlur}
            onSelect={onSuggestionSelect(ContactInfoFields.title)}
          />
        </Form.Item>
      </InputGroup>
      <InputGroup>
        <Form.Item
          noStyle
          name={[
            question?.id || question.internalId || 1,
            ContactInfoFields.email,
          ]}
          rules={[{ required: true, ...questionToInputRulesMapping.email }]}
        >
          <AutoComplete
            optionData={options}
            optionKey={ContactInfoFields.email}
            value={value.email}
            onChange={(email: string) =>
              onChange({ ...value, email: email.trim() })
            }
            placeholder="Email"
            onBlur={onBlur}
            onSelect={onSuggestionSelect(ContactInfoFields.email)}
          />
        </Form.Item>
      </InputGroup>
      <InputGroup>
        <Form.Item
          noStyle
          name={[
            question?.id || question.internalId || 1,
            ContactInfoFields.phone,
          ]}
          rules={[{ required: true, ...questionToInputRulesMapping.phone }]}
        >
          <AutoComplete
            optionData={options}
            optionKey={ContactInfoFields.phone}
            placeholder="(XXX) XXX-XXXX"
            onBlur={onBlur}
            onSelect={onSuggestionSelect(ContactInfoFields.phone)}
            onChange={onSuggestionSelect(ContactInfoFields.phone)}
            phoneInput
          />
        </Form.Item>
      </InputGroup>
    </>
  );
};
