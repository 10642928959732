import 'react-phone-input-2/lib/style.css';

import React, { ChangeEvent, FC, useEffect } from 'react';

import { TextInput } from 'src/components/TextInput';
import { Button, ButtonRounded } from 'src/components/Button';
import { use2faSMS } from 'src/hooks/use2faSMS/use2faSMS';
import { BodySMS } from 'src/types/common';
import * as S from './Styles';

export interface TwoFactorVerificationSMSProps {
  code: string;
  sms: Partial<BodySMS>;
  error?: string;
  onSubmit: (code: string, params: BodySMS) => void;
  onChangeCode: (code: string) => void;
  onChangeBodySMS: (params: Partial<BodySMS>) => void;
}

export const TwoFactorVerificationSMS: FC<TwoFactorVerificationSMSProps> = (
  props,
) => {
  const { code, sms, error } = props;
  const { dialCode, phoneNumber } = sms;
  const { onSubmit, onChangeCode, onChangeBodySMS } = props;

  const { sendSMS, available, sent, error: sendedError } = use2faSMS();

  const handleChangeBodySMS = (key: string, value: string) => {
    onChangeBodySMS({
      ...sms,
      [key]: value,
    });
  };

  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    const newCode = event.target.value;
    onChangeCode(newCode);
  };

  const handleSendAuthenticationCode = () => {
    if (dialCode && phoneNumber) {
      sendSMS({ dialCode, phoneNumber });
    }
  };

  const handleOnSubmit = (): void => {
    if (dialCode && phoneNumber && code.length === 6) {
      onSubmit(code, { dialCode, phoneNumber });
    }
  };

  useEffect(() => {
    handleChangeBodySMS('dialCode', `+${1}`);

    return () => {
      onChangeCode('');
      onChangeBodySMS({});
    };
  }, []);

  return (
    <S.SMSContainer>
      <S.HelpContainer>
        <S.HelpStepNumber>1.</S.HelpStepNumber>
        <S.HelpDescriptor>
          Enter your mobile number and click send:
        </S.HelpDescriptor>
      </S.HelpContainer>
      <S.PhoneInputWrapper>
        <S.StyledPhoneInput
          country="us"
          value={dialCode}
          placeholder="Select your country code"
          defaultMask=""
          alwaysDefaultMask
          enableSearch
          onChange={(value: string) =>
            handleChangeBodySMS('dialCode', `+${value}`)
          }
        />
        <S.PhoneTextInputWrapper>
          <TextInput
            autoFocus
            name="phoneNumber"
            placeholder="Mobile number"
            value={phoneNumber}
            error={sendedError}
            disabled={sent}
            float
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleChangeBodySMS('phoneNumber', event.target.value)
            }
          />
        </S.PhoneTextInputWrapper>
      </S.PhoneInputWrapper>
      <S.InputWrapper>
        <S.SendButtonWrapper>
          <ButtonRounded
            variant="radio"
            title="Send"
            disabled={!phoneNumber || !available}
            onClick={handleSendAuthenticationCode}
          />
        </S.SendButtonWrapper>
      </S.InputWrapper>
      <S.HelpContainer>
        <S.HelpStepNumber>2.</S.HelpStepNumber>
        <S.HelpDescriptor>
          An SMS will arrive shortly. Enter the code here:
        </S.HelpDescriptor>
      </S.HelpContainer>
      <S.InputWrapper>
        <TextInput
          autoFocus
          name="code"
          placeholder="SMS verification code"
          value={code}
          error={error}
          disabled={!sent}
          float
          onChange={handleChangeCode}
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <S.ButtonWrapper>
          <Button
            title="Finish Setup"
            onClick={handleOnSubmit}
            disabled={!sent}
          />
        </S.ButtonWrapper>
      </S.InputWrapper>
    </S.SMSContainer>
  );
};
