import React, { FC } from 'react';
import { Empty, List as ListAntD } from 'antd';
import styled from 'styled-components';
import * as _ from 'lodash';
import { NotificationFromResponse } from 'src/store/types';
import { dataMapper } from './dataMapper';
import { NotificationListItem } from './NotificationListItem';

export interface NotificationListingTableProps {
  notifications: NotificationFromResponse[];
  currentTab: Tab.ARCHIVE | Tab.INBOX;
}

enum Tab {
  INBOX = '1',
  ARCHIVE = '2',
}

export interface NotificationListItemData {
  id: number;
  type: string;
  body: React.ReactNode;
  createdAt: string;
  pending: boolean;
  archived: boolean;
  location: string;
}

const List = styled(ListAntD)`
  li.ant-list-item {
    width: 55%;
    margin: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;

    div.ant-list-item-meta-description {
      font-size: 16px;
      color: black;

      p.notification-date {
        font-size: 12px;
        color: grey;
        margin: 5px 15px;
      }

      span.ant-avatar {
        margin-bottom: 3px;
      }
    }

    div.notification-actions-container {
      margin: 10px 0px;
      display: flex;
      justify-content: flex-end;
      button {
        margin: 5px;
      }
    }
  }
` as typeof ListAntD;

export const NotificationListingTable: FC<NotificationListingTableProps> = ({
  notifications = [],
  currentTab = Tab.INBOX,
}) => {
  const dataSource: NotificationListItemData[] = dataMapper(notifications);
  const description = `Nothing here, yet. When you ${currentTab === Tab.INBOX ? 'get' : 'archive'} notifications they’ll show up here.`;

  return notifications.length > 0 ? (
    <List
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(item, idx) => (
        <List.Item>
          <List.Item.Meta description={<NotificationListItem item={item} />} />
        </List.Item>
      )}
    />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description} />
  );
};
