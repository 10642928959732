import {
  ASSESSMENT_TYPES_LOAD_ALL_REQUEST,
  ASSESSMENT_TYPES_LOAD_ALL_SUCCESS,
  ASSESSMENT_TYPES_LOAD_ALL_ERROR,
  ASSESSMENT_TYPES_LOAD_LABEL_REQUEST,
  ASSESSMENT_TYPES_LOAD_LABEL_SUCCESS,
  ASSESSMENT_TYPES_LOAD_LABEL_FAILED,
  AssessmentTypesLoadAllRequestAction,
  AssessmentTypesLoadAllSuccessAction,
  AssessmentTypesLoadAllErrorAction,
  AssessmentTypeFromResponse,
  AssessmentTypesLoadLabelRequestAction,
  AssessmentTypesLoadLabelSuccessAction,
  AssessmentTypesLoadLabelErrorAction,
  AssessmentTypesLabelFromResponse,
  AssessmentTypesLoadDelegateRequestAction,
  ASSESSMENT_TYPES_LOAD_DELEGATE_REQUEST,
  ASSESSMENT_TYPES_LOAD_DELEGATE_SUCCESS,
  AssessmentTypesLoadDelegateSuccessAction,
  AssessmentTypesLoadDelegateErrorAction,
  ASSESSMENT_TYPES_LOAD_DELEGATE_FAILED,
  AssessmentTypesDelegateFromResponse,
} from 'src/store/types';

import { showErrorMessage } from './error';

export const assessmentTypesLoadAllRequest =
  (): AssessmentTypesLoadAllRequestAction => ({
    type: ASSESSMENT_TYPES_LOAD_ALL_REQUEST,
  });

export const assessmentTypesLoadAllSuccess = (
  assessmentTypes: AssessmentTypeFromResponse[],
): AssessmentTypesLoadAllSuccessAction => ({
  type: ASSESSMENT_TYPES_LOAD_ALL_SUCCESS,
  assessmentTypes,
});

export const assessmentTypesLoadAllError = (
  errorMessage: string,
  originalError: Error,
): AssessmentTypesLoadAllErrorAction =>
  showErrorMessage(
    ASSESSMENT_TYPES_LOAD_ALL_ERROR,
    errorMessage,
    originalError,
  );

export const assessmentTypesLoadLabelsRequest = (
  typeId: number,
): AssessmentTypesLoadLabelRequestAction => ({
  type: ASSESSMENT_TYPES_LOAD_LABEL_REQUEST,
  typeId,
});

export const assessmentTypesLoadLabelsSuccess = (
  labels: AssessmentTypesLabelFromResponse[],
): AssessmentTypesLoadLabelSuccessAction => ({
  type: ASSESSMENT_TYPES_LOAD_LABEL_SUCCESS,
  labels,
});

export const assessmentTypesLoadLabelsFailed = (
  errorMessage: string,
  originalError: Error,
): AssessmentTypesLoadLabelErrorAction =>
  showErrorMessage(
    ASSESSMENT_TYPES_LOAD_LABEL_FAILED,
    errorMessage,
    originalError,
  );

export const assessmentTypesLoadDelegatesRequest = (
  typeId: number,
): AssessmentTypesLoadDelegateRequestAction => ({
  type: ASSESSMENT_TYPES_LOAD_DELEGATE_REQUEST,
  typeId,
});

export const assessmentTypesLoadDelegatesSuccess = (
  delegates: AssessmentTypesDelegateFromResponse[],
): AssessmentTypesLoadDelegateSuccessAction => ({
  type: ASSESSMENT_TYPES_LOAD_DELEGATE_SUCCESS,
  delegates,
});

export const assessmentTypesLoadDelegatesFailed = (
  errorMessage: string,
  originalError: Error,
): AssessmentTypesLoadDelegateErrorAction =>
  showErrorMessage(
    ASSESSMENT_TYPES_LOAD_DELEGATE_FAILED,
    errorMessage,
    originalError,
  );
