import { combineReducers } from 'redux';
import {
  CompanyState,
  CompanyActionTypes,
  Loader,
  SelectedCompanyState,
  COMPANY_LOAD_REQUEST,
  COMPANY_LOAD_SUCCESS,
  COMPANY_LOAD_ERROR,
  COMPANY_SAVE_REQUEST,
  COMPANY_SAVE_SUCCESS,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  INTERVIEW_UPDATE_ERROR,
  INTERVIEW_UPDATE_REQUEST,
  INTERVIEW_UPDATE_SUCCESS,
  CLEAR_2FA_REQUEST,
  CLEAR_2FA_ERROR,
  CLEAR_2FA_SUCCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_ERROR,
  COMPANY_UPDATE_ERROR,
  COMPANY_SAVE_ERROR,
} from 'src/store/types';

import { interviewListingReducer } from './interviewListing';
import { userListingReducer } from './userListing';
import { userSummaryListingReducer } from './userSummaryListing';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const companyInitialState: CompanyState = {
  id: 0,
  createdAt: '',
  updatedAt: '',
  name: '',
};

export const companyReducer = (
  state = companyInitialState,
  action: CompanyActionTypes,
): CompanyState => {
  switch (action.type) {
    case COMPANY_LOAD_SUCCESS: {
      const { assessments, ...baseCompany } = action.company;
      const assessmentIds = assessments.map((a: any) => a.id);
      let lastAssessmentId = 0;
      if (assessmentIds.length > 0) {
        lastAssessmentId = assessments.reduce((last: any, curr: any) => {
          if (curr.createdAt > last.createdAt) return curr;
          return last;
        }, assessments[0]).id;
      }
      return {
        ...baseCompany,
        assessmentIds,
        lastAssessmentId,
      };
    }
    default:
      return state;
  }
};

export const loaderReducer = (
  state: Loader = loaderInitialState,
  action: CompanyActionTypes,
): Loader => {
  switch (action.type) {
    case COMPANY_UPDATE_REQUEST:
    case COMPANY_SAVE_REQUEST:
    case COMPANY_LOAD_REQUEST:
    case INTERVIEW_UPDATE_REQUEST:
    case CLEAR_2FA_REQUEST:
    case USER_UPDATE_REQUEST:
    case USER_DELETE_REQUEST: {
      return { ...loaderInitialState, loading: true };
    }
    case COMPANY_UPDATE_SUCCESS:
    case COMPANY_SAVE_SUCCESS:
    case COMPANY_LOAD_SUCCESS:
    case INTERVIEW_UPDATE_SUCCESS:
    case CLEAR_2FA_SUCCESS:
    case USER_UPDATE_SUCCESS:
    case USER_DELETE_SUCCESS: {
      return { ...loaderInitialState, success: true, completed: true };
    }
    case COMPANY_SAVE_ERROR:
    case COMPANY_UPDATE_ERROR:
    case COMPANY_LOAD_ERROR:
    case INTERVIEW_UPDATE_ERROR:
    case CLEAR_2FA_ERROR:
    case USER_UPDATE_ERROR:
    case USER_DELETE_ERROR: {
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
      };
    }
    default:
      return state;
  }
};

export const combinedCompanyReducer = combineReducers<SelectedCompanyState>({
  company: companyReducer,
  interviewListing: interviewListingReducer,
  userListing: userListingReducer,
  userSummaryListing: userSummaryListingReducer,
  loader: loaderReducer,
});
