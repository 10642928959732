import { useSelector } from 'react-redux';
import { Loader, TypeOfTraining, User, UserRole } from 'src/store/types';
import { getUser, getUserLoader } from 'src/store/selectors/user';

export interface AuthHook {
  user: User;
  userLoader: Loader;
  isAdmin: boolean;
  isCompanyModerator: boolean;
  isOfficer: boolean;
  isAuditor: boolean;
  isTrainee: boolean;
  isUser: boolean;
  is2FAon: boolean;
  is2FAauthenticated: boolean;
  typeOfTrainings: null | TypeOfTraining[];
  hasTraineePendingOnboarding: boolean;
  hasOfficerPendingOnboarding: boolean;
  hasPendingOnboarding: boolean;
}

export const useAuth = (): AuthHook => {
  const user: User = useSelector(getUser);
  const userLoader: Loader = useSelector(getUserLoader);
  const userRole = user.role || UserRole.anonymous;

  const ROLES = {
    [UserRole.anonymous]: 0,
    [UserRole.user]: 1,
    [UserRole.trainee]: 2,
    [UserRole.auditor]: 3,
    [UserRole.officer]: 4,
    [UserRole.company_moderator]: 5,
    [UserRole.admin]: 6,
  };

  const isAdmin = ROLES.admin <= ROLES[userRole];
  const isCompanyModerator = ROLES.company_moderator <= ROLES[userRole];
  const isOfficer = ROLES.officer <= ROLES[userRole];
  const isAuditor = ROLES.auditor <= ROLES[userRole];
  const isTrainee =
    userRole === UserRole.trainee || userRole === UserRole.officer;
  const isUser = ROLES.user <= ROLES[userRole];
  const isUserLoaded = user.id > 0;

  const {
    isTwoFactorAuthenticationEnabled: is2FAon,
    isSecondFactorAuthenticated: is2FAauthenticated,
    attributes,
    isOnboardingRequired,
  } = user;

  const { typeOfTrainings = [] } = attributes;
  const hasTraineePendingOnboarding =
    isOnboardingRequired && isUserLoaded && userRole === UserRole.trainee;
  const hasOfficerPendingOnboarding =
    isOnboardingRequired && isUserLoaded && isOfficer;
  const hasPendingOnboarding = isAdmin
    ? false
    : hasTraineePendingOnboarding || hasOfficerPendingOnboarding;

  return {
    user,
    userLoader,
    isAdmin,
    isCompanyModerator,
    isOfficer,
    isAuditor,
    isTrainee,
    isUser,
    is2FAon,
    is2FAauthenticated,
    typeOfTrainings: isAdmin ? null : typeOfTrainings,
    hasTraineePendingOnboarding,
    hasOfficerPendingOnboarding,
    hasPendingOnboarding,
  };
};
