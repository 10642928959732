import React, { FC } from 'react';
import { Logo } from 'src/components/MainLayout/Logo';
import {
  LogoContainer,
  PageContainer,
  PageContent,
  PageLayout,
} from './Styles';

interface CenteredLayoutProps {
  children: React.ReactNode;
}

export const OnboardingLayout: FC<CenteredLayoutProps> = ({ children }) => {
  return (
    <PageLayout className="layout">
      <PageContent>
        <PageContainer>
          <LogoContainer>
            <Logo isDarkLogo />
          </LogoContainer>
          {children}
        </PageContainer>
      </PageContent>
    </PageLayout>
  );
};
