import { combineReducers, Reducer } from 'redux';
import { assessmentTypeListingReducer } from './assessmentTypeListing';
import { assessmentListingReducer } from './assessmentListing';
import { combinedCompanyReducer } from './company';
import { companyListingReducer } from './companyListing';
import { combinedDocumentReducer } from './document';
import { documentListingReducer } from './documentListing';
import { combinedFormReducer } from './form';
import { formListingReducer } from './formListing';
import { combinedUserReducer } from './user';
import { questionTypeReducer } from './questionType';
import { combinedInterviewReducer } from './interview';
import { threadListingReducer } from './threadListing';
import { notificationListingReducer } from './notificationListing';
import { combinedTemplateReducer } from './template';
import { templateListingReducer } from './templateListing';
import { questionListingReducer } from './questionListing';
import { combinedDocumentDraftReducer } from './documentDraft';
import { ErrorReducer } from './error';
import { documentDraftListingReducer } from './documentDraftListing';
import { constantListingReducer } from './constantListing';
import { commentListingReducer } from './commentListing';
import { commentReducer } from './comment';
import { LOGOUT_SUCCESS, RootState, UserActionTypes } from '../types';
import { userListingReducer } from './userListing';
import { categoryListingReducer } from './categoryListing';
import { combinedAssessmentTypeReducers } from './assessmentType';
import { featureFlagReducer } from './featureFlag';
import { combinedAssessmentReducer } from './assessment';
import { testTypeListingReducer } from './testTypeListing';
import { trainingTypeListingReducer } from './trainingTypeListing';
import { trainingReducer } from './training';
import { trainingListingReducer } from './trainingListing';
import { examCombinedReducer } from './exam';
import { examListingReducer } from './examListing';
import { trainingPeriodReducer } from './trainingPeriod';
import { trainingPeriodListingReducer } from './trainingPeriodListing';
import { certificateListingReducer } from './certificateListing';

export const appReducer = combineReducers({
  assessmentTypeListing: assessmentTypeListingReducer,
  assessmentListing: assessmentListingReducer,
  company: combinedCompanyReducer,
  companyListing: companyListingReducer,
  documentListing: documentListingReducer,
  formListing: formListingReducer,
  selectedForm: combinedFormReducer,
  selectedInterview: combinedInterviewReducer,
  selectedTemplate: combinedTemplateReducer,
  selectedDocument: combinedDocumentReducer,
  selectedAssessment: combinedAssessmentReducer,
  user: combinedUserReducer,
  questionTypes: questionTypeReducer,
  templateListing: templateListingReducer,
  threadListing: threadListingReducer,
  notificationListing: notificationListingReducer,
  questionListing: questionListingReducer,
  selectedDocumentDraft: combinedDocumentDraftReducer,
  error: ErrorReducer,
  documentDraftListing: documentDraftListingReducer,
  constantListing: constantListingReducer,
  commentListing: commentListingReducer,
  comment: commentReducer,
  userListing: userListingReducer,
  categoryListing: categoryListingReducer,
  selectedAssessmentType: combinedAssessmentTypeReducers,
  featureFlag: featureFlagReducer,
  testTypeListing: testTypeListingReducer,
  trainingTypeListing: trainingTypeListingReducer,
  training: trainingReducer,
  trainingListing: trainingListingReducer,
  exam: examCombinedReducer,
  examListing: examListingReducer,
  trainingPeriod: trainingPeriodReducer,
  trainingPeriodListing: trainingPeriodListingReducer,
  certificateListing: certificateListingReducer,
});

export const rootReducer: Reducer = (
  state: RootState,
  action: UserActionTypes,
) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};
