import { combineReducers } from 'redux';
import {
  EXAM_LOAD_SUBMISSION_FAILED,
  EXAM_LOAD_SUBMISSION_REQUEST,
  EXAM_LOAD_SUBMISSION_SUCCESS,
  EXAM_QUIZ_LOAD_FAILED,
  EXAM_QUIZ_LOAD_REQUEST,
  EXAM_QUIZ_LOAD_SUCCESS,
  EXAM_SUBMISSION_RESET,
  Loader,
  SelectedExamState,
} from 'src/store/types';
import {
  ExamTypes,
  ExamState,
  QuizQuestion,
  ExamSubmission,
  ExamSubmissionState,
  EXAM_SUBMISSION_FAILED,
  EXAM_SUBMISSION_RESPONSE_SUCCESS,
  EXAM_SUBMISSION_SUCCESS,
  EXAM_CREATE_FAILED,
  EXAM_CREATE_REQUEST,
  EXAM_CREATE_SUCCESS,
  EXAM_RESET,
  EXAM_QUESTION_CREATE_REQUEST,
  EXAM_QUESTION_CREATE_SUCCESS,
  EXAM_QUESTION_CREATE_FAILED,
  EXAM_QUESTION_UPDATE_REQUEST,
  EXAM_QUESTION_UPDATE_SUCCESS,
  EXAM_QUESTION_UPDATE_FAILED,
  EXAM_LOAD_REQUEST,
  EXAM_LOAD_SUCCESS,
  EXAM_LOAD_FAILED,
  EXAM_UPDATE_REQUEST,
  EXAM_UPDATE_SUCCESS,
  EXAM_UPDATE_FAILED,
  EXAM_QUESTION_DELETE_REQUEST,
  EXAM_QUESTION_DELETE_FAILED,
  EXAM_QUESTION_DELETE_SUCCESS,
  EXAM_CLONE_REQUEST,
  EXAM_CLONE_SUCCESS,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const examInitialState: ExamState = {
  exam: undefined,
  loader: loaderInitialState,
};

const initialExamSubmissionState: ExamSubmissionState = {
  submission: undefined,
  loader: loaderInitialState,
};

const examReducer = (
  state = examInitialState,
  action: ExamTypes,
): ExamState => {
  switch (action.type) {
    case EXAM_CREATE_REQUEST:
    case EXAM_CLONE_REQUEST:
    case EXAM_QUESTION_CREATE_REQUEST:
    case EXAM_QUESTION_UPDATE_REQUEST:
    case EXAM_QUESTION_DELETE_REQUEST:
    case EXAM_QUIZ_LOAD_REQUEST:
    case EXAM_LOAD_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case EXAM_UPDATE_REQUEST: {
      const newState = {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };

      const { exam } = state;
      const { config } = action;
      if (exam) {
        newState.exam = {
          ...exam,
          ...config,
        };
      }

      return newState;
    }
    case EXAM_CLONE_SUCCESS:
    case EXAM_CREATE_SUCCESS:
    case EXAM_QUIZ_LOAD_SUCCESS:
    case EXAM_LOAD_SUCCESS: {
      return {
        exam: action.exam,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_UPDATE_SUCCESS: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_LOAD_FAILED: {
      return {
        ...examInitialState,
        loader: {
          ...loaderInitialState,
          error: action.error,
        },
      };
    }
    case EXAM_CREATE_FAILED:
    case EXAM_RESET: {
      return {
        ...examInitialState,
      };
    }
    case EXAM_QUESTION_CREATE_SUCCESS: {
      const questions = state.exam
        ? [...state.exam.questions, action.question]
        : [action.question];
      return {
        exam: { ...state.exam, questions } as any,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_QUIZ_LOAD_FAILED:
    case EXAM_QUESTION_CREATE_FAILED:
    case EXAM_QUESTION_UPDATE_FAILED:
    case EXAM_QUESTION_DELETE_FAILED:
    case EXAM_UPDATE_FAILED: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
        },
      };
    }
    case EXAM_QUESTION_UPDATE_SUCCESS: {
      const questions: QuizQuestion[] = state.exam?.questions.map((question) =>
        question.id === action.question.id
          ? {
              ...question,
              ...action.question,
              choices: action.question.choices.map((choice) => {
                const previousChoice = question.choices.find(
                  (currentChoice) => currentChoice.id == choice.id,
                );
                return { ...previousChoice, ...choice };
              }),
            }
          : { ...question },
      ) || [action.question];
      return {
        exam: { ...state.exam, questions } as any,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_QUESTION_DELETE_SUCCESS: {
      const questions: QuizQuestion[] =
        state.exam?.questions.filter(
          (question) => question.id !== action.questionId,
        ) || [];
      return {
        exam: { ...state.exam, questions } as any,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    default:
      return state;
  }
};

const examSubmissionReducer = (
  state = initialExamSubmissionState,
  action: ExamTypes,
): ExamSubmissionState => {
  switch (action.type) {
    case EXAM_LOAD_SUBMISSION_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case EXAM_SUBMISSION_RESPONSE_SUCCESS: {
      return {
        ...state,
        submission: action.submissionResult || state.submission,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_SUBMISSION_SUCCESS: {
      return {
        ...state,
        submission: action.submission,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_SUBMISSION_FAILED: {
      return {
        ...state,
        submission: {} as ExamSubmission,
        loader: {
          ...loaderInitialState,
          success: false,
          completed: true,
        },
      };
    }
    case EXAM_LOAD_SUBMISSION_SUCCESS: {
      return {
        ...state,
        submission: action.submission,
      };
    }
    case EXAM_LOAD_SUBMISSION_FAILED: {
      return {
        ...state,
        submission: {} as ExamSubmission,
      };
    }
    case EXAM_SUBMISSION_RESET: {
      return {
        ...state,
        submission: undefined,
      };
    }
    default:
      return state;
  }
};

export const examCombinedReducer = combineReducers<SelectedExamState>({
  exam: examReducer,
  examSubmission: examSubmissionReducer,
});
