import { call, takeEvery, put } from 'redux-saga/effects';
import { trainingService } from 'src/features/learning';
import actions from 'src/store/actions';

import {
  TrainingArchiveRequestAction,
  TrainingDeleteRequestAction,
  TrainingListingLoadRequestAction,
  TrainingListingResponse,
  TRAINING_ACTIVATE_REQUEST,
  TRAINING_ARCHIVE_REQUEST,
  TRAINING_DELETE_REQUEST,
  TRAINING_LISTING_LOAD_REQUEST,
  USER_TRAINING_LISTING_LOAD_REQUEST,
} from '../types';

export const loadTrainingListing = function* async({
  page,
  pageSize,
  name,
  sort,
  column,
}: TrainingListingLoadRequestAction): Generator {
  try {
    const result = yield call(
      trainingService.getAll,
      page,
      pageSize,
      name,
      sort,
      column,
    );
    yield put(
      actions.trainingListingLoadSuccess(result as TrainingListingResponse),
    );
  } catch (err) {
    yield put(
      actions.trainingListingLoadError(
        'Failed to load training list',
        err as Error,
      ),
    );
  }
};

export const deleteTraining = function* async({
  trainingId,
}: TrainingDeleteRequestAction): Generator {
  try {
    const result = yield call(trainingService.delete, trainingId);
    yield put(actions.trainingDeleteSuccess(trainingId));
  } catch (err) {
    yield put(
      actions.trainingDeleteError('Failed to delete training', err as Error),
    );
  }
};

export const activateTraining = function* async({
  trainingId,
}: TrainingDeleteRequestAction): Generator {
  try {
    yield call(trainingService.activate, trainingId);
    yield put(actions.trainingActivateSuccess(trainingId));
  } catch (err) {
    yield put(
      actions.trainingActivateError(
        'Review this training before to activate',
        err as Error,
      ),
    );
  }
};

export const archiveTraining = function* async({
  trainingId,
}: TrainingArchiveRequestAction): Generator {
  try {
    yield call(trainingService.archive, trainingId);
    yield put(actions.trainingArchiveSuccess(trainingId));
  } catch (err) {
    yield put(
      actions.trainingArchiveError('Failed to archive training', err as Error),
    );
  }
};

export const loadUserTrainingListing = function* async(): Generator {
  try {
    const result = yield call(trainingService.getAllByUser);
    yield put(
      actions.userTrainingListingLoadSuccess(result as TrainingListingResponse),
    );
  } catch (err) {
    const errorMessage =
      err instanceof Error ? err.message : 'Failed to load trainings';
    yield put(actions.userTrainingListingLoadError(errorMessage, err as Error));
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(TRAINING_LISTING_LOAD_REQUEST, loadTrainingListing),
    yield takeEvery(TRAINING_DELETE_REQUEST, deleteTraining),
    yield takeEvery(TRAINING_ACTIVATE_REQUEST, activateTraining),
    yield takeEvery(
      USER_TRAINING_LISTING_LOAD_REQUEST,
      loadUserTrainingListing,
    ),
    yield takeEvery(TRAINING_ARCHIVE_REQUEST, archiveTraining),
  ];
}
