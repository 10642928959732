import { Loader, ShowErrorAction } from 'src/store/types';

export const TRAINING_TYPES_LOAD_REQUEST = 'TRAINING_TYPES_LOAD_REQUEST';
export const TRAINING_TYPES_LOAD_SUCCESS = 'TRAINING_TYPES_LOAD_SUCCESS';
export const TRAINING_TYPES_LOAD_FAILED = 'TRAINING_TYPES_LOAD_FAILED';

export type TrainingType = {
  id: number;
  name: string;
};

export type TrainingTypesResponse = TrainingType[];

export type TrainingTypesState = {
  trainingTypes: TrainingTypesResponse;
  loader: Loader;
};

export interface TrainingTypesLoadRequestAction {
  type: typeof TRAINING_TYPES_LOAD_REQUEST;
}

export interface TrainingTypesLoadSuccessAction {
  type: typeof TRAINING_TYPES_LOAD_SUCCESS;
  trainingTypes: TrainingTypesResponse;
}

export type TrainingTypesLoadErrorAction = ShowErrorAction<
  typeof TRAINING_TYPES_LOAD_FAILED
>;

export type TrainingTypeActionTypes =
  | TrainingTypesLoadRequestAction
  | TrainingTypesLoadSuccessAction
  | TrainingTypesLoadErrorAction;
