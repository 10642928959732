import { Loader } from './loader';
import { ShowErrorAction } from './error';

export const QUESTION_TYPES_LOAD_REQUEST = 'QUESTION_TYPES_LOAD_REQUEST';
export const QUESTION_TYPES_LOAD_SUCCESS = 'QUESTION_TYPES_LOAD_SUCCESS';
export const QUESTION_TYPES_LOAD_ERROR = 'QUESTION_TYPES_LOAD_ERROR';

export interface QuestionTypesState {
  types: QuestionType[];
  loader: Loader;
}

export interface QuestionType {
  id: number;
  label: string;
  key: string;
  category?: string;
  regex?: string;
  decoration: boolean;
  canBeSmart: boolean;
}

export interface QuestionTypeRequestAction {
  type: typeof QUESTION_TYPES_LOAD_REQUEST;
}

export interface QuestionTypeSuccessAction {
  type: typeof QUESTION_TYPES_LOAD_SUCCESS;
  types: QuestionType[];
}

export type QuestionTypeErrorAction = ShowErrorAction<
  typeof QUESTION_TYPES_LOAD_ERROR
>;

export type QuestionTypeTypes =
  | QuestionTypeRequestAction
  | QuestionTypeSuccessAction
  | QuestionTypeErrorAction;

export enum QuestionTypes {
  INPUT = 'single-line',
  PARAGRAPH = 'paragraph',
  NAME = 'full-name',
  DATE = 'date',
  SECTION = 'section',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  RADIO = 'radio',
  WEBSITE = 'website',
  EMAIL = 'email',
  PHONE = 'phone',
  NUMBER = 'number',
  FILE = 'file',
  ADDRESS = 'address',
  BASE = 'base',
  TIPS = 'tips',
  VIDEO = 'video',
  YES_NO = 'yes-no',
  CONTACT_INFO = 'contact-info',
}
