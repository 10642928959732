import {
  ThreadListActionTypes,
  CommentListingState,
  THREAD_LIST_LOAD_ERROR,
  THREAD_LIST_LOAD_REQUEST,
  THREAD_LIST_LOAD_SUCCESS,
  Loader,
  Thread,
  CommentFromResponse,
  CommentListingIndexedItem,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const threadListingInitialState: CommentListingState = {
  comments: {},
  loader: loaderInitialState,
};

export const commentListingReducer = (
  state = threadListingInitialState,
  action: ThreadListActionTypes,
): CommentListingState => {
  switch (action.type) {
    case THREAD_LIST_LOAD_REQUEST: {
      return {
        ...threadListingInitialState,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }

    case THREAD_LIST_LOAD_SUCCESS: {
      const comments: CommentListingIndexedItem = {};

      if (action.threads) {
        action.threads.forEach((thread: Thread) => {
          if (thread.comments) {
            thread.comments.forEach((c: CommentFromResponse) => {
              comments[c.id] = c;
            });
          }
        });
      }

      return {
        comments,
        loader: {
          ...loaderInitialState,
          completed: true,
          success: true,
        },
      };
    }
    case THREAD_LIST_LOAD_ERROR: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: false,
          completed: true,
          error: action.message.toString(),
        },
      };
    }
    default:
      return state;
  }
};
