import React, { FC } from 'react';
import { ToolbarItem } from './ToolbarItem';

interface TocToolbarItemProps {
  updateEditorContent: (val: string) => void;
}

export const UnorderedListToolbarItem: FC<TocToolbarItemProps> = ({
  updateEditorContent,
}) => {
  return (
    <ToolbarItem
      icon={
        <svg width="1024px" height="12px" viewBox="0 0 1024 1024">
          <path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0z" />
        </svg>
      }
      tooltip="Unordered list"
      execute={() => updateEditorContent('\n* LIST_ITEM')}
    />
  );
};
