import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useQuery } from 'src/hooks/useQuery/useQuery';
import { RoutesEnum } from 'src/shared/enums';
import { useReturnUrl } from 'src/shared/hooks/useReturnUrl';
import { useAuth } from 'src/hooks/useAuth';
import { THIconGoogleApp, THShield } from 'src/theme/media';
import { TwoFAAuthForm } from 'src/features/auth/components';
import { AuthMethod } from 'src/types/common';
import { use2faSMS } from 'src/hooks/use2faSMS';
import * as S from './Styles';
import { useDispatch } from 'react-redux';
import { logoutRequest } from 'src/store/actions/user';

export const TwoFAAuthPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const [isHelpEnabled, setIsHelpEnabled] = useState<boolean>(false);
  const history = useHistory();
  const query = useQuery();
  const { search } = useLocation();
  const { returnUrlHandler } = useReturnUrl();
  const { sendSMS, available } = use2faSMS();
  const { user, userLoader, is2FAauthenticated } = useAuth();
  const { dialCode, phoneNumber } = user;

  useEffect(() => {
    if (isSMSAuthMethod()) {
      handleSendSMSCode();
    }
  }, [user]);

  useEffect(() => {
    if (user.id === 0) {
      history.push({
        pathname: RoutesEnum.LOGIN,
        search,
      });
    } else if (is2FAauthenticated) {
      returnUrlHandler();
    }
  }, [user, history, query, search]);

  const handleNeedHelp = () => {
    setIsHelpEnabled(true);
  };

  const isAppAuthMethod = () => {
    return user.authMethod === AuthMethod.APP;
  };

  const isSMSAuthMethod = () => {
    return user.authMethod === AuthMethod.SMS;
  };

  const handleSendSMSCode = () => {
    if (dialCode && phoneNumber && available && isSMSAuthMethod()) {
      sendSMS({ dialCode, phoneNumber });
    }
  };

  const handleGoBack = async () => {
    history.push({
      pathname: '/login',
    });
    dispatch(logoutRequest());
  };

  return (
    <>
      <S.PageTitle>
        {!isHelpEnabled
          ? 'Two Step verification'
          : 'Help with two-step verification'}
      </S.PageTitle>
      <S.PageSubTitle>Your data is important to us!</S.PageSubTitle>
      {isHelpEnabled && (
        <>
          <S.HelpStepContainer>
            <S.HelpStepNumber>1.</S.HelpStepNumber>
            <span>
              <div>On your smartphone open the Google Authenticator App.</div>
              <S.HelpStepOneShortExplanation>
                If you have a new phone or deleted the Google Authenticator App
                please contact your organization’s HIPAA Administrator.
              </S.HelpStepOneShortExplanation>
            </span>
            <span>
              <img src={THIconGoogleApp} />
            </span>
          </S.HelpStepContainer>
          <S.HelpStepContainer>
            <S.HelpStepNumber>2.</S.HelpStepNumber>
            <span>Enter the code under “TotalHIPAA” here:</span>
          </S.HelpStepContainer>
        </>
      )}
      <S.PageContentContainer>
        {!isHelpEnabled && isAppAuthMethod() && (
          <p>
            Please enter your verification code from Google Authenticator here:
          </p>
        )}
        {isSMSAuthMethod() && (
          <p>
            We just sent you an SMS. Please enter the verification code here:
          </p>
        )}
        <TwoFAAuthForm user={user} userLoader={userLoader} />
      </S.PageContentContainer>
      <S.PageContentBottomContainer>
        <img height={150} width={150} src={THShield} />
        {!isHelpEnabled && isAppAuthMethod() && (
          <S.Label onClick={handleNeedHelp}>Need help?</S.Label>
        )}
        {isSMSAuthMethod() && (
          <S.Label
            style={{
              color: !available ? 'gray' : '',
              cursor: !available ? 'not-allowed' : 'pointer',
            }}
            onClick={handleSendSMSCode}
          >
            Resend SMS Code?
          </S.Label>
        )}
      </S.PageContentBottomContainer>
      <S.GoBackWrapper>
        <S.Label onClick={handleGoBack}>Go back to login</S.Label>
      </S.GoBackWrapper>
    </>
  );
};
