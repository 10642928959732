/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import { getFormsByCategoryId } from '.';
import {
  CategoryListingIndexedItem,
  CategoryState,
  FormState,
  Loader,
  RootState,
} from '../types';

export const getIndexedCategoriesFromState = (
  state: RootState,
): CategoryListingIndexedItem => {
  return state.categoryListing.categories;
};

export const getCategoriesFromState = (state: RootState): CategoryState[] => {
  return Object.values(getIndexedCategoriesFromState(state));
};

export const getSortedCategories = createSelector(
  (state: RootState) => getCategoriesFromState(state),
  (categories: CategoryState[]) => {
    return categories.sort((categoryA, categoryB) => {
      return categoryA.weight - categoryB.weight;
    });
  },
);

export const getCategoriesWithForms = createSelector(
  (state: RootState) => getIndexedCategoriesFromState(state),
  (state: RootState) => getFormsByCategoryId(state),
  (categories: CategoryListingIndexedItem, forms: any) => {
    const categoriesWithForms = { ...categories };

    Object.keys(forms).map((categoryId) => {
      let filteredForms: FormState[] = [...forms[categoryId]];
      filteredForms = filteredForms.sort((formA, formB) => {
        return formA.weight - formB.weight;
      });

      categoriesWithForms[Number(categoryId)] = {
        ...categories[Number(categoryId)],
        forms: filteredForms,
      };

      return null;
    });

    return Object.values(categoriesWithForms).sort((categoryA, categoryB) => {
      return categoryA.weight - categoryB.weight;
    });
  },
);

export const getCategories = createSelector(
  (state: RootState) => getIndexedCategoriesFromState(state),
  (state: RootState) => getFormsByCategoryId(state),
  (categories: CategoryListingIndexedItem, forms: any) => {
    Object.keys(forms).map((categoryId) => {
      const filteredForms: FormState[] = [...forms[categoryId]];

      categories[Number(categoryId)] = {
        ...categories[Number(categoryId)],
        forms: filteredForms,
      };

      return null;
    });

    return Object.values(categories).sort((categoryA, categoryB) => {
      return categoryA.weight - categoryB.weight;
    });
  },
);

export const getCategoryLoader = (state: RootState): Loader =>
  state.categoryListing.loader;
