import React from 'react';
import { message } from 'antd';
import { Message } from 'src/components/Message';

export const error = (title?: string, subtitle?: string) => {
  void message.error(<Message title={title} subtitle={subtitle} error />);
};

export const info = (title?: string, subtitle?: string) => {
  void message.info(<Message title={title} subtitle={subtitle} />);
};

export const success = (title?: string, subtitle?: string) => {
  void message.success(<Message title={title} subtitle={subtitle} />);
};
