import { Spin } from 'antd';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { MainLayout } from 'src/components/MainLayout';
import { OnboardingLayout } from 'src/components/OnboardingLayout';
import { ProtectedRoute } from 'src/components/ProtectedRoute';
import {
  AssessmentPage,
  AssessmentReportPage,
  AssessmentReportsListingPage,
  CategoryListingPage,
  CompanyAssessmentPage,
  CompanyListingPage,
  CompanyProfilePage,
  CompletedInterviewPage,
  DocumentDraftListingPage,
  DocumentDraftPage,
  FormEditPage,
  FormListingPage,
  FormPreviewPage,
  GeneratedPPDocumentsListingPage,
  InterviewPage,
  NotificationListingPage,
  PrivatePage,
  TemplateEditPage,
  TemplateListingPage,
  UserProfilePage,
} from 'src/features/risk-assessment/routes';
import { useAuth } from 'src/hooks/useAuth/useAuth';

import {
  CertificateListPage,
  ExamConfigPage,
  ExamListPage,
  ExamResultPage,
  ExamWizardPage,
  TrainingConfigPage,
  TrainingListPage,
  TrainingResultPage,
  TrainingWizardPage,
} from 'src/features/learning/routes';

import {
  ForgottenPasswordPage,
  LoginPage,
  PasswordChangePage,
  TwoFAAuthPage,
  TwoFATurnOnPage,
  WelcomePage,
} from 'src/features/auth';

import ReactGA from 'react-ga4';
import { NotFound } from 'src/components/NotFound';
import { TrainingQuizLayout } from 'src/components/TrainingQuizLayout';
import { Welcome } from 'src/components/Welcome';
import { TwoFATurnOnAppPage } from 'src/features/auth/routes/TwoFATurnOnAppPage';
import { TwoFATurnOnSMSPage } from 'src/features/auth/routes/TwoFATurnOnSMSPage';
import { SetTrainingPeriodPage } from 'src/features/learning/routes/SetTrainingPeriodPage';
import { TrainingDueDatePage } from 'src/features/learning/routes/TrainingDueDatePage';
import { TrainingPage } from 'src/features/learning/routes/TrainingPage';
import { CompanyUsersPage } from 'src/features/risk-assessment/routes/CompanyUsersPage';
import { DashboardPage } from 'src/features/risk-assessment/routes/DashboardPage';
import { StyleGuidePage } from 'src/features/style-guide/routes';
import { UserManagementPage } from 'src/features/user/routes';
import { useReturnUrl } from 'src/shared/hooks/useReturnUrl';
import { TenantProvider } from 'src/shared/providers/TenantProvider';
import actions from 'src/store/actions';
import { notificationsPollingStart } from 'src/store/actions/notification';
import { getFeatureFlagsLoader } from 'src/store/selectors';
import { UserRole } from 'src/store/types';
import './App.scss';

const isProduction =
  process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production';

if (isProduction) {
  ReactGA.initialize('G-7PD9R0BD3P');
}

const App: FC<unknown> = () => {
  const featuresFetched = useSelector(getFeatureFlagsLoader);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const {
    user,
    userLoader,
    is2FAon,
    is2FAauthenticated,
    hasTraineePendingOnboarding,
    hasOfficerPendingOnboarding,
  } = useAuth();
  const { hasReturnUrl, returnUrlHandler } = useReturnUrl();
  const {
    admin,
    company_moderator,
    officer,
    user: userRole,
    trainee,
  } = UserRole;

  useEffect(() => {
    dispatch(actions.loginCheck());
  }, []);

  useEffect(() => {
    if (!featuresFetched) {
      dispatch(actions.featureFlagLoadRequest());
    }
  }, [featuresFetched]);

  useEffect(() => {
    if (user.id !== 0 && user.isSecondFactorAuthenticated) {
      dispatch(notificationsPollingStart(user.id));
    }
  }, [user?.id]);

  return (
    <TenantProvider>
      <Switch>
        <Route
          exact
          path={[
            '/',
            '/users',
            '/dashboard',
            '/risk-assessment',
            '/library',
            '/forms',
            '/forms/edit/:id',
            '/forms/preview/:id',
            '/forms/categories',
            '/templates',
            '/templates/edit/:id',
            '/settings',
            '/assessments/:assessmentId/forms/:formId/interview',
            '/companies/:id/assessments/:assessmentId/forms/:formId/interview/:interviewId',
            '/my-company',
            '/companies',
            '/companies/:id',
            '/companies/:id/assessments/:assessmentId',
            '/companies/:id/assessments/:assessmentId/assessmentReports',
            '/companies/:id/assessments/:assessmentId/assessmentReports/:documentId',
            '/companies/:id/assessments/:assessmentId/assessmentReports/:documentId/documentDrafts',
            '/companies/:id/assessments/:assessmentId/assessmentReports/:documentId/documentDrafts/:documentDraftId',
            '/notifications',
            '/profile',
            '/user_management',
            '/training-management',
            '/exam-management',
            '/training-config/:id',
            '/exam-config/:id',
            '/my-training',
            '/certificates',
            '/not-found',
          ]}
        >
          <MainLayout>
            <ProtectedRoute requiredRole={officer} exact path="/users">
              <PrivatePage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={officer} exact path="/dashboard">
              <DashboardPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={officer}
              exact
              path="/risk-assessment"
            >
              <AssessmentPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={admin} exact path="/library">
              <PrivatePage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={admin} exact path="/forms">
              <FormListingPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={admin} exact path="/forms/categories">
              <CategoryListingPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={admin} exact path="/templates">
              <TemplateListingPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={admin}
              exact
              path="/templates/edit/:id"
            >
              <TemplateEditPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={userRole} exact path="/settings">
              <PrivatePage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={admin} exact path="/forms/edit/:id">
              <FormEditPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={admin}
              exact
              path="/forms/preview/:id"
            >
              <FormPreviewPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={officer}
              exact
              path="/assessments/:assessmentId/forms/:formId/interview"
            >
              <InterviewPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={company_moderator}
              exact
              path="/companies/:id/assessments/:assessmentId/forms/:formId/interview/:interviewId"
            >
              <CompletedInterviewPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={trainee} exact path="/my-company">
              <CompanyProfilePage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={company_moderator}
              exact
              path="/companies"
            >
              <CompanyListingPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={company_moderator}
              exact
              path="/companies/:id"
            >
              <CompanyProfilePage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={company_moderator}
              exact
              path="/companies/:id/assessments/:assessmentId"
            >
              <CompanyAssessmentPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={userRole}
              exact
              path="/companies/:id/assessments/:assessmentId/assessmentReports"
            >
              <AssessmentReportsListingPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={userRole} exact path="/ppDocuments">
              <GeneratedPPDocumentsListingPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={userRole}
              exact
              path="/companies/:id/assessments/:assessmentId/assessmentReports/:documentId"
            >
              <AssessmentReportPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={userRole}
              exact
              path="/companies/:id/assessments/:assessmentId/assessmentReports/:documentId/documentDrafts"
            >
              <DocumentDraftListingPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={userRole}
              exact
              path="/companies/:id/assessments/:assessmentId/assessmentReports/:documentId/documentDrafts/:documentDraftId"
            >
              <DocumentDraftPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={userRole} exact path="/notifications">
              <NotificationListingPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={userRole} exact path="/profile">
              <UserProfilePage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={admin} exact path="/user_management">
              <UserManagementPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={admin}
              exact
              path="/training-management"
            >
              <TrainingListPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={admin} exact path="/exam-management">
              <ExamListPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={admin}
              exact
              path="/training-config/:id"
            >
              <TrainingConfigPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={admin} exact path="/exam-config/:id">
              <ExamConfigPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={trainee} exact path="/my-training">
              <TrainingPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={trainee} exact path="/certificates">
              <CertificateListPage />
            </ProtectedRoute>
            <Route exact path="/">
              {userLoader?.loading && <Spin size="large" />}
              {userLoader?.completed &&
                !!user.id &&
                is2FAon &&
                !is2FAauthenticated && (
                  <Redirect to={{ pathname: '/2fa/auth', search }} />
                )}
              {userLoader?.completed && !!user.id && !is2FAon && (
                <Redirect to={{ pathname: '/2fa/turn-on', search }} />
              )}
              {userLoader?.completed && hasTraineePendingOnboarding && (
                <Redirect to={{ pathname: '/onboarding/date', search }} />
              )}
              {userLoader?.completed && hasOfficerPendingOnboarding && (
                <Redirect
                  to={{ pathname: '/onboarding/company_set_training_period' }}
                />
              )}
              {
                userLoader?.completed &&
                  !!user.id &&
                  hasReturnUrl() &&
                  (returnUrlHandler() as any) /*TODO remove any*/
              }
              {userLoader?.completed &&
                !!user.id &&
                user.role === UserRole.admin && <Welcome user={user} />}
              {userLoader?.completed &&
                !!user.id &&
                user.role === UserRole.officer && (
                  <Redirect to={{ pathname: '/my-company', search }} />
                )}
              {userLoader?.completed &&
                !!user.id &&
                user.role === UserRole.trainee && (
                  <Redirect to={{ pathname: '/my-training', search }} />
                )}
              {userLoader?.completed && !user.id && (
                <Redirect to={{ pathname: '/login', search }} />
              )}
            </Route>
            <Route exact path={'/not-found'} component={NotFound} />
          </MainLayout>
        </Route>
        <Route
          exact
          path={[
            '/login',
            '/forgotten_password',
            '/reset_password',
            '/2fa/auth',
            '/2fa/turn-on',
            '/2fa/turn-on/sms',
            '/2fa/turn-on/app',
            '/onboarding/welcome',
            '/onboarding/date',
            '/onboarding/company_users',
            '/onboarding/company_set_training_period',
          ]}
        >
          <OnboardingLayout>
            <Route exact path="/2fa/turn-on/sms">
              <TwoFATurnOnSMSPage />
            </Route>
            <Route exact path="/2fa/turn-on/app">
              <TwoFATurnOnAppPage />
            </Route>
            <Route exact path="/2fa/turn-on">
              <TwoFATurnOnPage />
            </Route>
            <Route exact path="/2fa/auth">
              <TwoFAAuthPage />
            </Route>
            <Route exact path="/forgotten_password">
              <ForgottenPasswordPage />
            </Route>
            <Route exact path="/reset_password">
              <PasswordChangePage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route exact path="/onboarding/welcome">
              <WelcomePage />
            </Route>
            <Route exact path="/onboarding/date">
              <TrainingDueDatePage />
            </Route>
            <Route exact path="/onboarding/company_users">
              <CompanyUsersPage />
            </Route>
            <Route exact path="/onboarding/company_set_training_period">
              <SetTrainingPeriodPage />
            </Route>
          </OnboardingLayout>
        </Route>
        <Route
          exact
          path={[
            '/training-quiz/:id',
            '/training-quiz/:id/submission/:submissionId',
            '/exam-quiz',
            '/exam-quiz/:id/submission/:submissionId',
          ]}
        >
          <TrainingQuizLayout>
            <ProtectedRoute
              requiredRole={trainee}
              exact
              path="/training-quiz/:id"
            >
              <TrainingWizardPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={trainee}
              exact
              path="/training-quiz/:id/submission/:submissionId"
            >
              <TrainingResultPage />
            </ProtectedRoute>
            <ProtectedRoute requiredRole={trainee} exact path="/exam-quiz">
              <ExamWizardPage />
            </ProtectedRoute>
            <ProtectedRoute
              requiredRole={trainee}
              exact
              path="/exam-quiz/:id/submission/:submissionId"
            >
              <ExamResultPage />
            </ProtectedRoute>
          </TrainingQuizLayout>
        </Route>
        <Route exact path="/style-guide">
          <StyleGuidePage />
        </Route>
        <MainLayout>
          <Route path={'*'} component={NotFound} />
        </MainLayout>
      </Switch>
    </TenantProvider>
  );
};

export default App;
