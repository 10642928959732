import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Collapse } from 'antd';
import styled from 'styled-components';

import { QuizQuestion } from 'src/store/types';
import { Button } from 'src/components/Button';
import actions from 'src/store/actions';

import { SavingPill } from '../SavingPill';
import { QuestionHeaderForm } from '../QuestionHeaderForm';
import { SingleQuestionForm } from '../SingleQuestionForm';
import { ResourceTypeEnum } from '../../enums';

const { Panel } = Collapse;

interface QuestionConfigFormProps {
  questions?: QuizQuestion[];
  isResourceActive?: boolean;
  isLoading: boolean;
  resourceId?: number;
  resourceType: ResourceTypeEnum;
}

const CollapseContainer = styled(Collapse)`
  margin-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  margin-bottom: 20px;
`;

const GrayPanel = styled(Panel)`
  .ant-collapse-content {
    background-color: whitesmoke;
  }
`;

export const QuestionConfigForm: FC<QuestionConfigFormProps> = ({
  resourceId = 0,
  resourceType,
  isResourceActive,
  isLoading,
  questions = [],
}) => {
  const dispatch = useDispatch();
  const createQuestionAction =
    resourceType == ResourceTypeEnum.TRAINING
      ? actions.trainingQuestionCreateRequest
      : actions.examQuestionCreateRequest;
  return (
    <div>
      <Header>
        <span>Questions</span>
        <SavingPill isLoading={isLoading} />
        <span>Total Questions: {questions.length}</span>
      </Header>
      <CollapseContainer accordion>
        {questions.map((question, i) => {
          return (
            <GrayPanel
              key={question.id}
              header={<QuestionHeaderForm question={question} position={i} />}
            >
              <SingleQuestionForm
                resourceId={resourceId}
                resourceType={resourceType}
                question={question}
                isResourceActive={isResourceActive}
              />
            </GrayPanel>
          );
        })}
      </CollapseContainer>
      <Button
        title="Add New Question"
        onClick={() => dispatch(createQuestionAction(resourceId))}
        disabled={isLoading || isResourceActive}
      />
    </div>
  );
};
