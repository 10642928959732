import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';

import { useHistory } from 'react-router-dom';
import { TopNavbar } from 'src/components/TopNavbar';
import { Button } from 'src/components/Button';
import { RootState, TrainingTypesResponse } from 'src/store/types';
import { getUser, getUserLoader } from 'src/store/selectors/user';
import { logoutRequest } from 'src/store/actions/user';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { FormCreateUser } from 'src/features/user/components/FormCreateUser';
import { getTrainingTypes } from 'src/store/selectors';
import actions from 'src/store/actions';
import { useAuth } from 'src/hooks/useAuth';
import { error } from 'src/shared/utils';
import {
  THIconFacebook,
  THIconLinkedIn,
  THIconTwitter,
  THIconProfileAdmin,
  THIconProfileEnvelope,
  THIconProfileSecurity1,
  THIconProfileSecurity2,
  THIconProfile,
  THIconGear,
  THIconBuilding,
} from 'src/theme/media';
import * as S from './Styles';

export const UserProfilePage: FC = () => {
  const [logoutLoading, setLogoutLoading] = useState<boolean>(false);
  const [formPanelVisible, setFormPanelVisible] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);

  const user = useSelector((state: RootState) => getUser(state));
  const userLoader = useSelector((state: RootState) => getUserLoader(state));
  const trainingTypes: TrainingTypesResponse = useSelector(getTrainingTypes);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAdmin } = useAuth();
  const [userForm] = Form.useForm();

  useEffect(() => {
    dispatch(actions.trainingTypesLoadRequest());
  }, []);

  useEffect(() => {
    if (logoutLoading && userLoader.completed && userLoader.success) {
      history.push('/login');
    }
  }, [userLoader]);

  const handleLogout = () => {
    setLogoutLoading(true);
    dispatch(logoutRequest());
  };

  const handleEditProfile = () => {
    userForm.setFieldsValue({ ...user, trainingTypeId: user.trainingTypeId });
    setUserRole(user.role);
    setFormPanelVisible(true);
  };

  const handleSaveButtonPress = () => {
    userForm.submit();
  };

  const handlePasswordChange = () => {
    error('This functionality is not available at the moment.');
  };

  const initials = user.name.split(' ').map((name) => name.charAt(0));

  return (
    <>
      <TopNavbar
        title={`Profile`}
        prevRoute={null}
        extraOptions={
          <Button
            className="btn"
            title="Logout"
            onClick={handleLogout}
            loading={logoutLoading}
          />
        }
      />
      <S.PageContentContainer>
        <S.UserContentContainer>
          <S.UserContainer>
            <span>
              <S.UserAvatar size={48}>
                {initials[0]}
                {initials[1]}
              </S.UserAvatar>
            </span>
            <span>
              <S.AvatarUserName>{user.name}</S.AvatarUserName>
              {!isAdmin && (
                <S.UserCompanyContentContainer>
                  <img src={THIconBuilding} />
                  {user.company?.name}
                </S.UserCompanyContentContainer>
              )}
            </span>
          </S.UserContainer>
          <S.SectionDivider />
          <div>
            <S.SectionTitle>Personal Info</S.SectionTitle>
            <S.PersonalInfoContentContainer>
              <span>
                <S.ProfileItemContentContainer>
                  <S.IconImage src={THIconProfile} />
                  <S.UserName>{user.name}</S.UserName>
                </S.ProfileItemContentContainer>
                <S.ProfileItemContentContainer>
                  <S.IconImage src={THIconProfileEnvelope} />
                  {user.email}
                </S.ProfileItemContentContainer>
              </span>
              <span>
                {/* <S.RightButtonRounded title="Edit" onClick={handleEditProfile} /> */}
              </span>
            </S.PersonalInfoContentContainer>
          </div>
          <S.SectionDivider />
          <div style={{ display: 'none' }}>
            <S.SectionTitle>HIPAA admin</S.SectionTitle>
            <S.HipaaAdminContentContainer>
              <div>
                <S.IconImage src={THIconProfileAdmin} />
                John Alden - johnalden@acme.com
              </div>
              <S.RightButtonRounded
                title="Contact"
                onClick={handlePasswordChange}
              />
            </S.HipaaAdminContentContainer>
          </div>
          <S.SectionDivider style={{ display: 'none' }} />
          <div style={{ display: 'none' }}>
            <S.SectionTitle>Security</S.SectionTitle>
            <S.SecurityContentContainer>
              <div>
                <S.ProfileItemContentContainer>
                  <S.IconImage src={THIconProfileSecurity1} />
                  Password
                  <S.RightButtonRounded
                    title="Change"
                    onClick={handlePasswordChange}
                  />
                </S.ProfileItemContentContainer>
              </div>
              <div>
                <S.ProfileItemContentContainer>
                  <S.IconImage src={THIconProfileSecurity2} />
                  Two-step Verification
                  <S.RightButtonRounded
                    title="Change"
                    onClick={handlePasswordChange}
                  />
                </S.ProfileItemContentContainer>
              </div>
            </S.SecurityContentContainer>
          </div>
          <S.SectionDivider style={{ display: 'none' }} />
          <div>
            <S.SectionTitle>Find us on the web</S.SectionTitle>
            <S.SocialIconsContainer>
              <a
                href="https://www.linkedin.com/company/total-hipaa-compliance/"
                target={'_blank'}
                rel="noreferrer"
              >
                <img src={THIconLinkedIn} />
              </a>
              <a
                href="https://twitter.com/totalhipaa"
                target={'_blank'}
                rel="noreferrer"
              >
                <img src={THIconTwitter} />
              </a>
              <a
                href="https://www.facebook.com/TotalHipaaCompliance/"
                target={'_blank'}
                rel="noreferrer"
              >
                <img src={THIconFacebook} />
              </a>
            </S.SocialIconsContainer>
          </div>
        </S.UserContentContainer>
        <SlidingPanel
          title="Edit profile"
          visible={formPanelVisible}
          saveLabel="Save"
          onClose={() => setFormPanelVisible(false)}
          onSave={handleSaveButtonPress}
        >
          <FormCreateUser
            userId={user ? user.id : 0}
            userForm={userForm}
            trainingTypes={trainingTypes}
            editingProfile
            userRole={userRole}
            setUserRole={setUserRole}
          />
        </SlidingPanel>
        <S.GearImage height={383} width={383} src={THIconGear} />
      </S.PageContentContainer>
    </>
  );
};
