import React, { FC } from 'react';
import { ToolbarItem } from './ToolbarItem';

interface TextStyleToolbarItemsProps {
  updateEditorContent: (val: string) => void;
}

interface TextStyleItem {
  icon: React.SVGProps<SVGSVGElement>;
  name: string;
  getToken: (text?: string) => string;
}

export const TextStyleToolbarItems: FC<TextStyleToolbarItemsProps> = ({
  updateEditorContent,
}) => {
  const bold: TextStyleItem = {
    name: 'Bold',
    getToken: (text?: string) => `**${text ?? 'TEXT'}**`,
    icon: (
      <svg
        width="12"
        height="12"
        viewBox="0 -20 200 250"
        version="1.1"
        id="svg8"
      >
        <path
          d="M148.004,94.812c18.332-8.126,28.671-23.362,28.671-42.752c0-17.261-6.954-31.206-20.11-40.328
	C145.653,4.166,130.438,0,113.721,0H16.957v34h17v134h-17v34h90.905c14.819,0,35.992-2.245,52.705-12.94
	c16.241-10.393,24.476-26.161,24.476-46.868C185.043,118.342,171.057,100.763,148.004,94.812z M103.12,80H73.957V34h26.096
	c25.961,0,36.551,6.34,36.551,21.884C136.604,75.816,118.396,80,103.12,80z M73.957,115h30.838c28.537,0,40.177,7.436,40.177,25.663
	c0,18.14-13.987,27.337-41.572,27.337H73.957V115z"
        />
      </svg>
    ),
  };
  const underline: TextStyleItem = {
    name: 'Underline',
    getToken: (text?: string) => `[u]${text ?? 'TEXT'}[/u]`,
    icon: (
      <svg
        width="12"
        height="12"
        viewBox="0 0 135.46666 135.46667"
        version="1.1"
      >
        <g>
          <g transform="matrix(1.8416256,0,0,3.6983265,-41.706689,-148.36772)">
            <g transform="scale(1.2384069,0.80748906)">
              <g transform="matrix(1.1442966,0,0,0.87389931,0.66291787,0.2531352)">
                <path d="m 59.919598,63.194872 -4.71092,20.283127 q -1.592117,6.739233 -5.387024,9.792607 -3.794908,3.031564 -10.992147,3.031564 -6.412085,0 -9.705367,-2.290031 -3.293282,-2.31184 -3.293282,-6.97914 0,-0.959632 0.152668,-1.941074 0.152669,-0.981441 0.327148,-1.788404 l 4.6673,-20.108649 h 8.462208 l -4.6673,20.130458 q -0.152669,0.610675 -0.261718,1.352209 -0.08724,0.719724 -0.08724,1.19954 0,2.050122 1.221349,3.118803 1.24316,1.046871 4.100245,1.046871 2.769847,0 4.471012,-1.526687 1.722976,-1.526687 2.464509,-4.754539 l 4.77635,-20.566655 z" />
              </g>
              <g transform="matrix(0,-0.26790228,-2.2159207,0,223.90426,86.300709)">
                <path d="M -7.6520414,91.352356 H -13.173664 V 67.486674 h 5.5216226 z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    ),
  };
  const italic: TextStyleItem = {
    name: 'Italic',
    getToken: (text?: string) => `_${text ?? 'TEXT'}_`,
    icon: (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="12"
        height="12"
        viewBox="0 0 92 92"
      >
        <path
          id="XMLID_2270_"
          d="M50.7,30.6c0.8,0.9,1.1,2.3,0.8,3.5L44.2,72c1.8-0.6,3.9,0.1,4.8,1.8c1.1,1.9,0.4,4.4-1.5,5.4l-2.5,1.4
	c-1,0.5-2,0.8-3.1,0.8c-1.3,0-2.6-0.4-3.7-1.3c-2-1.5-3-4-2.5-6.5L42.8,37h-1.7c-2.2,0-4-1.8-4-4s1.8-4,4-4h6.6
	C48.8,29,49.9,29.7,50.7,30.6z M48.9,13.4c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7S51.4,13.4,48.9,13.4z"
        />
      </svg>
    ),
  };

  const textStyles = [bold, underline, italic];

  const execute = (textStyle: TextStyleItem, text?: string) => {
    updateEditorContent(textStyle.getToken(text));
  };

  return (
    <>
      {textStyles.map((textStyle) => (
        <ToolbarItem
          icon={textStyle.icon}
          tooltip={textStyle.name}
          key={textStyle.name}
          execute={(text?: string) => execute(textStyle, text)}
        />
      ))}
    </>
  );
};
