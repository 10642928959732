import React, { FC } from 'react';
import { ToolbarItem } from './ToolbarItem';

interface TocToolbarItemProps {
  updateEditorContent: (val: string) => void;
}

export const TocToolbarItem: FC<TocToolbarItemProps> = ({
  updateEditorContent,
}) => {
  return (
    <ToolbarItem
      icon={
        <svg width="12" height="12" viewBox="0 0 135.46666 135.46667">
          <g>
            <g transform="matrix(1.8416256,0,0,3.6983265,-41.706689,-148.36772)">
              <g transform="scale(0.83061094,1.2039331)">
                <path d="m 58.077946,41.343174 h -7.524073 l -4.491742,19.475025 h -6.25925 L 44.294623,41.343174 H 36.77055 L 37.840784,36.67306 H 59.14818 Z" />
                <path d="m 84.006809,45.656543 q 0,2.967469 -1.005372,5.837643 -1.005372,2.870175 -2.918821,5.010644 -2.091822,2.335057 -4.880918,3.583664 -2.789096,1.232391 -6.421407,1.232391 -4.945781,0 -7.751093,-2.545861 -2.789096,-2.562076 -2.789096,-7.037603 0,-3.14584 1.070235,-5.983584 1.070234,-2.837743 3.064762,-4.994427 1.945881,-2.091822 4.816055,-3.340429 2.870175,-1.248607 6.259251,-1.248607 4.961996,0 7.751092,2.562076 2.805312,2.562077 2.805312,6.924093 z m -8.902405,8.237563 q 1.102666,-1.443195 1.718861,-3.340429 0.632412,-1.913449 0.632412,-4.394448 0,-2.594508 -1.248607,-4.053918 -1.232392,-1.475627 -3.535017,-1.475627 -1.508058,0 -2.886391,0.697274 -1.362116,0.681059 -2.513429,2.091822 -1.118882,1.345901 -1.79994,3.356645 -0.681058,1.994528 -0.681058,4.491742 0,2.77288 1.264822,4.183644 1.281039,1.410764 3.535017,1.410764 1.556705,0 2.9999,-0.762137 1.443195,-0.778353 2.51343,-2.205332 z" />
                <path d="m 96.671252,61.288454 q -4.897134,0 -7.556504,-2.39992 -2.659371,-2.416136 -2.659371,-6.907877 0,-3.551233 1.167529,-6.437623 1.183744,-2.88639 3.243134,-4.994428 2.010744,-2.043175 4.816056,-3.194488 2.821527,-1.151313 5.918724,-1.151313 2.49721,0 4.63768,0.518902 2.14047,0.502686 3.85933,1.491842 l -1.42698,5.853858 h -0.71349 q -1.54049,-1.653998 -3.06476,-2.432351 -1.52427,-0.778352 -3.58367,-0.778352 -3.616091,0 -5.967363,2.902606 -2.335058,2.902605 -2.335058,7.394347 0,2.88639 1.297254,4.183644 1.297254,1.297254 3.794468,1.297254 2.237759,0 4.199859,-0.891862 1.9621,-0.908078 3.71339,-2.302626 h 0.64863 l -1.41077,5.77278 q -0.7297,0.291883 -1.70264,0.71349 -0.97294,0.405392 -1.8648,0.664843 -1.16753,0.340529 -2.189121,0.518901 -1.005372,0.178373 -2.821527,0.178373 z" />
              </g>
            </g>
          </g>
        </svg>
      }
      tooltip="Table of content"
      execute={() => updateEditorContent('\n\n[toc]\n\n')}
    />
  );
};
