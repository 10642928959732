import React, { FC, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Tabs, message } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import { Button } from 'src/components/Button';
import { TopNavbar } from 'src/components/TopNavbar';
import {
  RootState,
  TemplateLoader,
  TemplateProcessingState,
  TemplateState,
} from 'src/store/types';
import { SidePanel } from 'src/components/SidePanel';
import {
  getAssessmentTypes,
  getLoadedTemplate,
  getTemplateFoldersOptions,
  getTemplateLoader,
} from 'src/store/selectors';
import {
  templateLoadRequest,
  templateUpdateRequest,
} from 'src/store/actions/template';
import {
  TemplateEditor,
  TemplatePreview,
} from 'src/features/risk-assessment/components/TemplateEditor';
import { questionsLoadRequest } from 'src/store/actions/questionListing';
import { confirmDialog } from 'src/shared/utils';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { FormCreateTemplate } from 'src/features/risk-assessment/components/FormCreateTemplate';
import { templatesLoadRequest } from 'src/store/actions/templateListing';
import { assessmentTypesLoadAllRequest } from 'src/store/actions/assessmentTypes';
import { constantsLoadRequest } from 'src/store/actions/constantListing';
import { MarkdownHelpSection } from './MarkdownHelpSection';
import { QuestionTokenHelpSection } from './QuestionTokenHelpSection';
import { ConditionalsHelpSection } from './ConditionalsHelpSection';
import { VariablesAndConstantsHelpSection } from './VariablesAndConstantsHelpSection';
import { TemplateFunctionsHelpSection } from './TemplateFunctionsHelpSection';
import { FootnoteHelpSection } from './FootnoteHelpSection';
import { TemplateEditorHelpSection } from './EditorHelpSection';

enum Tab {
  EDIT_TEMPLATE = 'Edit Template',
  PREVIEW = 'Preview',
}

interface RouteParams {
  id: string;
}

const Column = styled.div`
  display: flex;
`;

const MainColumn = styled.div`
  width: calc(99% - ${({ theme }) => `${theme.sidebar.width}px`});
`;

const PageContentContainer = styled.div`
  margin-top: 20px;
`;

const HelpTitle = styled.div`
  display: flex;
  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.deepBlue.main};
  }
`;

const TabsContainer = styled.div`
  .ant-tabs-card {
    .ant-tabs-content {
      height: 73vh;
      width: 100%;

      margin-top: -16px;
      .ant-tabs-tabpane {
        height: 100%;
        padding: 16px;
        background: #fff;
      }
    }

    .ant-tabs-tab {
      background: transparent;
      border-color: transparent;
    }

    .ant-tabs-tab-active {
      background: #fff;
    }
  }
`;

const { TabPane } = Tabs;

const REFRESH_TEMPLATE_TOAST_ID = 'refresh-template-toast-id';

export const TemplateEditPage: FC = () => {
  const [value, setValue] = useState<string>('');
  const [pristine, setPristine] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [markdownHelpOpen, setMarkdownHelpOpen] = useState(false);
  const [questionTokenHelpOpen, setQuestionTokenHelpOpen] = useState(false);
  const [conditionalsHelpOpen, setConditionalsHelpOpen] = useState(false);
  const [formPanelVisible, setFormPanelVisible] = useState<boolean>(false);
  const [hasConflicts, setHasConflicts] = useState<boolean>(false);
  const [variablesAndConstantsHelpOpen, setVariablesAndConstantsHelpOpen] =
    useState<boolean>(false);
  const [templateFunctionsHelpOpen, setTemplateFunctionsHelpOpen] =
    useState<boolean>(false);
  const [templateFootnoteHelpOpen, setTemplateFootnoteHelpOpen] =
    useState<boolean>(false);
  const [templateEditorHelpOpen, setTemplateEditorHelpOpen] =
    useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.EDIT_TEMPLATE);
  const [adjustEditorScroll, setAdjustEditorScroll] = useState<boolean>(false);
  const [adjustPreviewScroll, setAdjustPreviewScroll] =
    useState<boolean>(false);
  const [editorScrollTop, setEditorScrollTop] = useState<number>(0);
  const [previewScrollTop, setPreviewScrollTop] = useState<number>(0);

  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams<RouteParams>();
  const templateId = Number(id);

  const template = useSelector(
    (state: RootState): TemplateState => getLoadedTemplate(state),
  );
  const templateLoader = useSelector(
    (state: RootState): TemplateLoader => getTemplateLoader(state),
  );
  const assessmentTypes = useSelector(getAssessmentTypes);
  const templateFolders = useSelector(getTemplateFoldersOptions);

  let refreshTemplateTimeout: NodeJS.Timeout;
  const isRefreshingTemplate = useRef(false);

  useEffect(() => {
    dispatch(templatesLoadRequest());
    dispatch(assessmentTypesLoadAllRequest());
    dispatch(constantsLoadRequest());
  }, []);

  // Prevent the user to reload or quit if there are unsaved changes
  useEffect(() => {
    const preventNavigationHandler = (e: Event) => {
      e.preventDefault();
      e.returnValue = false;
    };

    if (!pristine) {
      window.addEventListener('beforeunload', preventNavigationHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigationHandler);
    };
  }, [pristine]);

  // Load template
  useEffect(() => {
    if (templateId) {
      setValue('');
      dispatch(templateLoadRequest(templateId));
    }
  }, [templateId]);

  useEffect(() => {
    if (template.processingStatus == TemplateProcessingState.PROCESSING) {
      isRefreshingTemplate.current = true;
      toast.loading('Updating documents, this may take a few minutes', {
        id: REFRESH_TEMPLATE_TOAST_ID,
      });
      refreshTemplateTimeout = setTimeout(() => {
        dispatch(templateLoadRequest(template.id));
      }, 3000);
    } else if (isRefreshingTemplate.current) {
      isRefreshingTemplate.current = false;
      toast.success('All documents updated', { id: REFRESH_TEMPLATE_TOAST_ID });
      clearTimeout(refreshTemplateTimeout);
    }
    return () => refreshTemplateTimeout && clearTimeout(refreshTemplateTimeout);
  }, [template]);

  // Whenever the template stored value change, reload the editor
  useEffect(() => {
    if (template && template.id && template.id === templateId) {
      setValue(
        (template.needsReview ? template.mergeBody : template.body) || ' ',
      );
      if (template.assessmentType) {
        dispatch(questionsLoadRequest(template.assessmentType.id));
      }
    }
  }, [template]);

  // Infer saving result
  useEffect(() => {
    if (isSaving && templateLoader.success) {
      void message.success('Template saved');
      setIsSaving(false);
      setPristine(true);
      setFormPanelVisible(false);
    }
    if (isSaving && templateLoader.completed && templateLoader.error != '') {
      void message.error('Unable to save template');
      setIsSaving(false);
    }
  }, [templateLoader.completed]);

  // Auto-scroll preview tab
  useLayoutEffect(() => {
    if (adjustPreviewScroll && selectedTab === Tab.PREVIEW) {
      const previewContainer = document.querySelector(
        '.wmde-markdown.wmde-markdown-color.w-md-editor-preview',
      );

      if (previewContainer) {
        previewContainer.scrollTop = Math.round(editorScrollTop * 1.1);
        setAdjustPreviewScroll(false);
      }
    }
  }, [selectedTab]);

  // Auto-scroll editor tab
  useLayoutEffect(() => {
    if (adjustEditorScroll && selectedTab === Tab.EDIT_TEMPLATE) {
      const editorArea = document.querySelector('.ant-input.editor');

      if (editorArea) {
        editorArea.scrollTop = Math.round(previewScrollTop * 0.9);
        setAdjustEditorScroll(false);
      }
    }
  }, [selectedTab]);

  const [templateForm] = Form.useForm();

  const onCancel = (): void => {
    history.push('/templates');
  };

  const handleChange = (newValue?: string): void => {
    if (newValue) {
      setPristine(false);
      setValue(newValue);
    }
  };

  const handleSaveTemplate = () => {
    if (value && value != '') {
      const templateObj: Partial<TemplateState> = {
        id: template.id,
        body: value,
        needsReview: false,
        mergeBody: '',
      };
      dispatch(templateUpdateRequest(template.id, templateObj));
      setIsSaving(true);
    }
  };

  const handleSaveButtonPress = () => {
    setIsSaving(true);
    templateForm.submit();
  };

  const saveEditorScrollPosition = () => {
    const editorArea = document.querySelector('.ant-input.editor');

    if (editorArea) {
      setEditorScrollTop(editorArea.scrollTop);
      setAdjustPreviewScroll(true);
    }
  };

  const savePreviewScrollPosition = () => {
    const previewContainer = document.querySelector(
      '.wmde-markdown.wmde-markdown-color.w-md-editor-preview',
    );

    if (previewContainer) {
      setPreviewScrollTop(previewContainer.scrollTop);
      setAdjustEditorScroll(true);
    }
  };

  const handleTabChange = (value: string) => {
    if (value === Tab.PREVIEW) {
      saveEditorScrollPosition();
    }

    if (value === Tab.EDIT_TEMPLATE) {
      savePreviewScrollPosition();
    }

    setSelectedTab(value as Tab);
  };

  const isTemplateProcessing =
    template.processingStatus === TemplateProcessingState.PROCESSING;

  return (
    <>
      <Prompt
        when={!pristine}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <TopNavbar
        title={!template ? '' : template.title}
        prevRoute={{ name: 'Templates', url: '/templates' }}
        extraOptions={[
          <Button
            onClick={onCancel}
            title="Cancel"
            variant="secondary"
            key={1}
          />,
          <Button
            onClick={() =>
              confirmDialog({
                onOk: handleSaveTemplate,
                text: 'Updating your template and documents based on it may take a little time and we will email you when the template is ready.',
              })
            }
            title="Save"
            key={2}
            disabled={pristine || hasConflicts || isTemplateProcessing}
            loading={templateLoader.loading}
          />,
          <Button
            onClick={() => {
              if (template?.assessmentType)
                templateForm.setFieldsValue({
                  assessmentTypeId: template.assessmentType.id,
                });
              setFormPanelVisible(true);
            }}
            title="Save as"
            key={3}
            disabled={hasConflicts || isTemplateProcessing}
            loading={templateLoader.loading}
          />,
        ]}
      />
      <PageContentContainer>
        <MainColumn>
          <TabsContainer>
            <Tabs
              type="card"
              defaultActiveKey={Tab.EDIT_TEMPLATE}
              onTabClick={handleTabChange}
              destroyInactiveTabPane
            >
              <TabPane
                tab={Tab.EDIT_TEMPLATE}
                key={Tab.EDIT_TEMPLATE}
                disabled={isSaving || templateLoader.loading}
              >
                <TemplateEditor
                  source={value}
                  templateId={templateId}
                  errorMessage={templateLoader.compilationError}
                  onChange={handleChange}
                  setHasConflicts={setHasConflicts}
                  isTemplateProcessing={isTemplateProcessing}
                />
              </TabPane>
              <TabPane
                tab={Tab.PREVIEW}
                key={Tab.PREVIEW}
                disabled={isSaving || templateLoader.loading}
              >
                <TemplatePreview source={value} onChange={handleChange} />
              </TabPane>
            </Tabs>
          </TabsContainer>
        </MainColumn>
        <Column>
          <SidePanel fixed confirmEnabled={false} hideFooter>
            <HelpTitle>
              <h2>Help</h2>
            </HelpTitle>
            <MarkdownHelpSection
              markdownHelpOpen={markdownHelpOpen}
              setMarkdownHelpOpen={setMarkdownHelpOpen}
            />
            <QuestionTokenHelpSection
              questionTokenHelpOpen={questionTokenHelpOpen}
              setQuestionTokenHelpOpen={setQuestionTokenHelpOpen}
            />
            <ConditionalsHelpSection
              conditionalsHelpOpen={conditionalsHelpOpen}
              setConditionalsHelpOpen={setConditionalsHelpOpen}
            />
            <VariablesAndConstantsHelpSection
              variablesAndConstantsHelpOpen={variablesAndConstantsHelpOpen}
              setVariablesAndConstantsHelpOpen={
                setVariablesAndConstantsHelpOpen
              }
            />
            <TemplateFunctionsHelpSection
              templateFunctionsHelpOpen={templateFunctionsHelpOpen}
              setTemplateFunctionsHelpOpen={setTemplateFunctionsHelpOpen}
            />
            <FootnoteHelpSection
              templateFootnoteHelpOpen={templateFootnoteHelpOpen}
              setTemplateFootnoteHelpOpen={setTemplateFootnoteHelpOpen}
            />
            <TemplateEditorHelpSection
              templateEditorHelpOpen={templateEditorHelpOpen}
              setTemplateEditorHelpOpen={setTemplateEditorHelpOpen}
            />
          </SidePanel>
        </Column>
        <SlidingPanel
          title="Save as"
          visible={formPanelVisible}
          saveLabel="Create template"
          onClose={() => {
            setFormPanelVisible(false);
            templateForm.resetFields();
          }}
          onSave={handleSaveButtonPress}
        >
          <FormCreateTemplate
            templateForm={templateForm}
            assessmentTypes={assessmentTypes}
            parentTemplateId={templateId}
            templateFolders={templateFolders}
            parentTemplateBody={value}
          />
        </SlidingPanel>
        <Toaster />
      </PageContentContainer>
    </>
  );
};
