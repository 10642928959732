import React, { FC } from 'react';
import styled from 'styled-components';
import { AssessmentFilter } from 'src/store/types';
import { Button } from 'src/components/Button';

interface AssessmentFiltersProps {
  activeFilter?: AssessmentFilter;
  onChange: (filter: AssessmentFilter) => void;
}

const AssessmentFiltersContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
  margin-top: 12px;

  .btn {
    margin-right: 5px;

    > span {
      font-family: ${({ theme }) => theme.fontFamilies.primary};
      font-size: 13px;
      font-weight: 600;
    }
  }
`;

const FILTERS = [AssessmentFilter.last, AssessmentFilter.older];
const FILTER_NAMES = {
  [AssessmentFilter.last]: 'Last Assessment',
  [AssessmentFilter.older]: 'Older Assessments',
};

export const AssessmentFilters: FC<AssessmentFiltersProps> = ({
  activeFilter = AssessmentFilter.last,
  onChange,
}) => (
  <AssessmentFiltersContainer>
    {FILTERS.map((filter) => (
      <Button
        altered={activeFilter === filter}
        className="btn"
        onClick={() => onChange(filter)}
        variant={'radio'}
        title={FILTER_NAMES[filter]}
        key={filter}
      />
    ))}
  </AssessmentFiltersContainer>
);
