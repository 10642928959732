import { Loader } from './loader';
import {
  AssessmentFromResponse,
  UserListingState,
  InterviewListingState,
  InterviewLoadAnswersRequestAction,
  InterviewLoadAnswersErrorAction,
  InterviewLoadAnswersSuccessAction,
  AssessmentLoadSuggestionsErrorAction,
  AssessmentLoadSuggestionsRequestAction,
  AssessmentLoadSuggestionsSuccessAction,
  ShowErrorAction,
  UserSummaryListingState,
} from '../types';
import {
  AnswerLoadActivityLogErrorAction,
  AnswerLoadActivityLogRequestAction,
  AnswerLoadActivityLogSuccessAction,
  InterviewLoadActivityLogErrorAction,
  InterviewLoadActivityLogRequestAction,
  InterviewLoadActivityLogSuccessAction,
  InterviewUpdateErrorAction,
  InterviewUpdateRequestAction,
  InterviewUpdateSuccessAction,
  InterviewCreateAssigneeRequestAction,
  InterviewDeleteAssigneeRequestAction,
  InterviewLoadAssigneesRequestAction,
  InterviewAssignOwnerRequestAction,
  InterviewRemoveOwnerRequestAction,
  InterviewCreateAssigneeSuccessAction,
  InterviewDeleteAssigneeSuccessAction,
  InterviewLoadAssigneesSuccessAction,
  InterviewAssignOwnerSuccessAction,
  InterviewRemoveOwnerSuccessAction,
  InterviewCreateAssigneeErrorAction,
  InterviewDeleteAssigneeErrorAction,
  InterviewLoadAssigneesErrorAction,
  InterviewAssignOwnerErrorAction,
  InterviewRemoveOwnerErrorAction,
} from './interview';
import {
  AddUserToListAction,
  Clear2FAErrorAction,
  Clear2FARequestAction,
  Clear2FASuccessAction,
  MultipleUsersSaveSuccessAction,
  UserDeleteRequestAction,
  UserDeleteErrorAction,
  UserDeleteSuccessAction,
  UserFromResponse,
  UserUpdateSuccessAction,
  UserUpdateErrorAction,
  UserUpdateRequestAction,
  GetAdminUsersSuccessAction,
} from './user';

export enum TypeOfTraining {
  HIPAA_PRIME = 'HIPAA_PRIME',
  GLBA = 'GLBA',
  TRAINING_ONLY = 'TRAINING_ONLY',
  CMMC = 'CMMC',
}

export enum CompanyType {
  AGENT = 'AGENT',
  EMPLOYER = 'EMPLOYER',
  BUSINESS_ASSOCIATE = 'BUSINESS_ASSOCIATE',
  MEDICAL_PRACTICE = 'MEDICAL_PRACTICE',
  DENTAL_PRACTICE = 'DENTAL_PRACTICE',
  COLLEGE_UNIVERSITY = 'COLLEGE_UNIVERSITY',
  OTHER = 'OTHER',
}

export interface CompanyFromResponse {
  id: number;
  legacyId?: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  users: UserFromResponse[];
  assessments: AssessmentFromResponse[];
  active: boolean;
  address: string;
  employees: number;
  licenses: number;
  state: string;
  typeOfTraining: TypeOfTraining;
  companyType: CompanyType;
  trainingType?: { name: string; id: number };
}

export interface CompanyState {
  id: number;
  legacyId?: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  active?: boolean;
  assessmentIds?: number[];
  lastAssessmentId?: number;
  typeOfTraining?: TypeOfTraining;
  companyType?: CompanyType;
  licenses?: number;
  employees?: number;
  address?: string;
  state?: string;
  users?: UserFromResponse[];
  trainingType?: { name: string; id: number };
}

export interface SelectedCompanyState {
  company: CompanyState;
  interviewListing: InterviewListingState;
  userListing: UserListingState;
  userSummaryListing: UserSummaryListingState;
  loader: Loader;
}

export interface CompanyInput {
  name: string;
  address: string;
  typeOfTraining: TypeOfTraining;
  companyType: CompanyType;
  licenses?: number;
  employees?: number;
  trainingTypeId?: number;
}

export const COMPANY_LOAD_REQUEST = 'COMPANY_LOAD_REQUEST';
export const COMPANY_LOAD_SUCCESS = 'COMPANY_LOAD_SUCCESS';
export const COMPANY_LOAD_ERROR = 'COMPANY_LOAD_ERROR';
export const COMPANY_UPDATE_REQUEST = 'COMPANY_UPDATE_REQUEST';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_ERROR = 'COMPANY_UPDATE_ERROR';
export const COMPANY_SAVE_REQUEST = 'COMPANY_SAVE_REQUEST';
export const COMPANY_SAVE_SUCCESS = 'COMPANY_SAVE_SUCCESS';
export const COMPANY_SAVE_ERROR = 'COMPANY_SAVE_ERROR';

export interface CompanyQueryOptions {
  withAssessments?: boolean;
  withUsers?: boolean;
  withTrainingType?: boolean;
}

export interface CompanyLoadRequestAction {
  type: typeof COMPANY_LOAD_REQUEST;
  companyId: number;
  options?: CompanyQueryOptions;
}

export interface CompanyLoadSuccessAction {
  type: typeof COMPANY_LOAD_SUCCESS;
  company: CompanyFromResponse;
}

export type CompanyLoadErrorAction = ShowErrorAction<typeof COMPANY_LOAD_ERROR>;

export interface CompanyUpdateRequestAction {
  type: typeof COMPANY_UPDATE_REQUEST;
  companyId: number;
  companyInput: CompanyInput;
}

export interface CompanyUpdateSuccessAction {
  type: typeof COMPANY_UPDATE_SUCCESS;
  updatedCompany: CompanyFromResponse;
}

export type CompanyUpdateErrorAction = ShowErrorAction<
  typeof COMPANY_UPDATE_ERROR
>;

export interface CompanySaveRequestAction {
  type: typeof COMPANY_SAVE_REQUEST;
  companyInput: CompanyInput;
}

export interface CompanySaveSuccessAction {
  type: typeof COMPANY_SAVE_SUCCESS;
  company: CompanyFromResponse;
}

export type CompanySaveErrorAction = ShowErrorAction<typeof COMPANY_SAVE_ERROR>;

export type CompanyActionTypes =
  | CompanyLoadRequestAction
  | CompanyLoadSuccessAction
  | CompanyLoadErrorAction
  | InterviewUpdateRequestAction
  | InterviewUpdateErrorAction
  | InterviewUpdateSuccessAction
  | CompanyUpdateRequestAction
  | CompanyUpdateSuccessAction
  | CompanyUpdateErrorAction
  | CompanySaveRequestAction
  | CompanySaveSuccessAction
  | CompanySaveErrorAction
  | AddUserToListAction
  | MultipleUsersSaveSuccessAction
  | InterviewLoadAnswersRequestAction
  | InterviewLoadAnswersErrorAction
  | InterviewLoadAnswersSuccessAction
  | InterviewLoadActivityLogRequestAction
  | InterviewLoadActivityLogErrorAction
  | InterviewLoadActivityLogSuccessAction
  | AnswerLoadActivityLogRequestAction
  | AnswerLoadActivityLogErrorAction
  | AnswerLoadActivityLogSuccessAction
  | AssessmentLoadSuggestionsErrorAction
  | AssessmentLoadSuggestionsRequestAction
  | AssessmentLoadSuggestionsSuccessAction
  | Clear2FARequestAction
  | Clear2FAErrorAction
  | Clear2FASuccessAction
  | UserDeleteRequestAction
  | UserDeleteErrorAction
  | UserDeleteSuccessAction
  | UserUpdateRequestAction
  | UserUpdateErrorAction
  | UserUpdateSuccessAction
  | GetAdminUsersSuccessAction
  | InterviewCreateAssigneeRequestAction
  | InterviewDeleteAssigneeRequestAction
  | InterviewLoadAssigneesRequestAction
  | InterviewAssignOwnerRequestAction
  | InterviewRemoveOwnerRequestAction
  | InterviewCreateAssigneeSuccessAction
  | InterviewDeleteAssigneeSuccessAction
  | InterviewLoadAssigneesSuccessAction
  | InterviewAssignOwnerSuccessAction
  | InterviewRemoveOwnerSuccessAction
  | InterviewCreateAssigneeErrorAction
  | InterviewDeleteAssigneeErrorAction
  | InterviewLoadAssigneesErrorAction
  | InterviewAssignOwnerErrorAction
  | InterviewRemoveOwnerErrorAction;
