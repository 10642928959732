import { HttpClient } from 'src/api/services/HttpClient';
import {
  TrainingConfig,
  TrainingListingResponse,
  QuizQuestion,
  Training,
  TrainingListingElement,
  TrainingSubmission,
  TrainingSubmissionResult,
  TrainingRawResponse,
} from 'src/store/types';
import { mapTrainingRawResponse } from '../utils';

class TrainingService extends HttpClient {
  public constructor() {
    super(`${process.env.REACT_APP_API_URL}training`);
  }

  public create = async (config: TrainingConfig): Promise<Training> => {
    const response = await this.instance.post<TrainingRawResponse>(
      '/',
      config,
      { withCredentials: true },
    );
    return mapTrainingRawResponse(response);
  };

  public update = async (
    trainingId: number,
    config: TrainingConfig,
  ): Promise<void> => {
    await this.instance.put(`/${trainingId}`, config, {
      withCredentials: true,
    });
  };

  public activate = async (trainingId: number): Promise<void> => {
    await this.instance.post(
      `/${trainingId}/activate`,
      {},
      { withCredentials: true },
    );
  };

  public archive = async (trainingId: number): Promise<void> => {
    await this.instance.post(
      `/${trainingId}/archive`,
      {},
      { withCredentials: true },
    );
  };

  public delete = async (trainingId: number): Promise<void> => {
    await this.instance.delete(`/${trainingId}`, { withCredentials: true });
  };

  public getAll = async (
    page: number,
    pageSize: number,
    name?: string,
    sort?: string,
    column?: string,
  ): Promise<TrainingListingResponse> => {
    let query = `?page=${page}&take=${pageSize}`;

    if (name) {
      query = `${query}&name=${encodeURIComponent(name)}`;
    }

    if (sort) {
      query = `${query}&orderBy=${sort}&sortBy=${column}`;
    }

    const response = await this.instance.get<{ data: TrainingListingResponse }>(
      query,
      {
        withCredentials: true,
      },
    );

    return response.data;
  };

  public getAllByUser = async (): Promise<TrainingListingResponse> => {
    const response = await this.instance.get<{
      data: TrainingListingElement[];
    }>('/user', {
      withCredentials: true,
    });

    return {
      records: response.data,
      total: response.data.length,
      page: 1,
      pageSize: response.data.length,
    };
  };

  public getById = async (trainingId: number): Promise<Training> => {
    const response = await this.instance.get<{ data: Training }>(
      `/${trainingId}`,
      {
        withCredentials: true,
      },
    );

    return response.data;
  };

  public createQuestion = async (trainingId: number): Promise<QuizQuestion> => {
    return await this.instance.post<QuizQuestion>(
      `/${trainingId}/quiz-question`,
      {},
      { withCredentials: true },
    );
  };

  public updateQuestion = async (
    trainingId: number,
    question: QuizQuestion,
  ): Promise<QuizQuestion> => {
    const response = await this.instance.put<{ data: QuizQuestion }>(
      `/${trainingId}/quiz-question/${question.id}`,
      {
        questionChoices: question.choices.map((choice) => ({
          ...choice,
          choiceIsCorrect: choice.isCorrect,
        })),
        questionText: question.questionText,
      },
      {
        withCredentials: true,
      },
    );
    return response.data;
  };

  public deleteQuestion = async (
    trainingId: number,
    questionId: number,
  ): Promise<QuizQuestion> => {
    const response = await this.instance.delete<{ data: QuizQuestion }>(
      `/${trainingId}/quiz-question/${questionId}`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  };

  public createSubmission = async (
    trainingId: number,
  ): Promise<TrainingSubmission> => {
    return await this.instance.post<TrainingSubmission>(
      `/${trainingId}/submission`,
      {},
      {
        withCredentials: true,
      },
    );
  };

  public createSubmissionResponse = async (
    trainingId: number,
    submissionId: number,
    questionId: number,
    choiceId: number,
  ): Promise<TrainingSubmission> => {
    return await this.instance.post<TrainingSubmission>(
      `/${trainingId}/submission/${submissionId}`,
      { questionId, choiceId },
      {
        withCredentials: true,
      },
    );
  };

  public computeSubmission = async (
    trainingId: number,
    submissionId: number,
  ): Promise<TrainingSubmissionResult> => {
    const response = await this.instance.post<{
      data: TrainingSubmissionResult;
    }>(
      `/${trainingId}/submission/${submissionId}/compute`,
      {},
      {
        withCredentials: true,
      },
    );
    return response.data;
  };

  public getTrainingSubmission = async (
    trainingId: number,
    submissionId: number,
  ): Promise<TrainingSubmission> => {
    const response = await this.instance.get<{ data: any }>(
      `/${trainingId}/submission/${submissionId}`,
      {
        withCredentials: true,
      },
    );

    return response.data;
  };

  public cloneTraining = async (trainingId: number) => {
    const response = await this.instance.post<{ data: any }>(
      `/${trainingId}/clone`,
      {},
      {
        withCredentials: true,
      },
    );

    return response.data;
  };
}

export default new TrainingService();
