import React, { FC } from 'react';
import moment from 'moment';
import { AnswerData, Answer } from 'src/store/types';
import { DatePicker } from 'src/components/DatePicker';

interface DateFieldProps {
  onChange: (value: moment.Moment | null, dateString: string) => void;
  value: moment.Moment;
  updateAnswer: (answerData: AnswerData) => void;
  deleteAnswer: (answerData: AnswerData) => void;
  answer: Answer;
}

export const DateField: FC<DateFieldProps> = ({
  onChange,
  value,
  updateAnswer,
  deleteAnswer,
  answer,
}) => {
  const triggerChange = (value: moment.Moment | null, dateString: string) => {
    onChange(value, dateString);
    if (dateString === '') {
      return deleteAnswer?.({ id: answer?.id, internalId: answer?.internalId });
    }
    updateAnswer({
      value: dateString,
      id: answer?.id,
      internalId: answer?.internalId,
    });
  };
  return (
    <DatePicker onChange={triggerChange} value={value} format={'MM/DD/YYYY'} />
  );
};
