import React from 'react';
import styled from 'styled-components';
import { Menu as MenuAntD, Dropdown } from 'antd';
import { SettingOutlined } from 'src/theme/icons';
import { useAuth } from 'src/hooks/useAuth';

export interface Props {
  onEdit: () => void;
  onManualCompletion: () => void;
  canEdit?: boolean;
}

const DisabledDropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-start;

  > span {
    color: ${({ theme }) => theme.colors.grayscale.middleGray};
    cursor: pointer;
    font-size: 20px;
  }
`;

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-start;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

const Menu = styled(MenuAntD)`
  border-radius: 4px;
  margin-top: -35px;
  margin-left: 12px;
  width: 200px;

  .ant-dropdown-menu-item:hover {
    &.title {
      background-color: white;
      cursor: default;
    }
  }

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightBlue.main}`};

    > span {
      font-weight: 600;
    }

    .title-icon {
      color: ${({ theme }) => theme.colors.lightBlue.main};
      font-size: 20px;
    }
  }

  .title-divider {
    color: red;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0px 4px;
    width: 172px;
  }

  span {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }
`;

const menu = (
  onEdit: () => void,
  onManualCompletion: () => void,
  canComplete: boolean,
) => (
  <Menu>
    <Menu.Item className="title" key="0">
      <span>Settings</span>
      <SettingOutlined className="title-icon" />
    </Menu.Item>
    <Menu.Item key="1">
      <a onClick={onEdit}>
        <span>Edit training period</span>
      </a>
    </Menu.Item>
    {canComplete && (
      <Menu.Item key="2">
        <a onClick={onManualCompletion}>
          <span>Close training period</span>
        </a>
      </Menu.Item>
    )}
    <Menu.Divider />
  </Menu>
);

const TrainingPeriodSettingsMenu = ({
  onEdit,
  onManualCompletion,
  canEdit = false,
}: Props) => {
  const { isAdmin } = useAuth();
  return (
    <Dropdown
      disabled={!canEdit}
      overlay={menu(onEdit, onManualCompletion, isAdmin)}
      trigger={['click']}
    >
      {canEdit ? (
        <DropdownIcon>
          <SettingOutlined />
        </DropdownIcon>
      ) : (
        <DisabledDropdownIcon>
          <SettingOutlined />
        </DisabledDropdownIcon>
      )}
    </Dropdown>
  );
};

export default TrainingPeriodSettingsMenu;
