import { HttpClient } from 'src/api/services/HttpClient';
import { TestTypesResponse } from 'src/store/types';

class TestTypeService extends HttpClient {
  public constructor() {
    super(`${process.env.REACT_APP_API_URL}test-type`);
  }

  public getAll = async (): Promise<TestTypesResponse> => {
    const res = await this.instance.get<{ data: TestTypesResponse }>('/', {
      withCredentials: true,
    });
    return res.data;
  };
}

export default new TestTypeService();
