import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { Button } from 'src/components/Button';
import { ProgressMeter } from 'src/components/ProgressMeter';
import { useAuth } from 'src/hooks/useAuth';
import { THShield } from 'src/theme/media';
import { DecodedToken } from 'src/types/common';
import * as S from './Styles';

export const WelcomePage: FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { hasOfficerPendingOnboarding, hasTraineePendingOnboarding } =
    useAuth();

  const handleBegin = () => {
    history.push(`/reset_password${search}&reset_password=true`);
  };

  const handleNewPass = () => {
    history.push(`/forgotten_password`);
  };

  const token = new URLSearchParams(search).get('token');
  const id = new URLSearchParams(search).get('id');

  if (!token || !id) {
    history.push('/');
    return null;
  }

  const decodedToken = jwtDecode<DecodedToken>(token);
  const isTokenExpired = decodedToken.exp * 1000 < Date.now();

  const expiredTokenPageContent = (
    <S.PageContainer>
      <S.PageContentContainer>
        <S.PageTitle>Sorry!</S.PageTitle>
        <S.PageSubTitle>The link is expired.</S.PageSubTitle>
        <p>
          The link you clicked on is expired. Please click below to request a
          new one.
        </p>
        <S.ButtonWrapper>
          <Button title="Reset password" onClick={handleNewPass} size="large" />
        </S.ButtonWrapper>
      </S.PageContentContainer>
    </S.PageContainer>
  );

  const welcomePageContent = (
    <S.PageContainer>
      <S.PageContentContainer>
        <S.PageTitle>Welcome!</S.PageTitle>
        <S.PageSubTitle>We're glad you're here.</S.PageSubTitle>
        {hasTraineePendingOnboarding && (
          <p>
            We at Total HIPAA Compliance are pleased to provide your HIPAA
            training. This course will equip you with the knowledge needed to
            receive an annual HIPAA training certificate, and help ensure your
            organization remains compliant.
          </p>
        )}

        {hasOfficerPendingOnboarding && (
          <>
            <p>
              The team at Total HIPAA Compliance is pleased to be partnering
              with you to support you through the compliance process.
            </p>
            <p>
              The ongoing responsibility of HIPAA can be overwhelming, but we’ve
              got you covered! Our system will guide you through the steps
              needed to ensure your organization remains compliant.
            </p>
          </>
        )}
        <p>Click "Begin" to set up your account.</p>
        <S.ButtonWrapper>
          <Button title="Begin" onClick={handleBegin} />
        </S.ButtonWrapper>
      </S.PageContentContainer>
      <S.PageBottomContainer>
        <div>
          <ProgressMeter hasOnboarding step={1} />
        </div>
        <S.PageShieldImage width={150} height={150} src={THShield} />
      </S.PageBottomContainer>
    </S.PageContainer>
  );

  return isTokenExpired ? expiredTokenPageContent : welcomePageContent;
};
