import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Popconfirm, Popover } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from 'src/theme/icons';
import { Question } from 'src/store/types';

interface FormFieldProps {
  className: string;
  question: Question;
  children?: ReactNode;
  onCopyPress: (question: Question) => void;
  onDeletePress: (question: Question) => void;
  onEditPress: (question: Question) => void;
  isLast?: boolean;
  title: string;
  temp?: boolean;
}

const Card = styled.div<Partial<FormFieldProps>>`
  border-radius: 4px;
  border: ${({ temp }) =>
    temp ? '1px dashed black' : '1px solid transparent'};
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px 12px;
  margin: 20px 0;

  &:hover {
    background-color: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);

    .header .actions {
      visibility: visible;
    }

    .content .options {
      visibility: visible;
    }
  }

  > span {
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 5px;

    > span {
      color: ${({ theme }) => theme.colors.grayscale.darkGray};
      font-weight: 600;
      flex-grow: 1;

      .anticon {
        margin-left: 5px;
      }
    }

    .actions {
      display: flex;
      visibility: hidden;
      justify-content: space-around;
      width: 120px;
      padding-top: 10px;

      > span {
        color: ${({ theme }) => theme.colors.lightBlue.main};
        font-size: 18px;
      }
    }
  }

  .description {
    font-size: 12px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.grayscale.darkGray};
    margin: 10px 0;
  }

  .content {
    display: flex;
    flex: 1;

    .input {
      color: ${({ theme }) => theme.colors.grayscale.darkGray};
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;

      > span {
        margin: 5px 0px;
      }
    }

    .options {
      display: flex;
      visibility: hidden;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0px 4px;

      > span {
        color: ${({ theme }) => theme.colors.lightBlue.main};
        padding: 5px 8px;

        &[disabled] {
          color: ${({ theme }) => theme.colors.grayscale.middleGray};
          cursor: default;
        }
      }
    }
  }
`;

export const FormField: FC<FormFieldProps> = ({
  className,
  question,
  isLast,
  children,
  onCopyPress,
  onDeletePress,
  onEditPress,
  title,
}) => {
  return (
    <Card className={className} isLast={isLast} temp={question?.temp}>
      <div className="header">
        <span>
          {title}
          {question?.temp && (
            <Popover content="This question needs to be configured">
              <InfoCircleOutlined />
            </Popover>
          )}
        </span>

        <div className="actions">
          <EditOutlined onClick={() => onEditPress(question)} />
          <CopyOutlined onClick={() => onCopyPress(question)} />
          <Popconfirm
            title="Are you sure delete this question?"
            onConfirm={() => onDeletePress(question)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      </div>
      {question.description && (
        <p className="description">{question.description}</p>
      )}
      <div className="content">
        <div className="input">{children}</div>
      </div>
    </Card>
  );
};
