import React, { FC, useState } from 'react';
import { Form, Select, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { TextInput } from 'src/components/TextInput';
import {
  templateSaveRequest,
  templateCloneRequest,
} from 'src/store/actions/template';

import {
  AssessmentTypeListingIndexedItem,
  AssessmentTypeState,
  TemplateInput,
} from 'src/store/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AutoComplete, AutocompleteOption } from '../Autocomplete';

export interface FormCreateTemplateProps {
  templateForm: FormInstance;
  assessmentTypes: AssessmentTypeListingIndexedItem;
  parentTemplateId: number | null;
  templateFolders: AutocompleteOption[];
  showAdvancedOptionsCheckbox?: boolean;
  onlyDuplicate?: boolean;
  parentTemplateBody?: string;
}

interface TemplateFormValues {
  title: string;
  assessmentTypeId: number;
  folder: string;
}

interface AssessmentTypeSelectorProps {
  hidden: boolean;
}

const FormContainer = styled(Form)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
    }
  }
`;

const AssessmentTypeSelector = styled.div<AssessmentTypeSelectorProps>`
  ${({ hidden }: any) =>
    hidden &&
    `
    display: none;
  `}
  width: 100%;
`;

export const FormCreateTemplate: FC<FormCreateTemplateProps> = ({
  templateForm,
  assessmentTypes,
  parentTemplateId,
  templateFolders,
  showAdvancedOptionsCheckbox = false,
  onlyDuplicate = false,
  parentTemplateBody,
}) => {
  const [showAdvancedOptions, setShowAdvancedOptions] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const handleSubmit = (values: TemplateFormValues) => {
    const templateFormInput: TemplateInput = {
      title: values?.title.trim(),
      assessmentTypeId: values?.assessmentTypeId,
      folder: values?.folder.trim(),
      body: parentTemplateBody,
    };
    dispatch(
      parentTemplateId
        ? templateCloneRequest(
            templateFormInput,
            parentTemplateId,
            onlyDuplicate,
          )
        : templateSaveRequest(templateFormInput),
    );
    return null;
  };

  return (
    <FormContainer
      form={templateForm}
      onFinish={(values) => {
        handleSubmit(values as TemplateFormValues);
        templateForm.resetFields();
      }}
    >
      <Form.Item
        name="title"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please input the template title',
          },
        ]}
      >
        <TextInput placeholder="Title" />
      </Form.Item>
      <Form.Item
        name="folder"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please select a folder',
          },
        ]}
      >
        <AutoComplete
          placeholder="Select a folder"
          options={templateFolders}
          handleOnChange={(value: string) =>
            templateForm.setFieldsValue({ folder: value })
          }
        />
      </Form.Item>
      {showAdvancedOptionsCheckbox && (
        <Form.Item>
          <Checkbox
            onChange={(e: CheckboxChangeEvent) =>
              setShowAdvancedOptions(!!e.target.checked)
            }
          >
            Show advanced options
          </Checkbox>
        </Form.Item>
      )}
      <AssessmentTypeSelector
        hidden={
          showAdvancedOptionsCheckbox &&
          !(showAdvancedOptionsCheckbox && showAdvancedOptions)
        }
      >
        <Form.Item
          name="assessmentTypeId"
          rules={[
            { required: true, message: 'Please select an assessment type' },
          ]}
        >
          <Select placeholder="Select an assessment type">
            {Object.values(assessmentTypes).map((a: AssessmentTypeState) => (
              <Select.Option value={a.id} key={a.id}>
                {a.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </AssessmentTypeSelector>
    </FormContainer>
  );
};
