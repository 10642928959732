import React, { FC, useState } from 'react';
import { Form, Upload } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import * as PapaParse from 'papaparse';
import {
  CompanyUserRole,
  FileData,
  UserCreationError,
  UserInput,
} from 'src/store/types';
import { multipleUsersSaveRequest } from 'src/store/actions/user';
import { showErrorMessage } from 'src/store/actions/error';
import { InboxOutlined } from 'src/theme/icons';
import { CsvInfo } from './CsvInfo';

export interface FormCreateMultipleUsersProps {
  multipleUsersForm: FormInstance;
  companyId: number;
  setSaveButtonEnabled: (value: boolean) => void;
}

const FormContainer = styled(Form)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
    }
  }
  .ant-upload-text-icon {
    display: none;
  }
  .ant-upload-list-item-progress {
    display: none;
  }
`;

export const getErrorMessages = (errors: UserCreationError[]): string[] => {
  return errors.map(({ error, recordNumber }: UserCreationError) => {
    return `User Nº${recordNumber}: ${error}.`;
  });
};

export const FormCreateMultipleUsers: FC<FormCreateMultipleUsersProps> = ({
  multipleUsersForm,
  companyId,
  setSaveButtonEnabled,
}) => {
  const dispatch = useDispatch();
  const [usersData, setUsersData] = useState<UserInput[]>([]);

  const validateUsersData = (): UserCreationError[] => {
    const totalErrors: UserCreationError[] = [];
    usersData.forEach((userData: UserInput, index: number) => {
      const currentRecordErrors: string[] = [];
      if (!userData.name?.trim()) {
        currentRecordErrors.push('Name should not be empty');
      }
      if (!userData.email?.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        currentRecordErrors.push('Email is not a valid email address');
      }
      if (
        userData.role?.trim() !== CompanyUserRole.officer &&
        userData.role?.trim() !== CompanyUserRole.auditor &&
        userData.role?.trim() !== CompanyUserRole.trainee
      ) {
        currentRecordErrors.push(
          'Role must be "officer", "auditor" or "trainee"',
        );
      }
      if (currentRecordErrors.length > 0) {
        totalErrors.push({
          error: currentRecordErrors.join(' - '),
          recordNumber: index + 1,
        });
      }
    });
    return totalErrors;
  };

  const handleSubmit = () => {
    setSaveButtonEnabled(false);
    const errors: UserCreationError[] = validateUsersData();
    if (errors.length > 0) {
      dispatch(
        showErrorMessage(getErrorMessages(errors), errors.map(String.toString)),
      );
      return;
    }
    const mappedUsersWithCompany = usersData.map((userData: UserInput) => ({
      ...userData,
      companyId,
      isActive: true,
    }));
    dispatch(multipleUsersSaveRequest(mappedUsersWithCompany));
  };

  const handleChange = ({ file }: { file: FileData; fileList: FileData[] }) => {
    const { status, originFileObj } = file;
    if (status === 'removed') {
      setSaveButtonEnabled(false);
    } else {
      const reader: FileReader = new FileReader();
      reader.onload = () => {
        const { data, errors }: PapaParse.ParseResult<UserInput> =
          PapaParse.parse(reader.result as string, {
            header: true,
            skipEmptyLines: true,
          });
        if (!data.length || errors.length) {
          setSaveButtonEnabled(false);
          dispatch(
            showErrorMessage(
              'Invalid file format. Download the example CSV to get the correct format.',
              errors.map(String.toString),
            ),
          );
          return;
        }
        setUsersData(data);
        setSaveButtonEnabled(true);
      };
      reader.readAsText(originFileObj as Blob);
    }
  };

  const { Dragger } = Upload;

  return (
    <FormContainer
      form={multipleUsersForm}
      onFinish={() => {
        handleSubmit();
        multipleUsersForm.resetFields();
      }}
    >
      <CsvInfo />
      <Form.Item
        name="csvFile"
        valuePropName="fileList"
        getValueFromEvent={handleChange}
        noStyle
      >
        <Dragger accept=".csv" maxCount={1} customRequest={() => null}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag the CSV file to this area to upload it
          </p>
        </Dragger>
      </Form.Item>
    </FormContainer>
  );
};
