import { call, takeEvery, put } from 'redux-saga/effects';
import {
  TemplateState,
  TEMPLATE_SAVE_REQUEST,
  TEMPLATE_DELETE_REQUEST,
  TEMPLATE_CLONE_REQUEST,
  TemplateSaveRequestAction,
  TemplateDeleteRequestAction,
  TemplateCloneRequestAction,
  TemplateLoadRequestAction,
  TEMPLATE_LOAD_REQUEST,
  TemplateUpdateRequestAction,
  TEMPLATE_UPDATE_REQUEST,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadTemplate = function* async({
  templateId,
}: TemplateLoadRequestAction): Generator {
  try {
    const template = yield call(api.loadTemplate, templateId);
    yield put(actions.templateLoadSuccess(template as TemplateState));
  } catch (err) {
    yield put(
      actions.templateSaveError('Failed to load template', err as Error),
    );
  }
};

export const updateTemplate = function* async({
  templateId,
  template: templateData,
}: TemplateUpdateRequestAction): Generator {
  try {
    const template = yield call(api.updateTemplate, templateId, templateData);
    yield put(
      actions.templateUpdateSuccess({
        ...(template as Partial<TemplateState>),
      }),
    );
  } catch (err) {
    yield put(
      actions.templateUpdateError('Failed to update template', err as Error),
    );
  }
};

export const saveTemplate = function* async({
  template: templateData,
}: TemplateSaveRequestAction): Generator {
  try {
    const template = yield call(api.saveTemplate, templateData);
    yield put(actions.addTemplateToList(template as TemplateState));
    yield put(actions.templateSaveSuccess(template as TemplateState));
  } catch (err) {
    yield put(
      actions.templateSaveError('Failed to save template', err as Error),
    );
  }
};

export const deleteTemplate = function* async({
  templateId,
}: TemplateDeleteRequestAction): Generator {
  try {
    yield call(api.deleteTemplate, templateId);
    yield put(actions.removeTemplateFromList(templateId));
    yield put(actions.templateDeleteSuccess(templateId));
  } catch (err) {
    yield put(
      actions.templateDeleteError('Failed to delete error', err as Error),
    );
  }
};

export const cloneTemplate = function* async({
  template: templateData,
  templateId,
  onlyDuplicate,
}: TemplateCloneRequestAction): Generator {
  try {
    const clonedTemplate = yield call(
      api.cloneTemplate,
      templateData,
      templateId,
      onlyDuplicate,
    );
    yield put(actions.addTemplateToList(clonedTemplate as TemplateState));
    yield put(actions.templateCloneSuccess(clonedTemplate as TemplateState));
  } catch (err) {
    yield put(
      actions.templateCloneError('Failed to clone template', err as Error),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(TEMPLATE_LOAD_REQUEST, loadTemplate),
    yield takeEvery(TEMPLATE_SAVE_REQUEST, saveTemplate),
    yield takeEvery(TEMPLATE_UPDATE_REQUEST, updateTemplate),
    yield takeEvery(TEMPLATE_DELETE_REQUEST, deleteTemplate),
    yield takeEvery(TEMPLATE_CLONE_REQUEST, cloneTemplate),
  ];
}
