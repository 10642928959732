import { Form } from 'antd';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProgressMeter } from 'src/components/ProgressMeter';
import { useAuth } from 'src/hooks/useAuth';
import actions from 'src/store/actions';
import {
  getCurrentTrainingPeriod,
  getTrainingPeriodLoader,
} from 'src/store/selectors';
import { SetTrainingPeriodForm } from '../../components/SetTrainingPeriodForm';
import * as S from './Styles';

interface TrainingPeriodValues {
  releaseDateType: string;
  dueDateType: string;
  customReleaseDate: Date;
  customDueDate: Date;
}

export enum TrainingPeriodType {
  TODAY = 'TODAY',
  WEEKS_4 = 'WEEKS_4',
  CUSTOM = 'CUSTOM',
}

export enum DueDateType {
  DAYS_30 = 'DAYS_30',
  DAYS_60 = 'DAYS_60',
  CUSTOM = 'CUSTOM',
}

export const SetTrainingPeriodPage: FC<unknown> = () => {
  const [trainingPeriodForm] = Form.useForm();
  const { user } = useAuth();
  const trainingPeriodLoader = useSelector(getTrainingPeriodLoader);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTrainingPeriod = useSelector(getCurrentTrainingPeriod);

  useEffect(() => {
    dispatch(actions.trainingPeriodCompanyLoadRequest());
  }, []);

  useEffect(() => {
    if (currentTrainingPeriod) {
      history.push('/onboarding/company_users');
    }
  }, [currentTrainingPeriod]);

  const handleSubmit = (values: TrainingPeriodValues): void => {
    let releaseDate = values.customReleaseDate
      ? values.customReleaseDate
      : moment().toDate();
    let dueDate = values.customDueDate
      ? values.customDueDate
      : moment().toDate();

    if (values.releaseDateType === TrainingPeriodType.WEEKS_4) {
      releaseDate = moment().add(4, 'weeks').toDate();
    }

    if (values.dueDateType === DueDateType.DAYS_60) {
      dueDate = moment().add(60, 'days').toDate();
    }

    if (user.company) {
      dispatch(
        actions.trainingPeriodCreateRequest({
          dueDate,
          releaseDate,
          companyId: user.company.id,
        }),
      );
    }

    trainingPeriodForm.resetFields();
  };

  return (
    <S.PageContainer>
      <S.PageTitle>Set training period</S.PageTitle>
      <S.PageSubTitle>Set training release date and due date</S.PageSubTitle>
      <div>
        <SetTrainingPeriodForm
          trainingPeriodLoader={trainingPeriodLoader}
          form={trainingPeriodForm}
          onSubmit={handleSubmit}
        />
      </div>
      <S.PageFooterContainer>
        <S.ProgressMeterWrapper>
          <ProgressMeter hasOnboarding step={5} />
        </S.ProgressMeterWrapper>
      </S.PageFooterContainer>
    </S.PageContainer>
  );
};
