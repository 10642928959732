import React from 'react';
import { Table, Empty, Tag } from 'antd';
import { BookTwoTone } from '@ant-design/icons';

import { Grid } from 'src/components/Grid';

import { TrainingListingElement } from 'src/store/types';
import { TrainingSettingsMenu } from '..';

interface Props {
  dataSource: TrainingListingElement[];
  sortTrainingTable: (order: string, column: string) => void;
}

const { Column } = Table;

const TrainingListingTable = ({ dataSource, sortTrainingTable }: Props) => {
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    sortTrainingTable(sorter.order, sorter.field);
  };

  return (
    <Grid
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
      onChange={onTableChange}
    >
      <Column<TrainingListingElement>
        sorter
        title="Name"
        dataIndex="name"
        key="name"
        render={(_, record) => (
          <>
            <BookTwoTone />
            <span>{` ${record.name}`}</span>
          </>
        )}
      />
      <Column<TrainingListingElement>
        title="Status"
        dataIndex="status"
        key="status"
        render={(_, record) => (
          <>
            <Tag
              color={
                record.status === 'ACTIVE'
                  ? 'success'
                  : record.status === 'ARCHIVE'
                    ? 'error'
                    : 'processing'
              }
            >
              {record.status}
            </Tag>
          </>
        )}
      />
      <Column<TrainingListingElement>
        title="Training Type"
        dataIndex="trainingTypeName"
        key="trainingTypeName"
        sorter
      />
      <Column<TrainingListingElement>
        title="Test Type"
        dataIndex="testTypeName"
        key="testTypeName"
        sorter
      />
      <Column<TrainingListingElement>
        sorter
        title="Chapter"
        dataIndex="chapter"
        key="chapter"
      />
      <Column<TrainingListingElement>
        dataIndex=""
        key="action"
        width="10%"
        render={(_, record: TrainingListingElement) => (
          <TrainingSettingsMenu training={record} />
        )}
      />
    </Grid>
  );
};

export default TrainingListingTable;
