import {
  QUESTION_TYPES_LOAD_ERROR,
  QuestionTypeErrorAction,
  QUESTION_TYPES_LOAD_SUCCESS,
  QuestionTypeSuccessAction,
  QUESTION_TYPES_LOAD_REQUEST,
  QuestionTypeRequestAction,
  QuestionType,
} from '../types';
import { showErrorMessage } from './error';

export const questionTypeLoadRequest = (): QuestionTypeRequestAction => ({
  type: QUESTION_TYPES_LOAD_REQUEST,
});

export const questionTypeLoadSuccess = (
  types: QuestionType[],
): QuestionTypeSuccessAction => ({
  type: QUESTION_TYPES_LOAD_SUCCESS,
  types,
});

export const questionTypeLoadError = (
  errorMessage: string,
  originalError: Error,
): QuestionTypeErrorAction =>
  showErrorMessage(QUESTION_TYPES_LOAD_ERROR, errorMessage, originalError);
