import { showErrorMessage } from 'src/store/actions/error';
import {
  TestTypesLoadErrorAction,
  TestTypesLoadRequestAction,
  TestTypesLoadSuccessAction,
  TestTypesResponse,
  TEST_TYPES_LOAD_FAILED,
  TEST_TYPES_LOAD_REQUEST,
  TEST_TYPES_LOAD_SUCCESS,
} from '../types';

export const testTypesLoadRequest = (): TestTypesLoadRequestAction => ({
  type: TEST_TYPES_LOAD_REQUEST,
});

export const testTypesLoadSuccess = (
  testTypes: TestTypesResponse,
): TestTypesLoadSuccessAction => ({
  type: TEST_TYPES_LOAD_SUCCESS,
  testTypes,
});

export const testTypesLoadError = (
  errorMessage: string,
  originalError?: Error,
): TestTypesLoadErrorAction =>
  showErrorMessage(TEST_TYPES_LOAD_FAILED, errorMessage, originalError);
