import React, { FC } from 'react';
import { Table, Empty } from 'antd';
import { Grid, GridTag } from 'src/components/Grid';
import { FormSettings } from 'src/features/risk-assessment/components/FormSettings';
import { FormState } from 'src/store/types';
import { dataMapper } from './dataMapper';

export interface FormListingTableProps {
  forms: FormState[];
  showUpdateFormPanel: (form: FormState) => void;
}

export interface TableRowInterface {
  id: number;
  title: string;
  status: boolean;
  draft: boolean;
  rawData: FormState;
}

const { Column } = Table;

export const FormListingTable: FC<FormListingTableProps> = ({
  forms: rawForms,
  showUpdateFormPanel,
}) => {
  const dataSource: TableRowInterface[] = dataMapper(rawForms);
  return (
    <Grid
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
    >
      <Column<TableRowInterface> title="Title" dataIndex="title" key="title" />
      <Column<TableRowInterface> title="ID" dataIndex="id" key="id" />
      <Column<TableRowInterface>
        title="Status"
        dataIndex=""
        key="status"
        render={(_, record: TableRowInterface) =>
          record.status ? (
            <GridTag type="active">Active</GridTag>
          ) : (
            <GridTag type="inactive">Inactive</GridTag>
          )
        }
      />
      <Column<TableRowInterface>
        title="Type"
        dataIndex="assesmentType"
        key="assesmentType"
        render={(_, record: TableRowInterface) =>
          record.rawData.assessmentType?.label
        }
      />
      <Column<TableRowInterface>
        title={false}
        dataIndex=""
        key="action"
        render={(_, record: TableRowInterface) => (
          <FormSettings
            formId={record.id}
            formActive={record.status}
            showUpdateFormPanel={() => showUpdateFormPanel(record.rawData)}
          />
        )}
      />
    </Grid>
  );
};
