import { call, takeEvery, put } from 'redux-saga/effects';
import {
  DocumentFromResponse,
  DocumentApproveRequestAction,
  DocumentCloseRequestAction,
  DocumentLoadRequestAction,
  DocumentSaveRequestAction,
  DocumentPPSaveRequestAction,
  DocumentSetInClientReviewRequestAction,
  DocumentAcceptRequestAction,
  DOCUMENT_SET_IN_CLIENT_REVIEW_REQUEST,
  DOCUMENT_APPROVE_REQUEST,
  DOCUMENT_CLOSE_REQUEST,
  DOCUMENT_LOAD_REQUEST,
  DOCUMENT_SAVE_REQUEST,
  DOCUMENT_PP_SAVE_REQUEST,
  DOCUMENT_ACCEPT_REQUEST,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const setDocumentInClientReview = function* async({
  documentId,
}: DocumentSetInClientReviewRequestAction): Generator {
  try {
    yield call(api.setDocumentInClientReview, documentId);
    yield put(actions.documentSetInClientReviewSuccess(documentId));
  } catch (err) {
    const errorMessage =
      err instanceof Error ? err.message : 'Failed start client review';
    yield put(
      actions.documentSetInClientReviewError(errorMessage, err as Error),
    );
  }
};

export const approveDocument = function* async({
  documentId,
}: DocumentApproveRequestAction): Generator {
  try {
    yield call(api.approveDocument, documentId);
    yield put(actions.documentApproveSuccess(documentId));
  } catch (err) {
    yield put(
      actions.documentApproveError('Failed to approve document', err as Error),
    );
  }
};

export const closeDocument = function* async({
  documentId,
}: DocumentCloseRequestAction): Generator {
  try {
    yield call(api.closeDocument, documentId);
    yield put(actions.documentCloseSuccess(documentId));
  } catch (err) {
    yield put(
      actions.documentCloseError('Failed to close document', err as Error),
    );
  }
};

export const getDocumentById = function* async({
  documentId,
}: DocumentLoadRequestAction): Generator {
  try {
    const res = yield call(api.getDocument, documentId);
    yield put(actions.documentLoadSuccess(res as DocumentFromResponse));
  } catch (err) {
    yield put(
      actions.documentLoadError('Failed to get document', err as Error),
    );
  }
};

export const updateDocument = function* async({
  document,
}: DocumentSaveRequestAction): Generator {
  try {
    const res = yield call(api.updateDocument, document);
    yield put(actions.documentSaveSuccess(res as DocumentFromResponse));
  } catch (err) {
    yield put(actions.documentSaveError('Failed to login', err as Error));
  }
};

export const saveDocumentPP = function* async({
  documentDraft,
  assessmentId,
}: DocumentPPSaveRequestAction): Generator {
  try {
    yield call(api.saveDocumentPP, documentDraft, assessmentId);
    yield put(actions.documentPPSaveSuccess());
  } catch (err) {
    yield put(actions.documentPPSaveError('Failed to login', err as Error));
  }
};

export const acceptDocument = function* async({
  documentId,
}: DocumentAcceptRequestAction): Generator {
  try {
    yield call(api.acceptDocument, documentId);
    yield put(actions.documentMarkAcceptedSuccess(documentId));
  } catch (err) {
    yield put(
      actions.documentMarkAcceptedError(
        'Failed to accept document',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(DOCUMENT_APPROVE_REQUEST, approveDocument),
    yield takeEvery(DOCUMENT_CLOSE_REQUEST, closeDocument),
    yield takeEvery(DOCUMENT_LOAD_REQUEST, getDocumentById),
    yield takeEvery(DOCUMENT_SAVE_REQUEST, updateDocument),
    yield takeEvery(DOCUMENT_PP_SAVE_REQUEST, saveDocumentPP),
    yield takeEvery(
      DOCUMENT_SET_IN_CLIENT_REVIEW_REQUEST,
      setDocumentInClientReview,
    ),
    yield takeEvery(DOCUMENT_ACCEPT_REQUEST, acceptDocument),
  ];
}
