import { Spin } from 'antd';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Button } from 'src/components/Button';
import { ProgressMeter } from 'src/components/ProgressMeter';
import { useAuth } from 'src/hooks/useAuth';
import { completeUserOnboardingRequest } from 'src/store/actions/user';
import { THFreeTool } from 'src/theme/media';
import actions from 'src/store/actions';
import {
  getCompanyFromState,
  getCompanyLoaderFromState,
  getCurrentTrainingPeriod,
  getTrainingPeriodLoader,
} from 'src/store/selectors';
import * as S from './Styles';
import { UserRole } from 'src/store/types';

export const TrainingDueDatePage: FC<unknown> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { hasPendingOnboarding, user } = useAuth();
  const trainingPeriod = useSelector(getCurrentTrainingPeriod);
  const trainingPeriodLoader = useSelector(getTrainingPeriodLoader);
  const company = useSelector(getCompanyFromState);
  const companyLoader = useSelector(getCompanyLoaderFromState);

  useEffect(() => {
    dispatch(actions.trainingPeriodCompanyLoadRequest());
    if (user.company?.id) {
      dispatch(actions.companyLoadRequest(user.company?.id));
    }
  }, []);

  const handleContinueClick = () => {
    dispatch(completeUserOnboardingRequest(user.id));
    history.push(`/`);
  };

  if (!hasPendingOnboarding) {
    history.push('/');
  }

  const companyOfficerName = company?.users?.find(
    (user) => user.role === UserRole.officer,
  )?.name;

  return (
    <S.PageContainer>
      <S.PageContentContainer>
        <S.PageTitle>{`${user.name}'s due date`}</S.PageTitle>
        <S.TrainingInfoContainer>
          <S.TrainingDueDateContainer>
            {!trainingPeriodLoader.loading && !companyLoader.loading ? (
              <>
                {trainingPeriod ? (
                  <div>{`${moment(trainingPeriod?.dueDate).format('LL')}`}</div>
                ) : (
                  <div>N/A</div>
                )}
                <p>
                  We at Total HIPAA Compliance are pleased to collaborate with{' '}
                  <b>{user.company?.name}</b> to provide your HIPAA training.{' '}
                  <b>{companyOfficerName} </b> has registered you to complete
                  HIPAA training and become HIPAA certified for the current
                  year.
                </p>
                <b>Tip:</b>
                <p>
                  Enter the due date into your calendar and set aside time for
                  training. You’ll need roughly <b>2 hours</b> to watch the
                  training and take the written test.
                </p>
              </>
            ) : (
              <Spin />
            )}
          </S.TrainingDueDateContainer>
        </S.TrainingInfoContainer>
        <S.ButtonWrapper>
          <Button
            title="Continue to HIPAA Training"
            onClick={handleContinueClick}
          />
        </S.ButtonWrapper>
        <S.ProgressMeterContainer>
          <ProgressMeter hasOnboarding={hasPendingOnboarding} step={5} />
        </S.ProgressMeterContainer>
      </S.PageContentContainer>
      <S.PageToolImage width={150} height={155} src={THFreeTool} />
    </S.PageContainer>
  );
};
