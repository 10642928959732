import React, { FC, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import {
  CompanyInput,
  CompanyType,
  TypeOfTraining,
  TrainingTypesResponse,
} from 'src/store/types';
import {
  companySaveRequest,
  companyUpdateRequest,
} from 'src/store/actions/company';
import { TypeOfTrainingOption } from 'src/types/company';
import { TYPE_OF_TRAINING_TEXTS } from 'src/constants/company';
import { useAuth } from 'src/hooks/useAuth/useAuth';

import { TextInput } from '../../../../components/TextInput';
import { mapCompanyType } from '../CompanyListingTable/dataMapper';
import { states } from './States';

export interface FormCreateCompanyProps {
  companyForm: FormInstance;
  companyId?: number;
  trainingTypes: TrainingTypesResponse;
}

interface CompanyFormValues {
  name: string;
  state: string;
  address: string;
  typeOfTraining: TypeOfTraining;
  type: CompanyType;
  licenses?: number;
  employees?: number;
}

const FormContainer = styled(Form)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
    }
  }
`;

export const FormCreateCompany: FC<FormCreateCompanyProps> = ({
  companyForm,
  companyId,
  trainingTypes,
}) => {
  const { isAdmin, typeOfTrainings } = useAuth();
  const dispatch = useDispatch();

  const [typeOfTrainingOptions, setTypeOfTrainingOptions] = useState<
    TypeOfTrainingOption[]
  >([]);
  const [selectedTypeOfTraining, setSelectedTypeOfTraining] =
    useState<TypeOfTraining | null>(null);

  const handleSubmit = (values: CompanyFormValues) => {
    const mappedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        return [key, value.toString().trim()];
      }),
    );
    const companyFormInput = mappedValues as CompanyInput;
    if (companyFormInput['licenses']) {
      companyFormInput['licenses'] = +companyFormInput['licenses'];
    }
    if (companyFormInput['employees']) {
      companyFormInput['employees'] = +companyFormInput['employees'];
    }
    if (companyId) {
      dispatch(companyUpdateRequest(companyId, companyFormInput));
    } else {
      dispatch(companySaveRequest(companyFormInput));
    }
    return null;
  };

  useEffect(() => {
    if (companyId) {
      const typeOfTraining = companyForm.getFieldValue('typeOfTraining');
      setSelectedTypeOfTraining(typeOfTraining);
    } else {
      setSelectedTypeOfTraining(null);
    }
  }, [companyId]);

  useEffect(() => {
    const filteredTypeOfTrainings: TypeOfTraining[] = Object.values(
      TypeOfTraining,
    ).filter(
      (typeOfTraining) => isAdmin || typeOfTrainings?.includes(typeOfTraining),
    );
    const mappedTypeOfTrainings: TypeOfTrainingOption[] =
      filteredTypeOfTrainings.map((typeOfTraining) => ({
        value: typeOfTraining,
        displayValue: TYPE_OF_TRAINING_TEXTS[typeOfTraining],
      }));
    setTypeOfTrainingOptions(mappedTypeOfTrainings);
  }, [isAdmin, typeOfTrainings]);

  return (
    <FormContainer
      form={companyForm}
      onFinish={(values) => {
        handleSubmit(values as CompanyFormValues);
      }}
    >
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please input the company name',
          },
        ]}
      >
        <TextInput placeholder="Name" />
      </Form.Item>

      <Form.Item
        name="address"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please input the company address',
          },
        ]}
      >
        <TextInput placeholder="Address" autoComplete="street-address" />
      </Form.Item>
      <Form.Item
        name="state"
        rules={[{ required: true, message: 'Please select the company state' }]}
      >
        <Select placeholder="Select the company state">
          {states.map((state: string) => (
            <Select.Option value={state} key={state}>
              {state}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="typeOfTraining"
        rules={[
          { required: true, message: 'Please select a type of training' },
        ]}
      >
        <Select
          placeholder="Select a type of training"
          onSelect={(value: TypeOfTraining) => setSelectedTypeOfTraining(value)}
        >
          {typeOfTrainingOptions.map(
            ({ value, displayValue }: TypeOfTrainingOption) => (
              <Select.Option value={value} key={value}>
                {displayValue}
              </Select.Option>
            ),
          )}
        </Select>
      </Form.Item>
      <Form.Item
        name="trainingTypeId"
        rules={[{ required: true, message: 'Please select the training type' }]}
      >
        <Select placeholder="Select the training type">
          {trainingTypes.map((trainingType) => (
            <Select.Option value={trainingType.id} key={trainingType.id}>
              {trainingType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {selectedTypeOfTraining &&
        selectedTypeOfTraining === TypeOfTraining.HIPAA_PRIME && (
          <Form.Item
            name="employees"
            rules={[
              {
                required: true,
                message: 'Please input the number of employees',
              },
            ]}
          >
            <TextInput numeric positiveOnly placeholder="# of Employees" />
          </Form.Item>
        )}
      <Form.Item
        name="licenses"
        rules={[
          { required: true, message: 'Please input the number of licenses' },
        ]}
      >
        <TextInput numeric positiveOnly placeholder="# of Licenses" />
      </Form.Item>
      <Form.Item
        name="companyType"
        rules={[{ required: true, message: 'Please select a company type' }]}
      >
        <Select placeholder="Select a company type">
          {Object.values(CompanyType).map((c: CompanyType) => (
            <Select.Option value={c} key={c}>
              {mapCompanyType(c)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </FormContainer>
  );
};
