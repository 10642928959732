import React, { FC } from 'react';
import { Avatar, Tag, Select } from 'antd';
import styled from 'styled-components';
import { User, UserListingIndexedItem } from 'src/store/types';

export interface TagUserInputProps {
  onChange: (value: string) => void;
  onSelect: (value: string) => void;
  value?: string;
  taggedUsers: string[];
  tagAdmins: boolean;
  users?: UserListingIndexedItem;
  removeTaggedUser: (value: string) => void;
}

const ItemTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  .tagTitle {
    font-weight: bolder;
    font-size: 16px;
  }
`;

const OptionText = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
`;

const TaggedUsersContainer = styled.div`
  width: 100%;
  padding-left: 22px;
  margin-bottom: 10px;

  span {
    color: ${({ theme }) => theme.colors.grayscale.preDark};
    font-size: 12px;
    font-weight: 600;
  }
`;

export const TagUserInput: FC<TagUserInputProps> = ({
  onChange,
  onSelect,
  users,
  value,
  taggedUsers,
  tagAdmins,
  removeTaggedUser,
}) => {
  const getUserAvatar = (user: User) => {
    if (user.avatarImg) {
      return <Avatar src={user.avatarImg} alt={user.name} size={36} />;
    }
    return <Avatar size={36}>{user.name.charAt(0)}</Avatar>;
  };

  return (
    <>
      <ItemTitleContainer>
        <h3 className="tagTitle">Tag People</h3>
        <h3>Name</h3>
      </ItemTitleContainer>
      <TaggedUsersContainer>
        {tagAdmins && (
          <Tag key="admins" closable onClose={() => removeTaggedUser('admins')}>
            Total HIPAA Administrators
          </Tag>
        )}
        {taggedUsers.length > 0 &&
          taggedUsers.map((userId: string) => (
            <Tag key={userId} closable onClose={() => removeTaggedUser(userId)}>
              {users?.[Number(userId)]?.name}
            </Tag>
          ))}
      </TaggedUsersContainer>
      <Select
        onChange={onChange as any}
        onSelect={onSelect as any}
        className="text-form-item"
        placeholder="Name"
        value={value}
        showSearch
        filterOption={(inputValue, option) => {
          return (
            (option?.label as string)
              .toUpperCase()
              ?.indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
        optionFilterProp="value"
        optionLabelProp="value"
      >
        {users &&
          Object.values(users)
            .filter((u: User) => {
              return !taggedUsers
                .map((userId: string) => userId)
                .includes(String(u.id));
            })
            .map((user: User) => (
              <Select.Option
                value={user.id.toString()}
                key={user.id.toString()}
                label={user.name}
              >
                {getUserAvatar(user)}
                <OptionText>{user.name}</OptionText>
              </Select.Option>
            ))}
        {!tagAdmins && (
          <Select.Option key="admins" value="admins" label="values">
            <Avatar size={36}>ADM</Avatar>
            <OptionText>Total HIPAA Administrators</OptionText>
          </Select.Option>
        )}
      </Select>
    </>
  );
};
