import React, { FC } from 'react';
import { ThemeProvider as DefaultThemeProvider } from 'styled-components';
import { mainTheme } from './theme';
import { GlobalStyle } from './GlobalStyles';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
}): JSX.Element => {
  return (
    <DefaultThemeProvider theme={mainTheme}>
      <GlobalStyle />
      {children}
    </DefaultThemeProvider>
  );
};
