import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/enums';
import {
  getCurrentSubmission,
  getCurrentSubmissionResult,
  getCurrentTraining,
  getTrainingLoader,
} from 'src/store/selectors';
import actions from 'src/store/actions';
import { QuizWizard } from '../../components/QuizWizard';

interface RouteParams {
  id: string;
}

export const TrainingWizardPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: trainingId } = useParams<RouteParams>();
  const currentTraining = useSelector(getCurrentTraining);
  const currentSubmission = useSelector(getCurrentSubmission);
  const currentSubmissionResult = useSelector(getCurrentSubmissionResult);
  const { loading } = useSelector(getTrainingLoader);

  useEffect(() => {
    dispatch(actions.trainingReset());
    dispatch(actions.trainingSubmissionRequest(Number(trainingId)));
    dispatch(actions.trainingLoadRequest(Number(trainingId)));
  }, []);

  useEffect(() => {
    if (currentSubmissionResult) {
      history.replace(
        `${RoutesEnum.QUIZ_RESULT}/${currentTraining.id}/submission/${currentSubmission.id}`,
      );
    }
  }, [currentSubmissionResult]);

  const sendSubmissionResponse = (
    questionId: number,
    choiceId: number,
    isLastQuestion: boolean,
  ): void => {
    dispatch(
      actions.trainingSubmissionResponseRequest(
        Number(trainingId),
        Number(currentSubmission.id),
        Number(questionId),
        Number(choiceId),
        isLastQuestion,
      ),
    );
  };

  if (!loading && currentSubmission && !currentSubmission.id) {
    return <Redirect to={RoutesEnum.MY_TRAINING} />;
  }

  return (
    <>
      {currentTraining && (
        <QuizWizard
          quizQuestions={currentTraining.questions}
          sendSubmissionResponse={sendSubmissionResponse}
        />
      )}
    </>
  );
};
