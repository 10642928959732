import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { device } from 'src/theme';
import { THIconGear } from 'src/theme/media';

export const PageSubTitle = styled.p`
  text-align: center;
  margin-top: ${remConvert(8)};
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: ${remConvert(16)};
  cursor: pointer;
  text-align: center;
  width: 100%;

  @media ${device.lg} {
    text-align: right;
  }
`;

export const PageTitle = styled.h1`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-top: ${remConvert(8)};
  margin-bottom: 0;
`;

export const PageContentBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${remConvert(42)};
`;

export const ProgressMeterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-image: none !important;
  background-repeat: no-repeat;
  background-position: left bottom;

  @media ${device.lg} {
    background-image: url(${THIconGear}) !important;
    background-repeat: no-repeat;
    background-position: left bottom;
  }
`;
