import { call, takeEvery, put } from 'redux-saga/effects';
import {
  DocumentDraftFromResponse,
  DocumentDraftLoadRequestAction,
  DOCUMENT_DRAFT_LOAD_REQUEST,
  DOCUMENT_DRAFT_SAVE_REQUEST,
  DOCUMENT_DRAFT_UPDATE_REQUEST,
  DocumentDraftSaveRequestAction,
  DocumentDraftUpdateRequestAction,
} from 'src/store/types';

import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const getDocumentDraftById = function* async({
  documentDraftId,
  withTemplate,
}: DocumentDraftLoadRequestAction): Generator {
  try {
    const res = yield call(
      api.getDocumentDraftById,
      documentDraftId,
      withTemplate,
    );
    yield put(
      actions.documentDraftLoadSuccess(res as DocumentDraftFromResponse),
    );
  } catch (err) {
    yield put(
      actions.documentDraftLoadError(
        'Failed to get document draft',
        err as Error,
      ),
    );
  }
};

export const saveDocumentDraft = function* async({
  documentDraft,
}: DocumentDraftSaveRequestAction): Generator {
  try {
    const res = yield call(api.saveDocumentDraft, documentDraft);
    yield put(
      actions.documentDraftSaveSuccess(res as DocumentDraftFromResponse),
    );
  } catch (err) {
    yield put(
      actions.documentSaveError('Failed to save document draft', err as Error),
    );
  }
};

export const updateDocumentDraft = function* async({
  documentDraftId,
  documentDraft,
}: DocumentDraftUpdateRequestAction): Generator {
  try {
    const res = yield call(
      api.updateDocumentDraft,
      documentDraftId,
      documentDraft,
    );
    yield put(
      actions.documentDraftUpdateSuccess(res as DocumentDraftFromResponse),
    );
  } catch (err) {
    yield put(
      actions.documentDraftUpdateError(
        'Failed to update document draft',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(DOCUMENT_DRAFT_LOAD_REQUEST, getDocumentDraftById),
    yield takeEvery(DOCUMENT_DRAFT_SAVE_REQUEST, saveDocumentDraft),
    yield takeEvery(DOCUMENT_DRAFT_UPDATE_REQUEST, updateDocumentDraft),
  ];
}
