import React from 'react';
import { FormInstance } from 'antd/es/form/Form';
import {
  Question,
  AnswerListingIndexedItem,
  AnswerSuggestionsMap,
  SelectedInterviewState,
  AssessmentRiskAnalysisState,
} from 'src/store/types';

import InterviewQuestion from './InterviewQuestion';

interface InterviewQuestionListProps {
  questions?: Question[];
  answers: AnswerListingIndexedItem;
  assessmentSuggestions?: AnswerSuggestionsMap;
  selectedInterview?: SelectedInterviewState;
  userId: number;
  form: FormInstance;
  assessmentRiskAnalysis?: AssessmentRiskAnalysisState;
}

const InterviewQuestionList = React.memo(
  (props: InterviewQuestionListProps) => {
    const {
      questions,
      answers,
      assessmentSuggestions,
      selectedInterview,
      userId,
      form,
      assessmentRiskAnalysis,
    } = props;

    if (!questions) {
      return <div>No questions</div>;
    }

    return (
      <div>
        {questions.map((question: Question) => {
          const { id = 0, type } = question;
          const suggestions: string[] =
            (assessmentSuggestions &&
              type.key &&
              assessmentSuggestions[type.key]) ||
            [];
          const associatedRisk = assessmentRiskAnalysis?.answers[id];

          return (
            <div key={id} id={String(id)}>
              <InterviewQuestion
                key={id}
                question={question}
                answer={answers[id]}
                suggestions={suggestions.sort()}
                interviewId={selectedInterview?.interview.id as number}
                assignee={selectedInterview?.questionAssignee.find(
                  (assignee) => assignee?.questionId === id,
                )}
                userId={userId}
                form={form}
                assessmentRiskAnalysisItem={associatedRisk}
              />
            </div>
          );
        })}
      </div>
    );
  },
);

export default InterviewQuestionList;
