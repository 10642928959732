import {
  ASSESSMENT_UPDATE_ERROR,
  ASSESSMENT_UPDATE_REQUEST,
  ASSESSMENT_UPDATE_SUCCESS,
  ASSESSMENT_UPDATE_STATUS_ERROR,
  ASSESSMENT_UPDATE_STATUS_REQUEST,
  ASSESSMENT_UPDATE_STATUS_SUCCESS,
  ASSESSMENT_LOAD_ERROR,
  ASSESSMENT_LOAD_REQUEST,
  ASSESSMENT_LOAD_SUCCESS,
  ASSESSMENT_LOAD_SUGGESTIONS_ERROR,
  ASSESSMENT_LOAD_SUGGESTIONS_REQUEST,
  ASSESSMENT_LOAD_SUGGESTIONS_SUCCESS,
  ASSESSMENT_SAVE_REQUEST,
  ASSESSMENT_SAVE_SUCCESS,
  ASSESSMENT_SAVE_ERROR,
  ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST,
  ASSESSMENT_LOAD_RISK_ANALYSIS_SUCCESS,
  ASSESSMENT_LOAD_RISK_ANALYSIS_ERROR,
  AssessmentState,
  AssessmentFromResponse,
  AssessmentUpdateErrorAction,
  AssessmentUpdateRequestAction,
  AssessmentUpdateSuccessAction,
  AssessmentUpdateStatusErrorAction,
  AssessmentUpdateStatusRequestAction,
  AssessmentUpdateStatusSuccessAction,
  AssessmentLoadErrorAction,
  AssessmentLoadRequestAction,
  AssessmentLoadSuccessAction,
  AssessmentLoadSuggestionsErrorAction,
  AssessmentLoadSuggestionsRequestAction,
  AssessmentLoadSuggestionsSuccessAction,
  AssessmentSaveRequestAction,
  AssessmentSaveSuccessAction,
  AssessmentSaveErrorAction,
  AssessmentStatus,
  AssessmentQueryParams,
  AssessmentInput,
  AssessmentLoadRiskAnalysisRequestAction,
  AssessmentLoadRiskAnalysisSuccessAction,
  AssessmentRiskAnalysisFromResponse,
  AssessmentLoadRiskAnalysisErrorAction,
} from 'src/store/types';

import { showErrorMessage } from './error';

export const assessmentUpdateRequest = (
  assessment: AssessmentState,
): AssessmentUpdateRequestAction => ({
  type: ASSESSMENT_UPDATE_REQUEST,
  assessment: {
    ...assessment,
    updatedAt: new Date().toString(),
  },
});

export const assessmentUpdateSuccess = (
  assessment: AssessmentFromResponse,
): AssessmentUpdateSuccessAction => ({
  type: ASSESSMENT_UPDATE_SUCCESS,
  assessment,
});

export const assessmentUpdateError = (
  errorMessage: string,
  originalError?: Error,
): AssessmentUpdateErrorAction =>
  showErrorMessage(ASSESSMENT_UPDATE_ERROR, errorMessage, originalError);

export const assessmentUpdateStatusRequest = (
  assessmentId: number,
  status: AssessmentStatus,
): AssessmentUpdateStatusRequestAction => ({
  type: ASSESSMENT_UPDATE_STATUS_REQUEST,
  assessmentId,
  status,
});

export const assessmentUpdateStatusSuccess = (
  assessmentId: number,
  status: AssessmentStatus,
): AssessmentUpdateStatusSuccessAction => ({
  type: ASSESSMENT_UPDATE_STATUS_SUCCESS,
  assessmentId,
  status,
});

export const assessmentUpdateStatusError = (
  errorMessage: string,
  originalError?: Error,
): AssessmentUpdateStatusErrorAction =>
  showErrorMessage(ASSESSMENT_UPDATE_STATUS_ERROR, errorMessage, originalError);

export const assessmentLoadRequest = (
  assessmentId: number,
  options?: AssessmentQueryParams,
): AssessmentLoadRequestAction => ({
  type: ASSESSMENT_LOAD_REQUEST,
  assessmentId,
  options,
});

export const assessmentLoadSuccess = (
  assessment: AssessmentFromResponse,
): AssessmentLoadSuccessAction => ({
  type: ASSESSMENT_LOAD_SUCCESS,
  assessment,
});

export const assessmentLoadError = (
  errorMessage: string,
  originalError?: Error,
): AssessmentLoadErrorAction =>
  showErrorMessage(ASSESSMENT_LOAD_ERROR, errorMessage, originalError);

export const assessmentLoadSuggestionsRequest = (
  assessmentId: number,
  excludeInterviewId: number,
): AssessmentLoadSuggestionsRequestAction => ({
  type: ASSESSMENT_LOAD_SUGGESTIONS_REQUEST,
  assessmentId,
  excludeInterviewId,
});

export const assessmentLoadSuggestionsSuccess = (suggestions: {
  [k: string]: string[];
}): AssessmentLoadSuggestionsSuccessAction => ({
  type: ASSESSMENT_LOAD_SUGGESTIONS_SUCCESS,
  suggestions,
});

export const assessmentLoadSuggestionsError = (
  errorMessage: string,
  originalError?: Error,
): AssessmentLoadSuggestionsErrorAction =>
  showErrorMessage(
    ASSESSMENT_LOAD_SUGGESTIONS_ERROR,
    errorMessage,
    originalError,
  );

export const assessmentSaveRequest = (
  assessmentInput: AssessmentInput,
): AssessmentSaveRequestAction => ({
  type: ASSESSMENT_SAVE_REQUEST,
  assessmentInput,
});

export const assessmentSaveSuccess = (
  assessment: AssessmentFromResponse,
): AssessmentSaveSuccessAction => ({
  type: ASSESSMENT_SAVE_SUCCESS,
  assessment,
});

export const assessmentSaveError = (
  errorMessage: string,
  originalError?: Error,
): AssessmentSaveErrorAction =>
  showErrorMessage(ASSESSMENT_SAVE_ERROR, errorMessage, originalError);

export const assessmentLoadRiskAnalysisRequest = (
  assessmentId: number,
): AssessmentLoadRiskAnalysisRequestAction => ({
  type: ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST,
  assessmentId,
});

export const assessmentLoadRiskAnalysisSuccess = (
  assessmentRiskAnalysis: AssessmentRiskAnalysisFromResponse,
): AssessmentLoadRiskAnalysisSuccessAction => ({
  type: ASSESSMENT_LOAD_RISK_ANALYSIS_SUCCESS,
  assessmentRiskAnalysis,
});

export const assessmentLoadRiskAnalysisError = (
  errorMessage: string,
  originalError?: Error,
): AssessmentLoadRiskAnalysisErrorAction =>
  showErrorMessage(
    ASSESSMENT_LOAD_RISK_ANALYSIS_ERROR,
    errorMessage,
    originalError,
  );
