import React, { FC } from 'react';
import { Popover, PageHeader, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'src/components/Breadcrumb';
import { getCompanyFromState } from 'src/store/selectors';
import { CompanyState } from 'src/store/types';
import { InfoCircleOutlined } from 'src/theme/icons';
import { CompanyInfoCard } from 'src/features/risk-assessment/components/CompanyInfoCard';
import {
  ExtraOptionsContainer,
  Header,
  SubTitle,
  Title,
  Description,
} from './Styles';

interface TopNavBarProps {
  prevRoute?: {
    url: string;
    name: string;
  } | null;
  title: string;
  subtitle?: string;
  onBackCallback?: () => Promise<void> | void;
  extraOptions?: React.ReactNode;
  withCompany?: boolean;
  sticky?: boolean;
  description?: string;
}

export const TopNavbar: FC<TopNavBarProps> = ({
  extraOptions,
  onBackCallback,
  prevRoute,
  title,
  subtitle,
  withCompany,
  sticky,
  description,
}) => {
  const history = useHistory();

  const company = useSelector(getCompanyFromState);

  const handleOnBack = async (): Promise<void> => {
    if (prevRoute) {
      onBackCallback && (await onBackCallback());
      history.push(prevRoute.url);
    }
  };

  const ExtraOptions = () => (
    <ExtraOptionsContainer>{extraOptions}</ExtraOptionsContainer>
  );

  const getTitle = () =>
    withCompany && company?.id !== 0 ? `${company.name} ${title}` : title;

  const getPopoverContent = (company: CompanyState) => (
    <CompanyInfoCard company={company} />
  );

  return (
    <>
      <Header sticky={sticky}>
        <PageHeader
          style={{ padding: 0 }}
          title={
            prevRoute && (
              <Breadcrumb text={prevRoute.name} onClick={handleOnBack} />
            )
          }
          subTitle={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Title
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'auto',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                  fontSize: 'calc(12px + 0.5vw)',
                }}
              >
                {getTitle()}
              </Title>
              {withCompany && company?.id !== 0 && (
                <Popover content={getPopoverContent(company)}>
                  <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                </Popover>
              )}
              {subtitle && <SubTitle>{subtitle}</SubTitle>}
            </div>
          }
          extra={[<ExtraOptions key={1} />]}
        />
        <Divider style={{ marginTop: 0, marginBottom: 24 }} />
      </Header>
      <Description>{description}</Description>
    </>
  );
};
