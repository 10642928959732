import { Spin } from 'antd';
import React, { FC } from 'react';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import useTenantContext from 'src/shared/hooks/useTenant';

interface LogoProps {
  isDarkLogo?: boolean;
}

export const Logo: FC<LogoProps> = ({ isDarkLogo }) => {
  const { logoName, brandName } = useTenantContext();
  const { user, is2FAauthenticated } = useAuth();

  const getLogoOrSpinner = () => {
    if (logoName !== 'None') {
      const logoSmall = require(`src/assets/${logoName}_Small.svg`);
      const logoMedium = require(`src/assets/${logoName}_Medium.svg`);
      const logoDark = require(`src/assets/${logoName}Dark_Medium.svg`);

      return (
        <div>
          {isDarkLogo ? (
            <img src={logoDark} alt={brandName} />
          ) : user.preference.collapseSideBar || !is2FAauthenticated ? (
            <img src={logoSmall} alt={brandName} />
          ) : (
            <img src={logoMedium} alt={brandName} />
          )}
        </div>
      );
    }

    return <Spin size="large" />;
  };

  return getLogoOrSpinner();
};
