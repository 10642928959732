import { call, takeEvery, put } from 'redux-saga/effects';
import {
  TemplateState,
  TEMPLATES_LOAD_REQUEST,
  TemplatesLoadRequestAction,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadTemplates = function* async({
  assessmentTypeId,
}: TemplatesLoadRequestAction): Generator {
  try {
    const templates = yield call(api.getTemplateList, assessmentTypeId);
    yield put(actions.templatesLoadSuccess(templates as TemplateState[]));
  } catch (err) {
    yield put(
      actions.templatesLoadError('Failed to load templates', err as Error),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(TEMPLATES_LOAD_REQUEST, loadTemplates)];
}
