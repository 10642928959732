import {
  DocumentState,
  Loader,
  TemplateState,
  DocumentLoadSuccessAction,
  DocumentStatus,
  ShowErrorAction,
  TemplateProcessingState,
} from '../types';

export const DOCUMENT_DRAFT_LOAD_REQUEST = 'DOCUMENT_DRAFT_LOAD_REQUEST';
export const DOCUMENT_DRAFT_LOAD_SUCCESS = 'DOCUMENT_DRAFT_LOAD_SUCCESS';
export const DOCUMENT_DRAFT_LOAD_ERROR = 'DOCUMENT_DRAFT_LOAD_ERROR';

export const DOCUMENT_DRAFT_SAVE_REQUEST = 'DOCUMENT_DRAFT_SAVE_REQUEST';
export const DOCUMENT_DRAFT_SAVE_SUCCESS = 'DOCUMENT_DRAFT_SAVE_SUCCESS';
export const DOCUMENT_DRAFT_SAVE_ERROR = 'DOCUMENT_DRAFT_SAVE_ERROR';

export const DOCUMENT_DRAFT_UPDATE_REQUEST = 'DOCUMENT_DRAFT_UPDATE_REQUEST';
export const DOCUMENT_DRAFT_UPDATE_SUCCESS = 'DOCUMENT_DRAFT_UPDATE_SUCCESS';
export const DOCUMENT_DRAFT_UPDATE_ERROR = 'DOCUMENT_DRAFT_UPDATE_ERROR';

export const SET_SELECTED_DOCUMENT_DRAFT = 'SET_SELECTED_DOCUMENT_DRAFT';

export interface DocumentDraftFromResponse {
  id: number;
  createdAt: string;
  updatedAt: string;
  title?: string;
  body: string;
  previewBody: string;
  archived?: boolean;
  document: DocumentState;
  template: TemplateState;
  status: DocumentStatus;
  processingStatus: TemplateProcessingState;
}

export interface DocumentDraftState
  extends Omit<DocumentDraftFromResponse, 'document' | 'template'> {
  documentId?: number;
  templateId?: number;
}

export interface DocumentDraftInput {
  body?: string;
  documentId?: number;
  templateId?: number;
  title?: string;
  archived?: boolean;
  status?: DocumentStatus;
}

export interface SelectedDocumentDraftState {
  documentDraft: DocumentDraftState;
  loader: Loader;
}

export interface SetSelectedDocumentDraftAction {
  type: typeof SET_SELECTED_DOCUMENT_DRAFT;
  documentDraft: DocumentDraftState;
}

export interface DocumentDraftLoadRequestAction {
  type: typeof DOCUMENT_DRAFT_LOAD_REQUEST;
  documentDraftId: number | string;
  withTemplate: boolean;
}

export interface DocumentDraftLoadSuccessAction {
  type: typeof DOCUMENT_DRAFT_LOAD_SUCCESS;
  documentDraft: DocumentDraftFromResponse;
}

export type DocumentDraftLoadErrorAction = ShowErrorAction<
  typeof DOCUMENT_DRAFT_LOAD_ERROR
>;

export interface DocumentDraftSaveRequestAction {
  type: typeof DOCUMENT_DRAFT_SAVE_REQUEST;
  documentDraft: DocumentDraftState | DocumentDraftInput;
}

export interface DocumentDraftSaveSuccessAction {
  type: typeof DOCUMENT_DRAFT_SAVE_SUCCESS;
  documentDraft: DocumentDraftFromResponse;
}

export type DocumentDraftSaveErrorAction = ShowErrorAction<
  typeof DOCUMENT_DRAFT_SAVE_ERROR
>;

export interface DocumentDraftUpdateRequestAction {
  type: typeof DOCUMENT_DRAFT_UPDATE_REQUEST;
  documentDraftId: number | string;
  documentDraft: DocumentDraftState | DocumentDraftInput;
}

export interface DocumentDraftUpdateSuccessAction {
  type: typeof DOCUMENT_DRAFT_UPDATE_SUCCESS;
  documentDraft: DocumentDraftFromResponse;
}

export type DocumentDraftUpdateErrorAction = ShowErrorAction<
  typeof DOCUMENT_DRAFT_UPDATE_ERROR
>;

export type DocumentDraftActionTypes =
  | SetSelectedDocumentDraftAction
  | DocumentDraftLoadRequestAction
  | DocumentDraftLoadErrorAction
  | DocumentDraftLoadSuccessAction
  | DocumentDraftSaveErrorAction
  | DocumentDraftSaveRequestAction
  | DocumentDraftSaveSuccessAction
  | DocumentLoadSuccessAction
  | DocumentDraftUpdateRequestAction
  | DocumentDraftUpdateSuccessAction
  | DocumentDraftUpdateErrorAction;
