import {
  QuestionsLoadSuccessAction,
  QUESTIONS_LOAD_REQUEST,
  QuestionsLoadRequestAction,
  QUESTIONS_LOAD_SUCCESS,
  QuestionsLoadErrorAction,
  QUESTIONS_LOAD_ERROR,
  FormQuestionsState,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const questionsLoadRequest = (
  assessmentTypeId: number,
): QuestionsLoadRequestAction => ({
  type: QUESTIONS_LOAD_REQUEST,
  assessmentTypeId,
});

export const questionsLoadSuccess = (
  forms: FormQuestionsState[],
): QuestionsLoadSuccessAction => ({
  type: QUESTIONS_LOAD_SUCCESS,
  forms,
});

export const questionsLoadError = (
  errorMessage: string,
  originalError: Error,
): QuestionsLoadErrorAction =>
  showErrorMessage(QUESTIONS_LOAD_ERROR, errorMessage, originalError);
