import { Empty } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { InterviewState } from 'src/store/types';
import { RiskAssessmentType } from 'src/features/risk-assessment/routes';
import { Grid, HipaaInfoSectionContainer, SettingsContainer } from './Styles';
import { TableRowInterface } from './riskAssessmentTableTypes';
import {
  InfoLabel,
  presentHipaaRowInformation,
} from './RiskAssessmentTableUtils';
import { RiskAssessmentTableSettings } from '.';

export interface RiskAssessmentTableProps {
  interview?: InterviewState;
  assesmentType: string;
  disableReviewSettings: boolean;
  selectedQuestionId?: number;
  dataSource: TableRowInterface[];
  setCompletionDatePanelVisible: (val: boolean) => void;
  setSelectedAnswerId: (answerId: number) => void;
  setSelectedInterviewId: (interviewId: number) => void;
  handleOpenDiscussion: (questionId: number) => void;
  presentAnswer?: (rowData: TableRowInterface) => any;
}

const ExpandableColumn = styled.div`
  padding-right: 20px;
`;

export const RiskAssessmentTable: FC<RiskAssessmentTableProps> = ({
  interview,
  assesmentType,
  disableReviewSettings,
  selectedQuestionId,
  dataSource,
  setCompletionDatePanelVisible,
  setSelectedAnswerId,
  setSelectedInterviewId,
  handleOpenDiscussion,
  presentAnswer,
}) => {
  return (
    <Grid
      dataSource={dataSource}
      pagination={false}
      rowKey="questionId"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No questions registered for this form"
          />
        ),
      }}
      onRow={(record: TableRowInterface, rowIndex) => {
        return {
          id: `question${record.questionId}`,
        };
      }}
      expandable={{
        expandIcon: ({ expanded, onExpand, record }) => null,
        rowExpandable: (record: TableRowInterface) => true,
        defaultExpandAllRows: true,
        expandedRowRender: (record: TableRowInterface) => {
          const info = presentHipaaRowInformation(record.hipaaInfo);
          let legalRef: string;
          let expandableTitle: string;
          switch (assesmentType) {
            case RiskAssessmentType.GLBA:
              legalRef = info.legalNist;
              expandableTitle = 'NIST';
              break;
            case RiskAssessmentType.NIST_800_171:
              legalRef = info.legalNist;
              expandableTitle = 'NIST';
              break;
            case RiskAssessmentType.CMMC:
              legalRef = info.legalCmmc;
              expandableTitle = 'CMMC';
              break;
            default:
              legalRef = info.legalRef;
              expandableTitle = 'HIPAA Law';
              break;
          }
          return (
            <HipaaInfoSectionContainer>
              <ExpandableColumn className="column">
                <InfoLabel title={expandableTitle} info={legalRef} />
                {info.analysis && (
                  <InfoLabel title="Analysis" info={info.analysis} />
                )}
                {info.delegate && (
                  <InfoLabel title="Delegate" info={info.delegate} />
                )}
              </ExpandableColumn>
              {(info.actionItem || info.risk) && (
                <ExpandableColumn className="column">
                  {info.actionItem && (
                    <InfoLabel title="Action Item" info={info.actionItem} />
                  )}
                  {info.risk && (
                    <InfoLabel title="Risk Level" info={info.risk} />
                  )}
                </ExpandableColumn>
              )}
            </HipaaInfoSectionContainer>
          );
        },
      }}
      rowClassName={(record: TableRowInterface) =>
        record.questionId === selectedQuestionId ? 'selected' : ''
      }
    >
      <Column<TableRowInterface>
        title="Question"
        dataIndex="question"
        key="question"
        width="40%"
      />
      <Column<TableRowInterface>
        title="Answer"
        dataIndex="answer"
        key="answer"
        render={(_, record: TableRowInterface) => presentAnswer!(record)}
      />
      <Column<TableRowInterface>
        title={false}
        dataIndex=""
        key="action"
        render={(_, record: TableRowInterface) => (
          <SettingsContainer>
            <RiskAssessmentTableSettings
              answerId={record.answerId}
              questionId={record.questionId}
              interviewId={interview?.id}
              disableReviewSettings={
                disableReviewSettings ||
                !(record.answerId && record.hipaaInfo.actionItem.length > 0)
              }
              setCompletionDatePanelVisible={setCompletionDatePanelVisible}
              setSelectedAnswerId={setSelectedAnswerId}
              setSelectedInterviewId={setSelectedInterviewId}
              handleOpenDiscussion={handleOpenDiscussion}
            />
          </SettingsContainer>
        )}
      />
    </Grid>
  );
};
