import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Badge } from 'antd';
import actions from 'src/store/actions';
import { getNotificationListingLoader } from 'src/store/selectors';
import { Button } from 'src/components/Button';
import { NotificationListItemData } from './NotificationListingTable';

export const NotificationListItem: FC<{ item: NotificationListItemData }> = ({
  item,
}) => {
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationLoader = useSelector(getNotificationListingLoader);

  useEffect(() => {
    if (!notificationLoader.loading) {
      setLoadingUpdate(false);
    }
  }, [notificationLoader]);

  const setNotificationArchived = (
    { id }: NotificationListItemData,
    value: boolean,
  ) => {
    setLoadingUpdate(true);
    dispatch(
      actions.notificationUpdateRequestAction({
        id,
        archived: value,
      }),
    );
  };

  return (
    <>
      {item.pending && <Badge color="green" title="New" />}
      {item.body}
      <p className="notification-date">{item.createdAt}</p>
      <div className="notification-actions-container">
        {!item.archived ? (
          <Button
            size="middle"
            altered
            title="Archive"
            onClick={() => setNotificationArchived(item, true)}
            loading={loadingUpdate}
          />
        ) : (
          <Button
            size="middle"
            altered
            title="Unarchive"
            onClick={() => setNotificationArchived(item, false)}
            loading={loadingUpdate}
          />
        )}
        <Button
          size="middle"
          title="Show me"
          onClick={() => history.push(item.location)}
        />
      </div>
    </>
  );
};
