import React, { FC, useState } from 'react';
import { ToolbarItem } from './ToolbarItem';
import { TemplateEditorVariableSelectorWindow } from './TemplateEditorSelectorWindows';
import { RegExpToken } from '../useRegExpTokens';

interface VariableToolbarItemProps {
  variableToken: RegExpToken;
  updateEditorContent: (val: string) => void;
  templateId: number;
}

export const VariableToolbarItem: FC<VariableToolbarItemProps> = ({
  variableToken,
  updateEditorContent,
  templateId,
}) => {
  const [childrenVisible, setChildrenVisible] = useState<boolean>(false);

  const execute = (selectedId: string) => {
    if (selectedId?.length > 0) {
      const textToAdd = variableToken.getToken(selectedId);
      updateEditorContent(textToAdd);
    }
    setChildrenVisible(false);
  };

  return (
    <ToolbarItem
      icon={
        <svg
          width="12"
          height="12"
          viewBox="0 0 135.46666 135.46667"
          version="1.1"
        >
          <g>
            <g transform="matrix(1.8416256,0,0,3.6983265,-41.706689,-148.36772)">
              <path d="m 94.162183,54.625617 h -6.482521 l -2.605317,5.408851 h 5.785034 l -0.779543,4.174034 h -7.015892 l -3.015603,6.156699 h -4.943948 l 2.974575,-6.156699 h -5.415777 l -2.974574,6.156699 h -5.026005 l 2.995089,-6.156699 h -5.538863 l 0.779544,-4.174034 h 6.769721 l 2.584802,-5.408851 h -6.010691 l 0.779543,-4.156642 h 7.24155 l 2.872002,-6.000173 h 5.026005 l -2.872003,6.000173 h 5.395263 l 2.892517,-6.000173 h 5.026004 l -2.892517,6.000173 h 5.23115 z m -11.405954,-0.03479 h -5.497834 l -2.666859,5.495811 h 5.518348 z" />
              <g transform="scale(1.2384069,0.80748907)">
                <path d="M 52.509935,58.499714 35.837804,85.516747 H 25.974061 L 21.884216,58.499714 h 8.925485 l 1.972748,18.596764 10.417075,-18.596764 z" />
              </g>
            </g>
          </g>
        </svg>
      }
      childrenVisible={childrenVisible}
      setChildrenVisible={setChildrenVisible}
      tooltip="Variable Tokens"
    >
      <TemplateEditorVariableSelectorWindow
        templateId={templateId}
        onSelection={(id: number) => execute(`${id}`)}
        onClose={() => setChildrenVisible(false)}
      />
    </ToolbarItem>
  );
};
