import { Loader } from './loader';
import {
  DocumentFromResponse,
  DocumentType,
  DocumentState,
  DocumentApproveSuccessAction,
  DocumentCloseSuccessAction,
  ShowErrorAction,
} from '../types';

export const DOCUMENTS_BY_ASSESSMENT_LOAD_REQUEST =
  'DOCUMENTS_BY_ASSESSMENT_LOAD_REQUEST';
export const DOCUMENTS_BY_ASSESSMENT_LOAD_SUCCESS =
  'DOCUMENTS_BY_ASSESSMENT_LOAD_SUCCESS';
export const DOCUMENTS_BY_ASSESSMENT_LOAD_ERROR =
  'DOCUMENTS_BY_ASSESSMENT_LOAD_ERROR';

export interface DocumentListingFilters {
  offset: number;
  limit: number;
}

export interface DocumentListingIndexedItem {
  [key: string]: DocumentState;
}

export interface DocumentListingState {
  documents: DocumentListingIndexedItem;
  loader: Loader;
}

export interface DocumentsByAssessmentLoadRequestAction {
  type: typeof DOCUMENTS_BY_ASSESSMENT_LOAD_REQUEST;
  assessmentId: number;
  documentType?: DocumentType;
}

export interface DocumentsByAssessmentLoadSuccessAction {
  type: typeof DOCUMENTS_BY_ASSESSMENT_LOAD_SUCCESS;
  documents: DocumentFromResponse[];
  assessmentId: number;
}

export type DocumentsByAssessmentLoadErrorAction = ShowErrorAction<
  typeof DOCUMENTS_BY_ASSESSMENT_LOAD_ERROR
>;

export type DocumentListingActionTypes =
  | DocumentsByAssessmentLoadRequestAction
  | DocumentsByAssessmentLoadSuccessAction
  | DocumentsByAssessmentLoadErrorAction
  | DocumentApproveSuccessAction
  | DocumentCloseSuccessAction;
