import { combineReducers } from 'redux';
import {
  Loader,
  DocumentDraftState,
  DocumentDraftActionTypes,
  DOCUMENT_DRAFT_LOAD_REQUEST,
  DOCUMENT_DRAFT_LOAD_SUCCESS,
  DOCUMENT_DRAFT_LOAD_ERROR,
  SET_SELECTED_DOCUMENT_DRAFT,
  SelectedDocumentDraftState,
  DOCUMENT_LOAD_SUCCESS,
  DocumentDraftFromResponse,
  DOCUMENT_DRAFT_UPDATE_SUCCESS,
  DOCUMENT_DRAFT_SAVE_SUCCESS,
  DocumentStatus,
  DOCUMENT_DRAFT_UPDATE_REQUEST,
  DOCUMENT_DRAFT_UPDATE_ERROR,
  DOCUMENT_DRAFT_SAVE_REQUEST,
  DOCUMENT_DRAFT_SAVE_ERROR,
  TemplateProcessingState,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const documentDraftInitialState: DocumentDraftState = {
  id: 0,
  createdAt: '',
  updatedAt: '',
  title: '',
  body: '',
  previewBody: '',
  documentId: undefined,
  templateId: undefined,
  status: DocumentStatus.ACTIVE,
  processingStatus: TemplateProcessingState.READY,
};

const loaderReducer = (
  state: Loader = loaderInitialState,
  action: DocumentDraftActionTypes,
): Loader => {
  switch (action.type) {
    case DOCUMENT_DRAFT_SAVE_REQUEST:
    case DOCUMENT_DRAFT_LOAD_REQUEST:
    case DOCUMENT_DRAFT_UPDATE_REQUEST: {
      return { ...loaderInitialState, loading: true };
    }
    case DOCUMENT_DRAFT_SAVE_SUCCESS:
    case DOCUMENT_DRAFT_LOAD_SUCCESS:
    case DOCUMENT_DRAFT_UPDATE_SUCCESS: {
      return { ...loaderInitialState, success: true, completed: true };
    }
    case DOCUMENT_DRAFT_SAVE_ERROR:
    case DOCUMENT_DRAFT_LOAD_ERROR:
    case DOCUMENT_DRAFT_UPDATE_ERROR: {
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
      };
    }
    default:
      return state;
  }
};

const serializeDocumentDraft = (
  documentDraft: DocumentDraftFromResponse,
): DocumentDraftState => {
  const {
    id,
    createdAt,
    updatedAt,
    title,
    body,
    document,
    template,
    status,
    previewBody,
    processingStatus,
  } = documentDraft;

  return {
    id,
    createdAt,
    updatedAt,
    title,
    body,
    previewBody,
    documentId: document ? document.id : undefined,
    templateId: template ? template.id : undefined,
    status,
    processingStatus,
  };
};

const documentDraftReducer = (
  state: DocumentDraftState = documentDraftInitialState,
  action: DocumentDraftActionTypes,
): DocumentDraftState => {
  switch (action.type) {
    case DOCUMENT_LOAD_SUCCESS: {
      const { documentDraft } = action.document;
      if (!documentDraft) return documentDraftInitialState;
      return { ...serializeDocumentDraft(documentDraft) };
    }
    case DOCUMENT_DRAFT_SAVE_SUCCESS:
    case DOCUMENT_DRAFT_UPDATE_SUCCESS:
    case DOCUMENT_DRAFT_LOAD_SUCCESS: {
      if (!action.documentDraft || !action.documentDraft.id)
        return documentDraftInitialState;
      return { ...serializeDocumentDraft(action.documentDraft) };
    }
    case SET_SELECTED_DOCUMENT_DRAFT: {
      return { ...action.documentDraft };
    }
    default:
      return state;
  }
};

export const combinedDocumentDraftReducer =
  combineReducers<SelectedDocumentDraftState>({
    documentDraft: documentDraftReducer,
    loader: loaderReducer,
  });
