import { call, takeEvery, put } from 'redux-saga/effects';
import {
  FormsLoadRequestAction,
  FormsReOrderRequestAction,
  FORMS_LOAD_REQUEST,
  FORMS_REORDER_REQUEST,
} from 'src/store/types/formListing';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';
import { FormState } from '../types';

export const loadForms = function* async({
  withAssessmentType = false,
}: FormsLoadRequestAction): Generator {
  try {
    const forms = yield call(api.getFormList, withAssessmentType);
    yield put(actions.formsLoadSuccess(forms as FormState[]));
  } catch (err) {
    yield put(actions.formsLoadError('Failed to load forms', err as Error));
  }
};

export const reorderForms = function* async({
  forms,
}: FormsReOrderRequestAction): Generator {
  try {
    yield call(api.reorderForms, forms);
    yield put(actions.formsReOrderSuccess());
  } catch (err) {
    yield put(
      actions.formsReOrderFailed('Failed to reorder forms', err as Error),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(FORMS_LOAD_REQUEST, loadForms),
    yield takeEvery(FORMS_REORDER_REQUEST, reorderForms),
  ];
}
