import { ErrorSeverity, ShowErrorAction, SHOW_ERROR } from '../types';

export const showErrorMessage = <T>(
  type: T,
  message: string | string[],
  originalError?: Error,
): ShowErrorAction<T> => ({
  type,
  message,
  originalError,
  severity: ErrorSeverity['ERROR'],
});

export const showWarningMessage = <T>(
  type: T,
  message: string | string[],
  originalError?: Error,
): ShowErrorAction<any> => ({
  type,
  message,
  originalError,
  severity: ErrorSeverity['WARNING'],
});

export const showInfoMessage = (
  message: string | string[],
  type = SHOW_ERROR,
): ShowErrorAction<any> => ({
  type,
  message,
  severity: ErrorSeverity['INFO'],
});
