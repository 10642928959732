/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  CompanyListingIndexedItem,
  AssessmentState,
  CompanyObject,
  RootState,
  CompanyState,
  AssessmentTypeListingIndexedItem,
  Loader,
} from '../types';
import { AssessmentListingIndexedInterface } from '../types/assessmentListing';
import { getAssessmentsFromState } from './assessment';
import { getAssessmentTypes } from './assessmentType';
import { getFilteredIndexedCompanies } from './companyListing';

export const getIndexAssessmentsByCompany = createSelector(
  (state: RootState) => getAssessmentsFromState(state),
  (state: RootState) => getAssessmentTypes(state),
  (state: RootState, textToFilter: string) =>
    getFilteredIndexedCompanies(state, textToFilter),
  (
    assessments: AssessmentState[],
    assessmentTypes: AssessmentTypeListingIndexedItem,
    companyListingIndexedItem: CompanyListingIndexedItem,
  ) => {
    const indexedCompanies: AssessmentListingIndexedInterface = Object.keys(
      companyListingIndexedItem,
    ).reduce((indexedCompanies, key: string) => {
      const company = companyListingIndexedItem[String(key)];
      return {
        ...indexedCompanies,
        [company.name]: { company: company, assessments: [] },
      };
    }, {});
    assessments.forEach((assessment) => {
      const company: CompanyState =
        companyListingIndexedItem[Number(assessment.companyId)];
      if (company) {
        if (indexedCompanies[company.name]) {
          indexedCompanies[company.name].assessments.push({
            ...assessment,
            assessmentType: assessmentTypes[assessment.typeId],
          });
        } else {
          indexedCompanies[company.name] = {
            name: company.name,
            assessments: [
              {
                ...assessment,
                assessmentType: assessmentTypes[assessment.typeId],
              },
            ],
          } as CompanyObject;
        }
        indexedCompanies[company.name].company = company;
      }
    });
    return indexedCompanies;
  },
);

export const getAssessmentListingLoader = (state: RootState): Loader => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return state.assessmentListing.loader;
};
