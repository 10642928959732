import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

export interface FieldTypeProps {
  label: string;
  selected?: boolean;
  onClick: () => void;
}

const WideButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  background-color: white;
  text-align: left;
  height: 48px;
  margin: 4px 0;
  padding: 0 16px;
  border: 1px solid transparent;
  font-size: 13px;
  border-radius: 4px;

  &&:hover {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    background-color: white;
    border-color: transparent;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.lightBlue.main};
    color: white;
    box-shadow: unset;

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightBlue.main};
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      color: white;
    }
  }

  span {
    font-weight: normal;
  }
`;

export const FieldType: FC<FieldTypeProps> = ({ label, selected, onClick }) => {
  return (
    <WideButton className={selected ? 'selected' : ''} block onClick={onClick}>
      {label}
    </WideButton>
  );
};
