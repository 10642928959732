import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getLoadedQuestionInfo } from 'src/store/selectors';
import { QuestionsState, RootState } from 'src/store/types';
import { TokenPreview } from './TokenPreview';

interface QuestionTokenPreviewProps {
  questionId: string | undefined;
}

export const QuestionTokenPreview: FC<QuestionTokenPreviewProps> = ({
  questionId,
}) => {
  const question = useSelector<
    RootState,
    (QuestionsState & { formLabel: string }) | undefined
  >((state: RootState) =>
    getLoadedQuestionInfo(state, { questionId: Number(questionId) }),
  );
  const tooltip = question
    ? `(${question.formLabel}) ${question.question}`
    : `Question #"${questionId}" was not found on the selected assessment`;
  const text = `Q: ${question ? question.question : questionId}`;

  return <TokenPreview tooltip={tooltip} text={text} error={!question} />;
};

export const QuestionTokenPreviewMemoized = React.memo(QuestionTokenPreview);
