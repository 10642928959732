import {
  InterviewActionsTypes,
  INTERVIEW_LOAD_ACTIVITY_LOG_SUCCESS,
  ANSWER_LOAD_ACTIVITY_LOG_SUCCESS,
  ActivityLogResponse,
} from 'src/store/types';

export const initialActivityLogState: ActivityLogResponse = {
  records: [],
  page: 1,
  pageSize: 10,
  total: 0,
};

export const activityLogReducer = (
  state: ActivityLogResponse = initialActivityLogState,
  action: InterviewActionsTypes,
): ActivityLogResponse => {
  switch (action.type) {
    case INTERVIEW_LOAD_ACTIVITY_LOG_SUCCESS:
    case ANSWER_LOAD_ACTIVITY_LOG_SUCCESS: {
      return { ...action.reconciliation };
    }
    default:
      return state;
  }
};
