import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { PasswordRecoveryForm } from 'src/features/auth/components';
import { THIconGear } from 'src/theme/media';
import * as S from './Styles';

export const ForgottenPasswordPage: FC<unknown> = () => {
  return (
    <>
      <S.PageTitle>Reset your password</S.PageTitle>
      <S.PageContentContainer>
        <p>Enter your email address to send a password reset link.</p>
        <PasswordRecoveryForm />
      </S.PageContentContainer>
      <S.PageContentBottomContainer>
        <img src={THIconGear} />
        <Link to={'/login'}>
          <S.Label>Back to Login</S.Label>
        </Link>
      </S.PageContentBottomContainer>
    </>
  );
};
