import React, { FC } from 'react';
import { FormState, InterviewState } from 'src/store/types';
import { dataMapper } from './dataMapper';
import { RiskAssessmentTable } from './RiskAssessmentTable';
import { TableRowInterface } from './riskAssessmentTableTypes';

export interface DocumentListingTableProps {
  interview?: InterviewState;
  assesmentType: string;
  selectedQuestionId?: number;
  form: FormState;
  disableReviewSettings: boolean;
  setCompletionDatePanelVisible: (val: boolean) => void;
  setSelectedAnswerId: (answerId: number) => void;
  setSelectedInterviewId: (interviewId: number) => void;
  handleOpenDiscussion: (questionId: number) => void;
  presentAnswer?: (rowData: TableRowInterface) => any;
}

export const HippaRiskAssessmentTable: FC<DocumentListingTableProps> = ({
  ...props
}) => {
  const { form, interview } = props;
  const dataSource: TableRowInterface[] = dataMapper(form, interview);

  return <RiskAssessmentTable {...props} dataSource={dataSource} />;
};
