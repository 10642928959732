import moment from 'moment';
import { DocumentState } from 'src/store/types';
import { TableRowInterface } from './DocumentListingTable';

export const dataMapper = (
  documentData: DocumentState[],
): TableRowInterface[] => {
  return documentData.map((document) => {
    const { createdAt, id, name, status } = document;
    return {
      id,
      name,
      status,
      createdAt: moment(createdAt).format('MM-DD-YYYY'),
    };
  });
};
