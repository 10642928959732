import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form } from 'antd';

import { CategoryListing } from 'src/features/risk-assessment/components/CategoryListing';
import { TopNavbar } from 'src/components/TopNavbar';
import { assessmentTypesLoadAllRequest } from 'src/store/actions/assessmentTypes';
import { categoryLoadRequest } from 'src/store/actions/category';
import { formsLoadRequest } from 'src/store/actions/formListing';
import { getCategoriesWithForms, getCategoryLoader } from 'src/store/selectors';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { FormCreateCategory } from 'src/features/risk-assessment/components/FormCreateCategory';
import { CategoryState } from 'src/store/types';

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const CategoryListingPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const [slidingPanelVisible, setSlidingPanelVisible] =
    useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const categories = useSelector(getCategoriesWithForms);
  const categoryLoader = useSelector(getCategoryLoader);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(categoryLoadRequest());
    dispatch(formsLoadRequest(true));
    dispatch(assessmentTypesLoadAllRequest());
  }, []);

  useEffect(() => {
    if (categoryLoader.completed && !categoryLoader.loading) {
      setSlidingPanelVisible(false);
      form.resetFields();
    }
  }, [categoryLoader.loading]);

  const handleSaveButtonPress = (): void => {
    form.submit();
  };

  const handleOnClickUpdateCategory = (category: CategoryState): void => {
    const { id, name } = category;

    form.setFieldsValue({
      id,
      name,
    });

    setSelectedCategoryId(category.id);
    setSlidingPanelVisible(true);
  };

  return (
    <div id="main-container">
      <TopNavbar
        title="Manage Forms/Categories"
        prevRoute={{ name: 'Forms', url: '/forms' }}
        extraOptions={[]}
      />
      <PageContentContainer>
        <CategoryListing
          categories={categories}
          showUpdateCategoryPanel={handleOnClickUpdateCategory}
        />
        <SlidingPanel
          title={'Update Category'}
          visible={slidingPanelVisible}
          saveLabel={'Update'}
          onClose={() => setSlidingPanelVisible(false)}
          onSave={handleSaveButtonPress}
          loading={categoryLoader.loading}
        >
          <FormCreateCategory
            form={form}
            categoryToUpdateId={selectedCategoryId}
          />
        </SlidingPanel>
      </PageContentContainer>
    </div>
  );
};
