import React, { FC } from 'react';
import { HelpSection } from './HelpSection';

interface EditorHelpSectionProps {
  templateEditorHelpOpen: boolean;
  setTemplateEditorHelpOpen: (value: boolean) => void;
}

export const TemplateEditorHelpSection: FC<EditorHelpSectionProps> = ({
  templateEditorHelpOpen,
  setTemplateEditorHelpOpen,
}) => (
  <HelpSection
    isOpen={templateEditorHelpOpen}
    setIsOpen={setTemplateEditorHelpOpen}
    title={'Template Editor'}
  >
    <h4>Search</h4>
    <p>
      Press <b>CTRL+F</b> inside the template editor on the left side to
      activate search cursor.
    </p>
    <h4>Expressions</h4>
    <p>
      Searching by expressions allow to find patterns in text. This is an
      advanced search feature available but it requires some knowledge. You can
      use the cheat-sheet below to get familiar with this.
    </p>
    <a
      href="https://www.keycdn.com/support/regex-cheat-sheet"
      target="_blank"
      rel="noreferrer"
    >
      Regular Expressions Cheat-sheet
    </a>
  </HelpSection>
);
