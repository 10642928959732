import { showErrorMessage } from 'src/store/actions/error';
import {
  TrainingActivateErrorAction,
  TrainingActivateRequestAction,
  TrainingActivateSuccessAction,
  TrainingArchiveErrorAction,
  TrainingArchiveRequestAction,
  TrainingArchiveSuccessAction,
  TrainingDeleteErrorAction,
  TrainingDeleteRequestAction,
  TrainingDeleteSuccessAction,
  TrainingListingLoadErrorAction,
  TrainingListingLoadRequestAction,
  TrainingListingLoadSuccessAction,
  TrainingListingResponse,
  TRAINING_ACTIVATE_FAILED,
  TRAINING_ACTIVATE_REQUEST,
  TRAINING_ACTIVATE_SUCCESS,
  TRAINING_ARCHIVE_FAILED,
  TRAINING_ARCHIVE_REQUEST,
  TRAINING_ARCHIVE_SUCCESS,
  TRAINING_DELETE_FAILED,
  TRAINING_DELETE_REQUEST,
  TRAINING_DELETE_SUCCESS,
  TRAINING_LISTING_LOAD_FAILED,
  TRAINING_LISTING_LOAD_REQUEST,
  TRAINING_LISTING_LOAD_SUCCESS,
  UserTrainingListingLoadErrorAction,
  UserTrainingListingLoadRequestAction,
  UserTrainingListingLoadSuccessAction,
  USER_TRAINING_LISTING_LOAD_FAILED,
  USER_TRAINING_LISTING_LOAD_REQUEST,
  USER_TRAINING_LISTING_LOAD_SUCCESS,
} from '../types';

export const trainingListingLoadRequest = (
  page: number,
  pageSize: number,
  sort?: string,
  column?: string,
  name?: string,
): TrainingListingLoadRequestAction => ({
  type: TRAINING_LISTING_LOAD_REQUEST,
  page,
  pageSize,
  name,
  sort,
  column,
});

export const trainingListingLoadSuccess = (
  trainingListing: TrainingListingResponse,
): TrainingListingLoadSuccessAction => ({
  type: TRAINING_LISTING_LOAD_SUCCESS,
  trainingListing,
});

export const trainingListingLoadError = (
  errorMessage: string,
  originalError?: Error,
): TrainingListingLoadErrorAction =>
  showErrorMessage(TRAINING_LISTING_LOAD_FAILED, errorMessage, originalError);

export const trainingDeleteRequest = (
  trainingId: number,
): TrainingDeleteRequestAction => ({
  type: TRAINING_DELETE_REQUEST,
  trainingId,
});

export const trainingDeleteSuccess = (
  trainingId: number,
): TrainingDeleteSuccessAction => ({
  type: TRAINING_DELETE_SUCCESS,
  trainingId,
});

export const trainingDeleteError = (
  errorMessage: string,
  originalError?: Error,
): TrainingDeleteErrorAction =>
  showErrorMessage(TRAINING_DELETE_FAILED, errorMessage, originalError);

export const trainingActivateRequest = (
  trainingId: number,
): TrainingActivateRequestAction => ({
  type: TRAINING_ACTIVATE_REQUEST,
  trainingId,
});

export const trainingActivateSuccess = (
  trainingId: number,
): TrainingActivateSuccessAction => ({
  type: TRAINING_ACTIVATE_SUCCESS,
  trainingId,
});

export const trainingActivateError = (
  errorMessage: string,
  originalError?: Error,
): TrainingActivateErrorAction =>
  showErrorMessage(TRAINING_ACTIVATE_FAILED, errorMessage, originalError);

export const trainingArchiveRequest = (
  trainingId: number,
): TrainingArchiveRequestAction => ({
  type: TRAINING_ARCHIVE_REQUEST,
  trainingId,
});

export const trainingArchiveSuccess = (
  trainingId: number,
): TrainingArchiveSuccessAction => ({
  type: TRAINING_ARCHIVE_SUCCESS,
  trainingId,
});

export const trainingArchiveError = (
  errorMessage: string,
  originalError?: Error,
): TrainingArchiveErrorAction =>
  showErrorMessage(TRAINING_ARCHIVE_FAILED, errorMessage, originalError);

export const userTrainingListingLoadRequest =
  (): UserTrainingListingLoadRequestAction => ({
    type: USER_TRAINING_LISTING_LOAD_REQUEST,
  });

export const userTrainingListingLoadSuccess = (
  trainingListing: TrainingListingResponse,
): UserTrainingListingLoadSuccessAction => ({
  type: USER_TRAINING_LISTING_LOAD_SUCCESS,
  trainingListing,
});

export const userTrainingListingLoadError = (
  errorMessage: string,
  originalError?: Error,
): UserTrainingListingLoadErrorAction =>
  showErrorMessage(
    USER_TRAINING_LISTING_LOAD_FAILED,
    errorMessage,
    originalError,
  );
