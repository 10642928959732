import { ShowErrorAction } from 'src/store/types';

export const DOWNLOAD_TRAINING_CERTIFICATE_REQUEST =
  'DOWNLOAD_TRAINING_CERTIFICATE_REQUEST';
export const DOWNLOAD_TRAINING_CERTIFICATE_SUCCESS =
  'DOWNLOAD_TRAINING_CERTIFICATE_SUCCESS';
export const DOWNLOAD_TRAINING_CERTIFICATE_ERROR =
  'DOWNLOAD_TRAINING_CERTIFICATE_ERROR';

export interface DownloadTrainingCertificateRequestAction {
  type: typeof DOWNLOAD_TRAINING_CERTIFICATE_REQUEST;
  examSubmissionId: number;
  userName: string;
  trainingTypeName: string;
}

export interface DownloadTrainingCertificateSuccessAction {
  type: typeof DOWNLOAD_TRAINING_CERTIFICATE_SUCCESS;
}

export type DownloadTrainingCertificateErrorAction = ShowErrorAction<
  typeof DOWNLOAD_TRAINING_CERTIFICATE_ERROR
>;

export type CertificateActionTypes =
  | DownloadTrainingCertificateRequestAction
  | DownloadTrainingCertificateSuccessAction
  | DownloadTrainingCertificateErrorAction;
