import * as assessmentActions from './assessment';
import * as assessmentTypesActions from './assessmentTypes';
import * as commentActions from './comment';
import * as companyActions from './company';
import * as companyListingActions from './companyListing';
import * as documentActions from './document';
import * as documentListingActions from './documentListing';
import * as formActions from './form';
import * as formListingActions from './formListing';
import * as interviewActions from './interview';
import * as notificationActions from './notification';
import * as questionListingActions from './questionListing';
import * as questionTypesActions from './questionType';
import * as templateActions from './template';
import * as templateListingActions from './templateListing';
import * as threadActions from './thread';
import * as threadListingActions from './threadListing';
import * as userActions from './user';
import * as variableActions from './variable';
import * as documentDraftActions from './documentDraft';
import * as userListingActions from './userListing';
import * as errorActions from './error';
import * as documentDraftListingActions from './documentDraftListing';
import * as constantsListingActions from './constantListing';
import * as categoryActions from './category';
import * as featureFlagActions from './featureFlag';
import * as trainingActions from './training';
import * as trainingListingActions from './trainingListing';
import * as examActions from './exam';
import * as examListingActions from './examListing';
import * as trainingPeriodActions from './trainingPeriod';
import * as trainingPeriodListingActions from './trainingPeriodListing';
import * as certificateActions from './certificate';
import * as certificateListingActions from './certificateListing';
import * as testTypesActions from './testTypes';
import * as trainingTypesActions from './trainingTypes';
import * as companyUserListingActions from './userSummaryListing';

export default {
  ...assessmentActions,
  ...assessmentTypesActions,
  ...commentActions,
  ...companyActions,
  ...companyListingActions,
  ...documentActions,
  ...documentListingActions,
  ...formListingActions,
  ...formActions,
  ...interviewActions,
  ...notificationActions,
  ...questionListingActions,
  ...questionTypesActions,
  ...templateActions,
  ...templateListingActions,
  ...threadActions,
  ...threadListingActions,
  ...userActions,
  ...variableActions,
  ...documentDraftActions,
  ...userListingActions,
  ...errorActions,
  ...documentDraftListingActions,
  ...constantsListingActions,
  ...categoryActions,
  ...featureFlagActions,
  ...trainingActions,
  ...trainingListingActions,
  ...examActions,
  ...examListingActions,
  ...trainingPeriodActions,
  ...trainingPeriodListingActions,
  ...certificateActions,
  ...certificateListingActions,
  ...testTypesActions,
  ...trainingTypesActions,
  ...companyUserListingActions,
};
