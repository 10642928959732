import React, { FC } from 'react';
import { Popconfirm, Spin } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Column from 'antd/lib/table/Column';
import {
  RootState,
  FormState,
  FormListingByCategory,
  InterviewState,
  InterviewListingIndexedItem,
  UserListingIndexedItem,
  User,
  CompanyState,
  AssessmentState,
  CategoryState,
  AssessmentRiskAnalysisState,
  Loader,
} from 'src/store/types';
import {
  getUser,
  getCompanyFromState,
  getSortedCategories,
  getCompanyLoaderFromState,
} from 'src/store/selectors';
import { interviewSaveRequest } from 'src/store/actions/interview';
import {
  THIconCompletedRisk,
  THIconPendingRisk,
  THIconUserAssignee,
} from 'src/theme/media';
import { Progress } from 'src/components/Progress';
import { Button } from 'src/components/Button';
import { RisksCollapsiblePanel } from '../RisksCollapsiblePanel';
import { CompliantStatus } from '../CompliantStatus';
import * as S from './Styles';

interface InterviewListingProps {
  forms: FormState[];
  interviews: InterviewListingIndexedItem;
  users: UserListingIndexedItem;
  assessment: AssessmentState;
  assessmentRiskAnalysis: AssessmentRiskAnalysisState;
  isMitigationMode: boolean;
  canSubmitRiskAssessment: boolean;
  completeAssessmentLoading: boolean;
  onSubmitRiskAssessment: () => void;
  onCardClick: () => void;
}

interface InterviewType {
  key: React.Key;
  risk: string;
  began: string;
  formId: number;
  assignee?: string;
  completed?: number;
  interview?: InterviewState;
  risks?: number;
}

export const InterviewListing: FC<InterviewListingProps> = ({
  forms,
  interviews,
  users,
  assessment,
  assessmentRiskAnalysis,
  isMitigationMode,
  canSubmitRiskAssessment,
  completeAssessmentLoading,
  onSubmitRiskAssessment,
  onCardClick,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: RootState): User => getUser(state));
  const company = useSelector(
    (state: RootState): CompanyState => getCompanyFromState(state),
  );
  const categories = useSelector((state: RootState): CategoryState[] =>
    getSortedCategories(state),
  );
  const companyLoader = useSelector(
    (state: RootState): Loader => getCompanyLoaderFromState(state),
  );

  const formsByCategory = forms.reduce(
    (formMap: FormListingByCategory, form: FormState) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!form.categoryFolder) {
        return {
          ...formMap,
        };
      }

      const categoryList = formMap[form.categoryFolder.id]
        ? [...formMap[form.categoryFolder.id], form]
        : [form];

      return {
        ...formMap,
        [form.categoryFolder.id.toString()]: categoryList,
      };
    },
    {},
  );

  const handleCardOnClick = (
    interview: InterviewState | undefined,
    formId: number,
  ) => {
    onCardClick();

    if (!interview) {
      dispatch(
        interviewSaveRequest(formId, user.id, company.id, assessment.id),
      );
    }

    history.push(`assessments/${assessment.id}/forms/${formId}/interview`);
  };

  const interviewsCompleted = forms.filter(
    (f) => interviews[f.id]?.completedAt,
  );
  const interviewsPercentage = Math.floor(
    (100 * interviewsCompleted.length) / forms.length,
  );

  return (
    <>
      <S.RiskProgressContainer>
        {companyLoader.completed && (
          <>
            <S.RiskProgressTitle>Risk Assessment progress</S.RiskProgressTitle>
            <Progress size="default" percent={interviewsPercentage} />
          </>
        )}
      </S.RiskProgressContainer>
      {canSubmitRiskAssessment && companyLoader.completed && (
        <S.RiskCompletionContainer>
          <h2>You've completed the Risk Assessment!</h2>
          <Popconfirm
            title={
              "After completing the assessment, you'll need to contact TH administrators if further updates should be made."
            }
            onConfirm={onSubmitRiskAssessment}
            okText="Yes"
            cancelText="No"
            key={0}
          >
            <Button
              title="Submit Risk Assessment"
              loading={completeAssessmentLoading}
            />
          </Popconfirm>
        </S.RiskCompletionContainer>
      )}
      {companyLoader.completed && (
        <div>
          {categories.map((category) => {
            if (
              Object.keys(formsByCategory).length === 0 ||
              (category && !formsByCategory[category.id.toString()])
            ) {
              return;
            }

            let sectionRisks = 0;
            let completedForms = 0;

            const risks = formsByCategory[category.id.toString()].map(
              (form): InterviewType => {
                sectionRisks += assessmentRiskAnalysis.forms[form.id] | 0;
                completedForms += interviews[form.id.toString()]?.completedAt
                  ? 1
                  : 0;

                return {
                  key: form.id,
                  risk: form.label,
                  began: new Date().toDateString(),
                  assignee: '',
                  completed: 0,
                  interview: interviews[form.id.toString()],
                  formId: form.id,
                  risks: assessmentRiskAnalysis.forms[form.id] || 0,
                };
              },
            );

            return (
              <RisksCollapsiblePanel
                key={category.id}
                assessment={assessment}
                title={category.name}
                completedForms={completedForms}
                sections={risks.length}
                risks={sectionRisks}
              >
                <S.InterviewsTable dataSource={risks} pagination={false}>
                  <Column<InterviewType>
                    title="Risk"
                    dataIndex="risk"
                    key="risk"
                    render={(_, record: InterviewType) => {
                      return (
                        <S.RiskLink
                          onClick={() =>
                            handleCardOnClick(record.interview, record.formId)
                          }
                        >
                          {record.risk}
                        </S.RiskLink>
                      );
                    }}
                  />
                  <Column<InterviewType>
                    title="Began"
                    dataIndex="began"
                    key="began"
                    render={(_, record: InterviewType) => {
                      const interview: InterviewState | undefined =
                        interviews[record.key.toString()];

                      if (!interview) {
                        return (
                          <S.SectionColumnWrapper>{'-'}</S.SectionColumnWrapper>
                        );
                      }

                      return (
                        <S.SectionColumnWrapper>
                          {moment(interview.createdAt).format('MM/DD/YY')}
                        </S.SectionColumnWrapper>
                      );
                    }}
                  />
                  <Column<InterviewType>
                    title="Assignee"
                    dataIndex="assignee"
                    key="assignee"
                    render={(_, record: InterviewType) => {
                      const interview: InterviewState | undefined =
                        interviews[record.key.toString()];

                      if (interview && interview.ownerId) {
                        return (
                          <S.SectionColumnWrapper>
                            <img src={THIconUserAssignee} />
                            {interview.ownerName}
                          </S.SectionColumnWrapper>
                        );
                      }

                      return (
                        <S.SectionColumnWrapper>{'-'}</S.SectionColumnWrapper>
                      );
                    }}
                  />
                  <Column<InterviewType>
                    title="Completed"
                    dataIndex="completed"
                    key="completed"
                    render={(_, record: InterviewType) => {
                      return record?.interview?.completedAt ? (
                        <S.SectionColumnWrapper>
                          <img src={THIconCompletedRisk} />
                          Completed
                        </S.SectionColumnWrapper>
                      ) : (
                        <S.SectionColumnWrapper>
                          <img src={THIconPendingRisk} />
                          Pending
                        </S.SectionColumnWrapper>
                      );
                    }}
                  />
                  <Column<InterviewType>
                    title="Risk Status"
                    dataIndex="riskStatus"
                    key="riskStatus"
                    render={(_, record: InterviewType) => {
                      return (
                        <S.SectionColumnWrapper>
                          {isMitigationMode ? (
                            <CompliantStatus risks={record.risks || 0} />
                          ) : (
                            '-'
                          )}
                        </S.SectionColumnWrapper>
                      );
                    }}
                  />
                </S.InterviewsTable>
              </RisksCollapsiblePanel>
            );
          })}
        </div>
      )}
    </>
  );
};
