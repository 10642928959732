import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getVariableById } from 'src/store/selectors';
import { VariableState, RootState } from 'src/store/types';
import { TokenPreview } from './TokenPreview';

interface VariableTokenPreviewProps {
  variableId: string | undefined;
}

export const VariableTokenPreview: FC<VariableTokenPreviewProps> = ({
  variableId,
}) => {
  const variable = useSelector<RootState, VariableState | undefined>(
    (state: RootState) => getVariableById(state, Number(variableId)),
  );
  let tooltip = `Variable "${variableId}" was not found on this template`;
  let text = `Variable #${variableId}`;
  if (variable) {
    tooltip = variable.content || '(the variable has no content)';

    text = `V: ${variable.description}`;
  }

  return <TokenPreview tooltip={tooltip} text={text} error={!variable} />;
};

export const VariableTokenPreviewMemoized = React.memo(VariableTokenPreview);
