import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { TopNavbar } from 'src/components/TopNavbar';
import { Button } from 'src/components/Button';
import { companyLoadRequest } from 'src/store/actions/company';
import { assessmentLoadRequest } from 'src/store/actions/assessment';
import { api } from 'src/api/services/ThApi';
import { AssessmentAuditingTab } from 'src/features/risk-assessment/components/AssessmentAuditingTab/AssessmentAuditingTab';
import { AssesmentListTab } from './AssesmentListTab';

interface RouteParams {
  id: string;
  assessmentId: string;
}

const { TabPane } = Tabs;

export const CompanyAssessmentPage: FC<unknown> = () => {
  const { id, assessmentId: rawAssessmentId } = useParams<RouteParams>();
  const companyId = +id;
  const assessmentId = +rawAssessmentId;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(companyLoadRequest(companyId));
    dispatch(assessmentLoadRequest(assessmentId, { withForms: true }));
  }, []);

  return (
    <>
      <TopNavbar
        title={'Assessment'}
        prevRoute={{ name: 'Companies', url: `/companies` }}
        extraOptions={[
          <Button
            disabled
            onClick={() =>
              history.push(`/ppDocuments?assessmentId=${assessmentId}`)
            }
            title="P&P Documents"
            key={0}
          />,
          <Button
            onClick={() =>
              history.push(
                `/companies/${companyId}/assessments/${assessmentId}/assessmentReports`,
              )
            }
            title="Review Reports"
            key={1}
          />,
          <Button
            onClick={async () => api.getAssessmentCsv(assessmentId)}
            title="Download CSV"
            key={2}
            variant="secondary"
          />,
        ]}
        withCompany
      />
      <Tabs type="line" size="large">
        <TabPane tab="Assessments" key={1}>
          <AssesmentListTab companyId={companyId} assessmentId={assessmentId} />
        </TabPane>
        <TabPane tab="Auditing" key={2}>
          <AssessmentAuditingTab />
        </TabPane>
      </Tabs>
    </>
  );
};
