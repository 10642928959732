import { Loader, ShowErrorAction, User } from 'src/store/types';
import { TrainingPeriodStatus } from 'src/store/types/training';
import { TrainingPeriodCreateSuccessAction } from '.';
import {
  TrainingPeriodCompleteSuccessAction,
  TrainingPeriodUpdateSuccessAction,
} from './trainingPeriod';

export const TRAINING_PERIOD_LISTING_LOAD_REQUEST =
  'TRAINING_PERIOD_LISTING_LOAD_REQUEST';
export const TRAINING_PERIOD_LISTING_LOAD_SUCCESS =
  'TRAINING_PERIOD_LISTING_LOAD_SUCCESS';
export const TRAINING_PERIOD_LISTING_LOAD_FAILED =
  'TRAINING_PERIOD_LISTING_LOAD_FAILED';

export type TrainingPeriodListingElement = {
  id: number;
  status: TrainingPeriodStatus;
  createdAt: Date;
  releaseDate: Date;
  dueDate: Date | null;
  expirationDate: Date;
  user?: User;
};

export type TrainingPeriodListingResponse = TrainingPeriodListingElement[];

export type TrainingPeriodListingState = {
  trainingPeriodListingResponse: TrainingPeriodListingResponse;
  loader: Loader;
};

export interface TrainingPeriodListingLoadRequestAction {
  type: typeof TRAINING_PERIOD_LISTING_LOAD_REQUEST;
  companyId: number;
}

export interface TrainingPeriodListingLoadSuccessAction {
  type: typeof TRAINING_PERIOD_LISTING_LOAD_SUCCESS;
  trainingPeriodListing: TrainingPeriodListingResponse;
}

export type TrainingPeriodListingLoadErrorAction = ShowErrorAction<
  typeof TRAINING_PERIOD_LISTING_LOAD_FAILED
>;

export type TrainingPeriodListingActionTypes =
  | TrainingPeriodListingLoadRequestAction
  | TrainingPeriodListingLoadSuccessAction
  | TrainingPeriodListingLoadErrorAction
  | TrainingPeriodCreateSuccessAction
  | TrainingPeriodUpdateSuccessAction
  | TrainingPeriodCompleteSuccessAction;
