import React, { FC } from 'react';
import styled from 'styled-components';
import { Table as TableAntd } from 'antd';
import { CheckOutlined } from 'src/theme/icons';
import { ColumnsType } from 'antd/lib/table';
import { IconButton } from 'src/components/Button';

interface TemplateEditorTitleSelectorWindowProps {
  onSelection: (id: number) => void;
  onClose: () => void;
}

interface Title {
  description: string;
  depth: number;
}

const Container = styled.div`
  width: 100%;
`;

const Table = styled<any>(TableAntd)`
  tr {
    th {
      font-size: 14px;

      &:last-child {
        text-align: right;
      }
    }
    td {
      font-size: 13px;
      .actions {
        text-align: right;
        button {
          font-size: 13px;
        }
      }
    }
  }
`;

export const TemplateEditorTitleSelectorWindow: FC<
  TemplateEditorTitleSelectorWindowProps
> = ({ onSelection, onClose }) => {
  const titles: Title[] = [1, 2, 3, 4, 5, 6].map((depth) => ({
    description: `Header ${depth}`,
    depth,
  }));

  const handleSelection = (titleDepth: number) => {
    onSelection(titleDepth);
    onClose();
  };

  const columns: ColumnsType<Title> = [
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text: string) => {
        return <p>{text}</p>;
      },
    },
    {
      title: 'Actions',
      render: (title: Title) => {
        return (
          <div className="actions">
            <IconButton
              onClick={() => handleSelection(title.depth)}
              icon={<CheckOutlined />}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      <Table columns={columns} dataSource={titles} size="small" />
    </Container>
  );
};
