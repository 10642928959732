import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { message, Divider, Popover } from 'antd';
import ReactDragListView from 'react-drag-listview';
import { Choice } from 'src/store/types';
import { Button, IconButton } from 'src/components/Button';
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  EditOutlined,
} from 'src/theme/icons';
import { generateId } from 'src/shared/utils';
import { LinkButton } from './LinkButton';
import { TextInput } from '../../../../components/TextInput';
import { RiskAssessmentFields } from './RiskAssessmentFields';

export interface ChoicesSettingsProps {
  value?: Choice[] | undefined;
  onChange?: (value: Choice[]) => void;
}

const Wrapper = styled.div`
  width: 100%;

  .newChoiceContainer {
    > div,
    input {
      flex-grow: 1;
      width: 100%;
    }

    .linkButton {
      margin: 10px 0;
    }

    .actions {
      display: flex;
      justify-content: space-between;

      button {
        font-size: 14px;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  cursor: -webkit-grab;
  cursor: grab;
`;

const RiskAssessmentInfoSummaryContainer = styled.p`
  font-size: 12px;
  max-width: 400px;
`;

const NewChoiceInput = styled(TextInput)`
  padding-left: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grayscale.middleGray};
`;

const ChoiceLabel = styled.span`
  display: block;
  width: 100%;
  word-wrap: break-word;
`;

const ChoiceContainer = styled.div`
  width: 80%;
`;

export const ChoicesSettings: FC<ChoicesSettingsProps> = ({
  value,
  onChange,
}) => {
  const [newChoice, setNewChoice] = useState<string>('');
  const [newChoiceFormVisible, setNewChoiceFormVisible] =
    useState<boolean>(false);
  const [newChoiceRiskForm, setNewChoiceRiskForm] = useState<boolean>(false);
  const [newChoiceRiskFormInfo, setNewChoiceRiskFormInfo] = useState<
    Partial<Choice>
  >({});
  const [currentChoices, setCurrentChoices] = useState<Choice[]>(
    value ? value : [],
  );
  const [editingChoice, setEditingChoice] = useState<boolean>(false);
  const [selectedChoice, setSelectedChoice] = useState<Choice | null>(null);

  const cleanForm = () => {
    setNewChoice('');
    setNewChoiceRiskFormInfo({});
    setNewChoiceRiskForm(false);
  };

  const persistChoicesData = (choices: Choice[]) => {
    setCurrentChoices(choices);
    triggerChange(choices);
    setNewChoiceFormVisible(false);
    cleanForm();
  };

  const handleSaveChoice = () => {
    if (!newChoice || newChoice.length === 0) {
      void message.error('Invalid choice name');
      return;
    }
    let newChoices: Choice[];
    if (editingChoice) {
      const editedChoiceObj: Choice = {
        ...selectedChoice,
        label: newChoice.trim(),
        value: newChoice,
        delegate: newChoiceRiskFormInfo
          ? newChoiceRiskFormInfo.delegate
          : undefined,
        risk: newChoiceRiskFormInfo ? newChoiceRiskFormInfo.risk : undefined,
        actionItem: newChoiceRiskFormInfo
          ? newChoiceRiskFormInfo.actionItem
          : undefined,
        analysis: newChoiceRiskFormInfo
          ? newChoiceRiskFormInfo.analysis
          : undefined,
      } as Choice;
      newChoices = [...currentChoices];
      const selectedChoiceIndex = currentChoices?.indexOf(
        selectedChoice as Choice,
      );
      newChoices.splice(selectedChoiceIndex, 1, editedChoiceObj);
    } else {
      const newChoiceObj: Choice = {
        internalId: generateId(),
        label: newChoice.trim(),
        isDefault: false,
        value: newChoice,
        delegate: newChoiceRiskFormInfo
          ? newChoiceRiskFormInfo.delegate
          : undefined,
        risk: newChoiceRiskFormInfo ? newChoiceRiskFormInfo.risk : undefined,
        actionItem: newChoiceRiskFormInfo
          ? newChoiceRiskFormInfo.actionItem
          : undefined,
        analysis: newChoiceRiskFormInfo
          ? newChoiceRiskFormInfo.analysis
          : undefined,
        weight: currentChoices.length + 1,
      };
      newChoices = [...currentChoices, newChoiceObj];
    }
    persistChoicesData(newChoices);
  };

  const handleRiskInfoChange = (riskInfo: any) => {
    setNewChoiceRiskFormInfo(riskInfo);
  };

  const rearrangeChoices = (choices: Choice[]) =>
    choices
      .map((c: Choice, index: number) => ({ ...c, weight: index + 1 }))
      .sort((a: Choice, b: Choice) => a.weight - b.weight);

  const deleteChoice = (id: number) => {
    const newValues = rearrangeChoices(
      currentChoices.filter((c: Choice) => id !== (c.id ?? c.internalId)),
    );
    triggerChange(newValues);
    cleanForm();
  };

  const editChoice = (id: number) => {
    const choice = currentChoices.find(
      (c: Choice) => id === (c.id ?? c.internalId),
    ) as Choice;
    setSelectedChoice(choice);
    const { analysis, actionItem, risk, delegate, label } = choice;
    setNewChoice(label);
    setNewChoiceRiskFormInfo({ analysis, actionItem, risk, delegate });
    setNewChoiceRiskForm(!!analysis || !!actionItem || !!risk || !!delegate);
    setEditingChoice(true);
    setNewChoiceFormVisible(true);
  };

  useEffect(() => {
    setCurrentChoices(value ? value : []);
  }, [value]);

  const triggerChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  const riskAssessmentInfoSummary = (choice: Choice) => {
    return (
      <RiskAssessmentInfoSummaryContainer>
        <p>{choice.actionItem}</p>
        {choice.risk && (
          <p>
            <span>Level:</span> {choice.risk.toLowerCase()}
          </p>
        )}
        {choice.delegate && (
          <p>
            <span>Delegate:</span> {choice.delegate.toLowerCase()}
          </p>
        )}
      </RiskAssessmentInfoSummaryContainer>
    );
  };

  const onDragEnd = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0) {
      return;
    }
    const newCurrentChoices = [...currentChoices];
    const choice = newCurrentChoices.splice(fromIndex, 1)[0];
    newCurrentChoices.splice(toIndex, 0, choice);
    const newRearrangedQuestions = rearrangeChoices(newCurrentChoices);
    setCurrentChoices(newRearrangedQuestions);
    triggerChange(newRearrangedQuestions);
  };

  return (
    <Wrapper>
      <ReactDragListView nodeSelector=".draggable" onDragEnd={onDragEnd}>
        {currentChoices.map((c: Choice) => {
          const id = c.id ? c.id : c.internalId;
          return (
            <Row className={'draggable'} key={id}>
              <ChoiceContainer>
                <ChoiceLabel>
                  {c.actionItem && (
                    <Popover
                      content={riskAssessmentInfoSummary(c)}
                      title="Risk assessment info"
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  )}{' '}
                  {c.label}
                </ChoiceLabel>
              </ChoiceContainer>
              <div>
                <IconButton
                  icon={<EditOutlined />}
                  onClick={() => {
                    editChoice(id as number);
                  }}
                />
                <IconButton
                  icon={<CloseCircleOutlined />}
                  onClick={() => {
                    deleteChoice(id as number);
                  }}
                />
              </div>
            </Row>
          );
        })}
      </ReactDragListView>
      {!newChoiceFormVisible && (
        <LinkButton
          icon={<PlusOutlined />}
          text="Add Choice"
          onClick={() => {
            setEditingChoice(false);
            setNewChoiceFormVisible(true);
          }}
        />
      )}
      {newChoiceFormVisible && (
        <>
          <Divider />
          <div className="newChoiceContainer">
            <NewChoiceInput
              placeholder="New choice label"
              autoFocus
              onChange={(e) => setNewChoice(e.target.value)}
              value={newChoice}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSaveChoice();
                }
              }}
            />
            {newChoiceRiskForm && (
              <RiskAssessmentFields
                value={newChoiceRiskFormInfo}
                onChange={handleRiskInfoChange}
              />
            )}
            {!newChoiceRiskForm && (
              <LinkButton
                onClick={() => {
                  setNewChoiceRiskForm(true);
                }}
                icon={<PlusCircleOutlined />}
                text="Add associated risk info"
              />
            )}
            <div className="actions">
              <Button
                variant="secondary"
                title="Cancel"
                onClick={() => {
                  setNewChoiceFormVisible(false);
                  cleanForm();
                }}
              />
              <Button
                variant="secondary"
                title={editingChoice ? 'Save' : 'Add'}
                onClick={handleSaveChoice}
              />
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};
