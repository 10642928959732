/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import styled from 'styled-components';
import { Select as SelectAntD } from 'antd';
import { Choice, Question, AnswerData, Answer } from 'src/store/types';

interface DropdownFieldProps {
  onChange: (value: string) => void;
  value: string;
  question: Question;
  updateAnswer: (answerData: AnswerData) => void;
  answer: Answer;
}

interface CheckboxOption {
  label: string;
  value: string;
}

const Select = styled(SelectAntD)`
  &&&&&& {
    width: 312px;
  }
`;

export const DropdownField: FC<DropdownFieldProps> = ({
  onChange,
  value,
  question,
  updateAnswer,
  answer,
}) => {
  const optionToChoiceId: { [k: string]: number } = {};
  let options: CheckboxOption[] = [];
  if (question.choices) {
    options = question.choices
      .map((c: Choice) => {
        const id = (c.id ? c.id : c.internalId) as number;
        optionToChoiceId[c.label] = id;
        return {
          key: id,
          label: c.label,
          value: c.label,
          weight: c.weight,
        };
      })
      .sort((a, b) => a.weight - b.weight);
  }

  const triggerChange = (value: string | Array<string>) => {
    switch (typeof value) {
      case 'string':
        onChange(value);

        updateAnswer({
          value,
          choiceIds: [optionToChoiceId[value]],
          id: answer?.id,
          internalId: answer?.internalId,
        });

        break;
      case 'object':
        const choiceIds: Array<number> = value.reduce((choices, choice) => {
          return [...choices, optionToChoiceId[choice]];
        }, new Array<number>());

        onChange(
          JSON.stringify({
            values: value as any,
          } as any),
        );

        updateAnswer({
          value: JSON.stringify({
            values: value as any,
          } as any),
          choiceIds,
          id: answer?.id,
          internalId: answer?.internalId,
        });

        break;
    }
  };

  if (value && typeof value === 'string' && question.multiple) {
    value = JSON.parse(value);
  }

  return (
    <Select
      options={options}
      mode={question.multiple ? 'multiple' : undefined}
      onChange={triggerChange as any}
      value={question.multiple ? (value ? (value as any)?.values : []) : value}
    />
  );
};
