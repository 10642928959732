import {
  FORM_DELETE_SUCCESS,
  Loader,
  QuestionListingActionTypes,
  QuestionListingState,
  QUESTIONS_LOAD_ERROR,
  QUESTIONS_LOAD_REQUEST,
  QUESTIONS_LOAD_SUCCESS,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const questionListingInitialState: QuestionListingState = {
  forms: [],
  loader: loaderInitialState,
};

export const questionListingReducer = (
  state: QuestionListingState = questionListingInitialState,
  action: QuestionListingActionTypes,
): QuestionListingState => {
  switch (action.type) {
    case QUESTIONS_LOAD_REQUEST: {
      return { ...state, loader: { ...loaderInitialState, loading: true } };
    }
    case QUESTIONS_LOAD_SUCCESS: {
      return {
        forms: action.forms,
        loader: { ...loaderInitialState, success: true, completed: true },
      };
    }
    case QUESTIONS_LOAD_ERROR: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: false,
          completed: true,
          error: action.message.toString(),
        },
      };
    }
    case FORM_DELETE_SUCCESS: {
      const updatedFormQuestions = [...state.forms].filter(
        (formQuestionsData) => formQuestionsData.id !== action.formId,
      );
      return {
        forms: updatedFormQuestions,
        loader: { ...loaderInitialState },
      };
    }
    default:
      return state;
  }
};
