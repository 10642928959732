import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { TopNavbar } from 'src/components/TopNavbar';
import { NotificationListingTable } from 'src/features/risk-assessment/components/NotificationListingTable';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import actions from 'src/store/actions';
import { getNotifications } from 'src/store/selectors';
import { NotificationFromResponse } from 'src/store/types';
import { Tabs as TabsAntD } from 'antd';

const { TabPane } = TabsAntD;

enum Tab {
  INBOX = '1',
  ARCHIVE = '2',
}

const Tabs = styled(TabsAntD)`
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      background-color: transparent;
      border: none;
      font-size: 18px;
    }

    .ant-tabs-tab-active {
      color: ${({ theme }) => theme.colors.darkBlue.main};
      text-decoration: underline;
    }
  }
`;

const CompanyListContainer = styled.div`
  height: 65%;
  overflow: auto;
  margin-bottom: 10px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const NotificationListingPage: FC<unknown> = () => {
  const { user } = useAuth();
  const notifications = useSelector(getNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user.id) {
      dispatch(actions.notificationListingLoadRequest(user.id));
      dispatch(actions.assessmentTypesLoadAllRequest());
    }
  }, []);

  useEffect(() => {
    if (notifications) {
      let ids: (number | null)[] = notifications.map(
        (n: NotificationFromResponse) => {
          if (n.pending) {
            return n.id;
          }
          return null;
        },
      );

      ids = ids.filter((n) => !!n);

      if (ids.length) {
        dispatch(actions.notificationsMarkAsReadRequest(ids as number[]));
      }
    }
  }, [notifications]);

  const activeNotifications = useMemo(
    () => notifications.filter((n) => !n.archived),
    [notifications],
  );
  const archivedNotifications = useMemo(
    () => notifications.filter((n) => n.archived),
    [notifications],
  );

  return (
    <>
      <TopNavbar title={'Notifications'} prevRoute={null} />
      <PageContentContainer>
        <CompanyListContainer>
          <Tabs type="card" defaultActiveKey={Tab.INBOX}>
            <TabPane tab="Inbox" key={Tab.INBOX}>
              <NotificationListingTable
                notifications={activeNotifications}
                currentTab={Tab.INBOX}
              />
            </TabPane>
            <TabPane tab="Archive" key={Tab.ARCHIVE}>
              <NotificationListingTable
                notifications={archivedNotifications}
                currentTab={Tab.ARCHIVE}
              />
            </TabPane>
          </Tabs>
        </CompanyListContainer>
      </PageContentContainer>
    </>
  );
};
