import React, { FC, useState } from 'react';
import { ToolbarItem } from './ToolbarItem';
import { TemplateEditorConditionalWindow } from './TemplateEditorSelectorWindows';

interface ConditionalToolbarItemProps {
  updateEditorContent: (val: string) => void;
}

export const ConditionalToolbarItem: FC<ConditionalToolbarItemProps> = ({
  updateEditorContent,
}) => {
  const [childrenVisible, setChildrenVisible] = useState<boolean>(false);

  const execute = (condition: string, body: string) => {
    updateEditorContent(`\n\n[if condition='${condition}']${body}[/if]`);
    setChildrenVisible(false);
  };

  return (
    <ToolbarItem
      icon={
        <svg
          width="12"
          height="12"
          viewBox="0 0 135.46666 135.46667"
          version="1.1"
          id="svg8"
        >
          <g>
            <g transform="matrix(1.8416256,0,0,3.6983265,-41.706689,-148.36772)">
              <g transform="scale(1.2384069,0.80748906)">
                <path d="m 41.590195,64.574962 -6.255057,27.017033 h -8.708963 l 6.255056,-27.017033 z m 2.64637,-10.417074 -1.515649,6.567809 h -9.190121 l 1.515648,-6.567809 z" />
                <path d="m 58.767542,64.574962 h 6.688099 l -1.299128,5.701725 h -6.399404 l -4.931871,21.315308 h -8.708964 l 4.931872,-21.315308 h -3.704918 l 1.299127,-5.701725 h 3.704918 l 0.168406,-0.697679 q 1.106663,-4.883756 4.041728,-7.409836 2.935065,-2.550139 8.684906,-2.550139 2.117096,0 3.68086,0.144348 1.563764,0.120289 2.694486,0.336811 l -1.419417,6.110709 h -0.649563 q -0.553332,-0.192464 -1.587822,-0.384927 -1.010433,-0.216521 -1.876517,-0.216521 -2.405791,0 -3.512455,0.938258 -1.082606,0.938259 -1.756228,3.536513 z" />
              </g>
            </g>
          </g>
        </svg>
      }
      childrenVisible={childrenVisible}
      setChildrenVisible={setChildrenVisible}
      tooltip="Conditional Tokens"
    >
      <TemplateEditorConditionalWindow
        onSelection={execute}
        onClose={() => setChildrenVisible(false)}
      />
    </ToolbarItem>
  );
};
