import { call, takeEvery, put } from 'redux-saga/effects';
import {
  FEATURE_FLAG_LOAD_REQUEST,
  FeatureFlagResponse,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadFeatureFlags = function* async(): Generator {
  try {
    const featureFlags = yield call(api.getAllFeatureFlags);
    localStorage.setItem('featureFlags', JSON.stringify(featureFlags));
    yield put(
      actions.featureFlagLoadSuccess(featureFlags as FeatureFlagResponse),
    );
  } catch (err) {
    const featureFlags = localStorage.getItem('featureFlags');
    if (featureFlags) {
      yield put(
        actions.featureFlagLoadSuccess(
          JSON.parse(featureFlags) as FeatureFlagResponse,
        ),
      );
    } else {
      yield put(
        actions.featureFlagLoadError(
          'Failed to load feature flags',
          err as Error,
        ),
      );
    }
  }
};

export default function* (): Generator {
  return [yield takeEvery(FEATURE_FLAG_LOAD_REQUEST, loadFeatureFlags)];
}
