import {
  THREAD_LIST_LOAD_REQUEST,
  ThreadsLoadRequestAction,
  THREAD_LIST_LOAD_SUCCESS,
  ThreadsLoadSuccessAction,
  THREAD_LIST_LOAD_ERROR,
  ThreadsLoadErrorAction,
  Thread,
} from '../types';
import { showErrorMessage } from './error';

export const threadsLoadRequest = ({
  assessmentId,
  documentDraftId,
}: {
  assessmentId?: number;
  documentDraftId?: number;
}): ThreadsLoadRequestAction => ({
  type: THREAD_LIST_LOAD_REQUEST,
  assessmentId,
  documentDraftId,
});

export const threadsLoadSuccess = (
  threads: Thread[],
): ThreadsLoadSuccessAction => ({
  type: THREAD_LIST_LOAD_SUCCESS,
  threads,
});

export const threadsLoadError = (
  errorMessage: string,
  originalError: Error,
): ThreadsLoadErrorAction =>
  showErrorMessage(THREAD_LIST_LOAD_ERROR, errorMessage, originalError);
