/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  AssessmentTypeListingIndexedItem,
  AssessmentTypeState,
  FormFilters,
  FormListingByCategory,
  FormListingIndexedItem,
  FormState,
  FormStatusFilter,
  Loader,
  RootState,
} from '../types';
import {
  getAssessmentTypes,
  getCompanyAssessmentTypes,
  getCompanyLastAssessmentType,
} from '../selectors';

const getIndexedFormsFromState = (state: RootState): FormListingIndexedItem => {
  return state.formListing.forms;
};

export const getFormsFromState = (state: RootState): FormState[] => {
  return Object.values(getIndexedFormsFromState(state));
};

export const getFormListingLoader = (state: RootState): Loader => {
  return state.formListing.loader;
};

export const formFilterByStatus: (
  filter: FormStatusFilter,
) => (form: FormState) => boolean = (filter: FormStatusFilter) => {
  if (filter === FormStatusFilter.active)
    return (form: FormState) => !!form.isActive;
  if (filter === FormStatusFilter.inactive)
    return (form: FormState) => !form.isActive;
  if (filter === FormStatusFilter.draft)
    return (form: FormState) => !!form.isDraft;
  return (_form: FormState) => true;
};

export const getForms = createSelector(
  (state: RootState, _filters: FormFilters, forms?: FormState[]) =>
    forms || getFormsFromState(state),
  (_: RootState, { offset }: FormFilters) => offset || 0,
  (_: RootState, { limit }: FormFilters) => limit,
  (_: RootState, { status }: FormFilters) => status || '',
  (_: RootState, { name }: FormFilters) => name || '',
  (_: RootState, { assessmentTypeLabel }: FormFilters) => assessmentTypeLabel,
  (
    forms: FormState[],
    offset: number,
    limit: number | undefined,
    status: string,
    name: string,
    assessmentTypeLabel: string | undefined,
  ) => {
    let filteredForms = [...forms];

    if (status) {
      filteredForms = forms.filter(
        formFilterByStatus(status as FormStatusFilter),
      );
    }

    if (name) {
      const capsName = name.toUpperCase();
      filteredForms = filteredForms.filter((f) =>
        f.label.toUpperCase().includes(capsName),
      );
    }

    if (assessmentTypeLabel) {
      filteredForms = filteredForms.filter(
        (f) => f.assessmentType?.label === assessmentTypeLabel,
      );
    }

    return {
      data: filteredForms.slice(offset, limit),
      totalItems: filteredForms.length,
    };
  },
);

export const getFormsByAssessmentTypeId = createSelector(
  (state: RootState) => getIndexedFormsFromState(state),
  (state: RootState) => getAssessmentTypes(state),
  (_state: RootState, assessmentTypeId: number) => assessmentTypeId,
  (
    forms: FormListingIndexedItem,
    types: AssessmentTypeListingIndexedItem,
    typeId: number,
  ) => {
    return types[typeId]?.formIds.map((formId) => forms[formId]) || [];
  },
);
createSelector(
  (state: RootState) => getIndexedFormsFromState(state),
  (state: RootState) => getCompanyAssessmentTypes(state),
  (forms: FormListingIndexedItem, types: AssessmentTypeState[]) => {
    return types.reduce(
      (filteredForms: FormState[], type: AssessmentTypeState) => {
        const assessmentTypeForms = type.formIds.map((formId) => forms[formId]);
        return [...filteredForms, ...assessmentTypeForms];
      },
      [],
    );
  },
);
export const getFormsByCompanyLastAssessment = createSelector(
  (state: RootState) => getIndexedFormsFromState(state),
  (state: RootState) => getCompanyLastAssessmentType(state),
  (_: RootState, { offset }: FormFilters) => offset || 0,
  (_: RootState, { limit }: FormFilters) => limit,
  (
    forms: FormListingIndexedItem,
    type: AssessmentTypeState | undefined,
    offset: number,
    limit: number | undefined,
  ) => {
    let filteredForms = type ? type.formIds.map((formId) => forms[formId]) : [];

    filteredForms = filteredForms.sort(
      (firstForm: FormState, secondForm: FormState) =>
        firstForm.weight > secondForm.weight ? 1 : -1,
    );

    return {
      data: filteredForms.slice(offset, limit),
      totalItems: filteredForms.length,
    };
  },
);

export const getFormsByCategoryId = createSelector(
  (state: RootState) => getFormsFromState(state),
  (forms: FormState[]) => {
    return forms.reduce((list: FormListingByCategory, form: FormState) => {
      if (form.categoryFolder) {
        if (!list[form.categoryFolder.id]) {
          list[form.categoryFolder.id.toString()] = [];
        }

        list[form.categoryFolder.id].push(form);
      }

      return list;
    }, {});
  },
);
