import React, { FC } from 'react';
import { Table, Empty, Tooltip } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Grid } from 'src/components/Grid';
import { DocumentDraftListingIndexedItem } from 'src/store/types';
import { FolderOutlined, EyeOutlined } from 'src/theme/icons';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { dataMapper } from './dataMapper';
import { DocumentDraftListingTableSettings } from './DocumentDraftListingTableSettings';

export interface DocumentDraftListingTableProps {
  documentDrafts: DocumentDraftListingIndexedItem;
  assessmentId: number;
  documentId: number;
  companyId: number;
}

export interface TableRowInterface {
  id: number;
  title: string;
  archived: boolean;
}

const DocumentDraftTitle = styled.span`
  margin-right: 10px;
`;

const TransparentGrid = styled(Grid)`
  .ant-table {
    background-color: transparent;
  }

  .ant-table table .ant-table-tbody tr.ant-table-row.archived td {
    opacity: 0.6;
  }
`;

const Options = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  .icon {
    font-size: 20px;
    padding-right: 10px;
    margin-right: 10px;
  }
`;

export const DocumentDraftListingTable: FC<DocumentDraftListingTableProps> = ({
  documentDrafts: rawDocumentsDrafts,
  assessmentId,
  documentId,
  companyId,
}) => {
  const { Column } = Table;

  const { isAdmin } = useAuth();

  const getUrl = (record: TableRowInterface) => {
    return `/companies/${companyId}/assessments/${assessmentId}/assessmentReports/${documentId}/documentDrafts/${record.id}`;
  };

  return (
    <TransparentGrid
      dataSource={dataMapper(rawDocumentsDrafts)}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
      rowClassName={(record: TableRowInterface) =>
        record.archived ? 'archived' : ''
      }
    >
      <Column<TableRowInterface> title="ID" dataIndex="id" key="id" />
      <Column<TableRowInterface>
        title="Title"
        dataIndex="title"
        key="title"
        render={(_, record: TableRowInterface) => (
          <>
            <DocumentDraftTitle>
              <Link
                to={`/companies/${companyId}/assessments/${assessmentId}/assessmentReports/${documentId}/documentDrafts/${record.id}`}
              >
                {record.title}
              </Link>
            </DocumentDraftTitle>
            {record.archived && (
              <Tooltip title="Archived">
                <FolderOutlined />
              </Tooltip>
            )}
          </>
        )}
      />
      <Column<TableRowInterface>
        title={false}
        dataIndex=""
        key="action"
        render={(_, record: TableRowInterface) => (
          <Options>
            <Link to={getUrl(record)} className="icon" title="Review">
              <EyeOutlined />
            </Link>
            {isAdmin && (
              <DocumentDraftListingTableSettings
                documentDraftId={record.id}
                archived={record.archived}
              />
            )}
          </Options>
        )}
      />
    </TransparentGrid>
  );
};
