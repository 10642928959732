import { UserRole } from 'src/store/types';
import { RoutesEnum } from '../enums';

export const REDIRECTION_EXCLUDED_PATHS: string[] = [
  RoutesEnum.PROFILE,
  RoutesEnum.DASHBOARD,
];

export const availableRoutesForTrainee = [
  RoutesEnum.DASHBOARD,
  RoutesEnum.MY_COMPANY,
  RoutesEnum.MY_TRAINING,
  RoutesEnum.NOTIFICATIONS,
];
export const availableRoutesForOfficer = [
  RoutesEnum.DASHBOARD,
  RoutesEnum.MY_COMPANY,
  RoutesEnum.NOTIFICATIONS,
];
export const availableRoutesForAuditor = [...availableRoutesForOfficer];
export const availableRoutesForCompanyModerator = [
  RoutesEnum.DASHBOARD,
  RoutesEnum.NOTIFICATIONS,
  RoutesEnum.COMPANIES,
];
export const availableRoutesForAdmin = [
  ...availableRoutesForCompanyModerator,
  RoutesEnum.TEMPLATES,
  RoutesEnum.FORMS,
  RoutesEnum.USER_MANAGEMENT,
];

export type AvailableRoutes = {
  [key in UserRole]?: string[];
};

export const availableRoutesForRoles: AvailableRoutes = {
  [UserRole.admin]: availableRoutesForAdmin,
  [UserRole.company_moderator]: availableRoutesForCompanyModerator,
  [UserRole.auditor]: availableRoutesForAuditor,
  [UserRole.officer]: availableRoutesForOfficer,
  [UserRole.trainee]: availableRoutesForTrainee,
};
