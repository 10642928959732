import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TopNavbar } from 'src/components/TopNavbar';
import { Pagination } from 'src/components/Pagination';
import { DocumentListingTable } from 'src/features/risk-assessment/components/DocumentListingTable';
import { documentsByAssessmentLoadRequest } from 'src/store/actions/documentListing';
import { templatesLoadRequest } from 'src/store/actions/templateListing';
import { assessmentLoadRequest } from 'src/store/actions/assessment';
import { RootState, DocumentType } from 'src/store/types';
import { getDocuments, getAssessmentById } from 'src/store/selectors';
import { useAuth } from 'src/hooks/useAuth/useAuth';

interface RouteParams {
  id: string;
  assessmentId: string;
}

const ListingTableContainer = styled.div`
  height: 65%;
  overflow: auto;
  margin-bottom: 10px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const AssessmentReportsListingPage: FC<unknown> = () => {
  const { isAdmin } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { id: rawCompanyId, assessmentId: rawAssessmentId } =
    useParams<RouteParams>();
  const companyId = +rawCompanyId;
  const assessmentId = +rawAssessmentId;

  const assessment = useSelector((state: RootState) =>
    getAssessmentById(state, assessmentId),
  );

  const documents = useSelector((state: RootState) =>
    getDocuments(state, {
      offset: (currentPage - 1) * pageSize,
      limit: currentPage * pageSize,
    }),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(assessmentLoadRequest(assessmentId));
    dispatch(
      documentsByAssessmentLoadRequest(assessmentId, DocumentType.REPORT),
    );
  }, []);

  useEffect(() => {
    assessment && isAdmin && dispatch(templatesLoadRequest(assessment.typeId));
  }, [assessment]);

  return (
    <>
      <TopNavbar
        title={'Assessment Reports'}
        subtitle={`(${documents.totalItems})`}
        prevRoute={{
          name: 'Assessment',
          url: `/companies/${companyId}/assessments/${assessmentId}`,
        }}
        withCompany
      />
      <PageContentContainer>
        <ListingTableContainer>
          <DocumentListingTable
            documents={documents.data}
            assessmentId={assessmentId}
            companyId={companyId}
          />
        </ListingTableContainer>
        {documents.totalItems > 10 && (
          <Pagination
            current={currentPage}
            items={documents.totalItems}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={(size) => {
              setPageSize(size);
              setCurrentPage(1);
            }}
            sizes={[10, 25, 50]}
          />
        )}
      </PageContentContainer>
    </>
  );
};
