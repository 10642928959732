import { Col, Form, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Papa from 'papaparse';
import { Button } from 'src/components/Button';
import { ProgressMeter } from 'src/components/ProgressMeter';
import { CompanyUserRole, UserInput } from 'src/store/types';
import { useAuth } from 'src/hooks/useAuth';
import actions from 'src/store/actions';
import { getCompanyFromState, getUserLoader } from 'src/store/selectors';
import {
  AddCompanyUserListingTable,
  FormAddCompanyUserForm,
} from '../../components';
import * as S from './Styles';

interface NewUserValues {
  name: string;
  email: string;
  officer: boolean;
}

interface FileValues {
  name: string;
  email: string;
  role: string;
}

export const CompanyUsersPage: FC<unknown> = () => {
  const [newCompanyUsers, setNewCompanyUsers] = useState<NewUserValues[]>([]);
  const [pushedUsers, setPushedUsers] = useState<boolean>(false);
  const [userForm] = Form.useForm();
  const { user } = useAuth();
  const companyId: number | undefined = user.company?.id ?? undefined;
  const history = useHistory();
  const dispatch = useDispatch();
  const userLoader = useSelector(getUserLoader);
  const company = useSelector(getCompanyFromState);

  useEffect(() => {
    if (userLoader.completed && userLoader.success) {
      if (pushedUsers) {
        setNewCompanyUsers([]);
        dispatch(actions.completeUserOnboardingRequest(user.id));
        history.push('/');
      }

      if (!user.isOnboardingRequired) {
        history.push('/');
      }
    }
  }, [userLoader]);

  useEffect(() => {
    if (companyId) {
      dispatch(actions.companyLoadRequest(companyId));
    }
  }, [companyId]);

  const onSubmitNewUser = (newUser: NewUserValues): void => {
    setNewCompanyUsers([...newCompanyUsers, newUser]);
    userForm.resetFields();
  };

  const removeNewUser = (userToRemove: NewUserValues): void => {
    const filteredUsers = newCompanyUsers.filter(
      (u) => u.email !== userToRemove.email,
    );
    setNewCompanyUsers([...filteredUsers]);
  };

  const handleDone = (): void => {
    const mappedUsersWithCompany: UserInput[] = newCompanyUsers.map(
      (userData: NewUserValues): UserInput => ({
        name: userData.name.trim(),
        email: userData.email.trim(),
        role: userData.officer
          ? CompanyUserRole.officer
          : CompanyUserRole.trainee,
        companyId,
        isActive: true,
      }),
    );

    dispatch(actions.multipleUsersSaveRequest(mappedUsersWithCompany));
    setPushedUsers(true);
  };

  const handleLateOnboarding = (): void => {
    dispatch(actions.completeUserOnboardingRequest(user.id));
  };

  const onFileChange = (info: any) => {
    const reader = new FileReader();

    reader.onload = ({ target }) => {
      const csv = Papa.parse(target?.result as string, {
        header: true,
        skipEmptyLines: true,
      });
      const parsedData = csv?.data as FileValues[];
      const formValues: NewUserValues[] = [];

      for (const parsedUser of parsedData) {
        formValues.push({
          ...parsedUser,
          officer:
            parsedUser.role.trim() === CompanyUserRole.officer ? true : false,
        });
      }

      setNewCompanyUsers([...newCompanyUsers, ...formValues]);
    };

    reader.readAsText(info);

    return false;
  };

  const companyLicenses = company.licenses ? company.licenses : 0;

  return (
    <S.PageContainer>
      <Row>
        <Col xs={24} lg={12}>
          <S.PageTitle>Add trainees & assign officers</S.PageTitle>
          <FormAddCompanyUserForm form={userForm} onSubmit={onSubmitNewUser} />
        </Col>
        <Col xs={24} lg={12}>
          <AddCompanyUserListingTable
            onFileChange={onFileChange}
            onRemove={removeNewUser}
            newCompanyUsers={newCompanyUsers}
            licensesLeft={companyLicenses}
          />
        </Col>
      </Row>
      <S.PageFooterContainer>
        <S.ProgressMeterWrapper>
          <ProgressMeter hasOnboarding step={6} />
        </S.ProgressMeterWrapper>
        <S.ButtonWrapper>
          <Button
            title={'Finish set-up'}
            onClick={handleDone}
            loading={userLoader.loading}
            disabled={newCompanyUsers.length === 0}
          />
        </S.ButtonWrapper>
        <S.LinkWrapper>
          <S.DoThisLaterLink href="#" onClick={handleLateOnboarding}>
            I'll do this later.
          </S.DoThisLaterLink>
        </S.LinkWrapper>
      </S.PageFooterContainer>
    </S.PageContainer>
  );
};
