import { call, takeEvery, put } from 'redux-saga/effects';
import {
  AssessmentTypeFromResponse,
  AssessmentTypesDelegateFromResponse,
  AssessmentTypesLabelFromResponse,
  AssessmentTypesLoadDelegateRequestAction,
  AssessmentTypesLoadLabelRequestAction,
  ASSESSMENT_TYPES_LOAD_ALL_REQUEST,
  ASSESSMENT_TYPES_LOAD_DELEGATE_REQUEST,
  ASSESSMENT_TYPES_LOAD_LABEL_REQUEST,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadAssessmentTypes = function* async(): Generator {
  try {
    const assessmentTypes = yield call(api.getAllAssessmentTypes);
    yield put(
      actions.assessmentTypesLoadAllSuccess(
        assessmentTypes as AssessmentTypeFromResponse[],
      ),
    );
  } catch (err) {
    yield put(
      actions.assessmentUpdateError(
        'Failed to load assessment types',
        err as Error,
      ),
    );
  }
};

export const assessmentTypesLoadLabels = function* async({
  typeId,
}: AssessmentTypesLoadLabelRequestAction): Generator {
  try {
    const labels = yield call(api.loadAssessmentTypeLabels, typeId);
    yield put(
      actions.assessmentTypesLoadLabelsSuccess(
        labels as AssessmentTypesLabelFromResponse[],
      ),
    );
  } catch (err) {
    yield put(
      actions.assessmentTypesLoadLabelsFailed(
        'Failed Loading Labels',
        err as Error,
      ),
    );
  }
};

export const assessmentTypesLoadDelegates = function* async({
  typeId,
}: AssessmentTypesLoadDelegateRequestAction): Generator {
  try {
    const delegates = yield call(api.loadAssessmentTypeDelegates, typeId);
    yield put(
      actions.assessmentTypesLoadDelegatesSuccess(
        delegates as AssessmentTypesDelegateFromResponse[],
      ),
    );
  } catch (err) {
    yield put(
      actions.assessmentTypesLoadDelegatesFailed(
        'Failed Loading Delegates',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(ASSESSMENT_TYPES_LOAD_ALL_REQUEST, loadAssessmentTypes),
    yield takeEvery(
      ASSESSMENT_TYPES_LOAD_LABEL_REQUEST,
      assessmentTypesLoadLabels,
    ),
    yield takeEvery(
      ASSESSMENT_TYPES_LOAD_DELEGATE_REQUEST,
      assessmentTypesLoadDelegates,
    ),
  ];
}
