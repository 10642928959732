import { ShowErrorAction } from './error';
import { Loader } from './loader';

export const USER_SUMMARY_LOAD_REQUEST = 'USER_SUMMARY_LOAD_REQUEST';
export const USER_SUMMARY_LOAD_SUCCESS = 'USER_SUMMARY_LOAD_SUCCESS';
export const USER_SUMMARY_LOAD_ERROR = 'USER_SUMMARY_LOAD_ERROR';

export interface UserSummaryState {
  id: number;
  name: string;
  email: string;
  trainingType: string;
  progress: number;
  score: number;
  lastLoginAt: Date;
  role: string;
  isTwoFactorAuthenticationEnabled: boolean;
}

export interface UserSummaryListingState {
  users: UserSummaryListingIndexedItem;
  loader: Loader;
}

export interface UserSummaryListingIndexedItem {
  [key: string]: UserSummaryState;
}

export interface UserSummaryFromResponse {
  id: number;
  name: string;
  email: string;
  progress: number;
  trainingType: string;
  score: number;
  role: string;
  lastLoginAt: Date;
}

export interface GetUserSummaryRequestAction {
  type: typeof USER_SUMMARY_LOAD_REQUEST;
  companyId: number;
}

export interface GetUserSummarySuccessAction {
  type: typeof USER_SUMMARY_LOAD_SUCCESS;
  users: UserSummaryFromResponse[];
}

export type GetUserSummaryErrorAction = ShowErrorAction<
  typeof USER_SUMMARY_LOAD_ERROR
>;

export type CompanyUsersActionTypes =
  | GetUserSummaryRequestAction
  | GetUserSummarySuccessAction
  | GetUserSummaryErrorAction;
