import { all, fork } from 'redux-saga/effects';
import assessmentSaga from './assessment';
import commentSaga from './comment';
import companySaga from './company';
import companyListingSaga from './companyListing';
import documentSaga from './document';
import documentListingSaga from './documentListing';
import formListingSaga from './formListing';
import formSaga from './form';
import userSaga from './user';
import questionTypeSaga from './questionType';
import questionSaga from './question';
import interviewSaga from './interview';
import assessmentTypes from './assessmentTypes';
import templateSaga from './template';
import templateListingSaga from './templateListing';
import threadSaga from './thread';
import threadListingSaga from './threadListing';
import notificationSaga from './notification';
import notificationPolling from './notificationPolling';
import questionListing from './questionListing';
import variablesSaga from './variable';
import documentDraftSaga from './documentDraft';
import documentDraftListingSaga from './documentDraftListing';
import constantListingSaga from './constantListing';
import categorySaga from './category';
import featureFlagSaga from './featureFlag';
import testTypesSaga from './testTypes';
import trainingTypesSaga from './trainingTypes';
import trainingSaga from './training';
import trainingListingSaga from './trainingListing';
import examSaga from './exam';
import examListingSaga from './examListing';
import trainingPeriodSaga from './trainingPeriod';
import trainingPeriodListingSaga from './trainingPeriodListing';
import certificateSaga from './certificate';
import certificateListingSaga from './certificateListing';
import loadCompanyUserListingSaga from './userSummaryListing';

export default function* root(): Generator {
  yield all([
    fork(assessmentSaga),
    fork(commentSaga),
    fork(companyListingSaga),
    fork(companySaga),
    fork(documentSaga),
    fork(documentListingSaga),
    fork(formListingSaga),
    fork(formSaga),
    fork(interviewSaga),
    fork(userSaga),
    fork(questionTypeSaga),
    fork(questionSaga),
    fork(assessmentTypes),
    fork(templateSaga),
    fork(templateListingSaga),
    fork(threadSaga),
    fork(threadListingSaga),
    fork(notificationSaga),
    fork(notificationPolling),
    fork(questionListing),
    fork(variablesSaga),
    fork(documentDraftSaga),
    fork(documentDraftListingSaga),
    fork(constantListingSaga),
    fork(categorySaga),
    fork(featureFlagSaga),
    fork(testTypesSaga),
    fork(trainingTypesSaga),
    fork(trainingSaga),
    fork(trainingListingSaga),
    fork(examSaga),
    fork(examListingSaga),
    fork(trainingPeriodSaga),
    fork(trainingPeriodListingSaga),
    fork(trainingPeriodListingSaga),
    fork(certificateSaga),
    fork(certificateListingSaga),
    fork(loadCompanyUserListingSaga),
  ]);
}
