import styled, { css } from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { centeredFlex, semiboldOpenSans } from 'src/theme';

const baseFonts = css`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
`;

const baseSemiboldFonts = css`
  ${baseFonts}
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const baseBoldFonts = css`
  ${baseFonts}
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Card = styled.div`
  border: ${remConvert(2)} solid rgba(0, 0, 0, 0.1);
  border-radius: ${remConvert(8)};
  width: ${remConvert(281)};
  padding: ${remConvert(16)};
  gap: ${remConvert(24)};
  background: ${({ theme }) => theme.colors.gallery.main};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: right;
`;

export const QuestionsCounter = styled.div`
  ${baseSemiboldFonts}
  line-height: ${remConvert(44)};
  color: ${({ theme }) => theme.colors.astral.main};
  font-size: 32px;
  text-align: center;
  width: 100%;
`;

export const QuestionsFormDescriptor = styled.p`
  ${baseSemiboldFonts}
  line-height: ${remConvert(28)};
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
`;

export const InterviewBeganDate = styled.div`
  ${baseSemiboldFonts};
  ${centeredFlex}
  line-height: ${remConvert(20)};
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
`;

export const InterviewRiskOwnerTitle = styled.div`
  ${baseSemiboldFonts}
  ${centeredFlex}
  line-height: ${remConvert(19)};
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

export const InterviewRiskOwner = styled.div`
  ${baseBoldFonts}
  line-height: ${remConvert(25)};
  margin-top: ${remConvert(8)};
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);

  img {
    margin-right: ${remConvert(10)};
  }
`;

export const InterviewRiskOwnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Separator = styled.hr`
  width: 100%;
`;

export const DeleteAssigneeButton = styled.a`
  color: gray;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-left: ${remConvert(10)};
`;

export const RiskPagerContainer = styled.div`
  ${semiboldOpenSans}
  line-height: ${remConvert(25)};
  color: ${({ theme }) => theme.colors.sunglo.main};
  font-size: 18px;
  width: 100%;

  > div {
    ${centeredFlex}
    justify-content: space-between;

    div {
      ${centeredFlex}
      padding-left: ${remConvert(15)};
      padding-right: ${remConvert(15)};
      margin-top: ${remConvert(20)};
      cursor: pointer;
      font-size: 14px;
      color: rgba(28, 36, 40, 0.85);
      flex-direction: column;
      border: 2px solid rgba(0, 0, 0, 0.1);
      width: 48%;
      border-radius: 8px;
    }
  }
`;
