import styled from 'styled-components';

export const GridActionIcon = styled.button`
  color: ${({ theme }) => theme.colors.lightBlue.main};
  font-size: 20px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
`;
