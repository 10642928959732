import { Form, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';

import { Button } from 'src/components/Button';

import { ExamConfig, Exam, TrainingTypesResponse } from 'src/store/types';
import { getExamLoader } from 'src/store/selectors';
import actions from 'src/store/actions';
import { SavingPill } from '../SavingPill';

interface Props {
  exam?: Exam;
  trainingTypes: TrainingTypesResponse;
}

const FormContainer = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 320px;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
    }
  }
`;

const ExamConfigForm = ({ exam, trainingTypes }: Props) => {
  const dispatch = useDispatch();
  const loader = useSelector(getExamLoader);
  const [form] = Form.useForm();

  useEffect(() => {
    if (exam) {
      const { trainingTypeId } = exam;
      form.setFieldsValue({ trainingTypeId });
    }
  }, [exam]);

  const handleSubmit = (values: ExamConfig) => {
    if (exam) {
      dispatch(actions.examUpdateRequest(exam.id, values));
    } else {
      dispatch(actions.examCreateRequest(values));
    }
  };

  const debounceSubmitForm = useMemo(
    () =>
      _.debounce(() => {
        form.submit();
      }, 500),
    [],
  );

  return (
    <div>
      <FormContainer
        form={form}
        onFinish={(values) => handleSubmit(values as ExamConfig)}
      >
        <Form.Item
          name="trainingTypeId"
          rules={[
            { required: true, message: 'Please select the training type' },
          ]}
        >
          <Select
            placeholder="Select the training type"
            onChange={exam && debounceSubmitForm}
            disabled={exam?.hasSubmissions}
          >
            {trainingTypes.map((trainingType) => (
              <Select.Option value={trainingType.id} key={trainingType.id}>
                {trainingType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </FormContainer>
      {!exam ? (
        <Button
          title="Save Exam"
          disabled={loader?.loading}
          onClick={() => form.submit()}
        />
      ) : (
        <SavingPill isLoading={loader?.loading} />
      )}
    </div>
  );
};

export default ExamConfigForm;
