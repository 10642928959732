import React, { FC } from 'react';
import styled from 'styled-components';
import { User } from 'src/store/types';
import { remConvert } from 'src/theme/utils';
import useTenantContext from 'src/shared/hooks/useTenant';

const WelcomeContainer = styled.div`
  height: calc(80vh - 72px);
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  font-size: 20px;
  justify-content: center;
`;

const WelcomeHeader = styled.h2`
  font-size: 35px;
`;

const WelcomeMainButton = styled.a`
  margin-top: ${remConvert(20)};
`;

interface WelcomeProps {
  user: User;
}

export const Welcome: FC<WelcomeProps> = ({ user }) => {
  const { brandName } = useTenantContext();

  return (
    <WelcomeContainer>
      <WelcomeHeader>
        👋 Welcome {user.name ? user.name : user.email}
      </WelcomeHeader>
      <div>{brandName} is the compliance app for business</div>
    </WelcomeContainer>
  );
};
