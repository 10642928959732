import React, { FC } from 'react';
import { THIconCompliantStatus } from 'src/theme/media';
import * as S from './Styles';

interface TrainingReleaseDateMessageProps {
  releaseDate: Date;
}

export const TrainingReleaseDateMessage: FC<
  TrainingReleaseDateMessageProps
> = ({ releaseDate }) => {
  return (
    <S.TrainingReleaseDateContainer>
      <div>
        <img height={130} width={130} src={THIconCompliantStatus} />
      </div>
      <div>
        <h2>Way to be proactive!</h2>
        <div className="trainingReleaseDateSubtitle">
          Your HIPAA training for{' '}
          {releaseDate
            ? new Date(releaseDate).getFullYear()
            : new Date().getFullYear()}{' '}
          hasn't yet been released. We'll send you an email when it becomes
          available.
        </div>
      </div>
    </S.TrainingReleaseDateContainer>
  );
};
