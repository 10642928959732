import {
  FormActionsTypes,
  FORM_ADD_NEW_QUESTION,
  Question,
  QuestionList,
  QuestionType,
  FORM_SAVE_EDITED_QUESTION,
  FORM_LOAD_QUESTIONS_SUCCESS,
  FORM_MARK_QUESTION_AS_DELETED,
  FORM_COPY_QUESTION,
  QuestionTypes,
  Choice,
  FORM_UPDATE_QUESTIONS,
} from 'src/store/types';
import { generateId } from 'src/shared/utils';

const initialQuestionTypeState: QuestionType = {
  id: 0,
  label: '',
  key: '',
  category: '',
  regex: '',
  decoration: false,
  canBeSmart: false,
};

export const initialQuestionState: Question = {
  id: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  isActive: false,
  question: '',
  description: '',
  tooltip: '',
  moreInfo: '',
  category: '',
  multiple: false,
  allowExtra: false,
  required: false,
  choices: [],
  answer: null,
  form: {},
  formId: undefined,
  weight: 0,
  conditions: [],
  type: initialQuestionTypeState,
  typeId: undefined,
  internalId: undefined,
  isSmart: false,
  legalRef: '',
  legalRefCmmc: '',
  legalRefNist: '',
  temp: true,
};

export const questionListReducer = (
  state: QuestionList = [],
  action: FormActionsTypes,
): QuestionList => {
  switch (action.type) {
    case FORM_ADD_NEW_QUESTION: {
      const newQuestion = {
        ...initialQuestionState,
        formId: action.formId,
        typeId: action.questionType.id,
        type: { ...initialQuestionTypeState, ...action.questionType },
        internalId: generateId(),
        weight: state.length + 1,
        temp: true,
      };

      if (action.questionType.key === QuestionTypes.YES_NO) {
        const options: Choice[] = [
          {
            internalId: generateId(),
            label: 'Yes',
            value: 'yes',
            isDefault: false,
            weight: 1,
          },
          {
            internalId: generateId(),
            label: 'No',
            value: 'no',
            isDefault: false,
            weight: 2,
          },
        ];

        newQuestion.choices = options;
      }

      return [...state, newQuestion];
    }
    case FORM_SAVE_EDITED_QUESTION: {
      const newQuestion = {
        ...action.originalQuestion,
        ...action.partialQuestion,
        temp: false,
      };
      const list = state.map((q: Question) => {
        if (newQuestion.id && newQuestion.id === q.id) {
          return newQuestion;
        }
        if (newQuestion.internalId && newQuestion.internalId === q.internalId) {
          return newQuestion;
        }
        return q;
      });
      return list;
    }
    case FORM_LOAD_QUESTIONS_SUCCESS:
    case FORM_UPDATE_QUESTIONS: {
      return [...action.questions];
    }
    case FORM_MARK_QUESTION_AS_DELETED: {
      let list = state;
      let question: Question | undefined = undefined;

      list = state.filter((q) => {
        if (
          (action.questionInternalId &&
            action.questionInternalId === q.internalId) ||
          (action.questionId && action.questionId === q.id)
        ) {
          question = q;
          return false;
        }
        return true;
      });

      // Remove related conditions, and reduce the weight of every question that comes after this one by one
      return list.map((q) => {
        const currentQuestion: Question = {
          ...q,
          conditions: q.conditions?.filter(
            (condition) =>
              !condition.subject || condition.subject?.id !== question?.id,
          ),
        };
        return question && currentQuestion.weight > question.weight
          ? {
              ...currentQuestion,
              weight: currentQuestion.weight - 1,
            }
          : currentQuestion;
      });
    }
    case FORM_COPY_QUESTION: {
      const list = state;
      const key = action.questionId || action.questionInternalId;
      const question = list.find((q) => q.id === key || q.internalId === key);
      const clonedQuestion = {
        ...question,
        id: undefined,
        internalId: generateId(),
        weight: list.length + 1,
        formId: action?.formId,
        typeId: question?.type?.id,
        conditions: question?.conditions?.map((condition) => ({
          ...condition,
          id: undefined,
          internalId: generateId(),
          subjectId: condition.subjectId || condition.subject?.id,
          subject: undefined,
        })),
        choices: question?.choices?.map((choice) => ({
          ...choice,
          id: undefined,
          internalId: generateId(),
        })),
      } as Question;
      return [...list, clonedQuestion];
    }
    default:
      return state;
  }
};
