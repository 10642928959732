import { showErrorMessage } from 'src/store/actions/error';
import {
  EXAM_CREATE_FAILED,
  EXAM_CREATE_REQUEST,
  EXAM_CREATE_SUCCESS,
  EXAM_RESET,
  EXAM_QUESTION_CREATE_REQUEST,
  EXAM_QUESTION_CREATE_SUCCESS,
  EXAM_QUESTION_CREATE_FAILED,
  EXAM_QUESTION_UPDATE_REQUEST,
  EXAM_QUESTION_UPDATE_SUCCESS,
  EXAM_QUESTION_UPDATE_FAILED,
  EXAM_LOAD_REQUEST,
  EXAM_LOAD_SUCCESS,
  EXAM_LOAD_FAILED,
  EXAM_UPDATE_REQUEST,
  EXAM_UPDATE_SUCCESS,
  EXAM_UPDATE_FAILED,
  EXAM_QUESTION_DELETE_REQUEST,
  EXAM_QUESTION_DELETE_SUCCESS,
  EXAM_QUESTION_DELETE_FAILED,
  EXAM_CLONE_REQUEST,
  EXAM_CLONE_SUCCESS,
  EXAM_CLONE_FAILED,
  EXAM_LOAD_SUBMISSION_FAILED,
  EXAM_LOAD_SUBMISSION_REQUEST,
  EXAM_LOAD_SUBMISSION_SUCCESS,
  EXAM_SUBMISSION_FAILED,
  EXAM_SUBMISSION_REQUEST,
  EXAM_SUBMISSION_RESPONSE_FAILED,
  EXAM_SUBMISSION_RESPONSE_REQUEST,
  EXAM_SUBMISSION_RESPONSE_SUCCESS,
  EXAM_SUBMISSION_SUCCESS,
  ExamLoadSubmissionErrorAction,
  ExamLoadSubmissionRequestAction,
  ExamLoadSubmissionSuccessAction,
  ExamSubmission,
  ExamSubmissionErrorAction,
  ExamSubmissionRequestAction,
  ExamSubmissionResponseErrorAction,
  ExamSubmissionResponseRequestAction,
  ExamSubmissionResponseSuccessAction,
  ExamSubmissionSuccessAction,
  ExamConfig,
  ExamCreateErrorAction,
  ExamCreateRequestAction,
  ExamCreateSuccessAction,
  ExamResetAction,
  Exam,
  ExamQuestionCreateRequestAction,
  ExamQuestionCreateSuccessAction,
  ExamQuestionCreateErrorAction,
  QuizQuestion,
  ExamQuestionUpdateSuccessAction,
  ExamQuestionUpdateRequestAction,
  ExamQuestionUpdateErrorAction,
  ExamLoadRequestAction,
  ExamLoadSuccessAction,
  ExamLoadErrorAction,
  ExamUpdateRequestAction,
  ExamUpdateSuccessAction,
  ExamUpdateErrorAction,
  ExamQuestionDeleteRequestAction,
  ExamQuestionDeleteSuccessAction,
  ExamQuestionDeleteErrorAction,
  ExamCloneSuccessAction,
  ExamCloneRequestAction,
  ExamCloneErrorAction,
  ExamQuizLoadRequestAction,
  EXAM_QUIZ_LOAD_REQUEST,
  EXAM_QUIZ_LOAD_SUCCESS,
  ExamQuizLoadSuccessAction,
  ExamQuizLoadErrorAction,
  EXAM_QUIZ_LOAD_FAILED,
  ExamSubmissionResetAction,
  EXAM_SUBMISSION_RESET,
} from '../types';

export const examCreateRequest = (
  config: ExamConfig,
): ExamCreateRequestAction => ({
  type: EXAM_CREATE_REQUEST,
  config,
});

export const examCreateSuccess = (exam: Exam): ExamCreateSuccessAction => ({
  type: EXAM_CREATE_SUCCESS,
  exam,
});

export const examCreateError = (
  errorMessage: string,
  originalError?: Error,
): ExamCreateErrorAction =>
  showErrorMessage(EXAM_CREATE_FAILED, errorMessage, originalError);

export const examReset = (): ExamResetAction => ({
  type: EXAM_RESET,
});

export const examQuestionCreateRequest = (
  examId: number,
): ExamQuestionCreateRequestAction => ({
  type: EXAM_QUESTION_CREATE_REQUEST,
  examId,
});

export const examQuestionCreateSuccess = (
  question: QuizQuestion,
): ExamQuestionCreateSuccessAction => ({
  type: EXAM_QUESTION_CREATE_SUCCESS,
  question,
});

export const examQuestionCreateError = (
  errorMessage: string,
  originalError?: Error,
): ExamQuestionCreateErrorAction =>
  showErrorMessage(EXAM_QUESTION_CREATE_FAILED, errorMessage, originalError);

export const examQuestionUpdateRequest = (
  examId: number,
  question: QuizQuestion,
): ExamQuestionUpdateRequestAction => ({
  type: EXAM_QUESTION_UPDATE_REQUEST,
  examId,
  question,
});

export const examQuestionUpdateSuccess = (
  question: QuizQuestion,
): ExamQuestionUpdateSuccessAction => ({
  type: EXAM_QUESTION_UPDATE_SUCCESS,
  question,
});

export const examQuestionUpdateError = (
  errorMessage: string,
  originalError?: Error,
): ExamQuestionUpdateErrorAction =>
  showErrorMessage(EXAM_QUESTION_UPDATE_FAILED, errorMessage, originalError);

export const examQuestionDeleteRequest = (
  examId: number,
  questionId: number,
): ExamQuestionDeleteRequestAction => ({
  type: EXAM_QUESTION_DELETE_REQUEST,
  examId,
  questionId,
});

export const examQuestionDeleteSuccess = (
  questionId: number,
): ExamQuestionDeleteSuccessAction => ({
  type: EXAM_QUESTION_DELETE_SUCCESS,
  questionId,
});

export const examQuestionDeleteError = (
  errorMessage: string,
  originalError?: Error,
): ExamQuestionDeleteErrorAction =>
  showErrorMessage(EXAM_QUESTION_DELETE_FAILED, errorMessage, originalError);

export const examLoadRequest = (
  examId?: number,
  loadSubmission?: boolean,
  shuffled?: boolean,
): ExamLoadRequestAction => ({
  type: EXAM_LOAD_REQUEST,
  examId,
  loadSubmission,
  shuffled,
});

export const examLoadSuccess = (exam: Exam): ExamLoadSuccessAction => ({
  type: EXAM_LOAD_SUCCESS,
  exam,
});

export const examLoadError = (
  errorMessage: string,
  originalError?: Error,
): ExamLoadErrorAction => ({
  type: EXAM_LOAD_FAILED,
  error: errorMessage,
});

export const examUpdateRequest = (
  examId: number,
  config: ExamConfig,
): ExamUpdateRequestAction => ({
  type: EXAM_UPDATE_REQUEST,
  examId,
  config,
});

export const examUpdateSuccess = (exam: Exam): ExamUpdateSuccessAction => ({
  type: EXAM_UPDATE_SUCCESS,
  exam,
});

export const examUpdateError = (
  errorMessage: string,
  originalError?: Error,
): ExamUpdateErrorAction =>
  showErrorMessage(EXAM_UPDATE_FAILED, errorMessage, originalError);

export const examCloneRequest = (examId: number): ExamCloneRequestAction => ({
  type: EXAM_CLONE_REQUEST,
  examId,
});

export const examCloneSuccess = (exam: Exam): ExamCloneSuccessAction => ({
  type: EXAM_CLONE_SUCCESS,
  exam,
});

export const examCloneError = (
  errorMessage: string,
  originalError?: Error,
): ExamCloneErrorAction =>
  showErrorMessage(EXAM_CLONE_FAILED, errorMessage, originalError);

export const examLoadSubmissionRequest = (
  examId: number,
  submissionId: number,
): ExamLoadSubmissionRequestAction => ({
  type: EXAM_LOAD_SUBMISSION_REQUEST,
  examId,
  submissionId,
});

export const examLoadSubmissionSuccess = (
  submission: ExamSubmission,
): ExamLoadSubmissionSuccessAction => ({
  type: EXAM_LOAD_SUBMISSION_SUCCESS,
  submission,
});

export const examLoadSubmissionError = (
  errorMessage: string,
  originalError?: Error,
): ExamLoadSubmissionErrorAction =>
  showErrorMessage(EXAM_LOAD_SUBMISSION_FAILED, errorMessage, originalError);

export const examSubmissionRequest = (
  examId: number,
): ExamSubmissionRequestAction => ({
  type: EXAM_SUBMISSION_REQUEST,
  examId,
});

export const examSubmissionSuccess = (
  submission: ExamSubmission,
): ExamSubmissionSuccessAction => ({
  type: EXAM_SUBMISSION_SUCCESS,
  submission,
});

export const examSubmissionError = (
  errorMessage: string,
  originalError?: Error,
): ExamSubmissionErrorAction =>
  showErrorMessage(EXAM_SUBMISSION_FAILED, errorMessage, originalError);

export const examSubmissionResponseRequest = (
  examId: number,
  submissionId: number,
  questionId: number,
  choiceId: number,
  compute: boolean,
): ExamSubmissionResponseRequestAction => ({
  type: EXAM_SUBMISSION_RESPONSE_REQUEST,
  examId,
  submissionId,
  questionId,
  choiceId,
  compute,
});

export const examSubmissionResponseSuccess = (
  submissionResult?: ExamSubmission,
): ExamSubmissionResponseSuccessAction => ({
  type: EXAM_SUBMISSION_RESPONSE_SUCCESS,
  submissionResult,
});

export const examSubmissionResponseError = (
  errorMessage: string,
  originalError?: Error,
): ExamSubmissionResponseErrorAction =>
  showErrorMessage(
    EXAM_SUBMISSION_RESPONSE_FAILED,
    errorMessage,
    originalError,
  );

export const examQuizLoadRequestAction = (): ExamQuizLoadRequestAction => ({
  type: EXAM_QUIZ_LOAD_REQUEST,
});

export const examQuizLoadSuccessAction = (
  exam: Exam,
): ExamQuizLoadSuccessAction => ({
  type: EXAM_QUIZ_LOAD_SUCCESS,
  exam,
});

export const examQuizLoadErrorAction = (
  errorMessage: string,
  originalError?: Error,
): ExamQuizLoadErrorAction =>
  showErrorMessage(EXAM_QUIZ_LOAD_FAILED, errorMessage, originalError);

export const examSubmissionReset = (): ExamSubmissionResetAction => ({
  type: EXAM_SUBMISSION_RESET,
});
