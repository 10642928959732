import { combineReducers } from 'redux';
import {
  Loader,
  FormActionsTypes,
  FormState,
  SelectedFormState,
  FormMetadataState,
  FORM_SAVE_REQUEST,
  FORM_SAVE_SUCCESS,
  FORM_SAVE_ERROR,
  FORM_SET_SELECTED,
  FORM_SET_QUESTION_SELECTED,
  FORM_SET_QUESTION_SELECTED_RESET,
  FORM_UPDATE_REQUEST,
  FORM_UPDATE_SUCCESS,
  FORM_UPDATE_ERROR,
  FORM_LOAD_QUESTIONS_SUCCESS,
  FORM_LOAD_QUESTIONS_REQUEST,
  FORM_LOAD_QUESTIONS_ERROR,
  FORM_MARK_QUESTION_AS_DELETED,
  FORM_MARK_AS_TOUCHED,
  FORM_SAVE_EDITED_QUESTION,
  FORM_COPY_QUESTION,
  FORM_UPDATE_QUESTIONS,
} from 'src/store/types';

import { questionListReducer } from './question';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const formInitialState: FormState = {
  id: 0,
  label: '',
  description: '',
  categoryFolder: undefined,
  creator: 0,
  updateReason: '',
  assessment: 0,
  required: false,
  isActive: false,
  isDraft: false,
  questions: [],
  selectedQuestion: undefined,
  weight: 0,
};

const formMetadataInitialState: FormMetadataState = {
  deletedQuestions: [],
  isPristine: true,
  saveCompleted: false,
};

export const loaderReducer = (
  state: Loader = loaderInitialState,
  action: FormActionsTypes,
): Loader => {
  switch (action.type) {
    case FORM_SAVE_REQUEST:
    case FORM_LOAD_QUESTIONS_REQUEST:
    case FORM_UPDATE_REQUEST: {
      return { ...loaderInitialState, loading: true };
    }
    case FORM_SAVE_SUCCESS:
    case FORM_LOAD_QUESTIONS_SUCCESS:
    case FORM_UPDATE_SUCCESS: {
      return { ...loaderInitialState, success: true, completed: true };
    }
    case FORM_SAVE_ERROR:
    case FORM_LOAD_QUESTIONS_ERROR:
    case FORM_UPDATE_ERROR: {
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
      };
    }
    default:
      return state;
  }
};

export const formReducer = (
  state: FormState = formInitialState,
  action: FormActionsTypes,
): FormState => {
  switch (action.type) {
    case FORM_SET_SELECTED: {
      return { ...action.form };
    }
    case FORM_SET_QUESTION_SELECTED: {
      return { ...state, selectedQuestion: action.question };
    }
    case FORM_SET_QUESTION_SELECTED_RESET: {
      return { ...state, selectedQuestion: undefined };
    }
    default:
      return state;
  }
};

export const formMetadataReducer = (
  state: FormMetadataState = formMetadataInitialState,
  action: FormActionsTypes,
): FormMetadataState => {
  switch (action.type) {
    case FORM_SET_SELECTED: {
      return formMetadataInitialState;
    }
    case FORM_MARK_QUESTION_AS_DELETED: {
      if (action.questionId) {
        return {
          ...formMetadataInitialState,
          isPristine: false,
          deletedQuestions: [...state.deletedQuestions, action.questionId],
        };
      }
      return state;
    }
    case FORM_MARK_AS_TOUCHED:
    case FORM_SAVE_EDITED_QUESTION:
    case FORM_COPY_QUESTION:
    case FORM_UPDATE_QUESTIONS: {
      return { ...state, isPristine: false, saveCompleted: false };
    }
    case FORM_UPDATE_SUCCESS: {
      return {
        ...formMetadataInitialState,
        isPristine: true,
        saveCompleted: true,
      };
    }
    default:
      return state;
  }
};

export const combinedFormReducer = combineReducers<SelectedFormState>({
  form: formReducer,
  loader: loaderReducer,
  questions: questionListReducer,
  metadata: formMetadataReducer,
});
