import React, { FC, useState, useEffect } from 'react';
import { Table, Empty } from 'antd';
import styled from 'styled-components';

import { Grid } from 'src/components/Grid';
import { UserRole, UserListingIndexedItem } from 'src/store/types';

import { dataMapper } from './dataMapper';
import { UsersTableSettings } from '../UsersTableSettings';

export interface UserListingTableProps {
  users: UserListingIndexedItem;
  showEditUserPanel: (userId: number) => void;
}

export interface UserTableRow {
  id: number;
  name: string;
  email: string;
  role: UserRole;
  roleName: string;
  isTwoFactorAuthenticationEnabled: boolean;
}

const { Column } = Table;

const CustomGrid = styled(Grid)`
  .ant-table table {
    .ant-table-tbody {
      tr.ant-table-row {
        td:last-child {
          display: table-cell;

          & > div {
            justify-content: center;
          }
        }
      }
    }
  }
`;

export const UserListingTable: FC<UserListingTableProps> = (props) => {
  const { users } = props;
  const { showEditUserPanel } = props;

  const [dataSource, setDataSource] = useState<UserTableRow[]>([]);

  useEffect(() => {
    const userRows: UserTableRow[] = dataMapper(users);

    setDataSource(userRows);
  }, [users]);

  return (
    <CustomGrid
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
    >
      <Column<UserTableRow> title="Name" dataIndex="name" key="name" />
      <Column<UserTableRow> title="Email" dataIndex="email" key="email" />
      <Column<UserTableRow> title="Role" dataIndex="roleName" key="roleName" />
      <Column<UserTableRow>
        title={false}
        dataIndex=""
        key="action"
        render={(_, record: UserTableRow) => (
          <UsersTableSettings user={record} onEdit={showEditUserPanel} />
        )}
      />
    </CustomGrid>
  );
};
