import { call, takeEvery, put } from 'redux-saga/effects';
import { testTypeService } from 'src/features/learning';
import actions from 'src/store/actions';
import { TestTypesResponse, TEST_TYPES_LOAD_REQUEST } from '../types';

export const loadTestTypes = function* async(): Generator {
  try {
    const result = yield call(testTypeService.getAll);
    yield put(actions.testTypesLoadSuccess(result as TestTypesResponse));
  } catch (err) {
    yield put(
      actions.testTypesLoadError('Failed to load test types', err as Error),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(TEST_TYPES_LOAD_REQUEST, loadTestTypes)];
}
