import React, { FC } from 'react';
import styled from 'styled-components';

import { Alert } from 'antd';

interface TemplateEditorMessageProps {
  message: string;
}

const ErrorMessage = styled(Alert)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border: none;

  & .ant-alert-message {
    max-height: 15vh;
    overflow: auto;
  }
`;

export const TemplateEditorMessage: FC<TemplateEditorMessageProps> = (
  props,
) => {
  const { message } = props;

  return <ErrorMessage message={message} type="error" closable />;
};
