import { call, takeEvery, put } from 'redux-saga/effects';
import {
  VariableState,
  VARIABLE_SAVE_REQUEST,
  VariableSaveRequestAction,
  VariableDeleteRequestAction,
  VariableUpdateRequestAction,
  VARIABLE_UPDATE_REQUEST,
  VARIABLE_DELETE_REQUEST,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const saveVariable = function* async({
  variable: variableData,
}: VariableSaveRequestAction): Generator {
  try {
    const variable = yield call(api.saveVariable, variableData);
    yield put(actions.variableSaveSuccess(variable as VariableState));
  } catch (err) {
    yield put(
      actions.templateSaveError('Failed to save variable', err as Error),
    );
  }
};

export const updateVariable = function* async({
  variableId,
  variable: variableData,
}: VariableUpdateRequestAction): Generator {
  try {
    const variable = yield call(api.updateVariable, variableId, variableData);
    yield put(actions.variableUpdateSuccess(variable as VariableState));
  } catch (err) {
    yield put(
      actions.templateUpdateError('Failed to update variable', err as Error),
    );
  }
};

export const deleteVariable = function* async({
  variableId,
}: VariableDeleteRequestAction): Generator {
  try {
    const result = yield call(api.deleteVariable, variableId);
    if (result) {
      yield put(actions.variableDeleteSuccess(variableId));
    }
  } catch (err) {
    yield put(
      actions.templateDeleteError('Failed to delete variable', err as Error),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(VARIABLE_SAVE_REQUEST, saveVariable),
    yield takeEvery(VARIABLE_UPDATE_REQUEST, updateVariable),
    yield takeEvery(VARIABLE_DELETE_REQUEST, deleteVariable),
  ];
}
