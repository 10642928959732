import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';
import Form from 'antd/lib/form';
import _ from 'lodash';
import { DatePicker } from 'src/components/DatePicker';
import { SearchInput } from '../../../../components/SearchInput';

interface AssessmentAuditingFiltersProps {
  fromDate: any;
  toDate: any;
  onFromDateChange: (filter: Moment | null) => void;
  onToDateChange: (filter: Moment | null) => void;
  onUsernameChange: (filter: string) => void;
}

const FormContainer = styled(Form)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`;

export const AssessmentAuditingFilters: FC<AssessmentAuditingFiltersProps> = ({
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  onUsernameChange,
}) => {
  const debounceSetUsername = useMemo(
    () =>
      _.debounce((username: string) => {
        onUsernameChange(username);
      }, 1000),
    [],
  );
  return (
    <FormContainer layout="inline">
      <Form.Item label="From">
        <DatePicker
          value={fromDate}
          placeholder="From"
          onChange={(value) => onFromDateChange(value)}
        />
      </Form.Item>
      <Form.Item label="To">
        <DatePicker
          value={toDate}
          placeholder="To"
          onChange={(value) => onToDateChange(value)}
        />
      </Form.Item>
      <Form.Item label="Search Users">
        <SearchInput
          type="email"
          onChange={(e) => debounceSetUsername(e.target.value)}
          placeholder="Email"
        />
      </Form.Item>
    </FormContainer>
  );
};
