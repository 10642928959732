/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector, OutputSelector } from 'reselect';
import {
  RootState,
  Comment,
  CommentListingIndexedItem,
  Loader,
} from '../types';

export const getCommentListingFromState = (
  state: RootState,
): CommentListingIndexedItem => state.commentListing.comments;

export const getCommentLoaderFromState = (state: RootState): Loader =>
  state.comment.loader;

export const getIsCommentLoaderSuccessful: OutputSelector<
  any,
  boolean,
  (res: Loader) => boolean
> = createSelector(
  getCommentLoaderFromState,
  (loader: Loader) => loader.completed && loader.success,
);

export const getCommentById = (
  state: RootState,
  commentId?: number,
): Comment | undefined => {
  if (!commentId) return undefined;

  return getCommentListingFromState(state)[commentId];
};
