import { Loader } from 'src/store/types';
import {
  TestTypeActionTypes,
  TestTypesState,
  TEST_TYPES_LOAD_FAILED,
  TEST_TYPES_LOAD_REQUEST,
  TEST_TYPES_LOAD_SUCCESS,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const testTypesInitialState: TestTypesState = {
  testTypes: [],
  loader: loaderInitialState,
};

export const testTypeListingReducer = (
  state = testTypesInitialState,
  action: TestTypeActionTypes,
): TestTypesState => {
  switch (action.type) {
    case TEST_TYPES_LOAD_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case TEST_TYPES_LOAD_SUCCESS: {
      return {
        testTypes: action.testTypes,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TEST_TYPES_LOAD_FAILED: {
      return {
        testTypes: [],
        loader: {
          ...loaderInitialState,
        },
      };
    }
    default:
      return state;
  }
};
