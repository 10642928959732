import React, { FC } from 'react';
import styled from 'styled-components';
import { Menu as MenuAntD, Dropdown } from 'antd';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { SettingOutlined } from 'src/theme/icons';
import { documentDraftUpdateRequest } from 'src/store/actions/documentDraft';
import { useAuth } from 'src/hooks/useAuth/useAuth';

export interface DocumentDraftListingTableSettingsProps {
  documentDraftId: number;
  archived: boolean;
}

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  width: 80;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

const Menu = styled(MenuAntD)`
  border-radius: 4px;
  margin-top: -35px;
  margin-left: 12px;
  width: 180px;

  .ant-dropdown-menu-item:hover {
    &.title {
      background-color: white;
      cursor: default;
    }
  }

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightBlue.main}`};

    > span {
      font-weight: 600;
    }

    .title-icon {
      color: ${({ theme }) => theme.colors.lightBlue.main};
      font-size: 20px;
    }
  }

  .title-divider {
    color: red;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0px 4px;
    width: 172px;
  }

  span {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }
`;

const menu = (
  documentDraftId: number,
  dispatch: Dispatch,
  admin: boolean,
  archived: boolean,
) => (
  <Menu>
    {admin && !archived ? (
      <Menu.Item
        key="0"
        onClick={() =>
          dispatch(
            documentDraftUpdateRequest(documentDraftId, { archived: true }),
          )
        }
      >
        <span>Archive document draft</span>
      </Menu.Item>
    ) : (
      <Menu.Item
        key="0"
        onClick={() =>
          dispatch(
            documentDraftUpdateRequest(documentDraftId, { archived: false }),
          )
        }
      >
        <span>Set active</span>
      </Menu.Item>
    )}
  </Menu>
);

export const DocumentDraftListingTableSettings: FC<
  DocumentDraftListingTableSettingsProps
> = ({ documentDraftId, archived }) => {
  const dispatch = useDispatch();
  const { isAdmin } = useAuth();

  return (
    <Dropdown
      overlay={menu(documentDraftId, dispatch, isAdmin, archived)}
      trigger={['click']}
    >
      <DropdownIcon>
        <SettingOutlined />
      </DropdownIcon>
    </Dropdown>
  );
};
