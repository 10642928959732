import { showErrorMessage } from 'src/store/actions/error';
import {
  TrainingConfig,
  TrainingCreateErrorAction,
  TrainingCreateRequestAction,
  TrainingCreateSuccessAction,
  TrainingResetAction,
  Training,
  TrainingQuestionCreateRequestAction,
  TrainingQuestionCreateSuccessAction,
  TrainingQuestionCreateErrorAction,
  TRAINING_CREATE_FAILED,
  TRAINING_CREATE_REQUEST,
  TRAINING_CREATE_SUCCESS,
  TRAINING_RESET,
  TRAINING_QUESTION_CREATE_REQUEST,
  TRAINING_QUESTION_CREATE_SUCCESS,
  TRAINING_QUESTION_CREATE_FAILED,
  QuizQuestion,
  TrainingQuestionUpdateSuccessAction,
  TrainingQuestionUpdateRequestAction,
  TrainingQuestionUpdateErrorAction,
  TRAINING_QUESTION_UPDATE_REQUEST,
  TRAINING_QUESTION_UPDATE_SUCCESS,
  TRAINING_QUESTION_UPDATE_FAILED,
  TrainingLoadRequestAction,
  TRAINING_LOAD_REQUEST,
  TRAINING_LOAD_SUCCESS,
  TrainingLoadSuccessAction,
  TrainingLoadErrorAction,
  TRAINING_LOAD_FAILED,
  TrainingUpdateRequestAction,
  TrainingUpdateSuccessAction,
  TrainingUpdateErrorAction,
  TRAINING_UPDATE_REQUEST,
  TRAINING_UPDATE_SUCCESS,
  TRAINING_UPDATE_FAILED,
  TrainingQuestionDeleteRequestAction,
  TrainingQuestionDeleteSuccessAction,
  TrainingQuestionDeleteErrorAction,
  TRAINING_QUESTION_DELETE_REQUEST,
  TRAINING_QUESTION_DELETE_SUCCESS,
  TRAINING_QUESTION_DELETE_FAILED,
  TrainingSubmissionRequestAction,
  TrainingSubmissionSuccessAction,
  TrainingSubmissionErrorAction,
  TRAINING_SUBMISSION_REQUEST,
  TRAINING_SUBMISSION_SUCCESS,
  TRAINING_SUBMISSION_FAILED,
  TrainingSubmission,
  TrainingSubmissionResponseRequestAction,
  TrainingSubmissionResponseSuccessAction,
  TrainingSubmissionResponseErrorAction,
  TRAINING_SUBMISSION_RESPONSE_REQUEST,
  TRAINING_SUBMISSION_RESPONSE_SUCCESS,
  TRAINING_SUBMISSION_RESPONSE_FAILED,
  TrainingSubmissionResult,
  TrainingSubmissionResponseResetAction,
  TRAINING_SUBMISSION_RESPONSE_RESET,
  TrainingLoadSubmissionRequestAction,
  TRAINING_LOAD_SUBMISSION_REQUEST,
  TRAINING_LOAD_SUBMISSION_SUCCESS,
  TrainingLoadSubmissionSuccessAction,
  TrainingLoadSubmissionErrorAction,
  TRAINING_LOAD_SUBMISSION_FAILED,
  TrainingCloneRequestAction,
  TRAINING_CLONE_REQUEST,
  TrainingCloneSuccessAction,
  TRAINING_CLONE_SUCCESS,
  TrainingCloneErrorAction,
  TRAINING_CLONE_FAILED,
  TrainingSetSelectedAction,
  TRAINING_SET_SELECTED,
} from '../types';

export const trainingCreateRequest = (
  config: TrainingConfig,
): TrainingCreateRequestAction => ({
  type: TRAINING_CREATE_REQUEST,
  config,
});

export const trainingCreateSuccess = (
  training: Training,
): TrainingCreateSuccessAction => ({
  type: TRAINING_CREATE_SUCCESS,
  training,
});

export const trainingCreateError = (
  errorMessage: string,
  originalError?: Error,
): TrainingCreateErrorAction =>
  showErrorMessage(TRAINING_CREATE_FAILED, errorMessage, originalError);

export const trainingReset = (): TrainingResetAction => ({
  type: TRAINING_RESET,
});

export const trainingQuestionCreateRequest = (
  trainingId: number,
): TrainingQuestionCreateRequestAction => ({
  type: TRAINING_QUESTION_CREATE_REQUEST,
  trainingId,
});

export const trainingQuestionCreateSuccess = (
  question: QuizQuestion,
): TrainingQuestionCreateSuccessAction => ({
  type: TRAINING_QUESTION_CREATE_SUCCESS,
  question,
});

export const trainingQuestionCreateError = (
  errorMessage: string,
  originalError?: Error,
): TrainingQuestionCreateErrorAction =>
  showErrorMessage(
    TRAINING_QUESTION_CREATE_FAILED,
    errorMessage,
    originalError,
  );

export const trainingQuestionUpdateRequest = (
  trainingId: number,
  question: QuizQuestion,
): TrainingQuestionUpdateRequestAction => ({
  type: TRAINING_QUESTION_UPDATE_REQUEST,
  trainingId,
  question,
});

export const trainingQuestionUpdateSuccess = (
  question: QuizQuestion,
): TrainingQuestionUpdateSuccessAction => ({
  type: TRAINING_QUESTION_UPDATE_SUCCESS,
  question,
});

export const trainingQuestionUpdateError = (
  errorMessage: string,
  originalError?: Error,
): TrainingQuestionUpdateErrorAction =>
  showErrorMessage(
    TRAINING_QUESTION_UPDATE_FAILED,
    errorMessage,
    originalError,
  );

export const trainingQuestionDeleteRequest = (
  trainingId: number,
  questionId: number,
): TrainingQuestionDeleteRequestAction => ({
  type: TRAINING_QUESTION_DELETE_REQUEST,
  trainingId,
  questionId,
});

export const trainingQuestionDeleteSuccess = (
  questionId: number,
): TrainingQuestionDeleteSuccessAction => ({
  type: TRAINING_QUESTION_DELETE_SUCCESS,
  questionId,
});

export const trainingQuestionDeleteError = (
  errorMessage: string,
  originalError?: Error,
): TrainingQuestionDeleteErrorAction =>
  showErrorMessage(
    TRAINING_QUESTION_DELETE_FAILED,
    errorMessage,
    originalError,
  );

export const trainingLoadRequest = (
  trainingId: number,
): TrainingLoadRequestAction => ({
  type: TRAINING_LOAD_REQUEST,
  trainingId,
});

export const trainingLoadSuccess = (
  training: Training,
): TrainingLoadSuccessAction => ({
  type: TRAINING_LOAD_SUCCESS,
  training,
});

export const trainingLoadError = (
  errorMessage: string,
  originalError?: Error,
): TrainingLoadErrorAction =>
  showErrorMessage(TRAINING_LOAD_FAILED, errorMessage, originalError);

export const trainingUpdateRequest = (
  trainingId: number,
  config: TrainingConfig,
): TrainingUpdateRequestAction => ({
  type: TRAINING_UPDATE_REQUEST,
  trainingId,
  config,
});

export const trainingUpdateSuccess = (
  training: Training,
): TrainingUpdateSuccessAction => ({
  type: TRAINING_UPDATE_SUCCESS,
  training,
});

export const trainingUpdateError = (
  errorMessage: string,
  originalError?: Error,
): TrainingUpdateErrorAction =>
  showErrorMessage(TRAINING_UPDATE_FAILED, errorMessage, originalError);

export const trainingSubmissionRequest = (
  trainingId: number,
): TrainingSubmissionRequestAction => ({
  type: TRAINING_SUBMISSION_REQUEST,
  trainingId,
});

export const trainingSubmissionSuccess = (
  submission: TrainingSubmission,
): TrainingSubmissionSuccessAction => ({
  type: TRAINING_SUBMISSION_SUCCESS,
  submission,
});

export const trainingSubmissionError = (
  errorMessage: string,
  originalError?: Error,
): TrainingSubmissionErrorAction =>
  showErrorMessage(TRAINING_SUBMISSION_FAILED, errorMessage, originalError);

export const trainingSubmissionResponseRequest = (
  trainingId: number,
  submissionId: number,
  questionId: number,
  choiceId: number,
  compute: boolean,
): TrainingSubmissionResponseRequestAction => ({
  type: TRAINING_SUBMISSION_RESPONSE_REQUEST,
  trainingId,
  submissionId,
  questionId,
  choiceId,
  compute,
});

export const trainingSubmissionResponseSuccess = (
  submissionResult?: TrainingSubmissionResult,
): TrainingSubmissionResponseSuccessAction => ({
  type: TRAINING_SUBMISSION_RESPONSE_SUCCESS,
  submissionResult,
});

export const trainingSubmissionResponseError = (
  errorMessage: string,
  originalError?: Error,
): TrainingSubmissionResponseErrorAction =>
  showErrorMessage(
    TRAINING_SUBMISSION_RESPONSE_FAILED,
    errorMessage,
    originalError,
  );

export const trainingSubmissionResponseReset =
  (): TrainingSubmissionResponseResetAction => ({
    type: TRAINING_SUBMISSION_RESPONSE_RESET,
  });

export const trainingLoadSubmissionRequest = (
  trainingId: number,
  submissionId: number,
): TrainingLoadSubmissionRequestAction => ({
  type: TRAINING_LOAD_SUBMISSION_REQUEST,
  trainingId,
  submissionId,
});

export const trainingLoadSubmissionSuccess = (
  trainingSubmission?: any,
): TrainingLoadSubmissionSuccessAction => ({
  type: TRAINING_LOAD_SUBMISSION_SUCCESS,
  trainingSubmission,
});

export const trainingLoadSubmissionResponseError = (
  errorMessage: string,
  originalError?: Error,
): TrainingLoadSubmissionErrorAction =>
  showErrorMessage(
    TRAINING_LOAD_SUBMISSION_FAILED,
    errorMessage,
    originalError,
  );

export const trainingCloneRequest = (
  trainingId: number,
): TrainingCloneRequestAction => ({
  type: TRAINING_CLONE_REQUEST,
  trainingId,
});

export const trainingCloneSuccess = (
  training: Training,
): TrainingCloneSuccessAction => ({
  type: TRAINING_CLONE_SUCCESS,
  training,
});

export const trainingCloneError = (
  errorMessage: string,
  originalError?: Error,
): TrainingCloneErrorAction =>
  showErrorMessage(TRAINING_CLONE_FAILED, errorMessage, originalError);

export const setSelectedTraining = (
  training: Training,
): TrainingSetSelectedAction => ({
  type: TRAINING_SET_SELECTED,
  training,
});
