/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  RootState,
  DocumentDraftListingIndexedItem,
  DocumentDraftState,
  DocumentStatus,
} from '../types';

export const getIndexedDocumentsDraftsFromState = (
  state: RootState,
  title = '',
  isVisibleByClient = false,
): DocumentDraftListingIndexedItem => {
  let filteredDocumentsDrafts: DocumentDraftState[] = Object.values(
    state.documentDraftListing.documentDrafts,
  );

  if (isVisibleByClient) {
    filteredDocumentsDrafts = filteredDocumentsDrafts.filter(
      (dd: DocumentDraftState) =>
        dd.status === DocumentStatus.CLIENT_REVIEW ||
        dd.status === DocumentStatus.CLOSED,
    );
  }

  if (title) {
    const capsTitle = title.toUpperCase();
    filteredDocumentsDrafts = filteredDocumentsDrafts.filter(
      (dd: DocumentDraftState) => dd.title?.toUpperCase().includes(capsTitle),
    );
  }

  return filteredDocumentsDrafts.reduce(
    (
      ddListingIndexedItem: DocumentDraftListingIndexedItem,
      dd: DocumentDraftState,
    ) => ({
      ...ddListingIndexedItem,
      [dd.id]: dd,
    }),
    {},
  );
};
