import styled from 'styled-components';
import { remConvert } from 'src/theme/utils/rem-convert';

export const PageContentContainer = styled.div`
  margin-top: ${remConvert(22)};
  display: block;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

export const PageTitle = styled.h1`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-top: ${remConvert(8)};
`;

export const SubTitle = styled.p`
  text-align: center;
  margin-bottom: ${remConvert(10)};
`;

export const ProgressMeterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${remConvert(30)};
`;
