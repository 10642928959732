import moment from 'moment';
import { FormState, InterviewListingIndexedItem } from 'src/store/types';
import { TableRowInterface } from './AssessmentFormListingTable';

export const dataMapper = (
  forms: FormState[] = [],
  interviews?: InterviewListingIndexedItem,
): TableRowInterface[] => {
  return forms.map((form) => {
    const interview = interviews && interviews[form.id];
    return {
      id: form.id,
      interviewId: interview?.id as number,
      formTitle: form.label,
      status: interview
        ? interview.completedAt
          ? 'Completed'
          : 'Started'
        : 'Not Started',
      completionDate:
        interview && interview.completedAt
          ? moment(interview.completedAt).format('MM-DD-YYYY')
          : 'None',
      timeSpent: interview
        ? moment
            .duration(
              moment(interview.updatedAt).diff(moment(interview.createdAt)),
            )
            .humanize()
        : 'None',
    };
  });
};
