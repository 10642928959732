import React, { ChangeEvent, FC, useState } from 'react';
import { InputProps as InputAntdProps } from 'antd/lib/input';
import { KeyOutlined } from 'src/theme/icons';
import { InputPasswordStyled, InputStyled, InputWrapper } from './Styles';

export interface InputProps extends InputAntdProps {
  className?: string;
  contentsize?: 'normal' | 'large';
  disabled?: boolean;
  error?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  password?: boolean;
  value?: string;
  numeric?: boolean;
  name?: string;
  float?: boolean;
  positiveOnly?: boolean;
}

export const TextInput: FC<InputProps> = ({
  className,
  contentsize = 'normal',
  disabled,
  error = '',
  onChange,
  placeholder = '',
  password = false,
  value,
  onBlur,
  onKeyPress,
  numeric = false,
  name = '',
  float,
  onFocus,
  positiveOnly = false,
  ...inputProps
}) => {
  const Input: FC<InputProps> = password ? InputPasswordStyled : InputStyled;
  const [focus, setFocus] = useState<boolean>(false);
  const isOccupied = focus || (value && value.length !== 0);
  const labelClassName = isOccupied ? 'as-label' : 'as-placeholder';

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (positiveOnly) {
      const { value } = e.target;
      if (value === '' || (/^\d+$/.test(value) && parseInt(value, 10) > 0)) {
        onChange && onChange(e);
      }
    } else {
      onChange && onChange(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (positiveOnly && e.key === 'ArrowDown') {
      const currentValue = e.currentTarget.value;
      if (currentValue === '' || parseInt(currentValue) <= 0) {
        e.preventDefault();
      }
    }
  };

  return (
    <InputWrapper>
      <Input
        name={name}
        className={className}
        contentsize={contentsize}
        disabled={disabled}
        error={error}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={!float ? placeholder : ''}
        prefix={password && <KeyOutlined className="icon" />}
        size="large"
        type={password ? 'password' : numeric ? 'number' : 'text'}
        value={value}
        onKeyPress={
          onKeyPress
            ? onKeyPress
            : (e) => {
                if (e.key === 'Enter') e.preventDefault();
              }
        }
        onBlur={() => {
          setFocus(false);

          if (onBlur) {
            onBlur();
          }
        }}
        onFocus={() => {
          setFocus(true);

          if (onFocus) {
            onFocus();
          }
        }}
        {...inputProps}
      />
      {error && (
        <div>
          <span className="error">{error}</span>
        </div>
      )}
      {float && <label className={labelClassName}>{placeholder}</label>}
    </InputWrapper>
  );
};
