import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'src/api/services/ThApi';
import actions from 'src/store/actions';
import {
  UserSummaryFromResponse,
  GetUserSummaryRequestAction,
  USER_SUMMARY_LOAD_REQUEST,
} from '../types';

export const loadUserSummaryListing = function* ({
  companyId,
}: GetUserSummaryRequestAction): Generator {
  try {
    const companyUsers = yield call(api.getTrainees, companyId);
    yield put(
      actions.userSummaryLoadSuccess(companyUsers as UserSummaryFromResponse[]),
    );
  } catch (err) {
    yield put(
      actions.userSummaryLoadError(
        'Failed to fetch company users',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(USER_SUMMARY_LOAD_REQUEST, loadUserSummaryListing)];
}
