import { showErrorMessage } from 'src/store/actions/error';
import {
  CertificateListingLoadErrorAction,
  CertificateListingLoadRequestAction,
  CertificateListingLoadSuccessAction,
  CertificateListingResponse,
  CERTIFICATE_LISTING_LOAD_FAILED,
  CERTIFICATE_LISTING_LOAD_REQUEST,
  CERTIFICATE_LISTING_LOAD_SUCCESS,
} from '../types';

export const certificateListingLoadRequest =
  (): CertificateListingLoadRequestAction => ({
    type: CERTIFICATE_LISTING_LOAD_REQUEST,
  });

export const certificateListingLoadSuccess = (
  certificateListing: CertificateListingResponse,
): CertificateListingLoadSuccessAction => ({
  type: CERTIFICATE_LISTING_LOAD_SUCCESS,
  certificateListing,
});

export const certificateListingLoadError = (
  errorMessage: string,
  originalError?: Error,
): CertificateListingLoadErrorAction =>
  showErrorMessage(
    CERTIFICATE_LISTING_LOAD_FAILED,
    errorMessage,
    originalError,
  );
