import React, { FC } from 'react';
import { AnswerData, Answer } from 'src/store/types';

interface VideoHelpProps {
  onChange: (text: string) => void;
  value: string;
  updateAnswer: (answerData: AnswerData) => void;
  answer: Answer;
}

export const VideoHelp: FC<VideoHelpProps> = ({
  onChange,
  value,
  updateAnswer,
  answer,
}) => {
  return <p>(This interview has a video setup)</p>;
};
