import { AuthMethod } from 'src/types/common';
import {
  LoginRequestAction,
  LoginSuccessAction,
  LoginErrorAction,
  LogoutRequestAction,
  LogoutSuccessAction,
  LogoutErrorAction,
  LoginCheckAction,
  ResetPasswordErrorAction,
  ResetPasswordSuccessAction,
  ResetPasswordRequestAction,
  UserUpdateRequestAction,
  UserUpdateSuccessAction,
  UserUpdateErrorAction,
  UserSaveRequestAction,
  UserSaveSuccessAction,
  UserSaveErrorAction,
  Get2FACodeErrorAction,
  Get2FACodeSuccessAction,
  Get2FACodeRequestAction,
  TurnOn2FASuccessAction,
  TurnOn2FAErrorAction,
  TurnOn2FARequestAction,
  Auth2FARequestAction,
  Auth2FASuccessAction,
  Auth2FAErrorAction,
  MultipleUsersSaveRequestAction,
  MultipleUsersSaveSuccessAction,
  MultipleUsersSaveErrorAction,
  Clear2FARequestAction,
  Clear2FASuccessAction,
  Clear2FAErrorAction,
  UserDeleteErrorAction,
  UserDeleteSuccessAction,
  UserDeleteRequestAction,
  GetAdminUsersRequestAction,
  GetAdminUsersSuccessAction,
  GetAdminUsersErrorAction,
  UserUpdateCollapsedSiderPreferenceRequestAction,
  UserUpdateCollapsedSiderPreferenceSuccessAction,
  UserUpdateCollapsedSiderPreferenceErrorAction,
  ImpersonateRequestAction,
  ImpersonateSuccessAction,
  ImpersonateErrorAction,
  GetUserOnboardingRequestAction,
  GetUserOnboardingSuccessAction,
  GetUserOnboardingErrorAction,
  UserCompleteOnboardingRequestAction,
  UserCompleteOnboardingSuccessAction,
  UserCompleteOnboardingErrorAction,
  UserUpdateInput,
  UserInput,
  UserOnboardingFromResponse,
  UserFromResponse,
  User,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  LOGIN_CHECK,
  LOGIN_CHECK_COMPLETED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_ERROR,
  USER_UPDATE_SUCCESS,
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS,
  USER_SAVE_ERROR,
  GET_2FA_CODE_ERROR,
  GET_2FA_CODE_SUCCESS,
  GET_2FA_CODE_REQUEST,
  TURN_ON_2FA_REQUEST,
  TURN_ON_2FA_ERROR,
  TURN_ON_2FA_SUCCESS,
  AUTH_2FA_ERROR,
  AUTH_2FA_SUCCESS,
  AUTH_2FA_REQUEST,
  MULTIPLE_USERS_SAVE_ERROR,
  MULTIPLE_USERS_SAVE_SUCCESS,
  MULTIPLE_USERS_SAVE_REQUEST,
  CLEAR_2FA_ERROR,
  CLEAR_2FA_REQUEST,
  CLEAR_2FA_SUCCESS,
  USER_DELETE_ERROR,
  USER_DELETE_SUCCESS,
  USER_DELETE_REQUEST,
  GET_ADMIN_USERS_REQUEST,
  GET_ADMIN_USERS_SUCCESS,
  GET_ADMIN_USERS_ERROR,
  USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_REQUEST,
  USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_SUCCESS,
  USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_ERROR,
  IMPERSONATE_REQUEST,
  IMPERSONATE_SUCCESS,
  IMPERSONATE_ERROR,
  GET_USER_ONBOARDING_REQUEST,
  GET_USER_ONBOARDING_SUCCESS,
  GET_USER_ONBOARDING_ERROR,
  USER_COMPLETE_ONBOARDING_REQUEST,
  USER_COMPLETE_ONBOARDING_SUCCESS,
  USER_COMPLETE_ONBOARDING_ERROR,
} from '../types';

import { showErrorMessage } from './error';

export const loginRequest = (
  email: string,
  password: string,
): LoginRequestAction => ({
  type: LOGIN_REQUEST,
  email,
  password,
});

export const loginSuccess = (user: User): LoginSuccessAction => ({
  type: LOGIN_SUCCESS,
  user: user,
});

export const loginError = (
  errorMessage: string,
  originalError: Error,
): LoginErrorAction =>
  showErrorMessage(LOGIN_ERROR, errorMessage, originalError);

export const impersonateRequest = (
  email: string,
): ImpersonateRequestAction => ({
  type: IMPERSONATE_REQUEST,
  email,
});

export const impersonateSuccess = (user: User): ImpersonateSuccessAction => ({
  type: IMPERSONATE_SUCCESS,
  user: user,
});

export const impersonateError = (
  errorMessage: string,
  originalError: Error,
): ImpersonateErrorAction =>
  showErrorMessage(IMPERSONATE_ERROR, errorMessage, originalError);

export const get2FACodeRequest = (): Get2FACodeRequestAction => ({
  type: GET_2FA_CODE_REQUEST,
});

export const get2FACodeSuccess = (code: string): Get2FACodeSuccessAction => ({
  type: GET_2FA_CODE_SUCCESS,
  code,
});

export const Get2FACodeError = (
  errorMessage: string,
  originalError: Error,
): Get2FACodeErrorAction =>
  showErrorMessage(GET_2FA_CODE_ERROR, errorMessage, originalError);

export const turnOn2FARequest = (
  code: string,
  authMethod: AuthMethod,
  dialCode?: string,
  phoneNumber?: string,
): TurnOn2FARequestAction => ({
  type: TURN_ON_2FA_REQUEST,
  code,
  authMethod,
  dialCode,
  phoneNumber,
});

export const turnOn2FASuccess = (
  isTwoFactorAuthenticationEnabled: boolean,
  user: User,
): TurnOn2FASuccessAction => ({
  type: TURN_ON_2FA_SUCCESS,
  isTwoFactorAuthenticationEnabled,
  user,
});

export const turnOn2FAError = (
  errorMessage: string,
  originalError: Error,
): TurnOn2FAErrorAction =>
  showErrorMessage(TURN_ON_2FA_ERROR, errorMessage, originalError);

export const auth2FARequest = (
  code: string,
  authMethod: AuthMethod,
): Auth2FARequestAction => ({
  type: AUTH_2FA_REQUEST,
  code,
  authMethod,
});

export const auth2FASuccess = (user: User): Auth2FASuccessAction => ({
  type: AUTH_2FA_SUCCESS,
  user,
});

export const auth2FAError = (
  errorMessage: string,
  originalError: Error,
): Auth2FAErrorAction =>
  showErrorMessage(AUTH_2FA_ERROR, errorMessage, originalError);

export const logoutRequest = (): LogoutRequestAction => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = (): LogoutSuccessAction => ({
  type: LOGOUT_SUCCESS,
});

export const logoutError = (
  errorMessage: string,
  originalError: Error,
): LogoutErrorAction =>
  showErrorMessage(LOGOUT_ERROR, errorMessage, originalError);

export const loginCheck = (): LoginCheckAction => ({
  type: LOGIN_CHECK,
});

export const loginCheckCompleted = (): LoginCheckAction => ({
  type: LOGIN_CHECK_COMPLETED,
});

export const resetPasswordRequest = (
  username: string,
): ResetPasswordRequestAction => ({
  type: RESET_PASSWORD_REQUEST,
  username,
});

export const resetPasswordSuccess = (): ResetPasswordSuccessAction => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordError = (
  errorMessage: string,
  originalError: Error,
): ResetPasswordErrorAction =>
  showErrorMessage(RESET_PASSWORD_ERROR, errorMessage, originalError);

export const userUpdateRequest = (
  userInput: UserUpdateInput,
  authToken?: string,
  updateUserData?: boolean,
): UserUpdateRequestAction => ({
  type: USER_UPDATE_REQUEST,
  userInput,
  authToken,
  updateUserData,
});

export const userUpdateSuccess = (user: User): UserUpdateSuccessAction => ({
  type: USER_UPDATE_SUCCESS,
  user,
});

export const userUpdateError = (
  errorMessage: string,
  originalError: Error,
): UserUpdateErrorAction =>
  showErrorMessage(USER_UPDATE_ERROR, errorMessage, originalError);

export const userSaveRequest = (user: UserInput): UserSaveRequestAction => ({
  type: USER_SAVE_REQUEST,
  user,
});

export const userSaveSuccess = (
  user: UserFromResponse,
): UserSaveSuccessAction => ({
  type: USER_SAVE_SUCCESS,
  user,
});

export const userSaveError = (
  errorMessage: string,
  originalError: Error,
): UserSaveErrorAction =>
  showErrorMessage(USER_SAVE_ERROR, errorMessage, originalError);

export const userDeleteRequest = (id: number): UserDeleteRequestAction => ({
  type: USER_DELETE_REQUEST,
  id,
});

export const userDeleteSuccess = (id: number): UserDeleteSuccessAction => ({
  type: USER_DELETE_SUCCESS,
  id,
});

export const userDeleteError = (
  errorMessage: string,
  originalError: Error,
): UserDeleteErrorAction =>
  showErrorMessage(USER_DELETE_ERROR, errorMessage, originalError);

export const multipleUsersSaveRequest = (
  users: UserInput[],
): MultipleUsersSaveRequestAction => ({
  type: MULTIPLE_USERS_SAVE_REQUEST,
  users,
});

export const multipleUsersSaveSuccess = (
  users: UserFromResponse[],
): MultipleUsersSaveSuccessAction => ({
  type: MULTIPLE_USERS_SAVE_SUCCESS,
  users,
});

export const multipleUsersSaveError = (
  errorMessage: string,
  originalError: Error,
): MultipleUsersSaveErrorAction =>
  showErrorMessage(MULTIPLE_USERS_SAVE_ERROR, errorMessage, originalError);

export const clear2FARequest = (userId: number): Clear2FARequestAction => ({
  type: CLEAR_2FA_REQUEST,
  userId,
});

export const clear2FASuccess = (userId: number): Clear2FASuccessAction => ({
  type: CLEAR_2FA_SUCCESS,
  userId,
});

export const clear2FAError = (
  errorMessage: string,
  originalError: Error,
): Clear2FAErrorAction =>
  showErrorMessage(CLEAR_2FA_ERROR, errorMessage, originalError);

export const getAdminUsers = (): GetAdminUsersRequestAction => ({
  type: GET_ADMIN_USERS_REQUEST,
});

export const getAdminUsersSuccess = (
  users: UserFromResponse[],
): GetAdminUsersSuccessAction => ({
  type: GET_ADMIN_USERS_SUCCESS,
  users,
});

export const getAdminUsersError = (
  errorMessage: string,
  originalError: Error,
): GetAdminUsersErrorAction =>
  showErrorMessage(GET_ADMIN_USERS_ERROR, errorMessage, originalError);

export const updateCollapsedSiderPreferenceRequest = (
  collapsedSider: boolean,
): UserUpdateCollapsedSiderPreferenceRequestAction => ({
  type: USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_REQUEST,
  collapsedSider,
});

export const updateCollapsedSiderPreferenceSuccess = (
  collapsedSider: boolean,
): UserUpdateCollapsedSiderPreferenceSuccessAction => ({
  type: USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_SUCCESS,
  collapsedSider,
});

export const updateCollapsedSiderPreferenceError = (
  errorMessage: string,
  originalError: Error,
): UserUpdateCollapsedSiderPreferenceErrorAction =>
  showErrorMessage(
    USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_ERROR,
    errorMessage,
    originalError,
  );

export const getUserOnboardingRequest = (
  id: number,
): GetUserOnboardingRequestAction => ({
  type: GET_USER_ONBOARDING_REQUEST,
  id,
});

export const getUserOnboardingSuccess = (
  user: UserOnboardingFromResponse,
): GetUserOnboardingSuccessAction => ({
  type: GET_USER_ONBOARDING_SUCCESS,
  user,
});

export const getUserOnboardingError = (
  message: string,
): GetUserOnboardingErrorAction => ({
  type: GET_USER_ONBOARDING_ERROR,
  message,
});

export const completeUserOnboardingRequest = (
  id: number,
): UserCompleteOnboardingRequestAction => ({
  type: USER_COMPLETE_ONBOARDING_REQUEST,
  id,
});

export const completeUserOnboardingSuccess = (
  user: UserFromResponse,
): UserCompleteOnboardingSuccessAction => ({
  type: USER_COMPLETE_ONBOARDING_SUCCESS,
  user,
});

export const completeUserOnboardingError = (
  errorMessage: string,
  originalError: Error,
): UserCompleteOnboardingErrorAction =>
  showErrorMessage(USER_COMPLETE_ONBOARDING_ERROR, errorMessage, originalError);
