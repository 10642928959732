import { TrainingRawResponse, Training } from 'src/store/types';
import { mapRawQuestions } from '../mapRawQuestions';

export const mapTrainingRawResponse = (
  rawResponse: TrainingRawResponse,
): Training => {
  const {
    id,
    name,
    chapter,
    minutes,
    videoId,
    trainingTypeId,
    trainingTypeName,
    testTypeId,
    quizId,
    statusType,
    questions,
    choices,
    hasSubmissions,
    nextTrainingId,
    lastSubmissionStatus,
  } = rawResponse;

  return {
    id,
    name,
    chapter,
    minutes,
    videoId,
    trainingTypeId,
    trainingTypeName,
    testTypeId,
    quizId,
    statusType,
    hasSubmissions,
    nextTrainingId,
    lastSubmissionStatus,
    status: rawResponse.statusType,
    questions: mapRawQuestions(questions, choices),
  } as Training;
};
