import React, { FC } from 'react';
import { Table, Empty } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Grid, GridTag } from 'src/components/Grid';
import { FormState, InterviewListingIndexedItem } from 'src/store/types';
import { EyeOutlined } from 'src/theme/icons';
import { dataMapper } from './dataMapper';
import { AssessmentFormListingTableSettings } from '../AssessmentFormListingTableSettings';

export interface AssessmentFormListingTableProps {
  interviews?: InterviewListingIndexedItem;
  forms?: FormState[];
  companyId: number;
  assessmentId: number;
}

export interface TableRowInterface {
  id: number;
  interviewId: number;
  formTitle: string;
  completionDate: string;
  timeSpent: string;
  status: 'Completed' | 'Started' | 'Not Started';
}

const { Column } = Table;

const Options = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  .icon {
    font-size: 20px;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #e0e0e0;
  }
`;

export const AssessmentFormListingTable: FC<
  AssessmentFormListingTableProps
> = ({ forms, interviews, companyId, assessmentId }) => {
  const dataSource: TableRowInterface[] = dataMapper(forms, interviews);
  return (
    <Grid
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
    >
      <Column<TableRowInterface>
        title="Form Title"
        dataIndex="formTitle"
        key="formTitle"
        width="30%"
      />
      <Column<TableRowInterface>
        title="Completion Date"
        dataIndex="completionDate"
        key="completionDate"
        width="20%"
      />
      <Column<TableRowInterface>
        title="Time Spent"
        dataIndex="timeSpent"
        key="timeSpent"
      />
      <Column<TableRowInterface>
        title="Status"
        dataIndex="status"
        key="status"
        render={(_, record: TableRowInterface) =>
          record.status === 'Completed' ? (
            <GridTag type="active">Completed</GridTag>
          ) : (
            <GridTag type="inactive">{record.status}</GridTag>
          )
        }
      />
      <Column<TableRowInterface>
        title={false}
        dataIndex=""
        key="action"
        width="10%"
        render={(_, record: TableRowInterface) => (
          <Options>
            <Link
              to={`/companies/${companyId}/assessments/${assessmentId}/forms/${record.id}/interview/${record.interviewId}`}
              className="icon"
              title="View Interview"
            >
              <EyeOutlined />
            </Link>
            <AssessmentFormListingTableSettings
              formId={record.id}
              interviewId={record.interviewId}
            />
          </Options>
        )}
      />
    </Grid>
  );
};
