import { ReactNode } from 'react';

export enum AuthMethod {
  APP = 'app',
  SMS = 'sms',
}

export enum TwoFaSteps {
  AUTH = 'authentication',
  VERIFY = 'verification',
}

export interface TabStep {
  id: string | number;
  title: string;
  description?: string;
}

export interface CountryCode {
  name: string;
  dialCode: string;
  code: string;
}

export interface SelectOption {
  value: string;
  label: ReactNode;
}

export interface BodySMS {
  dialCode: string;
  phoneNumber: string;
}

export interface DecodedToken {
  exp: number;
  iat: number;
}
