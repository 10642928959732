import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCurrentTrainingPeriod } from 'src/store/selectors';
import { TrainingPeriodResponse } from 'src/store/types';
import moment from 'moment';

const canBeReleasedTodayOrEarlier = (releaseDate: Date): boolean => {
  const today = new Date();
  const releaseDateFormatted = moment(releaseDate).startOf('day'); // Set the releaseDate to start of the day (00:00:00).
  const todayFormatted = moment(today).startOf('day'); // Set today's date to start of the day (00:00:00).

  return releaseDateFormatted.isSameOrBefore(todayFormatted);
};

export const useTraining = (): {
  isTrainingPeriodReleaseOverlay: boolean;
  currentTrainingPeriod: TrainingPeriodResponse;
} => {
  const currentTrainingPeriod = useSelector(getCurrentTrainingPeriod);
  const location = useLocation();
  const releaseDate = new Date(currentTrainingPeriod?.releaseDate);
  const canTrainingBeReleasedToday: boolean =
    canBeReleasedTodayOrEarlier(releaseDate);
  const isTrainingPeriodReleaseOverlay: boolean = !currentTrainingPeriod
    ? true && location.pathname.includes('my-training')
    : !canTrainingBeReleasedToday && location.pathname.includes('my-training');

  return { isTrainingPeriodReleaseOverlay, currentTrainingPeriod };
};
