import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { ProgressBarProps } from './ProgressBar';

export const MainContainer = styled.div<Partial<ProgressBarProps>>`
  display: flex;
  flex-direction: column;
  width: ${({ short }) => (short ? `${remConvert(312)}px` : '80%')};
  height: ${remConvert(13)};

  .ant-progress-inner {
    background-color: ${({ theme }) => theme.colors.alto.main};
  }

  .ant-progress-bg {
    background-color: ${({ theme }) => theme.colors.astral.main};
  }

  span {
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    color: ${({ theme }) => ''};
    font-size: 12px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  strong {
    font-weight: ${({ theme }): number => theme.fontWeights.semiBold};
    margin-right: ${remConvert(8)};
  }

  .end {
    align-items: flex-end;
    display: flex;
    margin-left: auto;
  }
`;

export const TextContainer = styled.div`
  display: flex;
`;

export const ProgressContainer = styled.div`
  display: flex;

  .end {
    width: ${remConvert(15)};
    margin-left: ${remConvert(5)};
  }
`;
