import download from 'downloadjs';
import { HttpClient } from 'src/api/services/HttpClient';

class CertificateService extends HttpClient {
  public constructor() {
    super(`${process.env.REACT_APP_API_URL}certificate`);
  }

  public generate = async (
    examSubmissionId: number,
    userName: string,
    trainingTypeName: string,
  ): Promise<void> => {
    const res = await this.instance.get(
      `/exam-submission/${examSubmissionId}`,
      {
        withCredentials: true,
        responseType: 'blob',
      },
    );
    download(res, `${trainingTypeName} - ${userName}.pdf`, 'application/pdf');
  };
}

export default new CertificateService();
