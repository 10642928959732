import { call, takeEvery, put } from 'redux-saga/effects';
import {
  FORM_LOAD_QUESTIONS_REQUEST,
  FormLoadQuestionsRequestAction,
} from 'src/store/types/form';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';
import { Question } from '../types';

export const loadQuestions = function* async({
  formId,
  assessmentId,
}: FormLoadQuestionsRequestAction): Generator {
  try {
    const questions = yield call(api.loadQuestions, formId, assessmentId);
    yield put(actions.formLoadQuestionsSuccess(questions as Question[]));
  } catch (err: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (err?.response?.status === 404) {
      yield put(actions.formLoadQuestionsSuccess([]));
      return;
    }
    yield put(
      actions.formLoadQuestionsError('Failed to load questions', err as Error),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(FORM_LOAD_QUESTIONS_REQUEST, loadQuestions)];
}
