/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createSelector } from 'reselect';
import {
  RootState,
  Thread,
  ThreadListingFilter,
  ThreadListingIndexedItem,
  ThreadListingRelationFilter,
  ThreadListingSortOrder,
  CommentFromResponse,
  Loader,
  FormState,
} from '../types';

export const getLoadedThreads = (
  state: RootState,
): ThreadListingIndexedItem => {
  return state.threadListing.threads;
};

export const getThreadListingLoader = (state: RootState): Loader => {
  return state.threadListing.loader;
};

export const getThreadsByQuestionId = createSelector(
  (state: RootState) => getLoadedThreads(state),
  (threads: ThreadListingIndexedItem): ThreadListingIndexedItem =>
    Object.values(threads).reduce(
      (threadsByQuestion: ThreadListingIndexedItem, thread) => {
        return thread.question?.id
          ? {
              ...threadsByQuestion,
              [thread.question.id]: thread,
            }
          : threadsByQuestion;
      },
      {},
    ),
);

export const getThreadsByRefKey = createSelector(
  (state: RootState) => getLoadedThreads(state),
  (threads: ThreadListingIndexedItem): ThreadListingIndexedItem =>
    Object.values(threads).reduce(
      (threadsByRefKey: ThreadListingIndexedItem, thread) => {
        return thread.refKey
          ? {
              ...threadsByRefKey,
              [thread.refKey]: thread,
            }
          : threadsByRefKey;
      },
      {},
    ),
);

export const getThreadById = (state: RootState, id: number): Thread => {
  return state.threadListing.threads[id];
};

const hasAnyAdminNote = (comments: CommentFromResponse[]): boolean => {
  if (!comments) {
    return false;
  }
  return !!comments.filter((c: CommentFromResponse) => {
    let isAdmin = c.adminOnly;
    if (c.children) {
      isAdmin =
        isAdmin ||
        !!c.children.filter((nestedComment: CommentFromResponse) => {
          return nestedComment.adminOnly;
        }).length;
    }
    return isAdmin;
  }).length;
};

const hasAnyPublicNote = (comments: CommentFromResponse[]): boolean => {
  if (!comments) {
    return false;
  }
  return !!comments.filter((c: CommentFromResponse) => {
    let isPublic = !c.adminOnly;
    if (c.children) {
      isPublic =
        isPublic ||
        !!c.children.filter((nestedComment: CommentFromResponse) => {
          return !nestedComment.adminOnly;
        }).length;
    }
    return isPublic;
  }).length;
};

const hasAnyFilesAttached = (comments: CommentFromResponse[]): boolean => {
  if (!comments) {
    return false;
  }
  return !!comments.filter((c: CommentFromResponse) => {
    let haveFiles = c.files && !!c.files.length;
    if (c.children) {
      haveFiles =
        haveFiles ||
        !!c.children.filter((nestedComment: CommentFromResponse) => {
          return nestedComment.files && !!nestedComment.files.length;
        }).length;
    }
    return haveFiles;
  }).length;
};

const getThreadsFiltered = (
  threads: ThreadListingIndexedItem,
  filter: (comments: CommentFromResponse[]) => boolean,
): ThreadListingIndexedItem => {
  if (threads) {
    const filtered = Object.keys(threads)
      .filter((key: string) => {
        const t = threads[key];
        return filter.call(null, t.comments as any);
      })
      .reduce((obj: ThreadListingIndexedItem, key: string) => {
        obj[key] = threads[key];
        return obj;
      }, {});
    return filtered;
  }
  return {};
};

const removeAdminNotesFromThreads = (
  threads: ThreadListingIndexedItem,
): ThreadListingIndexedItem => {
  const threadsWithOnlyPublicComments = Object.values(threads).map(
    (t: Thread) => {
      const publicComments = t.comments.reduce(
        (tempComments: CommentFromResponse[], c: CommentFromResponse) => {
          const publicChildrenComments: CommentFromResponse[] = [];
          if (c.children) {
            publicChildrenComments.push(
              ...c.children.filter((c: CommentFromResponse) => !c.adminOnly),
            );
          }
          if (c.adminOnly) {
            return [...tempComments, ...publicChildrenComments];
          } else {
            if (c.children) {
              return [
                ...tempComments,
                { ...c, children: publicChildrenComments },
              ];
            }
            return [...tempComments, c];
          }
        },
        [] as CommentFromResponse[],
      );
      return { ...t, comments: publicComments };
    },
  );
  return threadsWithOnlyPublicComments.reduce(
    (tempThreadListingIndexedItem: ThreadListingIndexedItem, t: Thread) => {
      return {
        ...tempThreadListingIndexedItem,
        [t.id]: t,
      };
    },
    {},
  );
};

const getThreadsByRelation = (
  threads: ThreadListingIndexedItem,
  relation: ThreadListingRelationFilter,
) => {
  const filterKey = relation.fromAssessment ? 'assessment' : 'documentDraft';
  const threadKeys = Object.keys(threads).filter(
    (t) => !!threads[t][filterKey],
  );
  return threadKeys.reduce(
    (threadMap, threadKey) => ({
      ...threadMap,
      [threadKey]: threads[threadKey],
    }),
    {},
  );
};

export const getLoadedThreadsSorted = createSelector(
  (state: RootState) => getLoadedThreads(state),
  (_: RootState, relation: ThreadListingRelationFilter) => relation,
  (
    _: RootState,
    _relation: ThreadListingRelationFilter,
    filter: ThreadListingFilter,
  ) => filter,
  (
    _: RootState,
    _relation: ThreadListingRelationFilter,
    _filter: ThreadListingFilter,
    isAdmin: boolean,
  ) => isAdmin,
  (
    _: RootState,
    _relation: ThreadListingRelationFilter,
    _filter: ThreadListingFilter,
    _isAdmin: boolean,
    sortOrder?: ThreadListingSortOrder,
  ) => sortOrder ?? ThreadListingSortOrder.id,

  (
    _: RootState,
    _relation: ThreadListingRelationFilter,
    _filter: ThreadListingFilter,
    _isAdmin: boolean,
    _sortOrder?: ThreadListingSortOrder,
    formList?: FormState[],
  ) => formList,
  (
    threads: ThreadListingIndexedItem,
    relation: ThreadListingRelationFilter,
    filter: ThreadListingFilter,
    isAdmin: boolean,
    sortOrder: ThreadListingSortOrder,
    formList?: FormState[],
  ) => {
    const threadsByRelation = getThreadsByRelation(threads, relation);
    let filtered: ThreadListingIndexedItem;
    switch (filter) {
      case ThreadListingFilter.admin:
        filtered = getThreadsFiltered(threadsByRelation, hasAnyAdminNote);
      case ThreadListingFilter.files:
        filtered = getThreadsFiltered(threadsByRelation, hasAnyFilesAttached);
      default:
        if (isAdmin) {
          filtered = threadsByRelation;
        } else {
          filtered = removeAdminNotesFromThreads(
            getThreadsFiltered(threadsByRelation, hasAnyPublicNote),
          );
        }
    }
    const threadsData = Object.values(filtered);

    switch (sortOrder) {
      case ThreadListingSortOrder.id:
        return threadsData.sort((t1, t2) => Number(t1.id) - Number(t2.id));
      case ThreadListingSortOrder.questionId:
        if (formList) {
          const questionOrderMap: { [key: number]: number } = {};
          let orderIndex = 0;
          formList.forEach((form) =>
            form.questions?.forEach((question) => {
              if (question.id) questionOrderMap[question.id] = orderIndex++;
            }),
          );
          return threadsData.sort(
            (t1, t2) =>
              questionOrderMap[Number(t1.questionId)] -
              questionOrderMap[Number(t2.questionId)],
          );
        }
        return threadsData.sort(
          (t1, t2) => Number(t1.questionId) - Number(t2.questionId),
        );
    }
  },
);
