import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

export interface HeaderProps {
  sticky?: boolean;
}

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.deepBlue.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 24px;
  line-height: ${remConvert(54)};
  display: flex;
  align-items: center;
  color: rgba(28, 36, 40, 0.85);
`;

export const SubTitle = styled.span`
  color: ${({ theme }) => theme.colors.grayscale.middleGray};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin-left: ${remConvert(16)};
  font-size: 22px;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colors.black.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 16px;
  margin-top: 10px;
`;

export const ExtraOptionsContainer = styled.div`
  align-items: center;
  display: flex;

  > button {
    margin: ${remConvert(0)} ${remConvert(5)};
  }
`;

export const Header = styled.div<HeaderProps>`
  background-color: ${({ theme }): string => theme.colors.grayscale.lightGray};
  height: auto;
  width: 100%;
  z-index: 1;
  position: relative;
  padding: 0px !important;
  top: 0px;
  position: ${({ sticky }): string => (sticky ? 'sticky' : 'relative')};

  .anticon {
    margin-left: ${remConvert(10)};
  }
`;
