import React, { FC } from 'react';
import { HelpSection } from './HelpSection';

interface FootnoteHelpSectionProps {
  templateFootnoteHelpOpen: boolean;
  setTemplateFootnoteHelpOpen: (value: boolean) => void;
}

export const FootnoteHelpSection: FC<FootnoteHelpSectionProps> = ({
  templateFootnoteHelpOpen,
  setTemplateFootnoteHelpOpen,
}) => (
  <HelpSection
    isOpen={templateFootnoteHelpOpen}
    setIsOpen={setTemplateFootnoteHelpOpen}
    title={'Footnotes'}
  >
    <h4>Overview</h4>
    <p>
      A <em>footnote</em> has the following syntax:{' '}
      <code>[footnote:content]</code>, where <code>content</code> is replaced by
      the text you want to be associated with the footnote.
    </p>
    <h4>Example</h4>
    <p>
      <code>
        For complex behavior you should consider the use of
        functions[footnote:Utility for text processing in templates]
      </code>
    </p>
    <p>
      This will set{' '}
      <code>
        For complex behavior you should consider the use of functions
        <sup>1</sup>
      </code>{' '}
      as part of the document while referencing to an endnote that says:{' '}
      <code>Utility for text processing in templates</code>.
    </p>
  </HelpSection>
);
