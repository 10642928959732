import { Modal } from 'antd';
import { UsergroupAddOutlined } from 'src/theme/icons';
import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

export const QuestionUserAssigner = styled(UsergroupAddOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.astral.main};
`;

export const UserAssignerContainer = styled.div`
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.astral.main};

  .assignButtonText {
    padding-left: ${remConvert(10)};
  }
`;

export const StyledModal = styled(Modal)`
  border-radius: ${remConvert(15)};

  .ant-modal-content {
    border-radius: ${remConvert(15)};
    box-shadow: ${remConvert(0)} ${remConvert(4)} ${remConvert(4)}
      rgba(0, 0, 0, 0.25);
  }

  .ant-modal-title {
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-style: ${({ theme }) => theme.fontStyles.normal};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    color: ${({ theme }) => theme.colors.deepBlue.main};
    line-height: ${remConvert(27)};
    font-size: 20px;
  }

  .ant-modal-body {
    font-family: ${({ theme }) => theme.fontFamilies.terciary};
    font-style: ${({ theme }) => theme.fontStyles.normal};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${remConvert(20)};
    font-size: 13px;
    color: rgba(0, 0, 0, 0.8);
  }

  .ant-modal-header,
  .ant-modal-footer {
    border-radius: ${remConvert(15)};
  }

  .ant-modal-header {
    border-bottom: ${remConvert(0)};
  }

  .ant-modal-footer {
    border-top: ${remConvert(0)};

    .ant-btn {
      border: ${remConvert(0)};
      font-family: ${({ theme }) => theme.fontFamilies.primary};
      font-style: ${({ theme }) => theme.fontStyles.normal};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      line-height: ${remConvert(20)};
      font-size: 15px;
      text-align: center;

      span {
        text-decoration: underline;
      }
    }

    .ant-btn-primary {
      background: linear-gradient(
        84.58deg,
        ${({ theme }) => theme.colors.astral.main} 12.28%,
        #3e8fac 79.73%
      );
      box-shadow: ${remConvert(0)} ${remConvert(2)} ${remConvert(4)}
        rgba(0, 0, 0, 0.2);
      border-radius: ${remConvert(4)};

      span {
        text-decoration: none;
      }
    }
  }
`;

export const AutocompleteTitleContainer = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.grayscale.darkGray};
  line-height: ${remConvert(22)};
  margin-bottom: ${remConvert(8)};
  font-size: 16px;
`;
