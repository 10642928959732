import React, { FC, useState } from 'react';
import { ToolbarItem } from './ToolbarItem';
import { TemplateEditorQuestionSelectorWindow } from './TemplateEditorSelectorWindows';
import { RegExpToken } from '../useRegExpTokens';

interface QuestionToolbarItemProps {
  questionToken: RegExpToken;
  updateEditorContent: (val: string) => void;
}

export const QuestionToolbarItem: FC<QuestionToolbarItemProps> = ({
  questionToken,
  updateEditorContent,
}) => {
  const [childrenVisible, setChildrenVisible] = useState<boolean>(false);

  const execute = (selectedId: string) => {
    if (selectedId?.length > 0) {
      const textToAdd = questionToken.getToken(selectedId);
      updateEditorContent(textToAdd);
    }
    // setChildrenVisible(false);
  };

  return (
    <ToolbarItem
      icon={
        <svg width="12" height="12" viewBox="0 0 135.46666 135.46667">
          <g>
            <g transform="matrix(1.8416256,0,0,3.6983265,-41.706689,-148.36772)">
              <path d="m 94.162183,54.625617 h -6.482521 l -2.605317,5.408851 h 5.785034 l -0.779543,4.174034 h -7.015892 l -3.015603,6.156699 h -4.943948 l 2.974575,-6.156699 h -5.415777 l -2.974574,6.156699 h -5.026005 l 2.995089,-6.156699 h -5.538863 l 0.779544,-4.174034 h 6.769721 l 2.584802,-5.408851 h -6.010691 l 0.779543,-4.156642 h 7.24155 l 2.872002,-6.000173 h 5.026005 l -2.872003,6.000173 h 5.395263 l 2.892517,-6.000173 h 5.026004 l -2.892517,6.000173 h 5.23115 z m -11.405954,-0.03479 h -5.497834 l -2.666859,5.495811 h 5.518348 z" />
              <g transform="scale(1.2941741,0.77269357)">
                <path d="m 39.933729,66.398409 q -0.667616,-0.322298 -1.749615,-0.575531 -1.081998,-0.276255 -1.910762,-0.276255 -2.210039,0 -3.752463,0.943871 -1.519401,0.94387 -2.509315,2.371187 -1.012934,1.404296 -1.588465,3.361101 -0.55251,1.956806 -0.55251,4.07476 0,2.140975 0.943871,3.315058 0.943871,1.174083 3.430165,1.174083 1.12804,0 2.371187,-0.506467 1.266168,-0.506467 2.394209,-1.197104 z m 9.737984,-6.031565 -8.17254,35.337603 h -8.333689 l 2.78557,-12.017087 q -0.989913,0.621573 -2.140975,1.335232 -1.128041,0.690637 -1.956806,1.035955 -1.174083,0.506468 -2.210039,0.690638 -1.035955,0.184169 -2.23306,0.184169 -3.752462,0 -6.008543,-2.440251 -2.233061,-2.440251 -2.233061,-6.929393 0,-3.867568 1.266169,-7.159606 1.266168,-3.292037 3.361101,-5.709267 2.094932,-2.394209 4.83446,-3.70642 2.739527,-1.335232 5.548119,-1.335232 2.094933,0 3.729441,0.506468 1.657529,0.483446 3.107867,1.358253 l 0.575531,-1.151062 z" />
              </g>
            </g>
          </g>
        </svg>
      }
      childrenVisible={childrenVisible}
      setChildrenVisible={setChildrenVisible}
      tooltip="Question Tokens"
    >
      <TemplateEditorQuestionSelectorWindow
        onSelection={(id: number) => execute(`${id}`)}
        onClose={() => setChildrenVisible(false)}
      />
    </ToolbarItem>
  );
};
