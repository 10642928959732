import styled from 'styled-components';
import { Button } from 'src/components/Button';
import { InputProps, TextInput } from 'src/components/TextInput';
import { remConvert } from 'src/theme/utils/rem-convert';
import { ButtonProps } from 'antd/es/button';

export const FormFieldsContainer = styled.div`
  padding-top: ${remConvert(32)};
`;

export const FormSubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${remConvert(0)};
`;

export const LoginTextInput: React.FC<InputProps> = styled(TextInput)`
  margin-bottom: ${remConvert(25)};

  .ant-input-prefix {
    display: none;
  }
`;

export const LoginSubmitButton: React.FC<ButtonProps> = styled(Button)`
  width: ${remConvert(186)};
`;
