import { useContext } from 'react';
import { TenantContext } from 'src/shared/providers/TenantProvider';

const useTenantContext = () => {
  const tenantContext = useContext(TenantContext);

  if (!tenantContext) {
    throw new Error('useTenantContext can only be used inside TenantProvider');
  }

  return {
    ...tenantContext,
  };
};

export default useTenantContext;
