import { Form, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { TextInput } from 'src/components/TextInput';
import { Button } from 'src/components/Button';
import actions from 'src/store/actions';
import { getTrainingLoader } from 'src/store/selectors';
import {
  TestTypesResponse,
  TrainingConfig,
  Training,
  TrainingTypesResponse,
} from 'src/store/types';

import { SavingPill } from '../SavingPill';

interface TrainingConfigFormProps {
  training?: Training;
  testTypes: TestTypesResponse;
  trainingTypes: TrainingTypesResponse;
  onNameChanged?: (name: string) => void;
}

const FormContainer = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 320px;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
    }
  }
`;

const TrainingConfigForm = ({
  training,
  testTypes,
  trainingTypes,
  onNameChanged,
}: TrainingConfigFormProps) => {
  const dispatch = useDispatch();
  const loader = useSelector(getTrainingLoader);
  const [form] = Form.useForm();

  useEffect(() => {
    if (training) {
      const { name, chapter, videoId, trainingTypeId, testTypeId, minutes } =
        training;
      form.setFieldsValue({
        name,
        chapter: `${chapter}`,
        videoId,
        trainingTypeId,
        testTypeId,
        minutes,
      });
      onNameChanged?.(name);
    }
  }, [training]);

  const handleSubmit = (values: TrainingConfig) => {
    if (training) {
      dispatch(
        actions.trainingUpdateRequest(training.id, {
          ...values,
          chapter: Number(values.chapter),
        }),
      );
    } else {
      dispatch(
        actions.trainingCreateRequest({
          ...values,
          chapter: Number(values.chapter),
        }),
      );
    }
  };

  const debounceSubmitForm = useMemo(
    () =>
      _.debounce(() => {
        form.submit();
      }, 500),
    [],
  );

  return (
    <div>
      <FormContainer
        form={form}
        onFinish={(values) => handleSubmit(values as TrainingConfig)}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please input the training name',
            },
          ]}
        >
          <TextInput
            placeholder="Name"
            onChange={(e) => {
              onNameChanged?.(e.target.value);
              training && debounceSubmitForm();
            }}
          />
        </Form.Item>

        <Form.Item
          name="chapter"
          rules={[
            {
              required: true,
              message: 'Please input the training chapter',
            },
            {
              max: 9,
              message: 'Chapter is too big',
            },
          ]}
        >
          <TextInput
            placeholder="Chapter"
            type="number"
            onChange={training && debounceSubmitForm}
            disabled={training?.status === 'ACTIVE'}
          />
        </Form.Item>

        <Form.Item
          name="videoId"
          rules={[
            {
              required: true,
              whitespace: false,
              message: 'Please input the training vimeo',
            },
          ]}
        >
          <TextInput
            placeholder="Vimeo"
            disabled={training?.status === 'ACTIVE'}
            onChange={training && debounceSubmitForm}
          />
        </Form.Item>
        <Form.Item
          name="minutes"
          rules={[
            {
              required: true,
              message: 'Please input the training duration',
            },
          ]}
        >
          <TextInput
            placeholder="Duration in minutes"
            type="number"
            onChange={training && debounceSubmitForm}
            disabled={training?.status === 'ACTIVE'}
          />
        </Form.Item>
        <Form.Item
          name="trainingTypeId"
          rules={[
            { required: true, message: 'Please select the training type' },
          ]}
        >
          <Select
            placeholder="Select the training type"
            onChange={training && debounceSubmitForm}
            disabled={training?.status === 'ACTIVE'}
          >
            {trainingTypes.map((trainingType) => (
              <Select.Option value={trainingType.id} key={trainingType.id}>
                {trainingType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="testTypeId"
          rules={[{ required: true, message: 'Please select the test type' }]}
        >
          <Select
            placeholder="Select the test type"
            onChange={training && debounceSubmitForm}
            disabled={training?.status === 'ACTIVE'}
          >
            {testTypes.map((testType) => (
              <Select.Option value={testType.id} key={testType.id}>
                {testType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </FormContainer>
      {!training ? (
        <Button
          title="Save Training"
          disabled={loader.loading}
          onClick={() => form.submit()}
        />
      ) : (
        <SavingPill isLoading={loader.loading} />
      )}
    </div>
  );
};

export default TrainingConfigForm;
