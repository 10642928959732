import { mainTheme } from './theme';

export const device = {
  xs: `(min-width: ${mainTheme.breakpoints[480]})`,
  sm: `(min-width: ${mainTheme.breakpoints[576]})`,
  md: `(min-width: ${mainTheme.breakpoints[768]})`,
  lg: `(min-width: ${mainTheme.breakpoints[992]})`,
  xl: `(min-width: ${mainTheme.breakpoints[1200]})`,
  xxl: `(min-width: ${mainTheme.breakpoints[1600]})`,
};
