import React, { FC, ChangeEvent, KeyboardEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'src/components/Button';
import { TextInput } from 'src/components/TextInput';
import { User, Loader } from 'src/store/types';
import { auth2FARequest } from 'src/store/actions/user';
import { remConvert } from 'src/theme/utils';

interface TwoFAFormProps {
  user: User;
  userLoader: Loader;
}

const TwoStepAuthFormContainer = styled.div`
  display: block;
  margin: 0 auto;
`;

const InputWrapper = styled.div`
  margin-bottom: ${remConvert(15)};
  display: flex;
  justify-content: center;
`;

const VerifyButton = styled(Button)`
  margin-top: ${remConvert(15)};
`;

export const TwoFAAuthForm: FC<TwoFAFormProps> = ({ user, userLoader }) => {
  const { authMethod } = user;
  const dispatch = useDispatch();
  const [code, setCode] = useState('');

  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    const newCode = event.target.value;
    setCode(newCode);
  };

  const handleOnSubmit = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (isValidCode) {
        handleVerifyAuthenticationCode();
      }
    }
  };

  const handleVerifyAuthenticationCode = (): void => {
    dispatch(auth2FARequest(code, authMethod));
    setCode('');
  };

  const isValidCode = code.length === 6;

  return (
    <TwoStepAuthFormContainer>
      <InputWrapper>
        <TextInput
          autoFocus
          name="code"
          placeholder="Verification code"
          value={code}
          error={userLoader?.error ? 'Invalid code' : ''}
          onChange={handleChangeCode}
          onKeyPress={handleOnSubmit}
          float
          autoComplete="off"
        />
      </InputWrapper>
      <InputWrapper>
        <VerifyButton
          className="btn"
          title="Verify and sign in"
          disabled={!isValidCode}
          onClick={handleVerifyAuthenticationCode}
        />
      </InputWrapper>
    </TwoStepAuthFormContainer>
  );
};
