import React, { FC } from 'react';
import { Form, FormInstance } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  categoryCreateRequest,
  categoryUpdateRequest,
} from 'src/store/actions/category';
import { TextInput } from '../../../../components/TextInput';

interface FormCreateCategoryProps {
  form: FormInstance;
  categoryToUpdateId?: number;
}

const FormContainer = styled(Form)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
      }
    }
  }
`;

export const FormCreateCategory: FC<FormCreateCategoryProps> = ({
  form,
  categoryToUpdateId,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (values: any): void => {
    if (categoryToUpdateId) {
      dispatch(categoryUpdateRequest(categoryToUpdateId, values.name));
    } else {
      dispatch(categoryCreateRequest(values.name));
    }
  };

  return (
    <FormContainer form={form} onFinish={(values) => handleSubmit(values)}>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please input the category name',
          },
        ]}
      >
        <TextInput placeholder="Category Name" />
      </Form.Item>
    </FormContainer>
  );
};
