import styled from 'styled-components';
import { device } from 'src/theme';

export const SetupContainer = styled.div``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.lg} {
    flex-direction: row;
  }
`;

export const AuthMethodCard = styled.div`
  border: 1px solid;
  width: 260px;
  height: 317px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: relative;
  padding: 20px;
  margin: 0 10px 10px 10px;
`;

export const AuthMethodCardTitle = styled.h2`
  text-align: center;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  color: #000000;
`;

export const AuthMethodDescriptor = styled.div`
  text-align: left;
  margin-bottom: 38px;
  width: 194px;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`;

export const AppsListing = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  padding-left: 0;
`;

export const IconSMS = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
