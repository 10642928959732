import { CompanyUserRole, UserRole } from 'src/store/types';

export const GLOBAL_ROLES: UserRole[] = [
  UserRole.admin,
  UserRole.company_moderator,
];

export const COMPANY_GLOBAL_ROLES: CompanyUserRole[] = [
  CompanyUserRole.admin,
  CompanyUserRole.company_moderator,
];

export const COMPANY_BASIC_ROLES: CompanyUserRole[] = [
  CompanyUserRole.auditor,
  CompanyUserRole.officer,
  CompanyUserRole.trainee,
];
