/* eslint-disable no-console */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Form } from 'antd';
import { Button } from 'src/components/Button';
import { TopNavbar } from 'src/components/TopNavbar';
import { getSelectedForm } from 'src/store/selectors/form';
import { InterviewWorkbench } from 'src/features/risk-assessment/components/InterviewWorkbench';

interface RouteParams {
  id: string;
}

const Columns = styled.div`
  display: flex;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const FormPreviewPage: FC<unknown> = () => {
  const history = useHistory();
  const [previewForm] = Form.useForm();
  const { id } = useParams<RouteParams>();
  const selectedForm = useSelector(getSelectedForm);

  if (!selectedForm || selectedForm.form.id !== Number(id)) {
    history.push('/forms');
    return null;
  }

  const handlePreviewFormSave = () => {
    previewForm.submit();
  };

  return (
    <>
      <TopNavbar
        title={selectedForm.form.label}
        prevRoute={{
          name: 'Form Edit',
          url: `/forms/edit/${selectedForm.form.id}`,
        }}
        extraOptions={[
          <Button onClick={handlePreviewFormSave} title="Save" key={1} />,
        ]}
      />
      <PageContentContainer>
        <Columns>
          <InterviewWorkbench form={previewForm} selectedForm={selectedForm} />
        </Columns>
      </PageContentContainer>
    </>
  );
};
