import React, { FC } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

export const StyledSelect = styled(Select)`
  width: 100%;

  > .ant-select-selector {
    background: ${({ theme }): string => theme.colors.white.main} !important;
    border: ${remConvert(1)} solid rgba(0, 0, 0, 0.3) !important;
    border-radius: ${remConvert(4)} !important;
    height: ${remConvert(40)} !important;
  }
`;

export interface Option {
  text: string;
  value: string;
}

interface DropdownProps {
  placeholder: string;
  onChange: (value: string) => void;
  options: Option[];
  showSearch?: boolean;
  allowClear?: boolean;
  className?: string;
  float?: boolean;
  disabled?: boolean;
}

export const Dropdown: FC<DropdownProps> = ({
  placeholder,
  onChange,
  options,
  showSearch = true,
  allowClear = true,
  className = '',
  float,
  disabled = false,
}) => {
  const { Option } = Select;
  const labelClassName = float ? 'as-label' : '';

  return (
    <StyledSelect
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={!float ? placeholder : ''}
      optionFilterProp="children"
      onChange={(value) => onChange(value as string)}
      filterOption={(input, option) =>
        (option?.value as string).toLowerCase()?.indexOf(input.toLowerCase()) >=
        0
      }
      className={className}
      disabled={disabled}
    >
      {options.map(({ value, text }: Option) => (
        <Option key={value} value={value}>
          {text}
        </Option>
      ))}
      {float && <label className={labelClassName}>{placeholder}</label>}
    </StyledSelect>
  );
};
