import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { MinusSquareOutlined, PlusSquareOutlined } from 'src/theme/icons';
import {
  Choice,
  Question,
  QuestionTypes,
  SelectedFormState,
} from 'src/store/types';

interface InterviewTipsCardProp {
  selectedForm: SelectedFormState;
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px;
  width: 100%;

  border-radius: 6px;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};

  h3 {
    color: ${({ theme }) => theme.colors.deepBlue.main};
    font-size: 14px;
    margin: 12px 0 18px 0;
  }
`;

const Tip = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 12px 16px;
  margin-bottom: 8px;

  h3 {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-size: 13px;
    margin: 10px 4px;
    display: flex;
    align-items: center;

    &.detail {
      cursor: pointer;
    }

    span {
      display: flex;
      flex-grow: 1;
      margin: 10px 0;

      &.anticon {
        flex-grow: 0;
        font-size: 20px;
      }
    }
  }
`;

const MoreInfo = styled.p`
  border-top: 1px solid ${({ theme }) => theme.colors.grayscale.moonGray};
  padding: 15px 2px 0 0;
  font-size: 12px;
  margin: 10px 0 0 0;
`;

export const InterviewTipsCard: FC<InterviewTipsCardProp> = ({
  selectedForm,
}) => {
  let tips: Choice[] = [];
  const [expanded, setExpanded] = useState<number | undefined>(0);
  if (selectedForm.questions) {
    selectedForm.questions.forEach((q: Question) => {
      if (q.type.key === QuestionTypes.TIPS && q.choices?.length) {
        tips = tips.concat(q.choices);
      }
    });
  }
  if (tips.length === 0) {
    return null;
  }
  return (
    <Card>
      <h3>Tips</h3>
      {tips.map((tip: Choice) => {
        const hasDetails = !!tip.moreInfo;
        return (
          <Tip key={tip.id}>
            <h3
              onClick={() => {
                if (hasDetails) {
                  setExpanded(tip.id === expanded ? 0 : tip.id);
                }
              }}
              className={hasDetails ? 'detail' : ''}
            >
              <span>{tip.label}</span>
              {hasDetails && expanded !== tip.id && <PlusSquareOutlined />}
              {hasDetails && expanded === tip.id && <MinusSquareOutlined />}
            </h3>
            {hasDetails && tip.id === expanded && (
              <MoreInfo>{tip.moreInfo}</MoreInfo>
            )}
          </Tip>
        );
      })}
    </Card>
  );
};
