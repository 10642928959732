export interface BasicFormattingItemInterface {
  title: string;
  content: string;
}

export const basicFormattingItems: BasicFormattingItemInterface[] = [
  {
    title: 'Headers',
    content: `
      # This is a 1st level title<br/>
      ## This is a 2nd level title<br/>
      ###### This is a 6th level title
    `,
  },
  {
    title: 'Emphasis',
    content: `
      *This text will be italic*<br/>
      _This will also be italic_<br/>
      <br/>
      **This text will be bold**<br/>
      __This will also be bold__<br/>
      <br/>
      _You **can** combine them_
    `,
  },
  {
    title: 'Unordered List',
    content: `
      * Item 1<br/>
      * Item 2<br/>
      &nbsp;&nbsp;* Item 2a<br/>
      &nbsp;&nbsp;* Item 2b
    `,
  },
  {
    title: 'Ordered List',
    content: `
      1. Item 1<br/>
      1. Item 2<br/>
      1. Item 3<br/>
      &nbsp;&nbsp;&nbsp;1. Item 3a<br/>
      &nbsp;&nbsp;&nbsp;1. Item 3b
    `,
  },
  {
    title: 'Images',
    content: `
      ![Image of TotalHIPAA](https://www.totalhipaa.com/wp-content/uploads/2020/12/total-hipaa-logo-cropped_440x-1.png)<br/>
      Format: ![Alt Text](url)
    `,
  },
  {
    title: 'Links',
    content: `
      https://www.totalhipaa.com/ - automatic!
      [TotalHIPAA](https://www.totalhipaa.com/)
    `,
  },
  {
    title: 'Blockquotes',
    content: `
      As Kanye West said:<br/>
      <br/>
      > We're living the future so<br/>
      > the present is our past.
    `,
  },
  {
    title: 'Inline code',
    content: 'You can put some `code` inside of backticks.',
  },
  {
    title: 'Line break',
    content: `
      You can put a backslash at the end of the line to make a line break:<br/>
      <br/>
      This is the line 1\\<br/>
      This is the line 2
    `,
  },
];
