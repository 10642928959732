import { Loader, ShowErrorAction } from 'src/store/types';

export const TRAINING_LISTING_LOAD_REQUEST = 'TRAINING_LISTING_LOAD_REQUEST';
export const TRAINING_LISTING_LOAD_SUCCESS = 'TRAINING_LISTING_LOAD_SUCCESS';
export const TRAINING_LISTING_LOAD_FAILED = 'TRAINING_LISTING_LOAD_FAILED';

export type TrainingListingElement = {
  id: number;
  name: string;
  chapter: number;
  minutes: number;
  videoId: string;
  trainingTypeName: string;
  testTypeName: string;
  testTypeOrder: number;
  status: string;
  createdAt: string;
  lastSubmissionStatus?: string;
  lastSubmissionId?: string;
  isAvailable?: boolean;
};

export type TrainingListingResponse = {
  records: TrainingListingElement[];
  total: number;
  page: number;
  pageSize: number;
};

export type TrainingListingState = {
  trainingListingResponse: TrainingListingResponse;
  loader: Loader;
};

export interface TrainingListingLoadRequestAction {
  type: typeof TRAINING_LISTING_LOAD_REQUEST;
  page: number;
  pageSize: number;
  sort?: string;
  column?: string;
  name?: string;
}

export interface TrainingListingLoadSuccessAction {
  type: typeof TRAINING_LISTING_LOAD_SUCCESS;
  trainingListing: TrainingListingResponse;
}

export type TrainingListingLoadErrorAction = ShowErrorAction<
  typeof TRAINING_LISTING_LOAD_FAILED
>;

export const TRAINING_DELETE_REQUEST = 'TRAINING_DELETE_REQUEST';
export const TRAINING_DELETE_SUCCESS = 'TRAINING_DELETE_SUCCESS';
export const TRAINING_DELETE_FAILED = 'TRAINING_DELETE_FAILED';

export interface TrainingDeleteRequestAction {
  type: typeof TRAINING_DELETE_REQUEST;
  trainingId: number;
}

export interface TrainingDeleteSuccessAction {
  type: typeof TRAINING_DELETE_SUCCESS;
  trainingId: number;
}

export type TrainingDeleteErrorAction = ShowErrorAction<
  typeof TRAINING_DELETE_FAILED
>;

export const TRAINING_ACTIVATE_REQUEST = 'TRAINING_ACTIVATE_REQUEST';
export const TRAINING_ACTIVATE_SUCCESS = 'TRAINING_ACTIVATE_SUCCESS';
export const TRAINING_ACTIVATE_FAILED = 'TRAINING_ACTIVATE_FAILED';

export interface TrainingActivateRequestAction {
  type: typeof TRAINING_ACTIVATE_REQUEST;
  trainingId: number;
}

export interface TrainingActivateSuccessAction {
  type: typeof TRAINING_ACTIVATE_SUCCESS;
  trainingId: number;
}

export type TrainingActivateErrorAction = ShowErrorAction<
  typeof TRAINING_ACTIVATE_FAILED
>;

export const TRAINING_ARCHIVE_REQUEST = 'TRAINING_ARCHIVE_REQUEST';
export const TRAINING_ARCHIVE_SUCCESS = 'TRAINING_ARCHIVE_SUCCESS';
export const TRAINING_ARCHIVE_FAILED = 'TRAINING_ARCHIVE_FAILED';

export interface TrainingArchiveRequestAction {
  type: typeof TRAINING_ARCHIVE_REQUEST;
  trainingId: number;
}

export interface TrainingArchiveSuccessAction {
  type: typeof TRAINING_ARCHIVE_SUCCESS;
  trainingId: number;
}

export type TrainingArchiveErrorAction = ShowErrorAction<
  typeof TRAINING_ARCHIVE_FAILED
>;

export const USER_TRAINING_LISTING_LOAD_REQUEST =
  'USER_TRAINING_LISTING_LOAD_REQUEST';
export const USER_TRAINING_LISTING_LOAD_SUCCESS =
  'USER_TRAINING_LISTING_LOAD_SUCCESS';
export const USER_TRAINING_LISTING_LOAD_FAILED =
  'USER_TRAINING_LISTING_LOAD_FAILED';

export interface UserTrainingListingLoadRequestAction {
  type: typeof USER_TRAINING_LISTING_LOAD_REQUEST;
}

export interface UserTrainingListingLoadSuccessAction {
  type: typeof USER_TRAINING_LISTING_LOAD_SUCCESS;
  trainingListing: TrainingListingResponse;
}

export type UserTrainingListingLoadErrorAction = ShowErrorAction<
  typeof USER_TRAINING_LISTING_LOAD_FAILED
>;

export type TrainingListingActionTypes =
  | TrainingListingLoadRequestAction
  | TrainingListingLoadSuccessAction
  | TrainingListingLoadErrorAction
  | TrainingDeleteRequestAction
  | TrainingDeleteSuccessAction
  | TrainingDeleteErrorAction
  | TrainingActivateRequestAction
  | TrainingActivateSuccessAction
  | TrainingActivateErrorAction
  | TrainingArchiveRequestAction
  | TrainingArchiveSuccessAction
  | TrainingArchiveErrorAction
  | UserTrainingListingLoadRequestAction
  | UserTrainingListingLoadSuccessAction
  | UserTrainingListingLoadErrorAction;
