import React, { FC } from 'react';
import styled from 'styled-components';

const ExampleCsvContainer = styled.a`
  margin-bottom: 15px;
`;

export const CsvInfo: FC = () => {
  return (
    <>
      <p>Possible user roles:</p>
      <p>
        <b>officer auditor trainee</b>
      </p>
      <ExampleCsvContainer href="/example_users_data_file.csv" download>
        Download example CSV
      </ExampleCsvContainer>
    </>
  );
};
