import React from 'react';
import { Table, Empty, Tag } from 'antd';
import moment from 'moment';

import { Grid } from 'src/components/Grid';

import { ExamListingElement } from 'src/store/types';
import { ExamSettingsMenu } from '..';

interface Props {
  dataSource: ExamListingElement[];
}

const { Column } = Table;
const ExamListingTable = ({ dataSource }: Props) => {
  return (
    <Grid
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
    >
      <Column<ExamListingElement>
        title="Training Type"
        dataIndex="trainingTypeName"
        key="trainingTypeName"
      />
      <Column<ExamListingElement>
        title="Status"
        dataIndex="status"
        key="status"
        render={(_, record) => (
          <>
            <Tag
              color={
                record.status === 'ACTIVE'
                  ? 'success'
                  : record.status === 'ARCHIVE'
                    ? 'error'
                    : 'processing'
              }
            >
              {record.status}
            </Tag>
          </>
        )}
      />
      <Column<ExamListingElement>
        title="Created at"
        dataIndex="createdAt"
        key="createdAt"
        render={(_, record) => (
          <span>{moment(record.createdAt).format('MM-DD-YYYY')}</span>
        )}
      />
      <Column<ExamListingElement>
        dataIndex=""
        key="action"
        width="10%"
        render={(_, record: ExamListingElement) => (
          <ExamSettingsMenu exam={record} />
        )}
      />
    </Grid>
  );
};

export default ExamListingTable;
