/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { RootState, CompanyState, Loader } from '../types';

export const getCompanyFromState = (state: RootState): CompanyState => {
  return state.company.company;
};

export const getCompanyLoaderFromState = (state: RootState): Loader => {
  return state.company.loader;
};
