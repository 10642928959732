import { call, takeEvery, put } from 'redux-saga/effects';
import {
  DOCUMENTS_BY_ASSESSMENT_LOAD_REQUEST,
  DocumentFromResponse,
  DocumentsByAssessmentLoadRequestAction,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadDocumentsByAssessment = function* async({
  assessmentId,
  documentType,
}: DocumentsByAssessmentLoadRequestAction): Generator {
  try {
    const documents = yield call(
      api.getDocumentsByAssessment,
      assessmentId,
      documentType,
    );
    yield put(
      actions.documentsByAssessmentLoadSuccess(
        documents as DocumentFromResponse[],
        assessmentId,
      ),
    );
  } catch (err) {
    yield put(
      actions.documentsByAssessmentLoadError(
        'Failed to load documents',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(
      DOCUMENTS_BY_ASSESSMENT_LOAD_REQUEST,
      loadDocumentsByAssessment,
    ),
  ];
}
