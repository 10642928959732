import { HttpClient } from 'src/api/services/HttpClient';
import { TrainingTypesResponse } from 'src/store/types';

class TrainingTypeService extends HttpClient {
  public constructor() {
    super(`${process.env.REACT_APP_API_URL as string}training-type`);
  }

  public getAll = async (): Promise<TrainingTypesResponse> => {
    const res = await this.instance.get<{ data: TrainingTypesResponse }>('/', {
      withCredentials: true,
    });
    return res.data;
  };
}

export default new TrainingTypeService();
