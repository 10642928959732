import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Tabs } from 'antd';
import { RootState, FormState, InterviewState } from 'src/store/types';
import { Button } from 'src/components/Button';
import { TopNavbar } from 'src/components/TopNavbar';
import {
  getFormById,
  getSelectedForm,
  getInterviewById,
  getAnswersValuesByQuestions,
  getSelectedInterview,
} from 'src/store/selectors';
import {
  setSelectedForm,
  formLoadQuestionsRequest,
} from 'src/store/actions/form';
import { formsLoadRequest } from 'src/store/actions/formListing';
import {
  setSelectedInterview,
  interviewLoadAnswersRequest,
} from 'src/store/actions/interview';
import { questionTypeLoadRequest } from 'src/store/actions/questionType';
import { companiesLoadRequest } from 'src/store/actions/companyListing';
import { companyLoadRequest } from 'src/store/actions/company';
import { InterviewReport } from 'src/features/risk-assessment/components/InterviewReport';
import { api } from 'src/api/services/ThApi';
import { AssessmentAuditingTab } from 'src/features/risk-assessment/components/AssessmentAuditingTab/AssessmentAuditingTab';

interface RouteParams {
  id: string;
  assessmentId: string;
  formId: string;
  interviewId: string;
}

const Columns = styled.div`
  display: flex;
  width: 70%;
`;

const PageContentContainer = styled.div`
  display: flex;
  margin-top: 22px;

  .progress-card {
    margin-top: 15px;
  }
`;

const { TabPane } = Tabs;

export const CompletedInterviewPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const [interviewForm] = Form.useForm();
  const {
    id: companyId,
    assessmentId,
    formId,
    interviewId,
  } = useParams<RouteParams>();
  const form = useSelector((state: RootState): FormState | undefined =>
    getFormById(state, formId),
  );
  const interview = useSelector(
    (state: RootState): InterviewState | undefined =>
      getInterviewById(state, interviewId),
  );
  const { interview: selectedInterview } = useSelector(getSelectedInterview);
  const selectedForm = useSelector(getSelectedForm);
  const answersValues = useSelector(getAnswersValuesByQuestions);

  useEffect(() => {
    dispatch(formsLoadRequest());
    dispatch(companiesLoadRequest());
    dispatch(companyLoadRequest(Number(companyId)));
    interviewForm.setFieldsValue(answersValues);
  }, []);

  useEffect(() => {
    if (form && form.id !== selectedForm.form.id) {
      dispatch(setSelectedForm(form));
      dispatch(formLoadQuestionsRequest(form.id));
      dispatch(questionTypeLoadRequest());
    }
  }, [form]);

  useEffect(() => {
    if (interview && interview.id !== selectedInterview.id) {
      dispatch(setSelectedInterview(interview));
      dispatch(interviewLoadAnswersRequest(interview.id as number));
    }
  }, [interview]);

  return (
    <>
      <TopNavbar
        title={selectedForm.form.label}
        prevRoute={{
          name: 'Assessment',
          url: `/companies/${companyId}/assessments/${assessmentId}`,
        }}
        extraOptions={[
          <Button
            onClick={async () =>
              interview?.id && api.getInterviewCsv(interview.id)
            }
            title="Download CSV"
            key={0}
          />,
        ]}
        withCompany
      />

      <Tabs type="line" size="large">
        <TabPane tab="Interview" key={1}>
          <PageContentContainer>
            <Columns>
              <InterviewReport selectedForm={selectedForm} />
            </Columns>
          </PageContentContainer>
        </TabPane>
        <TabPane tab="Auditing" key={2}>
          <AssessmentAuditingTab interviewId={Number(interviewId)} />
        </TabPane>
      </Tabs>
    </>
  );
};
