import {
  TEMPLATES_LOAD_SUCCESS,
  TEMPLATES_LOAD_REQUEST,
  ADD_TEMPLATE_TO_LIST,
  REMOVE_TEMPLATE_FROM_LIST,
  Loader,
  TemplateListingState,
  TemplateListingIndexedItem,
  TemplateListingActionTypes,
  TemplateState,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const templateListingInitialState: TemplateListingState = {
  templates: {},
  loader: loaderInitialState,
};

export const templateListingReducer = (
  state = templateListingInitialState,
  action: TemplateListingActionTypes,
): TemplateListingState => {
  switch (action.type) {
    case TEMPLATES_LOAD_REQUEST: {
      return {
        ...state,
        loader: { ...loaderInitialState, loading: true },
      };
    }
    case TEMPLATES_LOAD_SUCCESS: {
      const list = action.templates.reduce(
        (list: TemplateListingIndexedItem, template: TemplateState) => {
          return {
            ...list,
            [template.id]: template,
          };
        },
        {},
      );
      return {
        templates: list,
        loader: { ...loaderInitialState, success: true, completed: true },
      };
    }
    case ADD_TEMPLATE_TO_LIST: {
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.template.id]: action.template,
        },
      };
    }
    case REMOVE_TEMPLATE_FROM_LIST: {
      const newState = { ...state };
      delete newState.templates[action.templateId];
      return newState;
    }
    default:
      return state;
  }
};
