import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Alert, Badge, Typography } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import ReactDragListView from 'react-drag-listview';

import { Grid } from 'src/components/Grid';
import { formsReOrderRequest } from 'src/store/actions/formListing';
import {
  categoryDeleteRequest,
  categoryReOrder,
} from 'src/store/actions/category';
import {
  CategoryState,
  FormState,
  SortableItem,
  UserListingIndexedItem,
} from 'src/store/types';
import { FormCard } from '../FormCard';
import { CategorySettings } from '../CategorySettings';

interface CategoryListingProps {
  categories: CategoryState[];
  users?: UserListingIndexedItem;
  showUpdateCategoryPanel: (category: CategoryState) => void;
}

const { DragColumn } = ReactDragListView;

const FormCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .card {
    margin: 10px;
  }
`;

enum DragType {
  CATEGORY = 'CATEGORY',
  FORM = 'FORM',
}

export const CategoryListing: FC<CategoryListingProps> = ({
  categories,
  showUpdateCategoryPanel,
}) => {
  const dispatch = useDispatch();

  const reOrder = <T extends SortableItem>(
    data: T[],
    startIndex: number,
    endIndex: number,
  ): T[] => {
    let result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result = result.map((category, index) => {
      category.weight = index + 1;
      return category;
    });

    return result;
  };

  const onDragEnd = (
    fromIndex: number,
    toIndex: number,
    type: DragType,
    index?: number,
  ): void => {
    if (toIndex < 0) return;

    if (type === DragType.CATEGORY) {
      const orderedCategories = reOrder<CategoryState>(
        categories,
        fromIndex,
        toIndex,
      );
      dispatch(categoryReOrder(orderedCategories));
    } else if (type === DragType.FORM) {
      const forms = reOrder<FormState>(
        categories[index!].forms!,
        fromIndex,
        toIndex,
      );
      const orderedCategories = { ...categories };
      orderedCategories[index!].forms = forms;
      dispatch(formsReOrderRequest(forms));
    }
  };

  const nestedData = (record: any, index: number) => {
    return (
      <DragColumn
        onDragEnd={(fromIndex: number, toIndex: number) =>
          onDragEnd(fromIndex, toIndex, DragType.FORM, index)
        }
        nodeSelector="div"
        handleSelector=".card"
      >
        <FormCardsContainer>
          {record?.forms?.map?.((form: any) => {
            return (
              <FormCard
                className="card"
                key={form.id}
                form={form}
                disabled={false}
                preview
              />
            );
          })}
        </FormCardsContainer>
      </DragColumn>
    );
  };

  const handleDeleteCategory = (categoryId: number): void => {
    dispatch(categoryDeleteRequest(categoryId));
  };

  return (
    <>
      <Alert
        message="Categories and Forms can be re-arranged by drag & drop. Only categories without forms assigned can be deleted."
        type="info"
        showIcon
        closeText="Close Now"
      />
      <ReactDragListView
        onDragEnd={(fromIndex: number, toIndex: number) =>
          onDragEnd(fromIndex, toIndex, DragType.CATEGORY)
        }
        handleSelector=".category-draggable"
        ignoreSelector=".ant-table-expanded-row"
      >
        <Grid
          pagination={false}
          expandable={{
            expandedRowRender: (record, index) => nestedData(record, index),
          }}
          columns={[
            {
              title: 'Category',
              dataIndex: 'name',
              render: (_: any, record: CategoryState) => {
                return (
                  <>
                    <Typography.Text strong className="grabbable">
                      <FolderOutlined className="icon" />
                      {`   ${record.name}`}
                    </Typography.Text>
                  </>
                );
              },
            },
            {
              title: '',
              render: (_: any, record: CategoryState) => {
                return (
                  <span>
                    <Badge status="processing" />
                    {record.forms?.length} Forms
                  </span>
                );
              },
            },
            {
              title: '',
              render: (_: any, record: CategoryState) => {
                return (
                  <CategorySettings
                    category={record}
                    deleteCategory={() => handleDeleteCategory(record.id)}
                    showUpdateCategoryPanel={() =>
                      showUpdateCategoryPanel(record)
                    }
                  />
                );
              },
            },
          ]}
          dataSource={categories}
          rowClassName="category-draggable"
          rowKey="id"
        />
      </ReactDragListView>
    </>
  );
};
