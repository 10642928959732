import styled from 'styled-components';
import { centeredFlex, device } from 'src/theme';
import { remConvert } from 'src/theme/utils';

export const PageContainer = styled.div`
  position: relative;
`;

export const PageContentContainer = styled.div`
  ${centeredFlex}
  flex-direction: column;
  padding-left: ${remConvert(0)};
  padding-right: ${remConvert(0)};

  @media ${device.lg} {
    padding-left: ${remConvert(90)};
    padding-right: ${remConvert(90)};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${remConvert(42)};
  margin-bottom: ${remConvert(42)};
  width: 100%;

  button {
    width: 100% !important;
  }

  @media ${device.lg} {
    width: ${remConvert(264)};

    button {
      width: ${remConvert(264)} !important;
    }
  }
`;

export const PageToolImage = styled.img`
  position: absolute;
  transform: rotate(8deg);
  top: ${remConvert(-38)};
  right: ${remConvert(-26)};
  visibility: hidden;

  @media ${device.lg} {
    visibility: visible;
  }
`;

export const ProgressMeterContainer = styled.div`
  padding-bottom: ${remConvert(20)};
`;

export const PageTitle = styled.div`
  font-family: ${({ theme }): string => theme.fontFamilies.primary};
  font-style: ${({ theme }): string => theme.fontStyles.normal};
  font-weight: ${({ theme }): number => theme.fontWeights.semiBold};
  font-size: 34px;
  line-height: ${remConvert(46)};
  text-align: center;
  letter-spacing: 0.1em;
`;

export const TrainingInfoContainer = styled.div`
  p {
    font-family: ${({ theme }): string => theme.fontFamilies.primary};
    font-style: ${({ theme }): string => theme.fontStyles.normal};
    font-weight: 400;
    font-size: 14px;
    line-height: ${remConvert(19)};
    color: rgba(28, 36, 40, 0.85);
  }

  p:first-of-type {
    margin-top: ${remConvert(20)};
    margin-bottom: ${remConvert(20)};
  }
`;

export const TrainingDueDateContainer = styled.div`
  text-align: center;
  margin-top: ${remConvert(20)};
  margin-bottom: ${remConvert(20)};

  div:first-child {
    font-size: 22px;
    font-family: ${({ theme }): string => theme.fontFamilies.primary};
    font-style: ${({ theme }): string => theme.fontStyles.normal};
    font-weight: ${({ theme }): number => theme.fontWeights.semiBold};
    line-height: ${remConvert(30)};
    color: ${({ theme }): string => theme.colors.astral.main};
  }
`;
