import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin, Typography } from 'antd';
import styled from 'styled-components';
import { Pagination } from 'src/components/Pagination';
import { AssessmentFormListingTable } from 'src/features/risk-assessment/components/AssessmentFormListingTable';
import { RootState } from 'src/store/types';
import {
  getForms,
  getAssessmentInterviewsByFormId,
  getCompanyLoaderFromState,
  getAssessmentById,
  getFormsByAssessmentTypeId,
} from 'src/store/selectors';

const { Title: TitleAntD } = Typography;

interface Params {
  companyId: number;
  assessmentId: number;
}

const Title = styled(TitleAntD)`
  && {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
  }
`;

const PageContentContainer = styled.div`
  margin-top: 40px;
`;

export const AssesmentListTab: FC<Params> = ({ companyId, assessmentId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const companyLoader = useSelector(getCompanyLoaderFromState);

  const interviews = useSelector((state: RootState) =>
    getAssessmentInterviewsByFormId(state, assessmentId),
  );

  const assessment = useSelector((state: RootState) =>
    getAssessmentById(state, assessmentId),
  );

  const forms = useSelector(
    (state: RootState) =>
      assessment &&
      getForms(
        state,
        { limit: currentPage * pageSize, offset: (currentPage - 1) * pageSize },
        getFormsByAssessmentTypeId(state, assessment.typeId),
      ),
  );

  return (
    <PageContentContainer>
      <Title>Entries</Title>
      {companyLoader.loading ? (
        <Spin size="large" />
      ) : (
        <AssessmentFormListingTable
          forms={forms?.data}
          interviews={interviews}
          companyId={companyId}
          assessmentId={assessmentId}
        />
      )}
      <Pagination
        current={currentPage}
        items={forms?.totalItems || 0}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
        onPageSizeChange={(size) => {
          setPageSize(size);
          setCurrentPage(1);
        }}
        sizes={[10, 25, 50]}
      />
    </PageContentContainer>
  );
};
