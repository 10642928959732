import React, { FC } from 'react';
import { Progress } from 'antd';
import { MainContainer, TextContainer, ProgressContainer } from './Styles';

export interface ProgressBarProps {
  className?: string;
  completed: number;
  total: number;
  title?: string;
  short?: boolean;
  altered?: boolean;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  altered = false,
  className,
  completed,
  title,
  total,
  short = true,
}) => {
  const percent =
    total === 0
      ? 0
      : completed / total < 1
        ? Math.round((completed / total) * 100)
        : 100;
  return (
    <MainContainer className={className} short={short}>
      {!altered && (
        <TextContainer>
          <span>{title}</span>
          <span className="end">
            <strong>{percent}%</strong>
            <span>
              {completed < total ? completed : total}/{total}
            </span>
          </span>
        </TextContainer>
      )}
      <ProgressContainer>
        <Progress percent={percent} strokeColor={''} showInfo={false} />
        {altered && (
          <span className="end">
            <strong>{percent}%</strong>
            <span>
              {completed < total ? completed : total}/{total}
            </span>
          </span>
        )}
      </ProgressContainer>
    </MainContainer>
  );
};
