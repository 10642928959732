import styled from 'styled-components';
import { Tag as TagAntd } from 'antd';
import { remConvert } from 'src/theme/utils';

export const Tag = styled(TagAntd)`
  border-radius: 4px;
  border-width: 0;
  text-align: center;
  min-width: ${remConvert(84)};

  &.active {
    color: ${({ theme }) => theme.colors.green.darkest};
    background-color: ${({ theme }) => theme.colors.green.light};
  }

  &.inactive {
    color: ${({ theme }) => theme.colors.grayscale.preDark};
    background-color: ${({ theme }) => theme.colors.grayscale.lightGray};
  }

  &.completed {
    color: ${({ theme }) => theme.colors.green.darker};
    background-color: ${({ theme }) => theme.colors.white.main};
    border: 1px solid ${({ theme }) => theme.colors.green.light};
  }

  &.low {
    color: ${({ theme }) => theme.colors.grayscale.darkGray};
    background-color: ${({ theme }) => theme.colors.paleYellow.darker};
  }

  &.medium {
    color: ${({ theme }) => theme.colors.grayscale.darkGray};
    background-color: ${({ theme }) => theme.colors.orange.main};
  }

  &.high {
    color: ${({ theme }) => theme.colors.white.main};
    background-color: ${({ theme }) => theme.colors.burntSienna.main};
  }
`;
