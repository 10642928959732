import React, { FC } from 'react';
import { Table, Empty, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { Grid } from 'src/components/Grid';
import { TemplateSettings } from 'src/features/risk-assessment/components/TemplateSettings';
import { TemplatesListingIndexedInterface } from 'src/store/types';
import styled from 'styled-components';
import {
  WarningOutlined,
  CopyOutlined,
  FileSyncOutlined,
} from 'src/theme/icons';
import { dataMapper, dataMapperFolders } from './dataMapper';

export interface TemplateListingTableProps {
  templates: TemplatesListingIndexedInterface;
  showCloneTemplatePanel: (templateId: number, onlyDuplicate: boolean) => void;
}

export interface TableRowInterface {
  id: number;
  title: string;
  assessmentTypeLabel: string;
  parentTemplateTitle: string;
  parentTemplateId: number;
  needsReview: boolean;
  hasChildTemplates: boolean;
}

export interface FoldersTableRowInterface {
  id: number;
  folder: string;
}

const TemplateGrid = styled(Grid)`
  .ant-table table .ant-table-tbody tr.ant-table-row td {
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
    &.folder {
      color: black;
      font-weight: bold;
    }
    &:last-child {
      justify-content: end;
    }
  }

  &&&&&&&&& .ant-table-expanded-row {
    & > td {
      padding: 0;
      background-color: transparent;
    }
    .ant-table {
      margin-left: 10px;
      table {
        .ant-table-thead th {
          padding-bottom: 1px;
          padding-top: 1px;
        }
        .ant-table-tbody tr.ant-table-row {
          td {
            padding: 16px;
            background-color: #fff;

            &:last-child {
              justify-content: flex-end;
              margin-right: 16px;
            }
            &:first-child {
              width: 30px;
            }
            &:nth-child(2) {
              width: 50%;
            }
          }
        }
      }
    }
  }
`;

const TemplateTitle = styled.span`
  margin-right: 10px;
`;

export const TemplateListingTable: FC<TemplateListingTableProps> = ({
  templates: rawTemplates,
  showCloneTemplatePanel,
}) => {
  const nestedData = (folder: FoldersTableRowInterface) => {
    const columns = [
      {
        title: '',
        render: (_: any, record: TableRowInterface) => {
          if (record.needsReview) {
            return (
              <Tooltip title="Template needs review, please edit it to resolve possible conflicts">
                <WarningOutlined />
              </Tooltip>
            );
          }
          return null;
        },
      },
      {
        title: 'Title',
        render: (_: any, record: TableRowInterface) => {
          return (
            <>
              <TemplateTitle>{record.title}</TemplateTitle>
              {record.hasChildTemplates && (
                <Tooltip title="This template has a derived template from it">
                  <CopyOutlined />
                </Tooltip>
              )}
            </>
          );
        },
      },
      { title: 'Id', dataIndex: 'id', key: 'id' },
      { title: 'Type', dataIndex: 'assessmentTypeLabel', key: 'type' },
      {
        title: 'Parent Template',
        render: (_: any, record: TableRowInterface) => (
          <>
            {record.parentTemplateId && (
              <>
                <Tooltip
                  title={`This is children template has been created from ${record.parentTemplateTitle}`}
                >
                  <FileSyncOutlined />
                </Tooltip>

                <Link to={`/templates/edit/${record.parentTemplateId}`}>
                  {' '}
                  {record.parentTemplateTitle}
                </Link>
              </>
            )}
          </>
        ),
      },
      {
        title: '',
        render: (_: any, record: TableRowInterface) => (
          <TemplateSettings
            showCloneTemplatePanel={showCloneTemplatePanel}
            templateId={record.id}
          />
        ),
      },
    ];

    const data = dataMapper(rawTemplates, folder.folder);
    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
      />
    );
  };

  return (
    <TemplateGrid
      pagination={false}
      rowKey="id"
      expandable={{ expandedRowRender: nestedData }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
      columns={[
        {
          title: 'Folder',
          dataIndex: 'folder',
          className: 'folder',
        },
      ]}
      dataSource={dataMapperFolders(rawTemplates)}
    />
  );
};
