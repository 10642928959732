import {
  InterviewListingState,
  InterviewListingIndexedItem,
  CompanyActionTypes,
  COMPANY_LOAD_SUCCESS,
  INTERVIEW_UPDATE_SUCCESS,
  AssessmentFromResponse,
  INTERVIEW_ASSIGN_OWNER_SUCCESS,
  INTERVIEW_REMOVE_OWNER_SUCCESS,
  InterviewFromResponse,
  InterviewState,
} from 'src/store/types';

const interviewListingInitialState: InterviewListingState = {
  interviews: {},
};

const getInterviewIndexedItem = (
  assessmentId: number,
  {
    id,
    createdAt,
    creator,
    updatedAt,
    completedAt,
    form,
    owner,
  }: InterviewFromResponse,
): InterviewState => {
  return {
    id,
    createdAt,
    updatedAt,
    completedAt,
    formId: form?.id,
    creatorId: creator?.id || 0,
    ownerId: owner?.id,
    ownerName: owner?.name,
    assessmentId: assessmentId,
  };
};

const getInterviewsFromAssessment = (
  assessment: AssessmentFromResponse,
): InterviewListingIndexedItem => {
  const { interviews } = assessment;
  return interviews && interviews.length > 0
    ? interviews.reduce(
        (tempMap: InterviewListingIndexedItem, interview) => ({
          ...tempMap,
          [interview.id]: getInterviewIndexedItem(assessment.id, interview),
        }),
        {},
      )
    : {};
};

export const interviewListingReducer = (
  state = interviewListingInitialState,
  action: CompanyActionTypes,
): InterviewListingState => {
  switch (action.type) {
    case COMPANY_LOAD_SUCCESS: {
      const { assessments } = action.company;
      const interviewMap: InterviewListingIndexedItem = assessments.reduce(
        (
          map: InterviewListingIndexedItem,
          assessment: AssessmentFromResponse,
        ) => {
          const assessmentInterviews = getInterviewsFromAssessment(assessment);
          return {
            ...map,
            ...assessmentInterviews,
          };
        },
        {},
      );
      return { interviews: interviewMap };
    }
    case INTERVIEW_UPDATE_SUCCESS: {
      if (
        state.interviews &&
        action.interview.id &&
        state.interviews[action.interview.id]
      ) {
        return {
          interviews: {
            ...state.interviews,
            [action.interview.id]: {
              ...state.interviews[action.interview.id],
              ...action.interview,
            },
          },
        };
      }
      return state;
    }
    case INTERVIEW_ASSIGN_OWNER_SUCCESS: {
      if (
        state.interviews &&
        action.interview.id &&
        state.interviews[action.interview.id]
      ) {
        return {
          interviews: {
            ...state.interviews,
            [action.interview.id]: {
              ...state.interviews[action.interview.id],
              ...getInterviewIndexedItem(
                state.interviews[action.interview.id].assessmentId,
                action.interview,
              ),
            },
          },
        };
      }
      return state;
    }
    case INTERVIEW_REMOVE_OWNER_SUCCESS: {
      if (
        state.interviews &&
        action.interviewId &&
        state.interviews[action.interviewId]
      ) {
        return {
          interviews: {
            ...state.interviews,
            [action.interviewId]: {
              ...state.interviews[action.interviewId],
              ownerId: undefined,
              ownerName: undefined,
            },
          },
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
