import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { device } from 'src/theme';

export const PageContentContainer = styled.div`
  margin-top: ${remConvert(22)};
  max-width: ${remConvert(400)};
  display: block;
  margin: 0 auto;
`;

export const PageTitle = styled.h1`
  text-align: center;
  font-weight: 700;
  margin-top: ${remConvert(8)};
`;

export const PageContentBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${remConvert(35)};

  @media ${device.md} {
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-weight: bold;
  margin: 16px;
`;
