import {
  DocumentDraftFromResponse,
  DocumentDraftState,
  DocumentDraftUpdateSuccessAction,
} from './documentDraft';
import { Loader } from './loader';
import { ShowErrorAction } from './error';

export const DOCUMENTS_DRAFTS_LOAD_REQUEST = 'DOCUMENTS_DRAFTS_LOAD_REQUEST';
export const DOCUMENTS_DRAFTS_LOAD_SUCCESS = 'DOCUMENTS_DRAFTS_LOAD_SUCCESS';
export const DOCUMENTS_DRAFTS_LOAD_ERROR = 'DOCUMENTS_DRAFTS_LOAD_ERROR';

export interface DocumentDraftListingState {
  documentDrafts: DocumentDraftListingIndexedItem;
  loader: Loader;
}

export interface DocumentDraftListingIndexedItem {
  [key: string]: DocumentDraftState;
}

export interface DocumentsDraftsLoadRequestAction {
  type: typeof DOCUMENTS_DRAFTS_LOAD_REQUEST;
  documentId?: number;
}

export interface DocumentsDraftsLoadSuccessAction {
  type: typeof DOCUMENTS_DRAFTS_LOAD_SUCCESS;
  documentDrafts: DocumentDraftFromResponse[];
}

export type DocumentsDraftsLoadErrorAction = ShowErrorAction<
  typeof DOCUMENTS_DRAFTS_LOAD_ERROR
>;

export type DocumentsDraftsListingActionTypes =
  | DocumentsDraftsLoadRequestAction
  | DocumentsDraftsLoadSuccessAction
  | DocumentsDraftsLoadErrorAction
  | DocumentDraftUpdateSuccessAction;
