import {
  AssessmentFromResponse,
  Comment,
  DocumentDraftFromResponse,
  User,
  Question,
  ShowErrorAction,
} from 'src/store/types';

export interface Thread {
  id: number;
  createdAt: string;
  updatedAt: string;
  title?: string;
  questionId?: number;
  question?: Question;
  taggedUsers?: User[];
  assessment: AssessmentFromResponse;
  documentDraft?: DocumentDraftFromResponse;
  comments: Comment[];
  refKey?: string;
  resolved?: boolean;
}

export const THREAD_UPDATE_REQUEST = 'THREAD_UPDATE_REQUEST';
export const THREAD_UPDATE_SUCCESS = 'THREAD_UPDATE_SUCCESS';
export const THREAD_UPDATE_ERROR = 'THREAD_UPDATE_ERROR';

export interface ThreadUpdateRequestAction {
  type: typeof THREAD_UPDATE_REQUEST;
  thread: Thread;
}

export interface ThreadUpdateSuccessAction {
  type: typeof THREAD_UPDATE_SUCCESS;
  updatedThread: Thread;
}

export type ThreadUpdateErrorAction = ShowErrorAction<
  typeof THREAD_UPDATE_ERROR
>;
