/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import { mapCompanyType } from '../../features/risk-assessment/components/CompanyListingTable/dataMapper';
import {
  CompanyListingIndexedItem,
  CompanyState,
  CompanyType,
  Loader,
  RootState,
} from '../types';

const getIndexedCompaniesFromState = (
  state: RootState,
): CompanyListingIndexedItem => {
  return state.companyListing.companies;
};

const getCompaniesFromState = (state: RootState): CompanyState[] => {
  return Object.values(getIndexedCompaniesFromState(state));
};

export const getCompaniesLoader = (state: RootState): Loader => {
  return state.companyListing.loader;
};

export const getCompanies = createSelector(
  (state: RootState) => getCompaniesFromState(state),
  (_: RootState, textToFilter: string) => textToFilter,
  (companies: CompanyState[], textToFilter: string) => {
    if (textToFilter) {
      const capsName = textToFilter.toUpperCase();
      return companies.filter(
        (c) =>
          c.name.toUpperCase().includes(capsName) ||
          mapCompanyType(c.companyType as CompanyType)
            .toUpperCase()
            .includes(capsName),
      );
    }
    return companies;
  },
);

export const getFilteredIndexedCompanies = createSelector(
  (state: RootState, textToFilter: string) => getCompanies(state, textToFilter),
  (companies: CompanyState[]) => {
    return companies.reduce(
      (companies: CompanyListingIndexedItem, company: CompanyState) => {
        return {
          ...companies,
          [company.id]: company,
        };
      },
      {},
    );
  },
);

export const getCompanyById = createSelector(
  (state: RootState) => getIndexedCompaniesFromState(state),
  (_: RootState, id: number) => id,
  (companyListingIndexedItem: CompanyListingIndexedItem, id: number) => {
    return companyListingIndexedItem[id];
  },
);
