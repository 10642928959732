import React, { FC } from 'react';
import { THIconCompliantStatus, THIconRisksStatus } from 'src/theme/media';
import * as S from './Styles';

interface CompliantStatusProps {
  risks: number;
}

export const CompliantStatus: FC<CompliantStatusProps> = ({ risks }) => {
  return (
    <S.RiskSectionsCompliantStatusContainer>
      {risks === 0 ? (
        <>
          <img src={THIconCompliantStatus} />
          <span className="riskSectionStatus">Compliant</span>
        </>
      ) : (
        <>
          <img src={THIconRisksStatus} />
          <span className="riskSectionRisks">
            {risks} Risk{risks > 1 ? 's' : ''} Found
          </span>
        </>
      )}
    </S.RiskSectionsCompliantStatusContainer>
  );
};
