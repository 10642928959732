import { createGlobalStyle } from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { normalOpenSans } from './CommonStyles';

export const GlobalStyle = createGlobalStyle`
  .ant-popover {
    .ant-popover-buttons {
      direction: rtl;
    }
  }

  .highlight-token {
    border: 1px solid lightblue;
    border-radius: ${remConvert(5)};
    padding: ${remConvert(3)};
    background: lightblue;
  }

  .ant-message {
    .anticon {
      display: none;
    }
  }


  .ant-select {
    width: 100% !important;
  }

  .overriden-select {
    .ant-select-selector {
      background: ${({ theme }): string => theme.colors.white.main} !important;
      border: ${remConvert(1)} solid rgba(0, 0, 0, 0.3) !important;
      border-radius: ${remConvert(4)} !important;
    }
  }

  h1 {
    ${normalOpenSans}
    font-weight: 600;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: 0.1em;
    color: #1C2428;
  }
`;
