import React from 'react';
import { Avatar, Tooltip } from 'antd';

interface SimpleUserAvatarProps {
  user: { avatarImg?: string; name: string };
  size?: number;
}

export const SimpleUserAvatar = ({
  user,
  size = 36,
}: SimpleUserAvatarProps): React.ReactNode => {
  if (!user) {
    return (
      <Tooltip title={'null'}>
        <Avatar size={size}>{'N'}</Avatar>
      </Tooltip>
    );
  }

  if (user.avatarImg) {
    return (
      <Tooltip title={user.name}>
        <Avatar src={user.avatarImg} alt={user.name} size={size} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={user.name ?? ''}>
      <Avatar size={size}>{user.name.charAt(0) ?? ''}</Avatar>
    </Tooltip>
  );
};
