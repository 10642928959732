import { Upload } from 'antd';
import React, { FC } from 'react';
import { THIconClose } from 'src/theme/media';
import * as S from './Styles';

interface AddCompanyUserListingTableProps {
  newCompanyUsers: any[];
  licensesLeft: number;
  onRemove: (newUser: any) => void;
  onFileChange: (info: any) => void;
}

export const AddCompanyUserListingTable: FC<
  AddCompanyUserListingTableProps
> = ({ newCompanyUsers, licensesLeft, onRemove, onFileChange }) => {
  return (
    <>
      <S.AddUserTableContainer>
        <S.AddUserTable>
          <S.AddUserTableTitle>
            <h3>Trainees</h3>
          </S.AddUserTableTitle>
          <S.AddUserList>
            <S.LicensesWrapper style={{ textAlign: 'left' }}>
              Your subscription includes: <span>{licensesLeft} licenses</span>
            </S.LicensesWrapper>
            {newCompanyUsers.map((newCompanyUser, index) => {
              return (
                <li key={index}>
                  <div>
                    <div>{index + 1}.</div>
                    <S.AddUserRoleData>
                      <div>{newCompanyUser.name}</div>
                      <div>{newCompanyUser.email}</div>
                    </S.AddUserRoleData>
                  </div>
                  <div>
                    {newCompanyUser.officer && (
                      <S.AddUserRole>-officer</S.AddUserRole>
                    )}
                    <S.RemoveButtonWrapper
                      onClick={() => onRemove(newCompanyUser)}
                    >
                      <img src={THIconClose} />
                    </S.RemoveButtonWrapper>
                  </div>
                </li>
              );
            })}
          </S.AddUserList>
          <S.AddUserTableFile>
            <a href="/example_users_data_file.csv" download>
              Download example CSV
            </a>
            <Upload
              beforeUpload={onFileChange}
              name="file"
              accept={'.csv'}
              showUploadList={false}
              maxCount={1}
            >
              <a href="#">Upload CSV file</a>
            </Upload>
          </S.AddUserTableFile>
        </S.AddUserTable>
      </S.AddUserTableContainer>
    </>
  );
};
