/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  RootState,
  DocumentState,
  DocumentListingIndexedItem,
  DocumentListingFilters,
  Loader,
} from '../types';

const getIndexedDocumentsFromState = (
  state: RootState,
): DocumentListingIndexedItem => {
  return state.documentListing.documents;
};

const getDocumentsFromState = (state: RootState): DocumentState[] => {
  return Object.values(getIndexedDocumentsFromState(state)).sort(
    (docA, docB) => docB.id - docA.id,
  );
};

export const getDocuments = createSelector(
  (state: RootState) => getDocumentsFromState(state),
  (_: RootState, { offset }: DocumentListingFilters) => offset || 0,
  (_: RootState, { limit }: DocumentListingFilters) => limit,
  (documents: DocumentState[], offset: number, limit: number) => {
    return {
      data: documents.slice(offset, limit),
      totalItems: documents.length,
    };
  },
);

export const getDocumentById = (
  state: RootState,
  id: string | number,
): DocumentState | undefined => {
  return state.documentListing.documents[`${id}`];
};

export const getLatestDocumentFromState = createSelector(
  (state: RootState) => getDocumentsFromState(state),
  (documents: DocumentState[]) => {
    return documents[0];
  },
);
