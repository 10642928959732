import React, { FC } from 'react';
import { Pagination as PaginationAntD } from 'antd';
import styled, { css } from 'styled-components';
import { Button } from 'src/components/Button';

interface PaginationProps {
  current?: number;
  items: number;
  onPageChange: (size: number) => void;
  onPageSizeChange: (size: number) => void;
  pageSize?: number;
  sizes?: number[];
}

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
`;

const BaseContainerStyle = css`
  display: flex;
  align-items: center;

  > span {
    font-size: 14px;
    letter-spacing: 0.05em;
    margin-right: 12px;
  }
`;

const PaginationContainer = styled.div`
  ${BaseContainerStyle};
`;

const ItemsPerSheetContainer = styled.div`
  ${BaseContainerStyle};

  > span {
    margin-right: 9px;
  }

  .btn {
    margin: 3px;
  }
`;

const PaginationStyled = styled(PaginationAntD)`
  .ant-pagination-item {
    border: none;
  }
`;

const itemRender = (
  page: number,
  type: string,
  originalElement: any,
  current: number,
): React.ReactNode => {
  if (type === 'prev') return <Button variant={'radio'} title={'<'} />;
  if (type === 'next') return <Button variant={'radio'} title={'>'} />;
  if (type === 'jump-prev' || type === 'jump-next')
    return <Button variant={'radio'} title={'...'} />;
  return (
    <Button
      altered={current === page}
      variant={'radio'}
      title={page.toString()}
    />
  );
};

export const Pagination: FC<PaginationProps> = ({
  current = 1,
  items,
  onPageChange,
  onPageSizeChange,
  pageSize = 10,
  sizes = [10, 50, 100],
}) => {
  return (
    <MainContainer>
      <ItemsPerSheetContainer>
        <span>Items Per Sheet:</span>
        {sizes.map((size) => (
          <Button
            altered={pageSize === size}
            className="btn"
            onClick={() => onPageSizeChange(size)}
            variant={'radio'}
            title={size.toString()}
            key={size}
          />
        ))}
      </ItemsPerSheetContainer>
      <PaginationContainer>
        <span>Pages:</span>
        <PaginationStyled
          current={current}
          itemRender={(page, type, originalElement) =>
            itemRender(page, type, originalElement, current)
          }
          onChange={(page) => onPageChange(page)}
          pageSize={pageSize}
          showSizeChanger={false}
          total={items}
        />
      </PaginationContainer>
    </MainContainer>
  );
};
