import styled from 'styled-components';

import { remConvert } from 'src/theme/utils/rem-convert';
import { TextInput } from 'src/components/TextInput';

export const FormSubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${remConvert(16)};
`;

export const PasswordRulesContainer = styled.div`
  font-family: ${({ theme }): string => theme.fontFamilies.terciary};
  font-style: ${({ theme }): string => theme.fontStyles.normal};
  font-weight: ${({ theme }): number => theme.fontWeights.semiBold};
  font-size: 12px;
  line-height: ${remConvert(14)};
  color: ${({ theme }): string => theme.colors.astral.main};
`;

export const PasswordRuleContainer = styled.div<{ isFulfilled: boolean }>`
  margin-bottom: ${remConvert(11)};
  color: ${({ isFulfilled }) => (isFulfilled ? 'auto' : 'grey')};
  img {
    filter: ${({ isFulfilled }) => (isFulfilled ? 'none' : 'grayscale(100%)')};
    opacity: ${({ isFulfilled }) => (isFulfilled ? '1' : '0.5')};
  }
`;

export const PasswordTextInput = styled(TextInput)`
  .ant-input-prefix {
    display: none;
  }

  margin-bottom: ${remConvert(18)};
`;

export const PasswordRuleCheckIcon = styled.img`
  margin-right: ${remConvert(6)};
`;

export const PasswordRulesDescription = styled.div`
  width: 402px;
  height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  margin-bottom: ${remConvert(18)};
`;
