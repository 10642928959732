import { useMemo, useCallback } from 'react';
import { ComponentsEnum } from 'src/shared/enums';

const useTesting = (componentId: ComponentsEnum) => {
  const getTestingProps = useCallback(
    (id: string) => {
      const testId = `${componentId}-${id}`;
      return { 'data-testid': testId };
    },
    [componentId],
  );
  return useMemo(() => ({ getTestingProps }), [getTestingProps]);
};

export default useTesting;
