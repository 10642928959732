import React from 'react';
import { HIPAAInfoRow } from './riskAssessmentTableTypes';
import { GridTag } from '../../../../components/Grid';
import * as S from './Styles';

export const hasHipaaInformation = (info: HIPAAInfoRow): boolean =>
  !!info.legalRef ||
  !!info.legalRefNist ||
  !!info.legalRefCmmc ||
  info.actionItem.length > 0 ||
  info.risk.length > 0 ||
  info.delegate.length > 0 ||
  info.analysis.length > 0;

export const InfoLabel = ({
  title,
  info,
}: {
  title: string;
  info: string | React.ReactNode;
}) => (
  <S.HipaaLabelContainer>
    <S.Label>
      <strong>{title}</strong>: {info || '-'}
    </S.Label>
  </S.HipaaLabelContainer>
);

export const presentHipaaRowInformation = (info: HIPAAInfoRow) => ({
  analysis: info.analysis.join(',') || '',
  actionItem: info.actionItem.join(',') || '',
  risk:
    info.risk.length > 0
      ? info.risk.map((riskLevel, idx) => (
          <GridTag key={idx} type={riskLevel.toLowerCase()}>
            {riskLevel}
          </GridTag>
        ))
      : '',
  delegate: info.delegate.join(',') || '',
  legalRef: info.legalRef || '',
  legalCmmc: info.legalRefCmmc || '',
  legalNist: info.legalRefNist || '',
});
