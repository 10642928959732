import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import styled from 'styled-components';
import { Button } from 'src/components/Button';
import { TopNavbar } from 'src/components/TopNavbar';
import { FormListingTable } from 'src/features/risk-assessment/components/FormListingTable';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { FormCreateForm } from 'src/features/risk-assessment/components/FormCreateForm';
import { Pagination } from 'src/components/Pagination';
import { FormFilters } from 'src/features/risk-assessment/components/FormFilters';
import { formsLoadRequest } from 'src/store/actions/formListing';
import { RootState, FormState } from 'src/store/types';
import { getForms } from 'src/store/selectors/formListing';
import { getFormLoader } from 'src/store/selectors/form';
import {
  getAssessmentTypes,
  getCategories,
  getCategoryLoader,
} from 'src/store/selectors';
import { assessmentTypesLoadAllRequest } from 'src/store/actions/assessmentTypes';
import { FormCreateCategory } from 'src/features/risk-assessment/components/FormCreateCategory';
import { categoryLoadRequest } from 'src/store/actions/category';

const FormListingContainer = styled.div`
  height: 65%;
  overflow: auto;
  margin-bottom: 10px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

enum CreationType {
  CATEGORY = 'CATEGORY',
  FORM = 'FORM',
}

export const FormListingPage: FC<unknown> = () => {
  const [selectedFormId, setSelectedFormId] = useState<number | undefined>(
    undefined,
  );
  const [formName, setFormName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [slidingPanelVisible, setSlidingPanelVisible] = useState(false);
  const [slidingPanelTitle, setSlidingPanelTitle] = useState<string>('');
  const [slidingPanelSubmitLabel, setSlidingPanelSubmitLabel] =
    useState<string>('');
  const [creationType, setCreationType] = useState<CreationType>(
    CreationType.FORM,
  );
  const [activeFilter, setActiveFilter] = useState<string>('');
  const [activeAssessmentType, setActiveAssessmentType] = useState<string>('');

  const categories = useSelector(getCategories);

  const forms = useSelector((state: RootState) =>
    getForms(state, {
      offset: (currentPage - 1) * pageSize,
      limit: currentPage * pageSize,
      status: activeFilter,
      name: formName,
      assessmentTypeLabel: activeAssessmentType,
    }),
  );

  const formLoader = useSelector(getFormLoader);
  const categoryLoader = useSelector(getCategoryLoader);
  const assessmentTypes = useSelector(getAssessmentTypes);

  const dispatch = useDispatch();
  const history = useHistory();
  const [formForm] = Form.useForm();
  const [categoryForm] = Form.useForm();

  useEffect(() => {
    dispatch(formsLoadRequest(true));
    dispatch(assessmentTypesLoadAllRequest());
    dispatch(categoryLoadRequest());
  }, []);

  useEffect(() => {
    if (formLoader.completed && formLoader.success) {
      setSlidingPanelVisible(false);
      dispatch(formsLoadRequest(true));
      dispatch(categoryLoadRequest());
      formForm.resetFields();
    }
  }, [formLoader.completed]);

  useEffect(() => {
    if (categoryLoader.completed && categoryLoader.success) {
      setSlidingPanelVisible(false);
      dispatch(categoryLoadRequest());
      categoryForm.resetFields();
    }
  }, [categoryLoader.completed]);

  const handleSaveButtonPress = (): void => {
    const submit =
      creationType === CreationType.FORM
        ? formForm.submit
        : categoryForm.submit;
    submit();
  };

  const showCreateFormPanel = (): void => {
    setSlidingPanelTitle('Create a Form');
    setSlidingPanelSubmitLabel('Create');
    setSlidingPanelVisible(true);
    setCreationType(CreationType.FORM);
  };

  const showCreateCategoryPanel = (): void => {
    setSlidingPanelTitle('Create a Category');
    setSlidingPanelSubmitLabel('Create');
    setSlidingPanelVisible(true);
    setCreationType(CreationType.CATEGORY);
  };

  const showUpdateFormPanel = (form: FormState) => {
    const {
      id,
      label,
      categoryFolder,
      creator,
      description,
      updateReason,
      assessment,
      required,
      isActive,
      isDraft,
      assessmentType,
    } = form;

    formForm.setFieldsValue({
      id,
      label,
      categoryId: categoryFolder?.id,
      creator,
      description,
      updateReason,
      assessmentId: assessment,
      assessmentTypeId: assessmentType?.id,
      required,
      isActive,
      isDraft,
    });
    setSelectedFormId(form.id);
    setSlidingPanelTitle('Update Form');
    setSlidingPanelSubmitLabel('Update');
    setSlidingPanelVisible(true);
  };

  const showInterviewPreview = () => {
    history.push('/forms/categories');
  };

  return (
    <>
      <TopNavbar
        title={'Forms'}
        prevRoute={null}
        extraOptions={[
          <Button
            onClick={showInterviewPreview}
            title={'Manage Form Categories'}
            key={1}
          />,
          <Button
            onClick={showCreateCategoryPanel}
            title={'Create Category'}
            key={2}
          />,
          <Button
            onClick={showCreateFormPanel}
            title={'Create Form'}
            key={3}
          />,
        ]}
      />

      <PageContentContainer>
        <FormFilters
          assesmentTypes={assessmentTypes}
          onStatusChange={setActiveFilter}
          onAssesmentChange={setActiveAssessmentType}
          onSearchFormChange={setFormName}
        />
        <FormListingContainer>
          <FormListingTable
            forms={forms.data}
            showUpdateFormPanel={showUpdateFormPanel}
          />
        </FormListingContainer>

        {forms.totalItems > pageSize && (
          <Pagination
            current={currentPage}
            items={forms.totalItems}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={(size) => {
              setPageSize(size);
              setCurrentPage(1);
            }}
            sizes={[10, 25, 50]}
          />
        )}

        <SlidingPanel
          title={slidingPanelTitle}
          visible={slidingPanelVisible}
          saveLabel={slidingPanelSubmitLabel}
          onClose={() => setSlidingPanelVisible(false)}
          onSave={handleSaveButtonPress}
          loading={
            creationType === CreationType.FORM
              ? formLoader.loading
              : categoryLoader.loading
          }
        >
          {creationType === CreationType.FORM ? (
            <FormCreateForm
              form={formForm}
              assessment={assessmentTypes}
              formToUpdateId={selectedFormId}
              categories={categories}
            />
          ) : (
            <FormCreateCategory form={categoryForm} />
          )}
        </SlidingPanel>
      </PageContentContainer>
    </>
  );
};
