/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
  Loader,
  RootState,
  Training,
  TrainingSubmission,
  TrainingSubmissionResult,
} from 'src/store/types';

export const getTrainingLoader = (state: RootState): Loader =>
  state.training.loader;
export const getCurrentTraining = (state: RootState): Training =>
  state.training.training;
export const getCurrentSubmission = (state: RootState): TrainingSubmission =>
  state.training.submission;
export const getCurrentSubmissionResult = (
  state: RootState,
): TrainingSubmissionResult => state.training.submissionResult;
