import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Question } from 'src/store/types';
import { FormField } from 'src/features/risk-assessment/components/FormField';
import {
  formSetQuestionSelected,
  formMarkQuestionAsDeleted,
  formCopyQuestion,
} from 'src/store/actions/form';

export interface WorkbenchQuestionProps {
  className?: string;
  question: Question;
  formId: number;
  children?: React.ReactNode;
  isLast?: boolean;
}
export const WorkbenchQuestion: FC<WorkbenchQuestionProps> = ({
  className = '',
  question,
  children,
  isLast = false,
  formId,
}) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleCopy = (question: Question) => {
    dispatch(formCopyQuestion(formId, question.id, question.internalId));
  };
  const handleEdit = (question: Question) => {
    dispatch(formSetQuestionSelected(question));
  };
  const handleDelete = () => {
    dispatch(formMarkQuestionAsDeleted(question.id, question.internalId));
  };
  let title = '';
  if (question?.question) {
    title = question?.question;
  } else {
    title = `(${question?.type?.label})`;
  }
  return (
    <FormField
      className={className}
      question={question}
      onCopyPress={handleCopy}
      onEditPress={handleEdit}
      onDeletePress={handleDelete}
      isLast={isLast}
      title={title}
    >
      {children}
    </FormField>
  );
};
