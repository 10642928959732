export enum ErrorSeverity {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export const SHOW_ERROR = 'SHOW_ERROR';

export interface ErrorState {
  message: string | string[];
  severity: ErrorSeverity;
  originalError?: Error;
}

export interface ShowErrorAction<T> {
  type: T;
  message: string | string[];
  severity: ErrorSeverity;
  originalError?: Error;
}

export type ErrorActionTypes = ShowErrorAction<any>;
