import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Tooltip } from 'src/components/Tooltip';
import { TextInput } from 'src/components/TextInput';
import {
  Button,
  ButtonRounded,
  ButtonRectangular,
} from 'src/components/Button';
import { TextArea } from 'src/components/TextArea';
import { Dropdown } from 'src/components/Dropdown';
import { error, success, info } from 'src/shared/utils/toast';
import { DatePicker } from 'src/components/DatePicker';
import { SearchInput } from 'src/components/SearchInput';

const RowContainer = styled(Row)`
  padding: 30px;
`;

const Color = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 25px;
`;

const Color1 = styled(Color)`
  background-color: ${({ theme }): string => theme.colors.astral.main}}
`;

const Color2 = styled(Color)`
  background-color: ${({ theme }): string => theme.colors.bostonBlue.main}}
`;

const Color3 = styled(Color)`
  background-color: ${({ theme }): string => theme.colors.esmerald.main}}
`;

const Color4 = styled(Color)`
  background-color: ${({ theme }): string => theme.colors.amazon.main}}
`;

const Color5 = styled(Color)`
  background-color: ${({ theme }): string => theme.colors.chathamsBlue.main}}
`;

const Color6 = styled(Color)`
  background-color: ${({ theme }): string => theme.colors.mariner.main}}
`;

const Color7 = styled(Color)`
  background-color: ${({ theme }): string => theme.colors.concrete.main}}
`;

const Color8 = styled(Color)`
  background-color: ${({ theme }): string => theme.colors.athensGray.main}}
`;

const StyleGuideContainer = styled.div`
  padding: 80px;
`;

const Title = styled.p`
  font-family: ${({ theme }): string => theme.fontFamilies.primary}};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
`;

const SecondaryTitle = styled.p`
  font-family: ${({ theme }): string => theme.fontFamilies.primary}};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
`;

const CardTitle = styled.p`
  font-family: ${({ theme }): string => theme.fontFamilies.primary}};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
`;

const StandardText = styled.p`
  font-family: ${({ theme }): string => theme.fontFamilies.primary}};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const SmallDescriptor = styled.p`
  font-family: ${({ theme }): string => theme.fontFamilies.primary}};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const StyleGuide: FC = () => {
  return (
    <StyleGuideContainer>
      <div>
        <h2>Colors Palette</h2>
        <Row gutter={[16, 16]}>
          <Col xs={12} md={3}>
            <Color1 />
          </Col>
          <Col xs={12} md={3}>
            <Color2 />
          </Col>
          <Col xs={12} md={3}>
            <Color3 />
          </Col>
          <Col xs={12} md={3}>
            <Color4 />
          </Col>
          <Col xs={12} md={3}>
            <Color5 />
          </Col>
          <Col xs={12} md={3}>
            <Color6 />
          </Col>
          <Col xs={12} md={3}>
            <Color7 />
          </Col>
          <Col xs={12} md={3}>
            <Color8 />
          </Col>
        </Row>
      </div>
      <div>
        <h2>Buttons</h2>
        <RowContainer gutter={[16, 16]}>
          <Col sm={24} md={8}>
            <Button title="Small" size="small" disabled />
          </Col>
          <Col sm={24} md={8}>
            <Button title="Small" size="small" />
          </Col>
          <Col sm={24} md={8}>
            <Button title="Small" size="small" />
          </Col>
        </RowContainer>
        <RowContainer gutter={[16, 16]}>
          <Col sm={24} md={8}>
            <Button title="Middle" size="middle" disabled />
          </Col>
          <Col sm={24} md={8}>
            <Button title="Middle" size="middle" />
          </Col>
          <Col sm={24} md={8}>
            <Button title="Middle" size="middle" />
          </Col>
        </RowContainer>
        <RowContainer gutter={[16, 16]}>
          <Col sm={24} md={8}>
            <Button title="Large" size="large" disabled />
          </Col>
          <Col sm={24} md={8}>
            <Button title="Large" size="large" />
          </Col>
          <Col sm={24} md={8}>
            <Button title="Large" size="large" />
          </Col>
        </RowContainer>
        <RowContainer>
          <Col sm={24} md={12}>
            <ButtonRounded title="label" disabled />
          </Col>
          <Col sm={24} md={12}>
            <ButtonRounded title="label" />
          </Col>
        </RowContainer>
        <RowContainer gutter={[16, 16]}>
          <Col sm={24} md={12}>
            <ButtonRectangular title="Retake Quiz" disabled />
          </Col>
          <Col sm={24} md={12}>
            <ButtonRectangular title="Retake Quiz" />
          </Col>
        </RowContainer>
      </div>
      <div>
        <h2>Inputs</h2>
        <Row>
          <Col xs={24} md={8}>
            <TextInput float placeholder="Text Input" />
          </Col>
          <Col xs={24} md={8}>
            <TextInput password placeholder="Password Text Input" />
          </Col>
          <Col xs={24} md={8}>
            <Dropdown
              placeholder={'Product Selection'}
              onChange={() => console.log}
              options={[]}
            />
          </Col>
          <Col xs={24} md={8}>
            <DatePicker
              onChange={() => console.log}
              value={null}
              placeholder={'style-guide'}
            />
          </Col>
          <Col xs={24} md={8}>
            <TextArea />
          </Col>
          <Col xs={24} md={8}>
            <SearchInput />
          </Col>
        </Row>
      </div>
      <div>
        <h2>Fonts Open Sans</h2>
        <Title>Title - 30px, semiBold (36.6)</Title>
        <SecondaryTitle>Secondary Title</SecondaryTitle>
        <CardTitle>Card Title</CardTitle>
        <StandardText>Standard Text</StandardText>
        <SmallDescriptor>Small Descriptor</SmallDescriptor>
      </div>
      <div>
        <h2>Headings</h2>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
      </div>
      <div>
        <h2>Responsive System</h2>
        <Row>
          <Col xs={24} md={12} xl={6}>
            <div style={{ background: 'red' }}>Column 1</div>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <div style={{ background: 'lightblue' }}>Column 2</div>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <div style={{ background: 'yellow' }}>Column 3</div>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <div style={{ background: 'green' }}>Column 4</div>
          </Col>
        </Row>
      </div>
      <div>
        <h2>Tooltip</h2>
        <Tooltip text=" Enter your work email address. If your work email address has changed, please contact your organization’s HIPAA administrator.">
          <span>Hello this is a tooltip description</span>
        </Tooltip>
      </div>
      <div>
        <h2>Toasts</h2>
        <Row>
          <Col xs={24} md={8}>
            <Button
              title={'Show Error'}
              onClick={() =>
                error(
                  'Your reset password has been sent',
                  'Please check your email',
                )
              }
            />
          </Col>
          <Col xs={24} md={8}>
            <Button
              title={'Show Info'}
              onClick={() => info('All ready!', 'Profile Saved')}
            />
          </Col>
          <Col xs={24} md={8}>
            <Button
              title={'Show Success'}
              onClick={() =>
                success('Your password has been expired', 'Welcome Back')
              }
            />
          </Col>
        </Row>
      </div>
    </StyleGuideContainer>
  );
};
