import React, { FC } from 'react';
import styled from 'styled-components';
import { Card } from 'src/features/risk-assessment/components/Card';
import {
  mapCompanyType,
  mapTypeOfTraining,
} from 'src/features/risk-assessment/components/CompanyListingTable/dataMapper';
import { CompanyState, TypeOfTraining } from 'src/store/types';

interface CompanyInfoCardProps {
  company: CompanyState;
  licensesRemainder?: number;
}

const CardsContainer = styled(Card)`
  width: 100%;
  height: auto;
  padding: 16px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const BlueText = styled.div`
  color: ${({ theme }) => theme.colors.deepBlue.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  margin-bottom: 12px;
  font-size: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const GreenText = styled.div`
  color: green;
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  margin-bottom: 12px;
  font-size: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const RedText = styled.div`
  color: ${({ theme }) => theme.colors.red.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  margin-bottom: 12px;
  font-size: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled(BlueText)`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const CompanyInfoCard: FC<CompanyInfoCardProps> = (props) => {
  const { company, licensesRemainder } = props;
  const {
    typeOfTraining,
    employees,
    licenses,
    address,
    state,
    companyType,
    trainingType,
  } = company;
  return (
    <CardsContainer>
      <Title>
        <BoldSpan>
          {mapTypeOfTraining(typeOfTraining as TypeOfTraining).toUpperCase()}
        </BoldSpan>
      </Title>
      {typeOfTraining === TypeOfTraining.HIPAA_PRIME && (
        <BlueText>
          <BoldSpan># of Employees:</BoldSpan> {employees}
        </BlueText>
      )}
      <BlueText>
        <BoldSpan># of Licenses:</BoldSpan> {licenses}
      </BlueText>
      <BlueText>
        <BoldSpan>Address:</BoldSpan> {address}
      </BlueText>
      <BlueText>
        <BoldSpan>State:</BoldSpan> {state}
      </BlueText>
      <BlueText>
        <BoldSpan>Company type:</BoldSpan>{' '}
        {companyType ? mapCompanyType(companyType) : ''}
      </BlueText>
      <BlueText>
        <BoldSpan>Training type:</BoldSpan> {trainingType?.name ?? '--'}
      </BlueText>
      {company.users && (
        <BlueText>
          <BoldSpan>Company users:</BoldSpan> {company.users.length}
        </BlueText>
      )}
      {licensesRemainder !== undefined && licensesRemainder > 0 && (
        <GreenText>
          <BoldSpan>Licenses Left:</BoldSpan> {licensesRemainder}
        </GreenText>
      )}
      {licensesRemainder !== undefined && licensesRemainder <= 0 && (
        <RedText>
          <BoldSpan>Licenses Left:</BoldSpan> {licensesRemainder}
        </RedText>
      )}
    </CardsContainer>
  );
};
