import React, { FC, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import 'codemirror/addon/mode/simple';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/jump-to-line';
import 'codemirror/addon/display/panel';
import 'codemirror/theme/3024-day.css';
import 'codemirror/keymap/sublime';
import 'codemirror-revisedsearch';
import CodeMirror from 'codemirror';
import CodeMirrorControl from '@uiw/react-codemirror';
import { QuestionListingIndexedType, RootState } from 'src/store/types';
import {
  getIndexedQuestionListing,
  getVariables,
  getConstants,
} from 'src/store/selectors';
import { remConvert } from 'src/theme/utils';
import { baseOpenSans } from 'src/theme';
import { useRegExpTokens } from './useRegExpTokens';
import { Toolbar } from './Toolbar';
import { TemplateEditorMessage } from './TemplateErrorMessage';

CodeMirror.defineSimpleMode('thl', {
  start: [
    { regex: /\[if condition='/, token: 'variable-2' },
    { regex: /[\]\[|\'\]']/, token: 'variable-2' },
    { regex: /\/if/, token: 'variable-2' },
    { regex: /\else/, token: 'variable-2' },
    { regex: /[-+\/*=<>!]+/, token: 'operator' },
    {
      regex: /0x[a-f\d]+|[-+]?(?:\.\d+|\d+\.?\d*)(?:e[-+]?\d+)?/i,
      token: 'number',
    },
    { regex: /"(?:[^\\]|\\.)*?(?:"|$)/, token: 'comment' },
    { regex: /true|false/, token: 'variable' },
  ],
});

interface TemplateEditorProps {
  source?: string;
  templateId: number;
  errorMessage?: string;
  onChange: (val?: string) => void;
  setHasConflicts: (val: boolean) => void;
  isTemplateProcessing: boolean;
}

const StyleOverride = styled.div`
  position: relative;
  height: 71vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  .CodeMirror {
    height: 69vh !important;
  }

  .CodeMirror-advanced-dialog {
    padding: ${remConvert(5)};
    background: ${({ theme }) => theme.colors.white.main};
    position: absolute;
    z-index: 10;

    button,
    button:hover {
      width: ${remConvert(106)};
      height: ${remConvert(30)};
      ${baseOpenSans}
      font-weight: 700;
      font-size: 13px;
      border-radius: 15px;
      color: #f4fcff;
      margin-top: ${remConvert(5)};
      margin-bottom: ${remConvert(5)};
      cursor: pointer;
    }

    button {
      background: #1f5c72;
      border: 2px solid #1f5c72;
    }

    button:hover {
      background: linear-gradient(180deg, #337d98 0%, #3e8fac 100%);
    }

    input,
    input:focus {
      background: ${({ theme }) => theme.colors.white.main};
      border: 1px solid rgba(0, 0, 0, 0.4);
      border-radius: 4px;
    }
  }

  & .cm-theme-light {
    height: 100%;
    overflow: auto;

    & .cm-editor {
      height: 100%;
    }
  }

  & ::selection {
    background-color: rgb(24 144 255 / 40%);
    color: white;
  }

  .center {
    text-align: center;
  }

  a[href^='mailto:'] {
    color: inherit;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
  }
`;

export const TemplateEditor: FC<TemplateEditorProps> = (props) => {
  const { source, templateId, errorMessage, isTemplateProcessing } = props;
  const { onChange, setHasConflicts } = props;

  const [validateConflicts, setValidateConflicts] = useState<boolean>(true);

  const questions = useSelector<RootState, QuestionListingIndexedType>(
    (state: RootState) => getIndexedQuestionListing(state),
  );
  const variables = useSelector(getVariables);
  const constants = useSelector(getConstants);

  const regExpTokens = useRegExpTokens(questions, variables, constants);

  const { conflictToken, needsReviewToken } = regExpTokens;

  useEffect(() => {
    if (source && validateConflicts) {
      setValidateConflicts(false);
      const hasConflicts =
        !!source && source.includes(conflictToken.getConflictStart());
      setHasConflicts(hasConflicts);

      if (hasConflicts) {
        if (!source?.startsWith(needsReviewToken.getToken())) {
          // Add review token
          onChange(`${needsReviewToken.getToken()}${source}`);
        }
      } else if (source?.startsWith(needsReviewToken.getToken())) {
        // Remove review token
        onChange(source.substring(needsReviewToken.getToken().length));
      }
    }
  }, [validateConflicts]);

  const insertFromToolbar = (val: string) => {
    onChange(source ? source + ` ${val}` : val);
  };

  if (!source) {
    return null;
  }

  return (
    <StyleOverride>
      <Toolbar
        regExpTokens={regExpTokens}
        templateId={templateId}
        updateEditorContent={insertFromToolbar}
      />
      <CodeMirrorControl
        value={source}
        options={{
          lineWrapping: true,
          readOnly: isTemplateProcessing,
          theme: '3024-day',
          keyMap: 'sublime',
        }}
        onChange={(instance) => {
          if (instance.getValue() !== source) {
            onChange(instance.getValue());
          }
        }}
      />
      {errorMessage && <TemplateEditorMessage message={errorMessage} />}
    </StyleOverride>
  );
};
