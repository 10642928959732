import React, { FC } from 'react';

import { Button } from 'src/components/Button';
import * as M from 'src/theme/media';
import { QuizQuestion, TrainingSubmissionResponse } from 'src/store/types';
import { TrainingSubmissionResponsesTable } from '../TrainingSubmissionResponsesTable';
import { SimpleRadialChart } from '../SimpleRadialChart';
import * as S from './Styles';

interface QuizResultProps {
  hasPassed: boolean;
  passedChoices: number;
  failedChoices: number;
  totalChoices: number;
  passingScore: number;
  questions: QuizQuestion[];
  responses?: TrainingSubmissionResponse[];
  takeAgainHandler: () => void;
  keepTrainingHandler: () => void;
}

export const QuizResult: FC<QuizResultProps> = ({
  hasPassed,
  passedChoices,
  failedChoices,
  totalChoices,
  questions,
  passingScore,
  responses,
  takeAgainHandler,
  keepTrainingHandler,
}) => {
  return (
    <>
      <S.QuizSummaryContainer>
        <div>
          <S.SummaryStatus>
            {hasPassed ? (
              <>
                <img src={M.THSuccessHand} />
                <S.SuccessText>Good Work!</S.SuccessText>
              </>
            ) : (
              <>
                <img src={M.THFailedFace} />
                <S.FailureText>Oh no!</S.FailureText>
              </>
            )}
          </S.SummaryStatus>
          <S.SummaryText>
            You answered {passedChoices} out of {totalChoices} questions
            correctly. A Passing score is {passingScore}%.{' '}
          </S.SummaryText>
          <div
            style={{ display: 'flex', justifyContent: 'left', marginTop: 20 }}
          >
            {hasPassed ? (
              <Button title="Keep Training" onClick={keepTrainingHandler} />
            ) : (
              <Button title="Try Again" onClick={takeAgainHandler} />
            )}
          </div>
        </div>
        <S.RadialChartContainer>
          {
            <SimpleRadialChart
              progress={(passedChoices * 100) / (failedChoices + passedChoices)}
            />
          }
        </S.RadialChartContainer>
      </S.QuizSummaryContainer>
      <div>
        {responses && (
          <TrainingSubmissionResponsesTable
            currentQuestions={questions}
            currentSubmissionResponses={responses}
          />
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <Button
          title={hasPassed ? 'Keep Training' : 'Try Again'}
          onClick={hasPassed ? keepTrainingHandler : takeAgainHandler}
        />
      </div>
    </>
  );
};
