import React, { FC } from 'react';
import { Table, Empty } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Grid, GridTag } from 'src/components/Grid';
import { DocumentListingSettings } from 'src/features/risk-assessment/components/DocumentListingSettings';
import { DocumentState, DocumentStatus } from 'src/store/types';
import { EyeOutlined } from 'src/theme/icons';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { dataMapper } from './dataMapper';

export interface DocumentListingTableProps {
  documents: DocumentState[];
  assessmentId: number;
  companyId: number;
}

export interface TableRowInterface {
  id: number;
  name: string;
  status: DocumentStatus;
  createdAt: string;
}

const { Column } = Table;

const Options = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  .icon {
    font-size: 20px;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #e0e0e0;
  }
`;

const Label = styled.span`
  color: black;
`;

export const DocumentListingTable: FC<DocumentListingTableProps> = ({
  documents: rawDocuments,
  assessmentId,
  companyId,
}) => {
  const { isAdmin } = useAuth();
  const dataSource: TableRowInterface[] = dataMapper(rawDocuments);

  const getTagStyle = (status: DocumentStatus) => {
    switch (status) {
      case DocumentStatus.APPROVED:
        return 'completed';
      case DocumentStatus.CLOSED:
        return 'inactive';
      case DocumentStatus.CLIENT_REVIEW:
      case DocumentStatus.ACTIVE:
        return 'active';
      case DocumentStatus.ACCEPTED:
        return 'accepted';
    }
  };

  const getUrl = (record: TableRowInterface) => {
    return `/companies/${companyId}/assessments/${assessmentId}/assessmentReports/${record.id}`;
  };

  return (
    <Grid
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
    >
      <Column<TableRowInterface>
        title="Document"
        dataIndex="name"
        key="name"
        render={(_, record: TableRowInterface) => (
          <Link to={getUrl(record)}>
            <Label>{record.name}</Label>
          </Link>
        )}
      />
      <Column<TableRowInterface>
        title="Creation Date"
        dataIndex="createdAt"
        key="createdAt"
      />
      <Column<TableRowInterface>
        title="Status"
        dataIndex="status"
        key="status"
        render={(_, record: TableRowInterface) => (
          <GridTag type={getTagStyle(record.status)}>{record.status}</GridTag>
        )}
      />
      <Column<TableRowInterface>
        title={false}
        dataIndex=""
        key="action"
        render={(_, record: TableRowInterface) => (
          <Options>
            <Link to={getUrl(record)} className="icon" title="Review">
              <EyeOutlined />
            </Link>
            {isAdmin && <DocumentListingSettings documentId={record.id} />}
          </Options>
        )}
      />
    </Grid>
  );
};
