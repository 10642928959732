import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { capitalize } from 'src/shared/utils';
import { getQuestionTypesByCategory } from 'src/store/selectors/questionTypes';
import { QuestionType, RootState } from 'src/store/types';
import { SearchInput } from 'src/components/SearchInput';
import { FieldType } from './FieldType';

export interface FieldsCatalogueProps {
  onSelection: (question: QuestionType | null) => void;
}

const CatalogueSectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.deepBlue.main};
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  margin: 20px 0px 14px;
`;

const EmptyState = styled.p`
  color: ${({ theme }) => theme.colors.grayscale.darkGray};
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
`;

export const FieldsCatalogue: FC<FieldsCatalogueProps> = ({ onSelection }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const types = useSelector((state: RootState) =>
    getQuestionTypesByCategory(state, {
      filter: searchTerm,
    }),
  );
  const handleSelection = (question: QuestionType) => {
    if (selected === question.id) {
      setSelected(-1);
      onSelection(null);
    } else {
      setSelected(question.id);
      onSelection(question);
    }
  };

  const [selected, setSelected] = useState<number>(-1);

  const keys = Object.keys(types);
  return (
    <>
      <SearchInput
        placeholder="Search"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {!keys.length && (
        <EmptyState>No fields matching current criteria</EmptyState>
      )}
      <div>
        {keys.map((value: string) => (
          <div key={value}>
            <CatalogueSectionTitle>{capitalize(value)}</CatalogueSectionTitle>
            {types[value]?.map((e: QuestionType, k: number) => (
              <FieldType
                key={e.id}
                label={e.label}
                selected={selected === e.id}
                onClick={() => handleSelection(e)}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
