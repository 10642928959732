import {
  DocumentDraftState,
  DocumentDraftFromResponse,
  DocumentDraftLoadSuccessAction,
  DOCUMENT_DRAFT_LOAD_REQUEST,
  DocumentDraftLoadRequestAction,
  DOCUMENT_DRAFT_LOAD_SUCCESS,
  DocumentDraftLoadErrorAction,
  DOCUMENT_DRAFT_LOAD_ERROR,
  SET_SELECTED_DOCUMENT_DRAFT,
  DOCUMENT_DRAFT_SAVE_ERROR,
  DocumentDraftSaveErrorAction,
  DOCUMENT_DRAFT_SAVE_SUCCESS,
  DocumentDraftSaveSuccessAction,
  DOCUMENT_DRAFT_SAVE_REQUEST,
  DocumentDraftSaveRequestAction,
  SetSelectedDocumentDraftAction,
  DocumentDraftInput,
  DocumentDraftUpdateRequestAction,
  DOCUMENT_DRAFT_UPDATE_REQUEST,
  DocumentDraftUpdateErrorAction,
  DocumentDraftUpdateSuccessAction,
  DOCUMENT_DRAFT_UPDATE_SUCCESS,
  DOCUMENT_DRAFT_UPDATE_ERROR,
} from '../types';
import { showErrorMessage } from './error';

export const documentDraftLoadRequest = (
  documentDraftId: number | string,
  withTemplate = false,
): DocumentDraftLoadRequestAction => ({
  type: DOCUMENT_DRAFT_LOAD_REQUEST,
  documentDraftId,
  withTemplate,
});

export const documentDraftLoadSuccess = (
  documentDraft: DocumentDraftFromResponse,
): DocumentDraftLoadSuccessAction => ({
  type: DOCUMENT_DRAFT_LOAD_SUCCESS,
  documentDraft,
});

export const documentDraftLoadError = (
  errorMessage: string,
  originalError: Error,
): DocumentDraftLoadErrorAction =>
  showErrorMessage(DOCUMENT_DRAFT_LOAD_ERROR, errorMessage, originalError);

export const setSelectedDocumentDraft = (
  documentDraft: DocumentDraftState,
): SetSelectedDocumentDraftAction => ({
  type: SET_SELECTED_DOCUMENT_DRAFT,
  documentDraft,
});

export const documentDraftSaveRequest = (
  documentDraft: DocumentDraftState | DocumentDraftInput,
): DocumentDraftSaveRequestAction => ({
  type: DOCUMENT_DRAFT_SAVE_REQUEST,
  documentDraft,
});

export const documentDraftSaveSuccess = (
  documentDraft: DocumentDraftFromResponse,
): DocumentDraftSaveSuccessAction => ({
  type: DOCUMENT_DRAFT_SAVE_SUCCESS,
  documentDraft,
});

export const documentDraftSaveError = (
  errorMessage: string,
  originalError: Error,
): DocumentDraftSaveErrorAction =>
  showErrorMessage(DOCUMENT_DRAFT_SAVE_ERROR, errorMessage, originalError);

export const documentDraftUpdateRequest = (
  documentDraftId: number | string,
  documentDraft: DocumentDraftState | DocumentDraftInput,
): DocumentDraftUpdateRequestAction => ({
  type: DOCUMENT_DRAFT_UPDATE_REQUEST,
  documentDraftId,
  documentDraft,
});

export const documentDraftUpdateSuccess = (
  documentDraft: DocumentDraftFromResponse,
): DocumentDraftUpdateSuccessAction => ({
  type: DOCUMENT_DRAFT_UPDATE_SUCCESS,
  documentDraft,
});

export const documentDraftUpdateError = (
  errorMessage: string,
  originalError: Error,
): DocumentDraftUpdateErrorAction =>
  showErrorMessage(DOCUMENT_DRAFT_UPDATE_ERROR, errorMessage, originalError);
