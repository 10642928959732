import { call, takeEvery, put } from 'redux-saga/effects';
import {
  ASSESSMENT_UPDATE_REQUEST,
  ASSESSMENT_UPDATE_STATUS_REQUEST,
  ASSESSMENT_LOAD_REQUEST,
  ASSESSMENT_LOAD_SUGGESTIONS_REQUEST,
  ASSESSMENT_SAVE_REQUEST,
  ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST,
  AssessmentUpdateRequestAction,
  AssessmentFromResponse,
  AssessmentUpdateStatusRequestAction,
  AssessmentState,
  AssessmentLoadRequestAction,
  AssessmentStatus,
  AssessmentLoadSuggestionsRequestAction,
  AssessmentSaveRequestAction,
  AssessmentLoadRiskAnalysisRequestAction,
  AssessmentRiskAnalysisFromResponse,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const updateAssessment = function* async({
  assessment: assessmentData,
}: AssessmentUpdateRequestAction): Generator {
  try {
    const assessment = yield call(api.updateAssessment, assessmentData);
    yield put(
      actions.assessmentUpdateSuccess(assessment as AssessmentFromResponse),
    );
  } catch (err) {
    yield put(
      actions.assessmentUpdateError(
        'Failed to update assessment',
        err as Error,
      ),
    );
  }
};

export const updateAssessmentStatus = function* async({
  assessmentId,
  status,
}: AssessmentUpdateStatusRequestAction): Generator {
  try {
    if (status === AssessmentStatus.COMPLETED) {
      yield call(api.completeAssessment, assessmentId);
    } else {
      yield call(api.updateAssessment, {
        id: assessmentId,
        status,
      } as AssessmentState);
    }
    yield put(actions.assessmentUpdateStatusSuccess(assessmentId, status));
  } catch (err) {
    yield put(
      actions.assessmentUpdateStatusError(
        'Failed to update assessment',
        err as Error,
      ),
    );
  }
};

export const loadAssessment = function* async({
  assessmentId,
}: AssessmentLoadRequestAction): Generator {
  try {
    const assessment = yield call(api.loadAssessment, assessmentId);
    yield put(
      actions.assessmentLoadSuccess(assessment as AssessmentFromResponse),
    );
  } catch (err) {
    yield put(
      actions.assessmentLoadError('Failed to load assessment', err as Error),
    );
  }
};

export const loadAssessmentSuggestions = function* async({
  assessmentId,
  excludeInterviewId,
}: AssessmentLoadSuggestionsRequestAction): Generator {
  try {
    const suggestions = yield call(
      api.loadAssessmentSuggestions,
      assessmentId,
      excludeInterviewId,
    );
    yield put(
      actions.assessmentLoadSuggestionsSuccess(
        suggestions as { [k: string]: string[] },
      ),
    );
  } catch (err) {
    yield put(
      actions.assessmentLoadSuggestionsError(
        'Failed to load assessment suggestions',
        err as Error,
      ),
    );
  }
};

export const saveAssessment = function* async({
  assessmentInput,
}: AssessmentSaveRequestAction): Generator {
  try {
    const assessment = yield call(api.saveAssessment, assessmentInput);
    yield put(
      actions.assessmentLoadSuccess(assessment as AssessmentFromResponse),
    );
  } catch (err) {
    yield put(
      actions.assessmentSaveError('Failed to save assessment', err as Error),
    );
  }
};

export const loadAssessmentRiskAnalysis = function* async({
  assessmentId,
}: AssessmentLoadRiskAnalysisRequestAction): Generator {
  try {
    const assessmentRiskAnalysis = yield call(
      api.loadAssessmentRiskAnalysis,
      assessmentId,
    );
    yield put(
      actions.assessmentLoadRiskAnalysisSuccess(
        assessmentRiskAnalysis as AssessmentRiskAnalysisFromResponse,
      ),
    );
  } catch (err) {
    yield put(
      actions.assessmentLoadRiskAnalysisError(
        'Failed to load assessment risk analysis',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(ASSESSMENT_LOAD_REQUEST, loadAssessment),
    yield takeEvery(ASSESSMENT_UPDATE_REQUEST, updateAssessment),
    yield takeEvery(ASSESSMENT_UPDATE_STATUS_REQUEST, updateAssessmentStatus),
    yield takeEvery(
      ASSESSMENT_LOAD_SUGGESTIONS_REQUEST,
      loadAssessmentSuggestions,
    ),
    yield takeEvery(ASSESSMENT_SAVE_REQUEST, saveAssessment),
    yield takeEvery(
      ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST,
      loadAssessmentRiskAnalysis,
    ),
  ];
}
