import {
  AssessmentTypeFromResponse,
  InterviewFromResponse,
  DocumentFromResponse,
  CompanyFromResponse,
  ShowErrorAction,
} from '../types';
import { AssessmentTypeState } from './assessmentType';
import { Loader } from './loader';

export const ASSESSMENT_UPDATE_REQUEST = 'ASSESSMENT_UPDATE_REQUEST';
export const ASSESSMENT_UPDATE_SUCCESS = 'ASSESSMENT_UPDATE_SUCCESS';
export const ASSESSMENT_UPDATE_ERROR = 'ASSESSMENT_UPDATE_ERROR';

export const ASSESSMENT_UPDATE_STATUS_REQUEST =
  'ASSESSMENT_UPDATE_STATUS_REQUEST';
export const ASSESSMENT_UPDATE_STATUS_SUCCESS =
  'ASSESSMENT_UPDATE_STATUS_SUCCESS';
export const ASSESSMENT_UPDATE_STATUS_ERROR = 'ASSESSMENT_UPDATE_STATUS_ERROR';

export const ASSESSMENT_LOAD_REQUEST = 'ASSESSMENT_LOAD_REQUEST';
export const ASSESSMENT_LOAD_SUCCESS = 'ASSESSMENT_LOAD_SUCCESS';
export const ASSESSMENT_LOAD_ERROR = 'ASSESSMENT_LOAD_ERROR';

export const ASSESSMENT_LOAD_SUGGESTIONS_REQUEST =
  'ASSESSMENT_LOAD_SUGGESTIONS_REQUEST';
export const ASSESSMENT_LOAD_SUGGESTIONS_SUCCESS =
  'ASSESSMENT_LOAD_SUGGESTIONS_SUCCESS';
export const ASSESSMENT_LOAD_SUGGESTIONS_ERROR =
  'ASSESSMENT_LOAD_SUGGESTIONS_ERROR';

export const ASSESSMENT_SAVE_REQUEST = 'ASSESSMENT_SAVE_REQUEST';
export const ASSESSMENT_SAVE_SUCCESS = 'ASSESSMENT_SAVE_SUCCESS';
export const ASSESSMENT_SAVE_ERROR = 'ASSESSMENT_SAVE_ERROR';

export const ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST =
  'ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST';
export const ASSESSMENT_LOAD_RISK_ANALYSIS_SUCCESS =
  'ASSESSMENT_LOAD_RISK_ANALYSIS_SUCCESS';
export const ASSESSMENT_LOAD_RISK_ANALYSIS_ERROR =
  'ASSESSMENT_LOAD_RISK_ANALYSIS_ERROR';

export enum AssessmentStatus {
  COMPLETED = 'COMPLETED',
  OPEN = 'OPEN',
  REOPEN = 'REOPEN',
  MITIGATION = 'MITIGATION',
}

export interface AssessmentRiskAnalysisFormsIndexedItem {
  [id: number]: number;
}

export interface AssessmentRiskAnalysisItem {
  analysis: string[];
  actionItem: string[];
  risk: string[];
  delegate: string[];
}

export interface AssessmentRiskAnalysisAnswersIndexedItem {
  [id: number]: AssessmentRiskAnalysisItem;
}

export interface AssessmentRiskAnalysisFromResponse {
  forms: AssessmentRiskAnalysisFormsIndexedItem;
  answers: AssessmentRiskAnalysisAnswersIndexedItem;
  assessmentTotalRisks: number;
}

export interface AssessmentRiskAnalysisState {
  forms: AssessmentRiskAnalysisFormsIndexedItem;
  answers: AssessmentRiskAnalysisAnswersIndexedItem;
  assessmentTotalRisks: number;
}

export interface SelectedAssessment {
  risksAnalysis: AssessmentRiskAnalysisState;
  loader: Loader;
}

export interface AssessmentFromResponse {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: AssessmentStatus;
  type: AssessmentTypeFromResponse;
  assets: string;
  interviews?: InterviewFromResponse[];
  documents?: DocumentFromResponse[];
  company?: CompanyFromResponse;
  totalInterviews: number;
  completedInterviews: number;
}

export interface AssessmentState {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: AssessmentStatus;
  typeId: number;
  assessmentType?: AssessmentTypeState;
  documentsIds?: number[];
  lastDocumentId?: number;
  lastDocumentDraftId?: number;
  companyId?: number;
  totalInterviews: number;
  completedInterviews: number;
}

export interface AssessmentInput {
  typeId: number;
  companyId: number;
}

export interface AssessmentListingIndexedItem {
  [key: string]: AssessmentState;
}

export interface AssessmentListingState {
  assessments: AssessmentListingIndexedItem;
  loader: Loader;
}

export enum AssessmentFilter {
  last = 'Last',
  older = 'Older',
}

export interface AssessmentUpdateRequestAction {
  type: typeof ASSESSMENT_UPDATE_REQUEST;
  assessment: AssessmentState;
}

export interface AssessmentUpdateSuccessAction {
  type: typeof ASSESSMENT_UPDATE_SUCCESS;
  assessment: AssessmentFromResponse;
}

export type AssessmentUpdateErrorAction = ShowErrorAction<
  typeof ASSESSMENT_UPDATE_ERROR
>;

export interface AssessmentUpdateStatusRequestAction {
  type: typeof ASSESSMENT_UPDATE_STATUS_REQUEST;
  assessmentId: number;
  status: AssessmentStatus;
}

export interface AssessmentUpdateStatusSuccessAction {
  type: typeof ASSESSMENT_UPDATE_STATUS_SUCCESS;
  assessmentId: number;
  status: AssessmentStatus;
}

export type AssessmentUpdateStatusErrorAction = ShowErrorAction<
  typeof ASSESSMENT_UPDATE_STATUS_ERROR
>;

export interface AssessmentLoadRequestAction {
  type: typeof ASSESSMENT_LOAD_REQUEST;
  assessmentId: number;
  options?: AssessmentQueryParams;
}

export interface AssessmentLoadSuccessAction {
  type: typeof ASSESSMENT_LOAD_SUCCESS;
  assessment: AssessmentFromResponse;
}

export type AssessmentLoadErrorAction = ShowErrorAction<
  typeof ASSESSMENT_LOAD_ERROR
>;

export interface AssessmentLoadSuggestionsRequestAction {
  type: typeof ASSESSMENT_LOAD_SUGGESTIONS_REQUEST;
  assessmentId: number;
  excludeInterviewId: number;
}

export interface AssessmentLoadSuggestionsSuccessAction {
  type: typeof ASSESSMENT_LOAD_SUGGESTIONS_SUCCESS;
  suggestions: { [k: string]: string[] };
}

export type AssessmentLoadSuggestionsErrorAction = ShowErrorAction<
  typeof ASSESSMENT_LOAD_SUGGESTIONS_ERROR
>;

export interface AssessmentSaveRequestAction {
  type: typeof ASSESSMENT_SAVE_REQUEST;
  assessmentInput: AssessmentInput;
}

export interface AssessmentSaveSuccessAction {
  type: typeof ASSESSMENT_SAVE_SUCCESS;
  assessment: AssessmentFromResponse;
}

export type AssessmentSaveErrorAction = ShowErrorAction<
  typeof ASSESSMENT_SAVE_ERROR
>;

export interface AssessmentLoadRiskAnalysisRequestAction {
  type: typeof ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST;
  assessmentId: number;
}

export interface AssessmentLoadRiskAnalysisSuccessAction {
  type: typeof ASSESSMENT_LOAD_RISK_ANALYSIS_SUCCESS;
  assessmentRiskAnalysis: AssessmentRiskAnalysisFromResponse;
}

export type AssessmentLoadRiskAnalysisErrorAction = ShowErrorAction<
  typeof ASSESSMENT_LOAD_RISK_ANALYSIS_ERROR
>;

export type AssessmentRiskAnalysisActionTypes =
  | AssessmentLoadRiskAnalysisRequestAction
  | AssessmentLoadRiskAnalysisSuccessAction
  | AssessmentLoadRiskAnalysisErrorAction;

export type AssessmentActionTypes =
  | AssessmentUpdateSuccessAction
  | AssessmentUpdateStatusSuccessAction
  | AssessmentLoadSuccessAction
  | AssessmentSaveRequestAction
  | AssessmentSaveSuccessAction
  | AssessmentSaveErrorAction;

export type AssessmentSuggestionsActionTypes =
  | AssessmentLoadSuggestionsErrorAction
  | AssessmentLoadSuggestionsRequestAction
  | AssessmentLoadSuggestionsSuccessAction;

export interface AssessmentQueryParams {
  withType?: any;
  withCompany?: any;
  withForms?: any;
}
