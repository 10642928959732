import React, { FC } from 'react';
import styled from 'styled-components';
import { HelpSection } from './HelpSection';

interface VariablesAndConstantsHelpSectionProps {
  variablesAndConstantsHelpOpen: boolean;
  setVariablesAndConstantsHelpOpen: (value: boolean) => void;
}

const HelpSectionItem = styled.div`
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-family: monospace;
`;

const InlineHelpSectionItem = styled(HelpSectionItem)`
  display: inline-block;
`;

export const VariablesAndConstantsHelpSection: FC<
  VariablesAndConstantsHelpSectionProps
> = ({ variablesAndConstantsHelpOpen, setVariablesAndConstantsHelpOpen }) => (
  <HelpSection
    isOpen={variablesAndConstantsHelpOpen}
    setIsOpen={setVariablesAndConstantsHelpOpen}
    title={'Variables and Constants'}
  >
    <h4>Variables</h4>
    <InlineHelpSectionItem>[v#VID]</InlineHelpSectionItem>
    <p>
      Format:&nbsp;
      <b>"[v#"</b>&nbsp;+&nbsp;
      <b>ID]</b>
    </p>
    <HelpSectionItem>
      [v#15] will be replaced with the variable whose id is 15
    </HelpSectionItem>
    <br />
    <h5>Variables - Lists</h5>
    <p>
      You can create lists that store multiple values of any kind (e.g.,
      constants, questions, strings).
    </p>
    <HelpSectionItem>
      [v#id] will be replaced with a comma-separated string of the list's
      elements
    </HelpSectionItem>
    <br />
    <h4>Constants</h4>
    <InlineHelpSectionItem>[c#CID]</InlineHelpSectionItem>
    <p>
      Format:&nbsp;
      <b>"[c#"</b>&nbsp;+&nbsp;
      <b>ID]</b>
    </p>
    <HelpSectionItem>
      [c#38] will be replaced with the constant whose id is 38
    </HelpSectionItem>
  </HelpSection>
);
