import { Loader, ShowErrorAction } from 'src/store/types';

export const EXAM_LISTING_LOAD_REQUEST = 'EXAM_LISTING_LOAD_REQUEST';
export const EXAM_LISTING_LOAD_SUCCESS = 'EXAM_LISTING_LOAD_SUCCESS';
export const EXAM_LISTING_LOAD_FAILED = 'EXAM_LISTING_LOAD_FAILED';

export type ExamListingElement = {
  id: number;
  trainingTypeName: string;
  status: string;
  createdAt: string;
};

export type ExamListingResponse = {
  records: ExamListingElement[];
  total: number;
  page: number;
  pageSize: number;
};

export type ExamListingState = {
  examListingResponse: ExamListingResponse;
  loader: Loader;
};

export interface ExamListingLoadRequestAction {
  type: typeof EXAM_LISTING_LOAD_REQUEST;
  page: number;
  pageSize: number;
  name?: string;
}

export interface ExamListingLoadSuccessAction {
  type: typeof EXAM_LISTING_LOAD_SUCCESS;
  examListing: ExamListingResponse;
}

export type ExamListingLoadErrorAction = ShowErrorAction<
  typeof EXAM_LISTING_LOAD_FAILED
>;

export const EXAM_DELETE_REQUEST = 'EXAM_DELETE_REQUEST';
export const EXAM_DELETE_SUCCESS = 'EXAM_DELETE_SUCCESS';
export const EXAM_DELETE_FAILED = 'EXAM_DELETE_FAILED';

export interface ExamDeleteRequestAction {
  type: typeof EXAM_DELETE_REQUEST;
  examId: number;
}

export interface ExamDeleteSuccessAction {
  type: typeof EXAM_DELETE_SUCCESS;
  examId: number;
}

export type ExamDeleteErrorAction = ShowErrorAction<typeof EXAM_DELETE_FAILED>;

export const EXAM_ACTIVATE_REQUEST = 'EXAM_ACTIVATE_REQUEST';
export const EXAM_ACTIVATE_SUCCESS = 'EXAM_ACTIVATE_SUCCESS';
export const EXAM_ACTIVATE_FAILED = 'EXAM_ACTIVATE_FAILED';

export interface ExamActivateRequestAction {
  type: typeof EXAM_ACTIVATE_REQUEST;
  examId: number;
}

export interface ExamActivateSuccessAction {
  type: typeof EXAM_ACTIVATE_SUCCESS;
  examId: number;
}

export type ExamActivateErrorAction = ShowErrorAction<
  typeof EXAM_ACTIVATE_FAILED
>;

export const EXAM_ARCHIVE_REQUEST = 'EXAM_ARCHIVE_REQUEST';
export const EXAM_ARCHIVE_SUCCESS = 'EXAM_ARCHIVE_SUCCESS';
export const EXAM_ARCHIVE_FAILED = 'EXAM_ARCHIVE_FAILED';

export interface ExamArchiveRequestAction {
  type: typeof EXAM_ARCHIVE_REQUEST;
  examId: number;
}

export interface ExamArchiveSuccessAction {
  type: typeof EXAM_ARCHIVE_SUCCESS;
  examId: number;
}

export type ExamArchiveErrorAction = ShowErrorAction<
  typeof EXAM_ARCHIVE_FAILED
>;

export type ExamListingActionTypes =
  | ExamListingLoadRequestAction
  | ExamListingLoadSuccessAction
  | ExamListingLoadErrorAction
  | ExamDeleteRequestAction
  | ExamDeleteSuccessAction
  | ExamDeleteErrorAction
  | ExamActivateRequestAction
  | ExamActivateSuccessAction
  | ExamActivateErrorAction
  | ExamArchiveRequestAction
  | ExamArchiveSuccessAction
  | ExamArchiveErrorAction;
