import axios from 'axios';

export const validateAuthToken = async (
  authToken: string,
): Promise<boolean> => {
  const baseUrl = process.env.REACT_APP_API_URL as string;
  try {
    const response = await axios.post(
      `${baseUrl}users/token`,
      { authToken: authToken },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.status === 200;
  } catch (error) {
    return false;
  }
};
