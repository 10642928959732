import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { device } from 'src/theme';

export const PageContentContainer = styled.div`
  max-width: ${remConvert(400)};
  margin-top: ${remConvert(22)};
  display: block;
  margin: 0 auto;
`;

export const PageContentBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.lg} {
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
  }
`;

export const PageTitle = styled.h1`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-top: ${remConvert(8)};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ProgressMeterContainer = styled.div`
  padding-bottom: ${remConvert(6)};
`;
