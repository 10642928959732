import React, { FC } from 'react';
import { Badge, Popover } from 'antd';
import styled from 'styled-components';
import { CheckCircleTwoTone } from '@ant-design/icons';

import { QuestionChoice, QuizQuestion } from 'src/store/types';

interface QuestionHeaderFormProps {
  question: QuizQuestion;
  position: number;
}

const InlineContainer = styled.div`
  display: inline-block;
  width: 97%;
`;

export const QuestionHeaderForm: FC<QuestionHeaderFormProps> = ({
  question,
  position,
}) => {
  const suggestedWarnings = [];
  const hasNonSelectedCorrectAnswer = question.choices.every(
    (c) => !c.isCorrect,
  );
  const hasChoices = question.choices.length > 0;
  const hasEmptyQuestion = question.questionText.trim() === '';
  const hasEmptyChoices = question.choices.some(
    (c: QuestionChoice) => c.choiceText.trim() === '',
  );
  const repeatedResponses = question.choices.reduce(
    (map: Map<string, number>, choice: QuestionChoice) => {
      if (!map.has(choice.choiceText)) {
        map.set(choice.choiceText, 1);
      } else {
        map.set(choice.choiceText, map.get(choice.choiceText)! + 1);
      }

      return map;
    },
    new Map<string, number>(),
  );

  if (hasNonSelectedCorrectAnswer) {
    suggestedWarnings.push('Select one correct response to this question');
  }

  if (hasEmptyQuestion) {
    suggestedWarnings.push('Question must not be empty');
  }

  if (repeatedResponses.size > 0) {
    for (const response of repeatedResponses.keys()) {
      if (repeatedResponses.get(response)! > 1) {
        suggestedWarnings.push('Some responses seems to be duplicated');
        break;
      }
    }
  }

  if (!hasChoices) {
    suggestedWarnings.push('Add some responses to this question');
  }

  if (hasEmptyChoices) {
    suggestedWarnings.push('Some responses are empty');
  }

  const content = (
    <div>
      <ul>
        {suggestedWarnings.map((suggestion, i) => {
          return <li key={i}>{suggestion}</li>;
        })}
      </ul>
    </div>
  );

  return (
    <InlineContainer>
      <InlineContainer>
        {position + 1}. {question.questionText}
      </InlineContainer>
      {suggestedWarnings.length > 0 ? (
        <Popover content={content} title="Suggestions">
          <Badge
            count={suggestedWarnings.length}
            style={{ backgroundColor: 'orange' }}
          />
        </Popover>
      ) : (
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: '18px' }}
        />
      )}
    </InlineContainer>
  );
};
