import React, { FC } from 'react';
import { Button as ButtonAntd } from 'antd';
import { ButtonProps as ButtonAntdProps } from 'antd/lib/button';
import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

const StyledButton = styled(ButtonAntd)<StyledButtonProps>`
  background: linear-gradient(
    180deg,
    ${({ theme }): string => theme.colors.astral.main} 0%,
    ${({ theme }): string => theme.colors.astral.main} 100%
  );
  color: ${({ theme }): string => theme.colors.twilightBlue.main};
  box-shadow: ${remConvert(0)} ${remConvert(2)} ${remConvert(4)}
    rgba(0, 0, 0, 0.2);
  border-radius: ${remConvert(15)};
  border: ${remConvert(0)};
  border-color: ${remConvert(0)} ${remConvert(2)} ${remConvert(4)}
    rgba(0, 0, 0, 0.2);
  font-family: ${({ theme }): string => theme.fontFamilies.primary};
  font-style: ${({ theme }): string => theme.fontStyles.normal};
  font-weight: ${({ theme }): number => theme.fontWeights.semiBold};
  font-size: ${({ theme }): number => theme.fontSizes.roundBtn}px;
  line-height: ${remConvert(25)};

  &:focus,
  &:hover {
    background: ${({ theme }): string => theme.colors.white.main};
    color: ${({ theme }): string => theme.colors.astral.main};
    border: ${remConvert(1)} solid
      ${({ theme }): string => theme.colors.astral.main};
  }

  &:disabled,
  &:disabled:hover {
    background: linear-gradient(
      84.58deg,
      ${({ theme }): string => theme.colors.nepal.main} 12.28%,
      ${({ theme }): string => theme.colors.nepal.main} 79.73%
    );
    color: ${({ theme }): string => theme.colors.twilightBlue.main};
    border: ${remConvert(0)};
  }
`;

export interface ButtonRoundedProps extends ButtonAntdProps {
  className?: string;
  disabled?: boolean;
  altered?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'radio' | 'danger';
  title: string;
}

interface StyledButtonProps extends ButtonAntdProps {
  $altered?: boolean;
  children: string;
  variant: 'primary' | 'secondary' | 'tertiary' | 'radio' | 'danger';
}

export const ButtonRounded: FC<ButtonRoundedProps> = ({
  className,
  disabled,
  onClick,
  altered = false,
  title,
  variant = 'primary',
  loading,
}) => (
  <StyledButton
    $altered={altered}
    disabled={disabled}
    className={className}
    type="primary"
    variant={variant}
    onClick={onClick}
    loading={loading}
    size="middle"
  >
    {title}
  </StyledButton>
);
