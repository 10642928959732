import {
  Thread,
  THREAD_UPDATE_ERROR,
  THREAD_UPDATE_REQUEST,
  THREAD_UPDATE_SUCCESS,
  ThreadUpdateErrorAction,
  ThreadUpdateRequestAction,
  ThreadUpdateSuccessAction,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const threadUpdateRequest = (
  thread: Thread,
): ThreadUpdateRequestAction => ({
  type: THREAD_UPDATE_REQUEST,
  thread,
});

export const threadUpdateSuccess = (
  updatedThread: Thread,
): ThreadUpdateSuccessAction => ({
  type: THREAD_UPDATE_SUCCESS,
  updatedThread,
});

export const threadUpdateError = (
  errorMessage: string,
  originalError: Error,
): ThreadUpdateErrorAction =>
  showErrorMessage(THREAD_UPDATE_ERROR, errorMessage, originalError);
