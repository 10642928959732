/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
  Loader,
  RootState,
  Exam,
  ExamSubmission,
  ExamSubmissionResponse,
} from 'src/store/types';

export const getExamLoader = (state: RootState): Loader =>
  state.exam.exam.loader;
export const getCurrentExam = (state: RootState): Exam => state.exam.exam.exam;
export const getCurrentExamSubmission = (state: RootState): ExamSubmission =>
  state.exam.examSubmission.submission;
export const getCurrentExamSubmissionResult = (
  state: RootState,
): ExamSubmissionResponse => state.exam.examSubmission.submission;
