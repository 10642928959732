import {
  DocumentType,
  DocumentFromResponse,
  DocumentsByAssessmentLoadRequestAction,
  DOCUMENTS_BY_ASSESSMENT_LOAD_REQUEST,
  DocumentsByAssessmentLoadSuccessAction,
  DOCUMENTS_BY_ASSESSMENT_LOAD_SUCCESS,
  DocumentsByAssessmentLoadErrorAction,
  DOCUMENTS_BY_ASSESSMENT_LOAD_ERROR,
} from '../types';
import { showErrorMessage } from './error';

export const documentsByAssessmentLoadRequest = (
  assessmentId: number,
  documentType?: DocumentType,
): DocumentsByAssessmentLoadRequestAction => ({
  type: DOCUMENTS_BY_ASSESSMENT_LOAD_REQUEST,
  assessmentId,
  documentType,
});

export const documentsByAssessmentLoadSuccess = (
  documents: DocumentFromResponse[],
  assessmentId: number,
): DocumentsByAssessmentLoadSuccessAction => ({
  type: DOCUMENTS_BY_ASSESSMENT_LOAD_SUCCESS,
  documents,
  assessmentId,
});

export const documentsByAssessmentLoadError = (
  errorMessage: string,
  originalError: Error,
): DocumentsByAssessmentLoadErrorAction =>
  showErrorMessage(
    DOCUMENTS_BY_ASSESSMENT_LOAD_ERROR,
    errorMessage,
    originalError,
  );
