import React, { ChangeEvent, FC, useEffect } from 'react';
import { Button } from 'src/components/Button';
import { use2faQR } from 'src/hooks/use2faQR';
import {
  THIconAuthy,
  THIconGoogleApp,
  THIconMicrosoftAuth,
  THIconRightArrow,
} from 'src/theme/media';
import * as S from './Styles';

export interface TwoFactorVerificationAppProps {
  code: string;
  error?: string;
  showHelp: boolean;
  onSubmit: (code: string) => void;
  onChangeCode: (code: string) => void;
}

export const TwoFactorVerificationApp: FC<TwoFactorVerificationAppProps> = (
  props,
) => {
  const { code, error, showHelp } = props;
  const { onSubmit, onChangeCode } = props;
  const [qrCode] = use2faQR();

  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    const newCode = event.target.value;
    onChangeCode(newCode);
  };

  const handleOnSubmit = (): void => {
    if (code.length === 6) {
      onSubmit(code);
    }
  };

  useEffect(() => {
    return () => {
      onChangeCode('');
    };
  }, []);

  return (
    <S.AppContainer>
      <S.HelpSectionContainer>
        {!showHelp && (
          <div>
            <S.HelpStepContainer>
              <S.HelpStepNumber>1.</S.HelpStepNumber>{' '}
              <S.HelpStepDescriptor>
                Using your smartphone, scan the QR code with an Authenticator
                App.
              </S.HelpStepDescriptor>
            </S.HelpStepContainer>
            <S.HelpLastStepContainer>
              <S.HelpStepNumber>2.</S.HelpStepNumber>{' '}
              <S.HelpStepDescriptor>
                Enter the code under "TotalHIPAA" here:{' '}
              </S.HelpStepDescriptor>
            </S.HelpLastStepContainer>
          </div>
        )}
        {showHelp && (
          <div>
            <S.HelpStepContainer>
              <S.HelpStepNumber>1.</S.HelpStepNumber>{' '}
              <S.HelpStepDescriptor>
                Using your smartphone, download an Authenticator App from the
                Apple App Store or Google Play.
              </S.HelpStepDescriptor>
            </S.HelpStepContainer>
            <S.HelpStepContainer>
              <S.HelpStepNumber>2.</S.HelpStepNumber>
              <S.HelpStepDescriptor>
                {' '}
                Open the Authenticator app and select "add."
              </S.HelpStepDescriptor>
            </S.HelpStepContainer>
            <S.HelpStepContainer>
              <S.HelpStepNumber>3.</S.HelpStepNumber>{' '}
              <S.HelpStepDescriptor>
                Place this QR code in front of the camera.{' '}
                <img src={THIconRightArrow} />
              </S.HelpStepDescriptor>
            </S.HelpStepContainer>
            <S.HelpLastStepContainer>
              <S.HelpStepNumber>4.</S.HelpStepNumber>{' '}
              <S.HelpStepDescriptor>
                Enter the number under "TotalHIPAA" here, and click the button
                below:
              </S.HelpStepDescriptor>
            </S.HelpLastStepContainer>
          </div>
        )}
        <S.QRContainer>
          <div>
            {showHelp && (
              <S.AppList>
                <S.AppListItem>
                  <S.AppListTitle>Some Authenticator Apps:</S.AppListTitle>
                </S.AppListItem>
                <S.AppListItem>
                  <S.AppIconImage src={THIconGoogleApp} /> Google Authenticator
                </S.AppListItem>
                <S.AppListItem>
                  <S.AppIconImage src={THIconAuthy} /> Authy
                </S.AppListItem>
                <S.AppListItem>
                  <S.AppIconImage src={THIconMicrosoftAuth} /> Microsoft
                  Authenticator
                </S.AppListItem>
              </S.AppList>
            )}
          </div>
          <div>
            {qrCode && (
              <S.QRImage
                height={159}
                width={159}
                src={`data:image/png;base64,${qrCode}`}
              />
            )}
          </div>
        </S.QRContainer>
      </S.HelpSectionContainer>
      <S.SubmitContainer>
        <S.TextInputWrapper
          autoFocus
          error={error}
          placeholder="2FA Code"
          value={code}
          onChange={handleChangeCode}
        />
        <div>
          <Button
            title="Set up two-step verification"
            onClick={() => handleOnSubmit()}
          />
        </div>
      </S.SubmitContainer>
    </S.AppContainer>
  );
};
