import { AxiosResponse } from 'axios';
import { HttpClient } from 'src/api/services/HttpClient';
import {
  TrainingPeriodConfig,
  TrainingPeriodListingResponse,
  TrainingPeriodResponse,
} from 'src/store/types';

class TrainingPeriodService extends HttpClient {
  public constructor() {
    super(`${process.env.REACT_APP_API_URL}training-period`);
  }

  public create = async (
    config: TrainingPeriodConfig,
  ): Promise<TrainingPeriodResponse> => {
    return await this.instance.post<TrainingPeriodResponse>('/', config, {
      withCredentials: true,
    });
  };

  public update = async (
    trainingId: number,
    config: TrainingPeriodConfig,
  ): Promise<TrainingPeriodResponse> => {
    return await this.instance.put<TrainingPeriodResponse>(
      `/${trainingId}`,
      config,
      { withCredentials: true },
    );
  };

  public getAll = async (
    companyId: number,
  ): Promise<TrainingPeriodListingResponse> => {
    const response = await this.instance.get<{
      data: TrainingPeriodListingResponse;
    }>(`/company/${companyId}`, {
      withCredentials: true,
    });
    return response.data;
  };

  public getById = async (
    trainingId: number,
  ): Promise<TrainingPeriodResponse> => {
    const response = await this.instance.get<{ data: TrainingPeriodResponse }>(
      `/${trainingId}`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  };

  public complete = async (
    trainingPeriodId: number,
  ): Promise<TrainingPeriodResponse> => {
    const response = await this.instance.put<{ data: TrainingPeriodResponse }>(
      `/${trainingPeriodId}/complete`,
      null,
      {
        withCredentials: true,
      },
    );

    return response.data;
  };

  public getCompanyCurrent = async (): Promise<TrainingPeriodResponse> => {
    const response = await this.instance.get<
      AxiosResponse<{ data: TrainingPeriodResponse }>
    >(`/company/current`, {
      withCredentials: true,
    });

    return response.data;
  };
}

export default new TrainingPeriodService();
