import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { Tooltip as TooltipAntD } from 'antd';
import { TooltipContainer } from './Styles';

interface TooltipProps {
  children?: ReactElement;
  text: string;
  className?: string;
}

const OverlayText = styled.span`
  color: ${({ theme }): string => theme.colors.black.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 13px;
`;

export const Tooltip: FC<TooltipProps> = ({
  children,
  text = '',
  className,
}) => (
  <TooltipContainer>
    <TooltipAntD
      getPopupContainer={(triggerNode) => triggerNode}
      placement="right"
      title={text && <OverlayText>{text}</OverlayText>}
      className={className}
      color="white"
    >
      {children}
    </TooltipAntD>
  </TooltipContainer>
);
