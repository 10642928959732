import {
  Loader,
  Thread,
  CommentDeleteRequestAction,
  CommentDeleteErrorAction,
  ThreadUpdateErrorAction,
  ThreadUpdateRequestAction,
  ThreadUpdateSuccessAction,
  ShowErrorAction,
} from 'src/store/types';
import { CommentSaveErrorAction, CommentSaveRequestAction } from './comment';

export const THREAD_LIST_LOAD_REQUEST = 'THREAD_LIST_LOAD_REQUEST';
export const THREAD_LIST_LOAD_SUCCESS = 'THREAD_LIST_LOAD_SUCCESS';
export const THREAD_LIST_LOAD_ERROR = 'THREAD_LIST_LOAD_ERROR';

export interface ThreadListingRelationFilter {
  fromAssessment?: boolean;
  fromDocumentDraft?: boolean;
}

export interface ThreadListingIndexedItem {
  [key: string]: Thread;
}

export interface ThreadListingState {
  threads: ThreadListingIndexedItem;
  loader: Loader;
}

export enum ThreadListingFilter {
  all = 'all',
  admin = 'admin',
  files = 'files',
}

export enum ThreadListingSortOrder {
  questionId = 'questionId',
  id = 'id',
}

export interface ThreadsLoadRequestAction {
  type: typeof THREAD_LIST_LOAD_REQUEST;
  assessmentId?: number;
  documentDraftId?: number;
}

export interface ThreadsLoadSuccessAction {
  type: typeof THREAD_LIST_LOAD_SUCCESS;
  threads: Thread[];
}

export type ThreadsLoadErrorAction = ShowErrorAction<
  typeof THREAD_LIST_LOAD_ERROR
>;

export type ThreadListActionTypes =
  | ThreadsLoadSuccessAction
  | ThreadsLoadErrorAction
  | ThreadsLoadRequestAction
  | CommentDeleteRequestAction
  | CommentDeleteErrorAction
  | CommentSaveRequestAction
  | CommentSaveErrorAction
  | ThreadUpdateErrorAction
  | ThreadUpdateRequestAction
  | ThreadUpdateSuccessAction;
