import { Loader } from 'src/store/types';
import {
  CertificateListingActionTypes,
  CertificateListingState,
  CERTIFICATE_LISTING_LOAD_FAILED,
  CERTIFICATE_LISTING_LOAD_REQUEST,
  CERTIFICATE_LISTING_LOAD_SUCCESS,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const certificateListingInitialState: CertificateListingState = {
  certificateListingResponse: [],
  loader: loaderInitialState,
};

export const certificateListingReducer = (
  state = certificateListingInitialState,
  action: CertificateListingActionTypes,
): CertificateListingState => {
  switch (action.type) {
    case CERTIFICATE_LISTING_LOAD_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case CERTIFICATE_LISTING_LOAD_SUCCESS: {
      return {
        certificateListingResponse: action.certificateListing,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case CERTIFICATE_LISTING_LOAD_FAILED: {
      return {
        ...certificateListingInitialState,
      };
    }
    default:
      return state;
  }
};
