import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import _ from 'lodash';

import { TopNavbar } from 'src/components/TopNavbar';
import { Button } from 'src/components/Button';
import styled from 'styled-components';
import { Pagination } from 'src/components/Pagination';
import { RoutesEnum } from 'src/shared/enums';
import { SearchInput } from 'src/components/SearchInput';
import actions from 'src/store/actions';
import { TestTypesResponse, TrainingTypesResponse } from 'src/store/types';
import {
  getCurrentTraining,
  getTestTypes,
  getTrainingListingLoader,
  getTrainingListingResponse,
  getTrainingLoader,
  getTrainingTypes,
} from 'src/store/selectors';

import { TrainingConfigForm, TrainingListingTable } from '../../components';

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const TrainingListPage: FC<unknown> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const trainingListingLoader = useSelector(getTrainingListingLoader);
  const trainingListingResponse = useSelector(getTrainingListingResponse);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState<string | undefined>(undefined);
  const [column, setColumn] = useState<string | undefined>(undefined);
  const [creationModalVisible, setCreationModalVisible] = useState(false);
  const trainingTypes: TrainingTypesResponse = useSelector(getTrainingTypes);
  const testTypes: TestTypesResponse = useSelector(getTestTypes);
  const currentTraining = useSelector(getCurrentTraining);
  const trainingLoader = useSelector(getTrainingLoader);

  useEffect(() => {
    if (trainingLoader.completed && trainingLoader.success) {
      dispatch(
        actions.trainingListingLoadRequest(page, pageSize, sort, column),
      );
    }
  }, [trainingLoader.completed]);

  useEffect(() => {
    if (creationModalVisible && currentTraining) {
      setCreationModalVisible(false);
      history.push(`${RoutesEnum.TRAINING_CONFIG}/${currentTraining.id}`);
    }
  }, [currentTraining]);

  useEffect(() => {
    dispatch(actions.trainingReset());
    dispatch(actions.trainingTypesLoadRequest());
    dispatch(actions.testTypesLoadRequest());
  }, []);

  useEffect(() => {
    dispatch(actions.trainingListingLoadRequest(page, pageSize, sort, column));
  }, [page, pageSize, sort, column]);

  const sortTrainingList = (sort: string, column: string) => {
    setColumn(column);
    setSort(sort);
  };

  const debounceTrainingName = _.debounce((trainingName: string) => {
    dispatch(
      actions.trainingListingLoadRequest(
        page,
        pageSize,
        sort,
        column,
        trainingName,
      ),
    );
  }, 1000);

  return (
    <>
      <TopNavbar
        title="My trainings"
        prevRoute={null}
        extraOptions={[
          <Button
            onClick={() => setCreationModalVisible(true)}
            title="Create new Training"
            key={1}
          />,
        ]}
      />
      <PageContentContainer>
        <SearchInput
          onChange={(e) => debounceTrainingName(e.target.value)}
          placeholder="Training name"
        />
        <TrainingListingTable
          sortTrainingTable={sortTrainingList}
          dataSource={trainingListingResponse.records}
        />
        <Pagination
          current={page}
          items={
            trainingListingLoader.loading ? 0 : trainingListingResponse.total
          }
          pageSize={pageSize}
          onPageChange={setPage}
          onPageSizeChange={(size) => {
            setPageSize(size);
            setPage(1);
          }}
          sizes={[10, 25, 50]}
        />
      </PageContentContainer>
      <Modal
        title="New training"
        bodyStyle={{ backgroundColor: 'whitesmoke' }}
        open={creationModalVisible}
        onCancel={() => setCreationModalVisible(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <TrainingConfigForm
          trainingTypes={trainingTypes}
          testTypes={testTypes}
        />
      </Modal>
    </>
  );
};
