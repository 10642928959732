import React, { FC, useState } from 'react';
import { ToolbarItem } from './ToolbarItem';
import { TemplateEditorConstantSelectorWindow } from './TemplateEditorSelectorWindows';
import { RegExpToken } from '../useRegExpTokens';

interface ConstantToolbarItemProps {
  constantToken: RegExpToken;
  updateEditorContent: (val: string) => void;
}

export const ConstantToolbarItem: FC<ConstantToolbarItemProps> = ({
  constantToken,
  updateEditorContent,
}) => {
  const [childrenVisible, setChildrenVisible] = useState<boolean>(false);

  const execute = (selectedId: string) => {
    if (selectedId?.length > 0) {
      const textToAdd = constantToken.getToken(selectedId);
      updateEditorContent(textToAdd);
    }
    setChildrenVisible(false);
  };

  return (
    <ToolbarItem
      icon={
        <svg width="12" height="12" viewBox="0 0 135.46666 135.46667">
          <g transform="matrix(1.8416256,0,0,3.6983265,-41.706689,-148.36772)">
            <path
              d="m 94.162183,54.625617 h -6.482521 l -2.605317,5.408851 h 5.785034 l -0.779543,4.174034 h -7.015892 l -3.015603,6.156699 h -4.943948 l 2.974575,-6.156699 h -5.415777 l -2.974574,6.156699 h -5.026005 l 2.995089,-6.156699 h -5.538863 l 0.779544,-4.174034 h 6.769721 l 2.584802,-5.408851 h -6.010691 l 0.779543,-4.156642 h 7.24155 l 2.872002,-6.000173 h 5.026005 l -2.872003,6.000173 h 5.395263 l 2.892517,-6.000173 h 5.026004 l -2.892517,6.000173 h 5.23115 z m -11.405954,-0.03479 h -5.497834 l -2.666859,5.495811 h 5.518348 z"
              id="path16"
            />
            <g transform="scale(1.3969087,0.7158664)" id="text853">
              <path
                d="m 38.387324,85.266283 5.736767,0.636559 c -1.19185,3.307718 -2.609836,6.126184 -4.993535,7.880479 -2.383699,1.740904 -5.175842,2.611356 -8.376427,2.611356 -3.602332,0 -6.421257,-1.084717 -8.456776,-3.254151 -2.022127,-2.169434 -3.03319,-5.316453 -3.03319,-9.441056 0,-3.347893 0.676274,-6.407866 2.028823,-9.179921 1.36594,-2.785447 3.254151,-4.914706 5.664633,-6.387779 2.410483,-1.473073 5.03523,-2.209609 7.874243,-2.209609 3.187193,0 5.76507,0.857061 7.733631,2.571182 1.968561,1.700729 2.53527,4.096031 2.883451,7.042176 L 40.23536,76.0261 c -0.321397,-1.714121 -0.944105,-2.952841 -1.868124,-3.71616 -0.910627,-0.776711 -2.129259,-1.165067 -3.655898,-1.165067 -1.673946,0 -3.24076,0.50888 -4.700441,1.526639 -1.446289,1.017759 -2.60466,2.611356 -3.475112,4.78079 -0.870452,2.156043 -1.305678,4.312085 -1.305678,6.468128 0,2.356917 0.562446,4.17817 1.687338,5.463761 1.138283,1.285591 2.551094,1.928386 4.238431,1.928386 1.566814,0 2.999712,-0.50888 4.298694,-1.526639 1.312374,-1.017759 2.289959,-2.524311 2.932754,-4.519655 z"
                id="path855"
              />
            </g>
          </g>
        </svg>
      }
      childrenVisible={childrenVisible}
      setChildrenVisible={setChildrenVisible}
      tooltip="Constant Tokens"
    >
      <TemplateEditorConstantSelectorWindow
        onSelection={(id: number) => execute(`${id}`)}
        onClose={() => setChildrenVisible(false)}
      />
    </ToolbarItem>
  );
};
