/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Loader, NotificationFromResponse, RootState } from '../types';

export const getNotifications = (
  state: RootState,
): NotificationFromResponse[] => {
  return state.notificationListing.notifications || [];
};

export const getPendingNotifications = (
  state: RootState,
): NotificationFromResponse[] => {
  return getNotifications(state).filter((n) => n.pending);
};

export const getNotificationListingLoader = (state: RootState): Loader => {
  return state.notificationListing.loader;
};
