import { Progress, Spin as SpinAntD } from 'antd';
import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { centeredFlex, semiboldOpenSans } from 'src/theme';

export const BigColumn = styled.div`
  display: flex;
  width: calc(100% - ${({ theme }) => `${theme.sidebar.width}px`});
`;

export const SmallColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => `${theme.sidebar.width}px`};

  > div {
    position: fixed;
    width: ${({ theme }) => `${theme.sidebar.width}px`};
    height: calc(100vh - 100px);
    overflow: auto;
  }
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${remConvert(22)};

  .progress-card {
    margin-top: ${remConvert(22)};
  }
`;

export const Spin = styled(SpinAntD)`
  margin: ${remConvert(20)} ${remConvert(5)};
`;

export const AssessmentProgress = styled(Progress)`
  ${centeredFlex}

  .ant-progress-inner {
    background: ${({ theme }) => theme.colors.alto.secondary};
  }

  .ant-progress-bg {
    background: ${({ theme }) => theme.colors.astral.main};
  }

  .ant-progress-inner,
  .ant-progress-bg {
    height: ${remConvert(13)} !important;
  }

  .ant-progress-text {
    ${semiboldOpenSans}
    line-height: ${remConvert(41)}
    font-size: 30px;
    color: rgba(28, 36, 40, 0.75) !important;
    display: flex;
  }
`;
