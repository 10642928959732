import {
  CompanyFromResponse,
  CompanyLoadSuccessAction,
  COMPANY_LOAD_REQUEST,
  CompanyLoadRequestAction,
  COMPANY_LOAD_SUCCESS,
  CompanyLoadErrorAction,
  COMPANY_LOAD_ERROR,
  COMPANY_UPDATE_REQUEST,
  CompanyUpdateRequestAction,
  COMPANY_UPDATE_SUCCESS,
  CompanyUpdateSuccessAction,
  COMPANY_UPDATE_ERROR,
  CompanyUpdateErrorAction,
  COMPANY_SAVE_REQUEST,
  CompanySaveRequestAction,
  COMPANY_SAVE_SUCCESS,
  CompanySaveSuccessAction,
  COMPANY_SAVE_ERROR,
  CompanySaveErrorAction,
  CompanyInput,
  CompanyQueryOptions,
} from '../types';
import { showErrorMessage } from './error';

export const companyLoadRequest = (
  companyId: number,
  options?: CompanyQueryOptions,
): CompanyLoadRequestAction => ({
  type: COMPANY_LOAD_REQUEST,
  companyId,
  options,
});

export const companyLoadSuccess = (
  company: CompanyFromResponse,
): CompanyLoadSuccessAction => ({
  type: COMPANY_LOAD_SUCCESS,
  company,
});

export const companyLoadError = (
  errorMessage: string,
  originalError?: Error,
): CompanyLoadErrorAction =>
  showErrorMessage(COMPANY_LOAD_ERROR, errorMessage, originalError);

export const companyUpdateRequest = (
  companyId: number,
  companyInput: CompanyInput,
): CompanyUpdateRequestAction => ({
  type: COMPANY_UPDATE_REQUEST,
  companyId,
  companyInput,
});

export const companyUpdateSuccess = (
  updatedCompany: CompanyFromResponse,
): CompanyUpdateSuccessAction => ({
  type: COMPANY_UPDATE_SUCCESS,
  updatedCompany,
});

export const companyUpdateError = (
  errorMessage: string,
  originalError?: Error,
): CompanyUpdateErrorAction =>
  showErrorMessage(COMPANY_UPDATE_ERROR, errorMessage, originalError);

export const companySaveRequest = (
  companyInput: CompanyInput,
): CompanySaveRequestAction => ({
  type: COMPANY_SAVE_REQUEST,
  companyInput,
});

export const companySaveSuccess = (
  company: CompanyFromResponse,
): CompanySaveSuccessAction => ({
  type: COMPANY_SAVE_SUCCESS,
  company,
});

export const companySaveError = (
  errorMessage: string,
  originalError?: Error,
): CompanySaveErrorAction =>
  showErrorMessage(COMPANY_SAVE_ERROR, errorMessage, originalError);
