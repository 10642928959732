import React, { FC } from 'react';
import { Checkbox, Form, FormInstance } from 'antd';
import { ButtonRounded } from 'src/components/Button';
import { TextInput } from 'src/components/TextInput';
import { THIconInfo } from 'src/theme/media';
import * as S from './Styles';

interface FormAddCompanyUserFormProps {
  onSubmit: (newUser: any) => void;
  form: FormInstance;
}

export const FormAddCompanyUserForm: FC<FormAddCompanyUserFormProps> = ({
  onSubmit,
  form,
}) => {
  const handleOnSubmit = () => {
    form.submit();
  };

  return (
    <>
      <S.FormInfoWrapper>
        <S.FormInfoDescription>
          Enter the name and email address of the employees required to take
          HIPAA training.
        </S.FormInfoDescription>{' '}
        <div>
          <img src={THIconInfo} />
        </div>
      </S.FormInfoWrapper>
      <S.FormContainer>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item
            name={'name'}
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the user's full name",
              },
            ]}
          >
            <TextInput placeholder="Full name" float />
          </Form.Item>
          <Form.Item
            name={'email'}
            rules={[
              {
                required: true,
                whitespace: false,
                type: 'email',
                message: "Please enter the user's email address",
              },
            ]}
          >
            <TextInput placeholder="Email address" float />
          </Form.Item>

          <div>
            <Form.Item name={'officer'} valuePropName="checked">
              <div>
                <Checkbox>
                  <S.CheckboxLabel>Assign as officer</S.CheckboxLabel>
                  <img height={12} width={12} src={THIconInfo} />
                </Checkbox>
              </div>
            </Form.Item>

            <S.ButtonWrapper>
              <ButtonRounded title={'Add +'} onClick={handleOnSubmit} />
            </S.ButtonWrapper>
          </div>
        </Form>
      </S.FormContainer>
    </>
  );
};
