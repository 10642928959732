import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { DocumentDraftInput, TemplateState } from 'src/store/types';
import { documentDraftSaveRequest } from 'src/store/actions/documentDraft';
import { TextInput } from '../../../../components/TextInput';

export interface FormCreateDocumentDraftFromTemplateProps {
  baseDocumentId?: number;
  documentForm: FormInstance;
  templates: TemplateState[];
  setCreatingDocumentDraft: (value: boolean) => void;
}

interface TemplateFormValues {
  templateId: number;
  title: string;
}

const FormContainer = styled(Form)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
      }
    }
  }
`;

export const FormCreateDocumentDraftFromTemplate: FC<
  FormCreateDocumentDraftFromTemplateProps
> = ({ baseDocumentId, documentForm, templates, setCreatingDocumentDraft }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values: TemplateFormValues) => {
    if (baseDocumentId && values?.templateId) {
      setCreatingDocumentDraft(true);
      dispatch(
        documentDraftSaveRequest({
          documentId: baseDocumentId,
          templateId: values?.templateId,
          title: values?.title,
          archived: false,
        } as DocumentDraftInput),
      );
    }
    return null;
  };
  return (
    <FormContainer
      form={documentForm}
      onFinish={(values) => handleSubmit(values as TemplateFormValues)}
    >
      <Form.Item
        name="title"
        rules={[
          { required: true, message: 'Please input the document draft title' },
        ]}
      >
        <TextInput placeholder="Title" />
      </Form.Item>
      <Form.Item
        name="templateId"
        rules={[{ required: true, message: 'Please select a template' }]}
      >
        <Select placeholder="Select a template">
          {templates.map((template: TemplateState) => (
            <Select.Option value={template.id} key={template.id}>
              {template.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </FormContainer>
  );
};
