import { Input, Popover, Select as SelectAntd } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { ChangeEvent, FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAssessmentTypeDelegates } from 'src/store/selectors';
import { getConstants } from 'src/store/selectors/constant';
import { AssessmentTypesDelegateState, ConstantState } from 'src/store/types';
import { InfoCircleOutlined } from 'src/theme/icons';
import styled from 'styled-components';

export interface RiskAssessmentFieldsProps {
  value?: {
    analysis?: string;
    actionItem?: string;
    risk?: string;
    delegate?: string;
  };
  onChange?: (value: number[]) => void;
}

const Title = styled.span`
  display: block;
  margin-bottom: 2px;
`;

const Wrapper = styled.div`
  background-color: #f5f5f5;
  margin: 10px 0;
  padding: 8px;
  border-radius: 4px;
`;

const TextArea = styled(Input.TextArea)`
  margin: 5px 0;
`;

const Select = styled(SelectAntd)`
  width: 100%;
  margin: 5px 0;
`;

const CustomPopover = styled(Popover)`
  text-align: center;
`;

const ConstantList = styled.ul`
  padding-left: 20px;
`;

const { Option } = SelectAntd;

export const RiskAssessmentFields: FC<RiskAssessmentFieldsProps> = ({
  value,
  onChange,
}) => {
  const [analysis, setAnalysis] = useState(value?.analysis);
  const [actionItem, setActionItem] = useState(value?.actionItem);
  const [level, setLevel] = useState(value?.risk);
  const [delegate, setDelegate] = useState(value?.delegate);

  const constants = useSelector(getConstants);
  const delegates = useSelector(getAssessmentTypeDelegates);

  const getDelegatesOptions = (delegates: AssessmentTypesDelegateState[]) => {
    if (!delegates.length) {
      return [];
    }

    return delegates.map((delegate) => {
      return (
        <Option key={delegate.id} value={delegate.value}>
          {delegate.label}
        </Option>
      );
    });
  };

  const triggerChange = (newValue: any) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleAnalysisChange = (newValue: string) => {
    setAnalysis(newValue);
    triggerChange({ ...value, analysis: newValue });
  };

  const handleActionItemChange = (newValue: string) => {
    setActionItem(newValue);
    triggerChange({ ...value, actionItem: newValue });
  };

  const handleLevelChange = (newValue: string) => {
    setLevel(newValue);

    triggerChange({ ...value, risk: newValue });
  };

  const handleDelegateChange = (newValue: string) => {
    setDelegate(newValue);
    triggerChange({ ...value, delegate: newValue });
  };

  const getConstantsList = () => {
    return (
      <ConstantList>
        {Object.values(constants).map((constant: ConstantState) => (
          <li key={constant.id}>
            {constant.description}: <b>{`[c#${constant.id}]`}</b>
          </li>
        ))}
      </ConstantList>
    );
  };

  return (
    <Wrapper>
      <Title>
        Risk analysis info&nbsp;
        <CustomPopover
          content={getConstantsList()}
          title={<h3>Constant list</h3>}
        >
          <InfoCircleOutlined />
        </CustomPopover>
      </Title>
      <TextArea
        rows={5}
        value={analysis}
        placeholder="Analysis"
        onChange={(selectValue: ChangeEvent<HTMLTextAreaElement>) =>
          handleAnalysisChange(selectValue.target.value)
        }
      />
      <TextArea
        rows={5}
        value={actionItem}
        placeholder="Action Item"
        onChange={(selectValue: ChangeEvent<HTMLTextAreaElement>) =>
          handleActionItemChange(selectValue.target.value)
        }
      />
      <Select
        value={level}
        placeholder="Risk level"
        onChange={
          ((selectValue: SelectValue) =>
            handleLevelChange(selectValue as string)) as any /*TODO remove any*/
        }
      >
        <Option value="HIGH">High</Option>
        <Option value="MEDIUM">Medium</Option>
        <Option value="LOW">Low</Option>
      </Select>
      <Select
        value={delegate}
        placeholder="Delegate"
        onChange={
          ((selectValue: SelectValue) =>
            handleDelegateChange(
              selectValue as string,
            )) as any /*TODO remove any*/
        }
      >
        {getDelegatesOptions(delegates)}
      </Select>
    </Wrapper>
  );
};
