import {
  QuestionListingIndexedType,
  VariableListingIndexedItem,
  ConstantListingIndexedItem,
} from 'src/store/types';

export interface RegExpToken {
  getToken: (id?: string) => string;
  getRegExpForParams?: () => RegExp;
  getRegExp?: () => RegExp;
  getReplacement?: () => string;
}

export interface ConditionalRegExpToken extends RegExpToken {
  getConditionRegex: () => RegExp;
  getConditionalIfRegex: () => RegExp;
  getConditionalElseRegex: () => RegExp;
  getConditionalEndIfRegex: () => RegExp;
  translateParam: (param: string) => string;
  getConditionStatement: (condition: string) => string;
}

export interface FootnoteRegExpToken extends RegExpToken {
  getContent: (footnoteContent: string) => string;
}

export interface ConflictRegExpToken extends RegExpToken {
  getConflictStart: () => string;
  getConflictBranchDivider: () => string;
  getConflictEnd: () => string;
}

export interface TemplateRegExpTokens {
  questionToken: RegExpToken;
  variableToken: RegExpToken;
  constantToken: RegExpToken;
  tocToken: RegExpToken;
  centerTextTag: RegExpToken;
  underlineTextTag: RegExpToken;
  conditionalToken: ConditionalRegExpToken;
  conflictToken: ConflictRegExpToken;
  needsReviewToken: RegExpToken;
  footnoteToken: FootnoteRegExpToken;
}

export const useRegExpTokens = (
  questions: QuestionListingIndexedType,
  variables: VariableListingIndexedItem,
  constants: ConstantListingIndexedItem,
): TemplateRegExpTokens => {
  const questionToken: RegExpToken = {
    getToken: (id?: string) => `[q#${id}]`,
    getRegExpForParams: () => /q#([0-9]+)/,
    getRegExp: () => /\[q#([0-9]+)]/,
    getReplacement: () => '$1',
  };

  const variableToken: RegExpToken = {
    getToken: (id?: string) => `[v#${id}]`,
    getRegExpForParams: () => /v#([0-9]+)/,
    getRegExp: () => /\[v#([0-9]+)]/,
    getReplacement: () => '$1',
  };

  const constantToken: RegExpToken = {
    getToken: (id?: string) => `[c#${id}]`,
    getRegExpForParams: () => /c#([0-9]+)/,
    getRegExp: () => /\[c#([0-9]+)]/,
    getReplacement: () => '$1',
  };

  const tocToken: RegExpToken = {
    getToken: () => '[toc]',
    getRegExp: () => /\[toc]/,
    getRegExpForParams: () => /\[toc]/,
    getReplacement: () => '',
  };

  const centerTextTag: RegExpToken = {
    getToken: () => '[center]',
    getRegExp: () => /\[center]([^\[\]]*)\[\/center]/,
    getRegExpForParams: () => /\[center]([^\[\]]*)\[\/center]/,
    getReplacement: () => '$1',
  };

  const underlineTextTag: RegExpToken = {
    getToken: () => '',
    getRegExp: () => /\[u]([^\[\]]*)\[\/u]/g,
    getRegExpForParams: () => /\[u]([^\[\]]*)\[\/u]/g,
    getReplacement: () => '$1',
  };

  const conditionalToken: ConditionalRegExpToken = {
    getToken: () => '',
    getRegExp: () =>
      /(\[\s*?\n*?if\s*?condition='[^\[\]]*?'\s*?\n*?]|\[\s*?\n*?\/if\s*?\n*?])/,
    getRegExpForParams: () =>
      /(\[\s*?\n*?if\s*?condition='[^\[\]]*?'\s*?\n*?]|\[\s*?\n*?\/if\s*?\n*?])/,
    getConditionRegex: () => /'(.+?)([!]?=|[<>]=?)(.*)'/,
    getReplacement: () => '',
    getConditionalIfRegex: () => /\[[\n|\s]*if/,
    getConditionalElseRegex: () => /\[\n*?\s*?else\n*?\s*?\]/,
    getConditionalEndIfRegex: () => /\[[\n\s]*\/if/,
    translateParam: (param = ''): string => {
      if (questionToken.getRegExpForParams?.().test(param)) {
        const questionId = questionToken.getRegExpForParams().exec(param)?.[1];
        if (questionId) {
          return questions?.[questionId]?.question;
        }
      }

      if (variableToken.getRegExpForParams?.().test(param)) {
        const variableId = variableToken.getRegExpForParams().exec(param)?.[1];
        if (variableId) {
          return variables?.[variableId]?.content;
        }
      }

      if (constantToken.getRegExpForParams?.().test(param)) {
        const constantId = constantToken.getRegExpForParams().exec(param)?.[1];
        if (constantId) {
          const description = constants?.[constantId]?.description;
          return description ? description : '';
        }
      }

      return param;
    },
    getConditionStatement: function (conditionalToken: string) {
      const condition = this.getConditionRegex().exec(conditionalToken);
      const firstParam = this.translateParam(condition?.[1] ?? '');
      const operator = condition?.[2];
      const secondParam = this.translateParam(condition?.[3] ?? '');
      return `${firstParam}${operator}${secondParam}`;
    },
  };

  const conflictToken: ConflictRegExpToken = {
    getToken: () => `${'<'.repeat(7)} CURRENT`,
    getRegExp: () => /<{7} CURRENT|={7}|<{7} PARENT/,
    getReplacement: () => '$1',
    getConflictStart: () => `${'<'.repeat(7)} CURRENT`,
    getConflictEnd: () => `${'<'.repeat(7)} PARENT`,
    getConflictBranchDivider: () => '='.repeat(7),
  };

  const needsReviewToken: RegExpToken = {
    getToken: () => '[review]\n\n',
    getRegExp: () => /\[review]/,
    getRegExpForParams: () => /\[review]/,
    getReplacement: () => '$1',
  };

  const footnoteToken: FootnoteRegExpToken = {
    getToken: () => '[footnote:',
    getRegExp: () => /\[footnote:(?<content>.+?)]/m,
    getRegExpForParams: () => /\[footnote:(?<content>.+?)]/m,
    getReplacement: () => '$1',
    getContent: function (footnoteToken: string) {
      const token = this.getRegExp?.().exec(footnoteToken);
      return token?.groups?.content || 'Footnote';
    },
  };

  return {
    questionToken,
    variableToken,
    constantToken,
    tocToken,
    centerTextTag,
    underlineTextTag,
    conditionalToken,
    conflictToken,
    needsReviewToken,
    footnoteToken,
  };
};
