import moment from 'moment';
import {
  AssessmentStatus,
  AssessmentListingIndexedInterface,
  AssessmentState,
  TypeOfTraining,
  CompanyType,
} from 'src/store/types';
import {
  CompaniesTableRowInterface,
  AssessmentTableRowInterface,
} from './CompanyListingTable';

export const mapTypeOfTraining = (t: TypeOfTraining): string => {
  switch (t) {
    case TypeOfTraining.HIPAA_PRIME:
      return 'HIPAA Prime';
    case TypeOfTraining.TRAINING_ONLY:
      return 'Training only';
    case TypeOfTraining.CMMC:
      return 'NIST 800-171';
    case TypeOfTraining.GLBA:
      return 'GLBA';
    default:
      return 'Other';
  }
};

export const mapCompanyType = (c: CompanyType): string => {
  switch (c) {
    case CompanyType.AGENT:
      return 'Agent';
    case CompanyType.EMPLOYER:
      return 'Employer';
    case CompanyType.BUSINESS_ASSOCIATE:
      return 'Business Associate';
    case CompanyType.MEDICAL_PRACTICE:
      return 'Medical Practice';
    case CompanyType.DENTAL_PRACTICE:
      return 'Dental Practice';
    case CompanyType.COLLEGE_UNIVERSITY:
      return 'College/University';
    case CompanyType.OTHER:
      return 'Other';
  }
};

export const dataMapper = (
  companiesData: AssessmentListingIndexedInterface,
  companyName: string,
): AssessmentTableRowInterface[] => {
  return companiesData[companyName].assessments.map(
    (assessment: AssessmentState) => {
      return {
        id: assessment.id,
        companyId: companiesData[companyName].company.id,
        progress: {
          total: assessment.totalInterviews || 0,
          completed: assessment.completedInterviews || 0,
        },
        assessmentStartDate: assessment.createdAt
          ? moment(assessment.createdAt).format('MM-DD-YYYY')
          : 'None',
        assessmentStatus: assessment
          ? assessment.status
          : AssessmentStatus.OPEN,
        assessmentType: assessment.assessmentType?.label,
      } as AssessmentTableRowInterface;
    },
  );
};

export const dataMapperCompanies = (
  assessmentsData: AssessmentListingIndexedInterface,
): CompaniesTableRowInterface[] => {
  return Object.keys(assessmentsData)
    .map((companyName) => {
      const { company } = assessmentsData[companyName];
      return {
        name: companyName,
        id: company.id,
        typeOfTraining: mapTypeOfTraining(
          company.typeOfTraining as TypeOfTraining,
        ),
        companyType: mapCompanyType(company.companyType as CompanyType),
      } as CompaniesTableRowInterface;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};
