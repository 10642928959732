import styled from 'styled-components';
import { baseOpenSans, centeredFlex, device, italicOpenSans } from 'src/theme';
import { remConvert } from 'src/theme/utils';

export const AddUserTableContainer = styled.div`
  width: 100%;
  height: ${remConvert(200)};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: ${remConvert(10)};
  margin-left: auto;
  margin-right: 0;
  margin-bottom: ${remConvert(10)};

  @media ${device.lg} {
    width: ${remConvert(350)};
    height: ${remConvert(386)};
    margin-bottom: ${remConvert(34)};
  }
`;

export const AddUserTable = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;

  ul {
    list-style-type: none;
    padding: 0;
    height: ${remConvert(284)};
    overflow-y: scroll;
  }
`;

export const AddUserTableTitle = styled.div`
  ${centeredFlex}
  justify-content: center;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const AddUserTableFile = styled.div`
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const AddUserList = styled.ul`
  li {
    ${centeredFlex}
    justify-content: space-between;

    > div:first-of-type {
      ${centeredFlex}

      > div:nth-of-type(2) {
        padding-left: ${remConvert(6)};
        padding-top: ${remConvert(4)};
      }
    }

    > div:nth-of-type(2) {
      display: flex;
    }
  }
`;

export const AddUserRole = styled.div`
  ${italicOpenSans}
  font-weight: 700;
  font-size: 12px;
  line-height: ${remConvert(16)};
  color: #4883b7;
`;

export const AddUserRoleData = styled.div`
  ${baseOpenSans}
  font-weight: 600;
  font-size: 12px;
  line-height: ${remConvert(16)};
  color: rgba(28, 36, 40, 0.85);
`;

export const RemoveButtonWrapper = styled.div`
  margin-left: ${remConvert(35.2)};
  cursor: pointer;
`;

export const LicensesWrapper = styled.div`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;

  span {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #007894;
  }
`;
