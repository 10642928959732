import { Spin, Table } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import {
  FileExcelOutlined,
  FilePdfOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { FormState, InterviewListingIndexedItem } from 'src/store/types';
import { useAuth } from 'src/hooks/useAuth';
import { dataMapper } from '../RiskAssessmentTable/dataMapper';
import { TableRowInterface } from '../RiskAssessmentTable/riskAssessmentTableTypes';

interface RiskAssessmentRiskFieldListingTableProps {
  forms: FormState[];
  interviews: InterviewListingIndexedItem;
  isGeneratingRiskSummary: boolean;
  handleRiskSummaryDownload: () => void;
}

interface RiskyFieldValue {
  id?: number;
  actionItem: string;
  type: RiskType;
  actionItemCompletedAt?: string;
}

interface ExportRiskyField {
  actionItem: string;
  priority?: string;
  actionItemCompletedAt?: string;
}

const RiskTableContainer = styled.div`
  .risk-high {
    background-color: #f1948a;
  }

  .risk-medium {
    background-color: #fad7a0;
  }

  .risk-low {
    background-color: #f9e79f;
  }

  .ant-table-thead {
    .ant-table-cell {
      background: #1b75b7;
      color: white;
    }
  }
`;

const { Column } = Table;

enum RiskType {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

const riskTypeOrder = {
  [RiskType.HIGH]: 1,
  [RiskType.MEDIUM]: 2,
  [RiskType.LOW]: 3,
};

export const RiskAssessmentRiskFieldListingTable: FC<
  RiskAssessmentRiskFieldListingTableProps
> = ({
  forms,
  interviews,
  isGeneratingRiskSummary,
  handleRiskSummaryDownload,
}) => {
  const { isAdmin } = useAuth();
  const formsById = forms.reduce(
    (formsMap: Map<number, TableRowInterface[]>, form: FormState) => {
      const dataSource = dataMapper(form, interviews[form.id]);
      return formsMap.set(form.id, dataSource);
    },
    new Map<number, TableRowInterface[]>(),
  );

  const allRiskyFields = new Array<TableRowInterface>();
  for (const formById of formsById.values()) {
    allRiskyFields.push(
      ...formById.filter((formField) => formField.hipaaInfo.risk.length > 0),
    );
  }

  const classMap = new Map<string, string>();
  classMap.set(RiskType.HIGH, 'risk-high');
  classMap.set(RiskType.MEDIUM, 'risk-medium');
  classMap.set(RiskType.LOW, 'risk-low');

  const labelMap = new Map<string, string>();
  labelMap.set(RiskType.HIGH, 'High (30-60 Days)');
  labelMap.set(RiskType.MEDIUM, 'Medium (45-90 Days)');
  labelMap.set(RiskType.LOW, 'Low (60-90 Days)');

  let riskFieldValueList = allRiskyFields.reduce(
    (list: Array<RiskyFieldValue>, riskyField: TableRowInterface) => {
      const { answerId, hipaaInfo } = riskyField;

      for (let i = 0; i < riskyField.hipaaInfo.risk.length; i++) {
        list.push({
          actionItem: hipaaInfo.actionItem[i],
          type: hipaaInfo.risk[i] as RiskType,
          id: answerId,
          actionItemCompletedAt: riskyField.actionItemCompletedAt,
        });
      }

      return list;
    },
    new Array<RiskyFieldValue>(),
  );

  riskFieldValueList = riskFieldValueList.sort(
    (riskyFieldValue1: RiskyFieldValue, riskyFieldValue2: RiskyFieldValue) => {
      return (
        riskTypeOrder[riskyFieldValue1.type] -
        riskTypeOrder[riskyFieldValue2.type]
      );
    },
  );

  const exportRiskListHeaders = [
    {
      label: 'Action Item',
      key: 'actionItem',
    },
    {
      label: 'Completion Date',
      key: 'actionItemCompletedAt',
    },
    {
      label: 'Priority',
      key: 'priority',
    },
  ];

  const exportRisksList = riskFieldValueList.reduce(
    (exportRisksList: Array<ExportRiskyField>, risk: RiskyFieldValue) => {
      return exportRisksList.concat([
        {
          actionItem: risk.actionItem,
          priority: labelMap.get(risk.type),
          actionItemCompletedAt: risk.actionItemCompletedAt,
        },
      ]);
    },
    new Array<ExportRiskyField>(),
  );

  const antIcon = <LoadingOutlined spin />;

  return (
    <RiskTableContainer>
      <div>
        {!isGeneratingRiskSummary && isAdmin && (
          <a onClick={handleRiskSummaryDownload}>
            <FilePdfOutlined /> Download Risks (PDF)
          </a>
        )}
        {isGeneratingRiskSummary && (
          <>
            <Spin indicator={antIcon} /> <a> Generating Risks Summary...</a>
          </>
        )}
      </div>
      <div>
        <CSVLink
          data={exportRisksList}
          headers={exportRiskListHeaders}
          className="btn btn-primary"
          target="_blank"
        >
          <FileExcelOutlined /> Download Risks (CSV)
        </CSVLink>
      </div>
      <Table
        dataSource={riskFieldValueList}
        rowClassName={(record: RiskyFieldValue) => {
          return classMap.get(record.type) || '';
        }}
        rowKey="id"
        pagination={{
          pageSize: 10,
        }}
      >
        <Column<RiskyFieldValue>
          title="Action Item"
          render={(_, record: RiskyFieldValue) => {
            return record.actionItem;
          }}
        />
        <Column<RiskyFieldValue>
          title="Completion Date"
          render={(_, record: RiskyFieldValue) => {
            return record.actionItemCompletedAt;
          }}
        />
        <Column<RiskyFieldValue>
          title="Priority"
          render={(_, record: RiskyFieldValue) => {
            return labelMap.get(record.type);
          }}
        />
      </Table>
    </RiskTableContainer>
  );
};
