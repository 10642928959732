import { Loader, TRAINING_SET_SELECTED } from 'src/store/types';
import {
  TrainingTypes,
  TrainingState,
  QuizQuestion,
  TrainingSubmission,
  TRAINING_CREATE_FAILED,
  TRAINING_CREATE_REQUEST,
  TRAINING_CREATE_SUCCESS,
  TRAINING_RESET,
  TRAINING_QUESTION_CREATE_REQUEST,
  TRAINING_QUESTION_CREATE_SUCCESS,
  TRAINING_QUESTION_CREATE_FAILED,
  TRAINING_QUESTION_UPDATE_REQUEST,
  TRAINING_QUESTION_UPDATE_SUCCESS,
  TRAINING_QUESTION_UPDATE_FAILED,
  TRAINING_LOAD_REQUEST,
  TRAINING_LOAD_SUCCESS,
  TRAINING_LOAD_FAILED,
  TRAINING_UPDATE_REQUEST,
  TRAINING_UPDATE_SUCCESS,
  TRAINING_UPDATE_FAILED,
  TRAINING_QUESTION_DELETE_REQUEST,
  TRAINING_QUESTION_DELETE_FAILED,
  TRAINING_QUESTION_DELETE_SUCCESS,
  TRAINING_SUBMISSION_SUCCESS,
  TRAINING_SUBMISSION_RESPONSE_SUCCESS,
  TRAINING_SUBMISSION_FAILED,
  TRAINING_SUBMISSION_RESPONSE_RESET,
  TRAINING_LOAD_SUBMISSION_SUCCESS,
  TRAINING_CLONE_SUCCESS,
  TRAINING_CLONE_REQUEST,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const trainingInitialState: TrainingState = {
  training: undefined,
  submission: undefined,
  submissionResult: undefined,
  loader: loaderInitialState,
};

export const trainingReducer = (
  state = trainingInitialState,
  action: TrainingTypes,
): TrainingState => {
  switch (action.type) {
    case TRAINING_CREATE_REQUEST:
    case TRAINING_CLONE_REQUEST:
    case TRAINING_QUESTION_CREATE_REQUEST:
    case TRAINING_QUESTION_UPDATE_REQUEST:
    case TRAINING_QUESTION_DELETE_REQUEST:
    case TRAINING_LOAD_REQUEST:
    case TRAINING_UPDATE_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case TRAINING_CREATE_SUCCESS:
    case TRAINING_CLONE_SUCCESS:
    case TRAINING_LOAD_SUCCESS: {
      return {
        ...state,
        training: action.training,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_UPDATE_SUCCESS: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_SUBMISSION_SUCCESS: {
      return {
        ...state,
        submission: action.submission,
      };
    }
    case TRAINING_CREATE_FAILED:
    case TRAINING_LOAD_FAILED:
    case TRAINING_RESET: {
      return {
        ...trainingInitialState,
      };
    }
    case TRAINING_QUESTION_CREATE_SUCCESS: {
      const questions = state.training
        ? [...state.training.questions, action.question]
        : [action.question];
      return {
        ...state,
        training: { ...state.training, questions } as any,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_QUESTION_CREATE_FAILED:
    case TRAINING_QUESTION_UPDATE_FAILED:
    case TRAINING_QUESTION_DELETE_FAILED:
    case TRAINING_UPDATE_FAILED: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
        },
      };
    }
    case TRAINING_QUESTION_UPDATE_SUCCESS: {
      const questions: QuizQuestion[] = state.training?.questions.map(
        (question) =>
          question.id === action.question.id
            ? {
                ...question,
                ...action.question,
                choices: action.question.choices.map((choice) => {
                  const previousChoice = question.choices.find(
                    (currentChoice) => currentChoice.id == choice.id,
                  );
                  return { ...previousChoice, ...choice };
                }),
              }
            : { ...question },
      ) || [action.question];
      return {
        ...state,
        training: { ...state.training, questions } as any,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_QUESTION_DELETE_SUCCESS: {
      const questions: QuizQuestion[] =
        state.training?.questions.filter(
          (question) => question.id !== action.questionId,
        ) || [];
      return {
        ...state,
        training: { ...state.training, questions } as any,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_SUBMISSION_RESPONSE_SUCCESS: {
      return { ...state, submissionResult: action.submissionResult };
    }
    case TRAINING_SUBMISSION_RESPONSE_RESET: {
      return { ...state, submissionResult: undefined };
    }
    case TRAINING_SUBMISSION_FAILED: {
      return { ...state, submission: {} as TrainingSubmission };
    }
    case TRAINING_LOAD_SUBMISSION_SUCCESS: {
      return {
        ...state,
        submission: action.trainingSubmission,
      };
    }
    case TRAINING_SET_SELECTED: {
      return {
        ...state,
        training: action.training,
      };
    }
    default: {
      return state;
    }
  }
};
