import React, { FC } from 'react';
import styled from 'styled-components';

export interface ConditionalPreviewProps {
  condition?: string;
  isClosingTag?: boolean;
  isElseTag?: boolean;
}

const Wrapper = styled.div<{ isClosingTag: boolean }>`
  background-color: #efefef;
  border: 1px solid #909090;
  border-style: dashed;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  margin-left: ${({ isClosingTag }) => (isClosingTag ? '5px' : '0px')};
  margin-right: ${({ isClosingTag }) => (isClosingTag ? '0px' : '5px')};
  padding: 1px 5px;
`;

export const ConditionalPreview: FC<ConditionalPreviewProps> = ({
  condition,
  isClosingTag = false,
  isElseTag = false,
}) => {
  const getText = () => {
    if (isClosingTag) return '/IF';
    if (isElseTag) return 'ELSE';
    return `IF ${condition}`;
  };

  return (
    <Wrapper isClosingTag={isClosingTag}>
      <span>{getText()}</span>
    </Wrapper>
  );
};
