import { call, takeEvery, put } from 'redux-saga/effects';
import {
  CompaniesLoadRequestAction,
  COMPANIES_LOAD_REQUEST,
  CompanyFromResponse,
  COMPANY_ARCHIVE_REQUEST,
  ArchiveCompanyRequestAction,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadCompanies = function* async({
  typeOfTrainings,
  active,
}: CompaniesLoadRequestAction): Generator {
  try {
    const companies = yield call(api.getCompanyList, typeOfTrainings, active);
    yield put(actions.companiesLoadSuccess(companies as CompanyFromResponse[]));
  } catch (err) {
    yield put(
      actions.companiesLoadError('Failed to load companies', err as Error),
    );
  }
};

export const archiveCompany = function* async({
  companyId,
}: ArchiveCompanyRequestAction): Generator {
  try {
    const archivedCompany = yield call(api.archiveCompany, companyId, {
      active: false,
    });
    yield put(
      actions.archiveCompanySuccess(archivedCompany as CompanyFromResponse),
    );
  } catch (err) {
    yield put(
      actions.archiveCompanyError('Failed to archive company', err as Error),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(COMPANIES_LOAD_REQUEST, loadCompanies),
    yield takeEvery(COMPANY_ARCHIVE_REQUEST, archiveCompany),
  ];
}
