import { call, takeEvery, put } from 'redux-saga/effects';
import { examService } from 'src/features/learning';
import actions from 'src/store/actions';
import {
  CertificateListingResponse,
  CERTIFICATE_LISTING_LOAD_REQUEST,
} from '../types';

export const loadCertificateListing = function* async(): Generator {
  try {
    const result = yield call(examService.getPassedSubmissions);
    yield put(
      actions.certificateListingLoadSuccess(
        result as CertificateListingResponse,
      ),
    );
  } catch (err) {
    yield put(
      actions.certificateListingLoadError(
        'Failed to load certificate list',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(CERTIFICATE_LISTING_LOAD_REQUEST, loadCertificateListing),
  ];
}
