/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { RootState, SelectedDocumentState, Loader } from 'src/store/types';

export const getSelectedDocument = (state: RootState): SelectedDocumentState =>
  state.selectedDocument;

export const getSelectedDocumentLoader = (state: RootState): Loader => {
  return state.selectedDocument.loader;
};
