import { Moment } from 'moment';
import {
  AnswerData,
  AnswerFromResponse,
  AnswerList,
  FileData,
  INTERVIEW_ADD_FILE,
  INTERVIEW_CLEAR_SELECTED,
  INTERVIEW_FILE_DOWNLOAD,
  INTERVIEW_LOAD_ANSWERS_ERROR,
  INTERVIEW_LOAD_ANSWERS_REQUEST,
  INTERVIEW_LOAD_ANSWERS_SUCCESS,
  INTERVIEW_MARK_ANSWER_AS_DELETED,
  INTERVIEW_REMOVE_FILE,
  INTERVIEW_SAVE_ANSWER,
  INTERVIEW_SAVE_ERROR,
  INTERVIEW_SAVE_REQUEST,
  INTERVIEW_SAVE_SUCCESS,
  INTERVIEW_SET_QUESTIONS_COUNT,
  INTERVIEW_SET_SELECTED,
  INTERVIEW_SET_VISIBLE_QUESTIONS,
  INTERVIEW_UPDATE_ERROR,
  INTERVIEW_UPDATE_REQUEST,
  INTERVIEW_UPDATE_SUCCESS,
  InterviewAddFileAction,
  InterviewClearSelectedAction,
  InterviewFileDownloadRequestAction,
  InterviewLoadAnswersErrorAction,
  InterviewLoadAnswersRequestAction,
  InterviewLoadAnswersSuccessAction,
  InterviewMarkAnswerAsDeletedAction,
  InterviewMetadataState,
  InterviewRemoveFileAction,
  InterviewSaveAnswerAction,
  InterviewSaveErrorAction,
  InterviewSaveRequestAction,
  InterviewSaveSuccessAction,
  InterviewSetQuestionsCountAction,
  InterviewSetSelectedAction,
  InterviewSetVisibleQuestionsAction,
  InterviewState,
  InterviewUpdateErrorAction,
  InterviewUpdateRequestAction,
  InterviewUpdateSuccessAction,
  InterviewLoadActivityLogRequestAction,
  INTERVIEW_LOAD_ACTIVITY_LOG_REQUEST,
  INTERVIEW_LOAD_ACTIVITY_LOG_SUCCESS,
  InterviewLoadActivityLogSuccessAction,
  InterviewLoadActivityLogErrorAction,
  INTERVIEW_LOAD_ACTIVITY_LOG_ERROR,
  AnswerLoadActivityLogRequestAction,
  ANSWER_LOAD_ACTIVITY_LOG_REQUEST,
  ANSWER_LOAD_ACTIVITY_LOG_SUCCESS,
  AnswerLoadActivityLogSuccessAction,
  AnswerLoadActivityLogErrorAction,
  ANSWER_LOAD_ACTIVITY_LOG_ERROR,
  ActivityLogResponse,
  InterviewCreateAssigneeRequestAction,
  INTERVIEW_CREATE_ASSIGNEE_REQUEST,
  InterviewDeleteAssigneeRequestAction,
  INTERVIEW_DELETE_ASSIGNEE_REQUEST,
  InterviewLoadAssigneesRequestAction,
  INTERVIEW_LOAD_ASSIGNEES_REQUEST,
  InterviewAssignOwnerRequestAction,
  INTERVIEW_ASSIGN_OWNER_REQUEST,
  InterviewRemoveOwnerRequestAction,
  INTERVIEW_REMOVE_OWNER_REQUEST,
  INTERVIEW_CREATE_ASSIGNEE_SUCCESS,
  InterviewCreateAssigneeSuccessAction,
  AssigneeFromResponse,
  InterviewDeleteAssigneeSuccessAction,
  INTERVIEW_DELETE_ASSIGNEE_SUCCESS,
  InterviewLoadAssigneesSuccessAction,
  INTERVIEW_LOAD_ASSIGNEES_SUCCESS,
  InterviewAssignOwnerSuccessAction,
  INTERVIEW_ASSIGN_OWNER_SUCCESS,
  InterviewRemoveOwnerSuccessAction,
  INTERVIEW_REMOVE_OWNER_SUCCESS,
  InterviewFromResponse,
  InterviewCreateAssigneeErrorAction,
  INTERVIEW_CREATE_ASSIGNEE_ERROR,
  InterviewDeleteAssigneeErrorAction,
  INTERVIEW_DELETE_ASSIGNEE_ERROR,
  InterviewLoadAssigneesErrorAction,
  INTERVIEW_LOAD_ASSIGNEES_ERROR,
  InterviewAssignOwnerErrorAction,
  INTERVIEW_ASSIGN_OWNER_ERROR,
  InterviewRemoveOwnerErrorAction,
  INTERVIEW_REMOVE_OWNER_ERROR,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const interviewSaveRequest = (
  formId: number,
  creatorId: number,
  companyId: number,
  assessmentId: number,
): InterviewSaveRequestAction => ({
  type: INTERVIEW_SAVE_REQUEST,
  interview: { formId, creatorId, assessmentId },
  companyId,
});

export const interviewSaveSuccess = (
  interview: InterviewState,
  companyId: number,
): InterviewSaveSuccessAction => ({
  type: INTERVIEW_SAVE_SUCCESS,
  interview,
  companyId,
});

export const interviewSaveError = (
  errorMessage: string,
  originalError: Error,
): InterviewSaveErrorAction =>
  showErrorMessage(INTERVIEW_SAVE_ERROR, errorMessage, originalError);

export const setSelectedInterview = (
  interview: InterviewState,
): InterviewSetSelectedAction => ({
  type: INTERVIEW_SET_SELECTED,
  interview,
});

export const clearSelectedInterview = (): InterviewClearSelectedAction => ({
  type: INTERVIEW_CLEAR_SELECTED,
});

export const interviewUpdateRequest = (
  interview: InterviewState,
  completed = false,
  companyId: number,
  answers: AnswerList,
  metadata: InterviewMetadataState,
): InterviewUpdateRequestAction => ({
  type: INTERVIEW_UPDATE_REQUEST,
  interview: {
    ...interview,
    updatedAt: new Date().toString(),
    completedAt: completed ? new Date().toString() : null,
  },
  companyId,
  answers,
  metadata,
});

export const interviewUpdateSuccess = (
  interview: InterviewState,
  companyId: number,
): InterviewUpdateSuccessAction => ({
  type: INTERVIEW_UPDATE_SUCCESS,
  interview,
  companyId,
});

export const interviewUpdateError = (
  errorMessage: string,
  originalError: Error,
): InterviewUpdateErrorAction =>
  showErrorMessage(INTERVIEW_UPDATE_ERROR, errorMessage, originalError);

export const interviewSaveAnswer = (
  interviewId: number,
  questionId: number,
  userId: number,
  answerData: AnswerData,
): InterviewSaveAnswerAction => ({
  type: INTERVIEW_SAVE_ANSWER,
  interviewId,
  questionId,
  userId,
  answerData,
});

export const interviewDeleteAnswer = ({
  id,
  internalId,
}: AnswerData): InterviewMarkAnswerAsDeletedAction => ({
  type: INTERVIEW_MARK_ANSWER_AS_DELETED,
  answerId: id,
  answerInternalId: internalId,
});

export const interviewLoadAnswersRequest = (
  interviewId: number,
): InterviewLoadAnswersRequestAction => ({
  type: INTERVIEW_LOAD_ANSWERS_REQUEST,
  interviewId,
});

export const interviewLoadAnswersSuccess = (
  answers: AnswerFromResponse[],
): InterviewLoadAnswersSuccessAction => ({
  type: INTERVIEW_LOAD_ANSWERS_SUCCESS,
  answers,
});

export const interviewLoadAnswersError = (
  errorMessage: string,
  originalError: Error,
): InterviewLoadAnswersErrorAction =>
  showErrorMessage(INTERVIEW_LOAD_ANSWERS_ERROR, errorMessage, originalError);

export const interviewLoadActivityLogRequest = (
  fromDate: Moment,
  toDate: Moment,
  email: string,
  page: number,
  pageSize: number,
): InterviewLoadActivityLogRequestAction => ({
  type: INTERVIEW_LOAD_ACTIVITY_LOG_REQUEST,
  fromDate,
  toDate,
  email,
  page,
  pageSize,
});

export const interviewLoadActivityLogSuccess = (
  reconciliation: ActivityLogResponse,
): InterviewLoadActivityLogSuccessAction => ({
  type: INTERVIEW_LOAD_ACTIVITY_LOG_SUCCESS,
  reconciliation,
});

export const interviewLoadActivityLogError = (
  errorMessage: string,
  originalError: Error,
): InterviewLoadActivityLogErrorAction =>
  showErrorMessage(
    INTERVIEW_LOAD_ACTIVITY_LOG_ERROR,
    errorMessage,
    originalError,
  );

export const answerLoadActivityLogRequest = (
  interviewId: number,
  fromDate: Moment,
  toDate: Moment,
  email: string,
  page: number,
  pageSize: number,
): AnswerLoadActivityLogRequestAction => ({
  type: ANSWER_LOAD_ACTIVITY_LOG_REQUEST,
  interviewId,
  fromDate,
  toDate,
  email,
  page,
  pageSize,
});

export const answerLoadActivityLogSuccess = (
  reconciliation: ActivityLogResponse,
): AnswerLoadActivityLogSuccessAction => ({
  type: ANSWER_LOAD_ACTIVITY_LOG_SUCCESS,
  reconciliation,
});

export const answerLoadActivityLogError = (
  errorMessage: string,
  originalError: Error,
): AnswerLoadActivityLogErrorAction =>
  showErrorMessage(ANSWER_LOAD_ACTIVITY_LOG_ERROR, errorMessage, originalError);

export const interviewSetQuestionsCount = (
  value: number,
): InterviewSetQuestionsCountAction => ({
  type: INTERVIEW_SET_QUESTIONS_COUNT,
  value,
});

export const interviewSetVisibleQuestionsIds = (
  ids: number[],
): InterviewSetVisibleQuestionsAction => ({
  type: INTERVIEW_SET_VISIBLE_QUESTIONS,
  ids,
});

export const interviewFileDownloadRequest = (
  file: FileData,
): InterviewFileDownloadRequestAction => ({
  type: INTERVIEW_FILE_DOWNLOAD,
  file,
});

export const interviewAddFile = (file: FileData): InterviewAddFileAction => ({
  type: INTERVIEW_ADD_FILE,
  file,
});

export const interviewRemoveFile = (
  file: FileData,
): InterviewRemoveFileAction => ({
  type: INTERVIEW_REMOVE_FILE,
  file,
});

export const interviewCreateAssigneeRequestAction = (
  interviewId: number,
  assigneeUserId: number,
  questionId: number,
): InterviewCreateAssigneeRequestAction => ({
  type: INTERVIEW_CREATE_ASSIGNEE_REQUEST,
  assigneeUserId,
  questionId,
  interviewId,
});

export const interviewCreateAssigneeSuccessAction = (
  assignee: AssigneeFromResponse,
): InterviewCreateAssigneeSuccessAction => ({
  type: INTERVIEW_CREATE_ASSIGNEE_SUCCESS,
  assignee,
});

export const interviewCreateAssigneeErrorAction = (
  errorMessage: string,
  originalError: Error,
): InterviewCreateAssigneeErrorAction =>
  showErrorMessage(
    INTERVIEW_CREATE_ASSIGNEE_ERROR,
    errorMessage,
    originalError,
  );

export const interviewDeleteAssigneeRequestAction = (
  interviewId: number,
  assigneeId: number,
): InterviewDeleteAssigneeRequestAction => ({
  type: INTERVIEW_DELETE_ASSIGNEE_REQUEST,
  assigneeId,
  interviewId,
});

export const interviewDeleteAssigneeSuccessAction = (
  interviewId: number,
  assigneeId: number,
): InterviewDeleteAssigneeSuccessAction => ({
  type: INTERVIEW_DELETE_ASSIGNEE_SUCCESS,
  interviewId,
  assigneeId,
});

export const interviewDeleteAssigneeErrorAction = (
  errorMessage: string,
  originalError: Error,
): InterviewDeleteAssigneeErrorAction =>
  showErrorMessage(
    INTERVIEW_DELETE_ASSIGNEE_ERROR,
    errorMessage,
    originalError,
  );

export const interviewLoadAssigneesRequestAction = (
  interviewId: number,
): InterviewLoadAssigneesRequestAction => ({
  type: INTERVIEW_LOAD_ASSIGNEES_REQUEST,
  interviewId,
});

export const interviewLoadAssigneesSuccessAction = (
  assignees: AssigneeFromResponse[],
): InterviewLoadAssigneesSuccessAction => ({
  type: INTERVIEW_LOAD_ASSIGNEES_SUCCESS,
  assignees,
});

export const interviewLoadAssigneesErrorAction = (
  errorMessage: string,
  originalError: Error,
): InterviewLoadAssigneesErrorAction =>
  showErrorMessage(INTERVIEW_LOAD_ASSIGNEES_ERROR, errorMessage, originalError);

export const interviewAssignOwnerRequestAction = (
  ownerId: number,
  interviewId: number,
): InterviewAssignOwnerRequestAction => ({
  type: INTERVIEW_ASSIGN_OWNER_REQUEST,
  ownerId,
  interviewId,
});

export const interviewAssignOwnerSuccessAction = (
  interview: InterviewFromResponse,
): InterviewAssignOwnerSuccessAction => ({
  type: INTERVIEW_ASSIGN_OWNER_SUCCESS,
  interview,
});

export const interviewAssignOwnerErrorAction = (
  errorMessage: string,
  originalError: Error,
): InterviewAssignOwnerErrorAction =>
  showErrorMessage(INTERVIEW_ASSIGN_OWNER_ERROR, errorMessage, originalError);

export const interviewRemoveOwnerRequestAction = (
  interviewId: number,
  ownerUserId: number,
): InterviewRemoveOwnerRequestAction => ({
  type: INTERVIEW_REMOVE_OWNER_REQUEST,
  interviewId,
  ownerUserId,
});

export const interviewRemoveOwnerSuccessAction = (
  interviewId: number,
): InterviewRemoveOwnerSuccessAction => ({
  type: INTERVIEW_REMOVE_OWNER_SUCCESS,
  interviewId,
});

export const interviewRemoveOwnerErrorAction = (
  errorMessage: string,
  originalError: Error,
): InterviewRemoveOwnerErrorAction =>
  showErrorMessage(INTERVIEW_REMOVE_OWNER_ERROR, errorMessage, originalError);
