import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Form } from 'antd';
import { AutoComplete as AutoCompleteBase } from 'src/features/risk-assessment/components/AutoCompleteInput';
import { AnswerData, Answer, Question } from 'src/store/types';

export interface Name {
  firstName?: string;
  lastName?: string;
}

interface NameFieldProps {
  value: Name;
  answer: Answer;
  suggestions?: string[];
  question: Question;
  updateAnswer: (answerData: AnswerData) => void;
}

const AutoComplete = styled(AutoCompleteBase)`
  && {
    margin-bottom: 15px;
  }
`;

const InputGroup = styled(Input.Group)`
  &&&&&& input {
    width: 312px;
  }
`;

export const NameField: FC<NameFieldProps> = ({
  value,
  answer,
  question,
  suggestions,
  updateAnswer,
}) => {
  const [internalValue, setInternalValue] = useState<Name | null>(null);

  const saveDraft = (value: Name | null) => {
    if (value) {
      const { firstName, lastName } = value;
      const { id, internalId } = answer ?? {};

      const newAnswer: AnswerData = {
        value: undefined,
        id,
        internalId,
      };

      if (firstName && lastName) {
        newAnswer.value = JSON.stringify(value);
      }

      updateAnswer(newAnswer);
    }
  };

  const triggerAnswerUpdate = (key: string, data: string) => {
    const newInternalValue = {
      ...internalValue,
      [key]: data,
    };

    setInternalValue(newInternalValue);
  };

  const handleOnChange = (key: string) => (value: string) => {
    triggerAnswerUpdate(key, value);
  };

  const handleOnBlur = () => {
    saveDraft(internalValue);
  };

  useEffect(() => {
    if (!internalValue && value) {
      setInternalValue(value);
    }
  }, [value]);

  const disableSuggestions = !question || !question.isSmart;
  const options: Name[] =
    disableSuggestions || !suggestions
      ? []
      : suggestions.map((s) => JSON.parse(s) as Name);

  return (
    <InputGroup>
      <Form.Item
        noStyle
        name={[question?.id || question.internalId || 1, 'firstName']}
        rules={[{ required: true, message: 'First name is required' }]}
      >
        <AutoComplete
          optionData={options}
          optionKey="firstName"
          onBlur={handleOnBlur}
          onChange={handleOnChange('firstName')}
          value={internalValue?.firstName}
          placeholder="First Name"
        />
      </Form.Item>
      <InputGroup />
      <Form.Item
        noStyle
        name={[question?.id || question.internalId || 1, 'lastName']}
        rules={[{ required: true, message: 'Last name is required' }]}
      >
        <AutoCompleteBase
          optionData={options}
          optionKey="lastName"
          onBlur={handleOnBlur}
          onChange={handleOnChange('lastName')}
          value={internalValue?.lastName}
          placeholder="Last Name"
        />
      </Form.Item>
    </InputGroup>
  );
};
