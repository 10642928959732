import {
  TemplateState,
  TEMPLATE_SAVE_REQUEST,
  TEMPLATE_SAVE_SUCCESS,
  TEMPLATE_SAVE_ERROR,
  TEMPLATE_DELETE_REQUEST,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_DELETE_ERROR,
  Loader,
  TemplateLoader,
  TemplateActionsTypes,
  SelectedTemplateState,
  TEMPLATE_CLONE_REQUEST,
  TEMPLATE_CLONE_SUCCESS,
  TEMPLATE_CLONE_ERROR,
  TEMPLATE_LOAD_ERROR,
  TEMPLATE_LOAD_SUCCESS,
  TEMPLATE_LOAD_REQUEST,
  TEMPLATE_UPDATE_REQUEST,
  TEMPLATE_UPDATE_ERROR,
  TEMPLATE_UPDATE_SUCCESS,
  VariableListingIndexedItem,
  VARIABLE_SAVE_ERROR,
  VARIABLE_SAVE_REQUEST,
  VARIABLE_SAVE_SUCCESS,
  VariableActionsTypes,
  VARIABLE_UPDATE_SUCCESS,
  VARIABLE_DELETE_SUCCESS,
  DOCUMENT_DRAFT_SAVE_SUCCESS,
  TemplateProcessingState,
} from 'src/store/types';
import { combineReducers } from 'redux';

const loaderInitialState: TemplateLoader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
  compilationError: '',
};

const variableLoaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const templateInitialState: TemplateState = {
  id: 0,
  title: '',
  body: '',
  folder: '',
  needsReview: false,
  mergeBody: '',
  processingStatus: TemplateProcessingState.READY,
};

const variableInitialState: VariableListingIndexedItem = {};

export const loaderReducer = (
  state: TemplateLoader = loaderInitialState,
  action: TemplateActionsTypes,
): TemplateLoader => {
  switch (action.type) {
    case TEMPLATE_LOAD_REQUEST:
    case TEMPLATE_SAVE_REQUEST:
    case TEMPLATE_UPDATE_REQUEST:
    case TEMPLATE_DELETE_REQUEST:
    case TEMPLATE_CLONE_REQUEST:
      return { ...loaderInitialState, loading: true };
    case TEMPLATE_LOAD_SUCCESS:
    case TEMPLATE_SAVE_SUCCESS:
    case TEMPLATE_UPDATE_SUCCESS:
    case TEMPLATE_DELETE_SUCCESS:
    case TEMPLATE_CLONE_SUCCESS:
      return { ...loaderInitialState, success: true, completed: true };
    case TEMPLATE_LOAD_ERROR:
    case TEMPLATE_DELETE_ERROR:
    case TEMPLATE_CLONE_ERROR:
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
        compilationError: '',
      };
    case TEMPLATE_SAVE_ERROR:
    case TEMPLATE_UPDATE_ERROR:
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
        compilationError: (action.originalError as any)?.response?.data
          .message as string,
      };
    default:
      return state;
  }
};

export const templateReducer = (
  state: TemplateState = templateInitialState,
  action: TemplateActionsTypes,
): TemplateState => {
  switch (action.type) {
    case TEMPLATE_LOAD_SUCCESS:
    case TEMPLATE_CLONE_SUCCESS:
      return { ...templateInitialState, ...action.template };
    case DOCUMENT_DRAFT_SAVE_SUCCESS:
      return { ...templateInitialState, ...action.documentDraft.template };
    case TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.updatedTemplate,
        processingStatus: TemplateProcessingState.PROCESSING,
      };
    default:
      return state;
  }
};

export const variableReducer = (
  state: VariableListingIndexedItem = variableInitialState,
  action: VariableActionsTypes,
): VariableListingIndexedItem => {
  switch (action.type) {
    case TEMPLATE_LOAD_SUCCESS:
      const { variables } = action.template;
      return variables
        ? variables.reduce(
            (variableMap, variable) => ({
              ...variableMap,
              [variable.id]: variable,
            }),
            {},
          )
        : variableInitialState;
    case VARIABLE_SAVE_SUCCESS:
    case VARIABLE_UPDATE_SUCCESS:
      const { variable } = action;
      return {
        ...state,
        [variable.id]: variable,
      };
    case VARIABLE_DELETE_SUCCESS:
      const { variableId } = action;
      const newState = { ...state };
      delete newState[variableId];
      return newState;
    default:
      return state;
  }
};

export const variablesLoaderReducer = (
  state: Loader = variableLoaderInitialState,
  action: VariableActionsTypes,
): Loader => {
  switch (action.type) {
    case VARIABLE_SAVE_REQUEST:
      return { ...loaderInitialState, loading: true };
    case VARIABLE_SAVE_SUCCESS:
      return { ...loaderInitialState, success: true, completed: true };
    case VARIABLE_SAVE_ERROR:
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
      };
    default:
      return state;
  }
};

export const combinedTemplateReducer = combineReducers<SelectedTemplateState>({
  template: templateReducer,
  loader: loaderReducer,
  variables: variableReducer,
  variablesLoader: variablesLoaderReducer,
});
