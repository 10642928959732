import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { UrlParams } from 'src/shared/enums';

export const useReturnUrl = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { user } = useAuth();

  const hasReturnUrl = () => {
    const redirectTo = new URLSearchParams(search).get(UrlParams.RETURN_URL);

    return !!redirectTo;
  };

  const redirectToReturnUrl = (redirectTo: string): void => {
    if (redirectTo && user.isSecondFactorAuthenticated) {
      history.push(redirectTo);
    }
  };

  const redirectToDefaultUrl = (): void => {
    if (user.isSecondFactorAuthenticated) {
      history.push('/');
    }
  };

  const returnUrlHandler = () => {
    const redirectTo = new URLSearchParams(search).get(UrlParams.RETURN_URL);

    if (redirectTo) {
      redirectToReturnUrl(redirectTo);
    }

    if (!redirectTo) {
      redirectToDefaultUrl();
    }
  };

  return {
    returnUrlHandler,
    hasReturnUrl,
  };
};
