import React, { FC } from 'react';
import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';

export interface IconButtonProps {
  onClick?: () => void;
  icon: React.ReactNode;
  text?: string;
}

const Button = styled(ButtonAntd)`
  font-size: 18px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkBlue.main};
  box-shadow: unset;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.darkBlue.main};
  }
`;

export const IconButton: FC<IconButtonProps> = ({
  onClick,
  icon,
  text = '',
}) => (
  <Button onClick={onClick} type="text">
    {icon} {text}
  </Button>
);
