import {
  UserSummaryListingIndexedItem,
  RootState,
  UserSummaryState,
} from '../types';

export const getUserSummary = (
  state: RootState,
  valueToSearch = '',
): UserSummaryListingIndexedItem => {
  let users: UserSummaryState[] = Object.values(
    state.company.userSummaryListing.users,
  );

  if (valueToSearch) {
    const capsValue = valueToSearch.toUpperCase();
    users = users.filter(({ name, email, role }: any) => {
      return (
        name.toUpperCase().includes(capsValue) ||
        email.toUpperCase().includes(capsValue) ||
        role.toUpperCase().includes(capsValue)
      );
    });
  }
  return users.reduce(
    (users: UserSummaryListingIndexedItem, user: UserSummaryState) => {
      return {
        ...users,
        [user.id]: user,
      };
    },
    {},
  );
};

export const getUsersSize = (state: RootState): number => {
  return Object.values(state.company.userSummaryListing.users).length;
};
