import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Table, Empty } from 'antd';

import {
  UserSummaryListingIndexedItem,
  UserSummaryState,
  Trainee,
  BasicUser,
  TrainingPeriodListingElement,
  TrainingPeriodStatus,
} from 'src/store/types';

import { Center, CustomGrid } from './UserTrainingSummaryStyles';
import { sourceMapper } from './dataMapper';
import { UsersTableSettings } from '../UsersTableSettings';

const { Column } = Table;

export interface UserTrainingSummaryListingTableProps {
  users: UserSummaryListingIndexedItem;
  companyTrainingType?: string;
  onEdit: (userId: number) => void;
  onReleaseIndividualTraining: (user: BasicUser) => void;
  trainingPeriods: TrainingPeriodListingElement[];
}

export const UserTrainingSummaryListingTable: FC<
  UserTrainingSummaryListingTableProps
> = (props) => {
  const {
    users,
    companyTrainingType,
    onReleaseIndividualTraining,
    trainingPeriods,
  } = props;
  const { onEdit } = props;

  const [dataSource, setDataSource] = useState<UserSummaryState[]>([]);

  useEffect(() => {
    const userRows = sourceMapper(users);
    setDataSource(userRows);
  }, [users, companyTrainingType]);

  const openTrainingPeriod = useCallback(
    (record: BasicUser) => {
      return trainingPeriods.find(
        (tp: TrainingPeriodListingElement) =>
          tp.user?.id === record.id && tp.status === TrainingPeriodStatus.OPEN,
      );
    },
    [trainingPeriods],
  );

  return (
    <CustomGrid
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
    >
      <Column<Trainee>
        title="Name"
        dataIndex="name"
        key="name"
        render={(_, { name }: Trainee) => {
          return <div style={{ color: 'green' }}>{name}</div>;
        }}
      />
      <Column<Trainee>
        title="Email"
        dataIndex="email"
        key="email"
        render={(_, { email }: Trainee) => {
          return <div style={{ color: 'green' }}>{email}</div>;
        }}
      />
      <Column<Trainee> title="Role" dataIndex="role" key="role" />
      <Column<Trainee>
        title="Training Type"
        dataIndex="trainingType"
        key="trainingType"
      />

      <Column<Trainee>
        title="Training Progress"
        dataIndex="progress"
        key="progress"
        render={(_, { progress = null }: Trainee) =>
          progress === null ? <Center>--</Center> : `${progress}%`
        }
      />

      <Column<Trainee>
        title="Test Score"
        dataIndex="score"
        key="score"
        render={(_, { score = null }: Trainee) => (
          <Center>{score === null ? '--' : `${score}%`}</Center>
        )}
      />
      <Column<Trainee>
        title="Last Login"
        dataIndex="lastLoginAt"
        key="lastLoginAt"
        render={(_, { lastLoginAt }: Trainee) => {
          return (
            <div style={{ color: 'green' }}>
              {lastLoginAt === null
                ? '--'
                : moment(lastLoginAt).format('YYYY/MM/DD hh:mm:ss a')}
            </div>
          );
        }}
      />
      <Column<Trainee>
        title={false}
        dataIndex=""
        key="action"
        render={(_, record: Trainee) => (
          <UsersTableSettings
            user={record}
            onEdit={onEdit}
            onReleaseIndividualTraining={onReleaseIndividualTraining}
            openTrainingPeriod={openTrainingPeriod(record)}
          />
        )}
      />
    </CustomGrid>
  );
};
