/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  RootState,
  Loader,
  ConstantListingIndexedItem,
  ConstantState,
} from '../types';

export const getConstantLoader = (state: RootState): Loader =>
  state.constantListing.loader;
export const getConstants = (state: RootState): ConstantListingIndexedItem =>
  state.constantListing.constants;
export const getConstantById = (state: RootState, id: number): ConstantState =>
  state.constantListing.constants[id];
