import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

export const FormFieldsContainer = styled.div`
  display: block;
  margin: 0 auto;
`;

export const FormSubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${remConvert(48)};
`;
