/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import axios from 'axios';
import { api } from 'src/api/services/ThApi';
import { FileData } from 'src/store/types';

const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
const AWS_REGION = process.env.REACT_APP_AWS_REGION;

export const normalizeFile = (file: FileData): Partial<FileData> => {
  const fileData: Partial<FileData> = { name: file.name, uid: file.name };
  if (file.xhr) {
    fileData.xhr = {
      bucket: file.xhr.bucket || '',
      key: file.xhr.key || '',
      Location: file.xhr.Location || '',
    };
  }
  return fileData;
};

export const uploadFile = async ({
  onSuccess,
  onError,
  file,
}: {
  action: string;
  onSuccess: any;
  onError: any;
  file: FileData;
}): Promise<void> => {
  try {
    const { type: contentType } = file;
    const key = window.crypto.randomUUID();
    const uploadUrl = await api.getUploadUrl(key, contentType);

    const data = await axios.put(uploadUrl, file, {
      headers: {
        Key: key,
        'Content-Type': contentType,
      },
    });

    data.key = key;
    data.Location = `https://${BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/${key}`;

    onSuccess(null, data, file);
  } catch (err) {
    onError(err);
  }
};
