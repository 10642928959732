import {
  RootState,
  FeatureFlagState,
  FeatureFlagResponse,
} from 'src/store/types';

export const getFeatureFlagsState = (state: RootState): FeatureFlagState =>
  state.featureFlag;
export const getFeatureFlagsModules = (state: RootState): FeatureFlagResponse =>
  state.featureFlag.modules;
export const getFeatureFlagsLoader = (state: RootState): boolean =>
  state.featureFlag.completed;
