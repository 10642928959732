import React, { FC } from 'react';
import styled from 'styled-components';
import { Radio as RadioAntd } from 'antd';
import { Choice, Question, AnswerData, Answer } from 'src/store/types';
import { RadioChangeEvent } from 'antd/lib/radio';
import { remConvert } from 'src/theme/utils';

interface YesNoFieldProps {
  onChange: (value: string) => void;
  value: string;
  question: Question;
  updateAnswer: (answerData: AnswerData) => void;
  answer: Answer;
}

interface RadioOption {
  label: string;
  value: string;
  key: number;
}

const Radio = styled(RadioAntd)`
  height: ${remConvert(34)};
  line-height: ${remConvert(34)};
  display: flex;
  font-size: 14px;
  align-items: center;

  input {
    flex-grow: 1;
    margin-left: ${remConvert(20)};
  }

  .ant-radio-inner {
    border-color: rgba(0, 0, 0, 0.3) !important;
  }
`;

export const YesNoField: FC<YesNoFieldProps> = ({
  onChange,
  value,
  question,
  updateAnswer,
  answer,
}) => {
  const triggerChange = (e: RadioChangeEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const newValue = e.target.value as string;
    onChange(newValue);
    updateAnswer({
      value: newValue,
      id: answer?.id,
      internalId: answer?.internalId,
    });
  };

  const optionToChoiceId: { [k: string]: number } = {};

  let options: RadioOption[] = [];

  if (question.choices) {
    options = question.choices
      .map((c: Choice) => {
        const id = (c.id ? c.id : c.internalId) as number;
        optionToChoiceId[c.label] = id;
        return {
          key: id,
          label: c.label,
          value: c.label,
          weight: c.weight,
        };
      })
      .sort((a, b) => a.weight - b.weight);
  }

  return (
    <Radio.Group onChange={triggerChange} value={value}>
      {options.map((o) => (
        <Radio key={o.key} value={o.value}>
          {o.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};
