import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { CheckOutlined, DeleteOutlined } from 'src/theme/icons';
import { ConflictRegExpToken } from './useRegExpTokens';

export interface ConflictResolverProps {
  data: string;
  nodeKey: string;
  source: string;
  setSource: (val: string) => void;
  conflictToken: ConflictRegExpToken;
}

interface ConflictHeaderProps {
  title: string;
  onApply: () => void;
  disabled: boolean;
}

const ContainerBase = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 5px 0;
`;

const ContainerMain = styled(ContainerBase)`
  border: 1px dashed #909090;
  padding: 1px 5px;
`;

const ContainerSelection = styled(ContainerBase)`
  background-color: #efefef;
`;

const TitleContainer = styled.div<{ disabled: boolean }>`
  align-items: center;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grayscale.preDark : theme.colors.lightBlue.main};
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  justify-content: space-between;
  padding: 0 5px;

  span {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
  }

  .anticon {
    font-size: 14px;
    padding: 0 5px;
  }

  .icons-container {
    align-content: flex-end;
  }
`;

const ConflictHeader: FC<ConflictHeaderProps> = ({
  title,
  onApply,
  disabled,
}) => (
  <ContainerSelection>
    <TitleContainer disabled={disabled} onClick={onApply}>
      <span className="title">{title}</span>
      {!disabled && (
        <div className="icons-container">
          <CheckOutlined onClick={onApply} />
        </div>
      )}
    </TitleContainer>
  </ContainerSelection>
);

export const ConflictResolver: FC<ConflictResolverProps> = ({
  data,
  nodeKey,
  source,
  setSource,
  conflictToken,
}) => {
  const [sourceLines, setSourceLines] = useState<string[]>(source.split('\n'));
  const positionRE =
    /(?<type>\w+)-(?<line>[0-9]+)-(?<column>[0-9]+)-(?<offset>[0-9]+)/;

  const { line } = positionRE.exec(nodeKey)?.groups as {
    line: string;
    column: string;
  };
  const lineNumber = Number(line);

  useEffect(() => {
    setSourceLines(source.split('\n'));
  }, [source]);

  const applyChanges = () => {
    const startLine = getConflictStartLine();
    const dividerLine = getBranchDividerLine();
    const endLine = getConflictEndLine();

    const isFirstBranch = startLine === lineNumber - 1;

    setSource(
      sourceLines
        .filter((_v, idx) => {
          if (isFirstBranch) {
            return (idx < dividerLine && idx !== startLine) || idx > endLine;
          } else {
            return idx < startLine || (idx > dividerLine && idx !== endLine);
          }
        })
        .join('\n'),
    );
  };

  const getConflictStartLine = (): number => {
    const isConflictStart =
      sourceLines[lineNumber - 1] === conflictToken.getConflictStart();

    if (isConflictStart) {
      return lineNumber - 1;
    } else {
      const reversedIndex = sourceLines
        .slice(0, lineNumber)
        .reverse()
        .findIndex((line) => line.includes(conflictToken.getConflictStart()));
      return lineNumber - 1 - reversedIndex;
    }
  };

  const getBranchDividerLine = (): number => {
    const isBranchDivider =
      sourceLines[lineNumber - 1] === conflictToken.getConflictBranchDivider();

    return isBranchDivider
      ? lineNumber - 1
      : sourceLines
          .slice(lineNumber, sourceLines.length)
          .findIndex((line) =>
            line.includes(conflictToken.getConflictBranchDivider()),
          ) + lineNumber;
  };

  const getConflictEndLine = (): number => {
    return (
      sourceLines
        .slice(lineNumber)
        .findIndex((line) => line.includes(conflictToken.getConflictEnd())) +
      lineNumber
    );
  };

  const getTitle = (): string => {
    switch (data) {
      case conflictToken.getConflictStart():
        return 'Current template';
      case conflictToken.getConflictBranchDivider():
        return 'Parent template';
      case conflictToken.getConflictEnd():
        return 'End of conflict';
      default:
        return '';
    }
  };

  const isDisabled = (): boolean => data === conflictToken.getConflictEnd();

  return (
    <ContainerMain>
      <ConflictHeader
        title={getTitle()}
        onApply={applyChanges}
        disabled={isDisabled()}
      />
    </ContainerMain>
  );
};
