import { Loader } from 'src/store/types';
import {
  ExamListingActionTypes,
  ExamListingState,
  EXAM_ACTIVATE_FAILED,
  EXAM_ACTIVATE_REQUEST,
  EXAM_ACTIVATE_SUCCESS,
  EXAM_ARCHIVE_SUCCESS,
  EXAM_DELETE_FAILED,
  EXAM_DELETE_REQUEST,
  EXAM_DELETE_SUCCESS,
  EXAM_LISTING_LOAD_FAILED,
  EXAM_LISTING_LOAD_REQUEST,
  EXAM_LISTING_LOAD_SUCCESS,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const examListingInitialState: ExamListingState = {
  examListingResponse: {
    records: [],
    total: 0,
    page: 0,
    pageSize: 0,
  },
  loader: loaderInitialState,
};

export const examListingReducer = (
  state = examListingInitialState,
  action: ExamListingActionTypes,
): ExamListingState => {
  switch (action.type) {
    case EXAM_LISTING_LOAD_REQUEST:
    case EXAM_DELETE_REQUEST:
    case EXAM_ACTIVATE_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case EXAM_LISTING_LOAD_SUCCESS: {
      return {
        examListingResponse: action.examListing,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_LISTING_LOAD_FAILED: {
      return {
        ...examListingInitialState,
      };
    }
    case EXAM_DELETE_SUCCESS: {
      const newRecords = state.examListingResponse.records.filter(
        (record) => record.id !== action.examId,
      );
      return {
        examListingResponse: {
          ...state.examListingResponse,
          records: newRecords,
        },
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_ACTIVATE_SUCCESS: {
      const activatedExam = state.examListingResponse.records.find(
        (record) => record.id === action.examId,
      );
      const newRecords = state.examListingResponse.records.map((record) =>
        record.id === action.examId
          ? { ...record, status: 'ACTIVE' }
          : activatedExam?.trainingTypeName === record.trainingTypeName
            ? { ...record, status: 'ARCHIVE' }
            : { ...record },
      );
      return {
        examListingResponse: {
          ...state.examListingResponse,
          records: newRecords,
        },
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_ARCHIVE_SUCCESS: {
      return {
        examListingResponse: {
          ...state.examListingResponse,
          records: state.examListingResponse.records.map((record) =>
            record.id === action.examId
              ? { ...record, status: 'ARCHIVE' }
              : record,
          ),
        },
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case EXAM_DELETE_FAILED:
    case EXAM_ACTIVATE_FAILED: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
        },
      };
    }
    default:
      return state;
  }
};
