import { call, takeEvery, put } from 'redux-saga/effects';
import { certificateService } from 'src/features/learning';
import actions from 'src/store/actions';
import {
  DownloadTrainingCertificateRequestAction,
  DOWNLOAD_TRAINING_CERTIFICATE_REQUEST,
} from '../types';

export const download = function* async({
  examSubmissionId,
  userName,
  trainingTypeName,
}: DownloadTrainingCertificateRequestAction): Generator {
  try {
    yield call(
      certificateService.generate,
      examSubmissionId,
      userName,
      trainingTypeName,
    );
    yield put(actions.downloadTrainingCertificateSuccess());
  } catch (err) {
    yield put(
      actions.downloadTrainingCertificateError(
        'Failed to download certificate',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(DOWNLOAD_TRAINING_CERTIFICATE_REQUEST, download)];
}
