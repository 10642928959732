import { Loader } from './loader';
import { ShowErrorAction } from './error';

export const CONSTANTS_LOAD_REQUEST = 'CONSTANTS_LOAD_REQUEST';
export const CONSTANTS_LOAD_SUCCESS = 'CONSTANTS_LOAD_SUCCESS';
export const CONSTANTS_LOAD_ERROR = 'CONSTANTS_LOAD_ERROR';

export interface ConstantState {
  id: number;
  createdAt: string;
  updatedAt: string;
  key: string;
  description?: string;
}

export interface ConstantListingState {
  constants: ConstantListingIndexedItem;
  loader: Loader;
}

export interface ConstantListingIndexedItem {
  [key: string]: ConstantState;
}

export interface ConstantsLoadRequestAction {
  type: typeof CONSTANTS_LOAD_REQUEST;
}

export interface ConstantsLoadSuccessAction {
  type: typeof CONSTANTS_LOAD_SUCCESS;
  constants: ConstantState[];
}

export type ConstantsLoadErrorAction = ShowErrorAction<
  typeof CONSTANTS_LOAD_ERROR
>;

export type ConstantListingActionTypes =
  | ConstantsLoadRequestAction
  | ConstantsLoadSuccessAction
  | ConstantsLoadErrorAction;
