import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Menu as MenuAntd, Badge } from 'antd';

import { useAuth } from 'src/hooks/useAuth/useAuth';
import {
  getCompanyFromState,
  getPendingNotifications,
} from 'src/store/selectors';
import {
  AppstoreOutlined,
  AuditOutlined,
  BarsOutlined,
  BellOutlined,
  BellFilled,
  FormOutlined,
  UserOutlined,
  BookOutlined,
  FileProtectOutlined,
  PieChartOutlined,
  PlayCircleOutlined,
  HomeOutlined,
  CommentOutlined,
  FileTextOutlined,
} from 'src/theme/icons';
import { remConvert } from 'src/theme/utils';
import { RoutesEnum } from 'src/shared/enums';
import { TypeOfTraining } from 'src/store/types';
import { useAssessment } from 'src/hooks/useAssessment';

interface SidebarMenuProps {
  collapsed: boolean;
}

const Menu = styled(MenuAntd)`
  color: ${({ theme }): string => theme.colors.white.main};
  flex-grow: 1;
  overflow: initial;
  font-family: ${({ theme }): string => theme.fontFamilies.primary};
  font-style: ${({ theme }): string => theme.fontStyles.normal};
  font-weight: ${({ theme }): number => theme.fontWeights.semiBold};
  font-size: 13px;
  line-height: ${remConvert(18)};
  color: #eef4ff;

  &.ant-menu-vertical,
  &.ant-menu-inline {
    border-width: ${remConvert(0)};
  }

  &.ant-menu-inline-collapsed {
    width: ${remConvert(70)};

    > .ant-menu-item {
      padding: ${remConvert(0)} ${remConvert(25)};
    }
  }

  & .ant-menu-item {
    border-radius: ${remConvert(0)} ${remConvert(30)} ${remConvert(30)}
      ${remConvert(0)};
    padding-left: ${remConvert(22)};
    height: ${remConvert(64)};
    line-height: ${remConvert(64)};
    color: ${({ theme }): string => theme.colors.morningGlory.main};
    overflow: initial;

    .ant-badge {
      color: inherit;
    }

    .ant-badge-count {
      background-color: ${({ theme }): string => theme.colors.white.main};
      color: ${({ theme }): string => theme.colors.black.main};
      font-family: ${({ theme }) => theme.fontFamilies.primary};
    }

    &[style] {
      padding-left: ${remConvert(22)} !important;
    }

    span.anticon {
      font-size: ${({ theme }): string => theme.colors.white.main};
      margin-right: ${remConvert(32)};
    }

    &.ant-menu-item-active {
      color: ${({ theme }): string => theme.colors.white.main};
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.ant-menu-item-selected {
      color: ${({ theme }): string => theme.colors.white.main};
      background-color: rgba(255, 255, 255, 0.1);

      &:before {
        border: 1px solid ${({ theme }): string => theme.colors.white.main};
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        content: '';
      }
    }

    .anticon {
      font-size: 20px;
    }
  }
`;

export const SidebarMenu: FC<SidebarMenuProps> = ({ collapsed }) => {
  const {
    user,
    isAdmin,
    isCompanyModerator,
    isUser,
    isTrainee,
    is2FAauthenticated,
    isOfficer,
  } = useAuth();
  const pendingNotifications = useSelector(getPendingNotifications);
  const company = useSelector(getCompanyFromState);
  const { assessment } = useAssessment();
  const companyId = user.company ? user.company.id : 0;
  const [documentDraftLink, setDocumentDraftLink] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (assessment.lastDocumentId) {
      setDocumentDraftLink(
        `/companies/${companyId}/assessments/${assessment.id}/assessmentReports/${assessment.lastDocumentId}/documentDrafts/`,
      );
    }
  }, [assessment]);

  const {
    location: { pathname },
  } = useHistory();

  const [selectedItem, setSelectedItem] = useState<RoutesEnum>(
    RoutesEnum.DASHBOARD,
  );

  useEffect(() => {
    const [, basePath] = pathname.split('/');
    const route = `/${basePath}` as RoutesEnum;
    setSelectedItem(route);
  }, [pathname]);

  return (
    <Menu mode="vertical" selectedKeys={[selectedItem]}>
      {is2FAauthenticated && isAdmin && (
        <MenuAntd.Item
          key={RoutesEnum.DASHBOARD}
          icon={<AppstoreOutlined />}
          title="Dashboard"
        >
          <Link to={RoutesEnum.DASHBOARD} />
          {!collapsed && <span>Dashboard</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && isUser && (
        <MenuAntd.Item
          key={RoutesEnum.NOTIFICATIONS}
          icon={
            user.hasPendingNotifications ? (
              <Badge
                count={pendingNotifications.length}
                offset={[-30, 10]}
                size="small"
              >
                <BellFilled />
              </Badge>
            ) : (
              <BellOutlined />
            )
          }
          title="Notifications"
        >
          <Link to={RoutesEnum.NOTIFICATIONS} />
          {!collapsed && <span>Notifications</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && isOfficer && !isAdmin && (
        <MenuAntd.Item
          key={RoutesEnum.MY_COMPANY}
          icon={<HomeOutlined />}
          title="My Company"
        >
          <Link to={RoutesEnum.MY_COMPANY} />
          {!collapsed && <span>My Company</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && isTrainee && (
        <MenuAntd.Item
          key={RoutesEnum.MY_TRAINING}
          icon={<PlayCircleOutlined />}
          title="Training"
        >
          <Link to={RoutesEnum.MY_TRAINING} />
          {!collapsed && <span>Training</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated &&
        isOfficer &&
        !isAdmin &&
        company &&
        company.typeOfTraining !== TypeOfTraining.TRAINING_ONLY && (
          <MenuAntd.Item
            key={RoutesEnum.RISK_ASSESSMENT}
            icon={<PieChartOutlined />}
            title="Risk Assessment"
          >
            <Link to={RoutesEnum.RISK_ASSESSMENT} />
            {!collapsed && <span>Risk Assessment</span>}
          </MenuAntd.Item>
        )}
      {is2FAauthenticated && isTrainee && (
        <MenuAntd.Item
          key={RoutesEnum.CERTIFICATES}
          icon={<FileProtectOutlined />}
          title="Certificates"
        >
          <Link to={RoutesEnum.CERTIFICATES} />
          {!collapsed && <span>Certificates</span>}
        </MenuAntd.Item>
      )}
      {documentDraftLink && isOfficer && !isAdmin && (
        <MenuAntd.Item
          key={documentDraftLink}
          icon={<FileTextOutlined />}
          title="Document Draft"
        >
          <Link to={documentDraftLink} />
          {!collapsed && <span>Document Draft</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && (isAdmin || isCompanyModerator) && (
        <MenuAntd.Item
          key={RoutesEnum.COMPANIES}
          icon={<BarsOutlined />}
          title="Companies/Assessments"
        >
          <Link to={RoutesEnum.COMPANIES} />
          {!collapsed && <span>Companies</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && isAdmin && (
        <MenuAntd.Item
          key={RoutesEnum.TEMPLATES}
          icon={<AuditOutlined />}
          title="Templates"
        >
          <Link to={RoutesEnum.TEMPLATES} />
          {!collapsed && <span>Templates</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && isAdmin && (
        <MenuAntd.Item
          key={RoutesEnum.FORMS}
          icon={<FormOutlined />}
          title="Forms builder"
        >
          <Link to={RoutesEnum.FORMS} />
          {!collapsed && <span>Forms</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && isAdmin && (
        <MenuAntd.Item
          key={RoutesEnum.USER_MANAGEMENT}
          icon={<UserOutlined />}
          title="Users Management"
        >
          <Link to={RoutesEnum.USER_MANAGEMENT} />
          {!collapsed && <span>User Management</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && isAdmin && (
        <MenuAntd.Item
          key={RoutesEnum.TRAINING_MANAGEMENT}
          icon={<BookOutlined />}
          title="Learning Management"
        >
          <Link to={RoutesEnum.TRAINING_MANAGEMENT} />
          {!collapsed && <span>HIPAA Training</span>}
        </MenuAntd.Item>
      )}
      {is2FAauthenticated && !isAdmin && (isOfficer || isTrainee) && (
        <MenuAntd.Item
          key="help-desk"
          icon={<CommentOutlined />}
          title="Help Desk"
        >
          <a
            href="https://hipaaprime.zohodesk.com/portal/en/kb/total-hipaa-compliance-llc"
            target="_blank"
            rel="noreferrer"
          />
          {!collapsed && <span>Help Desk</span>}
        </MenuAntd.Item>
      )}
    </Menu>
  );
};
