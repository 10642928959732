import { normalOpenSans } from 'src/theme';
import { remConvert } from 'src/theme/utils';
import styled from 'styled-components';

export const SimpleRadialChartContainer = styled.div`
  .radial-percent-text {
    ${normalOpenSans}
    font-weight: 700;
    font-size: 12px;
    line-height: ${remConvert(16)};
    letter-spacing: 0.2em;
  }

  .radial-percent-number {
    ${normalOpenSans}
    font-weight: 600;
    font-size: 40px;
    line-height: ${remConvert(54)};
  }
`;
