import styled from 'styled-components';
import { remConvert } from 'src/theme/utils/rem-convert';
import { centeredFlex, device } from 'src/theme';

export const PageContainer = styled.div`
  position: relative;
`;

export const PageContentContainer = styled.div`
  margin-top: ${remConvert(22)};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  @media ${device.md} {
    width: ${remConvert(400)};
  }
`;

export const PageTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-top: ${remConvert(8)};
  text-align: center;
  font-size: 34px;
  letter-spacing: 0.1em;
`;

export const PageContentBottomContainer = styled.div`
  ${centeredFlex}
  justify-content: center;
  padding-top: ${remConvert(42)};
`;

export const GearImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  visibility: hidden;

  @media ${device.lg} {
    visibility: visible;
  }
`;
