import { Loader, ShowErrorAction } from 'src/store/types';

export const CERTIFICATE_LISTING_LOAD_REQUEST =
  'CERTIFICATE_LISTING_LOAD_REQUEST';
export const CERTIFICATE_LISTING_LOAD_SUCCESS =
  'CERTIFICATE_LISTING_LOAD_SUCCESS';
export const CERTIFICATE_LISTING_LOAD_FAILED =
  'CERTIFICATE_LISTING_LOAD_FAILED';

export type CertificateListingElement = {
  examId: number;
  submissionId: number;
  trainingPeriodId: number;
  trainingPeriodDate: Date;
  releaseDate: Date;
  isExpired: boolean;
  completedAt: Date;
};

export type CertificateListingResponse = CertificateListingElement[];

export type CertificateListingState = {
  certificateListingResponse: CertificateListingResponse;
  loader: Loader;
};

export interface CertificateListingLoadRequestAction {
  type: typeof CERTIFICATE_LISTING_LOAD_REQUEST;
}

export interface CertificateListingLoadSuccessAction {
  type: typeof CERTIFICATE_LISTING_LOAD_SUCCESS;
  certificateListing: CertificateListingResponse;
}

export type CertificateListingLoadErrorAction = ShowErrorAction<
  typeof CERTIFICATE_LISTING_LOAD_FAILED
>;

export type CertificateListingActionTypes =
  | CertificateListingLoadRequestAction
  | CertificateListingLoadSuccessAction
  | CertificateListingLoadErrorAction;
