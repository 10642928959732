import { combineReducers } from 'redux';
import {
  Loader,
  DocumentType,
  DocumentState,
  DocumentStatus,
  DocumentActionTypes,
  SelectedDocumentState,
  DOCUMENT_LOAD_REQUEST,
  DOCUMENT_LOAD_SUCCESS,
  DOCUMENT_LOAD_ERROR,
  SET_SELECTED_DOCUMENT,
  DOCUMENT_ANSWER_UPDATE,
  InterviewState,
  AnswerList,
  Answer,
  DocumentMetadataState,
  DOCUMENT_SAVE_SUCCESS,
  AssessmentReportDocument,
  DOCUMENT_PP_SAVE_REQUEST,
  DOCUMENT_PP_SAVE_SUCCESS,
  DOCUMENT_PP_SAVE_ERROR,
  DOCUMENT_SET_IN_CLIENT_REVIEW_SUCCESS,
  DOCUMENT_ACCEPT_SUCCESS,
  DOCUMENT_FINALIZE_SUCCESS,
  DOCUMENT_APPROVE_SUCCESS,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const documentInitialState: DocumentState = {
  id: 0,
  createdAt: '',
  updatedAt: '',
  status: DocumentStatus.CLOSED,
  name: '',
  assessmentId: undefined,
  creatorId: undefined,
  file: undefined,
  type: DocumentType.REPORT,
};

const documentMetadataInitialState: DocumentMetadataState = {
  isPristine: true,
  saveCompleted: false,
};

const loaderReducer = (
  state: Loader = loaderInitialState,
  action: DocumentActionTypes,
): Loader => {
  switch (action.type) {
    case DOCUMENT_LOAD_REQUEST:
    case DOCUMENT_PP_SAVE_REQUEST: {
      return { ...loaderInitialState, loading: true };
    }
    case DOCUMENT_LOAD_SUCCESS:
    case DOCUMENT_PP_SAVE_SUCCESS: {
      return { ...loaderInitialState, success: true, completed: true };
    }
    case DOCUMENT_LOAD_ERROR:
    case DOCUMENT_PP_SAVE_ERROR: {
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
      };
    }
    default:
      return state;
  }
};

const documentReducer = (
  state: DocumentState = documentInitialState,
  action: DocumentActionTypes,
): DocumentState => {
  switch (action.type) {
    case DOCUMENT_LOAD_SUCCESS: {
      const {
        id,
        createdAt,
        updatedAt,
        status,
        name,
        assessment,
        creator,
        file,
        type,
        documentDraft,
      } = action.document;
      return {
        id,
        createdAt,
        updatedAt,
        status,
        name,
        assessmentId: assessment?.id,
        creatorId: creator?.id,
        file,
        type,
        documentDraftId: documentDraft ? documentDraft.id : undefined,
      };
    }
    case SET_SELECTED_DOCUMENT: {
      return { ...action.document };
    }
    case DOCUMENT_ANSWER_UPDATE: {
      const { answerId, interviewId, answerUpdates } = action;
      const doc = { ...state };
      const file = doc.file
        ? (doc.file as AssessmentReportDocument)
        : undefined;
      const interviewIdx = file?.interviews.findIndex(
        (i) => i.id === interviewId,
      );
      const interview = (
        typeof interviewIdx !== 'undefined'
          ? { ...file?.interviews[interviewIdx] }
          : {}
      ) as InterviewState;
      const answerIdx =
        interview && interview.answers?.findIndex((a) => a.id === answerId);
      const updatedAnswer =
        interview &&
        ({
          ...interview.answers?.[answerIdx as number],
          ...answerUpdates,
        } as Answer);

      if (
        typeof interviewIdx !== 'undefined' &&
        file?.interviews[interviewIdx]
      ) {
        if (file?.interviews[interviewIdx].answers?.[answerIdx as number]) {
          (file.interviews[interviewIdx].answers as AnswerList)[
            answerIdx as number
          ] = { ...updatedAnswer };
        }
      }

      return { ...doc };
    }
    case DOCUMENT_SET_IN_CLIENT_REVIEW_SUCCESS: {
      return { ...state, status: DocumentStatus.CLIENT_REVIEW };
    }
    case DOCUMENT_ACCEPT_SUCCESS: {
      return { ...state, status: DocumentStatus.ACCEPTED };
    }
    case DOCUMENT_APPROVE_SUCCESS: {
      return { ...state, status: DocumentStatus.APPROVED };
    }
    default:
      return state;
  }
};

const documentMetadataReducer = (
  state: DocumentMetadataState = documentMetadataInitialState,
  action: DocumentActionTypes,
): DocumentMetadataState => {
  switch (action.type) {
    case DOCUMENT_SAVE_SUCCESS: {
      return { ...state, isPristine: true, saveCompleted: true };
    }
    case DOCUMENT_ANSWER_UPDATE: {
      return { ...state, isPristine: false, saveCompleted: true };
    }
    default:
      return state;
  }
};

export const combinedDocumentReducer = combineReducers<SelectedDocumentState>({
  document: documentReducer,
  loader: loaderReducer,
  metadata: documentMetadataReducer,
});
