/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import { getCompanyById } from './companyListing';
import {
  RootState,
  AssessmentListingIndexedItem,
  AssessmentState,
  CompanyState,
  AssessmentRiskAnalysisState,
  Loader,
} from '../types';

export const getIndexedAssessments = (
  state: RootState,
): AssessmentListingIndexedItem => {
  return state.assessmentListing.assessments;
};

export const getAssessmentsFromState = (
  state: RootState,
): AssessmentState[] => {
  return Object.values(getIndexedAssessments(state));
};

export const getCompanyAssessmentRiskAnalysis = (
  state: RootState,
): AssessmentRiskAnalysisState => {
  return state.selectedAssessment.risksAnalysis;
};

export const getCompanyAssessmentRiskAnalysisLoader = (
  state: RootState,
): Loader => {
  return state.selectedAssessment.loader;
};

export const getCompanyAssessmentIds = (state: RootState): number[] => {
  return state.company.company.assessmentIds || [];
};

export const getCompanyLastAssessmentId = (
  state: RootState,
): number | undefined => {
  return state.company.company.lastAssessmentId;
};

export const getIndexedAssessmentsByActiveCompany = createSelector(
  (state: RootState) => getIndexedAssessments(state),
  (state: RootState) => getCompanyAssessmentIds(state),
  (assessments: AssessmentListingIndexedItem, ids: number[]) => {
    return ids.reduce(
      (indexedAssessments: AssessmentListingIndexedItem, id: number) => {
        return {
          ...indexedAssessments,
          [id]: assessments[id],
        };
      },
      {},
    );
  },
);

export const getIndexedAssessmentsByCompanyId = createSelector(
  (state: RootState) => getIndexedAssessments(state),
  (state: RootState, companyId: number) => getCompanyById(state, companyId),
  (assessments: AssessmentListingIndexedItem, company: CompanyState) => {
    return (
      company.assessmentIds &&
      company.assessmentIds.reduce(
        (indexedAssessments: AssessmentListingIndexedItem, id: number) => ({
          ...indexedAssessments,
          [id]: assessments[id],
        }),
        {} as AssessmentListingIndexedItem,
      )
    );
  },
);

export const getCompanyLastAssessment = createSelector(
  (state: RootState) => getIndexedAssessments(state),
  (state: RootState) => getCompanyLastAssessmentId(state),
  (
    assessments: AssessmentListingIndexedItem,
    lastAssessmentId: number | undefined,
  ) => {
    if (lastAssessmentId) return assessments[lastAssessmentId];
    return {};
  },
);

export const getCompanyOlderAssessments = createSelector(
  (state: RootState) => getIndexedAssessments(state),
  (state: RootState) => getCompanyAssessmentIds(state),
  (state: RootState) => getCompanyLastAssessmentId(state),
  (
    assessments: AssessmentListingIndexedItem,
    assessmentIds: number[],
    lastAssessmentId: number | undefined,
  ) => {
    const olderAssessmentsIds = lastAssessmentId
      ? assessmentIds.filter((id) => id !== lastAssessmentId)
      : assessmentIds;
    return olderAssessmentsIds.map((id) => assessments[id]);
  },
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getAssessmentById = createSelector(
  (state: RootState) => getIndexedAssessments(state),
  (_: RootState, assessmentId: number | string) => assessmentId,
  (assessments: AssessmentListingIndexedItem, assessmentId: number | string) =>
    assessments[assessmentId],
);
