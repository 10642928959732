import {
  AssessmentState,
  User,
  Loader,
  AssessmentFromResponse,
  InterviewState,
  FormState,
  Answer,
  DocumentDraftFromResponse,
  ShowErrorAction,
} from '../types';
import { DocumentDraftState } from './documentDraft';

export const DOCUMENT_LOAD_REQUEST = 'DOCUMENT_LOAD_REQUEST';
export const DOCUMENT_LOAD_SUCCESS = 'DOCUMENT_LOAD_SUCCESS';
export const DOCUMENT_LOAD_ERROR = 'DOCUMENT_LOAD_ERROR';
export const DOCUMENT_APPROVE_REQUEST = 'DOCUMENT_APPROVE_REQUEST';
export const DOCUMENT_APPROVE_SUCCESS = 'DOCUMENT_APPROVE_SUCCESS';
export const DOCUMENT_APPROVE_ERROR = 'DOCUMENT_APPROVE_ERROR';
export const DOCUMENT_FINALIZE_REQUEST = 'DOCUMENT_FINALIZE_REQUEST';
export const DOCUMENT_FINALIZE_SUCCESS = 'DOCUMENT_FINALIZE_SUCCESS';
export const DOCUMENT_FINALIZE_ERROR = 'DOCUMENT_FINALIZE_ERROR';
export const DOCUMENT_ACCEPT_REQUEST = 'DOCUMENT_ACCEPT_REQUEST';
export const DOCUMENT_ACCEPT_SUCCESS = 'DOCUMENT_ACCEPT_SUCCESS';
export const DOCUMENT_ACCEPT_ERROR = 'DOCUMENT_ACCEPT_ERROR';
export const DOCUMENT_SET_IN_CLIENT_REVIEW_REQUEST =
  'DOCUMENT_SET_IN_CLIENT_REVIEW_REQUEST';
export const DOCUMENT_SET_IN_CLIENT_REVIEW_SUCCESS =
  'DOCUMENT_SET_IN_CLIENT_REVIEW_SUCCESS';
export const DOCUMENT_SET_IN_CLIENT_REVIEW_ERROR =
  'DOCUMENT_SET_IN_CLIENT_REVIEW_ERROR';
export const DOCUMENT_CLOSE_REQUEST = 'DOCUMENT_CLOSE_REQUEST';
export const DOCUMENT_CLOSE_SUCCESS = 'DOCUMENT_CLOSE_SUCCESS';
export const DOCUMENT_CLOSE_ERROR = 'DOCUMENT_CLOSE_ERROR';
export const SET_SELECTED_DOCUMENT = 'SET_SELECTED_DOCUMENT';
export const DOCUMENT_ANSWER_UPDATE = 'DOCUMENT_ANSWER_UPDATE';
export const DOCUMENT_ANSWER_UPDATE_FAILED = 'DOCUMENT_ANSWER_UPDATE_FAILED';
export const DOCUMENT_SAVE_REQUEST = 'DOCUMENT_SAVE_REQUEST';
export const DOCUMENT_SAVE_SUCCESS = 'DOCUMENT_SAVE_SUCCESS';
export const DOCUMENT_SAVE_ERROR = 'DOCUMENT_SAVE_ERROR';
export const DOCUMENT_PP_SAVE_REQUEST = 'DOCUMENT_PP_SAVE_REQUEST';
export const DOCUMENT_PP_SAVE_SUCCESS = 'DOCUMENT_PP_SAVE_SUCCESS';
export const DOCUMENT_PP_SAVE_ERROR = 'DOCUMENT_PP_SAVE_ERROR';

export enum DocumentStatus {
  ACTIVE = 'Active',
  CLIENT_REVIEW = 'In Review',
  ACCEPTED = 'Accepted',
  APPROVED = 'Approved',
  CLOSED = 'Closed',
}

export enum DocumentType {
  PP = 'P&P',
  REPORT = 'REPORT',
}

export interface DocumentFromResponse {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: DocumentStatus;
  name: string;
  assessment?: AssessmentState;
  creator?: User;
  file?: AssessmentReportDocument | string;
  type: DocumentType;
  documentDrafts?: DocumentDraftFromResponse[];
  documentDraft?: DocumentDraftFromResponse;
}

export interface DocumentState
  extends Omit<DocumentFromResponse, 'assessment' | 'creator'> {
  assessmentId?: number;
  creatorId?: number;
  documentDraftId?: number;
}

export interface SelectedDocumentState {
  document: DocumentState;
  loader: Loader;
  metadata: DocumentMetadataState;
}

export interface AssessmentReportDocument {
  assessment: AssessmentFromResponse;
  forms: FormState[];
  interviews: InterviewState[];
}

export const isAssessmentReportDocument = (
  file: AssessmentReportDocument | any,
): boolean => {
  return !!(file as AssessmentReportDocument).assessment;
};

export interface DocumentMetadataState {
  isPristine: boolean;
  saveCompleted: boolean;
}
export interface DocumentLoadRequestAction {
  type: typeof DOCUMENT_LOAD_REQUEST;
  documentId: number;
}

export interface DocumentLoadSuccessAction {
  type: typeof DOCUMENT_LOAD_SUCCESS;
  document: DocumentFromResponse;
}

export type DocumentLoadErrorAction = ShowErrorAction<
  typeof DOCUMENT_LOAD_ERROR
>;

export interface DocumentApproveRequestAction {
  type: typeof DOCUMENT_APPROVE_REQUEST;
  documentId: number;
}

export interface DocumentApproveSuccessAction {
  type: typeof DOCUMENT_APPROVE_SUCCESS;
  documentId: number;
}

export type DocumentApproveErrorAction = ShowErrorAction<
  typeof DOCUMENT_APPROVE_ERROR
>;

export interface DocumentFinalizeRequestAction {
  type: typeof DOCUMENT_FINALIZE_REQUEST;
  documentId: number;
}

export interface DocumentFinalizeSuccessAction {
  type: typeof DOCUMENT_FINALIZE_SUCCESS;
  documentId: number;
}

export type DocumentFinalizeErrorAction = ShowErrorAction<
  typeof DOCUMENT_FINALIZE_ERROR
>;

export interface DocumentAcceptRequestAction {
  type: typeof DOCUMENT_ACCEPT_REQUEST;
  documentId: number;
}

export interface DocumentAcceptSuccessAction {
  type: typeof DOCUMENT_ACCEPT_SUCCESS;
  documentId: number;
}

export type DocumentAcceptErrorAction = ShowErrorAction<
  typeof DOCUMENT_ACCEPT_ERROR
>;

export interface DocumentSetInClientReviewRequestAction {
  type: typeof DOCUMENT_SET_IN_CLIENT_REVIEW_REQUEST;
  documentId: number;
}

export interface DocumentSetInClientReviewSuccessAction {
  type: typeof DOCUMENT_SET_IN_CLIENT_REVIEW_SUCCESS;
  documentId: number;
}

export type DocumentSetInClientReviewErrorAction = ShowErrorAction<
  typeof DOCUMENT_SET_IN_CLIENT_REVIEW_ERROR
>;

export interface DocumentCloseRequestAction {
  type: typeof DOCUMENT_CLOSE_REQUEST;
  documentId: number;
}

export interface DocumentCloseSuccessAction {
  type: typeof DOCUMENT_CLOSE_SUCCESS;
  documentId: number;
}

export type DocumentCloseErrorAction = ShowErrorAction<
  typeof DOCUMENT_CLOSE_ERROR
>;

export interface SetSelectedDocumentAction {
  type: typeof SET_SELECTED_DOCUMENT;
  document: DocumentState;
}

export interface DocumentAnswerUpdateAction {
  type: typeof DOCUMENT_ANSWER_UPDATE;
  answerId: number;
  interviewId: number;
  answerUpdates: Partial<Answer>;
}

export interface DocumentAnswerUpdateFailedAction {
  type: typeof DOCUMENT_ANSWER_UPDATE_FAILED;
  error: string;
}

export interface DocumentSaveRequestAction {
  type: typeof DOCUMENT_SAVE_REQUEST;
  document: DocumentState;
}

export interface DocumentSaveSuccessAction {
  type: typeof DOCUMENT_SAVE_SUCCESS;
  document: DocumentState;
}

export type DocumentSaveErrorAction = ShowErrorAction<
  typeof DOCUMENT_SAVE_ERROR
>;

export interface DocumentPPSaveRequestAction {
  type: typeof DOCUMENT_PP_SAVE_REQUEST;
  documentDraft: DocumentDraftState;
  assessmentId: number;
}

export interface DocumentPPSaveSuccessAction {
  type: typeof DOCUMENT_PP_SAVE_SUCCESS;
}

export type DocumentPPSaveErrorAction = ShowErrorAction<
  typeof DOCUMENT_PP_SAVE_ERROR
>;

export type DocumentActionTypes =
  | DocumentLoadRequestAction
  | DocumentLoadErrorAction
  | DocumentLoadSuccessAction
  | DocumentApproveRequestAction
  | DocumentApproveErrorAction
  | DocumentApproveSuccessAction
  | DocumentCloseRequestAction
  | DocumentCloseErrorAction
  | DocumentCloseSuccessAction
  | SetSelectedDocumentAction
  | DocumentAnswerUpdateAction
  | DocumentAnswerUpdateFailedAction
  | DocumentSaveErrorAction
  | DocumentSaveRequestAction
  | DocumentSaveSuccessAction
  | DocumentPPSaveRequestAction
  | DocumentPPSaveSuccessAction
  | DocumentPPSaveErrorAction
  | DocumentSetInClientReviewErrorAction
  | DocumentSetInClientReviewRequestAction
  | DocumentSetInClientReviewSuccessAction
  | DocumentAcceptRequestAction
  | DocumentAcceptSuccessAction
  | DocumentAcceptErrorAction
  | DocumentFinalizeRequestAction
  | DocumentFinalizeSuccessAction
  | DocumentFinalizeErrorAction;
