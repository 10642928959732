import React, { FC } from 'react';
import styled from 'styled-components';
import { HelpSection } from './HelpSection';

interface QuestionTokenHelpSection {
  questionTokenHelpOpen: boolean;
  setQuestionTokenHelpOpen: (value: boolean) => void;
}

const HelpSectionItem = styled.div`
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-family: monospace;
`;

const InlineHelpSectionItem = styled(HelpSectionItem)`
  display: inline-block;
`;

export const QuestionTokenHelpSection: FC<QuestionTokenHelpSection> = ({
  questionTokenHelpOpen,
  setQuestionTokenHelpOpen,
}) => (
  <HelpSection
    isOpen={questionTokenHelpOpen}
    setIsOpen={setQuestionTokenHelpOpen}
    title={'Question Tokens'}
  >
    <InlineHelpSectionItem>[q#QID]</InlineHelpSectionItem>
    <p>
      Format:&nbsp;
      <b>"[q#"</b>&nbsp;+&nbsp;
      <b>ID]</b>
    </p>
    <HelpSectionItem>
      [q#22] will be replaced with the answer of the question whose id is 22
    </HelpSectionItem>
  </HelpSection>
);
