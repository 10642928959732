import {
  TemplateState,
  TemplateInput,
  TEMPLATE_SAVE_REQUEST,
  TEMPLATE_SAVE_SUCCESS,
  TEMPLATE_SAVE_ERROR,
  TEMPLATE_DELETE_REQUEST,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_DELETE_ERROR,
  TEMPLATE_CLONE_REQUEST,
  TEMPLATE_CLONE_SUCCESS,
  TEMPLATE_CLONE_ERROR,
  TemplateSaveRequestAction,
  TemplateSaveSuccessAction,
  TemplateSaveErrorAction,
  TemplateDeleteRequestAction,
  TemplateDeleteSuccessAction,
  TemplateDeleteErrorAction,
  TemplateCloneRequestAction,
  TemplateCloneSuccessAction,
  TemplateCloneErrorAction,
  TemplateLoadRequestAction,
  TEMPLATE_LOAD_REQUEST,
  TemplateLoadSuccessAction,
  TemplateLoadErrorAction,
  TEMPLATE_LOAD_SUCCESS,
  TEMPLATE_LOAD_ERROR,
  TemplateUpdateRequestAction,
  TEMPLATE_UPDATE_REQUEST,
  TemplateUpdateSuccessAction,
  TEMPLATE_UPDATE_SUCCESS,
  TemplateUpdateErrorAction,
  TEMPLATE_UPDATE_ERROR,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const templateLoadRequest = (
  templateId: number,
): TemplateLoadRequestAction => ({
  type: TEMPLATE_LOAD_REQUEST,
  templateId,
});
export const templateLoadSuccess = (
  template: TemplateState,
): TemplateLoadSuccessAction => ({
  type: TEMPLATE_LOAD_SUCCESS,
  template,
});
export const templateLoadError = (
  errorMessage: string,
  originalError: Error,
): TemplateLoadErrorAction =>
  showErrorMessage(TEMPLATE_LOAD_ERROR, errorMessage, originalError);

export const templateUpdateRequest = (
  templateId: number,
  template: Partial<TemplateState>,
): TemplateUpdateRequestAction => ({
  type: TEMPLATE_UPDATE_REQUEST,
  templateId,
  template,
});

export const templateUpdateSuccess = (
  updatedTemplate: Partial<TemplateState>,
): TemplateUpdateSuccessAction => ({
  type: TEMPLATE_UPDATE_SUCCESS,
  updatedTemplate,
});

export const templateUpdateError = (
  errorMessage: string,
  originalError: Error,
): TemplateUpdateErrorAction =>
  showErrorMessage(TEMPLATE_UPDATE_ERROR, errorMessage, originalError);

export const templateSaveRequest = (
  template: TemplateInput,
): TemplateSaveRequestAction => ({
  type: TEMPLATE_SAVE_REQUEST,
  template,
});

export const templateSaveSuccess = (
  template: TemplateState,
): TemplateSaveSuccessAction => ({
  type: TEMPLATE_SAVE_SUCCESS,
  template,
});

export const templateSaveError = (
  errorMessage: string,
  originalError: Error,
): TemplateSaveErrorAction =>
  showErrorMessage(TEMPLATE_SAVE_ERROR, errorMessage, originalError);

export const templateDeleteRequest = (
  templateId: number,
): TemplateDeleteRequestAction => ({
  type: TEMPLATE_DELETE_REQUEST,
  templateId,
});

export const templateDeleteSuccess = (
  templateId: number,
): TemplateDeleteSuccessAction => ({
  type: TEMPLATE_DELETE_SUCCESS,
  templateId,
});

export const templateDeleteError = (
  errorMessage: string,
  originalError: Error,
): TemplateDeleteErrorAction =>
  showErrorMessage(TEMPLATE_DELETE_ERROR, errorMessage, originalError);

export const templateCloneRequest = (
  template: TemplateInput,
  templateId: number,
  onlyDuplicate: boolean,
): TemplateCloneRequestAction => ({
  type: TEMPLATE_CLONE_REQUEST,
  template,
  templateId,
  onlyDuplicate,
});

export const templateCloneSuccess = (
  template: TemplateState,
): TemplateCloneSuccessAction => ({
  type: TEMPLATE_CLONE_SUCCESS,
  template,
});

export const templateCloneError = (
  errorMessage: string,
  originalError: Error,
): TemplateCloneErrorAction =>
  showErrorMessage(TEMPLATE_CLONE_ERROR, errorMessage, originalError);
