import {
  CompaniesLoadRequestAction,
  COMPANIES_LOAD_REQUEST,
  CompaniesLoadSuccessAction,
  COMPANIES_LOAD_SUCCESS,
  CompaniesLoadErrorAction,
  COMPANIES_LOAD_ERROR,
  COMPANY_ARCHIVE_REQUEST,
  ArchiveCompanyRequestAction,
  COMPANY_ARCHIVE_SUCCESS,
  ArchiveCompanySuccessAction,
  COMPANY_ARCHIVE_ERROR,
  ArchiveCompanyErrorAction,
  ADD_COMPANY_TO_LIST,
  AddCompanyToListAction,
  CompanyFromResponse,
  TypeOfTraining,
} from '../types';
import { showErrorMessage } from './error';

export const companiesLoadRequest = (
  typeOfTrainings: null | TypeOfTraining[] = null,
  active = true,
): CompaniesLoadRequestAction => ({
  type: COMPANIES_LOAD_REQUEST,
  active,
  typeOfTrainings,
});

export const companiesLoadSuccess = (
  companies: CompanyFromResponse[],
): CompaniesLoadSuccessAction => ({
  type: COMPANIES_LOAD_SUCCESS,
  companies,
});

export const companiesLoadError = (
  errorMessage: string,
  originalError?: Error,
): CompaniesLoadErrorAction =>
  showErrorMessage(COMPANIES_LOAD_ERROR, errorMessage, originalError);

export const archiveCompanyRequest = (
  companyId: number,
): ArchiveCompanyRequestAction => ({
  type: COMPANY_ARCHIVE_REQUEST,
  companyId,
});

export const archiveCompanySuccess = (
  archivedCompany: CompanyFromResponse,
): ArchiveCompanySuccessAction => ({
  type: COMPANY_ARCHIVE_SUCCESS,
  archivedCompany,
});

export const archiveCompanyError = (
  errorMessage: string,
  originalError?: Error,
): ArchiveCompanyErrorAction =>
  showErrorMessage(COMPANY_ARCHIVE_ERROR, errorMessage, originalError);

export const addCompanyToList = (
  company: CompanyFromResponse,
): AddCompanyToListAction => ({
  type: ADD_COMPANY_TO_LIST,
  company,
});
