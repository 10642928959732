import { call, takeEvery, put } from 'redux-saga/effects';
import { trainingService } from 'src/features/learning';
import actions from 'src/store/actions';
import {
  TrainingCreateRequestAction,
  Training,
  TRAINING_CREATE_REQUEST,
  TRAINING_QUESTION_CREATE_REQUEST,
  TrainingQuestionCreateRequestAction,
  QuizQuestion,
  TrainingQuestionUpdateRequestAction,
  TRAINING_QUESTION_UPDATE_REQUEST,
  TrainingLoadRequestAction,
  TRAINING_LOAD_REQUEST,
  TrainingUpdateRequestAction,
  TRAINING_UPDATE_REQUEST,
  TrainingQuestionDeleteRequestAction,
  TRAINING_QUESTION_DELETE_REQUEST,
  TrainingSubmissionRequestAction,
  TRAINING_SUBMISSION_REQUEST,
  TrainingSubmission,
  TrainingSubmissionResponseRequestAction,
  TRAINING_SUBMISSION_RESPONSE_REQUEST,
  TrainingSubmissionResult,
  TrainingLoadSubmissionRequestAction,
  TRAINING_LOAD_SUBMISSION_REQUEST,
  TrainingCloneRequestAction,
  TRAINING_CLONE_REQUEST,
} from '../types';

export const createTraining = function* async({
  config,
}: TrainingCreateRequestAction): Generator {
  try {
    const result = yield call(trainingService.create, config);
    yield put(actions.trainingCreateSuccess(result as Training));
  } catch (err) {
    yield put(
      actions.trainingCreateError('Failed to create training', err as Error),
    );
  }
};

export const updateTraining = function* async({
  trainingId,
  config,
}: TrainingUpdateRequestAction): Generator {
  try {
    const result = yield call(trainingService.update, trainingId, config);
    yield put(actions.trainingUpdateSuccess(result as Training));
  } catch (err) {
    yield put(
      actions.trainingUpdateError('Failed to update training', err as Error),
    );
  }
};

export const createTrainingQuestion = function* async({
  trainingId,
}: TrainingQuestionCreateRequestAction): Generator {
  try {
    const result = yield call(trainingService.createQuestion, trainingId);
    yield put(actions.trainingQuestionCreateSuccess(result as QuizQuestion));
  } catch (err) {
    yield put(
      actions.trainingQuestionCreateError(
        'Failed to create training question',
        err as Error,
      ),
    );
  }
};

export const updateTrainingQuestion = function* async({
  trainingId,
  question,
}: TrainingQuestionUpdateRequestAction): Generator {
  try {
    const result = yield call(
      trainingService.updateQuestion,
      trainingId,
      question,
    );
    yield put(actions.trainingQuestionUpdateSuccess(result as QuizQuestion));
  } catch (err) {
    yield put(
      actions.trainingQuestionUpdateError(
        'Failed to save training question',
        err as Error,
      ),
    );
  }
};

export const deleteTrainingQuestion = function* async({
  trainingId,
  questionId,
}: TrainingQuestionDeleteRequestAction): Generator {
  try {
    const result = yield call(
      trainingService.deleteQuestion,
      trainingId,
      questionId,
    );
    yield put(actions.trainingQuestionDeleteSuccess(questionId));
  } catch (err) {
    yield put(
      actions.trainingQuestionDeleteError(
        'Failed to delete training question',
        err as Error,
      ),
    );
  }
};

export const loadTraining = function* async({
  trainingId,
}: TrainingLoadRequestAction): Generator {
  try {
    const result = yield call(trainingService.getById, trainingId);
    yield put(actions.trainingLoadSuccess(result as Training));
  } catch (err) {
    yield put(
      actions.trainingLoadError('Failed to load training', err as Error),
    );
  }
};

export const createTrainingSubmission = function* async({
  trainingId,
}: TrainingSubmissionRequestAction): Generator {
  try {
    const result = yield call(trainingService.createSubmission, trainingId);
    yield put(actions.trainingSubmissionSuccess(result as TrainingSubmission));
  } catch (err) {
    const errorMessage =
      err instanceof Error
        ? err.message
        : 'Failed to create training submission';
    yield put(actions.trainingSubmissionError(errorMessage, err as Error));
  }
};

export const createTrainingResponse = function* async({
  trainingId,
  submissionId,
  questionId,
  choiceId,
  compute,
}: TrainingSubmissionResponseRequestAction): Generator {
  try {
    yield call(
      trainingService.createSubmissionResponse,
      trainingId,
      submissionId,
      questionId,
      choiceId,
    );
    if (compute) {
      const result = yield call(
        trainingService.computeSubmission,
        trainingId,
        submissionId,
      );
      yield put(
        actions.trainingSubmissionResponseSuccess(
          result as TrainingSubmissionResult,
        ),
      );
    } else {
      yield put(actions.trainingSubmissionResponseSuccess());
    }
  } catch (err) {
    yield put(
      actions.trainingSubmissionResponseError(
        'Failed to create training submission response',
        err as Error,
      ),
    );
  }
};

export const getLatestTrainingSubmission = function* async({
  trainingId,
  submissionId,
}: TrainingLoadSubmissionRequestAction): Generator {
  try {
    const response = yield call(
      trainingService.getTrainingSubmission,
      trainingId,
      submissionId,
    );
    yield put(
      actions.trainingLoadSubmissionSuccess(response as TrainingSubmission),
    );
  } catch (err) {
    yield put(
      actions.trainingLoadSubmissionResponseError(
        'Failed to load latest training submission responses',
        err as Error,
      ),
    );
  }
};

export const cloneTraining = function* async({
  trainingId,
}: TrainingCloneRequestAction): Generator {
  try {
    const response = yield call(trainingService.cloneTraining, trainingId);
    yield put(actions.trainingCloneSuccess(response as Training));
  } catch (err) {
    yield put(
      actions.trainingCloneError(
        'Failed to clone the given training',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(TRAINING_CREATE_REQUEST, createTraining),
    yield takeEvery(TRAINING_QUESTION_CREATE_REQUEST, createTrainingQuestion),
    yield takeEvery(TRAINING_QUESTION_UPDATE_REQUEST, updateTrainingQuestion),
    yield takeEvery(TRAINING_QUESTION_DELETE_REQUEST, deleteTrainingQuestion),
    yield takeEvery(TRAINING_LOAD_REQUEST, loadTraining),
    yield takeEvery(TRAINING_UPDATE_REQUEST, updateTraining),
    yield takeEvery(TRAINING_SUBMISSION_REQUEST, createTrainingSubmission),
    yield takeEvery(
      TRAINING_SUBMISSION_RESPONSE_REQUEST,
      createTrainingResponse,
    ),
    yield takeEvery(
      TRAINING_LOAD_SUBMISSION_REQUEST,
      getLatestTrainingSubmission,
    ),
    yield takeEvery(TRAINING_CLONE_REQUEST, cloneTraining),
  ];
}
