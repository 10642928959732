import { combineReducers } from 'redux';
import {
  ASSESSMENT_TYPES_LOAD_LABEL_SUCCESS,
  ASSESSMENT_TYPES_LOAD_LABEL_REQUEST,
  ASSESSMENT_TYPES_LOAD_LABEL_FAILED,
  ASSESSMENT_TYPES_LOAD_DELEGATE_REQUEST,
  ASSESSMENT_TYPES_LOAD_DELEGATE_SUCCESS,
  ASSESSMENT_TYPES_LOAD_DELEGATE_FAILED,
  AssessmentTypesActionTypes,
  SelectedAssessmentTypeState,
  AssessmentTypesLabelFromResponse,
  AssessmentTypeLabelsState,
  Loader,
  AssessmentTypesDelegateFromResponse,
} from 'src/store/types';

const assessmentTypeListingInitialState: AssessmentTypeLabelsState = {
  assessmentTypesLabels: {},
  assessmentTypesDelegates: {},
};

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

export const loaderReducer = (
  state = loaderInitialState,
  action: AssessmentTypesActionTypes,
): Loader => {
  switch (action.type) {
    case ASSESSMENT_TYPES_LOAD_DELEGATE_REQUEST:
    case ASSESSMENT_TYPES_LOAD_LABEL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_TYPES_LOAD_DELEGATE_SUCCESS:
    case ASSESSMENT_TYPES_LOAD_LABEL_SUCCESS: {
      return {
        ...state,
        loading: false,
        completed: true,
        success: true,
      };
    }
    case ASSESSMENT_TYPES_LOAD_DELEGATE_FAILED:
    case ASSESSMENT_TYPES_LOAD_LABEL_FAILED: {
      return {
        ...state,
        loading: false,
        completed: true,
        success: false,
        error: action.message.toString(),
      };
    }
    default: {
      return state;
    }
  }
};

export const assessmentTypeReducer = (
  state = assessmentTypeListingInitialState,
  action: AssessmentTypesActionTypes,
): AssessmentTypeLabelsState => {
  switch (action.type) {
    case ASSESSMENT_TYPES_LOAD_LABEL_SUCCESS: {
      const { labels } = action;
      const assessmentTypeLabelsData = labels.reduce(
        (labelListingIndexedItem, label: AssessmentTypesLabelFromResponse) => {
          return {
            ...labelListingIndexedItem,
            [label.label]: label,
          };
        },
        {},
      );

      return {
        ...state,
        assessmentTypesLabels: assessmentTypeLabelsData,
      };
    }
    case ASSESSMENT_TYPES_LOAD_DELEGATE_SUCCESS: {
      const { delegates } = action;
      const assessmentTypeDelegatesData = delegates.reduce(
        (
          delegateListingIndexedItem,
          delegate: AssessmentTypesDelegateFromResponse,
        ) => {
          return {
            ...delegateListingIndexedItem,
            [delegate.id.toString()]: delegate,
          };
        },
        {},
      );

      return {
        ...state,
        assessmentTypesDelegates: assessmentTypeDelegatesData,
      };
    }
    default: {
      return state;
    }
  }
};

export const combinedAssessmentTypeReducers =
  combineReducers<SelectedAssessmentTypeState>({
    loader: loaderReducer,
    assessmentType: assessmentTypeReducer,
  });
