import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

export const SubmissionResponsesList = styled.ul`
  list-style: none;
  padding: ${remConvert(0)};
`;

export const SubmissionResponsesListItem = styled.li`
  border-bottom: ${remConvert(1)} solid lightgray;
  padding-top: ${remConvert(15)};
  padding-bottom: ${remConvert(15)};
`;

export const SubmissionQuestionText = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

export const SubmissionQuestionChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding-left: ${remConvert(40)};
  padding-top: ${remConvert(15)};
`;

export const SubmissionQuestionIconContainer = styled.span`
  font-size: 25px;
  padding: ${remConvert(10)};
`;

export const SubmissionQuestionAnswer = styled.div`
  padding: ${remConvert(5)};
  font-size: ${remConvert(15)};
`;

export const SubmissionQuestionAnswerHighlighted = styled.div`
  padding: ${remConvert(5)};
  font-size: 15px;
  border: 1px dashed #337d98;
  border-radius: 10px;
`;

export const SubmissionQuestionAnswerRadio = styled.img`
  padding-right: ${remConvert(15)};
`;
