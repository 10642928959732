import {
  TemplateLoadRequestAction,
  TemplateLoadErrorAction,
  TemplateLoadSuccessAction,
  ShowErrorAction,
} from '../types';

export interface VariableState {
  id: number;
  createdAt: string;
  updatedAt: string;
  content: string;
  description?: string;
  isList?: boolean;
}

export interface VariableInput {
  templateId?: number;
  content: string;
  description?: string;
  isList?: boolean;
}

export interface VariableListingIndexedItem {
  [key: string]: VariableState;
}

export const VARIABLE_SAVE_REQUEST = 'VARIABLE_SAVE_REQUEST';
export const VARIABLE_SAVE_SUCCESS = 'VARIABLE_SAVE_SUCCESS';
export const VARIABLE_SAVE_ERROR = 'VARIABLE_SAVE_ERROR';

export const VARIABLE_UPDATE_REQUEST = 'VARIABLE_UPDATE_REQUEST';
export const VARIABLE_UPDATE_SUCCESS = 'VARIABLE_UPDATE_SUCCESS';
export const VARIABLE_UPDATE_ERROR = 'VARIABLE_UPDATE_ERROR';

export const VARIABLE_DELETE_REQUEST = 'VARIABLE_DELETE_REQUEST';
export const VARIABLE_DELETE_SUCCESS = 'VARIABLE_DELETE_SUCCESS';
export const VARIABLE_DELETE_ERROR = 'VARIABLE_DELETE_ERROR';

export interface VariableSaveRequestAction {
  type: typeof VARIABLE_SAVE_REQUEST;
  variable: VariableInput;
}

export interface VariableSaveSuccessAction {
  type: typeof VARIABLE_SAVE_SUCCESS;
  variable: VariableState;
}

export type VariableSaveErrorAction = ShowErrorAction<
  typeof VARIABLE_SAVE_ERROR
>;

export interface VariableUpdateRequestAction {
  type: typeof VARIABLE_UPDATE_REQUEST;
  variable: VariableInput;
  variableId: number;
}

export interface VariableUpdateSuccessAction {
  type: typeof VARIABLE_UPDATE_SUCCESS;
  variable: VariableState;
}

export type VariableUpdateErrorAction = ShowErrorAction<
  typeof VARIABLE_UPDATE_ERROR
>;

export interface VariableDeleteRequestAction {
  type: typeof VARIABLE_DELETE_REQUEST;
  variableId: number;
}

export interface VariableDeleteSuccessAction {
  type: typeof VARIABLE_DELETE_SUCCESS;
  variableId: number;
}

export type VariableDeleteErrorAction = ShowErrorAction<
  typeof VARIABLE_DELETE_ERROR
>;

export type VariableActionsTypes =
  | TemplateLoadErrorAction
  | TemplateLoadRequestAction
  | TemplateLoadSuccessAction
  | VariableUpdateErrorAction
  | VariableUpdateRequestAction
  | VariableUpdateSuccessAction
  | VariableSaveErrorAction
  | VariableSaveRequestAction
  | VariableSaveSuccessAction
  | VariableDeleteErrorAction
  | VariableDeleteRequestAction
  | VariableDeleteSuccessAction;
