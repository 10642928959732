import React from 'react';
import { Dispatch } from 'redux';
import styled, { useTheme } from 'styled-components';
import { Menu as MenuAntD, Dropdown } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SettingOutlined } from 'src/theme/icons';
import { confirmDialog } from 'src/shared/utils';
import { RoutesEnum } from 'src/shared/enums';
import actions from 'src/store/actions';
import { TrainingListingElement } from 'src/store/types';

export interface Props {
  training: TrainingListingElement;
}

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  width: 180px;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

const Menu = styled(MenuAntD)`
  border-radius: 4px;
  margin-top: -35px;
  margin-left: 12px;
  width: 180px;

  .ant-dropdown-menu-item:hover {
    &.title {
      background-color: white;
      cursor: default;
    }
  }

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightBlue.main}`};

    > span {
      font-weight: 600;
    }

    .title-icon {
      color: ${({ theme }) => theme.colors.lightBlue.main};
      font-size: 20px;
    }
  }

  .title-divider {
    color: red;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0px 4px;
    width: 172px;
  }

  span {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }
`;

const menu = (
  training: TrainingListingElement,
  dispatch: Dispatch,
  history: any,
) => (
  <Menu>
    <Menu.Item className="title" key="0">
      <span>Settings</span>
      <SettingOutlined className="title-icon" />
    </Menu.Item>
    {training.status !== 'ARCHIVE' && (
      <Menu.Item
        key="1"
        onClick={() => {
          if (training.status !== 'ACTIVE') {
            history.push(`${RoutesEnum.TRAINING_CONFIG}/${training.id}`);
            return;
          }
          confirmDialog({
            title: 'This training may already contain submissions',
            text: 'You can still edit name and fix typos but you cannot add, remove questions or choices. If you want to add or remove questions or choices, make a copy.',
            onOk: () => {
              history.push(`${RoutesEnum.TRAINING_CONFIG}/${training.id}`);
            },
          });
        }}
      >
        <span>Edit content</span>
      </Menu.Item>
    )}
    <Menu.Item
      key="2"
      onClick={() => {
        confirmDialog({
          title: 'Make a copy',
          text: 'Making a copy will create a new draft training.',
          onOk: () => {
            dispatch(actions.trainingCloneRequest(training.id));
          },
        });
      }}
    >
      <span>Make a copy</span>
    </Menu.Item>
    {training.status === 'ACTIVE' ? (
      <Menu.Item
        key="3"
        onClick={() => {
          confirmDialog({
            text: 'Ths action will archive the selected training',
            onOk: () => {
              dispatch(actions.trainingArchiveRequest(training.id));
            },
          });
        }}
      >
        <span>Archive</span>
      </Menu.Item>
    ) : (
      training.status === 'DRAFT' && (
        <Menu.Item
          key="3"
          onClick={() => {
            confirmDialog({
              text: 'Activating this training version archives the current version with the same training type and test type',
              onOk: () => {
                dispatch(actions.trainingActivateRequest(training.id));
              },
            });
          }}
        >
          <span>Activate</span>
        </Menu.Item>
      )
    )}

    <Menu.Divider />
    {training.status === 'DRAFT' && (
      <Menu.Item
        key="4"
        onClick={() => {
          confirmDialog({
            text: 'Deleting a training permanently removes it and its contents.',
            onOk: () => {
              dispatch(actions.trainingDeleteRequest(training.id));
            },
          });
        }}
      >
        <span>Delete</span>
      </Menu.Item>
    )}
  </Menu>
);

const TrainingSettingsMenu = ({ training }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  return (
    <Dropdown overlay={menu(training, dispatch, history)} trigger={['click']}>
      <DropdownIcon>
        <SettingOutlined style={{ color: theme.colors.lightBlue.main }} />
      </DropdownIcon>
    </Dropdown>
  );
};

export default TrainingSettingsMenu;
