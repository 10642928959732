import { call, takeEvery, put } from 'redux-saga/effects';
import { trainingTypeService } from 'src/features/learning';
import actions from 'src/store/actions';

import { TrainingTypesResponse, TRAINING_TYPES_LOAD_REQUEST } from '../types';

export const loadTrainingTypes = function* async(): Generator {
  try {
    const result = yield call(trainingTypeService.getAll);
    yield put(
      actions.trainingTypesLoadSuccess(result as TrainingTypesResponse),
    );
  } catch (err) {
    yield put(
      actions.trainingTypesLoadError(
        'Failed to load training types',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(TRAINING_TYPES_LOAD_REQUEST, loadTrainingTypes)];
}
