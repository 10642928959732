import React, { Dispatch, FC } from 'react';
import { interviewFileDownloadRequest } from 'src/store/actions/interview';
import { FileData } from 'src/store/types';
import { DownloadOutlined } from 'src/theme/icons';
import { DocumentListingTableProps } from '.';
import { TableRowInterface } from './riskAssessmentTableTypes';
import * as S from './Styles';

export const WithAssessmentTypeTable =
  (
    AssessmentTypeTable: FC<DocumentListingTableProps>,
    dispatch: Dispatch<any>,
  ) =>
  ({ ...props }: DocumentListingTableProps) => {
    const handleDownload = (f: FileData) => {
      dispatch(interviewFileDownloadRequest(f));
    };

    const presentAnswer = (rowData: TableRowInterface) => {
      if (rowData.typeKey === 'file') {
        const fileData = rowData.answer as FileData[];
        return Array.isArray(fileData) && fileData.length > 0 ? (
          <S.LabelContainer>
            {fileData.map(
              (f) =>
                f && (
                  <p className="answer download" key={f.name}>
                    <DownloadOutlined />{' '}
                    <span onClick={() => handleDownload(f)}>
                      Download {f.name}
                    </span>
                  </p>
                ),
            )}
          </S.LabelContainer>
        ) : null;
      }

      return (
        <S.LabelContainer>
          <span>{rowData.answer as string}</span>
        </S.LabelContainer>
      );
    };

    return <AssessmentTypeTable {...props} presentAnswer={presentAnswer} />;
  };
