import styled from 'styled-components';
import { centeredFlex, device, normalOpenSans } from 'src/theme';
import { remConvert } from 'src/theme/utils';

export const TrainingCollapsiblePanelRowHeader = styled.div`
  ${normalOpenSans}
  ${centeredFlex}
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  flex-direction: row;
  padding: ${remConvert(16)} ${remConvert(16)} ${remConvert(16)}
    ${remConvert(10)};
  background: ${({ theme }) => theme.colors.white.main};
  border-width: ${remConvert(1)} ${remConvert(0)};
  border-style: solid;
  border-color: rgba(218, 218, 218, 0.5);
  line-height: ${remConvert(31)};
  color: rgba(28, 36, 40, 0.85);

  > div {
    margin-left: ${remConvert(5.75)};
    width: 100%;
    display: flex;
    flex-direction: column;

    @media ${device.sm} {
      margin-left: ${remConvert(11.75)};
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    span:nth-of-type(2) {
      ${normalOpenSans}
      font-weight: 400;
      font-size: 12px;
      line-height: ${remConvert(16)};
      color: rgba(28, 36, 40, 0.75);
    }
  }
`;

export const TrainingCollapsiblePanelList = styled.ul`
  list-style-type: none;
  width: 100%;
  padding: ${remConvert(0)};
  margin: ${remConvert(0)};
`;

interface TrainingCollapsiblePanelListItemProps {
  selected: boolean;
  disabled: boolean;
}

export const TrainingCollapsiblePanelListItem = styled.li<TrainingCollapsiblePanelListItemProps>`
  background: ${({ theme }) => theme.colors.white.main};
  border-bottom: 1px solid rgba(218, 218, 218, 0.5);
  width: 100%;
  padding: ${remConvert(6)} ${remConvert(9)} ${remConvert(6.5)}
    ${remConvert(16)};
  cursor: pointer;
  position: relative;

  @media ${device.sm} {
    padding: ${remConvert(6)} ${remConvert(9)} ${remConvert(6.5)}
      ${remConvert(64)};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gallery.main};
  }

  ${(props) =>
    props.selected &&
    `
    &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: ${remConvert(7)};
    right: ${remConvert(0)};
    top: ${remConvert(0)};
    background: #77c1c1;
    border-radius: 0px 4px 4px 0px;
    transform: rotate(-180deg);
  }
  `}

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
  `}

  > p {
    ${normalOpenSans}
    font-weight: 600;
    font-size: 14px;
    line-height: ${remConvert(19)};
    color: ${({ theme }) => theme.colors.black.shark};
    margin: ${remConvert(0)};
  }
`;

export const TrainingInformation = styled.div`
  ${centeredFlex}
  justify-content: space-between;
`;

export const TrainingEstimatedDuration = styled.div`
  ${normalOpenSans}
  font-weight: 400;
  font-size: 12px;
  line-height: ${remConvert(16)};
  color: rgba(28, 36, 40, 0.75);
`;
