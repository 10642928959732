import { showErrorMessage } from 'src/store/actions/error';
import {
  TrainingPeriodConfig,
  TrainingPeriodCreateErrorAction,
  TrainingPeriodCreateRequestAction,
  TrainingPeriodCreateSuccessAction,
  TrainingPeriodResetAction,
  TrainingPeriodResponse,
  TRAINING_PERIOD_CREATE_FAILED,
  TRAINING_PERIOD_CREATE_REQUEST,
  TRAINING_PERIOD_CREATE_SUCCESS,
  TRAINING_PERIOD_RESET,
  TrainingPeriodLoadRequestAction,
  TRAINING_PERIOD_LOAD_REQUEST,
  TRAINING_PERIOD_LOAD_SUCCESS,
  TrainingPeriodLoadSuccessAction,
  TrainingPeriodLoadErrorAction,
  TRAINING_PERIOD_LOAD_FAILED,
  TrainingPeriodUpdateRequestAction,
  TrainingPeriodUpdateSuccessAction,
  TrainingPeriodUpdateErrorAction,
  TRAINING_PERIOD_UPDATE_REQUEST,
  TRAINING_PERIOD_UPDATE_SUCCESS,
  TRAINING_PERIOD_UPDATE_FAILED,
  TrainingPeriodCompleteRequestAction,
  TRAINING_PERIOD_COMPLETE_REQUEST,
  TrainingPeriodCompleteSuccessAction,
  TRAINING_PERIOD_COMPLETE_SUCCESS,
  TrainingPeriodCompleteFailedAction,
  TRAINING_PERIOD_COMPLETE_FAILED,
  TrainingPeriodCompanyLoadRequest,
  TRAINING_PERIOD_COMPANY_LOAD_REQUEST,
  TrainingPeriodCompanyLoadSuccess,
  TRAINING_PERIOD_COMPANY_LOAD_SUCCESS,
  TrainingPeriodCompanyLoadFailed,
  TRAINING_PERIOD_COMPANY_LOAD_FAILED,
} from '../types';

export const trainingPeriodCreateRequest = (
  config: TrainingPeriodConfig,
): TrainingPeriodCreateRequestAction => ({
  type: TRAINING_PERIOD_CREATE_REQUEST,
  config,
});

export const trainingPeriodCreateSuccess = (
  trainingPeriod: TrainingPeriodResponse,
): TrainingPeriodCreateSuccessAction => ({
  type: TRAINING_PERIOD_CREATE_SUCCESS,
  trainingPeriod,
});

export const trainingPeriodCreateError = (
  errorMessage: string,
  originalError?: Error,
): TrainingPeriodCreateErrorAction =>
  showErrorMessage(TRAINING_PERIOD_CREATE_FAILED, errorMessage, originalError);

export const trainingPeriodReset = (): TrainingPeriodResetAction => ({
  type: TRAINING_PERIOD_RESET,
});

export const trainingPeriodLoadRequest = (
  trainingPeriodId: number,
): TrainingPeriodLoadRequestAction => ({
  type: TRAINING_PERIOD_LOAD_REQUEST,
  trainingPeriodId,
});

export const trainingPeriodLoadSuccess = (
  trainingPeriod: TrainingPeriodResponse,
): TrainingPeriodLoadSuccessAction => ({
  type: TRAINING_PERIOD_LOAD_SUCCESS,
  trainingPeriod,
});

export const trainingPeriodLoadError = (
  errorMessage: string,
  originalError?: Error,
): TrainingPeriodLoadErrorAction =>
  showErrorMessage(TRAINING_PERIOD_LOAD_FAILED, errorMessage, originalError);

export const trainingPeriodUpdateRequest = (
  trainingPeriodId: number,
  config: TrainingPeriodConfig,
): TrainingPeriodUpdateRequestAction => ({
  type: TRAINING_PERIOD_UPDATE_REQUEST,
  trainingPeriodId,
  config,
});

export const trainingPeriodUpdateSuccess = (
  trainingPeriod: TrainingPeriodResponse,
): TrainingPeriodUpdateSuccessAction => ({
  type: TRAINING_PERIOD_UPDATE_SUCCESS,
  trainingPeriod,
});

export const trainingPeriodUpdateError = (
  errorMessage: string,
  originalError?: Error,
): TrainingPeriodUpdateErrorAction =>
  showErrorMessage(TRAINING_PERIOD_UPDATE_FAILED, errorMessage, originalError);

export const trainingPeriodCompleteRequest = (
  trainingPeriodId: number,
): TrainingPeriodCompleteRequestAction => ({
  type: TRAINING_PERIOD_COMPLETE_REQUEST,
  trainingPeriodId,
});

export const trainingPeriodCompleteSuccess = (
  trainingPeriod: TrainingPeriodResponse,
): TrainingPeriodCompleteSuccessAction => ({
  type: TRAINING_PERIOD_COMPLETE_SUCCESS,
  trainingPeriod,
});

export const trainingPeriodCompleteError = (
  errorMessage: string,
  originalError?: Error,
): TrainingPeriodCompleteFailedAction =>
  showErrorMessage(
    TRAINING_PERIOD_COMPLETE_FAILED,
    errorMessage,
    originalError,
  );

export const trainingPeriodCompanyLoadRequest =
  (): TrainingPeriodCompanyLoadRequest => ({
    type: TRAINING_PERIOD_COMPANY_LOAD_REQUEST,
  });

export const trainingPeriodCompanyLoadSuccess = (
  trainingPeriod: TrainingPeriodResponse,
): TrainingPeriodCompanyLoadSuccess => ({
  type: TRAINING_PERIOD_COMPANY_LOAD_SUCCESS,
  trainingPeriod,
});

export const trainingPeriodCompanyLoadFailed = (
  errorMessage: string,
  originalError?: Error,
): TrainingPeriodCompanyLoadFailed => ({
  type: TRAINING_PERIOD_COMPANY_LOAD_FAILED,
  error: errorMessage,
});
