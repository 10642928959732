/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import {
  Question,
  Answer,
  QuestionTypes,
  AssigneeState,
  AssessmentRiskAnalysisState,
  AssessmentRiskAnalysisItem,
} from 'src/store/types';
import { WorkbenchQuestion, WorkbenchQuestionProps } from './WorkbenchQuestion';
import { InterviewQuestion } from './InterviewQuestion';
import { ParagraphField } from './ParagraphField';
import { NameField } from './NameField';
import { SectionField } from './SectionField';
import { CheckboxesField } from './CheckboxesField';
import { DropdownField } from './DropdownField';
import { RadioField } from './RadioField';
import { SingleLineField } from './SingleLineField';
import { WebsiteField } from './WebsiteField';
import { EmailField } from './EmailField';
import { PhoneField } from './PhoneField';
import { NumberField } from './NumberField';
import { DateField } from './DateField';
import { FileUploadField } from './FileUploadField';
import { AddressField } from './AddressField';
import { TipsHelp } from './TipsHelp/TipsHelp';
import { VideoHelp } from './VideoHelp';
import { YesNoField } from './YesNoField';
import { ContactField } from './ContactField';
import { questionToInputRulesMapping } from './InputRules';

export enum QuestionFactoryType {
  workbench = 'workbench',
  interview = 'interview',
}

const DraggableWorkbenchQuestion = styled(WorkbenchQuestion)`
  cursor: -webkit-grab;
  cursor: grab;
`;

const questionToComponentMapping: {
  [k in QuestionTypes]: FC<WorkbenchQuestionProps | any>;
} = {
  [QuestionTypes.INPUT]: SingleLineField,
  [QuestionTypes.PARAGRAPH]: ParagraphField,
  [QuestionTypes.NAME]: NameField,
  [QuestionTypes.DATE]: DateField,
  [QuestionTypes.SECTION]: SectionField,
  [QuestionTypes.CHECKBOX]: CheckboxesField,
  [QuestionTypes.SELECT]: DropdownField,
  [QuestionTypes.RADIO]: RadioField,
  [QuestionTypes.WEBSITE]: WebsiteField,
  [QuestionTypes.EMAIL]: EmailField,
  [QuestionTypes.PHONE]: PhoneField,
  [QuestionTypes.NUMBER]: NumberField,
  [QuestionTypes.FILE]: FileUploadField,
  [QuestionTypes.ADDRESS]: AddressField,
  [QuestionTypes.BASE]: SingleLineField,
  [QuestionTypes.TIPS]: TipsHelp,
  [QuestionTypes.VIDEO]: VideoHelp,
  [QuestionTypes.YES_NO]: YesNoField,
  [QuestionTypes.CONTACT_INFO]: ContactField,
};

const renderWorkbenchQuestion = (
  type = QuestionTypes.BASE,
): FC<WorkbenchQuestionProps> => {
  let Question = questionToComponentMapping[QuestionTypes.BASE];
  if (!questionToComponentMapping[type]) {
    // eslint-disable-next-line no-console
    console.error(`Type ${type} is not defined`);
  } else {
    Question = questionToComponentMapping[type];
  }
  return function Component({
    question,
    isLast,
    formId,
  }: WorkbenchQuestionProps) {
    const [value, setValue] = useState('');
    return (
      <DraggableWorkbenchQuestion
        question={question}
        isLast={isLast}
        formId={formId}
        className="draggable"
      >
        <Question
          value={value}
          onChange={setValue}
          question={question}
          updateAnswer={() => {}}
          deleteAnswer={() => {}}
        />
      </DraggableWorkbenchQuestion>
    );
  };
};

const renderInterviewQuestion = (type = QuestionTypes.BASE): FC<any> => {
  const Question = questionToComponentMapping[type];
  return function Component({
    question,
    updateAnswer,
    deleteAnswer,
    answer,
    suggestions,
    interviewId,
    assignee,
    form,
    assessmentRiskAnalysisItem,
  }: {
    question: Question;
    updateAnswer: (value: any) => void;
    deleteAnswer: (value: any) => void;
    answer: Answer;
    suggestions: { [k: string]: string[] };
    interviewId: number;
    assignee?: AssigneeState;
    form: FormInstance;
    assessmentRiskAnalysisItem: AssessmentRiskAnalysisItem;
  }) {
    return (
      <InterviewQuestion
        assignee={assignee}
        interviewId={interviewId}
        question={question}
        rules={questionToInputRulesMapping[type] || {}}
        assessmentRiskAnalysisItem={assessmentRiskAnalysisItem}
      >
        <Question
          question={question}
          updateAnswer={updateAnswer}
          deleteAnswer={deleteAnswer}
          answer={answer}
          suggestions={suggestions}
          factoryType={QuestionFactoryType.interview}
          form={form}
        />
      </InterviewQuestion>
    );
  };
};

export const InterviewQuestionFactory = (
  questionType: string,
  factoryType: QuestionFactoryType = QuestionFactoryType.workbench,
): FC<WorkbenchQuestionProps | any> => {
  if (factoryType === QuestionFactoryType.interview) {
    return renderInterviewQuestion(questionType as QuestionTypes);
  }
  return renderWorkbenchQuestion(questionType as QuestionTypes);
};
