import styled from 'styled-components';
import { Layout } from 'antd';
import { BasicProps, Content } from 'antd/lib/layout/layout';
import { remConvert } from 'src/theme/utils';
import { centeredFlex, device } from 'src/theme';

export const PageLayout: React.FC<BasicProps> = styled(Layout)`
  background-color: ${({ theme }): string => theme.colors.steelBlue.main};
  overflow: auto;
  min-height: 100vh;
`;

export const PageContent = styled(Content)`
  ${centeredFlex}
  flex-direction: column;
  justify-content: center;
  padding: ${remConvert(5)};

  @media ${device.xs} {
    padding: ${remConvert(48)};
  }
`;

export const PageContainer = styled.div`
  background: ${({ theme }): string => theme.colors.white.main};
  box-shadow: ${remConvert(0)} ${remConvert(4)} ${remConvert(4)}
    rgba(0, 0, 0, 0.25);
  border-radius: ${remConvert(16)};
  padding: ${remConvert(10)} ${remConvert(22)};
  height: auto;
  width: 100%;

  @media ${device.md} {
    padding: ${remConvert(24)} ${remConvert(64)} ${remConvert(24)}
      ${remConvert(64)};
  }

  @media ${device.lg} {
    width: ${remConvert(900)};
  }
`;

export const LogoContainer = styled.div`
  ${centeredFlex}
  flex-direction: row;
  justify-content: center;
  border-bottom: ${remConvert(1)} solid rgba(0, 0, 0, 0.1);
  padding: ${remConvert(10)};

  img {
    width: ${remConvert(250)};

    @media ${device.xs} {
      width: 100%;
    }
  }
`;

export const TrainingTypeTitle = styled.div`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 10px;
  padding-top: 7px;
  border-bottom: 1px solid lightgray;
`;

export const QuizTitle = styled.div`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2em;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 26px;
  margin-bottom: 26px;
`;
