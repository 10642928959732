/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Question } from 'src/store/types';
import { FieldSettingsForm } from './FormFieldSettings';

export * from './FormFieldSettings';
export * from './LinkButton';
export * from './ConditionalRuleset';
export * from './RulesSettings';

export const questionToSettingsMapping = (
  question: Question,
): FieldSettingsForm => {
  const basicSettings = { ...question } as any;
  switch (question.type) {
    default: {
      if (question.conditions && question.conditions.length > 0) {
        basicSettings.hasConditions = question.conditions.length;
        basicSettings.conditions = question.conditions.map((c) => {
          return {
            ...c,
            subject: c.subject?.id,
            conditionChoice: c.choice?.id,
            hasChoice: !!c.choice,
          };
        });
        basicSettings.conditionAction = question.conditions[0].effect
          ? question.conditions[0].effect
          : 'SHOW';
        basicSettings.conditionConnector =
          question.conditions[0].connector === 'AND' ? 'all' : 'any';
      } else {
        basicSettings.hasConditions = false;
        basicSettings.conditionAction = 'SHOW';
        basicSettings.conditions = [];
      }
      if (question.riskConditions && question.riskConditions.length > 0) {
        basicSettings.riskConditions = question.riskConditions;
      } else {
        basicSettings.riskConditions = [];
      }
    }
  }
  return basicSettings;
};
