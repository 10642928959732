import React, { FC } from 'react';
import { Table, Empty } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Grid as GridBase, GridTag } from 'src/components/Grid';
import { ProgressBar } from 'src/components/ProgressBar';
import {
  AssessmentStatus,
  AssessmentListingIndexedInterface,
  CompanyState,
  CompanyType,
} from 'src/store/types';
import { EyeOutlined } from 'src/theme/icons';
import { dataMapper, dataMapperCompanies } from './dataMapper';
import { CompanyListingTableSettings } from './CompanyListingTableSettings';

export interface CompanyListingTableProps {
  companies: AssessmentListingIndexedInterface;
  showSetupAssessmentPanel: (companyId: number) => void;
  showEditCompanyPanel: (company: CompanyState) => void;
  showNewUserPanel: (companyId: number) => void;
}

export interface AssessmentTableRowInterface {
  id: number;
  companyId: number;
  name: string;
  progress: {
    total: number;
    completed: number;
  };
  assessmentStartDate: string;
  assessmentStatus: AssessmentStatus;
  assessmentType: string;
}

export interface CompaniesTableRowInterface {
  id: number;
  name: string;
  typeOfTraining: string;
  companyType: CompanyType;
}

const ProgressContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TagContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Options = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  .companyEyeIcon {
    font-size: 20px;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid ${({ theme }) => theme.colors.grayscale.middleGray};
  }

  .assessmentEyeIcon {
    font-size: 20px;
  }
`;

const Grid = styled(GridBase)`
  .ant-table table .ant-table-tbody tr.ant-table-row td {
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
    &.company {
      color: black;
      font-weight: bold;
    }
    &:last-child {
      justify-content: end;
    }
  }

  &&&&&&&&& .ant-table-expanded-row {
    & > td {
      padding: 0;
      background-color: transparent;
    }
    .ant-table {
      margin-left: 10px;
      table {
        .ant-table-thead th {
          padding-bottom: 1px;
          padding-top: 1px;
        }
        .ant-table-tbody tr.ant-table-row {
          td {
            padding: 16px;
            background-color: #fff;

            &:last-child {
              justify-content: flex-end;
              margin-right: 16px;
            }
            &:first-child {
              width: 30px;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 40%;
            }
          }
        }
      }
    }
  }
`;

export const CompanyListingTable: FC<CompanyListingTableProps> = ({
  companies: rawCompanies,
  showSetupAssessmentPanel,
  showEditCompanyPanel,
  showNewUserPanel,
}) => {
  const nestedData = (company: CompaniesTableRowInterface) => {
    const columns = [
      {
        title: 'Assessment Type',
        dataIndex: 'assessmentType',
        key: 'assessmentType',
        width: '15%',
      },
      {
        title: 'Start date',
        dataIndex: 'assessmentStartDate',
        key: 'assessmentStartDate',
        width: '10%',
      },
      {
        title: 'Status',
        width: '15%',
        render: (_: any, record: AssessmentTableRowInterface) => (
          <TagContainer>
            {record.assessmentStatus === AssessmentStatus.COMPLETED ? (
              <GridTag type="inactive">Completed</GridTag>
            ) : (
              <GridTag type="active">{record.assessmentStatus}</GridTag>
            )}
          </TagContainer>
        ),
      },
      {
        title: 'Progress',
        width: '30%',
        render: (_: any, record: AssessmentTableRowInterface) => (
          <ProgressContainer>
            <ProgressBar
              completed={record.progress.completed}
              total={record.progress.total}
              altered
              short={false}
            />
          </ProgressContainer>
        ),
      },
      {
        width: '30%',
        render: (_: any, record: AssessmentTableRowInterface) => (
          <Options>
            <Link
              to={`/companies/${record.companyId}/assessments/${record.id}`}
              className="assessmentEyeIcon"
              title="View assessment"
            >
              <EyeOutlined />
            </Link>
          </Options>
        ),
      },
    ];

    const data = dataMapper(rawCompanies, company.name);
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
      />
    );
  };

  return (
    <Grid
      dataSource={dataMapperCompanies(rawCompanies)}
      pagination={false}
      rowKey="id"
      expandable={{ expandedRowRender: nestedData }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
      columns={[
        {
          title: 'Company',
          dataIndex: 'name',
          className: 'company',
        },
        {
          title: 'Type of Training',
          dataIndex: 'typeOfTraining',
        },
        {
          title: 'Company Type',
          dataIndex: 'companyType',
        },
        {
          width: '30%',
          render: (_: any, record: CompaniesTableRowInterface) => (
            <Options>
              <Link
                to={`/companies/${record.id}`}
                className="companyEyeIcon"
                title="View company profile"
              >
                <EyeOutlined />
              </Link>
              <CompanyListingTableSettings
                companyId={record.id}
                showSetupAssessmentPanel={showSetupAssessmentPanel}
                showEditCompanyPanel={showEditCompanyPanel}
                showNewUserPanel={showNewUserPanel}
              />
            </Options>
          ),
        },
      ]}
    />
  );
};
