import React, { FC, useEffect, useMemo, useState } from 'react';
import { Progress } from 'src/components/Progress';
import { QuestionChoice, QuizQuestion } from 'src/store/types';
import * as M from 'src/theme/media';
import * as S from './Styles';

interface QuizWizardProps {
  quizQuestions: QuizQuestion[];
  sendSubmissionResponse: (
    questionId: number,
    choiceId: number,
    isLastQuestion: boolean,
  ) => void;
}

export const QuizWizard: FC<QuizWizardProps> = ({
  quizQuestions,
  sendSubmissionResponse,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<
    QuizQuestion | undefined
  >();
  const [selectedChoiceId, setSelectedChoiceId] = useState<number | undefined>(
    undefined,
  );
  const [selectedChoicesMap, setSelectedChoicesMap] = useState<
    Map<number, number | undefined>
  >(() => new Map());
  const isLastQuestion = useMemo(
    () => quizQuestions.length === currentQuestionIndex + 1,
    [currentQuestionIndex],
  );
  const isFirstQuestion = useMemo(
    () => currentQuestionIndex === 0,
    [currentQuestionIndex],
  );

  useEffect(() => {
    setCurrentQuestion(quizQuestions[currentQuestionIndex]);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (currentQuestion && selectedChoicesMap.has(currentQuestion.id)) {
      setSelectedChoiceId(selectedChoicesMap.get(currentQuestion.id));
    }
  }, [currentQuestion]);

  const quizProgress = useMemo(
    () =>
      ((currentQuestionIndex + (selectedChoiceId ? 1 : 0)) * 100) /
      quizQuestions.length,
    [currentQuestionIndex, selectedChoiceId],
  );

  const handleOnClickChoice = (choice: QuestionChoice): void => {
    if (currentQuestion) {
      setSelectedChoiceId(choice.id);
      setSelectedChoicesMap((prev) => prev.set(currentQuestion.id, choice.id));
    }
  };

  const handleOnClickBack = (): void => {
    if (!isFirstQuestion) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleOnClickNext = (): void => {
    if (selectedChoiceId) {
      if (currentQuestion) {
        sendSubmissionResponse(
          currentQuestion.id,
          selectedChoiceId,
          isLastQuestion,
        );
      }

      if (!isLastQuestion) {
        setSelectedChoiceId(undefined);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };

  return (
    <>
      <div>
        <div>Quiz Progress</div>
        <S.ProgressWrapper>
          <Progress percent={Math.round(quizProgress)} />{' '}
        </S.ProgressWrapper>
      </div>
      {currentQuestion && (
        <div>
          <S.QuizQuestionText>
            {currentQuestion.questionText}
          </S.QuizQuestionText>
          <S.QuizQuestionChoices>
            {currentQuestion.choices.map((choice: QuestionChoice) => {
              return (
                <li key={`${currentQuestion.id}-${choice.id}`}>
                  {selectedChoicesMap &&
                    selectedChoicesMap.get(currentQuestion.id) !==
                      choice.id && (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOnClickChoice(choice)}
                        src={M.THTrainingUnSelectedAnswer}
                      />
                    )}
                  {selectedChoicesMap &&
                    selectedChoicesMap.get(currentQuestion.id) ===
                      choice.id && (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOnClickChoice(choice)}
                        src={M.THTrainingSelectedAnswer}
                      />
                    )}
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOnClickChoice(choice)}
                  >
                    {choice.choiceText}
                  </div>
                </li>
              );
            })}
          </S.QuizQuestionChoices>
        </div>
      )}
      <S.QuizWizardControls>
        <img src={M.THIconPreviousQuestion} onClick={handleOnClickBack} />
        <div>
          Question {currentQuestionIndex + 1} of {quizQuestions.length}
        </div>
        <img src={M.THIconNextQuestion} onClick={handleOnClickNext} />
      </S.QuizWizardControls>
    </>
  );
};
