/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { FC, PropsWithChildren } from 'react';

export const DomainRedirect: FC<PropsWithChildren> = (props) => {
  const { location } = window;

  if (location.hostname !== 'localhost') {
    // Redirect to production canonical url
    if (location.hostname === 'th-front-prod.herokuapp.com') {
      window.location.assign('https://app.totalhipaa.com:443/');
    } else if (location.port === '80' || location.protocol === 'http:') {
      window.location.assign(`https://${location.hostname}:443`);
    }
  }

  return <>{props.children}</>;
};
