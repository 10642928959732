import {
  Answer,
  AnswerList,
  InterviewActionsTypes,
  INTERVIEW_MARK_ANSWER_AS_DELETED,
  INTERVIEW_SAVE_ANSWER,
  INTERVIEW_LOAD_ANSWERS_SUCCESS,
} from 'src/store/types';
import { generateId } from 'src/shared/utils';

export const initialAnswerState: Answer = {
  id: undefined,
  internalId: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  value: '',
  updateReason: '',
  required: false,
  choiceIds: [],
  questionId: 0,
  interviewId: 0,
  userId: 0,
  fileIds: [],
};

export const answerListReducer = (
  state: AnswerList = [],
  action: InterviewActionsTypes,
): AnswerList => {
  switch (action.type) {
    case INTERVIEW_SAVE_ANSWER: {
      const { interviewId, userId, questionId, answerData } = action;
      const { id, internalId, value, fileIds, choiceIds, question } =
        answerData;

      if (id || internalId) {
        return state.map((a: Answer) => {
          if (id && id === a.id) return { ...a, value, fileIds, choiceIds };
          if (internalId && internalId === a.internalId)
            return { ...a, value, fileIds, choiceIds };
          return a;
        });
      }

      const newAnswer = {
        ...initialAnswerState,
        interviewId,
        userId,
        questionId: questionId ?? question?.internalId,
        value: value || undefined,
        fileIds: fileIds || [],
        choiceIds: choiceIds || [],
        internalId: generateId(),
      } as Answer;
      return [...state, newAnswer];
    }
    case INTERVIEW_LOAD_ANSWERS_SUCCESS: {
      return action.answers.map((a) => {
        const answer = {
          ...a,
          questionId: a.question.id as number,
          choiceIds: a?.choices?.map((c) => c.id),
        } as Answer;
        delete answer.question;
        delete answer.choices;
        return answer;
      });
    }
    case INTERVIEW_MARK_ANSWER_AS_DELETED: {
      if (action.answerId)
        return state.filter((a) => (a.id ? action.answerId !== a.id : true));
      if (action.answerInternalId)
        return state.filter((a) => action.answerInternalId !== a.internalId);
      return [...state];
    }
    default:
      return state;
  }
};
