/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
  Loader,
  RootState,
  TrainingPeriodListingResponse,
  TrainingPeriodStatus,
} from 'src/store/types';

export const getTrainingPeriodListingLoader = (state: RootState): Loader =>
  state.trainingPeriodListing.loader;

export const getTrainingPeriodListingResponse = (
  state: RootState,
): TrainingPeriodListingResponse =>
  state.trainingPeriodListing.trainingPeriodListingResponse;

export const hasAnOpenTrainingPeriod = (state: RootState): boolean => {
  const trainingPeriods = state.trainingPeriodListing
    .trainingPeriodListingResponse as TrainingPeriodListingResponse;
  const filteredTrainingPeriods =
    trainingPeriods.filter((tpl) => tpl.status === TrainingPeriodStatus.OPEN) ||
    [];
  return filteredTrainingPeriods.length > 0;
};
