import React, { FC } from 'react';
import { HelpSection } from './HelpSection';

interface TemplateFunctionsHelpSectionProps {
  templateFunctionsHelpOpen: boolean;
  setTemplateFunctionsHelpOpen: (value: boolean) => void;
}

export const TemplateFunctionsHelpSection: FC<
  TemplateFunctionsHelpSectionProps
> = ({ templateFunctionsHelpOpen, setTemplateFunctionsHelpOpen }) => (
  <HelpSection
    isOpen={templateFunctionsHelpOpen}
    setIsOpen={setTemplateFunctionsHelpOpen}
    title={'Template Functions'}
  >
    <h4>Overview</h4>
    <p>
      A <em>function</em> has the following syntax:{' '}
      <code>$&lt;function_name&gt;(&lt;input&gt;)</code>, where{' '}
      <code>&lt;function_name&gt;</code> is replaced by the name of the function
      you want to invoke, and <code>&lt;input&gt;</code> is replaced by the text
      that you want to apply the function to, the <code>$</code> indicates that
      a function should be invoked.
    </p>
    <ul>
      <li>
        <code>a_an(human), a_an(animal)</code>: a human, an animal
      </li>
      <li>
        <code>capitalize(nacho)</code>: Nacho
      </li>
      <li>
        <code>possessive(Nacho), possessive(employees)</code>: Nacho's,
        employees'
      </li>
      <li>
        <code>the_noun(favorite programmer)</code>: The favorite programmer
      </li>
    </ul>
    <h4>Nested Functions</h4>
    <p>
      You can nest functions by passing another function as{' '}
      <code>&lt;input&gt;</code>. Remember to include <code>$</code> before each
      function name:{' '}
      <code>
        $&lt;outter_function&gt;($&lt;inner_function&gt;(&lt;input&gt;))
      </code>
      .
    </p>
    <ul>
      <li>
        <code>possessive(the_noun(company))</code>: the company's
      </li>
    </ul>
    <h4>Not Implemented Functions</h4>
    <p>
      In case you try to use a function that hasn't been implemented{' '}
      <em>yet</em>, you will see a warning in the generated document draft, for
      example:
    </p>
    <ul>
      <li>
        <code>$&lt;not_implemented_function_name&gt;(&lt;input&gt;)</code> will
        yield <code>[FUNCTION NOT IMPLEMENTED]</code>
      </li>
    </ul>
  </HelpSection>
);
