import React, { FC } from 'react';
import { Drawer as DrawerAntD } from 'antd';
import { Button } from 'src/components/Button';
import styled from 'styled-components';

interface SlidingPanelProps {
  title: string;
  children: React.ReactNode;
  visible: boolean;
  onSave?: () => void;
  onClose: () => void;
  saveLabel?: string;
  loading?: boolean;
  enabled?: boolean;
}

const Drawer = styled(DrawerAntD)`
  .ant-drawer-header {
    padding: 30px 24px;
    border-bottom-width: 0;

    .ant-drawer-title {
      color: ${({ theme }) => theme.colors.deepBlue.main};
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
    }

    .ant-drawer-close {
      color: ${({ theme }) => theme.colors.lightBlue.main};
      font-size: 22px;
      padding-top: 34px;
    }
  }

  .ant-drawer-body {
    background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  }

  .ant-drawer-footer {
    padding: 22px;
  }
`;
const WideButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  padding: 9px 24px;

  &:focus {
    font-size: 14px;
    padding: 9px 24px;
  }
`;
export const SlidingPanel: FC<SlidingPanelProps> = ({
  title,
  children,
  visible,
  onClose,
  saveLabel,
  onSave,
  loading,
  enabled,
}) => {
  return (
    <Drawer
      title={title}
      placement="right"
      open={visible}
      width={360}
      closable
      maskClosable
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
      onClose={onClose}
      footer={
        saveLabel ? (
          <WideButton
            disabled={enabled !== undefined ? !enabled : false}
            title={saveLabel}
            loading={loading}
            onClick={onSave}
          />
        ) : null
      }
    >
      {children}
    </Drawer>
  );
};
