import { combineReducers } from 'redux';
import {
  AssessmentRiskAnalysisActionTypes,
  AssessmentRiskAnalysisState,
  ASSESSMENT_LOAD_RISK_ANALYSIS_ERROR,
  ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST,
  ASSESSMENT_LOAD_RISK_ANALYSIS_SUCCESS,
  Loader,
  SelectedAssessment,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const assessmentRiskAnalysisInitialState: AssessmentRiskAnalysisState = {
  forms: {},
  answers: {},
  assessmentTotalRisks: 0,
};

export const assessmentRiskAnalysisReducer = (
  state = assessmentRiskAnalysisInitialState,
  action: AssessmentRiskAnalysisActionTypes,
): AssessmentRiskAnalysisState => {
  switch (action.type) {
    case ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST: {
      return state;
    }
    case ASSESSMENT_LOAD_RISK_ANALYSIS_SUCCESS: {
      return { ...action.assessmentRiskAnalysis };
    }
    case ASSESSMENT_LOAD_RISK_ANALYSIS_ERROR: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export const loaderReducer = (
  state: Loader = loaderInitialState,
  action: AssessmentRiskAnalysisActionTypes,
): Loader => {
  switch (action.type) {
    case ASSESSMENT_LOAD_RISK_ANALYSIS_SUCCESS: {
      return {
        ...state,
        completed: true,
        success: true,
        loading: false,
      };
    }
    case ASSESSMENT_LOAD_RISK_ANALYSIS_REQUEST: {
      return {
        ...state,
        loading: true,
        completed: false,
      };
    }
    case ASSESSMENT_LOAD_RISK_ANALYSIS_ERROR: {
      return {
        ...state,
        loading: false,
        completed: true,
        error: state.error,
      };
    }
    default: {
      return state;
    }
  }
};

export const combinedAssessmentReducer = combineReducers<SelectedAssessment>({
  risksAnalysis: assessmentRiskAnalysisReducer,
  loader: loaderReducer,
});
