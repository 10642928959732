import styled from 'styled-components';
import { THCheckList } from 'src/theme/media';
import { centeredJustifiedContentFlex, device } from 'src/theme';
import { remConvert } from 'src/theme/utils';

export const PageContainer = styled.div`
  margin-top: ${remConvert(32)};
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: left 370px;

  @media ${device.lg} {
    background-image: url(${THCheckList}) !important;
  }
`;

export const PageTitle = styled.h1`
  width: ${remConvert(370)};
`;

export const PageUsersContainer = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.div`
  ${centeredJustifiedContentFlex}
  position: relative;

  @media ${device.lg} {
    justify-content: end;
  }

  button {
    width: ${remConvert(170)};
    height: ${remConvert(40)};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const DoThisLaterLink = styled.a`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  background: linear-gradient(
    180deg,
    #417bae 0%,
    rgba(69, 134, 191, 0.94) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: ${remConvert(37.7)};
`;

export const ProgressMeterWrapper = styled.div`
  ${centeredJustifiedContentFlex}
  position: absolute;
  width: 100%;
  bottom: ${remConvert(21)};

  @media ${device.lg} {
    bottom: ${remConvert(10)};
  }
`;

export const PageFooterContainer = styled.div`
  position: relative;
`;
