import {
  FORMS_LOAD_REQUEST,
  FORMS_LOAD_SUCCESS,
  FORMS_LOAD_ERROR,
  FORMS_REORDER_REQUEST,
  FORMS_REORDER_SUCCESS,
  FORMS_REORDER_FAILED,
  FormState,
  FormsLoadSuccessAction,
  FormsLoadRequestAction,
  FormsLoadErrorAction,
  FormsReOrderRequestAction,
  FormsReOrderRequestSuccess,
  FormsReOrderRequestFailed,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const formsLoadRequest = (
  withAssessmentType = false,
): FormsLoadRequestAction => ({
  type: FORMS_LOAD_REQUEST,
  withAssessmentType,
});

export const formsLoadSuccess = (
  forms: FormState[],
): FormsLoadSuccessAction => ({
  type: FORMS_LOAD_SUCCESS,
  forms,
});

export const formsLoadError = (
  errorMessage: string,
  originalError: Error,
): FormsLoadErrorAction =>
  showErrorMessage(FORMS_LOAD_ERROR, errorMessage, originalError);

export const formsReOrderRequest = (
  forms: FormState[],
): FormsReOrderRequestAction => ({
  type: FORMS_REORDER_REQUEST,
  forms,
});

export const formsReOrderSuccess = (): FormsReOrderRequestSuccess => ({
  type: FORMS_REORDER_SUCCESS,
});

export const formsReOrderFailed = (
  errorMessage: string,
  originalError: Error,
): FormsReOrderRequestFailed =>
  showErrorMessage(FORMS_REORDER_FAILED, errorMessage, originalError);
