import styled from 'styled-components';
import { THIconInfo } from 'src/theme/media';
import { remConvert } from 'src/theme/utils';

export const TooltipContainer = styled.div`
  .ant-tooltip-content {
    background-color: ${({ theme }): string => theme.colors.white.main};
    border-radius: ${remConvert(8)};
    color: ${({ theme }): string => theme.colors.black.main};
    padding: ${remConvert(0)} ${remConvert(4)};
    max-width: ${remConvert(500)};
    border: ${remConvert(1)} solid ${({ theme }) => theme.colors.alto.main};
    box-shadow: none;

    .ant-tooltip-inner {
      background-color: ${({ theme }): string => theme.colors.white.main};
      display: flex;
      align-items: flex-start;
      box-shadow: none;
    }

    .ant-tooltip-inner:after {
      content: '';
      background: url(${THIconInfo}) no-repeat top right;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: 100%;
      height: ${remConvert(20)};
    }

    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background-color: ${({ theme }) => theme.colors.grayscale.darkGray};
        border: ${remConvert(1)} solid ${({ theme }) => theme.colors.alto.main};
      }
    }
  }
`;
