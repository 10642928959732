import React, { FC } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { UserRole } from 'src/store/types';
import * as M from '../../theme/media';

export interface ProgressMeterProps {
  hasOnboarding: boolean;
  step: 1 | 2 | 3 | 4 | 5 | 6;
}

export const ProgressMeter: FC<ProgressMeterProps> = ({
  hasOnboarding,
  step,
}) => {
  const { user } = useAuth();
  const isTrainee = user.role === UserRole.trainee;
  const isOfficer = user.role === UserRole.officer;

  return (
    <>
      {step === 1 && isTrainee && hasOnboarding && (
        <img height={32} width={237} src={M.THSteps1Of5} />
      )}
      {step === 2 && isTrainee && hasOnboarding && (
        <img height={32} width={237} src={M.THSteps2Of5} />
      )}
      {step === 3 && isTrainee && hasOnboarding && (
        <img height={32} width={237} src={M.THSteps3Of5} />
      )}
      {step === 4 && isTrainee && hasOnboarding && (
        <img height={32} width={237} src={M.THSteps4Of5} />
      )}
      {step === 5 && isTrainee && hasOnboarding && (
        <img height={32} width={237} src={M.THSteps5Of5} />
      )}

      {step === 1 && isOfficer && hasOnboarding && (
        <img height={33} width={292} src={M.THSteps1Of6} />
      )}
      {step === 2 && isOfficer && hasOnboarding && (
        <img height={33} width={292} src={M.THSteps2Of6} />
      )}
      {step === 3 && isOfficer && hasOnboarding && (
        <img height={33} width={292} src={M.THSteps3Of6} />
      )}
      {step === 4 && isOfficer && hasOnboarding && (
        <img height={33} width={292} src={M.THSteps4Of6} />
      )}
      {step === 5 && isOfficer && hasOnboarding && (
        <img height={33} width={292} src={M.THSteps5Of6} />
      )}
      {step === 6 && isOfficer && hasOnboarding && (
        <img height={33} width={292} src={M.THSteps6Of6} />
      )}
    </>
  );
};
