/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Loader, RootState, TrainingPeriodResponse } from 'src/store/types';

export const getTrainingPeriodLoader = (state: RootState): Loader =>
  state.trainingPeriod.loader;
export const getCurrentTrainingPeriod = (
  state: RootState,
): TrainingPeriodResponse => state.trainingPeriod.trainingPeriod;
