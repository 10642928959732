import styled, { css } from 'styled-components';
import {
  baseOpenSans,
  centeredFlex,
  centeredJustifiedContentFlex,
  device,
} from 'src/theme';
import { remConvert } from 'src/theme/utils';

export const TrainingDates = styled.div`
  text-align: center;
  margin-top: ${remConvert(24)};
  margin-bottom: ${remConvert(52)};

  display: flex;
  flex-direction: column;

  @media ${device.md} {
    ${centeredJustifiedContentFlex}
    flex-direction: row;
    align-items: baseline;
  }

  span:nth-of-type(2) {
    ${baseOpenSans}
    font-weight: 600;
    font-size: 22px;
    line-height: ${remConvert(30)};
    color: #337d98;
  }
`;

export const ButtonWrapper = styled.div`
  ${centeredFlex}
  justify-content: center;
`;

export const FormWrapper = styled.div`
  width: ${remConvert(400)};
  margin-top: ${remConvert(20)};

  > div:first-of-type {
    margin-bottom: ${remConvert(26)};
  }

  .ant-select-selector {
    .ant-select-selection-item,
    .ant-select-selection-search-input,
    .ant-select-selection-placeholder {
      padding-top: 5px !important;
    }
  }
`;

export const FormContainer = styled.div`
  ${centeredJustifiedContentFlex}
`;

const baseFont = css`
  ${baseOpenSans}
  font-weight: 700;
  font-size: 14px;
  line-height: ${remConvert(19)};
  color: rgba(28, 36, 40, 0.75);
`;

export const DropdownLabel = styled.div`
  ${baseFont}
  margin-bottom: ${remConvert(8)};
`;

export const Label = styled.span`
  ${baseFont}
  margin-right: ${remConvert(8)};
`;
