import React, { FC } from 'react';
import { Divider } from 'antd';
import styled from 'styled-components';
import { HelpSection } from './HelpSection';

interface ConditionalsHelpSectionProps {
  conditionalsHelpOpen: boolean;
  setConditionalsHelpOpen: (value: boolean) => void;
}

const HelpSectionItem = styled.div`
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-family: monospace;
`;

const InlineHelpSectionItem = styled(HelpSectionItem)`
  display: inline-block;
`;

export const ConditionalsHelpSection: FC<ConditionalsHelpSectionProps> = ({
  conditionalsHelpOpen,
  setConditionalsHelpOpen,
}) => (
  <HelpSection
    isOpen={conditionalsHelpOpen}
    setIsOpen={setConditionalsHelpOpen}
    title={'Conditional Logic'}
  >
    <InlineHelpSectionItem>
      [if condition='']content[else]alternative content[/if]
    </InlineHelpSectionItem>
    <h4>How it works</h4>
    <p>
      Conditions:&nbsp;
      <b>'argument1</b>&nbsp;+&nbsp;
      <b>operator</b>&nbsp;+&nbsp;
      <b>argument2'</b>
    </p>
    <p>
      Operators:&nbsp;
      <b>
        {'='}, {'!='}, {'<'}, {'<='}, {'>'}, {'>='}, {'$='}
      </b>
    </p>
    <p>
      Connectors:&nbsp;
      <b>
        {'&&'} (and), {'||'} (or)
      </b>
    </p>
    <Divider />
    <h4>Basic usage</h4>
    <HelpSectionItem>
      Content will be displayed if condition asserts to be true, you can nest
      more conditions as content and use question tokens and variables as
      arguments of the condition. When using variables or questions there's no
      need to use the square brackets on those tokens, see example.
    </HelpSectionItem>
    <h4>Example</h4>
    <HelpSectionItem>
      [if condition='q#1{'>'}=10']You have 10 or more employees[else]You have
      less than 10 employees[/if]
    </HelpSectionItem>
    <HelpSectionItem>
      [if condition='v#3=Yes']The content of the variable with id #3 is
      effectively Yes[/if]
    </HelpSectionItem>
    <Divider />
    <h4>Combining conditions</h4>
    <HelpSectionItem>
      You can use connectors to create more complex conditions.
    </HelpSectionItem>
    <h4>Example</h4>
    <HelpSectionItem>
      [if condition='v#3=Yes && q#1=Yes']The content of both the variable with
      id #3 <b>and</b> the question with id #1 are effectively Yes[/if]
    </HelpSectionItem>
  </HelpSection>
);
