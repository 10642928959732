/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  RootState,
  Loader,
  FormState,
  SelectedFormState,
  Question,
  QuestionList,
  QuestionListingIndexedType,
} from '../types';

export const getFormLoader = (state: RootState): Loader =>
  state.selectedForm.loader;

export const getFormById = (
  state: RootState,
  id: string,
): FormState | undefined => {
  return state.formListing.forms[id];
};

export const getSelectedForm = (state: RootState): SelectedFormState =>
  state.selectedForm;

export const getSelectedQuestion = (state: RootState): Question | undefined =>
  state.selectedForm.form.selectedQuestion;

export const getSelectedFormQuestions = (state: RootState): QuestionList =>
  state.selectedForm.questions;

export const getIndexedSelectedFormQuestionsItem = createSelector(
  (state: RootState) => getSelectedFormQuestions(state),
  (questionList): QuestionListingIndexedType =>
    questionList.reduce(
      (tempQuestions: QuestionListingIndexedType, question: Question) => ({
        ...tempQuestions,
        [Number(question.id)]: question,
      }),
      {},
    ),
);
