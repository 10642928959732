import React, { FC } from 'react';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { Menu as MenuAntD, Dropdown } from 'antd';
import { useDispatch } from 'react-redux';
import { SettingOutlined } from 'src/theme/icons';
import { api } from 'src/api/services/ThApi';

export interface AssessmentFormListingTableSettingsProps {
  formId: number;
  interviewId: number;
}

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-end;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

const Menu = styled(MenuAntD)`
  border-radius: 4px;
  margin-top: -35px;
  margin-left: 12px;
  width: 180px;

  .ant-dropdown-menu-item:hover {
    &.title {
      background-color: white;
      cursor: default;
    }
  }

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightBlue.main}`};

    > span {
      font-weight: 600;
    }

    .title-icon {
      color: ${({ theme }) => theme.colors.lightBlue.main};
      font-size: 20px;
    }
  }

  .title-divider {
    color: red;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0px 4px;
    width: 172px;
  }

  span {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }
`;

const menu = (formId: number, interviewId: number, dispatch: Dispatch) => (
  <Menu>
    <Menu.Item key="0" onClick={async () => api.getInterviewCsv(interviewId)}>
      <span>Download CSV</span>
    </Menu.Item>
  </Menu>
);

export const AssessmentFormListingTableSettings: FC<
  AssessmentFormListingTableSettingsProps
> = ({ formId, interviewId }) => {
  const dispatch = useDispatch();

  return (
    <Dropdown overlay={menu(formId, interviewId, dispatch)} trigger={['click']}>
      <DropdownIcon>
        <SettingOutlined />
      </DropdownIcon>
    </Dropdown>
  );
};
