import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/Button';
import { remConvert } from 'src/theme/utils';

export interface SidePanelProps {
  className?: string;
  children: ReactNode;
  fixed?: boolean;
  onConfirm?: () => void;
  confirmLabel?: string;
  confirmEnabled: boolean;
  hideFooter?: boolean;
}

const SidePanelContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  width: ${({ theme }) => `${theme.sidebar.width}px`};
  border-radius: 10px;

  padding: 0;
  display: flex;
  flex-direction: column;

  &.fixed {
    position: fixed;
    top: ${remConvert(195)};
    bottom: ${remConvert(30)};
    right: ${remConvert(66)};
  }

  .content {
    padding: ${remConvert(24)};
    flex: 1 0 auto;
    overflow-y: scroll;
    height: calc(100vh - ${({ theme }) => `${theme.sidebar.width}px`});
  }
`;

const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white.main};
  padding: ${remConvert(24)};
  box-shadow: 0px -2px 10px rgba(0, 35, 11, 0.1);
  border-radius: 0 0 10px 10px;
`;

const WideButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  padding: ${remConvert(9)} ${remConvert(24)};

  &:focus {
    font-size: 14px;
    padding: ${remConvert(9)} ${remConvert(24)};
  }
`;

export const SidePanel: FC<SidePanelProps> = ({
  className = '',
  children,
  fixed = false,
  onConfirm,
  confirmEnabled,
  confirmLabel = 'Save',
  hideFooter = false,
}) => {
  return (
    <SidePanelContainer className={fixed ? `${className} fixed` : className}>
      <div className="content">{children}</div>
      {!hideFooter && (
        <FooterContainer>
          <WideButton
            title={confirmLabel}
            onClick={onConfirm}
            disabled={!confirmEnabled}
          />
        </FooterContainer>
      )}
    </SidePanelContainer>
  );
};
