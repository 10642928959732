import { call, takeEvery, put } from 'redux-saga/effects';
import {
  COMPANY_LOAD_REQUEST,
  CompanyLoadRequestAction,
  INTERVIEW_SAVE_SUCCESS,
  COMPANY_SAVE_REQUEST,
  CompanySaveRequestAction,
  COMPANY_UPDATE_REQUEST,
  CompanyUpdateRequestAction,
  CompanyFromResponse,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadCompany = function* async({
  companyId,
  options,
}: CompanyLoadRequestAction): Generator {
  try {
    const companyData = yield call(api.getCompany, companyId, options);
    yield put(actions.companyLoadSuccess(companyData as CompanyFromResponse));
  } catch (err) {
    yield put(actions.companyLoadError('Failed to load company', err as Error));
  }
};

export const saveCompany = function* async({
  companyInput,
}: CompanySaveRequestAction): Generator {
  try {
    const company = yield call(api.saveCompany, companyInput);
    yield put(actions.companySaveSuccess(company as CompanyFromResponse));
    yield put(actions.addCompanyToList(company as CompanyFromResponse));
  } catch (err) {
    yield put(actions.companySaveError('Failed to save company', err as Error));
  }
};

export const updateCompany = function* async({
  companyId,
  companyInput,
}: CompanyUpdateRequestAction): Generator {
  try {
    const updatedCompany = yield call(
      api.updateCompany,
      companyId,
      companyInput,
    );
    yield put(
      actions.companyUpdateSuccess(updatedCompany as CompanyFromResponse),
    );
    yield put(actions.addCompanyToList(updatedCompany as CompanyFromResponse));
  } catch (err) {
    const errorMessage =
      (err as unknown as any).response?.data?.message ||
      'Failed to update company';
    yield put(actions.companySaveError(errorMessage, err as Error));
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(COMPANY_LOAD_REQUEST, loadCompany),
    yield takeEvery(INTERVIEW_SAVE_SUCCESS, loadCompany),
    yield takeEvery(COMPANY_SAVE_REQUEST, saveCompany),
    yield takeEvery(COMPANY_UPDATE_REQUEST, updateCompany),
  ];
}
