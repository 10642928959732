import { FC } from 'react';
import styled from 'styled-components';

import { Grid } from 'src/components/Grid';
import { TableProps } from 'antd/es/table';

export const StickyTable: FC<TableProps<any>> = styled(Grid)`
  .ant-table table {
    .ant-table-thead {
      position: sticky;
      top: 0;
      z-index: 9;

      & > tr > th {
        padding: 16px;
      }
    }
  }
`;
