import {
  Loader,
  QuestionChoice,
  QuizQuestion,
  QuizQuestionRaw,
  ShowErrorAction,
} from 'src/store/types';

export const EXAM_CREATE_REQUEST = 'EXAM_CREATE_REQUEST';
export const EXAM_CREATE_SUCCESS = 'EXAM_CREATE_SUCCESS';
export const EXAM_CREATE_FAILED = 'EXAM_CREATE_FAILED';

export type SelectedExamState = {
  exam: ExamState;
  examSubmission?: ExamSubmissionState;
};

export type ExamConfig = {
  trainingTypeId: number;
};

export type ExamByUserResponse = {
  id: number;
  status: string;
  lastSubmissionId: number;
  lastSubmissionStatus: string;
  trainingTypeName: string;
  createdAt: Date;
  trainingPeriodId: number;
};

export type ExamRawResponse = {
  id: number;
  trainingTypeId: number;
  quizId: number;
  status: string;
  createdAt: string;
  questions: { [key: number]: QuizQuestionRaw };
  choices: { [key: number]: QuestionChoice };
  hasSubmissions: boolean;
};

export type Exam = {
  id: number;
  trainingTypeId: number;
  quizId: number;
  status: string;
  createdAt: string;
  questions: QuizQuestion[];
  hasSubmissions?: boolean;
  lastSubmissionId?: number;
  lastSubmissionStatus?: string;
  trainingPeriodId?: number;
  trainingTypeName?: string;
  hasPastPassedSubmissions?: boolean;
};

export type ExamState = {
  exam?: Exam;
  loader: Loader;
};

export type ExamSubmissionState = {
  submission?: ExamSubmission;
  loader: Loader;
};

export type ExamSubmission = {
  id: number;
  completedAt: Date;
  grade: number;
  examId: number;
  passedChoices: number;
  failedChoices: number;
  responses: ExamSubmissionResponse[];
  status: string;
};

export type ExamSubmissionResponse = {
  id: number;
  answerText: string;
  isCorrect: boolean;
  questionId: number;
  failedChoices: number;
  passedChoices: number;
  grade: number;
};

export interface ExamCreateRequestAction {
  type: typeof EXAM_CREATE_REQUEST;
  config: ExamConfig;
}

export interface ExamCreateSuccessAction {
  type: typeof EXAM_CREATE_SUCCESS;
  exam: Exam;
}

export type ExamCreateErrorAction = ShowErrorAction<typeof EXAM_CREATE_FAILED>;

export const EXAM_RESET = 'EXAM_RESET';

export interface ExamResetAction {
  type: typeof EXAM_RESET;
}

export const EXAM_QUESTION_CREATE_REQUEST = 'EXAM_QUESTION_CREATE_REQUEST';
export const EXAM_QUESTION_CREATE_SUCCESS = 'EXAM_QUESTION_CREATE_SUCCESS';
export const EXAM_QUESTION_CREATE_FAILED = 'EXAM_QUESTION_CREATE_FAILED';

export interface ExamQuestionCreateRequestAction {
  type: typeof EXAM_QUESTION_CREATE_REQUEST;
  examId: number;
}

export interface ExamQuestionCreateSuccessAction {
  type: typeof EXAM_QUESTION_CREATE_SUCCESS;
  question: QuizQuestion;
}

export type ExamQuestionCreateErrorAction = ShowErrorAction<
  typeof EXAM_QUESTION_CREATE_FAILED
>;

export const EXAM_QUESTION_UPDATE_REQUEST = 'EXAM_QUESTION_UPDATE_REQUEST';
export const EXAM_QUESTION_UPDATE_SUCCESS = 'EXAM_QUESTION_UPDATE_SUCCESS';
export const EXAM_QUESTION_UPDATE_FAILED = 'EXAM_QUESTION_UPDATE_FAILED';

export interface ExamQuestionUpdateRequestAction {
  type: typeof EXAM_QUESTION_UPDATE_REQUEST;
  examId: number;
  question: QuizQuestion;
}

export interface ExamQuestionUpdateSuccessAction {
  type: typeof EXAM_QUESTION_UPDATE_SUCCESS;
  question: QuizQuestion;
}

export type ExamQuestionUpdateErrorAction = ShowErrorAction<
  typeof EXAM_QUESTION_UPDATE_FAILED
>;

export const EXAM_QUESTION_DELETE_REQUEST = 'EXAM_QUESTION_DELETE_REQUEST';
export const EXAM_QUESTION_DELETE_SUCCESS = 'EXAM_QUESTION_DELETE_SUCCESS';
export const EXAM_QUESTION_DELETE_FAILED = 'EXAM_QUESTION_DELETE_FAILED';

export interface ExamQuestionDeleteRequestAction {
  type: typeof EXAM_QUESTION_DELETE_REQUEST;
  examId: number;
  questionId: number;
}

export interface ExamQuestionDeleteSuccessAction {
  type: typeof EXAM_QUESTION_DELETE_SUCCESS;
  questionId: number;
}

export type ExamQuestionDeleteErrorAction = ShowErrorAction<
  typeof EXAM_QUESTION_DELETE_FAILED
>;

export const EXAM_LOAD_REQUEST = 'EXAM_LOAD_REQUEST';
export const EXAM_LOAD_SUCCESS = 'EXAM_LOAD_SUCCESS';
export const EXAM_LOAD_FAILED = 'EXAM_LOAD_FAILED';

export interface ExamLoadRequestAction {
  type: typeof EXAM_LOAD_REQUEST;
  examId?: number;
  loadSubmission?: boolean;
  shuffled?: boolean;
}

export interface ExamLoadSuccessAction {
  type: typeof EXAM_LOAD_SUCCESS;
  exam: Exam;
}

export type ExamLoadErrorAction = {
  type: typeof EXAM_LOAD_FAILED;
  error: string;
};

export const EXAM_UPDATE_REQUEST = 'EXAM_UPDATE_REQUEST';
export const EXAM_UPDATE_SUCCESS = 'EXAM_UPDATE_SUCCESS';
export const EXAM_UPDATE_FAILED = 'EXAM_UPDATE_FAILED';

export interface ExamUpdateRequestAction {
  type: typeof EXAM_UPDATE_REQUEST;
  examId: number;
  config: ExamConfig;
}

export interface ExamUpdateSuccessAction {
  type: typeof EXAM_UPDATE_SUCCESS;
  exam: Exam;
}

export type ExamUpdateErrorAction = ShowErrorAction<typeof EXAM_UPDATE_FAILED>;

export const EXAM_CLONE_REQUEST = 'EXAM_CLONE_REQUEST';
export const EXAM_CLONE_SUCCESS = 'EXAM_CLONE_SUCCESS';
export const EXAM_CLONE_FAILED = 'EXAM_CLONE_FAILED';

export interface ExamCloneRequestAction {
  type: typeof EXAM_CLONE_REQUEST;
  examId: number;
}

export interface ExamCloneSuccessAction {
  type: typeof EXAM_CLONE_SUCCESS;
  exam: Exam;
}

export type ExamCloneErrorAction = ShowErrorAction<typeof EXAM_CLONE_FAILED>;

export const EXAM_LOAD_SUBMISSION_REQUEST = 'EXAM_LOAD_SUBMISSION_REQUEST';
export const EXAM_LOAD_SUBMISSION_SUCCESS = 'EXAM_LOAD_SUBMISSION_SUCCESS';
export const EXAM_LOAD_SUBMISSION_FAILED = 'EXAM_LOAD_SUBMISSION_FAILED';

export interface ExamLoadSubmissionRequestAction {
  type: typeof EXAM_LOAD_SUBMISSION_REQUEST;
  examId: number;
  submissionId: number;
}

export interface ExamLoadSubmissionSuccessAction {
  type: typeof EXAM_LOAD_SUBMISSION_SUCCESS;
  submission: ExamSubmission;
}

export type ExamLoadSubmissionErrorAction = ShowErrorAction<
  typeof EXAM_LOAD_SUBMISSION_FAILED
>;

export const EXAM_SUBMISSION_REQUEST = 'EXAM_SUBMISSION_REQUEST';
export const EXAM_SUBMISSION_SUCCESS = 'EXAM_SUBMISSION_SUCCESS';
export const EXAM_SUBMISSION_FAILED = 'EXAM_SUBMISSION_FAILED';

export interface ExamSubmissionRequestAction {
  type: typeof EXAM_SUBMISSION_REQUEST;
  examId: number;
}

export interface ExamSubmissionSuccessAction {
  type: typeof EXAM_SUBMISSION_SUCCESS;
  submission: ExamSubmission;
}

export type ExamSubmissionErrorAction = ShowErrorAction<
  typeof EXAM_SUBMISSION_FAILED
>;

export const EXAM_SUBMISSION_RESPONSE_REQUEST =
  'EXAM_SUBMISSION_RESPONSE_REQUEST';
export const EXAM_SUBMISSION_RESPONSE_SUCCESS =
  'EXAM_SUBMISSION_RESPONSE_SUCCESS';
export const EXAM_SUBMISSION_RESPONSE_FAILED =
  'EXAM_SUBMISSION_RESPONSE_FAILED';

export interface ExamSubmissionResponseRequestAction {
  type: typeof EXAM_SUBMISSION_RESPONSE_REQUEST;
  examId: number;
  submissionId: number;
  questionId: number;
  choiceId: number;
  compute: boolean;
}

export interface ExamSubmissionResponseSuccessAction {
  type: typeof EXAM_SUBMISSION_RESPONSE_SUCCESS;
  submissionResult?: ExamSubmission;
}

export type ExamSubmissionResponseErrorAction = ShowErrorAction<
  typeof EXAM_SUBMISSION_RESPONSE_FAILED
>;

export const EXAM_QUIZ_LOAD_REQUEST = 'LOAD_EXAM_QUIZ_REQUEST';
export const EXAM_QUIZ_LOAD_SUCCESS = 'LOAD_EXAM_QUIZ_SUCCESS';
export const EXAM_QUIZ_LOAD_FAILED = 'LOAD_EXAM_QUIZ_FAILED';

export interface ExamQuizLoadRequestAction {
  type: typeof EXAM_QUIZ_LOAD_REQUEST;
}

export interface ExamQuizLoadSuccessAction {
  type: typeof EXAM_QUIZ_LOAD_SUCCESS;
  exam: Exam;
}

export type ExamQuizLoadErrorAction = ShowErrorAction<
  typeof EXAM_QUIZ_LOAD_FAILED
>;

export const EXAM_SUBMISSION_RESET = 'EXAM_SUBMISSION_RESET';

export interface ExamSubmissionResetAction {
  type: typeof EXAM_SUBMISSION_RESET;
}

export type ExamTypes =
  | ExamCreateRequestAction
  | ExamCreateSuccessAction
  | ExamCreateErrorAction
  | ExamResetAction
  | ExamQuestionCreateRequestAction
  | ExamQuestionCreateSuccessAction
  | ExamQuestionCreateErrorAction
  | ExamQuestionUpdateRequestAction
  | ExamQuestionUpdateSuccessAction
  | ExamQuestionUpdateErrorAction
  | ExamLoadRequestAction
  | ExamLoadSuccessAction
  | ExamLoadErrorAction
  | ExamUpdateRequestAction
  | ExamUpdateSuccessAction
  | ExamUpdateErrorAction
  | ExamQuestionDeleteRequestAction
  | ExamQuestionDeleteSuccessAction
  | ExamQuestionDeleteErrorAction
  | ExamCloneRequestAction
  | ExamCloneSuccessAction
  | ExamCloneErrorAction
  | ExamLoadSubmissionRequestAction
  | ExamLoadSubmissionSuccessAction
  | ExamLoadSubmissionErrorAction
  | ExamSubmissionRequestAction
  | ExamSubmissionSuccessAction
  | ExamSubmissionErrorAction
  | ExamSubmissionResponseRequestAction
  | ExamSubmissionResponseSuccessAction
  | ExamQuizLoadRequestAction
  | ExamQuizLoadSuccessAction
  | ExamQuizLoadErrorAction
  | ExamSubmissionResetAction;
