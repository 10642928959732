import {
  FEATURE_FLAG_LOAD_REQUEST,
  FEATURE_FLAG_LOAD_SUCCESS,
  FEATURE_FLAG_LOAD_ERROR,
  FeatureFlagLoadRequestAction,
  FeatureFlagLoadSuccessAction,
  FeatureFlagLoadErrorAction,
  FeatureFlagResponse,
} from '../types';
import { showErrorMessage } from './error';

export const featureFlagLoadRequest = (): FeatureFlagLoadRequestAction => ({
  type: FEATURE_FLAG_LOAD_REQUEST,
});

export const featureFlagLoadSuccess = (
  featureFlags: FeatureFlagResponse,
): FeatureFlagLoadSuccessAction => ({
  type: FEATURE_FLAG_LOAD_SUCCESS,
  featureFlags,
});

export const featureFlagLoadError = (
  errorMessage: string,
  originalError: Error,
): FeatureFlagLoadErrorAction =>
  showErrorMessage(FEATURE_FLAG_LOAD_ERROR, errorMessage, originalError);
