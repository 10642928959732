/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  RootState,
  InterviewListingIndexedItem,
  InterviewState,
} from '../types';
import { getCompanyLastAssessmentId } from '../selectors';

const getInterviewsFromState = (state: RootState): InterviewState[] => {
  const indexedInterviews: InterviewListingIndexedItem =
    state.company.interviewListing.interviews;
  return Object.values(indexedInterviews);
};

export const getInterviewById = (
  state: RootState,
  id: string,
): InterviewState | undefined => {
  return state.company.interviewListing.interviews[id];
};

export const getAssessmentInterviewsByFormId = (
  state: RootState,
  assessmentId: number,
): InterviewListingIndexedItem => {
  const interviews = getInterviewsFromState(state);
  const filteredInterviews = assessmentId
    ? interviews.filter((i) => i.assessmentId === assessmentId)
    : interviews;
  return filteredInterviews.reduce(
    (interviewMap: InterviewListingIndexedItem, interview: InterviewState) => {
      if (!interview.formId) {
        return {
          ...interviewMap,
        };
      }

      return {
        ...interviewMap,
        [interview.formId.toString()]: interview,
      };
    },
    {},
  );
};

export const getInterviewsFromLastAssessmentByFormId = createSelector(
  (state: RootState) => state,
  (state: RootState) => getCompanyLastAssessmentId(state),
  (state: RootState, lastAssessmentId?: number) =>
    getAssessmentInterviewsByFormId(state, lastAssessmentId as number),
);

export const getInterviewByAssessmentAndFormId = createSelector(
  (state: RootState) => state,
  (_: RootState, formId: string) => formId,
  (state: RootState, __: string, assessmentId: string) =>
    getAssessmentInterviewsByFormId(state, Number(assessmentId)),
  (state: RootState, formId: string, interviews: InterviewListingIndexedItem) =>
    interviews[formId],
);
