import { call, takeEvery, put } from 'redux-saga/effects';
import { CONSTANTS_LOAD_REQUEST, ConstantState } from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadConstants = function* async(): Generator {
  try {
    const constants = yield call(api.getConstantList);
    yield put(actions.constantsLoadSuccess(constants as ConstantState[]));
  } catch (err) {
    yield put(
      actions.constantsLoadError('Failed to load constants', err as Error),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(CONSTANTS_LOAD_REQUEST, loadConstants)];
}
