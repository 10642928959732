import React, { FC } from 'react';
import styled from 'styled-components';
import { CalendarOutlined, MessageOutlined } from 'src/theme/icons';

export interface RiskAssessmentTableSettingsProps {
  answerId?: number;
  questionId: number;
  interviewId?: number;
  disableReviewSettings: boolean;
  setCompletionDatePanelVisible: (val: boolean) => void;
  setSelectedAnswerId: (answerId: number) => void;
  setSelectedInterviewId: (interviewId: number) => void;
  handleOpenDiscussion: (questionId: number) => void;
}

const OptionContainer = styled.div`
  align-items: center;
  display: flex;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

export const RiskAssessmentTableSettings: FC<
  RiskAssessmentTableSettingsProps
> = ({
  answerId,
  questionId,
  interviewId,
  setCompletionDatePanelVisible,
  setSelectedAnswerId,
  setSelectedInterviewId,
  disableReviewSettings,
  handleOpenDiscussion,
}) => {
  return (
    <OptionContainer>
      <MessageOutlined
        onClick={() => handleOpenDiscussion(questionId)}
        title="Open discussion"
      />
      {!disableReviewSettings && answerId && (
        <CalendarOutlined
          onClick={() => {
            setCompletionDatePanelVisible(true);
            setSelectedAnswerId(answerId);
            interviewId && setSelectedInterviewId(interviewId);
          }}
        />
      )}
    </OptionContainer>
  );
};
