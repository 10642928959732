/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { RootState, Loader, TemplateState, TemplateLoader } from '../types';

export const getTemplateLoader = (state: RootState): TemplateLoader =>
  state.selectedTemplate.loader;
export const getTemplateById = (state: RootState, id: string): TemplateState =>
  state.templateListing.templates[id];
export const getLoadedTemplate = (state: RootState): TemplateState =>
  state.selectedTemplate.template;
