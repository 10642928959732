import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { Radio as RadioAntd } from 'antd';
import { Choice, Question, AnswerData, Answer } from 'src/store/types';
import { RadioChangeEvent } from 'antd/lib/radio';
import { TextArea } from 'src/components/TextArea';
import { remConvert } from 'src/theme/utils';

interface RadioFieldProps {
  onChange: (value: { isOther: boolean; value: string }) => void;
  value: { isOther: boolean; value: string };
  question: Question;
  updateAnswer: (answerData: AnswerData) => void;
  answer: Answer;
}

interface RadioOption {
  label: string;
  value: string;
  key: number;
}

const Radio = styled(RadioAntd)`
  height: ${remConvert(34)};
  line-height: ${remConvert(34)};
  display: flex;
  font-size: 14px;
  align-items: center;

  input {
    width: ${remConvert(200)};
    margin-left: ${remConvert(20)};
  }

  .ant-radio-inner {
    border-color: rgba(0, 0, 0, 0.3) !important;
  }
`;

export const RadioField: FC<RadioFieldProps> = ({
  onChange,
  value,
  question,
  updateAnswer,
  answer,
}) => {
  const optionToChoiceId: { [k: string]: number } = {};

  let options: RadioOption[] = [];

  const triggerChange = (e: RadioChangeEvent) => {
    const input = e.target.value as string;
    const isOther = input === 'OTHER';
    const newValue = isOther ? '' : input;

    onChange({ isOther, value: newValue });

    updateAnswer({
      value: JSON.stringify({ isOther, value: newValue }),
      choiceIds: optionToChoiceId[newValue] ? [optionToChoiceId[newValue]] : [],
      id: answer?.id,
      internalId: answer?.internalId,
    });
  };

  const triggerOtherChange = (e?: ChangeEvent<HTMLTextAreaElement>) => {
    const otherFieldValue = e?.target?.value;

    onChange({ isOther: true, value: otherFieldValue || '' });
  };

  const onOtherFieldBlur = () => {
    updateAnswer({
      value: JSON.stringify({ isOther: value.isOther, value: value.value }),
      choiceIds: [],
      id: answer?.id,
      internalId: answer?.internalId,
    });
  };

  if (question.choices) {
    options = question.choices
      .map((c: Choice) => {
        const id = (c.id ? c.id : c.internalId) as number;

        optionToChoiceId[c.label] = id;

        return {
          key: id,
          label: c.label,
          value: c.label,
          weight: c.weight,
        };
      })
      .sort((a, b) => a.weight - b.weight);
  }

  const selectedRadio = value?.isOther ? 'OTHER' : value?.value;

  return (
    <Radio.Group
      name={question.question}
      onChange={triggerChange}
      value={selectedRadio}
    >
      {options.map(({ key, value, label }) => (
        <Radio key={key} value={value}>
          {label}
        </Radio>
      ))}
      {question.allowExtra && (
        <>
          <Radio value={'OTHER'}>Other</Radio>
          {selectedRadio === 'OTHER' && (
            <TextArea
              onChange={triggerOtherChange}
              onBlur={onOtherFieldBlur}
              value={value.value}
            />
          )}
        </>
      )}
    </Radio.Group>
  );
};
