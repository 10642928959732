import React, { FC } from 'react';
import styled from 'styled-components';
import { MinusSquareOutlined, PlusSquareOutlined } from 'src/theme/icons';

interface MarkdownHelpSection {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  title: string;
  children: React.ReactNode;
}

const HelpSectionContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 8px;

  h3 {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-size: 13px;
    line-height: 18px;
    margin: 4px 0 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      display: flex;
      flex-grow: 1;
      margin: 0;

      &.anticon {
        flex-grow: 0;
        font-size: 20px;
      }
    }
  }
`;

const SectionContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grayscale.moonGray};
  margin-top: 10px;
  padding-top: 10px;
  h4 {
    color: #204864;
  }

  code {
    font-size: 11px;
  }
`;

export const HelpSection: FC<MarkdownHelpSection> = ({
  isOpen,
  setIsOpen,
  title,
  children,
}) => (
  <HelpSectionContainer>
    <h3
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <span>{title}</span>
      {isOpen ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
    </h3>
    {isOpen && <SectionContainer>{children}</SectionContainer>}
  </HelpSectionContainer>
);
