/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Loader, RootState, TrainingListingResponse } from 'src/store/types';

export const getTrainingListingLoader = (state: RootState): Loader =>
  state.trainingListing.loader;
export const getTrainingListingResponse = (
  state: RootState,
): TrainingListingResponse => state.trainingListing.trainingListingResponse;
