import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import { GlobalError } from 'src/components/GlobalError';
import { DomainRedirect } from 'src/components/DomainRedirect';
import { ThemeProvider } from 'src/theme';

import App from './app/App';
import store from './store/storeSetup';

import './index.css';
import './assets/fonts/OpenSans-Regular.ttf';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <DomainRedirect>
          <ThemeProvider>
            <GlobalError />
            <App />
          </ThemeProvider>
        </DomainRedirect>
      </Router>
    </React.StrictMode>
  </Provider>,
);
