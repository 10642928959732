import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { message, Divider, Popover, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import {
  RiskCondition,
  RiskComparatorType,
  QuestionTypes,
} from 'src/store/types';
import { Button, IconButton } from 'src/components/Button';
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from 'src/theme/icons';
import { LinkButton } from './LinkButton';
import { TextInput } from '../../../../components/TextInput';
import { RiskAssessmentFields } from './RiskAssessmentFields';

export interface RiskConditionsSettingsProps {
  value?: RiskCondition[] | undefined;
  onChange?: (value: RiskCondition[]) => void;
  fieldType: QuestionTypes;
}

const Wrapper = styled.div`
  width: 100%;

  .newRiskConditionContainer {
    > div,
    input {
      flex-grow: 1;
      width: 100%;
    }

    .linkButton {
      margin: 10px 0;
    }

    .actions {
      display: flex;
      justify-content: space-between;

      button {
        font-size: 14px;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const RiskAssessmentInfoSummaryContainer = styled.p`
  font-size: 12px;
  max-width: 400px;
`;

const ComparisonRuleset = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-select {
    margin: 0 5px;
  }
  input {
    height: 32px;
  }
`;

const NewRiskConditionInput = styled(TextInput)`
  padding-left: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grayscale.middleGray};
`;

const DATE_COMPARATORS: { [key: string]: RiskComparatorType } = {
  IS_OVER_YEARS: RiskComparatorType.IS_OVER_YEARS,
};

const NUMERIC_COMPARATORS: { [key: string]: RiskComparatorType } = {
  IS: RiskComparatorType.IS,
  IS_NOT: RiskComparatorType.IS_NOT,
  GREATER_THAN: RiskComparatorType.GREATER_THAN,
  LESS_THAN: RiskComparatorType.LESS_THAN,
};

const STRING_COMPARATORS: { [key: string]: string } = {
  IS_EMPTY: 'is empty',
};

export const RiskConditionsSettings: FC<RiskConditionsSettingsProps> = ({
  value,
  onChange,
  fieldType,
}) => {
  const [newRiskCondition, setNewRiskCondition] = useState<string>('');
  const [newRiskConditionComparator, setNewRiskConditionComparator] = useState(
    RiskComparatorType.IS_EMPTY,
  );
  const [newRiskConditionForm, setNewRiskConditionForm] = useState(false);
  const [newRiskConditionRiskFormInfo, setNewRiskConditionRiskFormInfo] =
    useState<Partial<RiskCondition>>({});
  const [currentRiskConditions, setCurrentRiskConditions] = useState(
    value ? value : [],
  );

  useEffect(() => {
    switch (fieldType) {
      case QuestionTypes.DATE:
        setNewRiskConditionComparator(RiskComparatorType.IS_OVER_YEARS);
        break;
      case QuestionTypes.NUMBER:
        setNewRiskConditionComparator(RiskComparatorType.IS);
        break;
      default:
        setNewRiskConditionComparator(RiskComparatorType.IS_EMPTY);
        break;
    }
  }, [value]);

  useEffect(() => {
    setCurrentRiskConditions(value ? value : []);
  }, [value]);

  const isDateFieldType = () => {
    return fieldType === QuestionTypes.DATE;
  };

  const isNumberFieldType = () => {
    return fieldType === QuestionTypes.NUMBER;
  };

  const addNewRiskCondition = () => {
    if (
      (!newRiskCondition || newRiskCondition.length === 0) &&
      (isDateFieldType() || isNumberFieldType())
    ) {
      void message.error('Invalid risk condition value');
      return;
    }

    const newRiskConditionObj: RiskCondition = {
      internalId: Math.random() * 10000,
      comparator: newRiskConditionComparator,
      condition: newRiskCondition,
      analysis: newRiskConditionRiskFormInfo
        ? newRiskConditionRiskFormInfo.analysis
        : undefined,
      delegate: newRiskConditionRiskFormInfo
        ? newRiskConditionRiskFormInfo.delegate
        : undefined,
      risk: newRiskConditionRiskFormInfo
        ? newRiskConditionRiskFormInfo.risk
        : undefined,
      actionItem: newRiskConditionRiskFormInfo
        ? newRiskConditionRiskFormInfo.actionItem
        : undefined,
    };

    const newValue = [...currentRiskConditions, newRiskConditionObj];
    setCurrentRiskConditions(newValue);
    triggerChange(newValue);
    resetForm();
  };

  const handleRiskInfoChange = (riskInfo: any) => {
    setNewRiskConditionRiskFormInfo(riskInfo);
  };

  const resetForm = () => {
    setNewRiskCondition('');
    setNewRiskConditionComparator(
      isNumberFieldType() ? RiskComparatorType.IS : RiskComparatorType.IS_EMPTY,
    );
    setNewRiskConditionForm(false);
    setNewRiskConditionRiskFormInfo({});
    setCurrentRiskConditions([]);
  };

  const deleteRiskCondition = (id: number) => {
    const newValues = currentRiskConditions.filter((c: RiskCondition) => {
      if (c.id) {
        return c.id !== id;
      } else {
        return c.internalId !== id;
      }
    });
    setCurrentRiskConditions(newValues);
    triggerChange(newValues);
  };

  const showNewConditionForm = () => {
    setNewRiskConditionForm(true);
  };

  const getComparatorString = (comparator?: string) => {
    if (!comparator) return '';

    if (isNumberFieldType()) return NUMERIC_COMPARATORS[comparator];
    if (isDateFieldType()) return DATE_COMPARATORS[comparator];

    return STRING_COMPARATORS[comparator];
  };

  const triggerChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  const riskAssessmentInfoSummary = (choice: RiskCondition) => {
    return (
      <RiskAssessmentInfoSummaryContainer>
        <h3>
          Answer {getComparatorString(choice.comparator)} {choice.condition}
        </h3>
        <p>{choice.analysis}</p>
        <p>{choice.actionItem}</p>
        {choice.risk && (
          <p>
            <span>Level:</span> {choice.risk.toLowerCase()}
          </p>
        )}
        {choice.delegate && (
          <p>
            <span>Delegate:</span> {choice.delegate.toLowerCase()}
          </p>
        )}
      </RiskAssessmentInfoSummaryContainer>
    );
  };

  const showDefaultRuleSet = (): boolean => {
    return !isNumberFieldType() && !isDateFieldType();
  };

  const allowAddNewRiskCondition = (): boolean => {
    return (
      isNumberFieldType() || isDateFieldType() || !currentRiskConditions.length
    );
  };

  return (
    <Wrapper>
      {currentRiskConditions.map((c: RiskCondition) => {
        const id = c.id ? c.id : c.internalId;
        return (
          <Row key={id}>
            <div>
              <span>
                {c.actionItem && (
                  <Popover
                    content={riskAssessmentInfoSummary(c)}
                    title="Risk assessment info"
                  >
                    <InfoCircleOutlined />
                  </Popover>
                )}{' '}
                Answer {getComparatorString(c.comparator)} {c.condition}
              </span>
            </div>
            <IconButton
              icon={<CloseCircleOutlined />}
              onClick={() => {
                deleteRiskCondition(id as number);
              }}
            />
          </Row>
        );
      })}
      {!newRiskConditionForm && allowAddNewRiskCondition() && (
        <LinkButton
          icon={<PlusOutlined />}
          text="Add new risk condition"
          onClick={() => {
            showNewConditionForm();
          }}
        />
      )}
      {newRiskConditionForm && (
        <>
          <Divider />
          <div className="newRiskConditionContainer">
            {isNumberFieldType() && (
              <ComparisonRuleset>
                Answer {''}
                <Select
                  value={newRiskConditionComparator}
                  onChange={(selectValue: SelectValue) =>
                    setNewRiskConditionComparator(
                      selectValue as RiskComparatorType,
                    )
                  }
                >
                  {Object.keys(NUMERIC_COMPARATORS).map(
                    (comparator: string, key: number) => {
                      return (
                        <Select.Option value={comparator} key={key}>
                          {NUMERIC_COMPARATORS[comparator]}
                        </Select.Option>
                      );
                    },
                  )}
                </Select>{' '}
                <NewRiskConditionInput
                  numeric
                  placeholder="0"
                  onChange={(e) => setNewRiskCondition(e.target.value)}
                />
              </ComparisonRuleset>
            )}
            {isDateFieldType() && (
              <ComparisonRuleset>
                Answer {''}
                <Select
                  value={newRiskConditionComparator}
                  onChange={(selectValue: SelectValue) =>
                    setNewRiskConditionComparator(
                      selectValue as RiskComparatorType,
                    )
                  }
                >
                  {Object.keys(DATE_COMPARATORS).map(
                    (comparator: string, key: number) => {
                      return (
                        <Select.Option value={comparator} key={key}>
                          {DATE_COMPARATORS[comparator]}
                        </Select.Option>
                      );
                    },
                  )}
                </Select>{' '}
                <NewRiskConditionInput
                  numeric
                  placeholder="0"
                  onChange={(e) => setNewRiskCondition(e.target.value)}
                />
              </ComparisonRuleset>
            )}

            {showDefaultRuleSet() && (
              <ComparisonRuleset>Answer is empty</ComparisonRuleset>
            )}

            <RiskAssessmentFields
              value={newRiskConditionRiskFormInfo}
              onChange={handleRiskInfoChange}
            />

            <div className="actions">
              <Button
                variant="secondary"
                title="Cancel"
                onClick={() => {
                  setNewRiskConditionForm(false);
                }}
              />
              <Button
                variant="secondary"
                title="Add"
                onClick={() => {
                  addNewRiskCondition();
                }}
              />
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};
