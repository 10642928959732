import styled from 'styled-components';
import { Grid as GridComponent } from 'src/components/Grid';

export const Grid = styled(GridComponent)`
  .ant-table-row-expand-icon-cell {
    display: none;
  }

  .ant-table table {
    border-spacing: 0 15px;
    .ant-table-tbody {
      tr.ant-table-row {
        td:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          display: table-cell;
          text-align: right;

          div {
            display: inline-block;
          }
        }
      }
    }

    .ant-table-expanded-row td {
      top: -20px;
    }
  }

  &&&& .selected {
    td {
      background-color: ${({ theme }) => theme.colors.paleYellow.main};
    }
  }

  &&&&& td {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
    border-width: 0 2px 2px 2px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.black.mainRgbLessOpacity};
  }

  tr.ant-table-row.ant-table-row-level-0 {
    td {
      border-width: 2px 0;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.black.mainRgbLessOpacity};
    }

    td:first-child {
      border-left-width: 2px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.black.mainRgbLessOpacity};
    }

    td:nth-child(2) {
      width: 50%;
      border-left-width: 2px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.black.mainRgbLessOpacity};
    }

    td:last-child {
      border-right-width: 2px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.black.mainRgbLessOpacity};
    }
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SettingsContainer = styled.div`
  display: flex;
  min-height: 21px;
  height: 100%;
`;

export const HipaaLabelContainer = styled(LabelContainer)`
  margin: 5px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.deepBlue.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 13px;
`;

export const HipaaInfoSectionContainer = styled.div`
  display: flex;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
  }

  .column {
    width: 50%;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.deepBlue.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
`;
