import React, { FC, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import {
  AnswerListingIndexedItem,
  AnswerSuggestionsMap,
  AssessmentRiskAnalysisState,
  CompanyState,
  Question,
  QuestionListingIndexedType,
  RootState,
  SelectedFormState,
  SelectedInterviewState,
  User,
} from 'src/store/types';
import {
  interviewDeleteAnswer,
  interviewSetQuestionsCount,
  interviewSetVisibleQuestionsIds,
  interviewUpdateRequest,
} from 'src/store/actions/interview';
import {
  getAnswersMapByQuestions,
  getAnswersSuggestions,
  getIndexedSelectedFormQuestionsItem,
  getUser,
} from 'src/store/selectors';
import {
  filterQuestions,
  processQuestionsMetadata,
  validateMessages,
} from './helpers';
import InterviewQuestionList from './InterviewQuestionList';
import * as S from './Styles';

interface InterviewWorkbench {
  company?: CompanyState;
  form: FormInstance;
  selectedForm: SelectedFormState;
  selectedInterview?: SelectedInterviewState;
  assessmentRiskAnalysis?: AssessmentRiskAnalysisState;
}

export const InterviewWorkbench: FC<InterviewWorkbench> = React.memo(
  (props) => {
    // Constants
    const {
      form,
      selectedForm,
      selectedInterview,
      company,
      assessmentRiskAnalysis,
    } = props;
    const { questions } = selectedForm;

    // Hooks
    const dispatch = useDispatch();
    const answers = useSelector(
      (state: RootState): AnswerListingIndexedItem =>
        getAnswersMapByQuestions(state),
    );
    const user = useSelector((state: RootState): User => getUser(state));
    const indexedSelectedFormQuestionsItem = useSelector(
      (state: RootState): QuestionListingIndexedType =>
        getIndexedSelectedFormQuestionsItem(state),
    );
    const assessmentSuggestions = useSelector(
      (state: RootState): AnswerSuggestionsMap => getAnswersSuggestions(state),
    );

    // State
    const [questionCount, setQuestionCount] = useState<number>(0);
    const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);

    // Keep local state's questionCount synced with interview's global state's questionCount
    if (
      selectedInterview?.metadata.questionsCount === 0 &&
      selectedInterview?.metadata.isPristine &&
      questionCount > 0
    ) {
      dispatch(interviewSetQuestionsCount(questionCount));
    }

    const deleteExistingAnswer = (questionId: number) => {
      const existingAnswer = answers[questionId];
      if (existingAnswer && (existingAnswer.id || existingAnswer.internalId)) {
        return dispatch(interviewDeleteAnswer(existingAnswer));
      }
    };

    const handleSubmitInterview = (values: { [k: string]: any }) => {
      const processedValues: { [k: string]: any } = {};

      Object.keys(values).forEach((key) => {
        if (moment.isMoment(values[key])) {
          // Date Object
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          processedValues[key] = values[key].format('MM/DD/YYYY');
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (Array.isArray(values[key]) && values[key]?.[0]?.response) {
          // File Object
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
          processedValues[key] = values[key].map((file: any) => file.response);
        } else {
          processedValues[key] = values[key];
        }
      });

      selectedInterview &&
        company &&
        dispatch(
          interviewUpdateRequest(
            selectedInterview.interview,
            true,
            company.id,
            Object.values(answers),
            selectedInterview.metadata,
          ),
        );

      return processedValues;
    };

    const handleSubmitInterviewFailed = (): void => {
      void message.error('Invalid answers, please check error messages');
    };

    // Effects
    useEffect(() => {
      const { deleteAnswersIds, questionsToAnswer, visibleQuestionsIds } =
        processQuestionsMetadata(
          selectedForm.questions,
          answers,
          indexedSelectedFormQuestionsItem,
          selectedForm,
          selectedInterview,
        );

      // Set final questions
      const newFilteredQuestions = filterQuestions(
        questions,
        visibleQuestionsIds,
      );
      setFilteredQuestions(newFilteredQuestions);

      // Delete answers
      deleteAnswersIds.forEach((questionId) => {
        deleteExistingAnswer(questionId);
      });

      // If there are questionsToAnswer and the amount is different from previous questionCount, update global state accordingly
      if (questionsToAnswer !== 0 && questionsToAnswer !== questionCount) {
        setQuestionCount(questionsToAnswer);
        dispatch(interviewSetQuestionsCount(questionsToAnswer));
        dispatch(interviewSetVisibleQuestionsIds(visibleQuestionsIds));
      }
    }, [
      answers,
      indexedSelectedFormQuestionsItem,
      selectedForm,
      selectedInterview,
    ]);

    return (
      <S.InterviewWorkbenchPropsContainer>
        <S.Title>
          <span style={{ display: 'flex', flexDirection: 'row' }}>
            {selectedForm.form.label}
          </span>
        </S.Title>
        <Form
          autoComplete="off"
          form={form}
          onFinish={handleSubmitInterview}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          onFinishFailed={handleSubmitInterviewFailed}
          validateMessages={validateMessages}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <InterviewQuestionList
            questions={filteredQuestions}
            answers={answers}
            assessmentSuggestions={assessmentSuggestions}
            selectedInterview={selectedInterview}
            userId={user.id}
            form={form}
            assessmentRiskAnalysis={assessmentRiskAnalysis}
          />
        </Form>
      </S.InterviewWorkbenchPropsContainer>
    );
  },
);
