import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { RoutesEnum } from 'src/shared/enums';
import actions from 'src/store/actions';
import { getCurrentExam, getCurrentExamSubmission } from 'src/store/selectors';
import { QuizWizard } from '../../components/QuizWizard';

export const ExamWizardPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentExam = useSelector(getCurrentExam);
  const currentSubmission = useSelector(getCurrentExamSubmission);

  useEffect(() => {
    dispatch(actions.examReset());
    dispatch(actions.examQuizLoadRequestAction());
  }, []);

  useEffect(() => {
    if (currentExam) {
      dispatch(actions.examSubmissionRequest(currentExam?.id));
    }
  }, [currentExam]);

  useEffect(() => {
    if (currentSubmission && currentSubmission.completedAt) {
      history.replace(
        `${RoutesEnum.EXAM_RESULT}/${currentExam.id}/submission/${currentSubmission.id}`,
      );
    }
  }, [currentSubmission]);

  const sendSubmissionResponse = (
    questionId: number,
    choiceId: number,
    isLastQuestion: boolean,
  ): void => {
    dispatch(
      actions.examSubmissionResponseRequest(
        Number(currentExam.id),
        Number(currentSubmission.id),
        Number(questionId),
        Number(choiceId),
        isLastQuestion,
      ),
    );
  };

  if (currentSubmission && !currentSubmission.id) {
    return <Redirect to={RoutesEnum.MY_TRAINING} />;
  }

  return (
    <>
      {currentExam && currentExam.questions && (
        <QuizWizard
          quizQuestions={currentExam?.questions}
          sendSubmissionResponse={sendSubmissionResponse}
        />
      )}
    </>
  );
};
