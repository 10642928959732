import React, { FC } from 'react';
import styled from 'styled-components';
import { StyleGuide } from 'src/features/style-guide/components';

const StyleGuideTitle = styled.h1`
  text-align: center;
`;

export const StyleGuidePage: FC = () => {
  return (
    <div>
      <StyleGuideTitle>Style Guide 2022</StyleGuideTitle>
      <StyleGuide />
    </div>
  );
};
