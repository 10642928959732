import {
  QuestionTypesState,
  QuestionTypeTypes,
  QUESTION_TYPES_LOAD_REQUEST,
  QUESTION_TYPES_LOAD_SUCCESS,
  QUESTION_TYPES_LOAD_ERROR,
  Loader,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: true,
  error: '',
  completed: false,
};

const formListingInitialState: QuestionTypesState = {
  types: [],
  loader: loaderInitialState,
};

export const questionTypeReducer = (
  state: QuestionTypesState = formListingInitialState,
  action: QuestionTypeTypes,
): QuestionTypesState => {
  switch (action.type) {
    case QUESTION_TYPES_LOAD_REQUEST: {
      return { ...state, loader: { ...loaderInitialState, loading: true } };
    }
    case QUESTION_TYPES_LOAD_SUCCESS: {
      return {
        types: action.types,
        loader: { ...loaderInitialState, success: true, completed: true },
      };
    }
    case QUESTION_TYPES_LOAD_ERROR: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: false,
          completed: true,
          error: action.message.toString(),
        },
      };
    }
    default:
      return state;
  }
};
