import { centeredFlex, semiboldOpenSans } from 'src/theme';
import styled, { css } from 'styled-components';
import { remConvert } from 'src/theme/utils';

const baseMargin = css`
  line-height: ${remConvert(22)};
  margin-left: ${remConvert(10)};
`;

export const RiskSectionsCompliantStatusContainer = styled.div`
  display: flex;

  .riskSectionStatus,
  .riskSectionRisks {
    ${semiboldOpenSans}
    ${baseMargin}
    ${centeredFlex}
    font-size: 16px;
  }

  .riskSectionStatus {
    color: rgba(0, 0, 0, 0.6);
  }

  .riskSectionRisks {
    color: ${({ theme }) => theme.colors.sunglo.main};
  }
`;
