import React, { ChangeEvent, FC } from 'react';
import { TextAreaProps as TextAreaAntdProps } from 'antd/lib/input';
import { TextAreaStyled } from './Styles';

interface TextAreaProps extends TextAreaAntdProps {
  className?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  onBlur?: (e?: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: FC<TextAreaProps> = ({
  className,
  disabled,
  onChange,
  placeholder = '',
  rows = 3,
  value,
  onBlur,
  ...textAreaProps
}) => (
  <TextAreaStyled
    className={className}
    disabled={disabled}
    onChange={onChange}
    placeholder={placeholder}
    rows={rows}
    value={value}
    onKeyPress={(e) => {
      if (e.key === 'Enter') e.preventDefault();
    }}
    onBlur={onBlur}
    {...textAreaProps}
  />
);
