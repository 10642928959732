import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

export const InterviewWorkbenchPropsContainer = styled.div`
  padding: ${remConvert(24)};
  width: 100%;
`;

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${remConvert(35)};
  font-size: 26px;
  color: rgba(28, 36, 40, 0.85);
`;

export const AssigneeNameLink = styled.a`
  margin-left: ${remConvert(10)};
`;

export const DeleteAssigneeButton = styled.a`
  color: gray;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-left: ${remConvert(10)};
`;

export const FormTitle = styled.span`
  display: flex;
  flex-direction: row;
`;
