import React, { FC } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';

interface FootnotePreviewProps {
  content: string;
}

const Label = styled.span`
  background-color: #efefef;
  border: 1px solid #909090;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin: 5px;
  font-size: 11px;
`;

export const FootnotePreview: FC<FootnotePreviewProps> = ({ content }) => {
  return (
    <Tooltip title={content}>
      <Label>Footnote</Label>
    </Tooltip>
  );
};
