/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC } from 'react';
import styled from 'styled-components';
import { Form, Upload, message } from 'antd';
import { InboxOutlined } from 'src/theme/icons';
import { FileData, Question, AnswerData, Answer } from 'src/store/types';
import { normalizeFile, uploadFile } from 'src/services/uploadFile';
import { useDispatch } from 'react-redux';
import {
  interviewRemoveFile,
  interviewAddFile,
} from 'src/store/actions/interview';

interface FileUploadFieldProps {
  onChange: (value: any) => void;
  value?: FileData[];
  question: Question;
  updateAnswer: (answerData: AnswerData) => void;
  deleteAnswer: (answerData: AnswerData) => void;
  answer: Answer;
}

const UploadInputItem = styled(Form.Item)`
  width: 312px;
`;

export const FileUploadField: FC<FileUploadFieldProps> = ({
  value,
  question,
  updateAnswer,
  deleteAnswer,
  answer,
}) => {
  const isUploading =
    value && Array.isArray(value)
      ? value.reduce(
          (uploading, file) => uploading || file.status === 'uploading',
          false,
        )
      : false;
  const dispatch = useDispatch();

  const getAllowedExtensions = (): string => {
    switch (question.extension) {
      case 'image':
        return 'image/*,.pdf';
      case 'text':
        return 'text/*,.pdf';
      default:
        return '';
    }
  };

  const aboveMaxSize = (file: FileData) =>
    question.max && file.size / 1024 ** 2 > question.max;

  const beforeUpload = (file: FileData) => {
    if (aboveMaxSize(file)) {
      void message.error(`Cannot exceed ${question.max}MB`);
      return false;
    }
  };

  const handleChange = (info: { file: FileData; fileList: FileData[] }) => {
    const { file, fileList } = info;
    if (file.status === 'removed') {
      fileList.length === 0
        ? deleteAnswer &&
          deleteAnswer({ id: answer?.id, internalId: answer?.internalId })
        : updateAnswer({
            id: answer?.id,
            internalId: answer?.internalId,
            value: JSON.stringify(fileList.map((f) => normalizeFile(f))),
          });
      dispatch(interviewRemoveFile(file));
      return fileList;
    }
    dispatch(interviewAddFile(file));
    if (file.status === 'done') {
      if (file.xhr) {
        const fileData = !question.multiple
          ? [normalizeFile(file)]
          : fileList.map((f) => normalizeFile(f));
        updateAnswer({
          id: answer?.id,
          internalId: answer?.internalId,
          value: JSON.stringify(fileData),
        });
        return fileData;
      }
      return fileList;
    }
    if (file.status === 'uploading') {
      return !question.multiple ? [file] : fileList;
    }
  };

  const { Dragger } = Upload;

  return (
    <UploadInputItem
      name={question.id || question.internalId}
      valuePropName="fileList"
      getValueFromEvent={handleChange}
      shouldUpdate={() => false}
    >
      <Dragger
        accept={getAllowedExtensions()}
        beforeUpload={beforeUpload as any}
        customRequest={uploadFile as any}
        name="files"
        multiple={question.multiple}
        disabled={!question.multiple && isUploading}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          {question.multiple
            ? 'Support for a single or bulk upload.'
            : 'Single upload allowed'}
        </p>
      </Dragger>
    </UploadInputItem>
  );
};
