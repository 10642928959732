import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { remConvert } from 'src/theme/utils';
import { centeredFlex, device } from 'src/theme';

export const HelpStepNumber = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${remConvert(68)};
  font-size: 50px;
  text-align: center;
  color: rgba(28, 36, 40, 0.5);
`;

export const HelpContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: ${remConvert(5)};
  padding-right: ${remConvert(5)};
`;

export const HelpDescriptor = styled.span`
  ${centeredFlex}
  justify-content: center;
  margin-left: ${remConvert(20)};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 14px;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${remConvert(10)};
  width: 100%;

  button {
    width: 100%;
  }
`;

export const SendButtonWrapper = styled.div`
  margin-top: ${remConvert(10)};
  width: 100%;

  button {
    float: right;
  }
`;

export const SMSContainer = styled.div`
  width: 100%;

  @media ${device.lg} {
    width: ${remConvert(519)};
  }

  ${centeredFlex}
  flex-direction: column;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${remConvert(8)};

  @media ${device.md} {
    width: ${remConvert(400)};
  }

  & input {
    width: 100%;
  }

  & .react-tel-input .form-control {
    width: 100%;

    @media ${device.md} {
      width: ${remConvert(400)};
    }
  }

  & .search {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    width: 100%;
    gap: ${remConvert(8)};

    & .search-box {
      margin-left: unset;
    }
  }
`;

export const StyledPhoneInput = styled(PhoneInput)`
  width: ${remConvert(88)};

  .form-control {
    width: ${remConvert(88)};
    height: ${remConvert(40)};
  }

  .flag-dropdown {
    ${centeredFlex}
    background-color: white;
    border-right: 0px;
    height: ${remConvert(40)};
    width: ${remConvert(80)};
    justify-content: center;
  }
`;

export const PhoneInputWrapper = styled.div`
  display: flex;
  flex-align: row;
  width: 100%;
  justify-content: center;
`;

export const PhoneTextInputWrapper = styled.div`
  padding-left: ${remConvert(10)};
  width: ${remConvert(300)};
`;
