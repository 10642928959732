import { FormState } from 'src/store/types';
import { TableRowInterface } from './FormListingTable';

export const dataMapper = (data: FormState[]): TableRowInterface[] => {
  return data.map((form) => ({
    id: form.id,
    title: form.label,
    status: form.isActive,
    draft: form.isDraft,
    rawData: form,
  }));
};
