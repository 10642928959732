import React, { FC } from 'react';
import styled from 'styled-components';
import { TemplateRegExpTokens } from '../useRegExpTokens';
import { ConstantToolbarItem } from './ConstantToolbarItem';
import { FunctionToolbarItem } from './FunctionToolbarItem';
import { QuestionToolbarItem } from './QuestionToolbarItem';
import { VariableToolbarItem } from './VariableToolbarItem';
import { ConditionalToolbarItem } from './ConditionalToolbarItem';
import { TextStyleToolbarItems } from './TextStyleToolbarItems';
import { TocToolbarItem } from './TocToolbarItem';
import { TitleToolbarItem } from './TitleToolbarItem';
import { OrderedListToolbarItem } from './OrderedListToolbarItem';
import { UnorderedListToolbarItem } from './UnorderedListToolbarItem';

interface ToolbarProps {
  regExpTokens: TemplateRegExpTokens;
  templateId: number;
  updateEditorContent: (val: string) => void;
}

const MainContainer = styled.div`
  display: flex;
  height: 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  border-radius: 4px;
`;

/**
 * Template Editor's toolbar
 * @param regExpTokens - Information about entities' RegExp tokens so that we can insert them into text when needed.
 * @param updateEditorContent - Handler for changing editor's content (TODO: Replace selection instead of appending content).
 */
export const Toolbar: FC<ToolbarProps> = ({
  regExpTokens,
  updateEditorContent,
  templateId,
}) => {
  return (
    <MainContainer>
      <QuestionToolbarItem
        questionToken={regExpTokens.questionToken}
        updateEditorContent={updateEditorContent}
      />
      <VariableToolbarItem
        variableToken={regExpTokens.variableToken}
        updateEditorContent={updateEditorContent}
        templateId={templateId}
      />
      <ConstantToolbarItem
        constantToken={regExpTokens.constantToken}
        updateEditorContent={updateEditorContent}
      />
      <FunctionToolbarItem updateEditorContent={updateEditorContent} />
      <ConditionalToolbarItem updateEditorContent={updateEditorContent} />
      <TextStyleToolbarItems updateEditorContent={updateEditorContent} />
      <TocToolbarItem updateEditorContent={updateEditorContent} />
      <TitleToolbarItem updateEditorContent={updateEditorContent} />
      <OrderedListToolbarItem updateEditorContent={updateEditorContent} />
      <UnorderedListToolbarItem updateEditorContent={updateEditorContent} />
    </MainContainer>
  );
};
