import { QuestionType } from './questionType';

export type QuestionList = Question[];

export interface Question {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  isActive: boolean;
  question: string;
  description: string;
  tooltip?: string;
  moreInfo?: string;
  category?: string;
  multiple: boolean;
  allowExtra: boolean;
  required: boolean;
  choices?: Choice[];
  answer?: any;
  form?: any;
  formId?: number;
  legalRef?: string;
  legalRefCmmc?: string;
  legalRefNist?: string;
  conditions?: Condition[];
  type: QuestionType;
  typeId?: number;
  weight: number;
  internalId?: string;
  min?: number;
  max?: number;
  extension?: string;
  riskConditions?: RiskCondition[];
  isSmart: boolean;
  visibilityPrecondition?: boolean;
  formLabel?: string;
  temp: boolean;
}

export interface Choice {
  id?: number;
  label: string;
  value: string;
  moreInfo?: string;
  isDefault: boolean;
  internalId?: string;
  analysis?: string;
  actionItem?: string;
  risk?: string;
  delegate?: string;
  weight: number;
}

export interface Condition {
  id?: number;
  questionId: number;
  question?: Question;
  subjectId: number;
  subject?: Question;
  connector?: string;
  comparator?: string;
  choiceId?: number;
  choice?: Choice;
  condition?: string;
  effect?: string;
}

export enum RiskComparatorType {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  CONTAINS = 'CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  IS_EMPTY = 'IS_EMPTY',
  IS_OVER_YEARS = 'IS_OVER_YEARS',
}

export interface RiskCondition {
  id?: number;
  internalId?: number;
  questionId?: number;
  question?: Question;
  comparator?: RiskComparatorType;
  condition?: string;
  analysis?: string;
  actionItem?: string;
  risk?: string;
  delegate?: string;
}

export interface QuestionListingIndexedType {
  [key: string]: Question;
}
