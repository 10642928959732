import {
  ThreadListActionTypes,
  ThreadListingIndexedItem,
  ThreadListingState,
  THREAD_LIST_LOAD_ERROR,
  THREAD_LIST_LOAD_REQUEST,
  THREAD_LIST_LOAD_SUCCESS,
  Loader,
  Thread,
  CommentFromResponseIndexed,
  CommentFromResponse,
  Comment,
  User,
  COMMENT_SAVE_REQUEST,
  COMMENT_SAVE_ERROR,
  COMMENT_DELETE_REQUEST,
  COMMENT_DELETE_ERROR,
  THREAD_UPDATE_REQUEST,
  THREAD_UPDATE_ERROR,
  THREAD_UPDATE_SUCCESS,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const loaderSuccessState: Loader = {
  ...loaderInitialState,
  loading: false,
  completed: true,
  success: true,
};

const threadListingInitialState: ThreadListingState = {
  threads: {},
  loader: loaderInitialState,
};

export const threadListingReducer = (
  state = threadListingInitialState,
  action: ThreadListActionTypes,
): ThreadListingState => {
  switch (action.type) {
    case THREAD_LIST_LOAD_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }

    case THREAD_LIST_LOAD_SUCCESS: {
      let threads = {};

      if (action.threads) {
        const children: CommentFromResponseIndexed = {};

        const commentAuthorRegistry: { [key: string]: Partial<User> } = {};

        action.threads.forEach((thread: Thread) => {
          if (thread.comments?.length) {
            thread.comments
              .sort((c: Comment, c2: Comment) => {
                return c.id - c2.id;
              })
              .forEach((comment: CommentFromResponse) => {
                const { id, parent, author } = comment;

                if (author && !commentAuthorRegistry[id]) {
                  commentAuthorRegistry[id] = {
                    id: author.id,
                    name: author.name,
                  };
                }

                if (parent) {
                  if (children[parent.id]) {
                    children[parent.id].push(comment);
                  } else {
                    children[parent.id] = [comment];
                  }
                }
              });
          }

          if (thread.question) {
            thread.questionId = thread.question.id;
          }
        });

        threads = action.threads.reduce(
          (tempMap: ThreadListingIndexedItem, thread) => {
            let comments: any = [];

            if (thread.comments) {
              comments = thread.comments
                .filter((comment: CommentFromResponse) => {
                  return !comment.parent;
                })
                .map((comment: CommentFromResponse) => {
                  if (children[comment.id]) {
                    return {
                      ...comment,
                      children: children[comment.id].map(
                        (c: CommentFromResponse) => {
                          if (!c.parent) {
                            return c;
                          }
                          return {
                            ...c,
                            parent: {
                              id: c.parent.id,
                              author: commentAuthorRegistry[c.parent?.id],
                            },
                          };
                        },
                      ),
                    };
                  }
                  return comment;
                });
            }

            return {
              ...tempMap,
              [thread.id]: { ...thread, comments },
            };
          },
          {},
        );
      }

      return {
        threads: threads,
        loader: loaderSuccessState,
      };
    }
    case THREAD_UPDATE_SUCCESS: {
      const { updatedThread } = action;
      const { threads } = state;
      return {
        threads: {
          ...threads,
          [updatedThread.id]: {
            ...threads[updatedThread.id],
            ...updatedThread,
          },
        },
        loader: loaderSuccessState,
      };
    }
    case THREAD_LIST_LOAD_ERROR:
    case THREAD_UPDATE_ERROR:
    case COMMENT_SAVE_ERROR:
    case COMMENT_DELETE_ERROR: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: false,
          completed: true,
          error: action.message.toString(),
        },
      };
    }
    default:
      return state;
  }
};
