import React, { FC } from 'react';
import { THLogoSmall, THIconError } from 'src/theme/media';
import {
  ToastTitleMessage,
  ToastLogo,
  ToastSubtitleMessage,
  ToastTitleMessageContainer,
} from './Styles';

interface MessageProps {
  title?: string;
  subtitle?: string;
  error?: boolean;
}

export const Message: FC<MessageProps> = ({ title, subtitle, error }) => {
  return (
    <div>
      <ToastTitleMessageContainer>
        {error ? (
          <ToastLogo height={48} width={48} src={THIconError} />
        ) : (
          <ToastLogo height={48} width={48} src={THLogoSmall} />
        )}
        <ToastTitleMessage>{title || ''}</ToastTitleMessage>
        <ToastSubtitleMessage>{subtitle || ''}</ToastSubtitleMessage>
      </ToastTitleMessageContainer>
    </div>
  );
};
