import {
  Loader,
  UserSummaryFromResponse,
  UserSummaryListingIndexedItem,
  UserSummaryListingState,
  CompanyUsersActionTypes,
  USER_SUMMARY_LOAD_SUCCESS,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const userSummaryListingInitialState: UserSummaryListingState = {
  users: {},
  loader: loaderInitialState,
};

export const userSummaryListingReducer = (
  state: UserSummaryListingState = userSummaryListingInitialState,
  action: CompanyUsersActionTypes,
): UserSummaryListingState => {
  switch (action.type) {
    case USER_SUMMARY_LOAD_SUCCESS: {
      return {
        ...state,
        users: action.users.reduce(
          (
            acc: UserSummaryListingIndexedItem,
            user: UserSummaryFromResponse,
          ) => {
            return {
              ...acc,
              [user.id]: user,
            };
          },
          {},
        ),
      };
    }
  }
  return state;
};
