import { call, takeEvery, put } from 'redux-saga/effects';
import {
  QuestionsLoadRequestAction,
  FormQuestionsState,
  QUESTIONS_LOAD_REQUEST,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadQuestions = function* async({
  assessmentTypeId,
}: QuestionsLoadRequestAction): Generator {
  try {
    const forms = yield call(
      api.getFormQuestionsByAssessmentType,
      assessmentTypeId,
    );
    yield put(actions.questionsLoadSuccess(forms as FormQuestionsState[]));
  } catch (err) {
    yield put(
      actions.questionsLoadError('Failed to load questions', err as Error),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(QUESTIONS_LOAD_REQUEST, loadQuestions)];
}
