/* eslint-disable no-console */
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ErrorSeverity } from 'src/store/types';
import styled from 'styled-components';
import { error, info } from 'src/shared/utils';
import { getErrorFromState } from '../../store/selectors/error';

const ErrorList = styled.ul`
  padding-left: 20px;
  margin-top: 12px;
`;

const ListItem = styled.li`
  text-align: left;
`;

export const GlobalError: FC = () => {
  const globalErrorState = useSelector(getErrorFromState);

  const isProduction =
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production';

  const logError = () =>
    isProduction &&
    console.info(
      `[${globalErrorState.severity}]: ${globalErrorState.originalError}`,
    );

  useEffect(() => {
    if (globalErrorState.originalError) {
      logError();
    }

    if (globalErrorState.message && globalErrorState.message !== '') {
      let messageToShow: string | React.ReactNode = globalErrorState.message;

      if (Array.isArray(globalErrorState.message)) {
        messageToShow = (
          <ErrorList>
            {globalErrorState.message.map((message, idx) => (
              <ListItem key={idx}>{message}</ListItem>
            ))}
          </ErrorList>
        );
      }

      switch (globalErrorState.severity) {
        case ErrorSeverity['WARNING']: {
          void info(messageToShow as string);
          if (!isProduction) {
            console.warn(globalErrorState.originalError);
          }
          break;
        }

        case ErrorSeverity['INFO']: {
          if (!isProduction) {
            console.info(messageToShow);
          }
          break;
        }

        default: {
          void error(messageToShow as string);
          if (!isProduction) {
            console.error(globalErrorState.originalError);
          }
          break;
        }
      }
    }
  }, [globalErrorState]);

  return null;
};
