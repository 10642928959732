import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getCompanyLastAssessment,
  getCompanyLoaderFromState,
} from 'src/store/selectors';
import {
  AssessmentState,
  AssessmentStatus,
  Loader,
  RootState,
} from 'src/store/types';

export const useAssessment = () => {
  const assessment = useSelector((state: RootState) =>
    getCompanyLastAssessment(state),
  ) as AssessmentState;
  const companyLoader = useSelector(
    (state: RootState): Loader => getCompanyLoaderFromState(state),
  );
  const location = useLocation();
  const isAssessmentOverlay: boolean =
    assessment?.status === AssessmentStatus.COMPLETED &&
    location.pathname.includes('risk-assessment') &&
    companyLoader.completed;

  return { isAssessmentOverlay, assessment, companyLoader };
};
