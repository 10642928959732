import { ShowErrorAction } from '../types';

export const FEATURE_FLAG_LOAD_REQUEST = 'FEATURE_FLAG_LOAD_REQUEST';
export const FEATURE_FLAG_LOAD_SUCCESS = 'FEATURE_FLAG_LOAD_SUCCESS';
export const FEATURE_FLAG_LOAD_ERROR = 'FEATURE_FLAG_LOAD_ERROR';

export type FeatureFlagState = {
  modules: FeatureFlagResponse;
  completed: boolean;
};

export type FeatureFlagResponse = {
  [key: string]: boolean;
};

export interface FeatureFlagLoadRequestAction {
  type: typeof FEATURE_FLAG_LOAD_REQUEST;
}

export interface FeatureFlagLoadSuccessAction {
  type: typeof FEATURE_FLAG_LOAD_SUCCESS;
  featureFlags: FeatureFlagResponse;
}

export type FeatureFlagLoadErrorAction = ShowErrorAction<
  typeof FEATURE_FLAG_LOAD_ERROR
>;

export type FeatureFlagActionTypes =
  | FeatureFlagLoadRequestAction
  | FeatureFlagLoadSuccessAction
  | FeatureFlagLoadErrorAction;
