import download from 'downloadjs';

import { HttpClient } from 'src/api/services/HttpClient';

export class S3DownloadFile extends HttpClient {
  public constructor() {
    super(process.env.REACT_APP_API_URL as string);
  }

  private getFileSignedUrl = async (key: string, bucket?: string) => {
    const res = await this.instance.get<{ url: string }>('/utils/signedUrl', {
      withCredentials: true,
      params: { key, bucket },
    });
    return res.url;
  };

  handleFileDownload = async (
    key: string,
    fileName: string,
    bucket?: string,
  ) => {
    const url = await this.getFileSignedUrl(key, bucket);
    return download(url);
  };

  handleFileDownloadSignedUrl = (url: string | Blob | File | Uint8Array) =>
    download(url);
}

const s3 = new S3DownloadFile();

export default s3;
