import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Radio, Input } from 'antd';
import styled from 'styled-components';
import _ from 'lodash';

import { Button } from 'src/components/Button';
import { confirmDialog } from 'src/shared/utils';
import { DeleteOutlined } from 'src/theme/icons';
import { QuestionChoice, QuizQuestion } from 'src/store/types';
import actions from 'src/store/actions';
import { ResourceTypeEnum } from '../../enums';

interface Props {
  resourceType: ResourceTypeEnum;
  resourceId: number;
  question: QuizQuestion;
  isResourceActive?: boolean;
}

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionNameInput = styled(Input.TextArea)`
  border: none;
  border-radius: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const RadioGroup = styled(Radio.Group)`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const ChoiceInput = styled(Input)`
  border: none;
  border-radius: 10px;
`;

const ResponsesHeader = styled.span`
  font-weight: bold;
`;

const IsCorrectHeader = styled.span`
  font-weight: bold;
  margin-right: 20px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const DeleteButton = styled.a`
  font-size: 16px;
`;

const SingleQuestionForm = ({
  resourceId,
  resourceType,
  question,
  isResourceActive,
}: Props) => {
  const dispatch = useDispatch();

  const questionUpdateAction =
    resourceType === ResourceTypeEnum.TRAINING
      ? actions.trainingQuestionUpdateRequest
      : actions.examQuestionUpdateRequest;

  const questionDeleteAction =
    resourceType === ResourceTypeEnum.TRAINING
      ? actions.trainingQuestionDeleteRequest
      : actions.examQuestionDeleteRequest;

  const [currentQuestion, setCurrentQuestion] =
    useState<QuizQuestion>(question);
  useEffect(() => {
    setCurrentQuestion({
      ...currentQuestion,
      choices: question.choices.map(
        (choice) =>
          currentQuestion.choices.find(
            (currentChoice) => currentChoice.id === choice.id,
          ) || choice,
      ),
    });
  }, [question]);

  const debounceUpdateQuestion = useMemo(
    () =>
      _.debounce((value: QuizQuestion) => {
        dispatch(questionUpdateAction(resourceId, value));
      }, 500),
    [],
  );

  const updateQuestion = (value: QuizQuestion) => {
    setCurrentQuestion(value);
    debounceUpdateQuestion(value);
  };

  return (
    <QuestionContainer>
      <QuestionNameInput
        rows={2}
        placeholder="Name"
        value={currentQuestion.questionText}
        onChange={({ target }) =>
          updateQuestion({ ...currentQuestion, questionText: target.value })
        }
      />
      <HeaderContainer>
        <ResponsesHeader>Responses</ResponsesHeader>
        <IsCorrectHeader>Correct</IsCorrectHeader>
      </HeaderContainer>
      <RadioGroup
        onChange={(value) => {
          const choices: QuestionChoice[] = currentQuestion.choices.map(
            (choice) => ({
              ...choice,
              isCorrect: choice.id === value.target.value,
            }),
          );
          updateQuestion({
            ...currentQuestion,
            choices,
          });
        }}
        value={
          currentQuestion.choices.find((option) => option.isCorrect)?.id || 0
        }
      >
        {currentQuestion.choices.map((choice) => {
          return (
            <ChoiceContainer
              key={choice.id}
              style={{
                backgroundColor: choice.id ? 'white' : 'whitesmoke',
              }}
            >
              <ChoiceInput
                disabled={!choice.id}
                placeholder="Name"
                value={choice.choiceText}
                onChange={(e) => {
                  const choices: QuestionChoice[] = currentQuestion.choices.map(
                    (currentChoice) => ({
                      ...currentChoice,
                      choiceText:
                        currentChoice.id === choice.id
                          ? e.target.value
                          : currentChoice.choiceText,
                    }),
                  );
                  updateQuestion({
                    ...currentQuestion,
                    choices,
                  });
                }}
              />
              <Radio
                value={choice.id}
                disabled={!choice.id || isResourceActive}
              >
                <DeleteOutlined
                  onClick={(e) => {
                    e.preventDefault();
                    if (!choice.id || isResourceActive) return;
                    const choices: QuestionChoice[] =
                      currentQuestion.choices.filter(
                        (currentChoice) => currentChoice.id !== choice.id,
                      );
                    updateQuestion({
                      ...currentQuestion,
                      choices,
                    });
                  }}
                />
              </Radio>
            </ChoiceContainer>
          );
        })}
      </RadioGroup>
      <Button
        title="Add response"
        disabled={isResourceActive}
        onClick={() => {
          updateQuestion({
            ...currentQuestion,
            choices: [
              ...currentQuestion.choices,
              { choiceText: '', isCorrect: false },
            ],
          });
        }}
      />
      {!isResourceActive && (
        <FooterContainer>
          <DeleteButton
            onClick={(e) => {
              e.preventDefault();
              confirmDialog({
                text: 'Deleting a question permanently removes it and its contents.',
                onOk: () => {
                  dispatch(questionDeleteAction(resourceId, question.id));
                },
              });
            }}
          >
            Delete question
          </DeleteButton>
        </FooterContainer>
      )}
    </QuestionContainer>
  );
};

export default SingleQuestionForm;
