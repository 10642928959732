import {
  DocumentState,
  DOCUMENTS_BY_ASSESSMENT_LOAD_REQUEST,
  DOCUMENTS_BY_ASSESSMENT_LOAD_SUCCESS,
  DOCUMENTS_BY_ASSESSMENT_LOAD_ERROR,
  DOCUMENT_APPROVE_SUCCESS,
  DOCUMENT_CLOSE_SUCCESS,
  ASSESSMENT_UPDATE_STATUS_SUCCESS,
  COMPANY_LOAD_REQUEST,
  COMPANY_LOAD_SUCCESS,
  DocumentListingActionTypes,
  DocumentListingState,
  Loader,
  DocumentListingIndexedItem,
  DocumentFromResponse,
  DocumentStatus,
  CompanyActionTypes,
  AssessmentFromResponse,
  AssessmentActionTypes,
  AssessmentStatus,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const documentListingInitialState: DocumentListingState = {
  documents: {},
  loader: loaderInitialState,
};

export const documentListingReducer = (
  state: DocumentListingState = documentListingInitialState,
  action:
    | DocumentListingActionTypes
    | CompanyActionTypes
    | AssessmentActionTypes,
): DocumentListingState => {
  switch (action.type) {
    case DOCUMENTS_BY_ASSESSMENT_LOAD_REQUEST:
    case COMPANY_LOAD_REQUEST: {
      return { ...state, loader: { ...loaderInitialState, loading: true } };
    }
    case DOCUMENTS_BY_ASSESSMENT_LOAD_SUCCESS: {
      const list = action.documents.reduce(
        (list: DocumentListingIndexedItem, document: DocumentFromResponse) => {
          const { createdAt, creator, id, name, status, updatedAt, file } =
            document;
          return {
            ...list,
            [document.id]: {
              id,
              assessmentId: action.assessmentId,
              creatorId: creator?.id,
              createdAt,
              name,
              status,
              updatedAt,
              file,
            } as DocumentState,
          };
        },
        {},
      );
      return {
        documents: list,
        loader: { ...loaderInitialState, success: true, completed: true },
      };
    }
    case COMPANY_LOAD_SUCCESS: {
      const documents = action.company.assessments.reduce(
        (
          list: DocumentListingIndexedItem,
          assessment: AssessmentFromResponse,
        ) => {
          return assessment.documents
            ? assessment.documents.reduce(
                (
                  list: DocumentListingIndexedItem,
                  document: DocumentFromResponse,
                ) => {
                  const { createdAt, creator, id, name, status, updatedAt } =
                    document;
                  return {
                    ...list,
                    [id]: {
                      createdAt,
                      creator,
                      id,
                      name,
                      status,
                      updatedAt,
                    },
                  };
                },
                list,
              )
            : list;
        },
        state.documents,
      );
      return { ...state, documents };
    }
    case DOCUMENTS_BY_ASSESSMENT_LOAD_ERROR: {
      return {
        documents: {},
        loader: {
          ...loaderInitialState,
          loading: false,
          completed: true,
          error: action.message.toString(),
        },
      };
    }
    case DOCUMENT_APPROVE_SUCCESS: {
      const newState = { ...state };
      newState.documents[action.documentId].status = DocumentStatus.APPROVED;
      return newState;
    }
    case DOCUMENT_CLOSE_SUCCESS: {
      const newState = { ...state };
      newState.documents[action.documentId].status = DocumentStatus.CLOSED;
      return newState;
    }
    case ASSESSMENT_UPDATE_STATUS_SUCCESS: {
      const { assessmentId, status } = action;
      const documents: DocumentListingIndexedItem = { ...state.documents };
      if (status === AssessmentStatus.REOPEN) {
        Object.keys(documents).forEach((id) => {
          const doc = documents[id];
          if (doc.assessmentId === assessmentId) {
            documents[id] = {
              ...doc,
              status: DocumentStatus.CLOSED,
            };
          }
        });
      }
      return { ...state, documents };
    }
    default:
      return state;
  }
};
