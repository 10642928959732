import { Input as InputAntd } from 'antd';
import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { SearchOutlined } from 'src/theme/icons';

export const PrefixIcon = styled(SearchOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grayscale.middleGray};
  margin-right: ${remConvert(10)};
`;

export const Input = styled(InputAntd)`
  background: ${({ theme }): string => theme.colors.white.main} !important;
  border: ${remConvert(1)} solid rgba(0, 0, 0, 0.3) !important;
  border-radius: ${remConvert(4)} !important;
  height: ${remConvert(40)};
`;
