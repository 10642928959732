/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import { QuestionType, RootState } from '../types';

export const getQuestionTypes = (state: RootState): QuestionType[] =>
  state.questionTypes.types;

export const getQuestionTypesByCategory = createSelector(
  (state: RootState) => getQuestionTypes(state),
  (_: RootState, { filter }: { filter: string }) => filter.toLowerCase(),
  (questionsTypes: QuestionType[], filter: string) => {
    const types = questionsTypes.reduce<{ [key: string]: QuestionType[] }>(
      (list, current: QuestionType) => {
        if (filter && current.label.toLowerCase()?.indexOf(filter) === -1) {
          return list;
        }
        const index = current.category
          ? current.category.toLowerCase()
          : 'other';
        if (list[index]) {
          list[index].push(current);
        } else {
          list[index] = [current];
        }
        return list;
      },
      {},
    );
    return types;
  },
);
