import React, { FC } from 'react';
import {
  QuestionChoice,
  QuizQuestion,
  TrainingSubmissionResponse,
} from 'src/store/types';
import * as S from './Styles';
import correctAnswerImg from 'src/assets/TrainingAnswerCorrect.svg';
import incorrectAnswerImg from 'src/assets/TrainingAnswerWrong.svg';
import selectedAnswer from 'src/assets/TrainingAnswerSelected.svg';
import unSelectedAnswer from 'src/assets/TrainingAnswerUnselected.svg';

interface TrainingSubmissionResponsesTableProps {
  currentQuestions: QuizQuestion[];
  currentSubmissionResponses: TrainingSubmissionResponse[];
}

export const TrainingSubmissionResponsesTable: FC<
  TrainingSubmissionResponsesTableProps
> = ({
  currentQuestions,
  currentSubmissionResponses,
}: TrainingSubmissionResponsesTableProps) => {
  if (!currentQuestions || !currentSubmissionResponses) {
    return <>Loading...</>;
  }

  const submissionResponsesMap = currentSubmissionResponses.reduce(
    (
      map: Map<number, TrainingSubmissionResponse>,
      response: TrainingSubmissionResponse,
    ) => {
      map.set(response.id, response);
      return map;
    },
    new Map<number, TrainingSubmissionResponse>(),
  );

  const questionResponsesMap = currentSubmissionResponses.reduce(
    (map: Map<number, boolean>, response: TrainingSubmissionResponse) => {
      map.set(response.questionId, response.isCorrect);
      return map;
    },
    new Map<number, boolean>(),
  );

  const validateIsCorrectQuestion = (questionId: number): boolean => {
    if (!questionResponsesMap.has(questionId)) {
      return false;
    }

    return questionResponsesMap.get(questionId)!;
  };

  return (
    <S.SubmissionResponsesList>
      {currentQuestions.map((q: QuizQuestion) => {
        return (
          <S.SubmissionResponsesListItem key={q.id}>
            <div>
              <S.SubmissionQuestionIconContainer>
                {validateIsCorrectQuestion(q.id) ? (
                  <img src={correctAnswerImg} />
                ) : (
                  <img src={incorrectAnswerImg} />
                )}
              </S.SubmissionQuestionIconContainer>
              <S.SubmissionQuestionText>
                {q.questionText}
              </S.SubmissionQuestionText>
            </div>
            <S.SubmissionQuestionChoicesContainer>
              {q.choices.map((c: QuestionChoice) => {
                const chosenSubmissionResponse = submissionResponsesMap.get(
                  c.id!,
                );

                if (validateIsCorrectQuestion(q.id)) {
                  if (!chosenSubmissionResponse) {
                    return (
                      <S.SubmissionQuestionAnswer key={c.id}>
                        <S.SubmissionQuestionAnswerRadio
                          src={unSelectedAnswer}
                        />
                        {c.choiceText}
                      </S.SubmissionQuestionAnswer>
                    );
                  }

                  return (
                    <S.SubmissionQuestionAnswer key={c.id}>
                      <S.SubmissionQuestionAnswerRadio src={selectedAnswer} />
                      {c.choiceText}
                    </S.SubmissionQuestionAnswer>
                  );
                } else {
                  if (!chosenSubmissionResponse) {
                    if (c.isCorrect) {
                      return (
                        <S.SubmissionQuestionAnswerHighlighted key={c.id}>
                          <S.SubmissionQuestionAnswerRadio
                            src={unSelectedAnswer}
                          />
                          {c.choiceText}
                        </S.SubmissionQuestionAnswerHighlighted>
                      );
                    }

                    return (
                      <S.SubmissionQuestionAnswer key={c.id}>
                        <S.SubmissionQuestionAnswerRadio
                          src={unSelectedAnswer}
                        />
                        {c.choiceText}
                      </S.SubmissionQuestionAnswer>
                    );
                  }

                  return (
                    <S.SubmissionQuestionAnswer key={c.id}>
                      <S.SubmissionQuestionAnswerRadio src={selectedAnswer} />
                      {c.choiceText}
                    </S.SubmissionQuestionAnswer>
                  );
                }
              })}
            </S.SubmissionQuestionChoicesContainer>
          </S.SubmissionResponsesListItem>
        );
      })}
    </S.SubmissionResponsesList>
  );
};
