import { Tooltip } from 'antd';
import React, { FC } from 'react';
import { WarningOutlined } from 'src/theme/icons';
import styled from 'styled-components';

export interface TokenPreviewProps {
  tooltip: string;
  text: string;
  error?: boolean;
}

const Wrapper = styled.span`
  background-color: #efefef;
  border: 1px solid #909090;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  padding: 1px 5px;
  cursor: pointer;
`;

const WrapperError = styled.span`
  background-color: #f8be00;
  border: 1px solid #d35028;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
  padding: 1px 5px;
  font-weight: bold;
  cursor: pointer;
`;

export const TokenPreview: FC<TokenPreviewProps> = ({
  tooltip,
  text,
  error,
}) => {
  if (error) {
    return (
      <WrapperError>
        <Tooltip title={tooltip}>
          <span>
            <WarningOutlined /> {` `}
            {text}
          </span>
        </Tooltip>
      </WrapperError>
    );
  }
  return (
    <Wrapper>
      <Tooltip title={tooltip}>
        <span>{text}</span>
      </Tooltip>
    </Wrapper>
  );
};
