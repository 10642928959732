import {
  Loader,
  CONSTANTS_LOAD_REQUEST,
  CONSTANTS_LOAD_SUCCESS,
  ConstantState,
  ConstantListingIndexedItem,
} from 'src/store/types';
import {
  ConstantListingActionTypes,
  ConstantListingState,
} from '../types/constantListing';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const constantListingInitialState: ConstantListingState = {
  constants: {},
  loader: loaderInitialState,
};

export const constantListingReducer = (
  state = constantListingInitialState,
  action: ConstantListingActionTypes,
): ConstantListingState => {
  switch (action.type) {
    case CONSTANTS_LOAD_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case CONSTANTS_LOAD_SUCCESS: {
      const list = action.constants.reduce(
        (list: ConstantListingIndexedItem, constant: ConstantState) => {
          return {
            ...list,
            [constant.id]: constant,
          };
        },
        {},
      );
      return {
        constants: list,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    default:
      return state;
  }
};
