/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { RootState, SelectedInterviewState } from 'src/store/types';

export const getSelectedInterview = (
  state: RootState,
): SelectedInterviewState => state.selectedInterview;

export const getAnswersSuggestions = (
  state: RootState,
): { [k: string]: string[] } =>
  getSelectedInterview(state).answerSuggestions.suggestions;
