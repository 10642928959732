import {
  InterviewState,
  INTERVIEW_SET_SELECTED,
  COMPANY_LOAD_REQUEST,
  COMPANY_LOAD_SUCCESS,
  COMPANY_LOAD_ERROR,
  Loader,
  InterviewMetadataState,
  InterviewActionsTypes,
  CompanyActionTypes,
  SelectedInterviewState,
  INTERVIEW_UPDATE_SUCCESS,
  INTERVIEW_SAVE_ANSWER,
  INTERVIEW_MARK_ANSWER_AS_DELETED,
  INTERVIEW_SET_QUESTIONS_COUNT,
  INTERVIEW_SET_VISIBLE_QUESTIONS,
  INTERVIEW_CLEAR_SELECTED,
  INTERVIEW_LOAD_ANSWERS_REQUEST,
  INTERVIEW_LOAD_ANSWERS_ERROR,
  INTERVIEW_LOAD_ANSWERS_SUCCESS,
  InterviewAnswerSuggestionsTypes,
  AnswerSuggestionsState,
  AnswerSuggestionsMap,
  Question,
  AssessmentSuggestionsActionTypes,
  ASSESSMENT_LOAD_SUGGESTIONS_REQUEST,
  ASSESSMENT_LOAD_SUGGESTIONS_ERROR,
  ASSESSMENT_LOAD_SUGGESTIONS_SUCCESS,
  INTERVIEW_SAVE_SUCCESS,
  INTERVIEW_ADD_FILE,
  INTERVIEW_REMOVE_FILE,
  INTERVIEW_LOAD_ACTIVITY_LOG_REQUEST,
  ANSWER_LOAD_ACTIVITY_LOG_REQUEST,
  INTERVIEW_LOAD_ACTIVITY_LOG_SUCCESS,
  ANSWER_LOAD_ACTIVITY_LOG_SUCCESS,
  INTERVIEW_LOAD_ACTIVITY_LOG_ERROR,
  ANSWER_LOAD_ACTIVITY_LOG_ERROR,
  INTERVIEW_CREATE_ASSIGNEE_REQUEST,
  INTERVIEW_DELETE_ASSIGNEE_REQUEST,
  INTERVIEW_LOAD_ASSIGNEES_REQUEST,
  INTERVIEW_ASSIGN_OWNER_REQUEST,
  INTERVIEW_REMOVE_OWNER_REQUEST,
  INTERVIEW_CREATE_ASSIGNEE_SUCCESS,
  INTERVIEW_DELETE_ASSIGNEE_SUCCESS,
  INTERVIEW_LOAD_ASSIGNEES_SUCCESS,
  INTERVIEW_ASSIGN_OWNER_SUCCESS,
  INTERVIEW_REMOVE_OWNER_SUCCESS,
  INTERVIEW_CREATE_ASSIGNEE_ERROR,
  INTERVIEW_DELETE_ASSIGNEE_ERROR,
  INTERVIEW_LOAD_ASSIGNEES_ERROR,
  INTERVIEW_ASSIGN_OWNER_ERROR,
  INTERVIEW_REMOVE_OWNER_ERROR,
} from 'src/store/types';
import { combineReducers } from 'redux';
import { answerListReducer } from './answer';
import { activityLogReducer } from './activityLog';
import { questionAssigneeReducer } from './questionAssignee';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const interviewInitialState: InterviewState = {
  createdAt: '',
  updatedAt: '',
  completedAt: '',
  formId: 0,
  creatorId: 0,
  assessmentId: 0,
  ownerId: 0,
  ownerName: '',
  visibleQuestionsIds: [],
};

const interviewMetadataInitialState: InterviewMetadataState = {
  isPristine: true,
  saveCompleted: false,
  deletedAnswers: [],
  questionsCount: 0,
  files: {},
};

const answerSuggestionsInitialState: AnswerSuggestionsState = {
  selectedInterviewSmartAnswers: {},
  suggestions: {},
};

const loaderReducer = (
  state: Loader = loaderInitialState,
  action: CompanyActionTypes,
): Loader => {
  switch (action.type) {
    case COMPANY_LOAD_REQUEST:
    case INTERVIEW_LOAD_ANSWERS_REQUEST:
    case INTERVIEW_LOAD_ACTIVITY_LOG_REQUEST:
    case ANSWER_LOAD_ACTIVITY_LOG_REQUEST:
    case INTERVIEW_CREATE_ASSIGNEE_REQUEST:
    case INTERVIEW_DELETE_ASSIGNEE_REQUEST:
    case INTERVIEW_LOAD_ASSIGNEES_REQUEST:
    case INTERVIEW_ASSIGN_OWNER_REQUEST:
    case INTERVIEW_REMOVE_OWNER_REQUEST:
    case ASSESSMENT_LOAD_SUGGESTIONS_REQUEST: {
      return { ...loaderInitialState, loading: true };
    }
    case COMPANY_LOAD_SUCCESS:
    case INTERVIEW_LOAD_ANSWERS_SUCCESS:
    case INTERVIEW_LOAD_ACTIVITY_LOG_SUCCESS:
    case ANSWER_LOAD_ACTIVITY_LOG_SUCCESS:
    case INTERVIEW_CREATE_ASSIGNEE_SUCCESS:
    case INTERVIEW_DELETE_ASSIGNEE_SUCCESS:
    case INTERVIEW_LOAD_ASSIGNEES_SUCCESS:
    case INTERVIEW_ASSIGN_OWNER_SUCCESS:
    case INTERVIEW_REMOVE_OWNER_SUCCESS:
    case ASSESSMENT_LOAD_SUGGESTIONS_SUCCESS: {
      return { ...loaderInitialState, success: true, completed: true };
    }
    case COMPANY_LOAD_ERROR:
    case INTERVIEW_LOAD_ANSWERS_ERROR:
    case INTERVIEW_LOAD_ACTIVITY_LOG_ERROR:
    case ANSWER_LOAD_ACTIVITY_LOG_ERROR:
    case INTERVIEW_CREATE_ASSIGNEE_ERROR:
    case INTERVIEW_DELETE_ASSIGNEE_ERROR:
    case INTERVIEW_LOAD_ASSIGNEES_ERROR:
    case INTERVIEW_ASSIGN_OWNER_ERROR:
    case INTERVIEW_REMOVE_OWNER_ERROR:
    case ASSESSMENT_LOAD_SUGGESTIONS_ERROR: {
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
      };
    }
    default:
      return state;
  }
};

const interviewReducer = (
  state: InterviewState = interviewInitialState,
  action: InterviewActionsTypes,
): InterviewState => {
  switch (action.type) {
    case INTERVIEW_SAVE_SUCCESS: {
      return { ...action.interview };
    }
    case INTERVIEW_SET_SELECTED: {
      return { ...action.interview };
    }
    case INTERVIEW_CLEAR_SELECTED: {
      return { ...interviewInitialState };
    }
    case INTERVIEW_SET_VISIBLE_QUESTIONS: {
      return { ...state, visibleQuestionsIds: action.ids };
    }
    case INTERVIEW_ASSIGN_OWNER_SUCCESS: {
      return {
        ...state,
        ownerId: action.interview.owner?.id,
        ownerName: action.interview.owner?.name,
      };
    }
    case INTERVIEW_REMOVE_OWNER_SUCCESS: {
      return { ...state, ownerId: undefined, ownerName: undefined };
    }
    default:
      return state;
  }
};

const interviewMetadataReducer = (
  state: InterviewMetadataState = interviewMetadataInitialState,
  action: InterviewActionsTypes,
): InterviewMetadataState => {
  switch (action.type) {
    case INTERVIEW_SET_SELECTED:
    case INTERVIEW_CLEAR_SELECTED: {
      return { ...interviewMetadataInitialState };
    }
    case INTERVIEW_UPDATE_SUCCESS: {
      return {
        ...interviewMetadataInitialState,
        isPristine: true,
        saveCompleted: true,
      };
    }
    case INTERVIEW_SAVE_ANSWER: {
      return { ...state, isPristine: false, saveCompleted: false };
    }
    case INTERVIEW_MARK_ANSWER_AS_DELETED: {
      if (action.answerId && !state.deletedAnswers.includes(action.answerId)) {
        return {
          ...state,
          deletedAnswers: [...state.deletedAnswers, action.answerId],
          isPristine: false,
          saveCompleted: false,
        };
      }
      return state;
    }
    case INTERVIEW_SET_QUESTIONS_COUNT: {
      return { ...state, questionsCount: action.value };
    }
    case INTERVIEW_ADD_FILE: {
      return {
        ...state,
        files: {
          ...state.files,
          [String(action.file.name)]: action.file,
        },
      };
    }
    case INTERVIEW_REMOVE_FILE: {
      const newState = { ...state };
      delete newState.files[String(action.file.name)];
      return newState;
    }
    default:
      return state;
  }
};

const normalizeSuggestions = (
  suggestions: AnswerSuggestionsMap,
): AnswerSuggestionsMap => {
  const keys = Object.keys(suggestions);
  return keys.reduce(
    (res, key) => ({
      ...res,
      [key]: Array.from(new Set(suggestions[key])),
    }),
    {},
  );
};

const answerSuggestionsReducer = (
  state: AnswerSuggestionsState = answerSuggestionsInitialState,
  action:
    | InterviewActionsTypes
    | InterviewAnswerSuggestionsTypes
    | AssessmentSuggestionsActionTypes,
): AnswerSuggestionsState => {
  switch (action.type) {
    case INTERVIEW_SET_SELECTED: {
      return answerSuggestionsInitialState;
    }

    case INTERVIEW_LOAD_ANSWERS_SUCCESS: {
      const smartAnswers = action.answers.filter(
        (answer) => answer.question.isSmart,
      );

      const smartAnswersById = smartAnswers.reduce(
        (answerMap, answer) => ({
          ...answerMap,
          [answer.id]: answer,
        }),
        {},
      );

      const interviewSuggestions = smartAnswers.reduce(
        (suggestions, answer) => {
          if (!answer.value) return suggestions;
          const { key } = answer.question.type;
          return {
            ...suggestions,
            [key]: suggestions[key]
              ? [...suggestions[key], answer.value]
              : [answer.value],
          };
        },
        state.suggestions,
      );

      return {
        selectedInterviewSmartAnswers: smartAnswersById,
        suggestions: normalizeSuggestions(interviewSuggestions),
      };
    }

    case INTERVIEW_SAVE_ANSWER: {
      const key = (
        action.answerData.id
          ? action.answerData.id.toString()
          : action.answerData.internalId
      ) as string;

      const smartAnswersById = {
        ...state.selectedInterviewSmartAnswers,
        [key]: action.answerData,
      };

      const interviewSuggestions = Object.values(smartAnswersById).reduce(
        (suggestions, answer) => {
          if (!answer.value) return suggestions;
          const { key } = (answer.question as Question).type;
          return {
            ...suggestions,
            [key]: suggestions[key]
              ? [...suggestions[key], answer.value]
              : [answer.value],
          };
        },
        state.suggestions,
      );

      return {
        selectedInterviewSmartAnswers: smartAnswersById,
        suggestions: normalizeSuggestions(interviewSuggestions),
      };
    }

    case ASSESSMENT_LOAD_SUGGESTIONS_SUCCESS: {
      const suggestions = Object.keys(action.suggestions).reduce(
        (suggestion, key) => ({
          ...suggestion,
          [key]: suggestion[key]
            ? [...suggestion[key], ...action.suggestions[key]]
            : action.suggestions[key],
        }),
        state.suggestions,
      );

      return {
        selectedInterviewSmartAnswers: {
          ...state.selectedInterviewSmartAnswers,
        },
        suggestions: normalizeSuggestions(suggestions),
      };
    }

    default:
      return state;
  }
};

export const combinedInterviewReducer = combineReducers<SelectedInterviewState>(
  {
    interview: interviewReducer,
    loader: loaderReducer,
    metadata: interviewMetadataReducer,
    answers: answerListReducer,
    questionAssignee: questionAssigneeReducer,
    reconciliation: activityLogReducer,
    answerSuggestions: answerSuggestionsReducer,
  },
);
