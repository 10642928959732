import React from 'react';
import { Table, Empty, Tag } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from 'src/components/Grid';
import { CertificateListingElement, RootState } from 'src/store/types';
import { getUser } from 'src/store/selectors';
import { downloadTrainingCertificateRequest } from 'src/store/actions/certificate';

interface Props {
  dataSource: CertificateListingElement[];
}

const { Column } = Table;
const ExamListingTable = ({ dataSource }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => getUser(state));

  return (
    <Grid
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Certificates will appear here once your annual training is completed."
          />
        ),
      }}
    >
      <Column<CertificateListingElement>
        title="Issued on"
        dataIndex="issuedOn"
        key="issuedOn"
        render={(_, record) => (
          <span>{moment(record.completedAt).format('MM-DD-YYYY')}</span>
        )}
      />
      <Column<CertificateListingElement>
        title="Expires on"
        dataIndex="expiresOn"
        key="expiresOn"
        render={(_, record) => (
          <>
            <Tag color={record.isExpired ? 'error' : 'processing'}>
              {record.isExpired
                ? 'Expired'
                : `12-31-${new Date().getFullYear()}`}
            </Tag>
          </>
        )}
      />
      <Column<CertificateListingElement>
        title="Certificate verification"
        dataIndex="certificateVerification"
        key="certificateVerification"
        render={(_, record: CertificateListingElement) => (
          <a
            style={{ width: '100%' }}
            onClick={() =>
              dispatch(
                downloadTrainingCertificateRequest(
                  record.submissionId || 0,
                  user.name,
                  moment(record.completedAt).format('MM-DD-YYYY'),
                ),
              )
            }
          >
            Download
          </a>
        )}
      />
    </Grid>
  );
};

export default ExamListingTable;
