import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { device } from 'src/theme/device';
import { THIconGear } from 'src/theme/media';

export const PageTitle = styled.h1`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-top: ${remConvert(8)};
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: ${remConvert(16)};
  cursor: pointer;
  text-align: center;

  @media ${device.lg} {
    text-align: right;
  }
`;

export const PageContentBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.md} {
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
  }
`;

export const PageSubTitle = styled.p`
  text-align: center;
`;

export const ProgressMeterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AppFormWrapper = styled.div`
  display: flex;
  justify-content: center;

  background-image: none !important;
  background-repeat: no-repeat;
  background-position: left bottom;

  @media ${device.lg} {
    background-image: url(${THIconGear}) !important;
    background-repeat: no-repeat;
    background-position: left bottom;
  }
`;

export const ProgressMeterWrapper = styled.div`
  margin-right: 0;
  display: flex;
  justify-content: center;

  @media ${device.lg} {
    margin-right: 190px;
  }
`;

export const PageBottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  bottom: 5px;
  justify-content: end;
  margin-bottom: 10px;

  @media ${device.lg} {
    position: absolute;
    width: 100%;
    flex-direction: row;
  }
`;

export const PageContainer = styled.div`
  position: relative;

  background-image: none !important;
  background-repeat: no-repeat;
  background-position: left bottom;

  @media ${device.lg} {
    background-image: url(${THIconGear}) !important;
    background-repeat: no-repeat;
    background-position: left bottom;
  }
`;

export const BottomButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media ${device.lg} {
    margin-top: 0;
  }
`;
