import {
  CommentFromResponse,
  COMMENT_SAVE_ERROR,
  COMMENT_SAVE_SUCCESS,
  COMMENT_SAVE_REQUEST,
  CommentSaveErrorAction,
  CommentSaveRequestAction,
  CommentSaveSuccessAction,
  CommentInput,
  CommentShowSelectedQuestionAction,
  COMMENT_SHOW_QUESTION,
  CommentShowSelectedCommentAction,
  COMMENT_SHOW_THREAD,
  CommentShowSelectedTextAction,
  COMMENT_SHOW_TEXT,
  COMMENT_DELETE_ERROR,
  COMMENT_DELETE_REQUEST,
  COMMENT_DELETE_SUCCESS,
  CommentDeleteErrorAction,
  CommentDeleteRequestAction,
  CommentDeleteSuccessAction,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const commentSaveRequest = (
  comment: Partial<CommentInput>,
): CommentSaveRequestAction => ({
  type: COMMENT_SAVE_REQUEST,
  comment,
});

export const commentSaveSuccess = (
  comment: CommentFromResponse,
): CommentSaveSuccessAction => ({
  type: COMMENT_SAVE_SUCCESS,
  comment,
});

export const commentSaveError = (
  errorMessage: string,
  originalError: Error,
): CommentSaveErrorAction =>
  showErrorMessage(COMMENT_SAVE_ERROR, errorMessage, originalError);

export const commentDeleteRequest = (
  commentId: number,
  assessmentId?: number,
  documentDraftId?: number,
): CommentDeleteRequestAction => ({
  type: COMMENT_DELETE_REQUEST,
  commentId,
  assessmentId,
  documentDraftId,
});

export const commentDeleteSuccess = (
  commentId: number,
): CommentDeleteSuccessAction => ({
  type: COMMENT_DELETE_SUCCESS,
  commentId,
});

export const commentDeleteError = (
  errorMessage: string,
  originalError: Error,
): CommentDeleteErrorAction =>
  showErrorMessage(COMMENT_DELETE_ERROR, errorMessage, originalError);

export const commentShowSelectedQuestion = (
  id: string,
): CommentShowSelectedQuestionAction => ({
  type: COMMENT_SHOW_QUESTION,
  id,
});

export const commentShowSelectedText = (
  id: string,
): CommentShowSelectedTextAction => ({
  type: COMMENT_SHOW_TEXT,
  id,
});

export const commentShowSelectedComment = (
  id: string,
): CommentShowSelectedCommentAction => ({
  type: COMMENT_SHOW_THREAD,
  id,
});
