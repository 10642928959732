/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { Checkbox, Col, Row } from 'antd';
import styled from 'styled-components';
import { Choice, Question, AnswerData, Answer } from 'src/store/types';
import { TextArea } from 'src/components/TextArea';
import { remConvert } from 'src/theme/utils';

interface CheckboxesFieldProps {
  onChange: (value: { other?: string; values: string[] }) => void;
  value: { other?: string; values: string[] };
  question: Question;
  updateAnswer: (answerData: AnswerData) => void;
  deleteAnswer: (answerData: AnswerData) => void;
  answer: Answer;
}

interface CheckboxOption {
  label: string;
  value: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  textarea {
    margin-top: ${remConvert(10)};
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  max-width: 80%;
  .ant-checkbox-wrapper {
    margin: ${remConvert(5)} ${remConvert(0)};

    .ant-checkbox-inner {
      border-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

const WrappedCheckbox = styled(Checkbox)`
  max-width: 95%;
  span:not([class]) {
    word-wrap: break-word;
    max-width: 100%;
  }
`;

export const CheckboxesField: FC<CheckboxesFieldProps> = ({
  onChange,
  value,
  question,
  updateAnswer,
  deleteAnswer,
  answer,
}) => {
  const optionToChoiceId: { [k: string]: number } = {};

  let options: CheckboxOption[] = [];

  if (question.choices) {
    options = question.choices
      .map((c: Choice) => {
        const id = (c.id ? c.id : c.internalId) as number;

        optionToChoiceId[c.label] = id;

        return {
          key: id,
          weight: c.weight,
          label: c.label,
          value: c.label,
        };
      })
      .sort((a, b) => a.weight - b.weight);
  }

  const triggerChange = (changedValues: string[]) => {
    onChange({ values: changedValues });

    if (changedValues.length === 0) {
      return deleteAnswer?.({ id: answer?.id, internalId: answer?.internalId });
    }

    let includeOtherValue = false;

    const choiceIds = changedValues
      .filter((v) => {
        if (v === 'Other') {
          includeOtherValue = true;
          return false;
        }
        return true;
      })
      .map((v) => optionToChoiceId[v]);

    updateAnswer({
      value: JSON.stringify({
        other: includeOtherValue ? value?.other : undefined,
        values: changedValues,
      }),
      choiceIds,
      id: answer?.id,
      internalId: answer?.internalId,
    });
  };

  const onOtherFieldBlur = () => {
    updateAnswer({
      value: JSON.stringify({ other: value.other, values: value.values }),
      choiceIds: value?.values
        .filter((v) => v !== 'Other')
        .map((v) => optionToChoiceId[v]),
      id: answer?.id,
      internalId: answer?.internalId,
    });
  };

  const otherField = () =>
    question.allowExtra &&
    value &&
    value?.values.includes('Other') && (
      <TextArea
        onChange={(e) =>
          onChange({ other: e.target.value || '', values: value?.values })
        }
        onBlur={onOtherFieldBlur}
        value={value?.other}
      />
    );

  const getCheckboxes = () => {
    const checkboxes = options.map(({ label, value }) => (
      <Col key={value} span={8}>
        <WrappedCheckbox value={value}>{label}</WrappedCheckbox>
      </Col>
    ));

    if (question.allowExtra) {
      checkboxes.push(
        <Col span={8} key="other">
          <Checkbox value="Other">Other</Checkbox>
        </Col>,
      );
    }

    return <Row>{checkboxes}</Row>;
  };

  return (
    <Container>
      <CheckboxGroup
        name={question.question}
        onChange={triggerChange as any}
        value={value?.values}
      >
        {getCheckboxes()}
      </CheckboxGroup>
      {otherField()}
    </Container>
  );
};
