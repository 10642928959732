import { Loader } from 'src/store/types';
import {
  TrainingPeriodListingActionTypes,
  TrainingPeriodListingState,
  TRAINING_PERIOD_COMPLETE_SUCCESS,
  TRAINING_PERIOD_CREATE_SUCCESS,
  TRAINING_PERIOD_LISTING_LOAD_FAILED,
  TRAINING_PERIOD_LISTING_LOAD_REQUEST,
  TRAINING_PERIOD_LISTING_LOAD_SUCCESS,
  TRAINING_PERIOD_UPDATE_SUCCESS,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const trainingPeriodListingInitialState: TrainingPeriodListingState = {
  trainingPeriodListingResponse: [],
  loader: loaderInitialState,
};

export const trainingPeriodListingReducer = (
  state = trainingPeriodListingInitialState,
  action: TrainingPeriodListingActionTypes,
): TrainingPeriodListingState => {
  switch (action.type) {
    case TRAINING_PERIOD_LISTING_LOAD_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case TRAINING_PERIOD_LISTING_LOAD_SUCCESS: {
      return {
        trainingPeriodListingResponse: action.trainingPeriodListing,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_PERIOD_CREATE_SUCCESS: {
      return {
        ...state,
        trainingPeriodListingResponse: [
          ...state.trainingPeriodListingResponse,
          action.trainingPeriod,
        ],
      };
    }
    case TRAINING_PERIOD_COMPLETE_SUCCESS:
    case TRAINING_PERIOD_UPDATE_SUCCESS: {
      return {
        ...state,
        trainingPeriodListingResponse: state.trainingPeriodListingResponse.map(
          (period) =>
            period.id === action.trainingPeriod.id
              ? action.trainingPeriod
              : period,
        ),
      };
    }
    case TRAINING_PERIOD_LISTING_LOAD_FAILED: {
      return {
        ...trainingPeriodListingInitialState,
      };
    }
    default:
      return state;
  }
};
