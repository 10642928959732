import {
  ASSESSMENT_SAVE_REQUEST,
  ASSESSMENT_UPDATE_STATUS_SUCCESS,
  COMPANY_LOAD_SUCCESS,
  ASSESSMENT_LOAD_SUCCESS,
  COMPANIES_LOAD_SUCCESS,
  AssessmentFromResponse,
  AssessmentListingState,
  AssessmentListingIndexedItem,
  CompanyActionTypes,
  AssessmentActionTypes,
  DocumentFromResponse,
  CompanyListingActionTypes,
  CompanyFromResponse,
  AssessmentState,
  Loader,
  DocumentStatus,
  DocumentDraftFromResponse,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const assessmentsListingInitialState: AssessmentListingState = {
  assessments: {},
  loader: loaderInitialState,
};

const getLastCreatedDoc = <T extends { createdAt: string }>(
  docs: T[],
): T | undefined => {
  const lastDocument = docs.reduce((lastDoc, doc) => {
    return new Date(doc.createdAt) > new Date(lastDoc.createdAt)
      ? doc
      : lastDoc;
  }, docs[0]);

  return lastDocument;
};

const getDocumentIdForClients = (
  documents: DocumentFromResponse[],
): number | undefined => {
  const lastDoc = getLastCreatedDoc(documents);

  return lastDoc && lastDoc.status !== DocumentStatus.ACTIVE
    ? lastDoc.id
    : undefined;
};

const getLastDocumentDraftIdForClients = (
  documentDrafts: DocumentDraftFromResponse[],
): number | undefined => {
  const lastDoc = getLastCreatedDoc(documentDrafts);

  return lastDoc && lastDoc.status !== DocumentStatus.ACTIVE
    ? lastDoc.id
    : undefined;
};

const normalizeAssessment = (
  assessment: AssessmentFromResponse,
  companyId?: number,
): AssessmentState => {
  const {
    id,
    createdAt,
    updatedAt,
    status,
    type,
    documents,
    totalInterviews,
    completedInterviews,
  } = assessment;

  const data: AssessmentState = {
    id,
    createdAt,
    updatedAt,
    status,
    typeId: type.id,
    totalInterviews,
    completedInterviews,
  };

  if (documents) {
    data.documentsIds = documents.map((d) => d.id);

    data.lastDocumentId = getDocumentIdForClients(documents);

    if (data.lastDocumentId) {
      const documentDrafts = documents.find(
        (d) => d.id === data.lastDocumentId,
      )?.documentDrafts;

      data.lastDocumentDraftId =
        documentDrafts && getLastDocumentDraftIdForClients(documentDrafts);
    }
  }

  if (companyId) {
    data.companyId = companyId;
  }

  return data;
};

export const assessmentListingReducer = (
  state = assessmentsListingInitialState,
  action:
    | CompanyActionTypes
    | AssessmentActionTypes
    | CompanyListingActionTypes,
): AssessmentListingState => {
  switch (action.type) {
    case ASSESSMENT_SAVE_REQUEST: {
      return {
        ...state,
        loader: { ...loaderInitialState, loading: true },
      };
    }
    case COMPANY_LOAD_SUCCESS: {
      const { assessments } = action.company;
      const list = assessments.reduce(
        (
          list: AssessmentListingIndexedItem,
          assessment: AssessmentFromResponse,
        ) => {
          const assessmentData = normalizeAssessment(assessment);
          return {
            ...list,
            [assessment.id]: assessmentData,
          };
        },
        {},
      );
      return { ...state, assessments: list };
    }
    case COMPANIES_LOAD_SUCCESS: {
      const { companies } = action;
      const assessments = companies.reduce(
        (
          assessmentMap: AssessmentListingIndexedItem,
          company: CompanyFromResponse,
        ) => {
          return company.assessments.reduce(
            (partialAssessmentMap, assessment) => {
              const assessmentData = normalizeAssessment(
                assessment,
                company.id,
              );
              partialAssessmentMap[assessment.id] = assessmentData;
              return partialAssessmentMap;
            },
            assessmentMap,
          );
        },
        { ...state.assessments },
      );
      return { ...state, assessments };
    }
    case ASSESSMENT_LOAD_SUCCESS: {
      const { assessment } = action;
      const list = { ...state.assessments };
      const assessmentData = normalizeAssessment(
        assessment,
        assessment.company?.id,
      );
      list[assessment.id] = assessmentData;
      return {
        assessments: list,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case ASSESSMENT_UPDATE_STATUS_SUCCESS: {
      const { assessmentId, status } = action;
      const list = { ...state.assessments };
      if (list[assessmentId]) {
        list[assessmentId] = {
          ...list[assessmentId],
          status,
        };
      }
      return { ...state, assessments: list };
    }
    default:
      return state;
  }
};
