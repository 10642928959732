import React, { FC } from 'react';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { Menu as MenuAntD, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SettingOutlined } from 'src/theme/icons';
import { templateDeleteRequest } from 'src/store/actions/template';
import { confirmDialog } from 'src/shared/utils';

export interface TemplateSettingsProps {
  templateId: number;
  showCloneTemplatePanel: (templateId: number, onlyDuplicate: boolean) => void;
}

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  width: 180px;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

const Menu = styled(MenuAntD)`
  border-radius: 4px;
  margin-top: -35px;
  margin-left: 12px;
  width: auto;

  .ant-dropdown-menu-item:hover {
    &.title {
      background-color: white;
      cursor: default;
    }
  }

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightBlue.main}`};

    > span {
      font-weight: 600;
    }

    .title-icon {
      color: ${({ theme }) => theme.colors.lightBlue.main};
      font-size: 20px;
    }
  }

  .title-divider {
    color: red;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0px 4px;
    width: auto;
  }

  span {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }
`;

const menu = (
  id: number,
  dispatch: Dispatch,
  showCloneTemplatePanel: (templateId: number, onlyDuplicate: boolean) => void,
) => (
  <Menu>
    <Menu.Item className="title" key="0">
      <span>Settings</span>
      <SettingOutlined className="title-icon" />
    </Menu.Item>
    <Menu.Item key="1">
      <Link to={`/templates/edit/${id}`}>
        <span>Edit</span>
      </Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item
      key="2"
      onClick={() =>
        confirmDialog({
          text: 'This action will duplicate the template along with all the variables associated with it.',
          onOk: () => showCloneTemplatePanel(id, true),
        })
      }
    >
      <span>Duplicate</span>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item
      key="3"
      onClick={() =>
        confirmDialog({
          text: 'This action will create a new template based on this one along with all the variables associated with it.',
          onOk: () => showCloneTemplatePanel(id, false),
        })
      }
    >
      <span>Create new template based on this one</span>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item
      key="4"
      onClick={() =>
        confirmDialog({
          text: 'Are you sure you want to delete this template?',
          onOk: () => {
            dispatch(templateDeleteRequest(id));
          },
        })
      }
    >
      <span>Delete</span>
    </Menu.Item>
  </Menu>
);

export const TemplateSettings: FC<TemplateSettingsProps> = ({
  templateId,
  showCloneTemplatePanel,
}) => {
  const dispatch = useDispatch();

  return (
    <Dropdown
      overlay={menu(templateId, dispatch, showCloneTemplatePanel)}
      trigger={['click']}
    >
      <DropdownIcon>
        <SettingOutlined />
      </DropdownIcon>
    </Dropdown>
  );
};
