import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { TopNavbar } from 'src/components/TopNavbar';
import { DocumentDraftListingTable } from 'src/features/risk-assessment/components/DocumentDraftListingTable';
import { SearchInput } from 'src/components/SearchInput';
import { companiesLoadRequest } from 'src/store/actions/companyListing';
import { RootState, SelectedDocumentDraftState } from 'src/store/types';
import {
  getAssessmentById,
  getIndexedDocumentsDraftsFromState,
  getSelectedDocumentDraft,
  getTemplatesFromState,
} from 'src/store/selectors';
import { Button } from 'src/components/Button';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { documentDraftsLoadRequest } from 'src/store/actions/documentDraftListing';
import { FormCreateDocumentDraftFromTemplate } from 'src/features/risk-assessment/components/FormCreateDocumentDraftFromTemplate';
import { templatesLoadRequest } from 'src/store/actions/templateListing';
import { useAuth } from 'src/hooks/useAuth/useAuth';

interface RouteParams {
  id: string;
  assessmentId: string;
  documentId: string;
}

const CompanyListContainer = styled.div`
  height: 65%;
  overflow: auto;
  margin-bottom: 10px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const DocumentDraftListingPage: FC<unknown> = () => {
  const { isAdmin, isCompanyModerator } = useAuth();
  const {
    id: rawCompanyId,
    assessmentId: rawAssessmentId,
    documentId: rawDocumentId,
  } = useParams<RouteParams>();
  const companyId = +rawCompanyId;
  const assessmentId = +rawAssessmentId;
  const documentId = +rawDocumentId;

  const [createDocumentDraftPanelVisible, setCreateDocumentDraftPanelVisible] =
    useState<boolean>(false);
  const [creatingDocumentDraft, setCreatingDocumentDraft] = useState(false);
  const [documentDraftTitle, setDocumentDraftTitle] = useState<string>('');

  const documentDrafts = useSelector((state: RootState) =>
    getIndexedDocumentsDraftsFromState(state, documentDraftTitle, !isAdmin),
  );
  const { documentDraft, loader: documentDraftLoader } = useSelector(
    (state: RootState): SelectedDocumentDraftState =>
      getSelectedDocumentDraft(state),
  );
  const templates = useSelector(getTemplatesFromState);
  const assessment = useSelector((state: RootState) =>
    getAssessmentById(state, assessmentId),
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(documentDraftsLoadRequest(documentId));
    if (isCompanyModerator) {
      dispatch(companiesLoadRequest());
    }
  }, []);

  useEffect(() => {
    if (assessment && isAdmin) {
      dispatch(templatesLoadRequest(assessment.typeId));
    }
  }, [assessment]);

  useEffect(() => {
    if (
      documentDraftLoader.completed &&
      documentDraftLoader.success &&
      creatingDocumentDraft
    ) {
      setCreateDocumentDraftPanelVisible(false);
      setCreatingDocumentDraft(false);
      const documentDraftId = documentDraft?.id;
      history.push(
        `/companies/${companyId}/assessments/${assessmentId}/assessmentReports/${documentId}/documentDrafts/${documentDraftId}`,
      );
    }
  }, [documentDraftLoader]);

  const [documentForm] = Form.useForm();

  const handleCreateNewDocumentDraftButtonPress = () => {
    documentForm.submit();
  };

  return (
    <>
      <TopNavbar
        title="Documents Drafts"
        prevRoute={{
          name: 'Assessment Report',
          url: `/companies/${companyId}/assessments/${assessmentId}/assessmentReports/${documentId}`,
        }}
        extraOptions={
          isAdmin
            ? [
                <Button
                  onClick={() => setCreateDocumentDraftPanelVisible(true)}
                  title="Create new document draft"
                  key={1}
                  loading={documentDraftLoader.loading}
                />,
              ]
            : []
        }
        withCompany
      />
      <PageContentContainer>
        <SearchInput
          onChange={(e) => {
            setDocumentDraftTitle(e.target.value);
          }}
          placeholder="Document draft title"
        />
        <CompanyListContainer>
          <DocumentDraftListingTable
            companyId={companyId}
            documentDrafts={documentDrafts}
            assessmentId={assessmentId}
            documentId={documentId}
          />
        </CompanyListContainer>
        <SlidingPanel
          title="Create new document draft"
          visible={createDocumentDraftPanelVisible}
          saveLabel="Create"
          onClose={() => {
            setCreateDocumentDraftPanelVisible(false);
            documentForm.resetFields();
          }}
          onSave={handleCreateNewDocumentDraftButtonPress}
          loading={creatingDocumentDraft}
        >
          <FormCreateDocumentDraftFromTemplate
            baseDocumentId={documentId}
            documentForm={documentForm}
            templates={templates}
            setCreatingDocumentDraft={setCreatingDocumentDraft}
          />
        </SlidingPanel>
      </PageContentContainer>
    </>
  );
};
