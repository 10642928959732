import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Form, Checkbox, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Breadcrumb } from 'src/components/Breadcrumb';
import { TextInput } from 'src/components/TextInput';
import { TextArea } from 'src/components/TextArea';
import {
  Choice,
  Condition,
  Question,
  RiskCondition,
  QuestionTypes,
} from 'src/store/types';
import { ConditionalRulesetFormValue, RulesSettings } from '.';
import { ChoicesSettings } from './ChoicesSettings';
import { FileUploadSettings } from './FieldUploadSettings';
import { RiskConditionsSettings } from './RiskConditionsSettings';
import { TipsSettings } from './TipsSettings';

interface FormFieldSettingsProps {
  children?: ReactNode[];
  form: FormInstance;
  isRequired?: boolean;
  isMultiple?: boolean;
  allowsExtra?: boolean;
  onSubmit: (question: Partial<Question>) => void;
  onBackPress: () => void;
  question: Question;
}

export interface FieldSettingsForm {
  description: string;
  question: string;
  required: boolean;
  multiple: boolean;
  allowExtra: boolean;
  tooltip?: string;
  moreInfo?: string;
  hasConditions?: boolean;
  conditions: any[];
  conditionAction?: string;
  conditionConnector?: string;
  choices: Choice[];
  min?: number;
  max?: number;
  legalRef?: string;
  legalRefCmmc?: string;
  legalRefNist?: string;
  riskConditions?: RiskCondition[];
  extension?: 'image' | 'text' | 'all';
  isSmart: boolean;
}

const SettingsContainer = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0px;

  .ant-form-item-explain {
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  .breadcrumb {
    align-self: flex-start;
    padding: 0px;
    height: 28px;
    margin-bottom: 10px;
  }

  .input {
    margin-bottom: 12px;
    width: 100%;
    &:focus {
      border-color: #40a9ff;
    }
  }

  .formItem {
    width: 100%;
    margin-bottom: 0px;
  }

  .ant-row.ant-form-item {
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .conditionRules {
    width: 100%;
  }
`;

export const ItemContainer = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 12px 16px;
  width: 100%;

  .ant-checkbox-wrapper {
    margin: 5px 0px;

    > span {
      color: ${({ theme }) => theme.colors.grayscale.preDark};
      font-family: ${({ theme }) => theme.fontFamilies.primary};
      font-size: 14px;
    }
  }

  > span {
    color: ${({ theme }) => theme.colors.grayscale.preDark};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 12px;
  }
`;

const Field = Form.Item;

export const FormFieldSettings: FC<FormFieldSettingsProps> = ({
  children,
  form,
  onSubmit,
  onBackPress,
  question,
}) => {
  const questionType = question.type;

  const handleSubmit = (values: FieldSettingsForm) => {
    // Conditions
    let conditions: Condition[] = [];
    if (values.conditions) {
      conditions = values.conditions.map((c: ConditionalRulesetFormValue) => {
        return {
          questionId: question.id as number,
          subjectId: c.subject,
          condition: c.condition,
          comparator: c.comparator,
          choiceId: c.choiceId ?? c.choice?.id,
          connector: values.conditionConnector === 'any' ? 'OR' : 'AND',
          effect: values.conditionAction,
        };
      });
    }

    let riskConditions: RiskCondition[] = [];
    if (values.riskConditions) {
      riskConditions = values.riskConditions.map(
        (rc: Partial<RiskCondition>) => {
          return {
            ...rc,
            questionId: question.id as number,
          };
        },
      );
    }

    // Choices
    if (values.choices?.length === 0) {
      void message.error('Please add at least one choice');
      return;
    }

    let choices: Choice[] = [];
    if (values.choices) {
      choices = values.choices.map((c: Choice) => ({
        ...c,
        questionId: question.id as number,
      }));
    }

    const tempQuestion = {
      description: values.description,
      question: values.question,
      required: values.required,
      multiple: values.multiple,
      allowExtra: values.allowExtra,
      tooltip: values?.tooltip,
      moreInfo: values?.moreInfo,
      conditions,
      choices,
      min: values?.min ? Number(values.min) : undefined,
      max: values?.max ? Number(values.max) : undefined,
      extension: values?.extension,
      legalRef: values?.legalRef,
      legalRefCmmc: values?.legalRefCmmc,
      legalRefNist: values?.legalRefNist,
      riskConditions,
      isSmart: !!values?.isSmart,
    };

    onSubmit(tempQuestion);
  };

  const showChoicesMenu = () => {
    switch (questionType.key) {
      case QuestionTypes.CHECKBOX:
      case QuestionTypes.RADIO:
      case QuestionTypes.SELECT:
      case QuestionTypes.YES_NO:
        return true;
      default:
        return false;
    }
  };

  const showRiskConditionsMenu = () => {
    switch (questionType.key) {
      case QuestionTypes.NUMBER:
      case QuestionTypes.INPUT:
      case QuestionTypes.PARAGRAPH:
      case QuestionTypes.DATE:
      case QuestionTypes.EMAIL:
      case QuestionTypes.WEBSITE:
      case QuestionTypes.PHONE:
      case QuestionTypes.NAME:
        return true;
      default:
        return false;
    }
  };

  const showRulesMenu = () => {
    switch (questionType.key) {
      case QuestionTypes.TIPS:
      case QuestionTypes.VIDEO:
        return false;
      default:
        return true;
    }
  };

  const showRangeMenu = () => questionType.key === QuestionTypes.NUMBER;

  const showFileMenu = () => questionType.key === QuestionTypes.FILE;

  const showTipsMenu = () => questionType.key === QuestionTypes.TIPS;

  const showVideoMenu = () => questionType.key === QuestionTypes.VIDEO;

  const showMoreInfo = () =>
    questionType.key === QuestionTypes.TIPS ||
    questionType.key === QuestionTypes.VIDEO;

  return (
    <SettingsContainer
      form={form}
      onFinish={(values) => handleSubmit(values as FieldSettingsForm)}
    >
      <Breadcrumb
        altered
        className="breadcrumb"
        text={questionType?.label || 'Back'}
        onClick={onBackPress}
      />
      {!showTipsMenu() && !showVideoMenu() && (
        <>
          <Field
            className="formItem"
            name="question"
            rules={[
              { required: true, message: 'Please write the question title' },
            ]}
          >
            <TextArea className="input" placeholder="Title" autoSize />
          </Field>
          <Field
            name="description"
            className="formItem"
            rules={[{ required: false }]}
          >
            <TextArea className="input" placeholder="Description" rows={6} />
          </Field>
          {questionType.canBeSmart && (
            <Field
              name="isSmart"
              className="formItem"
              rules={[{ required: false }]}
              valuePropName="checked"
            >
              <Checkbox className="input">Is Smart</Checkbox>
            </Field>
          )}
          <Field name="tooltip" noStyle rules={[{ required: false }]}>
            <TextInput className="input" placeholder="Tooltip" />
          </Field>
          {showMoreInfo() && (
            <Field name="moreInfo" noStyle rules={[{ required: false }]}>
              <TextInput className="input" placeholder="More Info" />
            </Field>
          )}
          <Field name="legalRef" noStyle rules={[{ required: false }]}>
            <TextInput className="input" placeholder="HIPAA reference" />
          </Field>
          <Field name="legalRefCmmc" noStyle rules={[{ required: false }]}>
            <TextInput className="input" placeholder="CMMC reference" />
          </Field>
          <Field name="legalRefNist" noStyle rules={[{ required: false }]}>
            <TextInput className="input" placeholder="NIST reference" />
          </Field>
        </>
      )}
      {showVideoMenu() && (
        <>
          <Field
            className="formItem"
            name="question"
            rules={[
              { required: false, message: 'Please write the video title' },
            ]}
          >
            <TextInput className="input" placeholder="Title" />
          </Field>
          <Field
            className="formItem"
            name="moreInfo"
            rules={[
              { required: true, message: 'Please write the video URL here' },
            ]}
          >
            <TextInput className="input" placeholder="Video url" />
          </Field>
        </>
      )}
      {showRangeMenu() && (
        <ItemContainer>
          <span>Range</span>
          <Field
            className="formItem"
            name="min"
            rules={[
              {
                required: false,
                type: 'number',
                transform: (value) => (value ? Number(value) : 0),
                message: 'Must be a number in valid range',
              },
            ]}
          >
            <TextInput className="input" placeholder="Min value" numeric />
          </Field>
          <Field
            className="formItem"
            name="max"
            rules={[
              {
                required: false,
                type: 'number',
                transform: (value) => (value ? Number(value) : undefined),
                message: 'Must be a number in valid range',
              },
            ]}
          >
            <TextInput className="input" placeholder="Max value" />
          </Field>
        </ItemContainer>
      )}
      {showFileMenu() && <FileUploadSettings />}
      {showTipsMenu() && (
        <ItemContainer>
          <span>Tips</span>
          <Field
            name="choices"
            noStyle
            rules={[{ required: false }]}
            shouldUpdate
          >
            <TipsSettings />
          </Field>
        </ItemContainer>
      )}
      {showChoicesMenu() && (
        <ItemContainer>
          <span>Choices</span>
          <Field
            name="choices"
            noStyle
            rules={[{ required: false }]}
            shouldUpdate
          >
            <ChoicesSettings />
          </Field>
        </ItemContainer>
      )}
      {showRiskConditionsMenu() && (
        <ItemContainer>
          <span>Risk Conditions</span>
          <Field
            name="riskConditions"
            noStyle
            rules={[{ required: false }]}
            shouldUpdate
          >
            <RiskConditionsSettings
              fieldType={questionType.key as QuestionTypes}
            />
          </Field>
        </ItemContainer>
      )}
      {showRulesMenu() && <RulesSettings question={question} />}

      {React.Children.map(
        children,
        (child) =>
          child &&
          React.cloneElement(child as any, {
            className: 'input',
          }),
      )}
    </SettingsContainer>
  );
};
