import React, { FC, useState, useEffect } from 'react';
import { AutoComplete as AutoCompleteAntd } from 'antd';
import styled from 'styled-components';
import { Question, QuestionList } from 'src/store/types';

export interface AutocompleteProps {
  handleQuestionChange: (selectValue: number) => void;
  questions: QuestionList;
  initialTextValue: string;
  className?: string;
}

const FormLabel = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.lightBlue.main};
`;

export const AutoComplete: FC<AutocompleteProps> = ({
  handleQuestionChange,
  questions,
  initialTextValue,
  className,
}) => {
  const [results, setResults] = useState<Question[]>([]);
  const [textValue, setTextValue] = useState<string>(initialTextValue);

  useEffect(() => {
    setTextValue(initialTextValue);
  }, [initialTextValue]);

  const { Option } = AutoCompleteAntd;

  const handleSearch = (valueToSearch: string) => {
    const filterResults = questions.filter(
      (q: Question) =>
        q.question.toLowerCase()?.indexOf(valueToSearch.toLowerCase()) >= 0,
    );
    setResults(filterResults);
  };

  const handleOnSelect = (_: string, { key: questionId }: { key: string }) => {
    handleQuestionChange(Number(questionId));
    setTextValue('');
  };

  return (
    <AutoCompleteAntd
      onSearch={handleSearch}
      onSelect={handleOnSelect as any}
      placeholder="Search question"
      onChange={(value: string) => setTextValue(value)}
      value={textValue}
      className={className}
    >
      {results.map(({ id, question, formLabel, internalId }: Question) => (
        <Option
          key={id || internalId}
          value={id ? question : '(Unsaved question)'}
          disabled={!id}
        >
          {id ? (
            <>
              <FormLabel>{formLabel}</FormLabel>
              {question}
            </>
          ) : (
            '(Unsaved question)'
          )}
        </Option>
      ))}
    </AutoCompleteAntd>
  );
};
