import React, { FC } from 'react';
import { ProgressMeter } from 'src/components/ProgressMeter';
import { TwoFASetup } from 'src/features/auth/components';
import { useAuth } from 'src/hooks/useAuth';
import * as S from './Styles';

export const TwoFATurnOnPage: FC<unknown> = () => {
  const { hasPendingOnboarding } = useAuth();
  return (
    <>
      <S.PageTitle>Two-step verification</S.PageTitle>
      <S.SubTitle>
        Pick a two-step verification method to keep your account safe.
      </S.SubTitle>
      <S.PageContentContainer>
        <TwoFASetup />
      </S.PageContentContainer>
      <S.ProgressMeterContainer>
        <ProgressMeter hasOnboarding={hasPendingOnboarding} step={4} />
      </S.ProgressMeterContainer>
    </>
  );
};
