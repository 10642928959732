import React, { FC } from 'react';
import * as S from './Styles';

interface SimpleRadialChart {
  progress: number;
  dimension?: number;
}

export const SimpleRadialChart: FC<SimpleRadialChart> = ({
  progress,
  dimension,
}) => {
  const circleRadius = 80;
  const circumference = 2 * Math.PI * circleRadius;
  const circleDimension = dimension || 180;
  const strokeWidth = 20;
  const strokeLength = (circumference / 100) * progress;

  return (
    <>
      <S.SimpleRadialChartContainer>
        <svg
          viewBox="0 0 180 180"
          width={circleDimension}
          height={circleDimension}
        >
          <circle
            cx="90"
            cy="90"
            r="80"
            fill="none"
            stroke="#E66F6F"
            strokeWidth={strokeWidth}
          />
          <circle
            cx="90"
            cy="90"
            r="80"
            fill="none"
            stroke="#8CCCAD"
            strokeWidth={strokeWidth}
            strokeDasharray={`${strokeLength},${circumference}`}
            strokeLinecap="round"
          />
          <text
            className="radial-percent-text"
            x="50%"
            y="35%"
            textAnchor="middle"
            fill="#666666"
          >
            You scored
          </text>
          <text
            className="radial-percent-number"
            x="50%"
            y="65%"
            textAnchor="middle"
            fill="#666666"
          >
            {Math.round(progress)}%
          </text>
        </svg>
      </S.SimpleRadialChartContainer>
    </>
  );
};
