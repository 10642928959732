import React, { FC } from 'react';
import { Divider } from 'antd';
import { Question } from 'src/store/types';
import styled from 'styled-components';
import { QuestionFactoryType } from '..';

interface SectionFieldProps {
  question: Question;
  factoryType: QuestionFactoryType;
}

const ParagraphText = styled.p`
  color: ${({ theme }) => theme.colors.grayscale.preDark};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 12px;
  font-weight: 600;
  margin: 10px 0px;
`;

export const SectionField: FC<SectionFieldProps> = ({
  question,
  factoryType,
}) => (
  <>
    <Divider orientation="left">
      {factoryType === QuestionFactoryType.interview
        ? question?.question || 'Section'
        : ''}
    </Divider>
    {factoryType === QuestionFactoryType.interview ? (
      <ParagraphText>{question?.description || ''}</ParagraphText>
    ) : null}
  </>
);
