import React, { FC } from 'react';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { Menu as MenuAntD, Dropdown, message } from 'antd';
import { useDispatch } from 'react-redux';
import { SettingOutlined } from 'src/theme/icons';
import {
  documentApproveRequest,
  documentCloseRequest,
} from 'src/store/actions/document';
import { api } from 'src/api/services/ThApi';
import { confirmDialog } from 'src/shared/utils';

export interface DocumentListingSettingsProps {
  documentId: number;
}

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-end;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

const Menu = styled(MenuAntD)`
  border-radius: 4px;
  margin-top: -35px;
  margin-left: 12px;
  width: 180px;

  .ant-dropdown-menu-item:hover {
    &.title {
      background-color: white;
      cursor: default;
    }
  }

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightBlue.main}`};

    > span {
      font-weight: 600;
    }

    .title-icon {
      color: ${({ theme }) => theme.colors.lightBlue.main};
      font-size: 20px;
    }
  }

  .title-divider {
    color: red;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0px 4px;
    width: 172px;
  }

  span {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }
`;

const handleDownloadFiles = async (documentId: number) => {
  try {
    await api.getDocumentFiles(documentId);
  } catch (err) {
    void message.error(
      "There's not any uploaded files in this report's interviews",
    );
  }
};

const menu = (documentId: number, dispatch: Dispatch) => (
  <Menu>
    <Menu.Item
      key="0"
      onClick={() =>
        confirmDialog({
          text: 'Are you sure you want to approve this document?',
          onOk: () => {
            dispatch(documentApproveRequest(documentId));
          },
        })
      }
    >
      <span>Approve</span>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item
      key="1"
      onClick={() =>
        confirmDialog({
          text: 'Are you sure you want to close this document?',
          onOk: () => {
            dispatch(documentCloseRequest(documentId));
          },
        })
      }
    >
      <span>Close</span>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="2" onClick={async () => handleDownloadFiles(documentId)}>
      <span>Download uploaded files</span>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3" onClick={async () => api.getDocumentRawFile(documentId)}>
      <span>Download Raw data</span>
    </Menu.Item>
    <Menu.Divider />
  </Menu>
);

export const DocumentListingSettings: FC<DocumentListingSettingsProps> = ({
  documentId,
}) => {
  const dispatch = useDispatch();

  return (
    <Dropdown overlay={menu(documentId, dispatch)} trigger={['click']}>
      <DropdownIcon>
        <SettingOutlined />
      </DropdownIcon>
    </Dropdown>
  );
};
