import React, { FC } from 'react';
import styled from 'styled-components';
import {
  BasicFormattingItemInterface,
  basicFormattingItems,
} from './HelpSectionBasicFormatting';
import { HelpSection } from './HelpSection';

interface MarkdownHelpSection {
  markdownHelpOpen: boolean;
  setMarkdownHelpOpen: (value: boolean) => void;
}

const HelpSectionItem = styled.div`
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-family: monospace;
`;

export const MarkdownHelpSection: FC<MarkdownHelpSection> = ({
  markdownHelpOpen,
  setMarkdownHelpOpen,
}) => (
  <HelpSection
    isOpen={markdownHelpOpen}
    setIsOpen={setMarkdownHelpOpen}
    title={'Basic Markdown Formatting'}
  >
    {basicFormattingItems.map(
      ({ title, content }: BasicFormattingItemInterface) => (
        <>
          <h4>{title}</h4>
          <HelpSectionItem>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </HelpSectionItem>
        </>
      ),
    )}
  </HelpSection>
);
