import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table as TableAntd,
  Form,
  Input,
  Button,
  Popconfirm,
  Tooltip,
  Modal,
  Checkbox,
} from 'antd';
import { getVariables } from 'src/store/selectors';
import { VariableInput, VariableState } from 'src/store/types';
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from 'src/theme/icons';
import { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import {
  variableDeleteRequest,
  variableSaveRequest,
  variableUpdateRequest,
} from 'src/store/actions/variable';
import { IconButton } from 'src/components/Button';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface TemplateEditorVariableSelectorWindowProps {
  onSelection: (id: number) => void;
  onClose: () => void;
  templateId: number;
}

const Container = styled.div`
  width: 100%;
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: baseline;

  button {
    text-align: right;
    font-size: 14px;
    height: auto;
    margin-left: 2px;
  }
`;

const ActionRow = styled.div`
  button {
    margin-right: 5px;
  }
`;

const Table = styled<any>(TableAntd)`
  tr {
    th {
      font-size: 14px;

      &:last-child {
        text-align: right;
      }
    }
    td {
      font-size: 13px;
      .actions {
        text-align: right;
        button {
          font-size: 13px;
        }
      }
    }
  }
`;

export const TemplateEditorVariableSelectorWindow: FC<
  TemplateEditorVariableSelectorWindowProps
> = ({ templateId, onSelection, onClose }) => {
  const [form] = useForm();
  const [value, setValue] = useState<any>([]);
  const [showForm, setShowForm] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState<
    VariableState | undefined
  >(undefined);
  const [isList, setIsList] = useState<boolean>(false);

  const [editingId, setEditingId] = useState(0);

  const variables = useSelector(getVariables);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(Object.values(variables));
  }, [variables]);

  const getVariableAsFormData = (variable: VariableState) => {
    if (variable.isList) {
      return {
        ...variable,
        content: variable.content.split(','),
      };
    }
    return variable;
  };

  const handleSelection = (id: number) => {
    if (id) {
      onSelection(id);
      onClose();
    }
  };

  const handleEdit = (variable: VariableState) => {
    setShowForm(true);
    setEditingId(variable.id);
    setIsList(!!variable.isList);
    form.setFieldsValue(getVariableAsFormData(variable));
  };

  const handleAdd = () => {
    form.resetFields();
    setIsList(false);
    setShowForm(true);
  };

  const getVariableContentFromFormData = (
    isList: boolean,
    content: string[] | string,
  ): string =>
    isList && Array.isArray(content) ? content.join(',') : (content as string);

  const handleFinish = () => {
    const values = form.getFieldsValue() as {
      content: string | string[];
      isList: boolean;
    };

    if (editingId === 0) {
      dispatch(
        variableSaveRequest({
          ...values,
          templateId: templateId,
          content: getVariableContentFromFormData(
            values.isList,
            values.content,
          ),
        } as VariableInput),
      );
    } else {
      dispatch(
        variableUpdateRequest(editingId, {
          ...(values as VariableInput),
          content: getVariableContentFromFormData(
            values.isList,
            values.content,
          ),
        }),
      );
    }
    cleanUp();
  };

  const handleDelete = (id: number) => {
    dispatch(variableDeleteRequest(id));
  };

  const handlePreview = (variable: VariableState) => {
    setShowPreviewModal(true);
    setSelectedVariable(variable);
  };

  const cleanUp = () => {
    setShowForm(false);
    setEditingId(0);
    setSelectedVariable(undefined);
  };

  const columns: ColumnsType<VariableState> = [
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text: string, variable: VariableState) => {
        return (
          <td>
            <Tooltip title={variable.content}>
              <span>{text}</span>
            </Tooltip>
          </td>
        );
      },
    },
    {
      title: 'Actions',
      render: (variable: VariableState) => {
        return (
          <div className="actions">
            <IconButton
              onClick={() => handleSelection(variable.id)}
              icon={<CheckOutlined />}
            />
            <IconButton
              onClick={() => handlePreview(variable)}
              icon={<EyeOutlined />}
            />
            <IconButton
              onClick={() => handleEdit(variable)}
              icon={<EditOutlined />}
            />
            <Popconfirm
              title="Are you sure to delete this variable?"
              onConfirm={() => {
                handleDelete(variable.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <IconButton icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      {!showForm && (
        <>
          <Title onClick={() => handleAdd()}>
            <span>Add New</span>
            <IconButton icon={<PlusOutlined />} />
          </Title>
          <Table columns={columns} dataSource={value} size="small" />
          <Modal
            title={selectedVariable ? selectedVariable.description : ''}
            visible={showPreviewModal}
            onOk={() => {
              onSelection(selectedVariable ? selectedVariable.id : 0);
              onClose();
              setShowPreviewModal(false);
            }}
            onCancel={() => {
              setShowPreviewModal(false);
            }}
            okText="Add to template"
          >
            <pre>{selectedVariable && selectedVariable.content}</pre>
          </Modal>
        </>
      )}
      {showForm && (
        <Form onFinish={handleFinish} form={form}>
          <Form.Item name="description" rules={[{ required: true }]}>
            <Input placeholder="Description" />
          </Form.Item>
          <Form.Item name="isList" valuePropName="checked">
            <Checkbox
              checked={isList}
              onChange={(e: CheckboxChangeEvent) => setIsList(e.target.checked)}
            >
              List
            </Checkbox>
          </Form.Item>
          {isList ? (
            <Form.List
              name="content"
              rules={[
                {
                  validator: async (_, content: string[]) => {
                    if (!content || content.length < 1) {
                      return Promise.reject(new Error('At least 1 element'));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      label={`${index + 1}:`}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              'Please input element or delete this field.',
                          },
                        ]}
                        noStyle
                      >
                        <Input placeholder="Content" style={{ width: '60%' }} />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusSquareOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '60%' }}
                      icon={<PlusSquareOutlined />}
                    >
                      Add field
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          ) : (
            <Form.Item name="content">
              <Input.TextArea placeholder="Content to replace" />
            </Form.Item>
          )}

          <ActionRow>
            <Button type="primary" htmlType="submit" size="small">
              Submit
            </Button>
            <Button
              type="default"
              htmlType="button"
              onClick={() => {
                cleanUp();
              }}
              size="small"
            >
              Cancel
            </Button>
          </ActionRow>
        </Form>
      )}
    </Container>
  );
};
