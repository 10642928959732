import React, { FC } from 'react';
import styled from 'styled-components';

import { Table as TableAntd } from 'antd';
import { TableProps } from 'antd/es/table';

export const Grid: FC<TableProps<any>> = styled(TableAntd)`
  .ant-table table {
    background: ${({ theme }) => theme.colors.grayscale.lightGray};
    border-spacing: 0 8px;
    .ant-table-thead > tr > th {
      background: ${({ theme }) => theme.colors.grayscale.lightGray};
      color: ${({ theme }) => theme.colors.grayscale.preDark};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      border: unset;
      font-size: 12px;
      letter-spacing: 0.05em;
      line-height: 16px;
      padding-bottom: 8px;
    }

    .ant-table-tbody {
      tr.ant-table-row {
        td {
          background: ${({ theme }) => theme.colors.grayscale.white};
          font-size: 14px;
          padding: 18px 16px;
        }

        td:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        td:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
`;
