export enum RoutesEnum {
  HOME = '/',
  PROFILE = '/profile',
  LOGIN = '/login',
  TWO_FA_TURN_ON = '/2fa/turn-on',
  TWO_FA_AUTH = '/2fa/auth',
  DASHBOARD = '/dashboard',
  RISK_ASSESSMENT = '/risk-assessment',
  COMPANIES = '/companies',
  MY_COMPANY = '/my-company',
  TEMPLATES = '/templates',
  FORMS = '/forms',
  USER_MANAGEMENT = '/user_management',
  TRAINING_MANAGEMENT = '/training-management',
  TRAINING_CONFIG = '/training-config',
  MY_TRAINING = '/my-training',
  EXAM_MANAGEMENT = '/exam-management',
  EXAM_CONFIG = '/exam-config',
  TRAINING_PERIOD = '/training-period',
  PLAY_TRAINING = '/play-training',
  TRAINING_QUIZ = '/training-quiz',
  QUIZ_RESULT = '/training-quiz',
  EXAM = '/exam-quiz',
  EXAM_RESULT = '/exam-quiz',
  NOTIFICATIONS = '/notifications',
  CERTIFICATES = '/certificates',
}

export enum UrlParams {
  RETURN_URL = 'returnUrl',
}
