import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RoutesEnum } from 'src/shared/enums';
import { LoaderComponent } from 'src/components/Loader';

import actions from 'src/store/actions';
import {
  getCurrentExam,
  getCurrentExamSubmission,
  getExamLoader,
} from 'src/store/selectors';
import { QuizResult } from '../../components';

interface RouteParams {
  id: string;
  submissionId: string;
}

export const ExamResultPage: FC<unknown> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentExam = useSelector(getCurrentExam);
  const currentSubmission = useSelector(getCurrentExamSubmission);
  const examLoader = useSelector(getExamLoader);
  const { submissionId: currentExamSubmissionId } = useParams<RouteParams>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    dispatch(actions.examReset());
    dispatch(actions.examQuizLoadRequestAction());
  }, []);

  useEffect(() => {
    const submissionId = currentSubmission
      ? currentSubmission.id
      : Number(currentExamSubmissionId);
    if (currentExam) {
      dispatch(actions.examLoadSubmissionRequest(currentExam.id, submissionId));
    }
  }, [currentExam]);

  useEffect(() => {
    if (
      examLoader.completed &&
      examLoader.success &&
      currentExam &&
      currentSubmission
    ) {
      setLoaded(true);
    }
  }, [currentExam, currentSubmission, examLoader]);

  const handleTakeAgain = () => {
    dispatch(actions.examSubmissionReset());
    history.push(`${RoutesEnum.EXAM}`);
  };

  const handleKeepTraining = (): void => {
    dispatch(actions.trainingSubmissionResponseReset());
    history.push(RoutesEnum.MY_TRAINING);
  };

  const hasPassed = currentSubmission?.status === 'PASSED';
  const totalChoices =
    currentSubmission?.passedChoices + currentSubmission?.failedChoices;

  return (
    <>
      {!loaded ? (
        <LoaderComponent />
      ) : (
        <QuizResult
          hasPassed={hasPassed}
          passedChoices={currentSubmission?.passedChoices}
          failedChoices={currentSubmission?.failedChoices}
          totalChoices={totalChoices}
          passingScore={70}
          questions={currentExam?.questions}
          takeAgainHandler={handleTakeAgain}
          keepTrainingHandler={handleKeepTraining}
        />
      )}
    </>
  );
};
