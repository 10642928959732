import React, { FC } from 'react';
import styled from 'styled-components';
import { TextArea as TextAreaBase } from 'src/components/TextArea';
import { AnswerData, Answer } from 'src/store/types';

interface ParagraphFieldProps {
  onChange: (paragraph: string) => void;
  value: string;
  updateAnswer: (answerData: AnswerData) => void;
  answer: Answer;
  minRows?: number;
  maxRows?: number;
}

const TextArea = styled(TextAreaBase)`
  width: 312px;
  resize: both;
`;

export const ParagraphField: FC<ParagraphFieldProps> = ({
  onChange,
  value,
  updateAnswer,
  answer,
  minRows = 3,
  maxRows,
}) => {
  return (
    <TextArea
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder="Answer"
      autoSize={{ minRows, maxRows }}
      onBlur={() => {
        setTimeout(
          () =>
            updateAnswer({
              value,
              id: answer?.id,
              internalId: answer?.internalId,
            }),
          100,
        );
      }}
    />
  );
};
