import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProgressMeter } from 'src/components/ProgressMeter';

import { useAuth } from 'src/hooks/useAuth';
import { turnOn2FARequest } from 'src/store/actions/user';
import { getUser } from 'src/store/selectors';
import { AuthMethod } from 'src/types/common';
import { TwoFactorVerificationApp } from '../../components/TwoFactorAppForm';
import * as S from './Styles';

export const TwoFATurnOnAppPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const user = useSelector(getUser);
  const { hasPendingOnboarding } = useAuth();

  const handleOnSubmit = (token: string) => {
    dispatch(turnOn2FARequest(token, AuthMethod.APP));
  };

  const handleShowHelp = () => {
    setShowHelp(!showHelp);
  };

  const handleGoBack = () => {
    history.push({
      pathname: '/2fa/turn-on/',
    });
  };

  useEffect(() => {
    if (user.isSecondFactorAuthenticated) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  return (
    <S.PageContainer>
      <S.PageTitle>Two-step verification</S.PageTitle>
      <S.PageSubTitle>
        Two-step verification helps keep your account safe.
      </S.PageSubTitle>
      <S.AppFormWrapper>
        <TwoFactorVerificationApp
          showHelp={showHelp}
          code={code}
          onChangeCode={setCode}
          onSubmit={handleOnSubmit}
        />
      </S.AppFormWrapper>
      <S.PageBottomContainer>
        <S.ProgressMeterWrapper>
          <ProgressMeter hasOnboarding={hasPendingOnboarding} step={4} />
        </S.ProgressMeterWrapper>
        <S.Label onClick={handleShowHelp}>Need help?</S.Label>
      </S.PageBottomContainer>
      <S.Label onClick={handleGoBack}>Go back to 2-step selection</S.Label>
    </S.PageContainer>
  );
};
