import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { TextInput } from 'src/components/TextInput';
import { ItemContainer } from './FormFieldSettings';

const Field = Form.Item;

export const FileUploadSettings: FC = () => {
  return (
    <ItemContainer>
      <span>File Restrictions</span>
      <Field
        className="formItem"
        name="max"
        rules={[
          {
            type: 'number',
            transform: (value) => Number(value),
            message: 'Must be a number',
          },
        ]}
      >
        <TextInput className="input" placeholder="Max MB" />
      </Field>
      <Field className="formItem" name="extension">
        <Select placeholder="Allowed Type">
          <Select.Option value="image">
            Images (jpg, png, pdf, etc...)
          </Select.Option>
          <Select.Option value="text">
            Text (txt, doc, pdf, etc...)
          </Select.Option>
          <Select.Option value="all">All</Select.Option>
        </Select>
      </Field>
    </ItemContainer>
  );
};
