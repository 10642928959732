import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  AssessmentRiskAnalysisState,
  Question,
  SelectedFormState,
  SelectedInterviewState,
  User,
} from 'src/store/types';
import * as M from 'src/theme/media';
import {
  interviewAssignOwnerRequestAction,
  interviewRemoveOwnerRequestAction,
} from 'src/store/actions/interview';
import { confirmDialog } from 'src/shared/utils';
import { DownOutlined, UpOutlined } from 'src/theme/icons';
import { AssignButton } from '../AssignButton';
import * as S from './Styles';

interface FormCardProps {
  className?: string;
  selectedForm: SelectedFormState;
  selectedInterview: SelectedInterviewState;
  assessmentRiskAnalysis?: AssessmentRiskAnalysisState;
  user: User;
}

export const InterviewProgressCard: FC<FormCardProps> = ({
  className,
  selectedForm,
  selectedInterview,
  assessmentRiskAnalysis,
}) => {
  const dispatch = useDispatch();
  const interviewLoadTimeOut = 600;
  const [riskCurrentPosition, setRiskCurrentPosition] = useState<number>(-1);
  const interviewRisksCounter =
    assessmentRiskAnalysis &&
    assessmentRiskAnalysis.forms[selectedInterview.interview.formId]
      ? assessmentRiskAnalysis.forms[selectedInterview.interview.formId]
      : 0;

  const riskyAnswerIds = useMemo(() => {
    return selectedForm.questions
      .map((question: Question) => {
        if (assessmentRiskAnalysis?.answers[question.id as number]) {
          return question.id;
        }
      })
      .filter((a) => !!a);
  }, [selectedForm]);

  useLayoutEffect(() => {
    setTimeout(() => {
      scrollNext();
    }, interviewLoadTimeOut);
  }, []);

  useEffect(() => {
    const questionEl = document.getElementById(
      String(riskyAnswerIds[riskCurrentPosition]),
    );

    if (questionEl) {
      questionEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [riskCurrentPosition]);

  const scrollNext = () => {
    if (riskCurrentPosition + 1 < interviewRisksCounter) {
      setRiskCurrentPosition(riskCurrentPosition + 1);
    }
  };

  const scrollBack = () => {
    if (riskCurrentPosition - 1 > -1) {
      setRiskCurrentPosition(riskCurrentPosition - 1);
    }
  };

  return (
    <S.Card className={className}>
      <S.InterviewBeganDate>
        Began: {moment(selectedInterview.interview.createdAt).format('lll')}
      </S.InterviewBeganDate>
      <S.InterviewRiskOwner>
        <AssignButton
          hide={!!selectedInterview?.interview.ownerId}
          buttonTooltip="Delegate someone to this form"
          modalTitle="Assign Risk Section"
          autoCompleteTitle={selectedForm.form.label}
          onOk={(selectedAssigneeId?: number) => {
            if (selectedInterview?.interview?.id && selectedAssigneeId) {
              dispatch(
                interviewAssignOwnerRequestAction(
                  selectedAssigneeId,
                  selectedInterview?.interview?.id,
                ),
              );
            }
          }}
        />
      </S.InterviewRiskOwner>
      {selectedInterview?.interview?.ownerName && (
        <S.InterviewRiskOwnerContainer>
          <S.InterviewRiskOwnerTitle>
            Risk Assessment Owner
          </S.InterviewRiskOwnerTitle>
          <S.InterviewRiskOwner>
            <>
              <img src={M.THIconOwner} />
              {selectedInterview?.interview?.ownerName}
              <S.DeleteAssigneeButton
                onClick={() => {
                  confirmDialog({
                    text: 'Are you sure you want to unassign this form?',
                    onOk: () => {
                      dispatch(
                        interviewRemoveOwnerRequestAction(
                          selectedInterview?.interview.id || 0,
                          selectedInterview?.interview.ownerId || 0,
                        ),
                      );
                    },
                  });
                }}
              >
                x
              </S.DeleteAssigneeButton>
            </>
          </S.InterviewRiskOwner>
        </S.InterviewRiskOwnerContainer>
      )}
      <S.Separator />
      <S.QuestionsCounter>
        {selectedInterview.answers.length}/
        {selectedInterview.metadata.questionsCount} Questions
      </S.QuestionsCounter>
      <S.QuestionsFormDescriptor>
        This form is dynamic. The number of questions will change as you answer
        questions.
      </S.QuestionsFormDescriptor>
      {assessmentRiskAnalysis &&
        assessmentRiskAnalysis.forms[selectedForm.form.id] > 0 && (
          <>
            <S.Separator />
            <S.RiskPagerContainer>
              <img src={M.THIconRisksStatus} />
              {riskCurrentPosition + 1}/{interviewRisksCounter} HIPAA Risks
              <div>
                <div onClick={scrollNext}>
                  Next risk
                  <DownOutlined />
                </div>
                <div onClick={scrollBack}>
                  Previous risk
                  <UpOutlined />
                </div>
              </div>
            </S.RiskPagerContainer>
          </>
        )}
    </S.Card>
  );
};
