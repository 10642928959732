import { showErrorMessage } from 'src/store/actions/error';
import {
  TrainingPeriodListingLoadErrorAction,
  TrainingPeriodListingLoadRequestAction,
  TrainingPeriodListingLoadSuccessAction,
  TrainingPeriodListingResponse,
  TRAINING_PERIOD_LISTING_LOAD_FAILED,
  TRAINING_PERIOD_LISTING_LOAD_REQUEST,
  TRAINING_PERIOD_LISTING_LOAD_SUCCESS,
} from '../types';

export const trainingPeriodListingLoadRequest = (
  companyId: number,
): TrainingPeriodListingLoadRequestAction => ({
  type: TRAINING_PERIOD_LISTING_LOAD_REQUEST,
  companyId,
});

export const trainingPeriodListingLoadSuccess = (
  trainingPeriodListing: TrainingPeriodListingResponse,
): TrainingPeriodListingLoadSuccessAction => ({
  type: TRAINING_PERIOD_LISTING_LOAD_SUCCESS,
  trainingPeriodListing,
});

export const trainingPeriodListingLoadError = (
  errorMessage: string,
  originalError?: Error,
): TrainingPeriodListingLoadErrorAction =>
  showErrorMessage(
    TRAINING_PERIOD_LISTING_LOAD_FAILED,
    errorMessage,
    originalError,
  );
