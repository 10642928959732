import {
  COMPANY_LOAD_SUCCESS,
  ASSESSMENT_TYPES_LOAD_ALL_SUCCESS,
  AssessmentTypeListingState,
  AssessmentTypeListingIndexedItem,
  AssessmentTypeFromResponse,
  AssessmentTypesActionTypes,
  CompanyActionTypes,
} from 'src/store/types';

const assessmentTypeListingInitialState: AssessmentTypeListingState = {
  assessmentTypes: {},
};

export const assessmentTypeListingReducer = (
  state = assessmentTypeListingInitialState,
  action: AssessmentTypesActionTypes | CompanyActionTypes,
): AssessmentTypeListingState => {
  switch (action.type) {
    case ASSESSMENT_TYPES_LOAD_ALL_SUCCESS: {
      if (action.assessmentTypes) {
        const indexedList = action.assessmentTypes.reduce(
          (indexedAssessments: any, assessment: any) => {
            const formIds = state.assessmentTypes[assessment.id]
              ? state.assessmentTypes[assessment.id].formIds
              : [];
            return {
              ...indexedAssessments,
              [assessment.id]: { ...assessment, formIds },
            };
          },
          {},
        );

        return {
          assessmentTypes: indexedList,
        };
      }
      return state;
    }
    case COMPANY_LOAD_SUCCESS: {
      const { assessments } = action.company;
      const types: AssessmentTypeFromResponse[] = assessments.map(
        (assessment: any) => assessment.type,
      );
      const typesMap = types.reduce(
        (
          list: AssessmentTypeListingIndexedItem,
          assessmentType: AssessmentTypeFromResponse,
        ) => {
          const { id, createdAt, updatedAt, label } = assessmentType;
          let formIds: number[] = [];
          if (assessmentType.forms) {
            formIds = assessmentType.forms.map((f) => f.id);
          }
          return {
            ...list,
            [assessmentType.id]: { id, createdAt, updatedAt, label, formIds },
          };
        },
        {},
      );
      return { assessmentTypes: typesMap };
    }
    default:
      return state;
  }
};
