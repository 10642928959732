import {
  DOCUMENTS_DRAFTS_LOAD_REQUEST,
  DOCUMENTS_DRAFTS_LOAD_SUCCESS,
  DOCUMENTS_DRAFTS_LOAD_ERROR,
  DocumentsDraftsLoadRequestAction,
  DocumentsDraftsLoadSuccessAction,
  DocumentsDraftsLoadErrorAction,
  DocumentDraftFromResponse,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const documentDraftsLoadRequest = (
  documentId?: number,
): DocumentsDraftsLoadRequestAction => ({
  type: DOCUMENTS_DRAFTS_LOAD_REQUEST,
  documentId,
});

export const documentDraftsLoadSuccess = (
  documentDrafts: DocumentDraftFromResponse[],
): DocumentsDraftsLoadSuccessAction => ({
  type: DOCUMENTS_DRAFTS_LOAD_SUCCESS,
  documentDrafts,
});

export const documentDraftsLoadError = (
  errorMessage: string,
  originalError: Error,
): DocumentsDraftsLoadErrorAction =>
  showErrorMessage(DOCUMENTS_DRAFTS_LOAD_ERROR, errorMessage, originalError);
