import React, { FC } from 'react';
import { Moment } from 'moment';
import { StyledDatePicker } from './Styles';

interface DatePickerProps {
  value?: Moment | null;
  placeholder?: string;
  onChange?: (value: Moment | null, dateString: string) => void;
  format?: string;
  disabledDate?: (value: Moment) => boolean;
  open?: boolean;
  style?: React.CSSProperties;
}

export const DatePicker: FC<DatePickerProps> = ({
  value,
  placeholder,
  format,
  open,
  style,
  onChange,
  disabledDate,
}) => {
  return (
    <StyledDatePicker
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      format={format}
      disabledDate={disabledDate}
      open={open}
      style={style}
    />
  );
};
