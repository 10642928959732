import { call, takeEvery, put } from 'redux-saga/effects';
import { trainingPeriodService } from 'src/features/learning';
import actions from 'src/store/actions';

import {
  TrainingPeriodCreateRequestAction,
  TrainingPeriodResponse,
  TRAINING_PERIOD_CREATE_REQUEST,
  TrainingPeriodLoadRequestAction,
  TRAINING_PERIOD_LOAD_REQUEST,
  TrainingPeriodUpdateRequestAction,
  TRAINING_PERIOD_UPDATE_REQUEST,
  TrainingPeriodCompleteRequestAction,
  TRAINING_PERIOD_COMPLETE_REQUEST,
  TRAINING_PERIOD_COMPANY_LOAD_REQUEST,
} from '../types';

export const createTrainingPeriod = function* async({
  config,
}: TrainingPeriodCreateRequestAction): Generator {
  try {
    const result = yield call(trainingPeriodService.create, config);
    yield put(
      actions.trainingPeriodCreateSuccess(result as TrainingPeriodResponse),
    );
  } catch (err) {
    const message = config.userId
      ? 'The user already has individual training opened'
      : 'The company already has open training periods';
    yield put(actions.trainingPeriodCreateError(message, err as Error));
  }
};

export const updateTrainingPeriod = function* async({
  trainingPeriodId,
  config,
}: TrainingPeriodUpdateRequestAction): Generator {
  try {
    const result = yield call(
      trainingPeriodService.update,
      trainingPeriodId,
      config,
    );
    yield put(
      actions.trainingPeriodUpdateSuccess(result as TrainingPeriodResponse),
    );
  } catch (err) {
    yield put(
      actions.trainingPeriodUpdateError(
        'Failed to update trainingPeriod',
        err as Error,
      ),
    );
  }
};

export const loadTrainingPeriod = function* async({
  trainingPeriodId,
}: TrainingPeriodLoadRequestAction): Generator {
  try {
    const result = yield call(trainingPeriodService.getById, trainingPeriodId);
    yield put(
      actions.trainingPeriodLoadSuccess(result as TrainingPeriodResponse),
    );
  } catch (err) {
    yield put(
      actions.trainingPeriodLoadError(
        'Failed to load trainingPeriod',
        err as Error,
      ),
    );
  }
};

export const completeTrainingPeriod = function* async({
  trainingPeriodId,
}: TrainingPeriodCompleteRequestAction): Generator {
  try {
    const result = yield call(trainingPeriodService.complete, trainingPeriodId);
    yield put(
      actions.trainingPeriodCompleteSuccess(result as TrainingPeriodResponse),
    );
  } catch (err) {
    yield put(
      actions.trainingPeriodCompleteError(
        'Failed to complete training period',
        err as Error,
      ),
    );
  }
};

export const loadTrainingPeriodCompany = function* async(): Generator {
  try {
    const trainingPeriod = yield call(trainingPeriodService.getCompanyCurrent);
    yield put(
      actions.trainingPeriodCompanyLoadSuccess(
        trainingPeriod as TrainingPeriodResponse,
      ),
    );
  } catch (err) {
    yield put(
      actions.trainingPeriodCompanyLoadFailed(
        'Failed to load company training period',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(TRAINING_PERIOD_CREATE_REQUEST, createTrainingPeriod),
    yield takeEvery(TRAINING_PERIOD_LOAD_REQUEST, loadTrainingPeriod),
    yield takeEvery(TRAINING_PERIOD_UPDATE_REQUEST, updateTrainingPeriod),
    yield takeEvery(TRAINING_PERIOD_COMPLETE_REQUEST, completeTrainingPeriod),
    yield takeEvery(
      TRAINING_PERIOD_COMPANY_LOAD_REQUEST,
      loadTrainingPeriodCompany,
    ),
  ];
}
