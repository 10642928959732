import {
  DocumentDraftListingIndexedItem,
  DocumentDraftState,
} from 'src/store/types';
import { TableRowInterface } from './DocumentDraftListingTable';

export const dataMapper = (
  documentDraftsData: DocumentDraftListingIndexedItem,
): TableRowInterface[] => {
  return Object.values(documentDraftsData).map(
    ({ id, title, archived }: DocumentDraftState) => {
      return { id, title, archived } as TableRowInterface;
    },
  );
};
