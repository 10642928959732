import { Loader, ShowErrorAction } from 'src/store/types';

export const TRAINING_CREATE_REQUEST = 'TRAINING_CREATE_REQUEST';
export const TRAINING_CREATE_SUCCESS = 'TRAINING_CREATE_SUCCESS';
export const TRAINING_CREATE_FAILED = 'TRAINING_CREATE_FAILED';

export const TRAINING_SET_SELECTED = 'TRAINING_SET_SELECTED';

export enum TrainingPeriodStatus {
  COMPLETED = 'COMPLETED',
  OPEN = 'OPEN',
}

export enum TrainingSubmissionStatus {
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN PROGRESS',
}

export type TrainingConfig = {
  name: string;
  trainingTypeId: number;
  testTypeId: number;
  videoId: string;
  chapter: number;
};

export type QuestionChoice = {
  id?: number;
  choiceText: string;
  isCorrect: boolean;
};

export type QuizQuestion = {
  questionText: string;
  id: number;
  choices: QuestionChoice[];
};

export type QuizQuestionRaw = {
  questionText: string;
  id: number;
  choicesIds: number[];
};

export type TrainingRawResponse = {
  id: number;
  name: string;
  chapter: number;
  minutes: number;
  videoId: string;
  trainingTypeId: number;
  trainingTypeName: string;
  testTypeId: number;
  quizId: number;
  statusType: string;
  questions: { [key: number]: QuizQuestionRaw };
  choices: { [key: number]: QuestionChoice };
  hasSubmissions: boolean;
  nextTrainingId: number;
  lastSubmissionStatus: string;
};

export type Training = {
  id: number;
  name: string;
  chapter: number;
  minutes: number;
  videoId: string;
  trainingTypeId: number;
  trainingTypeName: string;
  testTypeId: number;
  quizId: number;
  status: string;
  questions: QuizQuestion[];
  hasSubmissions?: boolean;
  nextTrainingId?: number;
  lastSubmissionStatus?: string;
};

export interface SelectedTrainingState {
  training: Training;
  loader: Loader;
}

export interface TrainingSetSelectedAction {
  type: typeof TRAINING_SET_SELECTED;
  training: Training;
}

export type TrainingSubmission = {
  id: number;
  trainingId: number;
  responses: TrainingSubmissionResponse[];
  failedChoices: number;
  passedChoices: number;
  grade: number;
  status: string;
};

export type TrainingSubmissionResponse = {
  id: number;
  answerText: string;
  isCorrect: boolean;
  questionId: number;
  failedChoices: number;
  passedChoices: number;
  grade: number;
};

export type TrainingSubmissionResult = {
  id: number;
  completedAt: Date;
  grade: number;
  trainingId: number;
  passedChoices: number;
  failedChoices: number;
};

export type TrainingState = {
  training?: Training;
  submission?: TrainingSubmission;
  submissionResult?: TrainingSubmissionResult;
  loader: Loader;
};

export interface TrainingCreateRequestAction {
  type: typeof TRAINING_CREATE_REQUEST;
  config: TrainingConfig;
}

export interface TrainingCreateSuccessAction {
  type: typeof TRAINING_CREATE_SUCCESS;
  training: Training;
}

export type TrainingCreateErrorAction = ShowErrorAction<
  typeof TRAINING_CREATE_FAILED
>;

export const TRAINING_RESET = 'TRAINING_RESET';

export interface TrainingResetAction {
  type: typeof TRAINING_RESET;
}

export const TRAINING_QUESTION_CREATE_REQUEST =
  'TRAINING_QUESTION_CREATE_REQUEST';
export const TRAINING_QUESTION_CREATE_SUCCESS =
  'TRAINING_QUESTION_CREATE_SUCCESS';
export const TRAINING_QUESTION_CREATE_FAILED =
  'TRAINING_QUESTION_CREATE_FAILED';

export interface TrainingQuestionCreateRequestAction {
  type: typeof TRAINING_QUESTION_CREATE_REQUEST;
  trainingId: number;
}

export interface TrainingQuestionCreateSuccessAction {
  type: typeof TRAINING_QUESTION_CREATE_SUCCESS;
  question: QuizQuestion;
}

export type TrainingQuestionCreateErrorAction = ShowErrorAction<
  typeof TRAINING_QUESTION_CREATE_FAILED
>;

export const TRAINING_QUESTION_UPDATE_REQUEST =
  'TRAINING_QUESTION_UPDATE_REQUEST';
export const TRAINING_QUESTION_UPDATE_SUCCESS =
  'TRAINING_QUESTION_UPDATE_SUCCESS';
export const TRAINING_QUESTION_UPDATE_FAILED =
  'TRAINING_QUESTION_UPDATE_FAILED';

export interface TrainingQuestionUpdateRequestAction {
  type: typeof TRAINING_QUESTION_UPDATE_REQUEST;
  trainingId: number;
  question: QuizQuestion;
}

export interface TrainingQuestionUpdateSuccessAction {
  type: typeof TRAINING_QUESTION_UPDATE_SUCCESS;
  question: QuizQuestion;
}

export type TrainingQuestionUpdateErrorAction = ShowErrorAction<
  typeof TRAINING_QUESTION_UPDATE_FAILED
>;

export const TRAINING_QUESTION_DELETE_REQUEST =
  'TRAINING_QUESTION_DELETE_REQUEST';
export const TRAINING_QUESTION_DELETE_SUCCESS =
  'TRAINING_QUESTION_DELETE_SUCCESS';
export const TRAINING_QUESTION_DELETE_FAILED =
  'TRAINING_QUESTION_DELETE_FAILED';

export interface TrainingQuestionDeleteRequestAction {
  type: typeof TRAINING_QUESTION_DELETE_REQUEST;
  trainingId: number;
  questionId: number;
}

export interface TrainingQuestionDeleteSuccessAction {
  type: typeof TRAINING_QUESTION_DELETE_SUCCESS;
  questionId: number;
}

export type TrainingQuestionDeleteErrorAction = ShowErrorAction<
  typeof TRAINING_QUESTION_DELETE_FAILED
>;

export const TRAINING_LOAD_REQUEST = 'TRAINING_LOAD_REQUEST';
export const TRAINING_LOAD_SUCCESS = 'TRAINING_LOAD_SUCCESS';
export const TRAINING_LOAD_FAILED = 'TRAINING_LOAD_FAILED';

export interface TrainingLoadRequestAction {
  type: typeof TRAINING_LOAD_REQUEST;
  trainingId: number;
}

export interface TrainingLoadSuccessAction {
  type: typeof TRAINING_LOAD_SUCCESS;
  training: Training;
}

export type TrainingLoadErrorAction = ShowErrorAction<
  typeof TRAINING_LOAD_FAILED
>;

export const TRAINING_UPDATE_REQUEST = 'TRAINING_UPDATE_REQUEST';
export const TRAINING_UPDATE_SUCCESS = 'TRAINING_UPDATE_SUCCESS';
export const TRAINING_UPDATE_FAILED = 'TRAINING_UPDATE_FAILED';

export interface TrainingUpdateRequestAction {
  type: typeof TRAINING_UPDATE_REQUEST;
  trainingId: number;
  config: TrainingConfig;
}

export interface TrainingUpdateSuccessAction {
  type: typeof TRAINING_UPDATE_SUCCESS;
  training: Training;
}

export type TrainingUpdateErrorAction = ShowErrorAction<
  typeof TRAINING_UPDATE_FAILED
>;

export const TRAINING_SUBMISSION_REQUEST = 'TRAINING_SUBMISSION_REQUEST';
export const TRAINING_SUBMISSION_SUCCESS = 'TRAINING_SUBMISSION_SUCCESS';
export const TRAINING_SUBMISSION_FAILED = 'TRAINING_SUBMISSION_FAILED';

export interface TrainingSubmissionRequestAction {
  type: typeof TRAINING_SUBMISSION_REQUEST;
  trainingId: number;
}

export interface TrainingSubmissionSuccessAction {
  type: typeof TRAINING_SUBMISSION_SUCCESS;
  submission: TrainingSubmission;
}

export type TrainingSubmissionErrorAction = ShowErrorAction<
  typeof TRAINING_SUBMISSION_FAILED
>;

export const TRAINING_SUBMISSION_RESPONSE_REQUEST =
  'TRAINING_SUBMISSION_RESPONSE_REQUEST';
export const TRAINING_SUBMISSION_RESPONSE_SUCCESS =
  'TRAINING_SUBMISSION_RESPONSE_SUCCESS';
export const TRAINING_SUBMISSION_RESPONSE_FAILED =
  'TRAINING_SUBMISSION_RESPONSE_FAILED';
export const TRAINING_SUBMISSION_RESPONSE_RESET =
  'TRAINING_SUBMISSION_RESPONSE_RESET';

export interface TrainingSubmissionResponseRequestAction {
  type: typeof TRAINING_SUBMISSION_RESPONSE_REQUEST;
  trainingId: number;
  submissionId: number;
  questionId: number;
  choiceId: number;
  compute: boolean;
}

export interface TrainingSubmissionResponseSuccessAction {
  type: typeof TRAINING_SUBMISSION_RESPONSE_SUCCESS;
  submissionResult?: TrainingSubmissionResult;
}

export type TrainingSubmissionResponseErrorAction = ShowErrorAction<
  typeof TRAINING_SUBMISSION_RESPONSE_FAILED
>;

export interface TrainingSubmissionResponseResetAction {
  type: typeof TRAINING_SUBMISSION_RESPONSE_RESET;
}

export const TRAINING_LOAD_SUBMISSION_REQUEST =
  'TRAINING_LOAD_SUBMISSION_REQUEST';
export const TRAINING_LOAD_SUBMISSION_SUCCESS =
  'TRAINING_LOAD_SUBMISSION_SUCCESS';
export const TRAINING_LOAD_SUBMISSION_FAILED =
  'TRAINING_LOAD_SUBMISSION_FAILED';

export interface TrainingLoadSubmissionRequestAction {
  type: typeof TRAINING_LOAD_SUBMISSION_REQUEST;
  trainingId: number;
  submissionId: number;
}

export interface TrainingLoadSubmissionSuccessAction {
  type: typeof TRAINING_LOAD_SUBMISSION_SUCCESS;
  trainingSubmission?: any;
}

export type TrainingLoadSubmissionErrorAction = ShowErrorAction<
  typeof TRAINING_LOAD_SUBMISSION_FAILED
>;

export const TRAINING_CLONE_REQUEST = 'TRAINING_CLONE_REQUEST';
export const TRAINING_CLONE_SUCCESS = 'TRAINING_CLONE_SUCCESS';
export const TRAINING_CLONE_FAILED = 'TRAINING_CLONE_FAILED';

export interface TrainingCloneRequestAction {
  type: typeof TRAINING_CLONE_REQUEST;
  trainingId: number;
}

export interface TrainingCloneSuccessAction {
  type: typeof TRAINING_CLONE_SUCCESS;
  training: Training;
}

export type TrainingCloneErrorAction = ShowErrorAction<
  typeof TRAINING_CLONE_FAILED
>;

export type TrainingTypes =
  | TrainingCreateRequestAction
  | TrainingCreateSuccessAction
  | TrainingCreateErrorAction
  | TrainingResetAction
  | TrainingQuestionCreateRequestAction
  | TrainingQuestionCreateSuccessAction
  | TrainingQuestionCreateErrorAction
  | TrainingQuestionUpdateRequestAction
  | TrainingQuestionUpdateSuccessAction
  | TrainingQuestionUpdateErrorAction
  | TrainingLoadRequestAction
  | TrainingLoadSuccessAction
  | TrainingLoadErrorAction
  | TrainingUpdateRequestAction
  | TrainingUpdateSuccessAction
  | TrainingUpdateErrorAction
  | TrainingQuestionDeleteRequestAction
  | TrainingQuestionDeleteSuccessAction
  | TrainingQuestionDeleteErrorAction
  | TrainingSubmissionRequestAction
  | TrainingSubmissionSuccessAction
  | TrainingSubmissionErrorAction
  | TrainingSubmissionResponseRequestAction
  | TrainingSubmissionResponseSuccessAction
  | TrainingSubmissionResponseErrorAction
  | TrainingSubmissionResponseResetAction
  | TrainingLoadSubmissionRequestAction
  | TrainingLoadSubmissionSuccessAction
  | TrainingLoadSubmissionErrorAction
  | TrainingCloneRequestAction
  | TrainingCloneSuccessAction
  | TrainingCloneErrorAction
  | TrainingSetSelectedAction;
