import React, { FC } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

interface LoaderProps {
  opacity?: number;
}

export const LoaderComponent: FC<LoaderProps> = ({ opacity }) => {
  return (
    <LoaderContainer
      style={{ opacity: opacity ?? 100, display: opacity === 0 ? 'none' : '' }}
    >
      <Spin size="large" />
    </LoaderContainer>
  );
};
