import { call, takeEvery, put } from 'redux-saga/effects';
import { QUESTION_TYPES_LOAD_REQUEST, QuestionType } from 'src/store/types/';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadQuestionTypes = function* async(): Generator {
  try {
    const questionTypes = yield call(api.getQuestionTypes);
    yield put(actions.questionTypeLoadSuccess(questionTypes as QuestionType[]));
  } catch (err) {
    yield put(
      actions.questionTypeLoadError(
        'Failed to load question types',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(QUESTION_TYPES_LOAD_REQUEST, loadQuestionTypes)];
}
