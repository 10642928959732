import React, { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import styled from 'styled-components';

interface Props {
  isLoading: boolean;
}

const PillContainer = styled.span`
  background-color: ${({ theme }) => theme.colors.green.main};
  border-radius: 10px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
`;

const SavingPill = ({ isLoading }: Props) => {
  const [showSaving, setShowSaving] = useState(false);

  const hideLoading = useMemo(
    () =>
      _.debounce(() => {
        setShowSaving(false);
      }, 1000),
    [],
  );

  useEffect(() => {
    if (isLoading) {
      setShowSaving(true);
    } else {
      hideLoading();
    }
  }, [isLoading]);

  return showSaving ? <PillContainer>Saving...</PillContainer> : <></>;
};

export default SavingPill;
