import { CompanyUserRole, UserRole } from 'src/store/types';
import { UserRoleOption } from 'src/types/user';

export const mapUserRoleText = (user: CompanyUserRole | UserRole): string => {
  const words = user.split('_');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  );
  const displayValue = capitalizedWords.join(' ');

  return displayValue;
};

export const mapUserRoleOptions = (
  excludeRoles: CompanyUserRole[],
): UserRoleOption[] => {
  const filteredRoles: CompanyUserRole[] = Object.values(
    CompanyUserRole,
  ).filter((role) => !excludeRoles.includes(role));
  const mappedRoles: UserRoleOption[] = filteredRoles.map((role) => ({
    value: role,
    displayValue: mapUserRoleText(role),
  }));

  return mappedRoles;
};
