import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { OrderedListOutlined } from 'src/theme/icons';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #efefef;
  border: 1px solid #909090;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  margin: 5px;
  padding: 1px 5px;
  width: 50px;
  height: 50px;

  span {
    font-size: 28px;
  }
`;

export const TocPreview: FC = () => {
  return (
    <Wrapper>
      <Tooltip
        title={'Table of Content will be generated during document generation'}
      >
        <OrderedListOutlined />
      </Tooltip>
    </Wrapper>
  );
};
