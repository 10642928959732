import React, { FC } from 'react';
import { Table, Empty, Tooltip } from 'antd';
import styled from 'styled-components';
import { Grid } from 'src/components/Grid';
import { DownloadOutlined } from 'src/theme/icons';
import s3 from 'src/services/downloadFile';
import { DocumentState } from 'src/store/types';
import { dataMapper } from './dataMapper';
import { IconButton } from '../../../../components/Button';

export interface PPDocumentListingTableProps {
  documents: DocumentState[];
}

export interface TableRowInterface {
  id: number;
  name: string;
  createdAt: string;
}

const { Column } = Table;

const Label = styled.span`
  color: black;
`;

export const PPDocumentListingTable: FC<PPDocumentListingTableProps> = ({
  documents: rawDocuments,
}) => {
  const dataSource: TableRowInterface[] = dataMapper(rawDocuments);

  const downloadFile = async (documentId: number) => {
    const document = rawDocuments.find(
      (rawDocument) => rawDocument.id === documentId,
    );
    if (document) {
      const file = JSON.parse(document.file as string) as {
        Key: string;
        Bucket: string;
        'Content-Type': string;
      };
      await s3.handleFileDownload(
        file.Key,
        `${document.name}.pdf`,
        file.Bucket,
      );
    }
  };

  return (
    <Grid
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No results were found, please try a different filter"
          />
        ),
      }}
    >
      <Column<TableRowInterface>
        title="Document"
        dataIndex="name"
        key="name"
        render={(_, record: TableRowInterface) => <Label>{record.name}</Label>}
      />
      <Column<TableRowInterface>
        title="Creation Date"
        dataIndex="createdAt"
        key="createdAt"
      />
      <Column<TableRowInterface>
        title={false}
        dataIndex=""
        key="action"
        render={(_, record: TableRowInterface) => (
          <Tooltip title="Download" placement="left">
            <span>
              <IconButton
                onClick={() => downloadFile(record.id)}
                icon={<DownloadOutlined />}
              />
            </span>
          </Tooltip>
        )}
      />
    </Grid>
  );
};
