import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { device } from 'src/theme';

export const PageContentContainer = styled.div`
  margin-top: ${remConvert(22)};
  width: 100%;
  display: block;
  margin: 0 auto;

  @media ${device.md} {
    width: ${remConvert(400)};
  }
`;

export const PageTitle = styled.h1`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const PageSubTitle = styled.p`
  text-align: center;
  font-size: 16px;
`;

export const PageContentBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.md} {
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  cursor: pointer;
`;

export const HelpStepNumber = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${remConvert(68)};
  font-size: 50px;
  text-align: center;
  color: rgba(28, 36, 40, 0.5);
`;

export const HelpStepContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${remConvert(0)};
  margin-left: ${remConvert(0)};

  @media ${device.lg} {
    margin-right: ${remConvert(92)};
    margin-left: ${remConvert(72)};
  }
`;

export const HelpStepOneShortExplanation = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.italic};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 10px;
  line-height: ${remConvert(14)};
  color: ${({ theme }) => theme.colors.black.shark};
`;

export const GoBackWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;

  @media ${device.lg} {
    margin-top: 0;
  }
`;
