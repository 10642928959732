import { Alert, Tabs, Tag } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { RoutesEnum } from 'src/shared/enums';
import { TopNavbar } from 'src/components/TopNavbar';
import { Button } from 'src/components/Button';
import actions from 'src/store/actions';
import {
  getCurrentTraining,
  getTestTypes,
  getTrainingTypes,
  getTrainingLoader,
} from 'src/store/selectors';
import { TestTypesResponse, TrainingTypesResponse } from 'src/store/types';

import { QuestionConfigForm, TrainingConfigForm } from '../../components';
import { ResourceTypeEnum } from '../../enums';

interface RouteParams {
  id: string;
}

const { TabPane } = Tabs;

const TabContainer = styled(Tabs)`
  padding-top: 50px;
`;

export const TrainingConfigPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState('');

  const { id: trainingId } = useParams<RouteParams>();

  const currentTrainingLoader = useSelector(getTrainingLoader);
  const currentTraining = useSelector(getCurrentTraining);
  const trainingTypes: TrainingTypesResponse = useSelector(getTrainingTypes);
  const testTypes: TestTypesResponse = useSelector(getTestTypes);

  useEffect(() => {
    dispatch(actions.trainingTypesLoadRequest());
    dispatch(actions.testTypesLoadRequest());
    if (trainingId) {
      dispatch(actions.trainingLoadRequest(Number(trainingId)));
    }
  }, []);

  return (
    <>
      <TopNavbar
        title={!name ? 'Untitled training' : name}
        extraOptions={
          <>
            {currentTraining?.status && (
              <Tag
                color={
                  currentTraining?.status !== 'ACTIVE'
                    ? 'processing'
                    : 'success'
                }
              >
                {currentTraining.status}
              </Tag>
            )}
            <Button
              title="Close"
              onClick={() => history.replace(RoutesEnum.TRAINING_MANAGEMENT)}
            />
          </>
        }
      />
      {currentTraining?.status === 'ACTIVE' && (
        <Alert
          message="Editing quiz questions is limited because this quiz has already been submitted. If you need to make changes, make a copy and activate it."
          type="info"
          showIcon
        />
      )}
      <TabContainer type="line" size="large" tabPosition="left">
        <TabPane tab="1 - Training Details" key={1}>
          <TrainingConfigForm
            training={currentTraining}
            trainingTypes={trainingTypes}
            testTypes={testTypes}
            onNameChanged={setName}
          />
        </TabPane>
        <TabPane tab="2 - Quiz Questions" key={2} disabled={!currentTraining}>
          <QuestionConfigForm
            questions={currentTraining?.questions}
            resourceId={currentTraining?.id}
            resourceType={ResourceTypeEnum.TRAINING}
            isLoading={currentTrainingLoader.loading}
            isResourceActive={currentTraining?.status === 'ACTIVE'}
          />
        </TabPane>
      </TabContainer>
    </>
  );
};
