import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Table as TableAntd } from 'antd';
import { getConstantLoader, getConstants } from 'src/store/selectors';
import { ConstantState } from 'src/store/types';
import { CheckOutlined } from 'src/theme/icons';
import { ColumnsType } from 'antd/lib/table';
import { IconButton } from 'src/components/Button';

interface TemplateEditorConstantSelectorWindowProps {
  onSelection: (id: number) => void;
  onClose: () => void;
}

const Container = styled.div`
  width: 100%;
`;

const Table = styled<any>(TableAntd)`
  tr {
    th {
      font-size: 14px;

      &:last-child {
        text-align: right;
      }
    }
    td {
      font-size: 13px;
      .actions {
        text-align: right;
        button {
          font-size: 13px;
        }
      }
    }
  }
`;

export const TemplateEditorConstantSelectorWindow: FC<
  TemplateEditorConstantSelectorWindowProps
> = ({ onSelection, onClose }) => {
  const [value, setValue] = useState<ConstantState[]>([]);

  const constants = useSelector(getConstants);
  const constantLoader = useSelector(getConstantLoader);

  useEffect(() => {
    setValue(Object.values(constants));
  }, [constantLoader.completed]);

  const handleSelection = (id: number) => {
    if (id) {
      onSelection(id);
      onClose();
    }
  };

  const columns: ColumnsType<ConstantState> = [
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text: string) => {
        return <p>{text}</p>;
      },
    },
    {
      title: 'Actions',
      render: (constant: ConstantState) => {
        return (
          <div className="actions">
            <IconButton
              onClick={() => handleSelection(constant.id)}
              icon={<CheckOutlined />}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      <Table columns={columns} dataSource={value} size="small" />
    </Container>
  );
};
