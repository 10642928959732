import { Table } from 'antd';
import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { centeredFlex, semiboldOpenSans } from 'src/theme';

export const InterviewsTable = styled(Table)`
  .ant-table {
    background-color: ${({ theme }) => theme.colors.alabaster.main};
  }

  tr > th {
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-style: ${({ theme }) => theme.fontStyles.normal};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    line-height: ${remConvert(22)};
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5) !important;
  }

  tr > th,
  tr > td {
    border-bottom: 0;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    position: relative;
  }
`;

export const RiskLink = styled.a`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 14px;
  line-height: ${remConvert(22)};
  color: ${({ theme }) => theme.colors.chathamsBlue.secondary};
`;

export const RiskProgressContainer = styled.div`
  padding-top: ${remConvert(32)};
  padding-bottom: ${remConvert(32)};
`;

export const RiskCompletionContainer = styled.div`
  ${centeredFlex}
  justify-content: center;
  flex-direction: column;
  padding: 0 0 ${remConvert(16)} 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  h2 {
    ${semiboldOpenSans}
    ${centeredFlex}
    font-size: 23px;
    line-height: ${remConvert(31)};
    color: rgba(28, 36, 40, 0.75);
  }
`;

export const RiskProgressTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${remConvert(19)};
  font-size: 14px;
  color: rgba(28, 36, 40, 0.75);
`;

export const SectionColumnWrapper = styled.div`
  ${centeredFlex}
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${remConvert(22)};
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);

  img {
    margin-right: ${remConvert(10)};
  }
`;
