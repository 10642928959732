import styled from 'styled-components';
import { Layout as LayoutAntd } from 'antd';
import { remConvert } from 'src/theme/utils';

const { Sider: SiderAntD } = LayoutAntd;

export const Sider = styled(SiderAntD)`
  background-color: ${({ theme }): string => theme.colors.mariner.main};
  height: 100vh;

  .ant-menu-item-active {
    background: rgba(255, 255, 255, 0.1);
  }

  .ant-menu-item-selected {
    border-left: ${remConvert(2)} solid
      ${({ theme }): string => theme.colors.iceberg.main};
  }

  .ant-menu-item-active:before {
    border: ${remConvert(2)} solid
      ${({ theme }): string => theme.colors.iceberg.main};
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    content: '';
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

    .row {
      padding: ${remConvert(5)};

      &.logo {
        margin: ${remConvert(22)} 0 ${remConvert(25)} ${remConvert(10)};
        overflow-x: clip;
      }

      &.avatar {
        margin-bottom: ${remConvert(10)};
        display: flex;
        justify-content: space-between;
        overflow: hidden;
      }
    }

    .expand-toggle {
      color: ${({ theme }): string => theme.colors.zircon.main};
      align-self: center;
      font-size: 20px;
      padding-bottom: ${remConvert(0)};

      &.collapse {
        margin-right: ${remConvert(21)};
      }

      &.hidden-avatar {
        display: flex;
        margin: ${remConvert(0)};
        justify-content: center;
        width: 100%;
      }
    }
  }

  &&& ul {
    background-color: ${({ theme }): string => theme.colors.mariner.main};
  }
`;

export const ArrowIcon = styled.img`
  cursor: pointer;
`;

export const LayoutOverlay = styled.div`
  height: 100%;
  width: 100%;
  background: red;
  position: absolute;
  z-index: 10;
  display: flex;
  vertical-align: middle;
  align-items: center;
  background: rgba(154, 154, 155, 0.6);
`;
