import styled from 'styled-components';
import { THCheckList } from 'src/theme/media';
import { baseOpenSans, centeredJustifiedContentFlex, device } from 'src/theme';
import { remConvert } from 'src/theme/utils';

export const PageContainer = styled.div`
  margin-top: ${remConvert(12)};
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: left 322px;

  @media ${device.lg} {
    background-image: url(${THCheckList}) !important;
  }
`;

export const PageTitle = styled.h1`
  text-align: center;
`;

export const PageSubTitle = styled.div`
  ${baseOpenSans}
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: ${remConvert(22)};
  color: rgba(28, 36, 40, 0.75);
`;

export const PageFooterContainer = styled.div`
  position: relative;
  margin-top: ${remConvert(42)};
  height: ${remConvert(42)};

  a {
    ${baseOpenSans}
    font-weight: 700;
    font-size: 13px;
    line-height: ${remConvert(21)};
    background: linear-gradient(
      180deg,
      #417bae 0%,
      rgba(69, 134, 191, 0.94) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

export const ProgressMeterWrapper = styled.div`
  ${centeredJustifiedContentFlex}
  width: 100%;
  position: absolute;
`;
