import { ExamRawResponse, Exam } from 'src/store/types';
import { mapRawQuestions } from '../mapRawQuestions';

export const mapExamRawResponse = (rawResponse: ExamRawResponse): Exam => {
  const { id, trainingTypeId, quizId, status, createdAt, hasSubmissions } =
    rawResponse;
  return {
    id,
    trainingTypeId,
    quizId,
    status,
    createdAt,
    hasSubmissions,
    questions: mapRawQuestions(rawResponse.questions, rawResponse.choices),
  } as Exam;
};
