import React, { FC } from 'react';
import { Button as ButtonAntD } from 'antd';
import styled from 'styled-components';
import { LeftOutlined } from 'src/theme/icons';

interface ButtonProps {
  $altered?: boolean;
  className?: string;
}

interface BreadcrumbProps {
  altered?: boolean;
  className?: string;
  onClick: () => void;
  text: string;
}

const Button = styled(ButtonAntD)<ButtonProps>`
  align-items: center;
  background-color: transparent;
  border: ${({ $altered, theme }) =>
    $altered ? 'none' : `1px solid ${theme.colors.grayscale.moonGray}`};
  border-radius: 4px;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.darkBlue.main};
  display: flex;
  height: 48px;
  padding: 14px 20px;
  -webkit-box-shadow: none;

  &:hover,
  &:focus {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.darkBlue.main};
    border-color: ${({ theme }) => theme.colors.darkBlue.main};
  }

  &:after {
    display: none;
  }

  .label {
    color: ${({ $altered, theme }) =>
      $altered ? 'black' : theme.colors.darkBlue.main};
    font-size: 14px;
    font-weight: ${({ $altered }) => ($altered ? 'bold' : 'normal')};
    line-height: 20px;
    height: 20px;
    padding-bottom: 22px;
  }
`;

export const Breadcrumb: FC<BreadcrumbProps> = ({
  altered = false,
  className,
  text,
  onClick,
}) => (
  <Button
    className={className}
    $altered={altered}
    onClick={onClick}
    size="middle"
    type="default"
  >
    <LeftOutlined style={{ fontSize: 20 }} />
    <span className="label">{text}</span>
  </Button>
);
