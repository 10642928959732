import {
  FORM_SAVE_REQUEST,
  FORM_SAVE_SUCCESS,
  FORM_SAVE_ERROR,
  FORM_UPDATE_REQUEST,
  FORM_UPDATE_SUCCESS,
  FORM_UPDATE_ERROR,
  FormState,
  FormSaveSuccessAction,
  FormSaveRequestAction,
  FormSaveErrorAction,
  FormUpdateSuccessAction,
  FormUpdateRequestAction,
  FormUpdateErrorAction,
  FormSetSelectedAction,
  FORM_SET_SELECTED,
  FORM_ADD_NEW_QUESTION,
  FormAddNewQuestionAction,
  FormSetQuestionSelectedAction,
  FORM_SET_QUESTION_SELECTED,
  FORM_SET_QUESTION_SELECTED_RESET,
  FormSetQuestionSelectedResetAction,
  FormSaveEditedQuestionAction,
  FORM_SAVE_EDITED_QUESTION,
  FORM_LOAD_QUESTIONS_REQUEST,
  FORM_LOAD_QUESTIONS_SUCCESS,
  FORM_LOAD_QUESTIONS_ERROR,
  FormLoadQuestionsRequestAction,
  FormLoadQuestionsSuccessAction,
  FormLoadQuestionsErrorAction,
  FormMarkAsTouchedAction,
  FormMarkQuestionAsDeletedAction,
  FORM_MARK_AS_TOUCHED,
  FORM_MARK_QUESTION_AS_DELETED,
  FormCopyQuestionAction,
  FORM_COPY_QUESTION,
  FormMetadataState,
  FormCloneRequestAction,
  FormCloneSuccessAction,
  FormCloneErrorAction,
  FORM_CLONE_REQUEST,
  FORM_CLONE_SUCCESS,
  FORM_CLONE_ERROR,
  FormSetStatusRequestAction,
  FORM_SET_STATUS_REQUEST,
  FormSetStatusErrorAction,
  FormSetStatusSuccessAction,
  FORM_SET_STATUS_SUCCESS,
  FORM_SET_STATUS_ERROR,
  FormUpdateQuestionsSuccessAction,
  FORM_UPDATE_QUESTIONS,
  FormDeleteErrorAction,
  FormDeleteRequestAction,
  FormDeleteSuccessAction,
  FORM_DELETE_ERROR,
  FORM_DELETE_REQUEST,
  FORM_DELETE_SUCCESS,
  QuestionType,
  Question,
} from '../types';
import { showErrorMessage } from './error';

export const formSaveRequest = (
  form: Partial<FormState>,
): FormSaveRequestAction => ({
  type: FORM_SAVE_REQUEST,
  form,
});

export const formSaveSuccess = (form: FormState): FormSaveSuccessAction => ({
  type: FORM_SAVE_SUCCESS,
  form,
});

export const formSaveError = (
  errorMessage: string,
  originalError: Error,
): FormSaveErrorAction =>
  showErrorMessage(FORM_SAVE_ERROR, errorMessage, originalError);

export const formUpdateRequest = (
  form: FormState,
  questions?: Question[],
  metadata?: FormMetadataState,
): FormUpdateRequestAction => ({
  type: FORM_UPDATE_REQUEST,
  form,
  questions,
  metadata,
});

export const formUpdateSuccess = (
  form: FormState,
  questions?: Question[],
): FormUpdateSuccessAction => ({
  type: FORM_UPDATE_SUCCESS,
  form,
  questions,
});

export const formUpdateError = (
  errorMessage: string,
  originalError: Error,
): FormUpdateErrorAction =>
  showErrorMessage(FORM_UPDATE_ERROR, errorMessage, originalError);

export const setSelectedForm = (form: FormState): FormSetSelectedAction => ({
  type: FORM_SET_SELECTED,
  form,
});

export const formAddNewQuestion = (
  questionType: QuestionType,
  formId: number,
): FormAddNewQuestionAction => ({
  type: FORM_ADD_NEW_QUESTION,
  questionType,
  formId,
});

export const formSetQuestionSelected = (
  question: Question,
): FormSetQuestionSelectedAction => ({
  type: FORM_SET_QUESTION_SELECTED,
  question,
});

export const formSetQuestionSelectedReset =
  (): FormSetQuestionSelectedResetAction => ({
    type: FORM_SET_QUESTION_SELECTED_RESET,
  });

export const formLoadQuestionsRequest = (
  formId: number,
  assessmentId?: number,
): FormLoadQuestionsRequestAction => ({
  type: FORM_LOAD_QUESTIONS_REQUEST,
  formId,
  assessmentId,
});

export const formLoadQuestionsSuccess = (
  questions: Question[],
): FormLoadQuestionsSuccessAction => ({
  type: FORM_LOAD_QUESTIONS_SUCCESS,
  questions,
});

export const formLoadQuestionsError = (
  errorMessage: string,
  originalError: Error,
): FormLoadQuestionsErrorAction =>
  showErrorMessage(FORM_LOAD_QUESTIONS_ERROR, errorMessage, originalError);

export const formMarkAsTouched = (): FormMarkAsTouchedAction => ({
  type: FORM_MARK_AS_TOUCHED,
});

export const formCopyQuestion = (
  formId: number,
  questionId?: number,
  questionInternalId?: string,
): FormCopyQuestionAction => ({
  type: FORM_COPY_QUESTION,
  formId,
  questionId,
  questionInternalId,
});

export const formMarkQuestionAsDeleted = (
  questionId?: number,
  questionInternalId?: string,
): FormMarkQuestionAsDeletedAction => ({
  type: FORM_MARK_QUESTION_AS_DELETED,
  questionId,
  questionInternalId,
});

export const formSaveEditedQuestion = (
  originalQuestion: Question,
  partialQuestion: Partial<Question>,
): FormSaveEditedQuestionAction => ({
  type: FORM_SAVE_EDITED_QUESTION,
  partialQuestion,
  originalQuestion,
});

export const formCloneRequest = (formId: number): FormCloneRequestAction => ({
  type: FORM_CLONE_REQUEST,
  formId,
});

export const formCloneSuccess = (): FormCloneSuccessAction => ({
  type: FORM_CLONE_SUCCESS,
});

export const formCloneError = (
  errorMessage: string,
  originalError: Error,
): FormCloneErrorAction =>
  showErrorMessage(FORM_CLONE_ERROR, errorMessage, originalError);

export const formDeleteRequest = (formId: number): FormDeleteRequestAction => ({
  type: FORM_DELETE_REQUEST,
  formId,
});

export const formDeleteSuccess = (formId: number): FormDeleteSuccessAction => ({
  type: FORM_DELETE_SUCCESS,
  formId,
});

export const formDeleteError = (
  errorMessage: string,
  originalError: Error,
): FormDeleteErrorAction =>
  showErrorMessage(FORM_DELETE_ERROR, errorMessage, originalError);

export const formSetStatusRequest = (
  formId: number,
  status: boolean,
): FormSetStatusRequestAction => ({
  type: FORM_SET_STATUS_REQUEST,
  formId,
  status,
});

export const formSetStatusSuccess = (
  form: FormState,
): FormSetStatusSuccessAction => ({
  type: FORM_SET_STATUS_SUCCESS,
  form,
});

export const formSetStatusError = (
  errorMessage: string,
  originalError: Error,
): FormSetStatusErrorAction =>
  showErrorMessage(FORM_SET_STATUS_ERROR, errorMessage, originalError);

export const formUpdateQuestions = (
  questions: Question[],
): FormUpdateQuestionsSuccessAction => ({
  type: FORM_UPDATE_QUESTIONS,
  questions,
});
