import { Loader } from 'src/store/types';
import {
  TrainingTypeActionTypes,
  TrainingTypesState,
  TRAINING_TYPES_LOAD_FAILED,
  TRAINING_TYPES_LOAD_REQUEST,
  TRAINING_TYPES_LOAD_SUCCESS,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const trainingTypesInitialState: TrainingTypesState = {
  trainingTypes: [],
  loader: loaderInitialState,
};

export const trainingTypeListingReducer = (
  state = trainingTypesInitialState,
  action: TrainingTypeActionTypes,
): TrainingTypesState => {
  switch (action.type) {
    case TRAINING_TYPES_LOAD_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case TRAINING_TYPES_LOAD_SUCCESS: {
      return {
        trainingTypes: action.trainingTypes,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_TYPES_LOAD_FAILED: {
      return {
        trainingTypes: [],
        loader: {
          ...loaderInitialState,
        },
      };
    }
    default:
      return state;
  }
};
