import { Alert, Tabs, Tag } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { RoutesEnum } from 'src/shared/enums';
import { TopNavbar } from 'src/components/TopNavbar';
import { Button } from 'src/components/Button';
import actions from 'src/store/actions';
import {
  getCurrentExam,
  getExamLoader,
  getTrainingTypes,
} from 'src/store/selectors';

import { QuestionConfigForm, ExamConfigForm } from '../../components';
import { ResourceTypeEnum } from '../../enums';

interface RouteParams {
  id: string;
}

const { TabPane } = Tabs;

const TabContainer = styled(Tabs)`
  padding-top: 50px;
`;

const DEFAULT_TITLE = 'New exam';

export const ExamConfigPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: examId } = useParams<RouteParams>();

  const currentExamLoader = useSelector(getExamLoader);
  const currentExam = useSelector(getCurrentExam);
  const trainingTypes = useSelector(getTrainingTypes);

  const [headerTitle, setHeaderTitle] = useState<string>(DEFAULT_TITLE);

  const { id, trainingTypeId, status, questions } = currentExam ?? {};

  useEffect(() => {
    dispatch(actions.trainingTypesLoadRequest());
    if (examId) {
      dispatch(actions.examLoadRequest(Number(examId)));
    }
  }, []);

  // Update header title based on selected training type
  useEffect(() => {
    if (trainingTypes && trainingTypeId) {
      const selectedType = trainingTypes.find(
        (trainingType) => trainingType.id === trainingTypeId,
      );
      if (selectedType) {
        setHeaderTitle(selectedType.name);
      }
    }

    return () => {
      setHeaderTitle(DEFAULT_TITLE);
    };
  }, [trainingTypeId, trainingTypes]);

  return (
    <>
      <TopNavbar
        title={headerTitle}
        extraOptions={
          <>
            {status && (
              <Tag color={status !== 'ACTIVE' ? 'processing' : 'success'}>
                {currentExam.status}
              </Tag>
            )}
            <Button
              title="Close"
              onClick={() => history.replace(RoutesEnum.EXAM_MANAGEMENT)}
            />
          </>
        }
      />
      {status === 'ACTIVE' && (
        <Alert
          message="Editing quiz questions is limited because this quiz has already been submitted. If you need to make changes, make a copy and activate it."
          type="info"
          showIcon
        />
      )}
      <TabContainer
        type="line"
        size="large"
        tabPosition="left"
        defaultActiveKey="2"
      >
        <TabPane tab="1 - Exam Details" key={1}>
          <ExamConfigForm exam={currentExam} trainingTypes={trainingTypes} />
        </TabPane>
        <TabPane tab="2 - Exam Questions" key={2} disabled={!currentExam}>
          <QuestionConfigForm
            questions={questions}
            resourceId={id}
            resourceType={ResourceTypeEnum.EXAM}
            isLoading={currentExamLoader?.loading}
            isResourceActive={status === 'ACTIVE'}
          />
        </TabPane>
      </TabContainer>
    </>
  );
};
