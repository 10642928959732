import { createSelector } from 'reselect';
import { GLOBAL_ROLES } from 'src/constants/user';
import { Loader, RootState, User, UserListingIndexedItem } from '../types';

export const getUser = (state: RootState): User => state.user.user;

export const getUserLoader = (state: RootState): Loader => state.user.loader;

export const getCompanyUsers = (
  state: RootState,
  valueToSearch = '',
  includeAdmins = true,
): UserListingIndexedItem => {
  let users: User[] = Object.values(state.company.userListing.users);
  if (!includeAdmins) {
    users = users.filter(({ role }: User) => !GLOBAL_ROLES.includes(role));
  }
  if (valueToSearch) {
    const capsValue = valueToSearch.toUpperCase();
    users = users.filter(({ name, email, role }: User) => {
      return (
        name.toUpperCase().includes(capsValue) ||
        email.toUpperCase().includes(capsValue) ||
        role.toUpperCase().includes(capsValue)
      );
    });
  }
  return users.reduce((users: UserListingIndexedItem, user: User) => {
    return {
      ...users,
      [user.id]: user,
    };
  }, {});
};

export const getCompanyUsersSize = (
  state: RootState,
  valueToSearch = '',
  includeAdmins = true,
): number => {
  return Object.values(state.company.userListing.users).length;
};

export const getUserFromCompanyById = createSelector(
  (state: RootState) => getCompanyUsers(state),
  (_: RootState, id: number | undefined) => id ?? 0,
  (indexedUsers: UserListingIndexedItem, id: number) => indexedUsers[id],
);
