import React, { FC } from 'react';
import moment from 'moment';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { updateDocumentAnswer } from 'src/store/actions/document';
import { DatePicker } from 'src/components/DatePicker';

export interface SetCompletionDateFormProps {
  form: FormInstance;
  answerId?: number;
  interviewId?: number;
}

interface FormValues {
  actionItemCompletedAt: moment.Moment;
}

const FormContainer = styled(Form)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
      }
    }
  }
`;

export const SetCompletionDateForm: FC<SetCompletionDateFormProps> = ({
  form,
  answerId,
  interviewId,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (values: FormValues) => {
    const answerUpdates = {
      actionItemCompletedAt: values.actionItemCompletedAt.format('MM/DD/YYYY'),
    };

    answerId &&
      interviewId &&
      dispatch(updateDocumentAnswer(answerId, interviewId, answerUpdates));

    form.resetFields();
  };

  return (
    <FormContainer
      form={form}
      onFinish={(values) => handleSubmit(values as FormValues)}
    >
      <Form.Item
        name="actionItemCompletedAt"
        rules={[{ required: true, message: 'Please select a valid date' }]}
      >
        <DatePicker format={'MM/DD/YYYY'} />
      </Form.Item>
    </FormContainer>
  );
};
