import {
  TemplateState,
  TemplatesLoadSuccessAction,
  TEMPLATES_LOAD_REQUEST,
  TemplatesLoadRequestAction,
  TEMPLATES_LOAD_SUCCESS,
  TemplatesLoadErrorAction,
  TEMPLATES_LOAD_ERROR,
  AddTemplateToListAction,
  ADD_TEMPLATE_TO_LIST,
  RemoveTemplateFromListAction,
  REMOVE_TEMPLATE_FROM_LIST,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const templatesLoadRequest = (
  assessmentTypeId?: number,
): TemplatesLoadRequestAction => ({
  type: TEMPLATES_LOAD_REQUEST,
  assessmentTypeId,
});

export const templatesLoadSuccess = (
  templates: TemplateState[],
): TemplatesLoadSuccessAction => ({
  type: TEMPLATES_LOAD_SUCCESS,
  templates,
});

export const templatesLoadError = (
  errorMessage: string,
  originalError: Error,
): TemplatesLoadErrorAction =>
  showErrorMessage(TEMPLATES_LOAD_ERROR, errorMessage, originalError);

export const addTemplateToList = (
  template: TemplateState,
): AddTemplateToListAction => ({
  type: ADD_TEMPLATE_TO_LIST,
  template,
});

export const removeTemplateFromList = (
  templateId: number,
): RemoveTemplateFromListAction => ({
  type: REMOVE_TEMPLATE_FROM_LIST,
  templateId,
});
