/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import MDEditor from '@uiw/react-md-editor';
import { QuestionListingIndexedType, RootState } from 'src/store/types';
import {
  getConstants,
  getIndexedQuestionListing,
  getVariables,
} from 'src/store/selectors';
import { QuestionTokenPreviewMemoized } from './QuestionTokenPreview';
import { VariableTokenPreviewMemoized } from './VariableTokenPreview';
import { ConditionalPreview } from './ConditionalPreview';
import { TocPreview } from './TocPreview';
import { ConflictResolver } from './ConflictResolver';
import { ConstantTokenPreviewMemoized } from './ConstantTokenPreview';
import { FootnotePreview } from './FootnotePreview';
import { useRegExpTokens } from './useRegExpTokens';

const reactStringReplace = require('react-string-replace');

interface TemplateEditorProps {
  source: string | undefined;
  onChange: (val?: string) => void;
  editorScrollTop?: number;
  setEditorScrollTop?: (y: number) => void;
}

const ReviewBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.red.main};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 50%;

  span {
    color: white;
    font-weight: bold;
    font-size: 10px;
  }
`;

const StyleOverride = styled.div`
  height: 100%;
  width: 100%;

  .w-md-editor {
    height: 100%;
  }

  .w-md-editor-content {
    height: 100%;
    padding: 10px;
  }

  & ::selection {
    background-color: rgb(24 144 255 / 40%);
    color: white;
  }

  .center {
    text-align: center;
  }

  a[href^='mailto:'] {
    color: inherit;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
  }
`;

export const TemplatePreview: FC<TemplateEditorProps> = ({
  source,
  onChange,
}) => {
  const questions = useSelector<RootState, QuestionListingIndexedType>(
    (state: RootState) => getIndexedQuestionListing(state),
  );
  const variables = useSelector(getVariables);
  const constants = useSelector(getConstants);

  const {
    questionToken,
    variableToken,
    constantToken,
    tocToken,
    centerTextTag,
    underlineTextTag,
    conditionalToken,
    conflictToken,
    footnoteToken,
  } = useRegExpTokens(questions, variables, constants);

  const renderers = {
    text: (props: { value: string; nodeKey: string }) => {
      const { value, nodeKey } = props;

      const regex =
        /(\[[\n|\s]*if condition='[^'.]*'[\n|\s]*]|\[[\n\s]*\/if[\n\s]*]|\[q#[0-9]+]|\[v#[0-9]+]|\[c#[0-9]+]|\[toc]|<<<<<<< CURRENT|=======|<<<<<<< PARENT|\[review]|\[center].*\[\/center]|\[[\n|\s]*else[\n|\s]*]|\[footnote:.+?]|\[u].*\[\/u])/;

      if (regex.test(value)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        return reactStringReplace(value, regex, (match: string, _i: number) => {
          if (conflictToken.getRegExp?.().test(match)) {
            return (
              <ConflictResolver
                data={match}
                nodeKey={nodeKey}
                source={source as string}
                setSource={onChange}
                conflictToken={conflictToken}
              />
            );
          }

          if (match === '[review]') {
            return (
              <Tooltip title="Please review changes to enable saving">
                <ReviewBadge>
                  <span>Some changes need your review</span>
                </ReviewBadge>
              </Tooltip>
            );
          }

          if (match.startsWith('[footnote:')) {
            return (
              <FootnotePreview content={footnoteToken.getContent(match)} />
            );
          }

          if (conditionalToken.getConditionalElseRegex().test(match)) {
            return <ConditionalPreview isElseTag />;
          }

          if (conditionalToken.getConditionalIfRegex().test(match)) {
            return (
              <ConditionalPreview
                condition={conditionalToken.getConditionStatement(match)}
              />
            );
          }

          if (conditionalToken.getConditionalEndIfRegex().test(match)) {
            return <ConditionalPreview isClosingTag />;
          }

          if (questionToken.getRegExp?.().test(match)) {
            const questionId = match.replace(
              questionToken.getRegExp(),
              questionToken.getReplacement!(),
            );
            return (
              <QuestionTokenPreviewMemoized
                questionId={questionId ? questionId : ''}
              />
            );
          }

          if (variableToken.getRegExp?.().test(match)) {
            const variableId = match.replace(
              variableToken.getRegExp(),
              variableToken.getReplacement!(),
            );
            return (
              <VariableTokenPreviewMemoized
                variableId={variableId ? variableId : ''}
              />
            );
          }

          if (constantToken.getRegExp?.().test(match)) {
            const constantId = match.replace(
              constantToken.getRegExp(),
              constantToken.getReplacement!(),
            );
            return (
              <ConstantTokenPreviewMemoized
                constantId={constantId ? constantId : ''}
              />
            );
          }

          if (tocToken.getRegExp?.().test(match)) {
            return <TocPreview />;
          }

          if (centerTextTag.getRegExp?.().test(match)) {
            const text = match.replace(
              centerTextTag.getRegExp(),
              centerTextTag.getReplacement!(),
            );
            return <div className="center">{text}</div>;
          }

          if (underlineTextTag.getRegExp?.().test(match)) {
            const text = match.replaceAll(
              underlineTextTag.getRegExp(),
              (_: string, content: string) => {
                return `<u>${content}</u>`;
              },
            );

            return <span dangerouslySetInnerHTML={{ __html: text }} />;
          }

          return match;
        });
      }

      return `${value}`;
    },
  };

  return (
    <StyleOverride>
      <MDEditor
        hideToolbar
        preview="preview"
        value={source}
        previewOptions={{
          renderers,
          unwrapDisallowed: true,
          disallowedTypes: ['link'],
        }}
        height="100%"
      />
    </StyleOverride>
  );
};
