import { call, takeEvery, put } from 'redux-saga/effects';
import { examService } from 'src/features/learning';
import actions from 'src/store/actions';

import {
  EXAM_CREATE_REQUEST,
  EXAM_QUESTION_CREATE_REQUEST,
  EXAM_QUESTION_UPDATE_REQUEST,
  EXAM_LOAD_REQUEST,
  EXAM_UPDATE_REQUEST,
  EXAM_QUESTION_DELETE_REQUEST,
  EXAM_SUBMISSION_REQUEST,
  EXAM_SUBMISSION_RESPONSE_REQUEST,
  EXAM_CLONE_REQUEST,
  ExamCreateRequestAction,
  Exam,
  ExamQuestionCreateRequestAction,
  ExamQuestionUpdateRequestAction,
  ExamLoadRequestAction,
  ExamUpdateRequestAction,
  ExamQuestionDeleteRequestAction,
  ExamByUserResponse,
  ExamSubmissionRequestAction,
  ExamSubmissionResponseRequestAction,
  ExamSubmission,
  ExamCloneRequestAction,
  QuizQuestion,
  EXAM_LOAD_SUBMISSION_REQUEST,
  ExamLoadSubmissionRequestAction,
  ExamQuizLoadRequestAction,
  EXAM_QUIZ_LOAD_REQUEST,
} from '../types';

export const createExam = function* async({
  config,
}: ExamCreateRequestAction): Generator {
  try {
    const result = yield call(examService.create, config);
    yield put(actions.examCreateSuccess(result as Exam));
  } catch (err) {
    yield put(actions.examCreateError('Failed to create exam', err as Error));
  }
};

export const updateExam = function* async({
  examId,
  config,
}: ExamUpdateRequestAction): Generator {
  try {
    const result = yield call(examService.update, examId, config);
    yield put(actions.examUpdateSuccess(result as Exam));
  } catch (err) {
    yield put(actions.examUpdateError('Failed to update exam', err as Error));
  }
};

export const createExamQuestion = function* async({
  examId,
}: ExamQuestionCreateRequestAction): Generator {
  try {
    const result = yield call(examService.createQuestion, examId);
    yield put(actions.examQuestionCreateSuccess(result as QuizQuestion));
  } catch (err) {
    yield put(
      actions.examQuestionCreateError(
        'Failed to create exam question',
        err as Error,
      ),
    );
  }
};

export const updateExamQuestion = function* async({
  examId,
  question,
}: ExamQuestionUpdateRequestAction): Generator {
  try {
    const result = yield call(examService.updateQuestion, examId, question);
    yield put(actions.examQuestionUpdateSuccess(result as QuizQuestion));
  } catch (err) {
    yield put(
      actions.examQuestionUpdateError(
        'Failed to save exam question',
        err as Error,
      ),
    );
  }
};

export const deleteExamQuestion = function* async({
  examId,
  questionId,
}: ExamQuestionDeleteRequestAction): Generator {
  try {
    yield call(examService.deleteQuestion, examId, questionId);
    yield put(actions.examQuestionDeleteSuccess(questionId));
  } catch (err) {
    yield put(
      actions.examQuestionDeleteError(
        'Failed to delete exam question',
        err as Error,
      ),
    );
  }
};

export const loadExam = function* async({
  examId,
  loadSubmission,
  shuffled,
}: ExamLoadRequestAction): Generator {
  try {
    let exam;

    if (!examId) {
      exam = (yield call(examService.getByUser)) as ExamByUserResponse;
      examId = exam.id;
    } else {
      exam = yield call(examService.getById, examId, shuffled);
    }

    if (loadSubmission) {
      yield put(actions.examSubmissionRequest(examId));
    }

    yield put(actions.examLoadSuccess({ ...(exam as any) } as Exam));
  } catch (err) {
    const errorMessage =
      err instanceof Error
        ? err.message
        : 'Company does not have an open period';
    yield put(actions.examLoadError(errorMessage, err as Error));
  }
};

export const createExamSubmission = function* async({
  examId,
}: ExamSubmissionRequestAction): Generator {
  try {
    const result = yield call(examService.createSubmission, examId);
    yield put(actions.examSubmissionSuccess(result as ExamSubmission));
  } catch (err) {
    const errorMessage =
      err instanceof Error ? err.message : 'Failed to create exam submission';
    yield put(actions.trainingSubmissionError(errorMessage, err as Error));
  }
};

export const loadExamQuiz = function* async(): Generator {
  try {
    const result = (yield call(examService.getExamQuizByUser)) as Exam;
    yield put(actions.examQuizLoadSuccessAction(result));
  } catch (err) {
    const errorMessage =
      err instanceof Error ? err.message : 'Failed to load exam for quiz';
    yield put(actions.trainingSubmissionError(errorMessage, err as Error));
  }
};

export const createExamResponse = function* async({
  examId,
  submissionId,
  questionId,
  choiceId,
  compute,
}: ExamSubmissionResponseRequestAction): Generator {
  try {
    yield call(
      examService.createSubmissionResponse,
      examId,
      submissionId,
      questionId,
      choiceId,
    );
    if (compute) {
      const result = yield call(
        examService.computeSubmission,
        examId,
        submissionId,
      );
      yield put(
        actions.examSubmissionResponseSuccess(result as ExamSubmission),
      );
    } else {
      yield put(actions.examSubmissionResponseSuccess());
    }
  } catch (err) {
    yield put(
      actions.examSubmissionResponseError(
        'Failed to create training submission response',
        err as Error,
      ),
    );
  }
};

export const cloneExam = function* async({
  examId,
}: ExamCloneRequestAction): Generator {
  try {
    const response = yield call(examService.cloneExam, examId);
    yield put(actions.examCloneSuccess(response as Exam));
  } catch (err) {
    yield put(
      actions.examCloneError('Failed to clone the given exam', err as Error),
    );
  }
};

export const loadExamSubmission = function* async({
  examId,
  submissionId,
}: ExamLoadSubmissionRequestAction): Generator {
  try {
    const response = yield call(
      examService.getSubmission,
      examId,
      submissionId,
    );
    yield put(actions.examLoadSubmissionSuccess(response as ExamSubmission));
  } catch (err) {
    yield put(
      actions.examLoadSubmissionError(
        'Failed to load exam submission',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(EXAM_CREATE_REQUEST, createExam),
    yield takeEvery(EXAM_QUESTION_CREATE_REQUEST, createExamQuestion),
    yield takeEvery(EXAM_QUESTION_UPDATE_REQUEST, updateExamQuestion),
    yield takeEvery(EXAM_QUESTION_DELETE_REQUEST, deleteExamQuestion),
    yield takeEvery(EXAM_LOAD_REQUEST, loadExam),
    yield takeEvery(EXAM_UPDATE_REQUEST, updateExam),
    yield takeEvery(EXAM_SUBMISSION_REQUEST, createExamSubmission),
    yield takeEvery(EXAM_SUBMISSION_RESPONSE_REQUEST, createExamResponse),
    yield takeEvery(EXAM_CLONE_REQUEST, cloneExam),
    yield takeEvery(EXAM_LOAD_SUBMISSION_REQUEST, loadExamSubmission),
    yield takeEvery(EXAM_QUIZ_LOAD_REQUEST, loadExamQuiz),
  ];
}
