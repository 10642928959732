import { DatePicker as DatePickerAntd } from 'antd';
import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

export const StyledDatePicker = styled(DatePickerAntd)`
  background: ${({ theme }): string => theme.colors.white.main} !important;
  border: ${remConvert(1)} solid rgba(0, 0, 0, 0.3) !important;
  border-radius: ${remConvert(4)} !important;
  height: ${remConvert(40)};
  width: 100%;
`;
