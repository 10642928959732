import {
  ConstantsLoadRequestAction,
  ConstantState,
  ConstantsLoadSuccessAction,
  ConstantsLoadErrorAction,
  CONSTANTS_LOAD_REQUEST,
  CONSTANTS_LOAD_SUCCESS,
  CONSTANTS_LOAD_ERROR,
} from 'src/store/types';
import { showErrorMessage } from './error';

export const constantsLoadRequest = (): ConstantsLoadRequestAction => ({
  type: CONSTANTS_LOAD_REQUEST,
});

export const constantsLoadSuccess = (
  constants: ConstantState[],
): ConstantsLoadSuccessAction => ({
  type: CONSTANTS_LOAD_SUCCESS,
  constants,
});

export const constantsLoadError = (
  errorMessage: string,
  originalError: Error,
): ConstantsLoadErrorAction =>
  showErrorMessage(CONSTANTS_LOAD_ERROR, errorMessage, originalError);
