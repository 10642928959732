import React, { FC } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

interface CardProps {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  description?: string;
  onClick?: () => void;
}

const { Title: TitleAntD } = Typography;

const Title = styled(TitleAntD)`
  && {
    color: ${({ theme }) => theme.colors.deepBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }
`;

const CardContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 181px;
  min-height: 181px;
  padding: 16px;
  width: 424px;
  min-width: 424px;

  .content {
    display: flex;
    flex-direction: column;
    line-height: 1;
  }

  p {
    color: ${({ theme }) => theme.colors.grayscale.darkGray};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 13px;
  }
`;

export const Card: FC<CardProps> = ({
  className,
  children,
  title,
  description,
  onClick,
}) => {
  return (
    <CardContainer className={className}>
      {title && <Title>{title.toUpperCase()}</Title>}
      <div className="content">
        {description && <p>{description}</p>}
        {children}
      </div>
    </CardContainer>
  );
};
