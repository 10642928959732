import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { TopNavbar } from 'src/components/TopNavbar';
import { Pagination } from 'src/components/Pagination';
import { PPDocumentListingTable } from 'src/features/risk-assessment/components/PPDocumentListingTable';
import { documentsByAssessmentLoadRequest } from 'src/store/actions/documentListing';
import { RootState, DocumentType } from 'src/store/types';
import { getDocuments } from 'src/store/selectors';

const CompanyListContainer = styled.div`
  height: 65%;
  overflow: auto;
  margin-bottom: 10px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const GeneratedPPDocumentsListingPage: FC<unknown> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const assessmentId = Number(query.get('assessmentId')) || 0;

  const documents = useSelector((state: RootState) =>
    getDocuments(state, {
      offset: (currentPage - 1) * pageSize,
      limit: currentPage * pageSize,
    }),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(documentsByAssessmentLoadRequest(assessmentId, DocumentType.PP));
  }, []);

  return (
    <>
      <TopNavbar
        title={'Generated P&P Documents'}
        subtitle={`(${documents.totalItems})`}
        prevRoute={null}
        withCompany
      />
      <PageContentContainer>
        <CompanyListContainer>
          <PPDocumentListingTable documents={documents.data} />
        </CompanyListContainer>
        <Pagination
          current={currentPage}
          items={documents.totalItems}
          pageSize={pageSize}
          onPageChange={setCurrentPage}
          onPageSizeChange={(size) => {
            setPageSize(size);
            setCurrentPage(1);
          }}
          sizes={[10, 25, 50]}
        />
      </PageContentContainer>
    </>
  );
};
