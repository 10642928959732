import { call, takeEvery, put, select } from 'redux-saga/effects';
import {
  FORM_SAVE_REQUEST,
  FORM_UPDATE_REQUEST,
  FORM_CLONE_REQUEST,
  FORM_SET_STATUS_REQUEST,
  FormSaveRequestAction,
  FormUpdateRequestAction,
  FormCloneRequestAction,
  FormSetStatusRequestAction,
  FORM_ADD_NEW_QUESTION,
  FormDeleteRequestAction,
  FORM_DELETE_REQUEST,
} from 'src/store/types/form';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';
import { FormState, Question, QuestionList } from '../types';
import { getSelectedFormQuestions } from '../selectors';
import { formSetQuestionSelected } from '../actions/form';

export const saveForm = function* async({
  form: formData,
}: FormSaveRequestAction): Generator {
  try {
    const form = yield call(api.saveForm, formData);
    yield put(actions.formSaveSuccess(form as FormState));
  } catch (err) {
    yield put(actions.formSaveError('Failed to save form', err as Error));
  }
};

export const updateForm = function* async({
  form,
  questions,
  metadata,
}: FormUpdateRequestAction): Generator {
  try {
    const questionsToPersist = questions?.filter((q) => !q.temp);

    const updatedData = yield call(
      api.updateForm,
      form,
      questionsToPersist,
      metadata,
    );

    const { form: updatedForm, questions: updatedQuestions } = updatedData as {
      form: FormState;
      questions: Question[];
    };

    yield put(actions.formUpdateSuccess(updatedForm, updatedQuestions));

    if (updatedForm.assessmentType) {
      yield put(actions.questionsLoadRequest(updatedForm.assessmentType.id));
    }
  } catch (err) {
    yield put(actions.formUpdateError('Failed to update form', err as Error));
  }
};

export const cloneForm = function* async({
  formId,
}: FormCloneRequestAction): Generator {
  try {
    yield call(api.cloneForm, formId);
    yield put(actions.formCloneSuccess());
    yield put(actions.formsLoadRequest(true));
  } catch (err) {
    yield put(actions.formCloneError('Failed to clone form', err as Error));
  }
};

export const setFormStatus = function* async({
  formId,
  status,
}: FormSetStatusRequestAction): Generator {
  try {
    const updatedData = yield call(api.updateFormStatus, formId, status);
    const data = updatedData as { form: FormState };
    yield put(actions.formSetStatusSuccess(data.form));
  } catch (err) {
    yield put(
      actions.formSetStatusError('Failed to updated form status', err as Error),
    );
  }
};

export const selectLastQuestionAdded = function* (): Generator {
  const formQuestions = yield select(getSelectedFormQuestions);
  const questionList = formQuestions as QuestionList;
  const lastQuestionAdded = questionList[questionList.length - 1];
  yield put(formSetQuestionSelected(lastQuestionAdded));
};

export const deleteForm = function* async({
  formId,
}: FormDeleteRequestAction): Generator {
  try {
    yield call(api.deleteForm, formId);
    yield put(actions.formDeleteSuccess(formId));
  } catch (err) {
    yield put(actions.formDeleteError('Failed to delete form', err as Error));
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(FORM_SAVE_REQUEST, saveForm),
    yield takeEvery(FORM_UPDATE_REQUEST, updateForm),
    yield takeEvery(FORM_CLONE_REQUEST, cloneForm),
    yield takeEvery(FORM_SET_STATUS_REQUEST, setFormStatus),
    yield takeEvery(FORM_ADD_NEW_QUESTION, selectLastQuestionAdded),
    yield takeEvery(FORM_DELETE_REQUEST, deleteForm),
  ];
}
