import {
  UserSummaryListingIndexedItem,
  UserSummaryState,
} from 'src/store/types';

export const sourceMapper = (
  users: UserSummaryListingIndexedItem,
): UserSummaryState[] => {
  const mappedTrainees = Object.values(users).map(
    (user: UserSummaryState): UserSummaryState => {
      return {
        id: user.id,
        email: user.email,
        name: user.name,
        trainingType: user.trainingType,
        progress: user.progress,
        score: user.score,
        lastLoginAt: user.lastLoginAt,
        role: user.role.charAt(0).toUpperCase() + user.role.slice(1),
        isTwoFactorAuthenticationEnabled: user.isTwoFactorAuthenticationEnabled,
      };
    },
  );

  return mappedTrainees;
};
