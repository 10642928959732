import {
  UserListingState,
  UserListingIndexedItem,
  CompanyActionTypes,
  COMPANY_LOAD_SUCCESS,
  UserFromResponse,
  ADD_USER_TO_LIST,
  MULTIPLE_USERS_SAVE_SUCCESS,
  CLEAR_2FA_SUCCESS,
  User,
  USER_DELETE_SUCCESS,
  USER_UPDATE_SUCCESS,
  GET_ADMIN_USERS_SUCCESS,
} from 'src/store/types';

const userListingInitialState: UserListingState = {
  users: {},
};

const mapUsers = (users: UserFromResponse[]) => {
  return users.reduce(
    (list: UserListingIndexedItem, user: UserFromResponse) => {
      const { id, interviews, trainingType, ...restData } = user;
      const interviewIds = interviews ? interviews.map((i) => i.id) : [];
      return {
        ...list,
        [id]: {
          id,
          interviewIds,
          trainingType,
          trainingTypeId: trainingType?.id,
          ...restData,
        },
      };
    },
    {},
  );
};

export const userListingReducer = (
  state = userListingInitialState,
  action: CompanyActionTypes,
): UserListingState => {
  switch (action.type) {
    case COMPANY_LOAD_SUCCESS: {
      const { users } = action.company;
      return { users: mapUsers(users) };
    }
    case ADD_USER_TO_LIST: {
      const { id, name, email, avatarImg, role } = action.user;
      const interviewIds = action.user.interviews
        ? action.user.interviews.map((i: any) => i.id)
        : [];
      return {
        users: {
          ...state.users,
          [id]: { id, name, email, avatarImg, interviewIds, role },
        },
      };
    }
    case MULTIPLE_USERS_SAVE_SUCCESS: {
      const { users } = action;
      return {
        users: {
          ...state.users,
          ...mapUsers(users),
        },
      };
    }
    case CLEAR_2FA_SUCCESS: {
      const { userId } = action;

      const updatedUser: User | undefined = state.users[userId]
        ? { ...state.users[userId], isTwoFactorAuthenticationEnabled: false }
        : undefined;

      const updatedUsersMap = updatedUser
        ? { ...state.users, [userId]: updatedUser }
        : { ...state.users };

      return {
        users: updatedUsersMap,
      };
    }
    case USER_DELETE_SUCCESS: {
      const updatedUserListing = { ...state.users };
      delete updatedUserListing[action.id];
      return {
        users: updatedUserListing,
      };
    }
    case USER_UPDATE_SUCCESS: {
      const updatedUser: User | undefined = state.users[action.user.id]
        ? { ...state.users[action.user.id], ...action.user }
        : action.user;

      const updatedUsersMap = { ...state.users, [action.user.id]: updatedUser };

      return {
        users: updatedUsersMap,
      };
    }
    case GET_ADMIN_USERS_SUCCESS: {
      const { users } = action;
      return { users: mapUsers(users) };
    }
    default:
      return state;
  }
};
