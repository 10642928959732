import { call, takeEvery, put } from 'redux-saga/effects';
import { examService } from 'src/features/learning';
import actions from 'src/store/actions';

import {
  ExamActivateRequestAction,
  ExamArchiveRequestAction,
  ExamDeleteRequestAction,
  ExamListingLoadRequestAction,
  ExamListingResponse,
  EXAM_ACTIVATE_REQUEST,
  EXAM_ARCHIVE_REQUEST,
  EXAM_DELETE_REQUEST,
  EXAM_LISTING_LOAD_REQUEST,
} from '../types';

export const loadExamListing = function* async({
  page,
  pageSize,
  name,
}: ExamListingLoadRequestAction): Generator {
  try {
    const result = yield call(examService.getAll, page, pageSize, name);
    yield put(actions.examListingLoadSuccess(result as ExamListingResponse));
  } catch (err) {
    yield put(
      actions.examListingLoadError('Failed to load exam list', err as Error),
    );
  }
};

export const deleteExam = function* async({
  examId,
}: ExamDeleteRequestAction): Generator {
  try {
    yield call(examService.delete, examId);
    yield put(actions.examDeleteSuccess(examId));
  } catch (err) {
    yield put(actions.examDeleteError('Failed to delete exam', err as Error));
  }
};

export const activateExam = function* async({
  examId,
}: ExamActivateRequestAction): Generator {
  try {
    yield call(examService.activate, examId);
    yield put(actions.examActivateSuccess(examId));
  } catch (err) {
    yield put(
      actions.examActivateError(
        'Review this exam before to activate',
        err as Error,
      ),
    );
  }
};

export const archiveExam = function* async({
  examId,
}: ExamArchiveRequestAction): Generator {
  try {
    yield call(examService.archive, examId);
    yield put(actions.examArchiveSuccess(examId));
  } catch (err) {
    yield put(actions.examArchiveError('Failed to archive exam', err as Error));
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(EXAM_LISTING_LOAD_REQUEST, loadExamListing),
    yield takeEvery(EXAM_DELETE_REQUEST, deleteExam),
    yield takeEvery(EXAM_ACTIVATE_REQUEST, activateExam),
    yield takeEvery(EXAM_ARCHIVE_REQUEST, archiveExam),
  ];
}
