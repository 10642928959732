import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { assessmentSaveRequest } from 'src/store/actions/assessment';
import {
  AssessmentInput,
  AssessmentTypeListingIndexedItem,
  AssessmentTypeState,
} from 'src/store/types';

export interface FormSetupAssessmentProps {
  assessmentForm: FormInstance;
  assessmentTypes: AssessmentTypeListingIndexedItem;
  companyId: number;
}

interface AssessmentFormValues {
  assessmentTypeId: number;
}

const FormContainer = styled(Form)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-color: transparent;
        height: 40px;
        padding-top: 6px;
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
    }
  }
`;

export const FormSetupAssessment: FC<FormSetupAssessmentProps> = ({
  assessmentForm,
  assessmentTypes,
  companyId,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (values: AssessmentFormValues) => {
    const assessmentFormInput: AssessmentInput = {
      typeId: values?.assessmentTypeId,
      companyId,
    };
    dispatch(assessmentSaveRequest(assessmentFormInput));
    return null;
  };

  return (
    <FormContainer
      form={assessmentForm}
      onFinish={(values) => {
        handleSubmit(values as AssessmentFormValues);
        assessmentForm.resetFields();
      }}
    >
      <Form.Item
        name="assessmentTypeId"
        rules={[
          { required: true, message: 'Please select an assessment type' },
        ]}
      >
        <Select placeholder="Select an assessment type">
          {Object.values(assessmentTypes).map((a: AssessmentTypeState) => (
            <Select.Option value={a.id} key={a.id}>
              {a.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </FormContainer>
  );
};
