import styled from 'styled-components';
import { Grid } from 'src/components/Grid';

export const CustomGrid = styled(Grid)`
  .ant-table table {
    .ant-table-tbody {
      tr.ant-table-row {
        td:last-child {
          display: table-cell;

          & > div {
            justify-content: center;
          }
        }
      }
    }
  }
`;

export const Options = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  .icon {
    font-size: 20px;
    padding-right: 10px;
    margin-right: 10px;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;
