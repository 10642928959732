import {
  AutoComplete as AutoCompleteAntd,
  Input as InputAntd,
  Tooltip,
} from 'antd';
import { AutoCompleteProps as AutoCompleteAntdProps } from 'antd/lib/auto-complete';
import { AsYouType, isPossibleNumber } from 'libphonenumber-js';
import React, { FC } from 'react';
import { CheckCircleTwoTone, WarningTwoTone } from 'src/theme/icons';
import { remConvert } from 'src/theme/utils';
import styled from 'styled-components';

interface AutoCompleteProps extends Omit<AutoCompleteAntdProps, 'onChange'> {
  className?: string;
  onBlur?: (args: any) => void;
  onSelect?: (v: string) => void;
  value?: string;
  onChange?: (value: string) => void;
  placeholder: string;
  optionData?: string[] | any;
  optionKey?: string;
  numericInput?: boolean;
  phoneInput?: boolean;
}

const AutoCompleteStyled = styled(AutoCompleteAntd)`
  .ant-select-selector {
    border: ${remConvert(0)} !important;
  }
`;

const TextInput = styled(InputAntd)`
  background: ${({ theme }): string => theme.colors.white.main};
  border: ${remConvert(1)} solid rgba(0, 0, 0, 0.3);
  border-radius: ${remConvert(4)};
`;

const getUniqueOptionValues = (
  options: { [k: string]: string }[],
  key: string,
) => Array.from(new Set(options.filter((o) => !!o[key]).map((o) => o[key])));

export const AutoComplete: FC<AutoCompleteProps> = ({
  className,
  onChange,
  placeholder = '',
  value,
  onBlur,
  optionData,
  optionKey,
  onSelect,
  numericInput = false,
  phoneInput = false,
}) => {
  const formatValue = (val = ''): string => {
    if (numericInput) return formatNumber(val);
    if (phoneInput) return new AsYouType('US').input(val);
    return val;
  };

  const validatePhoneNumber = (val = ''): boolean => {
    return isPossibleNumber(val, 'US');
  };

  const suggestions =
    optionData &&
    (optionKey
      ? getUniqueOptionValues(optionData, optionKey).map((value) => ({ value }))
      : (optionData as string[]).map((o) => ({ value: o })));

  const formatNumber = (value: string): string =>
    value.replaceAll(/[^0-9]/g, '');

  return (
    <div style={{ display: 'flex' }}>
      <AutoCompleteStyled
        className={className}
        options={suggestions}
        filterOption={(inputValue, option) =>
          (option?.value as string)
            .toUpperCase()
            ?.indexOf(inputValue.toUpperCase()) !== -1
        }
        value={formatValue(value)}
        onSelect={onSelect as any} //TODO remove any
        onChange={onChange as any} //TODO remove any
      >
        <TextInput
          autoComplete="off"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(e.target.value);
          }}
          placeholder={placeholder}
          onBlur={onBlur}
        />
      </AutoCompleteStyled>
      {phoneInput && value && (
        <>
          {validatePhoneNumber(value) ? (
            <Tooltip title={'Valid US phone number format'}>
              <CheckCircleTwoTone
                style={{
                  fontSize: '20px',
                  paddingLeft: '10px',
                  paddingTop: '10px',
                }}
                twoToneColor="#52c41a"
              />
            </Tooltip>
          ) : (
            <Tooltip title={'Invalid US phone number format'}>
              <WarningTwoTone
                style={{
                  fontSize: '20px',
                  paddingLeft: '10px',
                  paddingTop: '10px',
                }}
                twoToneColor="orange"
              />
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};
