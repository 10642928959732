import React, { FC } from 'react';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { Menu as MenuAntD, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SettingOutlined } from 'src/theme/icons';
import {
  formCloneRequest,
  formSetStatusRequest,
  formDeleteRequest,
} from 'src/store/actions/form';
import { confirmDialog } from 'src/shared/utils';

export interface FormSettingsProps {
  formId: number;
  formActive: boolean;
  showUpdateFormPanel: () => void;
}

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  width: 180px;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

const Menu = styled(MenuAntD)`
  border-radius: 4px;
  margin-top: -35px;
  margin-left: 12px;
  width: 180px;

  .ant-dropdown-menu-item:hover {
    &.title {
      background-color: white;
      cursor: default;
    }
  }

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightBlue.main}`};

    > span {
      font-weight: 600;
    }

    .title-icon {
      color: ${({ theme }) => theme.colors.lightBlue.main};
      font-size: 20px;
    }
  }

  .title-divider {
    color: red;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0px 4px;
    width: 172px;
  }

  span {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 14px;
  }
`;

const menu = (
  id: number,
  dispatch: Dispatch,
  formActive: boolean,
  showUpdateFormPanel: () => void,
) => (
  <Menu>
    <Menu.Item className="title" key="0">
      <span>Settings</span>
      <SettingOutlined className="title-icon" />
    </Menu.Item>

    <Menu.Item key="1">
      <Link to={`/forms/edit/${id}`}>
        <span>Edit content</span>
      </Link>
    </Menu.Item>

    <Menu.Divider />

    <Menu.Item key="2" onClick={showUpdateFormPanel}>
      <span>Change metadata</span>
    </Menu.Item>

    <Menu.Divider />

    <Menu.Item
      key="3"
      onClick={() =>
        confirmDialog({
          text: 'This action will clone the interview along with all the questions and options associated with it.',
          onOk: () => {
            dispatch(formCloneRequest(id));
          },
        })
      }
    >
      <span>Duplicate</span>
    </Menu.Item>

    <Menu.Divider />

    {formActive ? (
      <Menu.Item
        key="4"
        onClick={() =>
          confirmDialog({
            text: 'Setting this interview as "inactive" will make it unavailable for completion.',
            onOk: () => {
              dispatch(formSetStatusRequest(id, false));
            },
          })
        }
      >
        <span>Set inactive</span>
      </Menu.Item>
    ) : (
      <Menu.Item
        key="5"
        onClick={() =>
          confirmDialog({
            text: 'Setting this interview as "active" will make it available for user completion.',
            onOk: () => {
              dispatch(formSetStatusRequest(id, true));
            },
          })
        }
      >
        <span>Set active</span>
      </Menu.Item>
    )}

    <Menu.Divider />

    <Menu.Item
      key="6"
      onClick={() =>
        confirmDialog({
          text: 'Deleting a form will delete its questions too.',
          onOk: () => {
            dispatch(formDeleteRequest(id));
          },
        })
      }
    >
      <span>Delete</span>
    </Menu.Item>
  </Menu>
);

export const FormSettings: FC<FormSettingsProps> = ({
  formId,
  formActive,
  showUpdateFormPanel,
}) => {
  const dispatch = useDispatch();

  return (
    <Dropdown
      overlay={menu(formId, dispatch, formActive, showUpdateFormPanel)}
      trigger={['click']}
    >
      <DropdownIcon>
        <SettingOutlined />
      </DropdownIcon>
    </Dropdown>
  );
};
