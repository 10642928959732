import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProgressMeter } from 'src/components/ProgressMeter';
import { useAuth } from 'src/hooks/useAuth';
import { turnOn2FARequest } from 'src/store/actions/user';
import { getUser } from 'src/store/selectors';
import { AuthMethod, BodySMS } from 'src/types/common';
import { TwoFactorVerificationSMS } from '../../components/TwoFactorSMSForm';
import * as S from './Styles';

export const TwoFATurnOnSMSPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [code, setCode] = useState<string>('');
  const [sms, setSms] = useState<Partial<BodySMS>>({
    dialCode: undefined,
    phoneNumber: undefined,
  });
  const user = useSelector(getUser);
  const { hasTraineePendingOnboarding } = useAuth();

  const handleOnSubmit = (token: string, params: Partial<BodySMS>) => {
    const { dialCode, phoneNumber } = params;
    dispatch(turnOn2FARequest(token, AuthMethod.SMS, dialCode, phoneNumber));
  };

  const handleGoBack = () => {
    history.push({
      pathname: '/2fa/turn-on/',
    });
  };

  useEffect(() => {
    if (user.isSecondFactorAuthenticated) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  return (
    <S.PageContainer>
      <S.PageTitle>Two-step verification</S.PageTitle>
      <S.PageSubTitle>
        Two-step verification helps keep your account safe.
      </S.PageSubTitle>
      <TwoFactorVerificationSMS
        code={code}
        sms={sms}
        onSubmit={handleOnSubmit}
        onChangeCode={setCode}
        onChangeBodySMS={setSms}
      />
      <S.PageContentBottomContainer>
        <S.ProgressMeterContainer>
          <ProgressMeter hasOnboarding={hasTraineePendingOnboarding} step={4} />
        </S.ProgressMeterContainer>
      </S.PageContentBottomContainer>
      <S.Label onClick={handleGoBack}>Go back to 2-step selection</S.Label>
    </S.PageContainer>
  );
};
