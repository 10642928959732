import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'antd';
import { useDispatch } from 'react-redux';

import { SettingOutlined } from 'src/theme/icons';
import { useAuth } from 'src/hooks/useAuth/useAuth';

import {
  BasicUser,
  TrainingPeriodListingElement,
  UserRole,
} from 'src/store/types';
import { DropdownIcon, Options } from './UsersTableSettingsStyles';
import { MenuList } from './MenuList';

export interface UsersTableSettingsProps {
  user: BasicUser;
  onEdit: (userId: number) => void;
  onReleaseIndividualTraining?: (user: BasicUser) => void;
  openTrainingPeriod?: TrainingPeriodListingElement;
}

export const UsersTableSettings: FC<UsersTableSettingsProps> = ({
  user,
  onEdit,
  onReleaseIndividualTraining,
  openTrainingPeriod,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOfficer } = useAuth();

  return (
    <Options>
      <Dropdown
        overlay={MenuList({
          user,
          dispatch,
          history,
          onEdit,
          onReleaseIndividualTraining,
          openTrainingPeriod: openTrainingPeriod,
          userIsAdmin: user.role === UserRole.admin,
        })}
        trigger={['click']}
        disabled={!isOfficer}
      >
        <DropdownIcon>
          <SettingOutlined />
        </DropdownIcon>
      </Dropdown>
    </Options>
  );
};
