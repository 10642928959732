import {
  InterviewAssigneeActionTypes,
  AssigneeFromResponse,
  INTERVIEW_LOAD_ASSIGNEES_SUCCESS,
  INTERVIEW_CREATE_ASSIGNEE_SUCCESS,
  INTERVIEW_DELETE_ASSIGNEE_SUCCESS,
} from 'src/store/types';

export const questionAssigneeState: AssigneeFromResponse[] = [];

export const questionAssigneeReducer = (
  state: AssigneeFromResponse[] = questionAssigneeState,
  action: InterviewAssigneeActionTypes,
): AssigneeFromResponse[] => {
  switch (action.type) {
    case INTERVIEW_LOAD_ASSIGNEES_SUCCESS: {
      return [...action.assignees];
    }
    case INTERVIEW_CREATE_ASSIGNEE_SUCCESS: {
      return [...state, action.assignee];
    }
    case INTERVIEW_DELETE_ASSIGNEE_SUCCESS: {
      return [
        ...state.filter(
          (assignee) =>
            assignee.id !== action.assigneeId ||
            assignee.interviewId !== action.interviewId,
        ),
      ];
    }
    default:
      return state;
  }
};
