import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ButtonRectangular } from 'src/components/Button';
import { RoutesEnum } from 'src/shared/enums';
import { trainingLoadRequest } from 'src/store/actions/training';
import { getCurrentExam, getCurrentTraining } from 'src/store/selectors';
import {
  TrainingListingElement,
  TrainingSubmissionStatus,
} from 'src/store/types';
import { DownOutlined, UpOutlined } from 'src/theme/icons';
import * as M from 'src/theme/media';
import * as S from './Styles';

interface TrainingCollapsiblePanelRowProps {
  sectionName: string;
  sectionNumber: number;
  sectionTrainings: TrainingListingElement[];
  sectionOpened: boolean;
  nextTrainingId?: number;
  handlePlayVideo?: () => void;
}

export const TrainingCollapsiblePanelRow: FC<
  TrainingCollapsiblePanelRowProps
> = ({
  sectionName,
  sectionTrainings,
  sectionNumber,
  sectionOpened,
  nextTrainingId,
  handlePlayVideo,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openPanel, setOpenPanel] = useState<boolean>(sectionOpened);
  const currentTraining = useSelector(getCurrentTraining);
  const currentExam = useSelector(getCurrentExam);

  const sectionEstimatedTime = useMemo(() => {
    return sectionTrainings.reduce(
      (minutes: number, training: TrainingListingElement) => {
        return minutes + training.minutes;
      },
      0,
    );
  }, [sectionTrainings]);

  const handleOpenPanel = (): void => {
    setOpenPanel(!openPanel);
  };

  useEffect(() => {
    setOpenPanel(sectionOpened);
  }, [sectionNumber, sectionOpened]);

  const handleOnClickTraining = (training: TrainingListingElement): void => {
    if (!isTrainingDisabled(training)) {
      dispatch(trainingLoadRequest(training.id));
    }
  };

  const renderInstructions = () => {
    return (
      <S.TrainingCollapsiblePanelList>
        <S.TrainingCollapsiblePanelListItem
          selected={openPanel}
          disabled={false}
        >
          <p>
            Press “Play” to begin training. Each video is followed by a short
            quiz. After all course sections are complete, the final exam will be
            unlocked. A passing grade of 70% is needed for both quizzes and the
            final exam. Good luck!
          </p>
        </S.TrainingCollapsiblePanelListItem>
      </S.TrainingCollapsiblePanelList>
    );
  };

  const handleTakeQuizOnClick = (): void => {
    history.push(`${RoutesEnum.TRAINING_QUIZ}/${currentTraining.id}`);
  };

  const handlePlayOnClick = (): void => {
    setTimeout(() => {
      if (handlePlayVideo) {
        handlePlayVideo();
      }
    }, 1000);
  };

  const isTrainingDisabled = (
    sectionTraining: TrainingListingElement,
  ): boolean => {
    return (
      typeof sectionTraining.lastSubmissionStatus === 'undefined' &&
      sectionTraining.id !== currentTraining?.id &&
      sectionTraining.id !== nextTrainingId &&
      !isExamPassed()
    );
  };

  const isExamPassed = (): boolean => {
    return (
      currentExam?.lastSubmissionStatus === TrainingSubmissionStatus.PASSED
    );
  };

  const isNextTraining = (sectionTraining: TrainingListingElement): boolean => {
    return sectionTraining.id === nextTrainingId;
  };

  const isTrainingSubmissionInProgress = (
    sectionTraining: TrainingListingElement,
  ): boolean => {
    return (
      sectionTraining.lastSubmissionStatus ===
      TrainingSubmissionStatus.IN_PROGRESS
    );
  };

  const renderTrainingList = () => {
    return (
      <S.TrainingCollapsiblePanelList>
        {sectionTrainings.map((sectionTraining: TrainingListingElement) => {
          return (
            <S.TrainingCollapsiblePanelListItem
              key={sectionTraining.id}
              selected={sectionTraining.id === currentTraining?.id}
              onClick={() => handleOnClickTraining(sectionTraining)}
              disabled={isTrainingDisabled(sectionTraining)}
            >
              <S.TrainingInformation>
                {sectionTraining.name}
                {sectionTraining.lastSubmissionStatus ===
                  TrainingSubmissionStatus.PASSED && (
                  <img height={20} width={20} src={M.THIconComplete} />
                )}
                {sectionTraining.lastSubmissionStatus ===
                  TrainingSubmissionStatus.FAILED && (
                  <ButtonRectangular
                    title={'Retake Quiz'}
                    onClick={handleTakeQuizOnClick}
                  />
                )}
              </S.TrainingInformation>
              <S.TrainingEstimatedDuration>
                {(!sectionTraining.lastSubmissionStatus ||
                  isTrainingSubmissionInProgress(sectionTraining)) &&
                  !isTrainingDisabled(sectionTraining) &&
                  isNextTraining(sectionTraining) && (
                    <img src={M.THIconPlayGreen} onClick={handlePlayOnClick} />
                  )}
                {isExamPassed() && !isNextTraining(sectionTraining) && (
                  <img src={M.THIconPlayGray} onClick={handlePlayOnClick} />
                )}
                {sectionTraining.lastSubmissionStatus &&
                  !isExamPassed() &&
                  !isTrainingSubmissionInProgress(sectionTraining) && (
                    <img src={M.THIconPlayGray} onClick={handlePlayOnClick} />
                  )}
                {isTrainingDisabled(sectionTraining) && (
                  <img src={M.THIconPlayLightGray} />
                )}
                {sectionTraining.minutes}m
              </S.TrainingEstimatedDuration>
            </S.TrainingCollapsiblePanelListItem>
          );
        })}
      </S.TrainingCollapsiblePanelList>
    );
  };

  return (
    <div>
      <S.TrainingCollapsiblePanelRowHeader onClick={handleOpenPanel}>
        {openPanel && <UpOutlined />}
        {!openPanel && <DownOutlined />}
        <div>
          {sectionNumber === 0 ? (
            <span>{sectionName}</span>
          ) : (
            <span>{`${sectionNumber}. ${sectionName}`}</span>
          )}
          {sectionNumber === 0 ? (
            <span>{''}</span>
          ) : (
            <span>
              {sectionTrainings.length} parts ~ {sectionEstimatedTime}m
            </span>
          )}
        </div>
      </S.TrainingCollapsiblePanelRowHeader>
      {openPanel && sectionNumber > 0 && renderTrainingList()}
      {openPanel && sectionNumber === 0 && renderInstructions()}
    </div>
  );
};
