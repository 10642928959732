import { Loader } from './loader';
import { FormState } from './form';
import { ShowErrorAction } from './error';

export const FORMS_LOAD_REQUEST = 'FORMS_LOAD_REQUEST';
export const FORMS_LOAD_SUCCESS = 'FORMS_LOAD_SUCCESS';
export const FORMS_LOAD_ERROR = 'FORMS_LOAD_ERROR';
export const FORMS_REORDER_REQUEST = 'FORMS_REORDER_REQUEST';
export const FORMS_REORDER_SUCCESS = 'FORMS_REORDER_SUCCESS';
export const FORMS_REORDER_FAILED = 'FORMS_REORDER_FAILED';

export interface FormListingState {
  forms: FormListingIndexedItem;
  loader: Loader;
}

export interface FormListingIndexedItem {
  [key: string]: FormState;
}

export interface FormListingByCategory {
  [key: string]: FormState[];
}

export interface FormsLoadRequestAction {
  type: typeof FORMS_LOAD_REQUEST;
  withAssessmentType: boolean;
}

export interface FormsLoadSuccessAction {
  type: typeof FORMS_LOAD_SUCCESS;
  forms: FormState[];
}

export type FormsLoadErrorAction = ShowErrorAction<typeof FORMS_LOAD_ERROR>;

export interface FormsReOrderRequestAction {
  type: typeof FORMS_REORDER_REQUEST;
  forms: FormState[];
}

export interface FormsReOrderRequestSuccess {
  type: typeof FORMS_REORDER_SUCCESS;
}

export type FormsReOrderRequestFailed = ShowErrorAction<
  typeof FORMS_REORDER_FAILED
>;

export type FormListingActionTypes =
  | FormsLoadRequestAction
  | FormsLoadSuccessAction
  | FormsLoadErrorAction
  | FormsReOrderRequestAction
  | FormsReOrderRequestSuccess
  | FormsReOrderRequestFailed;
