/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  FormQuestionsState,
  RootState,
  QuestionListingIndexedType,
  QuestionList,
  Question,
  QuestionsState,
} from '../types';
import { getSelectedFormQuestions } from './form';

export const getLoadedForms = createSelector(
  (state: RootState) => state.questionListing.forms,
  (formList: FormQuestionsState[]) => {
    return formList.map((f: FormQuestionsState) => {
      const mappedQuestions = f.questions.map((q: QuestionsState) => {
        const formLabel = formList.find(
          ({ questions }: FormQuestionsState) =>
            !!questions.find(({ id }: QuestionsState) => id === q.id),
        )?.label;
        return { ...q, formLabel };
      });
      return { ...f, questions: mappedQuestions };
    });
  },
);

interface GetLoadedQuestionInfoParams {
  questionId: number;
}

export const getIndexedQuestionListing = createSelector(
  (state: RootState) => getLoadedForms(state),
  (formList): QuestionListingIndexedType => {
    return formList.reduce((questions: QuestionListingIndexedType, form) => {
      return form.questions.reduce(
        (tempQuestions, question) => ({
          ...tempQuestions,
          [question.id]: question,
        }),
        questions,
      );
    }, {});
  },
);

export const getLoadedQuestionInfo = createSelector(
  (state: RootState) => getLoadedForms(state),
  (_: RootState, { questionId }: GetLoadedQuestionInfoParams) => questionId,
  (forms, questionId) => {
    let found = undefined;
    forms.forEach((form: FormQuestionsState) => {
      if (form.questions && form.questions.length) {
        const question = form.questions.find(
          (question) => question.id === questionId,
        );
        if (question) {
          found = { ...question, formLabel: form.label };
          return;
        }
      }
    });
    return found;
  },
);

export const getAssessmentTypeQuestions = createSelector(
  (state: RootState) => getSelectedFormQuestions(state),
  (state: RootState) => getLoadedForms(state),
  (state: RootState) => getIndexedQuestionListing(state),
  (
    selectedFormQuestions,
    formList,
    indexedAssessmentTypeQuestions,
  ): QuestionList => {
    selectedFormQuestions.forEach(
      (q: Question) =>
        (indexedAssessmentTypeQuestions[String(q.id ?? q.internalId)] = q),
    );
    const replacedAssessmentTypeQuestions: QuestionList = Object.values(
      indexedAssessmentTypeQuestions,
    );
    return replacedAssessmentTypeQuestions.map((q: Question) => {
      const formLabel = formList.find(
        ({ questions }: FormQuestionsState) =>
          !!questions.find(({ id }: QuestionsState) => id === q.id),
      )?.label;
      return { ...q, formLabel };
    });
  },
);
