import React, { FC, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Alert, Space } from 'antd';

import LoginForm from 'src/features/auth/components/LoginForm';
import useQuery from 'src/hooks/useQuery/useQuery';
import { RoutesEnum } from 'src/shared/enums';
import { THShield } from 'src/theme/media';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { ProgressMeter } from 'src/components/ProgressMeter';
import { getUserOnboardingRequest } from 'src/store/actions/user';
import * as S from './Styles';

export const LoginPage: FC<unknown> = () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { is2FAauthenticated, user, hasPendingOnboarding } = useAuth();
  const id = query.get('id');

  const hasUserLoggedIn = () => {
    return user.id > 0 && user.email;
  };

  const canAuthenticate2FA = () => {
    return (
      !user.isSecondFactorAuthenticated && user.isTwoFactorAuthenticationEnabled
    );
  };

  const is2FADisabled = () => {
    return !user.isTwoFactorAuthenticationEnabled;
  };

  useEffect(() => {
    if (id && user.id === 0) {
      dispatch(getUserOnboardingRequest(+id));
    }
  }, []);

  useEffect(() => {
    if (is2FAauthenticated) {
      history.push({
        pathname: '/',
      });
    }

    if (hasUserLoggedIn()) {
      if (canAuthenticate2FA()) {
        history.push({
          pathname: RoutesEnum.TWO_FA_AUTH,
          search: hasPendingOnboarding ? null : search,
        });
      } else if (is2FADisabled()) {
        history.push({
          pathname: RoutesEnum.TWO_FA_TURN_ON,
          search: hasPendingOnboarding ? null : search,
        });
      }
    }
  }, [user, history, query]);

  return (
    <>
      <S.PageTitle>
        {!hasPendingOnboarding
          ? "We're glad you're back!"
          : 'Sign in using your new password'}
      </S.PageTitle>
      <S.PageContentContainer>
        <p>
          {!hasPendingOnboarding
            ? 'Please sign in to access your Total HIPAA account.'
            : 'Please sign in to continue setting up your account.'}
        </p>
        <LoginForm />
      </S.PageContentContainer>
      <S.PageContentBottomContainer>
        <img height={150} width={150} src={THShield} />
        <S.ProgressMeterContainer>
          <ProgressMeter hasOnboarding={hasPendingOnboarding} step={3} />
        </S.ProgressMeterContainer>
        <Link to={'/forgotten_password'}>
          <S.Label>Forgot password?</S.Label>
        </Link>
      </S.PageContentBottomContainer>
      {process.env.MAINTENANCE_DATE && (
        <Space
          direction="vertical"
          style={{
            width: '100%',
            marginTop: '20px',
          }}
        >
          <Alert
            message={`Please be advised that the system will be unavailable for scheduled maintenance and updates on ${process.env.MAINTENANCE_DATE}`}
            type="warning"
          />
        </Space>
      )}
    </>
  );
};
