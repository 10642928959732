import React, { FC } from 'react';
import styled from 'styled-components';
import { useIsTrainingRoute } from 'src/hooks/useIsTrainingRoute';
import { useTesting } from 'src/hooks/useTesting';
import { ComponentsEnum } from 'src/shared/enums';
import { TrainingMenu } from '../TrainingMenu';

export interface Props {
  children: React.ReactNode;
}

const Layout = styled.div`
  .site-layout {
    background-color: ${({ theme }): string =>
      theme.colors.grayscale.lightGray};
    width: 100%;
  }
`;

const TrainingLayout: FC<Props> = ({ children }) => {
  const { getTestingProps } = useTesting(ComponentsEnum.TRAINING_LAYOUT);
  const isTrainingRoute = useIsTrainingRoute();
  if (!isTrainingRoute) return <>{children}</>;
  return (
    <Layout {...getTestingProps('menu')}>
      <TrainingMenu />
      <Layout className="site-layout">
        <div>{children}</div>
      </Layout>
    </Layout>
  );
};

export default TrainingLayout;
