import { css } from 'styled-components';

export const centeredFlex = css`
  display: flex;
  align-items: center;
`;

export const centeredJustifiedContentFlex = css`
  display: flex;
  justify-content: center;
`;

export const baseOpenSans = css`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
`;

export const italicOpenSans = css`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.italic};
`;

export const normalOpenSans = css`
  ${baseOpenSans}
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const semiboldOpenSans = css`
  ${baseOpenSans}
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const boldOpenSans = css`
  ${baseOpenSans}
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
