import React, { ChangeEvent, FC, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, User, LoginRequestAction, Loader } from 'src/store/types';
import { getUser, getUserLoader } from 'src/store/selectors/user';
import { loginRequest } from 'src/store/actions/user';
import * as S from './Styles';

interface LoginFormProps {
  loginRequest: (username: string, password: string) => LoginRequestAction;
  user: User;
  userLoader: Loader;
}

const LoginForm: FC<LoginFormProps> = ({ loginRequest, user, userLoader }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const clearInputs = (): void => {
    setUsername('');
    setPassword('');
  };

  const handleSubmit = (): void => {
    loginRequest(username, password);
    clearInputs();
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const email = e.target.value;
    setUsername(email);
    setIsEmailValid(email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null);
  };

  return (
    <>
      <S.FormFieldsContainer>
        <S.LoginTextInput
          autoFocus
          error={userLoader?.error ? 'Invalid credentials' : ''}
          onChange={handleEmailChange}
          placeholder="Email"
          type="email"
          value={username}
          name="username"
          float
          autoComplete="on"
        />
        <S.LoginTextInput
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
          }}
          password
          placeholder="Password"
          value={password}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSubmit();
            }
          }}
          name="password"
          float
          type="password"
          autoComplete="on"
        />
      </S.FormFieldsContainer>
      <S.FormSubmitContainer>
        <S.LoginSubmitButton
          size="large"
          disabled={!username || !password || !isEmailValid}
          className="btn"
          title="Sign in"
          onClick={handleSubmit}
        />
      </S.FormSubmitContainer>
    </>
  );
};

const ConnectedLoginForm = connect(
  (state: RootState) => ({
    user: getUser(state),
    userLoader: getUserLoader(state),
  }),
  { loginRequest },
)(LoginForm);

export default ConnectedLoginForm;
