import {
  FolderObject,
  TemplatesListingIndexedInterface,
  TemplateState,
} from 'src/store/types';
import {
  FoldersTableRowInterface,
  TableRowInterface,
} from './TemplateListingTable';

export const dataMapperFolders = (
  templatesData: TemplatesListingIndexedInterface,
): FoldersTableRowInterface[] =>
  Object.keys(templatesData).map((folder, index) => {
    return { folder, id: index };
  });

export const dataMapper = (
  templatesData: TemplatesListingIndexedInterface,
  folder: string,
): TableRowInterface[] => {
  const childTemplates = Object.values(templatesData)
    .reduce(
      (
        tempAllTemplates: TemplateState[],
        folder: FolderObject,
      ): TemplateState[] => {
        return [...tempAllTemplates, ...folder.templates];
      },
      [],
    )
    .filter((t: TemplateState) => !!t.parentTemplate);
  const allParentTemplateIds = childTemplates.map(
    (t: TemplateState): number => t.parentTemplate?.id as number,
  );
  const uniqueParentTemplateIds = [...new Set<number>(allParentTemplateIds)];

  return templatesData[folder].templates.map((template: TemplateState) => {
    return {
      id: template.id,
      title: template.title,
      assessmentTypeLabel: template.assessmentType
        ? template.assessmentType.label
        : '',
      parentTemplateTitle: template.parentTemplate
        ? template.parentTemplate?.title
        : null,
      parentTemplateId: template.parentTemplate
        ? template.parentTemplate.id
        : null,
      needsReview: template.needsReview,
      hasChildTemplates: uniqueParentTemplateIds.includes(template.id),
    } as TableRowInterface;
  });
};
