import React, { FC, useState } from 'react';
import { AutoComplete } from 'src/features/risk-assessment/components/AutoCompleteInput';
import { AnswerData, Answer, Question } from 'src/store/types';

interface PhoneFieldProps {
  onChange: (phone: string) => void;
  value: string;
  updateAnswer: (answerData: AnswerData) => void;
  answer: Answer;
  suggestions?: string[];
  question: Question;
}

export const PhoneField: FC<PhoneFieldProps> = ({
  onChange,
  value,
  updateAnswer,
  answer,
  suggestions,
  question,
}) => {
  const [blurIgnoreUpdate, setBlurIgnoreUpdate] = useState(false);

  const disableSuggestions = !question || !question.isSmart;
  const options = disableSuggestions || !suggestions ? [] : suggestions;

  const triggerAnswerUpdate = (selectVal?: string) => {
    updateAnswer({
      value: selectVal || value,
      id: answer?.id,
      internalId: answer?.internalId,
    });
  };

  const onBlur = () => {
    if (blurIgnoreUpdate) {
      setBlurIgnoreUpdate(false);
      return;
    }

    setTimeout(() => triggerAnswerUpdate(), 100);
  };

  const onSelect = (value: string) => {
    triggerAnswerUpdate(value);
    setBlurIgnoreUpdate(true);
  };

  return (
    <AutoComplete
      onChange={onChange}
      optionData={options}
      value={value}
      placeholder="(XXX) XXX-XXXX"
      onBlur={onBlur}
      onSelect={onSelect}
      phoneInput
    />
  );
};
