import {
  FEATURE_FLAG_LOAD_SUCCESS,
  FeatureFlagActionTypes,
  FeatureFlagState,
} from 'src/store/types';

const initialFeatureFlagState: FeatureFlagState = {
  modules: {},
  completed: false,
};

export const featureFlagReducer = (
  state: FeatureFlagState = initialFeatureFlagState,
  action: FeatureFlagActionTypes,
): FeatureFlagState => {
  switch (action.type) {
    case FEATURE_FLAG_LOAD_SUCCESS: {
      return {
        modules: { ...action.featureFlags },
        completed: true,
      };
    }
    default:
      return state;
  }
};
