import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import {
  commentShowSelectedQuestion,
  commentShowSelectedText,
} from 'src/store/actions/comment';
import { threadUpdateRequest } from 'src/store/actions/thread';
import {
  getCompanyUsers,
  getLoadedThreadsSorted,
  getThreadById,
  getThreadListingLoader,
} from 'src/store/selectors';
import {
  CommentFromResponse,
  FormState,
  RootState,
  Thread,
  ThreadListingFilter,
  ThreadListingSortOrder,
  User,
} from 'src/store/types';
import {
  CheckSquareOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  UnlockOutlined,
} from 'src/theme/icons';
import styled from 'styled-components';
import { DialogComment } from '.';

interface DialogListingProps {
  assessmentId?: number;
  documentDraftId?: number;
  threadId?: number;
  formList?: FormState[];
  setSelectedQuestionId?: (questionId?: number) => void;
  setSelectedThreadId: (threadId?: number) => void;
  filter: ThreadListingFilter;
  handleReply: (parentId: number, replyingToId: number) => void;
  handleDelete: (commentId: number) => void;
  questionIds?: { [threadId: number]: number };
  visibleQuestionIds?: number[];
  setSelectedLine?: (line?: number) => void;
  setSelectedRefKey?: (refKey?: string) => void;
  areThreadsWithoutQuestion?: boolean;
}

const MainContainer = styled.div`
  padding: 0 24px;
  flex-grow: 1;
`;

const ThreadContainer = styled.div<{
  isQuestionVisible: boolean;
  isResolved?: boolean;
}>`
  background-color: ${({ isQuestionVisible, isResolved, theme }) =>
    !isQuestionVisible || isResolved
      ? theme.colors.grayscale.lightGray
      : 'white'};
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 8px;

  h3 {
    color: ${({ theme, isResolved }) =>
      isResolved ? theme.colors.grayscale.preDark : theme.colors.darkBlue.main};
    font-size: 13px;
    line-height: 18px;
    margin: 4px 0 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      display: flex;
      flex-grow: 1;
      margin: 0;

      &.anticon {
        flex-grow: 0;
        font-size: 20px;
      }

      &#thread-title {
        text-decoration: ${({ isQuestionVisible }) =>
          isQuestionVisible ? 'none' : 'line-through'};
      }

      &#not-visible-msg {
        color: ${({ theme }) => theme.colors.grayscale.preDark};
        font-size: 9px;
      }
    }
  }
`;

const CommentsContainer = styled.div``;

export const DialogListing: FC<DialogListingProps> = ({
  assessmentId,
  documentDraftId,
  threadId,
  filter,
  formList,
  setSelectedQuestionId,
  setSelectedThreadId,
  handleReply,
  handleDelete,
  questionIds,
  visibleQuestionIds,
  setSelectedLine,
  setSelectedRefKey,
  areThreadsWithoutQuestion,
}) => {
  const { isAdmin } = useAuth();
  const threads = useSelector((state: RootState): Thread[] =>
    getLoadedThreadsSorted(
      state,
      { fromAssessment: !!assessmentId, fromDocumentDraft: !!documentDraftId },
      filter,
      isAdmin,
      ThreadListingSortOrder.questionId,
      formList,
    ),
  );
  const dispatch = useDispatch();
  const users = useSelector(getCompanyUsers);
  const selectedThread = useSelector((state: RootState) =>
    getThreadById(state, Number(threadId)),
  );
  const threadListingLoader = useSelector(getThreadListingLoader);

  const handleSelection = ({ id: threadKey, refKey, questionId }: Thread) => {
    if (threadId === threadKey) {
      // Hide thread
      setSelectedQuestionId && setSelectedQuestionId(undefined);

      setSelectedThreadId(undefined);

      setSelectedLine && setSelectedLine(undefined);

      setSelectedRefKey && setSelectedRefKey(undefined);
    } else {
      // Show thread
      setSelectedThreadId(threadKey);

      if (questionId && questionIds && setSelectedQuestionId) {
        // Assessment Report case
        setSelectedQuestionId(questionIds[threadKey] ?? undefined);

        questionId &&
          dispatch(commentShowSelectedQuestion(`question${questionId}`));
      }

      if (refKey) {
        // Document Draft case
        dispatch(commentShowSelectedText(`${refKey}`));

        setSelectedRefKey && setSelectedRefKey(refKey);

        setSelectedLine && setSelectedLine(undefined);
      }
    }
  };

  const getThreadTitle = (thread: Thread): string => {
    if (thread.title) {
      return thread.title;
    }
    if (thread.comments && thread.comments[0]) {
      return thread.comments[0].comment;
    }
    return 'Open Discussion';
  };

  const hasAnyAdminNoteInside = (comment: CommentFromResponse) => {
    if (comment.adminOnly) {
      return true;
    }
    return (
      comment.children &&
      comment.children.find((c: CommentFromResponse) => c.adminOnly)
    );
  };

  const commentHasFile = (comment: CommentFromResponse): boolean => {
    return !!comment.files && !!comment.files.length;
  };

  const hasAnyFileInside = (comment: CommentFromResponse) => {
    if (commentHasFile(comment)) {
      return true;
    }
    return (
      comment.children &&
      comment.children.find((c: CommentFromResponse) => commentHasFile(c))
    );
  };

  const isQuestionIdVisible = (questionId?: number) => {
    if (areThreadsWithoutQuestion) return true;
    if (!questionId) return false;
    if (!visibleQuestionIds || visibleQuestionIds.length === 0) return true;

    return visibleQuestionIds.includes(questionId);
  };

  return threadListingLoader?.loading ? (
    <Spin size="large" />
  ) : (
    <MainContainer>
      {threads &&
        threads.map((thread) => {
          const selected = threadId === thread.id;
          const isQuestionVisible = isQuestionIdVisible(thread.questionId);

          let threadElementId = '';

          if (thread.questionId) {
            threadElementId = `${thread.questionId}`;
          } else {
            threadElementId = thread.refKey ? thread.refKey : '';
          }

          let taggedUsers: User[] = [];

          if (thread.taggedUsers && thread.taggedUsers.length) {
            taggedUsers = thread.taggedUsers.map((user) => {
              if (users[user.id]) return users[user.id];
              return user;
            });
          }

          const handleThreadSelection = () => handleSelection(thread);

          const setThreadResolved = () => {
            dispatch(
              threadUpdateRequest({
                id: thread.id,
                resolved: !thread.resolved,
              } as Thread),
            );
          };

          return (
            <ThreadContainer
              key={thread.id}
              isQuestionVisible={isQuestionVisible}
              isResolved={thread.resolved}
            >
              <h3 id={`thread${threadElementId}`}>
                <span onClick={handleThreadSelection} id="thread-title">
                  {getThreadTitle(thread)}
                </span>
                {!isQuestionVisible && (
                  <span id="not-visible-msg">(Removed from report)</span>
                )}
                {thread.resolved ? (
                  <UnlockOutlined onClick={setThreadResolved} />
                ) : (
                  <CheckSquareOutlined onClick={setThreadResolved} />
                )}
                {selected ? (
                  <MinusSquareOutlined onClick={handleThreadSelection} />
                ) : (
                  <PlusSquareOutlined onClick={handleThreadSelection} />
                )}
              </h3>
              {selected && (
                <CommentsContainer>
                  {thread.comments.map((comment: CommentFromResponse) => {
                    if (
                      filter === ThreadListingFilter.admin &&
                      !hasAnyAdminNoteInside(comment)
                    ) {
                      return null;
                    }
                    if (
                      filter === ThreadListingFilter.files &&
                      !hasAnyFileInside(comment)
                    ) {
                      return null;
                    }
                    return (
                      <div key={`Comment#${comment.id}`}>
                        <DialogComment
                          key={comment.id}
                          comment={comment}
                          handleReply={() => {
                            let parentId = 0;
                            selectedThread.comments.forEach(
                              (c: CommentFromResponse) => {
                                if (c.id === comment?.id) {
                                  parentId = c.id;
                                } else {
                                  const childCommentToReply = c.children?.find(
                                    (c: CommentFromResponse) =>
                                      c.id === comment?.id,
                                  );
                                  if (childCommentToReply?.parent) {
                                    parentId = childCommentToReply.parent.id;
                                  }
                                }
                              },
                            );
                            handleReply(parentId, comment.id);
                          }}
                          handleDelete={() => handleDelete(comment.id)}
                          taggedUsers={taggedUsers}
                        />
                        {comment.children &&
                          comment.children.map((reply: CommentFromResponse) => {
                            if (
                              filter === ThreadListingFilter.admin &&
                              !reply.adminOnly
                            ) {
                              return null;
                            }
                            if (
                              filter === ThreadListingFilter.files &&
                              !commentHasFile(reply)
                            ) {
                              return null;
                            }
                            return (
                              <DialogComment
                                key={reply.id}
                                comment={reply}
                                handleReply={() =>
                                  handleReply(comment.id, reply.id)
                                }
                                handleDelete={() => handleDelete(comment.id)}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
                </CommentsContainer>
              )}
            </ThreadContainer>
          );
        })}
    </MainContainer>
  );
};
