import React, { FC } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { capitalize } from 'src/shared/utils';
import { TopNavbar } from 'src/components/TopNavbar';
import { Button } from 'src/components/Button';
import { logoutRequest } from 'src/store/actions/user';
import { getUser } from 'src/store/selectors/user';
import {
  RootState,
  User,
  UserRole,
  LogoutRequestAction,
} from 'src/store/types';

interface PrivatePageProps {
  user: User;
  logoutRequest: () => LogoutRequestAction;
}

const Label = styled.span`
  font-size: 14px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

const PrivatePageBase: FC<PrivatePageProps> = ({ user, logoutRequest }) => {
  const match = useRouteMatch('/:slug');

  const getRouteFromUrl = (path: string): string => {
    return path.slice(1);
  };

  const getPageTitle = () => {
    if (match) return capitalize(getRouteFromUrl(match.url));
    return 'Home';
  };

  const shouldDisplayPrevRoute = () => {
    // Define strategy to get this, maybe an action on redux before redirecting?
    return null;
  };

  return (
    <>
      <TopNavbar title={getPageTitle()} prevRoute={shouldDisplayPrevRoute()} />
      <PageContentContainer>
        <h3>
          {user.id
            ? `${user.id}, ${user.email}, ${user.role || UserRole.anonymous}`
            : ''}
        </h3>
        <Label>Welcome to your private page</Label>
        <Button title="Logout" onClick={logoutRequest} />
      </PageContentContainer>
    </>
  );
};

export const PrivatePage = connect(
  (state: RootState) => ({
    user: getUser(state),
  }),
  { logoutRequest },
)(PrivatePageBase);
