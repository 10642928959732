import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'src/components/Button';
import { useAuth } from 'src/hooks/useAuth';
import { THIconCompliantStatus } from 'src/theme/media';
import { useDispatch, useSelector } from 'react-redux';
import {
  AssessmentState,
  AssessmentStatus,
  DocumentState,
  DocumentStatus,
  RootState,
} from 'src/store/types';
import { getIndexedDocumentsDraftsFromState } from 'src/store/selectors';
import { companiesLoadRequest } from 'src/store/actions/companyListing';
import { documentDraftsLoadRequest } from 'src/store/actions/documentDraftListing';
import * as S from './Styles';

const Disclaimer = styled.p`
  font-size: 12px;
  padding-top: 10px;
  opacity: 0.8;
`;

interface AssessmentCompletedMessageProps {
  assessment: AssessmentState;
  document: DocumentState;
}

export const AssessmentCompletedMessage: FC<
  AssessmentCompletedMessageProps
> = ({ assessment, document }) => {
  const history = useHistory();
  const { user, isOfficer, isCompanyModerator, isAdmin } = useAuth();
  const [grayOutDraftButton, setGrayOutDraftButton] = useState<boolean>(true);
  const [grayOutViewRiskReportButton, setGrayOutViewRiskReportButton] =
    useState<boolean>(true);
  const { lastDocumentId } = assessment;

  const dispatch = useDispatch();
  const documentDrafts = useSelector((state: RootState) =>
    getIndexedDocumentsDraftsFromState(state, '', !isAdmin),
  );

  useEffect(() => {
    if (lastDocumentId) {
      dispatch(documentDraftsLoadRequest(lastDocumentId));
    }
    if (isCompanyModerator) {
      dispatch(companiesLoadRequest());
    }
    setGrayOutViewRiskReportButton(!canReviewDocumentReport());
  }, []);

  useEffect(() => {
    setGrayOutDraftButton(!(Object.keys(documentDrafts).length > 0));
  }, [documentDrafts]);

  const canReviewDocumentDraft = () => {
    return (
      isOfficer &&
      Array.isArray(assessment?.documentsIds) &&
      assessment.documentsIds.length > 0 &&
      assessment.lastDocumentId
    );
  };

  const canReviewDocumentReport = () => {
    return (
      assessment.status === AssessmentStatus.COMPLETED &&
      document.status !== undefined &&
      document.status !== DocumentStatus.CLOSED &&
      document.status !== DocumentStatus.ACTIVE
    );
  };

  const openRiskReportPage = () => {
    const companyId = user.company ? user.company.id : 0;
    history.push(
      `companies/${companyId}/assessments/${assessment.id}/assessmentReports/`,
    );
  };

  const openReviewDocumentDraftPage = () => {
    const companyId = user.company ? user.company.id : 0;
    history.push(
      `/companies/${companyId}/assessments/${assessment.id}/assessmentReports/${assessment.lastDocumentId}/documentDrafts/`,
    );
  };

  return (
    <S.RiskAssessmentCompletedContainer>
      <div>
        <img height={130} width={130} src={THIconCompliantStatus} />
      </div>
      <div className="riskAssessmentMessageContainer">
        <h2>Your Risk Assessment has been submitted!</h2>
        <div className="riskAssessmentReport">
          {
            <Button
              disabled={grayOutViewRiskReportButton}
              key={'view-risk-report-button'}
              title="View Risk Report"
              onClick={openRiskReportPage}
            />
          }
          {
            <Button
              disabled={grayOutDraftButton}
              title="Review Document Draft"
              key={'review-document-draft-button'}
              onClick={openReviewDocumentDraftPage}
            />
          }
        </div>
        {grayOutViewRiskReportButton && (
          <Disclaimer>
            Your Risk Assessment will be unavailable while it’s being reviewed.
            We’ll let you know when your Risk Report is ready!
          </Disclaimer>
        )}
        {grayOutDraftButton && (
          <Disclaimer>
            Total HIPAA is customizing your Policies and Procedures. You will be
            notified via email when they are ready for your review.
          </Disclaimer>
        )}
      </div>
    </S.RiskAssessmentCompletedContainer>
  );
};
