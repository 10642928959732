import { QuizQuestion, QuizQuestionRaw, QuestionChoice } from 'src/store/types';

export const mapRawQuestions = (
  questions: { [key: number]: QuizQuestionRaw },
  choices: { [key: number]: QuestionChoice },
) => {
  return Object.keys(questions).map((questionId: string): QuizQuestion => {
    const rawQuestion = questions[Number(questionId)];
    return {
      id: rawQuestion.id,
      questionText: rawQuestion.questionText,
      choices: rawQuestion.choicesIds.map(
        (choiceId: number) => choices[choiceId],
      ),
    } as QuizQuestion;
  });
};
