import { call, takeEvery, put } from 'redux-saga/effects';
import {
  Thread,
  THREAD_UPDATE_REQUEST,
  ThreadUpdateRequestAction,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const updateThread = function* async({
  thread,
}: ThreadUpdateRequestAction): Generator {
  try {
    const updatedThread = yield call(api.updateThread, thread);
    yield put(actions.threadUpdateSuccess(updatedThread as Thread));
  } catch (err) {
    yield put(
      actions.threadUpdateError('Failed to update thread', err as Error),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(THREAD_UPDATE_REQUEST, updateThread)];
}
