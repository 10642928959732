import React, { FC } from 'react';
import { Avatar } from 'antd';
import styled from 'styled-components';
import { rgba } from 'polished';
import moment from 'moment';
import { Button, ButtonRounded } from 'src/components/Button';
import { UserOutlined } from 'src/theme/icons';
import { FormState, InterviewState, User } from 'src/store/types';

interface CardProps {
  status: 'done' | 'started' | 'notstarted' | 'completed';
}

export interface FormCardProps {
  className: string;
  form: FormState;
  interview?: InterviewState;
  user?: User;
  onClick?: () => void;
  disabled?: boolean;
  preview?: boolean;
}

const Card = styled.div<CardProps>`
  background-color: ${({ status, theme }) => {
    switch (status) {
      case 'done':
        return rgba(theme.colors.grayscale.middleGray, 0.6);
      case 'completed':
        return theme.colors.green.lighter;
      default:
        return theme.colors.lightBlue.main;
    }
  }};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 115px;
  min-height: 115px;
  justify-content: space-between;
  padding: 16px;
  width: 312px;
  min-width: 312px;

  .header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    .title-label {
      color: white;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      max-width: 164px;
    }
  }

  .content {
    align-items: ${({ status }) =>
      status !== 'notstarted' ? 'flex-end' : 'center'};
    display: flex;
    justify-self: flex-end;

    .content-text {
      display: flex;
      flex-direction: column;
      margin-left: 8px;

      .user-label {
        color: white;
        font-size: 14px;
        line-height: 150%;
      }

      .status-label {
        color: rgba(255, 255, 255, 0.7);
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
      }

      .expiry-label {
        color: ${({ theme }) => rgba(theme.colors.deepBlue.main, 0.7)};
      }
    }
  }
`;

const AssigneeNameLink = styled.a`
  margin-left: 10px;
  color: white;
`;

const AssigneeTitle = styled.b`
  color: white;
`;

export const FormCard: FC<FormCardProps> = ({
  className,
  form,
  interview,
  user,
  onClick,
  disabled = false,
  preview = false,
}) => {
  const isStarted = () => !!interview;

  const getButtonTitle = () => {
    if (disabled) {
      return 'Done';
    }
    if (interview?.completedAt) {
      return 'Edit';
    }
    if (isStarted()) {
      return 'Continue';
    }
    return 'Start';
  };

  const getStatusLabel = () => {
    if (interview?.completedAt) {
      return `Completed on ${interview && moment(interview.completedAt).format('lll')}`;
    }
    if (isStarted()) {
      return `Started on ${interview && moment(interview.createdAt).format('lll')}`;
    }
    return '';
  };

  const getStatus = () => {
    if (disabled) {
      return 'done';
    }
    if (interview?.completedAt) {
      return 'completed';
    }
    if (isStarted()) {
      return 'started';
    }
    return 'notstarted';
  };

  return (
    <Card className={className} status={getStatus()}>
      <div className="header">
        <span className="title-label">
          <span>{form.label}</span>
        </span>
        {!preview && (
          <ButtonRounded
            size="small"
            disabled={disabled}
            onClick={onClick}
            title={getButtonTitle()}
            variant="tertiary"
          />
        )}
      </div>
      {interview?.ownerId && (
        <div>
          <span>
            <AssigneeTitle>Assigned to:</AssigneeTitle>
            <AssigneeNameLink href="#"> {interview.ownerName}</AssigneeNameLink>
          </span>
        </div>
      )}
      <div className="content">
        <Avatar
          size="large"
          src={user && user.avatarImg}
          icon={<UserOutlined />}
        />
        <div className="content-text">
          <span className="user-label">{user && user.name}</span>
          <span className="status-label">
            {interview && <span>{getStatusLabel()}</span>}
          </span>
        </div>
      </div>
    </Card>
  );
};
