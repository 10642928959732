/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import moment from 'moment';
import {
  RootState,
  AnswerList,
  Answer,
  AnswerListingIndexedItem,
  QuestionType,
  AssessmentReportDocument,
  choiceValue,
  checkboxValue,
} from '../types';
import { getSelectedFormQuestions } from './form';

export const getSelectedInterviewAnswersFromState = (
  state: RootState,
): AnswerList => {
  return state.selectedInterview.answers;
};

export const getAnswersFromDocumentData = (state: RootState): AnswerList => {
  const fileData = state.selectedDocument.document
    .file as AssessmentReportDocument;
  return fileData && fileData.interviews
    ? fileData.interviews.reduce(
        (answers, i) => (i.answers ? [...answers, ...i.answers] : answers),
        [] as AnswerList,
      )
    : [];
};

export const getAnswersMapByQuestions = createSelector(
  (state: RootState) => getSelectedInterviewAnswersFromState(state),
  (answers: AnswerList): AnswerListingIndexedItem => {
    return answers.reduce(
      (answersByQuestions: AnswerListingIndexedItem, answer: Answer) => {
        return {
          ...answersByQuestions,
          [answer.questionId.toString()]: answer,
        };
      },
      {},
    );
  },
);

export interface AnswerValueListingIndexedItem {
  [key: string]: any;
}

export const getAnswerValueByQuestionType = (
  type: QuestionType,
  answer: Answer,
  original = false,
): string | string[] | moment.Moment | choiceValue | undefined => {
  if (!answer) {
    return undefined;
  }
  switch (type.key) {
    case 'full-name':
    case 'address':
    case 'contact-info':
    case 'file':
      return JSON.parse(answer.value as string);
    case 'date':
      return moment(answer.value);
    case 'checkbox': {
      const obj = JSON.parse(answer.value as string) as checkboxValue;
      if (!original) {
        if (
          obj &&
          obj.values &&
          Array.isArray(obj.values) &&
          obj.other &&
          obj.other?.length
        ) {
          return obj.values.map((val) =>
            val === 'Other' ? obj.other || '' : val,
          );
        }
        return obj.values;
      }

      return obj;
    }
    case 'radio': {
      const obj = JSON.parse(answer.value as string) as choiceValue;
      if (!original) {
        return obj.value;
      }
      return obj;
    }
    default:
      return answer.value;
  }
};

export const getAnswersValuesByQuestions = createSelector(
  (state: RootState) => getAnswersMapByQuestions(state),
  (state: RootState) => getSelectedFormQuestions(state),
  (answersMap, questionList): AnswerValueListingIndexedItem => {
    return questionList.reduce(
      (answers: AnswerValueListingIndexedItem, question) => {
        if (question.id) {
          const answer = answersMap[question.id.toString()];
          if (answer) {
            answers[question.id.toString()] = getAnswerValueByQuestionType(
              question.type,
              answer,
              true,
            );
          }
        }
        return answers;
      },
      {},
    );
  },
);

export const getAnswerFromDocumentData = createSelector(
  (state: RootState) => getAnswersFromDocumentData(state),
  (_: RootState, answerId?: number) => answerId,
  (answerList, answerId) => answerList.find((a) => a.id === answerId),
);
