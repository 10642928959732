import React, { FC } from 'react';
import { Button as ButtonAntd } from 'antd';
import { ButtonProps as ButtonAntdProps } from 'antd/lib/button';
import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

const StyledButton = styled(ButtonAntd)<StyledButtonProps>`
  font-family: ${({ theme }): string => theme.fontFamilies.primary};
  font-style: ${({ theme }): string => theme.fontStyles.normal};
  font-weight: ${({ theme }): string => String(theme.fontWeights.semiBold)};
  font-size: ${({ theme }): string => String(theme.fontSizes.rectBtn)}px;
  line-height: ${remConvert(14)};
  background: ${({ theme }): string => theme.colors.white.main};
  border: ${remConvert(1)} solid
    ${({ theme }): string => theme.colors.mandy.main};
  box-shadow: ${remConvert(0)} ${remConvert(2)} ${remConvert(4)}
    rgba(0, 0, 0, 0.1);
  border-radius: ${remConvert(5)};
  color: ${({ theme }): string => theme.colors.mandy.main};
  font-family: ${({ theme }): string => theme.fontFamilies.primary};

  &:hover {
    background: ${({ theme }): string => theme.colors.mandy.main};
    border: ${remConvert(1)} solid
      ${({ theme }): string => theme.colors.mandy.main};
  }

  &:disabled,
  &:disabled:hover {
    background: ${({ theme }): string => theme.colors.white.main};
    border: ${remConvert(1)} solid
      ${({ theme }): string => theme.colors.mandy.lighter};
    color: ${({ theme }): string => theme.colors.mandy.lighter};
  }
`;

interface ButtonRectangularProps extends ButtonAntdProps {
  className?: string;
  disabled?: boolean;
  altered?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'radio' | 'danger';
  title: string;
}

interface StyledButtonProps extends ButtonAntdProps {
  $altered?: boolean;
  children: string;
  variant: 'primary' | 'secondary' | 'tertiary' | 'radio' | 'danger';
}

export const ButtonRectangular: FC<ButtonRectangularProps> = ({
  className,
  disabled,
  onClick,
  altered = false,
  title,
  variant = 'primary',
  loading,
}) => (
  <StyledButton
    $altered={altered}
    disabled={disabled}
    className={className}
    type="primary"
    variant={variant}
    onClick={onClick}
    loading={loading}
    size="middle"
  >
    {title}
  </StyledButton>
);
