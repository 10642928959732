import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';

export const ToastLogo = styled.img`
  position: absolute;
  left: 0;
  bottom: 10px;
`;

export const ToastTitleMessageContainer = styled.div`
  padding-bottom: ${remConvert(10)};
  position: relative;
  margin-right: ${remConvert(20)};
  margin-top: ${remConvert(20)};
  padding-left: ${remConvert(60)};
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`;

export const ToastSubtitleMessage = styled.div`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: ${remConvert(25)};
  text-align: center;
`;

export const ToastTitleMessage = styled.p``;
