import moment, { Moment } from 'moment';

export const suggestFutureDate = (date: Date | Moment, days = 30): Moment => {
  const lastDayOfYear = moment(date).endOf('year');
  const availableDaysInTheYear = lastDayOfYear.diff(date, 'day');
  const maxAvailableDays =
    availableDaysInTheYear > days ? days : availableDaysInTheYear;
  const suggestedDate = moment(date).add(maxAvailableDays, 'day');

  return suggestedDate;
};
