import { showErrorMessage } from 'src/store/actions/error';
import {
  ExamActivateErrorAction,
  ExamActivateRequestAction,
  ExamActivateSuccessAction,
  ExamArchiveErrorAction,
  ExamArchiveRequestAction,
  ExamArchiveSuccessAction,
  ExamDeleteErrorAction,
  ExamDeleteRequestAction,
  ExamDeleteSuccessAction,
  ExamListingLoadErrorAction,
  ExamListingLoadRequestAction,
  ExamListingLoadSuccessAction,
  ExamListingResponse,
  EXAM_ACTIVATE_FAILED,
  EXAM_ACTIVATE_REQUEST,
  EXAM_ACTIVATE_SUCCESS,
  EXAM_ARCHIVE_FAILED,
  EXAM_ARCHIVE_REQUEST,
  EXAM_ARCHIVE_SUCCESS,
  EXAM_DELETE_FAILED,
  EXAM_DELETE_REQUEST,
  EXAM_DELETE_SUCCESS,
  EXAM_LISTING_LOAD_FAILED,
  EXAM_LISTING_LOAD_REQUEST,
  EXAM_LISTING_LOAD_SUCCESS,
} from '../types';

export const examListingLoadRequest = (
  page: number,
  pageSize: number,
  name?: string,
): ExamListingLoadRequestAction => ({
  type: EXAM_LISTING_LOAD_REQUEST,
  page,
  pageSize,
  name,
});

export const examListingLoadSuccess = (
  examListing: ExamListingResponse,
): ExamListingLoadSuccessAction => ({
  type: EXAM_LISTING_LOAD_SUCCESS,
  examListing,
});

export const examListingLoadError = (
  errorMessage: string,
  originalError?: Error,
): ExamListingLoadErrorAction =>
  showErrorMessage(EXAM_LISTING_LOAD_FAILED, errorMessage, originalError);

export const examDeleteRequest = (examId: number): ExamDeleteRequestAction => ({
  type: EXAM_DELETE_REQUEST,
  examId,
});

export const examDeleteSuccess = (examId: number): ExamDeleteSuccessAction => ({
  type: EXAM_DELETE_SUCCESS,
  examId,
});

export const examDeleteError = (
  errorMessage: string,
  originalError?: Error,
): ExamDeleteErrorAction =>
  showErrorMessage(EXAM_DELETE_FAILED, errorMessage, originalError);

export const examActivateRequest = (
  examId: number,
): ExamActivateRequestAction => ({
  type: EXAM_ACTIVATE_REQUEST,
  examId,
});

export const examActivateSuccess = (
  examId: number,
): ExamActivateSuccessAction => ({
  type: EXAM_ACTIVATE_SUCCESS,
  examId,
});

export const examActivateError = (
  errorMessage: string,
  originalError?: Error,
): ExamActivateErrorAction =>
  showErrorMessage(EXAM_ACTIVATE_FAILED, errorMessage, originalError);

export const examArchiveRequest = (
  examId: number,
): ExamArchiveRequestAction => ({
  type: EXAM_ARCHIVE_REQUEST,
  examId,
});

export const examArchiveSuccess = (
  examId: number,
): ExamArchiveSuccessAction => ({
  type: EXAM_ARCHIVE_SUCCESS,
  examId,
});

export const examArchiveError = (
  errorMessage: string,
  originalError?: Error,
): ExamArchiveErrorAction =>
  showErrorMessage(EXAM_ARCHIVE_FAILED, errorMessage, originalError);
