import { showErrorMessage } from 'src/store/actions/error';
import {
  DownloadTrainingCertificateErrorAction,
  DownloadTrainingCertificateRequestAction,
  DownloadTrainingCertificateSuccessAction,
  DOWNLOAD_TRAINING_CERTIFICATE_ERROR,
  DOWNLOAD_TRAINING_CERTIFICATE_REQUEST,
  DOWNLOAD_TRAINING_CERTIFICATE_SUCCESS,
} from '../types';

export const downloadTrainingCertificateRequest = (
  examSubmissionId: number,
  userName: string,
  trainingTypeName: string,
): DownloadTrainingCertificateRequestAction => ({
  type: DOWNLOAD_TRAINING_CERTIFICATE_REQUEST,
  examSubmissionId,
  userName,
  trainingTypeName,
});

export const downloadTrainingCertificateSuccess =
  (): DownloadTrainingCertificateSuccessAction => ({
    type: DOWNLOAD_TRAINING_CERTIFICATE_SUCCESS,
  });

export const downloadTrainingCertificateError = (
  errorMessage: string,
  originalError: Error,
): DownloadTrainingCertificateErrorAction =>
  showErrorMessage(
    DOWNLOAD_TRAINING_CERTIFICATE_ERROR,
    errorMessage,
    originalError,
  );
