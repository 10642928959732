import React, { FC } from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { RoutesEnum } from 'src/shared/enums';
import { mapUserRoleText } from 'src/shared/utils/user';
import { UserRole } from 'src/store/types';
import { remConvert } from 'src/theme/utils';

interface UserAvatarProps {
  user: {
    name: string;
    avatar?: string;
    email: string;
    role?: UserRole;
    id?: number | string;
    company?: string;
  };
  full?: boolean;
  linkToProfile?: boolean;
}

const AvatarContainer = styled.div`
  padding: ${remConvert(6)} ${remConvert(4)};
  display: flex;

  a {
    display: flex;

    &:hover {
      .user-avatar {
        overflow: visible;
        &:after {
          content: '';
          border: 3px solid #7fa6d8;
          border-radius: 27px;
          height: ${remConvert(54)};
          width: ${remConvert(54)};
          position: absolute;
          top: -3px;
          left: -3px;
        }
      }
    }

    > span {
      border-radius: 50% !important;

      > img {
        border-radius: 50% !important;
      }
    }
  }

  .user-avatar {
    flex-grow: 0;
    min-width: ${remConvert(48)};
    text-transform: uppercase;
    background: linear-gradient(180deg, #a2a2a2 0%, #ababab 100%);
  }

  .user-info {
    margin-left: ${remConvert(18)};
    margin-top: ${remConvert(4)};
    > * {
      color: white;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      max-width: 164px;
    }

    p {
      font-weight: 600;
      font-size: 12px;
      line-height: ${remConvert(16)};
      letter-spacing: 0.05em;
      color: #e4e4e4;
      margin-bottom: ${remConvert(0)};
    }
  }
`;

export const UserAvatar: FC<UserAvatarProps> = (props) => {
  const {
    user: { name, company, avatar, email, role, id = '' },
    linkToProfile,
    full = false,
  } = props;
  let image = null;

  const initials = name.split(' ').map((name) => name.charAt(0));

  if (avatar) {
    image = (
      <Avatar className="user-avatar" src={avatar} alt={email} size={48} />
    );
  } else {
    image = (
      <Avatar className="user-avatar" size={48}>
        {initials[0]}
        {initials[1]}
      </Avatar>
    );
  }
  const content = (
    <>
      {image}
      {full && (
        <div className="user-info">
          <span>{name}</span>
          {role && <p>{mapUserRoleText(role)}</p>}
        </div>
      )}
    </>
  );
  if (linkToProfile) {
    return (
      <AvatarContainer>
        <Link to={RoutesEnum.PROFILE}>{content}</Link>
      </AvatarContainer>
    );
  }
  return <AvatarContainer>{content}</AvatarContainer>;
};
