import React, { FC } from 'react';
import styled from 'styled-components';
import { Question, QuestionTypes, SelectedFormState } from 'src/store/types';
import ReactPlayer from 'react-player';

interface VideoCardProps {
  selectedForm: SelectedFormState;
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  width: 100%;

  .react-player {
    height: 234px;
    margin-bottom: 10px;
  }

  h3 {
    color: ${({ theme }) => theme.colors.deepBlue.main};
    font-size: 14px;
    margin: 12px 0 18px 0;
  }
`;

export const VideoCard: FC<VideoCardProps> = ({ selectedForm }) => {
  const { questions = [] } = selectedForm;

  const videos = questions.filter(
    ({ id, type, moreInfo }: Question) =>
      id !== undefined && type.key === QuestionTypes.VIDEO && moreInfo,
  ) as (Question & { id: number; moreInfo: string })[];

  if (videos.length === 0) {
    return null;
  }

  return (
    <Card>
      {videos.map(({ id, question, moreInfo }) => {
        return (
          <div key={id}>
            <h3>{question}</h3>
            <ReactPlayer
              url={moreInfo}
              className="react-player"
              width="100%"
              height="234"
            />
          </div>
        );
      })}
    </Card>
  );
};
