import { Avatar } from 'antd';
import styled from 'styled-components';
import { ButtonRounded, ButtonRoundedProps } from 'src/components/Button';
import { centeredFlex, device } from 'src/theme';
import { remConvert } from 'src/theme/utils';

export const PageContentContainer = styled.div`
  margin-top: ${remConvert(22)};
  position: relative;
  height: 78vh;
`;

export const UserAvatar: any = styled(Avatar)`
  ${centeredFlex}
  height: ${remConvert(100)} !important;
  width: ${remConvert(100)} !important;
  text-transform: uppercase;
  line-height: ${remConvert(65)};
  text-align: center;
  letter-spacing: 0.1em;
  background: linear-gradient(180deg, #a2a2a2 0%, #ababab 100%);

  .ant-avatar-string {
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-style: ${({ theme }) => theme.fontStyles.normal};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 48px;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.sm} {
    flex-direction: row;
  }
`;

export const AvatarUserName = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  line-height: ${remConvert(48)};
  margin-left: ${remConvert(18)};
  font-size: 35px;
  letter-spacing: 0.15em;
  color: rgba(28, 36, 40, 0.75);
`;

export const SectionTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${remConvert(22)};
  padding-bottom: ${remConvert(10)};
  font-size: 16px;
  color: rgba(28, 36, 40, 0.85);
`;

export const UserContentContainer = styled.div`
  width: 100%;

  @media ${device.lg} {
    width: ${remConvert(600)};
  }
`;

export const PersonalInfoContentContainer = styled.div`
  ${centeredFlex}
  flex-direction: column;
  justify-content: center;

  .ant-btn {
    float: left;
    margin-top: ${remConvert(10)};
  }

  @media ${device.md} {
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
  }
`;

export const HipaaAdminContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SecurityContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightButtonRounded: React.FC<ButtonRoundedProps> = styled(
  ButtonRounded,
)`
  float: right;
  width: ${remConvert(100)};
`;

export const ProfileItemContentContainer = styled.div`
  margin-top: ${remConvert(10)};
`;

export const SectionDivider = styled.hr`
  border-top-color: lightgray;
  margin-bottom: ${remConvert(10)};
  margin-top: ${remConvert(10)};
`;

export const GearImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;

  @media ${device.xl} {
    display: block;
  }
`;

export const IconImage = styled.img`
  margin-right: ${remConvert(0)};
  display: none;

  @media ${device.sm} {
    margin-right: ${remConvert(20)};
    display: inline;
  }
`;

export const SocialIconsContainer = styled.div`
  ${centeredFlex}
  margin-top: ${remConvert(20)};
  justify-content: center;
  flex-direction: column;

  @media ${device.sm} {
    flex-direction: row;
  }

  img {
    margin-left: ${remConvert(32)};
    cursor: pointer;
  }
`;

export const UserName = styled.span`
  line-height: ${remConvert(20)};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.black.shark};
  font-size: 15px;
`;

export const UserCompanyContentContainer = styled.div`
  padding-left: ${remConvert(10)};
  line-height: ${remConvert(20)};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-style: ${({ theme }) => theme.fontStyles.normal};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 15px;
`;
