import { call, takeEvery, put } from 'redux-saga/effects';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';
import {
  CategoryCreateRequestAction,
  CategoryDeleteRequestAction,
  CategoryFromResponse,
  CategoryReOrderRequestAction,
  CategoryUpdateRequestAction,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_LOAD_REQUEST,
  CATEGORY_REORDER_REQUEST,
  CATEGORY_UPDATE_REQUEST,
} from '../types';

export const createCategory = function* async({
  name,
}: CategoryCreateRequestAction): Generator {
  try {
    const category = yield call(api.createCategory, name);
    yield put(actions.categoryCreateRequestSuccess(category));
  } catch (err) {
    yield put(
      actions.categoryCreateRequestFailed(
        'Failed to create category',
        err as Error,
      ),
    );
  }
};

export const loadCategories = function* async(): Generator {
  try {
    const categories = yield call(api.loadCategories);
    yield put(actions.categoryLoadRequestSuccess(categories as any[]));
  } catch (err) {
    yield put(
      actions.categoryLoadRequestFailed(
        'Failed to load category',
        err as Error,
      ),
    );
  }
};

export const reorderCategories = function* async({
  categories,
}: CategoryReOrderRequestAction): Generator {
  try {
    yield call(api.reorderCategories, categories);
    yield put(actions.categoryReOrderSuccess());
  } catch (err) {
    yield put(
      actions.categoryReOrderFailed('Failed to reorder category', err as Error),
    );
  }
};

export const deleteCategory = function* async({
  categoryId,
}: CategoryDeleteRequestAction): Generator {
  try {
    yield call(api.deleteCategory, categoryId);
    yield put(actions.categoryDeleteSuccess(categoryId));
  } catch (err) {
    yield put(
      actions.categoryDeleteFailed('Failed to delete category', err as Error),
    );
  }
};

export const updateCategory = function* async({
  categoryId,
  name,
}: CategoryUpdateRequestAction): Generator {
  try {
    const response = yield call(api.updateCategory, {
      id: categoryId,
      name,
    });
    yield put(actions.categoryUpdateSuccess(response as CategoryFromResponse));
  } catch (err) {
    yield put(
      actions.categoryUpdateFailed('Failed to update category', err as Error),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(CATEGORY_CREATE_REQUEST, createCategory),
    yield takeEvery(CATEGORY_LOAD_REQUEST, loadCategories),
    yield takeEvery(CATEGORY_REORDER_REQUEST, reorderCategories),
    yield takeEvery(CATEGORY_UPDATE_REQUEST, updateCategory),
    yield takeEvery(CATEGORY_DELETE_REQUEST, deleteCategory),
  ];
}
