import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCurrentExam, getCurrentTraining } from 'src/store/selectors';
import * as S from './Styles';

interface TrainingLayoutProps {
  children: React.ReactNode;
}

export const TrainingQuizLayout: FC<TrainingLayoutProps> = ({ children }) => {
  const currentTraining = useSelector(getCurrentTraining);
  const currentExam = useSelector(getCurrentExam);
  const location = useLocation();
  const isExamQuiz = location.pathname.includes('exam-quiz');
  const isTrainingQuiz = location.pathname.includes('training-quiz');

  return (
    <S.PageLayout className="layout">
      <S.PageContent>
        <S.PageContainer>
          <S.TrainingTypeTitle>
            {currentTraining && isTrainingQuiz
              ? currentTraining.trainingTypeName
              : currentExam && isExamQuiz
                ? currentExam.trainingTypeName
                : ''}
          </S.TrainingTypeTitle>
          {currentTraining && isTrainingQuiz && (
            <S.QuizTitle>
              Quiz: {currentTraining ? currentTraining.name : ''}
            </S.QuizTitle>
          )}
          {currentExam && isExamQuiz && (
            <S.QuizTitle>Quiz: {'Final Exam'}</S.QuizTitle>
          )}
          {children}
        </S.PageContainer>
      </S.PageContent>
    </S.PageLayout>
  );
};
