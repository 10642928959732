import { centeredFlex, normalOpenSans } from 'src/theme';
import { remConvert } from 'src/theme/utils';
import styled from 'styled-components';

export const TrainingTypeTitle = styled.div`
  ${normalOpenSans}
  font-weight: 400;
  font-size: 40px;
  line-height: ${remConvert(54)};
  text-align: center;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: ${remConvert(10)};
  padding-top: ${remConvert(7)};
  border-bottom: 1px solid lightgray;
`;

export const QuizTitle = styled.div`
  ${normalOpenSans}
  ${centeredFlex}
  font-weight: 600;
  font-size: 20px;
  line-height: ${remConvert(27)};
  letter-spacing: 0.2em;
  color: rgba(0, 0, 0, 0.6);
  margin-top: ${remConvert(26)};
  margin-bottom: ${remConvert(26)};
`;

export const QuizQuestionText = styled.div`
  ${normalOpenSans}
  font-weight: 600;
  font-size: 18px;
  line-height: ${remConvert(35)};
  color: #000000;
  padding: ${remConvert(26)} ${remConvert(0)} ${remConvert(20)} ${remConvert(0)};
`;

export const QuizQuestionChoices = styled.ul`
  list-style-type: none;
  padding: ${remConvert(0)} ${remConvert(0)} ${remConvert(0)} ${remConvert(34)};

  li {
    display: flex;
    margin-bottom: ${remConvert(20)};

    div {
      padding-left: ${remConvert(15)};
      cursor: pointer;
    }
  }
`;

export const QuizWizardControls = styled.div`
  ${centeredFlex}
  ${normalOpenSans}
  margin-top: ${remConvert(47)};
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: ${remConvert(27)};
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.6);

  img {
    cursor: pointer;
  }
`;

export const ProgressWrapper = styled.div`
  .ant-progress-bg,
  .ant-progress-inner {
    height: ${remConvert(15)} !important;
  }

  .ant-progress-text {
    ${normalOpenSans}
    font-weight: 600;
    font-size: 20px;
    line-height: ${remConvert(27)};
    color: rgba(28, 36, 40, 0.5);
  }
`;
