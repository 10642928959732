import {
  FormState,
  FormListingActionTypes,
  FORMS_LOAD_REQUEST,
  FORMS_LOAD_SUCCESS,
  FORMS_LOAD_ERROR,
  COMPANY_LOAD_REQUEST,
  COMPANY_LOAD_SUCCESS,
  COMPANY_LOAD_ERROR,
  FORM_DELETE_REQUEST,
  FORM_DELETE_SUCCESS,
  FORM_DELETE_ERROR,
  FORMS_REORDER_REQUEST,
  FORM_SET_STATUS_SUCCESS,
  CompanyActionTypes,
  FormListingState,
  Loader,
  FormListingIndexedItem,
  AssessmentTypeFromResponse,
  FormActionsTypes,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const formListingInitialState: FormListingState = {
  forms: {},
  loader: loaderInitialState,
};

export const formListingReducer = (
  state: FormListingState = formListingInitialState,
  action: FormListingActionTypes | CompanyActionTypes | FormActionsTypes,
): FormListingState => {
  switch (action.type) {
    case COMPANY_LOAD_REQUEST:
    case FORMS_LOAD_REQUEST:
    case FORM_DELETE_REQUEST: {
      return { ...state, loader: { ...loaderInitialState, loading: true } };
    }
    case FORMS_LOAD_SUCCESS: {
      const list = action.forms.reduce(
        (list: FormListingIndexedItem, form: FormState) => {
          return {
            ...list,
            [form.id]: form,
          };
        },
        {},
      );
      return {
        forms: list,
        loader: { ...loaderInitialState, success: true, completed: true },
      };
    }
    case FORM_DELETE_SUCCESS: {
      const updatedForms = { ...state.forms };

      delete updatedForms[action.formId];

      return {
        forms: updatedForms,
        loader: { ...loaderInitialState, success: true, completed: true },
      };
    }
    case COMPANY_LOAD_SUCCESS: {
      const { assessments } = action.company;
      const types: AssessmentTypeFromResponse[] = assessments.map(
        (assessment: any) => assessment.type,
      );
      const formMap = types.reduce((map: FormListingIndexedItem, type) => {
        if (!type.forms) {
          return {};
        }
        return type.forms.reduce(
          (tempMap: FormListingIndexedItem, form: FormState) => {
            return {
              ...tempMap,
              [form.id]: form,
            };
          },
          map,
        );
      }, {});

      return {
        forms: formMap,
        loader: { ...loaderInitialState, success: true, completed: true },
      };
    }
    case FORM_SET_STATUS_SUCCESS: {
      const { form } = action;
      const formMap = {
        ...state.forms,
        [form.id]: form,
      };
      return {
        forms: formMap,
        loader: { ...loaderInitialState, success: true, completed: true },
      };
    }
    case COMPANY_LOAD_ERROR:
    case FORMS_LOAD_ERROR:
    case FORM_DELETE_ERROR: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: false,
          completed: true,
          error: action.message.toString(),
        },
      };
    }
    case FORMS_REORDER_REQUEST:
      const forms: FormListingIndexedItem = { ...state.forms };

      action.forms.map((form: any) => {
        forms[form.id] = { ...form };
      });

      return {
        ...state,
        forms,
      };
    default:
      return state;
  }
};
