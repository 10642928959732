import React, { FC } from 'react';
import styled from 'styled-components';
import { useForm } from 'antd/lib/form/Form';
import { Button, Form, Input, Select } from 'antd';

interface TemplateEditorConditionalWindowProps {
  onSelection: (condition: string, body: string) => void;
  onClose: () => void;
}

const Container = styled.div`
  padding: 10px;
  width: 300px;

  .compact {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    .ant-form-item {
      display: flex;
      flex-grow: 1;
    }
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 12px;
  font-weight: bold;
`;

const ActionRow = styled.div`
  button {
    margin-right: 5px;
  }
`;

const { Option } = Select;

export const TemplateEditorConditionalWindow: FC<
  TemplateEditorConditionalWindowProps
> = ({ onSelection, onClose }) => {
  const [form] = useForm();

  const handleFinish = () => {
    const values = form.getFieldsValue();
    onSelection(
      `${values['op1']}${values['comparator']}${values['op2']}`,
      values['body'],
    );

    cleanUp();
  };

  const cleanUp = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Container>
      <Title>Insert a conditional block</Title>
      <Form onFinish={handleFinish} form={form}>
        <Input.Group compact className="compact">
          <Form.Item
            name="op1"
            rules={[{ required: true, message: 'First operand is required' }]}
          >
            <Input placeholder="First Operand" prefix="if: " />
          </Form.Item>
          <Form.Item name="comparator">
            <Select placeholder="Select a comparator">
              <Option value="=">is equal to (=)</Option>
              <Option value="!=">is different to (!=)</Option>
              <Option value="<">is smaller than ({`<`}) </Option>
              <Option value="<=">is smaller than or equal to ({`<=`}) </Option>
              <Option value=">=">is larger than or equal to ({`>=`})</Option>
              <Option value=">">is larger than ({`>`})</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="op2"
            rules={[{ required: true, message: 'Second operand is required' }]}
          >
            <Input placeholder="Second Operand" />
          </Form.Item>
        </Input.Group>
        <label> Then: </label>
        <Form.Item name="body">
          <Input.TextArea placeholder="Body to add when condition is true" />
        </Form.Item>

        <ActionRow>
          <Button type="primary" htmlType="submit" size="small">
            Submit
          </Button>
          <Button
            type="default"
            htmlType="button"
            onClick={() => {
              cleanUp();
            }}
            size="small"
          >
            Cancel
          </Button>
        </ActionRow>
      </Form>
    </Container>
  );
};
