import {
  AddUserToListAction,
  ADD_USER_TO_LIST,
  UserFromResponse,
} from '../types';

export const addUserToList = (user: UserFromResponse): AddUserToListAction => ({
  type: ADD_USER_TO_LIST,
  user,
});
