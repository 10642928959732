import { Loader } from '../types';
import {
  CategoryActionTypes,
  CategoryListingState,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_REQUEST_SUCCESS,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LOAD_REQUEST,
  CATEGORY_LOAD_REQUEST_SUCCESS,
  CATEGORY_REORDER_REQUEST,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
} from '../types/category';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const categoryListingInitialState: CategoryListingState = {
  categories: {},
  loader: loaderInitialState,
};

export const categoryListingReducer = (
  state: CategoryListingState = categoryListingInitialState,
  action: CategoryActionTypes,
): CategoryListingState => {
  switch (action.type) {
    case CATEGORY_LOAD_REQUEST:
    case CATEGORY_CREATE_REQUEST:
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    case CATEGORY_CREATE_REQUEST_SUCCESS:
      const { category } = action;
      return {
        ...state,
        categories: {
          ...state.categories,
          [category.id]: { ...category, forms: [] },
        },
        loader: {
          ...state.loader,
          loading: false,
          completed: true,
          success: true,
        },
      };
    case CATEGORY_LOAD_REQUEST_SUCCESS: {
      const categoryMap = action.categories.reduce((list, category) => {
        return {
          ...list,
          [category.id]: { ...category, forms: [] },
        };
      }, {});

      return {
        ...state,
        categories: categoryMap,
        loader: {
          ...state.loader,
          loading: false,
        },
      };
    }
    case CATEGORY_REORDER_REQUEST: {
      const categoryMap = action.categories.reduce((list, category) => {
        return {
          ...list,
          [category.id]: { ...category },
        };
      }, {});

      return {
        ...state,
        categories: categoryMap,
      };
    }
    case CATEGORY_UPDATE_REQUEST:
    case CATEGORY_DELETE_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case CATEGORY_DELETE_SUCCESS: {
      const categories = { ...state.categories };

      delete categories[action.categoryId];

      return {
        ...state,
        categories,
        loader: {
          ...loaderInitialState,
          loading: false,
          completed: true,
        },
      };
    }
    case CATEGORY_UPDATE_SUCCESS: {
      const categories = {
        ...state.categories,
      };

      categories[action.category.id] = {
        ...action.category,
      };

      return {
        ...state,
        categories,
        loader: {
          ...loaderInitialState,
          loading: false,
          completed: true,
        },
      };
    }
    default:
      return state;
  }
};
