/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { Form, Select, Divider as DividerAntd, Input } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { Question, Choice, QuestionList, RootState } from 'src/store/types';
import { SelectValue } from 'antd/lib/select';
import { IconButton } from 'src/components/Button';
import { CloseCircleOutlined } from 'src/theme/icons';
import { getAssessmentTypeQuestions } from 'src/store/selectors';
import { AutoComplete } from './Autocomplete';

const Divider = styled(DividerAntd)`
  && {
    margin-top: 0px;
  }

  span {
    font-size: 11px;
  }
`;

export interface ConditionalRulesetProps {
  value?: any | undefined;
  onChange?: (value: Choice[]) => void;
  connector: string;
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
}

export interface ConditionalRulesetFormValue {
  comparator: string;
  subject: number;
  subjectId: number;
  condition?: string;
  choiceId?: number;
  choice?: Choice;
}

const Item = styled(Form.Item)`
  .ant-form-item-explain {
    margin-top: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;

  .conditionFields {
    width: 100%;
  }
  .deleteContainer {
    flex-grow: 0;
    margin-left: 10px;
  }

  .ant-select {
    margin-bottom: 10px;
  }
`;

const serializeFieldValue = (fieldValue?: ConditionalRulesetFormValue) => ({
  comparator: fieldValue?.comparator ?? 'IS',
  subject: fieldValue?.subject ?? 0,
  subjectId: fieldValue?.subjectId ?? 0,
  condition: fieldValue?.condition,
  choiceId: fieldValue?.choice ? fieldValue.choice.id : fieldValue?.choiceId,
});

export const ConditionalRuleset: FC<ConditionalRulesetProps> = ({
  value: fieldValue,
  onChange,
  connector,
  field,
  remove,
}) => {
  const [value, setValue] = useState<ConditionalRulesetFormValue>(
    serializeFieldValue(fieldValue),
  );
  const [autocompleteTextValue, setAutocompleteTextValue] =
    useState<string>('');
  const [choices, setChoices] = useState<Choice[]>([]);
  const [showChoices, setShowChoices] = useState(!!value?.choiceId);

  const assessmentTypeQuestions = useSelector<RootState, QuestionList>(
    (state: RootState) => getAssessmentTypeQuestions(state),
  );

  const triggerChange = (newValue: any) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (fieldValue) {
      let conditionalQuestionId = 0;
      if (fieldValue.subject) {
        setValue(serializeFieldValue(fieldValue));
        handleQuestionChange(fieldValue.subject as number, false);
        conditionalQuestionId = Number(fieldValue.subject);
      } else if (fieldValue.subjectId) {
        const val = {
          ...serializeFieldValue(fieldValue),
          subject: fieldValue.subjectId,
        };
        triggerChange(val);
        setValue(val);
        handleQuestionChange(fieldValue.subjectId, false);
        conditionalQuestionId = Number(fieldValue.subjectId);
      }
      if (conditionalQuestionId) {
        const conditionalQuestion = assessmentTypeQuestions.find(
          (q: Question) => q.id === conditionalQuestionId,
        );
        setAutocompleteTextValue(
          conditionalQuestion ? conditionalQuestion.question : '',
        );
      }
    } else {
      setValue(serializeFieldValue());
    }
  }, [fieldValue]);

  const handleQuestionChange = (selectValue: number, update = true) => {
    let result: Question | undefined = undefined;
    if (assessmentTypeQuestions.length) {
      result = assessmentTypeQuestions.find((q: Question) => {
        return q.id === selectValue;
      });
    }
    if (result && update) {
      triggerChange({ ...serializeFieldValue(), subject: selectValue });
    }
    if (result && result.choices && result.choices.length) {
      setChoices(result.choices);
      setShowChoices(true);
    } else {
      setChoices([]);
      setShowChoices(false);
    }
  };

  const handleOnComparatorChange = (selectValue: SelectValue) => {
    if (selectValue) {
      triggerChange({ ...value, comparator: selectValue as string });
    }
  };

  const handleOnChoiceChange = (selectValue: SelectValue) => {
    if (selectValue) {
      triggerChange({
        ...value,
        choiceId: selectValue as number,
        condition: undefined,
      });
    }
  };

  const handleOnConditionChange = (
    selectValue: ChangeEvent<HTMLInputElement>,
  ) => {
    if (selectValue) {
      triggerChange({
        ...value,
        condition: selectValue.target.value,
        choiceId: undefined,
      });
    }
  };

  return (
    <>
      {field.name > 0 && (
        <Divider plain className="divisor">
          {connector === 'all' ? 'and' : 'or'}
        </Divider>
      )}
      <Wrapper>
        <div className="conditionFields">
          <AutoComplete
            initialTextValue={autocompleteTextValue}
            handleQuestionChange={handleQuestionChange}
            questions={assessmentTypeQuestions}
          />
          <Select
            placeholder="Select comparison"
            value={value.comparator}
            onChange={handleOnComparatorChange}
          >
            <Select.Option value="IS">is</Select.Option>
            <Select.Option value="IS_NOT">is not</Select.Option>
            {!showChoices && (
              <>
                <Select.Option value="GREATER_THAN">greater than</Select.Option>
                <Select.Option value="LESS_THAN">less than</Select.Option>
                <Select.Option value="CONTAINS">contains</Select.Option>
                <Select.Option value="STARTS_WITH">starts with</Select.Option>
                <Select.Option value="ENDS_WITH">ends with</Select.Option>
              </>
            )}
          </Select>
          {!showChoices && (
            <Input
              className="input"
              placeholder="Enter value"
              value={value.condition}
              onChange={handleOnConditionChange}
            />
          )}
          {showChoices && (
            <Select
              placeholder="Select an option"
              value={value.choiceId}
              onChange={handleOnChoiceChange}
            >
              {choices.map((c: Choice, k) => {
                let key = c.id ? c.id : c.internalId;
                if (!key) {
                  key = k;
                }
                if (value.choiceId === key) {
                }
                return (
                  <Select.Option value={key} key={key} disabled={!c.id}>
                    {c.label}
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </div>
        <div className="deleteContainer">
          <IconButton
            icon={<CloseCircleOutlined />}
            onClick={() => {
              remove(field.name);
            }}
          />
        </div>
      </Wrapper>
    </>
  );
};
