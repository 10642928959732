import React, { FC } from 'react';
import { TopNavbar } from 'src/components/TopNavbar';
import { Welcome } from 'src/components/Welcome';
import { useAuth } from 'src/hooks/useAuth';

export const DashboardPage: FC<unknown> = () => {
  const { user } = useAuth();
  return (
    <>
      <TopNavbar title="Dashboard" />
      <Welcome user={user} />
    </>
  );
};
