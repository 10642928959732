import styled from 'styled-components';
import { remConvert } from 'src/theme/utils';
import { baseOpenSans, centeredFlex } from 'src/theme';

export const FormContainer = styled.div`
  margin-top: ${remConvert(16)};
  margin-bottom: ${remConvert(16)};

  > div:first-of-type {
    margin-bottom: ${remConvert(14)};
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${remConvert(19.5)};
  display: flex;
  justify-content: end;

  button {
    width: ${remConvert(111)};
  }
`;

export const FormInfoWrapper = styled.div`
  ${centeredFlex}
`;

export const FormInfoDescription = styled.div`
  width: ${remConvert(295)};
`;

export const CheckboxLabel = styled.span`
  ${baseOpenSans}
  font-weight: 600;
  font-size: 14px;
  line-height: ${remConvert(19)};
  color: #000000;
  margin-right: ${remConvert(3)};
`;

export const LicensesLeftLabel = styled.div`
  ${baseOpenSans}
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin-right: ${remConvert(3)};
  margin-left: ${remConvert(25)};
`;
