import React, { FC } from 'react';
import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';

export interface LinkButtonProps {
  onClick: () => void;
  icon: React.ReactElement;
  text: string;
}

const Button = styled(ButtonAntd)`
  font-size: 16px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkBlue.main};
  box-shadow: unset;
  padding-left: 0;

  &:hover,
  &:focus {
    border-color: transparent;
  }
`;

export const LinkButton: FC<LinkButtonProps> = ({ onClick, icon, text }) => (
  <Button onClick={onClick} className="linkButton">
    {icon} {text}
  </Button>
);
