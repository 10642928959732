import { Loader } from './loader';
import { Choice } from './question';
import { FormDeleteSuccessAction } from './form';
import { ShowErrorAction } from './error';

export const QUESTIONS_LOAD_REQUEST = 'QUESTIONS_LOAD_REQUEST';
export const QUESTIONS_LOAD_SUCCESS = 'QUESTIONS_LOAD_SUCCESS';
export const QUESTIONS_LOAD_ERROR = 'QUESTIONS_LOAD_ERROR';

export interface QuestionsState {
  id: number;
  question: string;
  type: {
    key: string;
    decoration: boolean;
    label: string;
  };
  choices: Choice[];
}

export interface FormQuestionsState {
  id: number;
  label: string;
  isActive: boolean;
  questions: QuestionsState[];
}

export interface FormQuestionListingIndexedItem {
  [key: string]: FormQuestionsState;
}

export interface QuestionListingState {
  forms: FormQuestionsState[];
  loader: Loader;
}

export interface QuestionsLoadRequestAction {
  type: typeof QUESTIONS_LOAD_REQUEST;
  assessmentTypeId: number;
}

export interface QuestionsLoadSuccessAction {
  type: typeof QUESTIONS_LOAD_SUCCESS;
  forms: FormQuestionsState[];
}

export type QuestionsLoadErrorAction = ShowErrorAction<
  typeof QUESTIONS_LOAD_ERROR
>;

export type QuestionListingActionTypes =
  | FormDeleteSuccessAction
  | QuestionsLoadRequestAction
  | QuestionsLoadSuccessAction
  | QuestionsLoadErrorAction;
