import { checkConditionWithValue } from 'src/shared/utils';
import { getAnswerValueByQuestionType } from 'src/store/selectors';
import {
  AnswerListingIndexedItem,
  Condition,
  Question,
  QuestionListingIndexedType,
  QuestionTypes,
  SelectedFormState,
  SelectedInterviewState,
} from 'src/store/types';

export const validateMessages = {
  required: 'This field is required',
  types: {
    email: 'Must be a valid email',
    number: 'Must be a valid number',
    url: 'Must be a valid url',
  },
};

/**
 * Evaluate if the condition to be evaluated belongs to the same form that's currently being used.
 * @param subject from condition (the question from which we depend to provide an answer)
 * @param indexedSelectedFormQuestionsItem
 */
export const isInternalFormCondition = (
  { subject }: Condition,
  indexedSelectedFormQuestionsItem: QuestionListingIndexedType,
): boolean => {
  return subject
    ? !!indexedSelectedFormQuestionsItem[Number(subject.id)]
    : false;
};

/**
 * Evaluates a question's conditions whose subject belongs to the same form as the question.
 * Precondition: conditions that belong to the same question have the same connector (ConnectorType)
 * @param question to be evaluated
 * @param answers
 * @param indexedSelectedFormQuestionsItem
 */
const checkConditionIsMetInInternalForm = (
  question: Question,
  answers: AnswerListingIndexedItem,
  indexedSelectedFormQuestionsItem: QuestionListingIndexedType,
): boolean => {
  if (Array.isArray(question.conditions) && question.conditions.length > 0) {
    const internalConditions = question.conditions.filter(
      (condition: Condition) =>
        isInternalFormCondition(condition, indexedSelectedFormQuestionsItem),
    );

    const connectorAll = question.conditions[0]?.connector === 'AND';

    return internalConditions.reduce<boolean>(
      (partial: boolean, condition: Condition) => {
        const currentAnswer =
          condition.subject?.id && answers[condition.subject.id];
        const originalQuestion =
          condition.subject?.id &&
          indexedSelectedFormQuestionsItem[Number(condition.subject.id)];
        const parsedAnswer =
          currentAnswer && originalQuestion
            ? getAnswerValueByQuestionType(originalQuestion.type, currentAnswer)
            : undefined;

        return connectorAll
          ? partial && checkConditionWithValue(condition, parsedAnswer)
          : partial || checkConditionWithValue(condition, parsedAnswer);
      },
      connectorAll,
    );
  } else {
    return true;
  }
};

/**
 * Evaluates if a given question asserts its conditions' rules.
 * `visibilityPrecondition` is the result of conditions whose subject belongs to a different form than the question's one.
 * Therefore the end result is based on the evaluation of conditions that belong to external forms && conditions that belong to the question's form.
 * @param question to be evaluated
 * @param answers
 * @param indexedSelectedFormQuestionsItem
 */
export const checkIfConditionIsMet = (
  question: Question,
  answers: AnswerListingIndexedItem,
  indexedSelectedFormQuestionsItem: QuestionListingIndexedType,
): boolean => {
  if (question.id && Array.isArray(question.conditions)) {
    const isAndConnector = question.conditions?.[0]?.connector === 'AND';
    const { visibilityPrecondition } = question;
    const hasExternalConditions = !!question.conditions.find(
      (condition: Condition) =>
        !isInternalFormCondition(condition, indexedSelectedFormQuestionsItem),
    );
    const externalFormsConditionsAreMet =
      (!hasExternalConditions && isAndConnector) || !!visibilityPrecondition;

    if ((isAndConnector && externalFormsConditionsAreMet) || !isAndConnector) {
      const isConditionMetInInternalForm = checkConditionIsMetInInternalForm(
        question,
        answers,
        indexedSelectedFormQuestionsItem,
      );
      return isAndConnector
        ? externalFormsConditionsAreMet && isConditionMetInInternalForm
        : externalFormsConditionsAreMet || isConditionMetInInternalForm;
    }
  }
  return false;
};

/**
 * @return boolean QuestionType accepts an answer
 * @param type
 */
export const isAnswerableQuestion = (type: QuestionTypes): boolean => {
  switch (type) {
    case QuestionTypes.SECTION:
    case QuestionTypes.TIPS:
    case QuestionTypes.VIDEO:
      return false;
    default:
      return true;
  }
};

/**
 * @return boolean QuestionType not displayed in interview main body
 * @param type
 */
export const isOutOfFlowQuestion = (type: QuestionTypes): boolean => {
  switch (type) {
    case QuestionTypes.TIPS:
    case QuestionTypes.VIDEO:
      return true;
    default:
      return false;
  }
};

/**
 * Return if a question should be displayed or not depending on its condition rules.
 * @param question
 * @param answers
 * @param indexedSelectedFormQuestionsItem
 * @param selectedInterview
 */
export const isConditionToShowMet = (
  question: Question,
  answers: AnswerListingIndexedItem,
  indexedSelectedFormQuestionsItem: QuestionListingIndexedType,
  selectedInterview?: SelectedInterviewState,
): boolean => {
  if (
    selectedInterview &&
    Array.isArray(question.conditions) &&
    question.conditions.length > 0
  ) {
    const conditionMet = checkIfConditionIsMet(
      question,
      answers,
      indexedSelectedFormQuestionsItem,
    );

    return (
      (question.conditions[0]?.effect === 'SHOW' && conditionMet) ||
      (question.conditions[0]?.effect === 'HIDE' && !conditionMet)
    );
  }
  return true;
};

/**
 * Check if a question is locally marked as deleted
 * @param question
 */
export const isQuestionMarkedAsDeleted = (
  question: Question,
  selectedForm: SelectedFormState,
): boolean => {
  return Boolean(
    question.id &&
      selectedForm.metadata.deletedQuestions.indexOf(question.id) !== -1,
  );
};

/**
 * Handle question state for Interview page. If a question is not displayed then it's answer, if any, should be deleted.
 */
export const processQuestionsMetadata = (
  questions: Question[],
  answers: AnswerListingIndexedItem,
  indexedSelectedFormQuestionsItem: QuestionListingIndexedType,
  selectedForm: SelectedFormState,
  selectedInterview?: SelectedInterviewState,
): {
  visibleQuestionsIds: number[];
  deleteAnswersIds: number[];
  questionsToAnswer: number;
} => {
  // questionToAnswer is used for tracking form progress
  let questionsToAnswer = 0;
  // currentSection is used for tracking to which section each answerable question belongs and handle section's logic
  let currentSection: { id?: number; visible?: boolean } = { visible: true };

  // visibleQuestionsIds declares which questions ended up being rendered (i.e., for Assessment Report generation)
  const visibleQuestionsIds: number[] = [];
  const deleteAnswersIds: number[] = [];

  // For each visible question, create an appropriated ReactElement
  for (const question of questions) {
    if (isQuestionMarkedAsDeleted(question, selectedForm)) {
      continue;
    }

    const { id = 0, internalId, type } = question;

    // Check if question should be displayed based on conditions, return null if it doesn't
    const conditionToShowMet = isConditionToShowMet(
      question,
      answers,
      indexedSelectedFormQuestionsItem,
      selectedInterview,
    );

    // If this is a section, update current section index
    if (type.key === QuestionTypes.SECTION) {
      const sectionId = id || internalId;
      currentSection = { id: sectionId as number, visible: conditionToShowMet };
    }

    if (conditionToShowMet && currentSection.visible) {
      if (isAnswerableQuestion(type.key as QuestionTypes)) {
        questionsToAnswer = questionsToAnswer + 1;
        visibleQuestionsIds.push(id);
      }
    } else {
      deleteAnswersIds.push(id);
    }
  }

  return { visibleQuestionsIds, deleteAnswersIds, questionsToAnswer };
};

/**
 * Handle question state for Interview page. If a question is not displayed then it's answer, if any, should be deleted.
 */
export const filterQuestions = (
  questions: Question[],
  availableQuestionsIds: number[],
): Question[] => {
  const filteredQuestions = questions.filter(({ id, type }) => {
    if (!id) {
      return false;
    }

    if (!availableQuestionsIds.includes(id)) {
      return false;
    }

    if (isOutOfFlowQuestion(type.key as QuestionTypes)) {
      return false;
    }

    return true;
  });

  return filteredQuestions;
};
