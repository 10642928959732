/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  RootState,
  AssessmentState,
  AssessmentTypeState,
  AssessmentTypeListingIndexedItem,
  AssessmentListingIndexedItem,
  AssessmentTypesLabelListingIndexedItem,
  AssessmentTypesDelegateState,
} from '../types';
import {
  getIndexedAssessmentsByActiveCompany,
  getCompanyLastAssessmentId,
  getIndexedAssessments,
  getCompanyOlderAssessments,
} from './assessment';

export const getAssessmentTypes = (
  state: RootState,
): AssessmentTypeListingIndexedItem => {
  return state.assessmentTypeListing.assessmentTypes;
};

export const getAssessmentTypeLabels = (
  state: RootState,
): AssessmentTypesLabelListingIndexedItem => {
  return state.selectedAssessmentType.assessmentType.assessmentTypesLabels;
};

export const getAssessmentTypeDelegates = (
  state: RootState,
): AssessmentTypesDelegateState[] => {
  return Object.values(
    state.selectedAssessmentType.assessmentType.assessmentTypesDelegates,
  );
};

export const getCompanyAssessmentTypes = createSelector(
  (state: RootState) => getAssessmentTypes(state),
  (state: RootState) => getIndexedAssessmentsByActiveCompany(state),
  (
    types: AssessmentTypeListingIndexedItem,
    assessments: AssessmentListingIndexedItem,
  ) => {
    return Object.values(assessments).reduce(
      (typeList: AssessmentTypeState[], assessment: AssessmentState) => {
        const type = types[assessment.typeId];
        return [...typeList, type];
      },
      [],
    );
  },
);

export const getCompanyLastAssessmentType = createSelector(
  (state: RootState) => getAssessmentTypes(state),
  (state: RootState) => getIndexedAssessments(state),
  (state: RootState) => getCompanyLastAssessmentId(state),
  (
    types: AssessmentTypeListingIndexedItem,
    assessments: AssessmentListingIndexedItem,
    id: number | undefined,
  ) => {
    if (id) return types[assessments[id].typeId];
    return undefined;
  },
);

export const getCompanyOlderAssessmentTypes = createSelector(
  (state: RootState) => getAssessmentTypes(state),
  (state: RootState) => getCompanyOlderAssessments(state),
  (types: AssessmentTypeListingIndexedItem, assessments: AssessmentState[]) => {
    return assessments.reduce(
      (typeList: AssessmentTypeState[], assessment: AssessmentState) => {
        const type = types[assessment.typeId];
        return [...typeList, type];
      },
      [],
    );
  },
);

export const getAssessmentTypeById = createSelector(
  (state: RootState) => getAssessmentTypes(state),
  (state: RootState, assessment: AssessmentState) => assessment,
  (types: AssessmentTypeListingIndexedItem, assessment?: AssessmentState) => {
    return assessment ? types[assessment.typeId] : undefined;
  },
);

export const getAssessmentTypeLabel = createSelector(
  (state: RootState) => getAssessmentTypeLabels(state),
  (state: RootState, labelName: string) => labelName,
  (labels: AssessmentTypesLabelListingIndexedItem, labelName: string) => {
    return labels[labelName] ? labels[labelName].value : '';
  },
);
