import { call, takeEvery, put } from 'redux-saga/effects';
import {
  THREAD_LIST_LOAD_REQUEST,
  ThreadsLoadRequestAction,
  Thread,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadThreads = function* async({
  assessmentId,
  documentDraftId,
}: ThreadsLoadRequestAction): Generator {
  try {
    const threads =
      assessmentId || documentDraftId
        ? yield call(api.getThreadsWithComments, {
            assessmentId,
            documentDraftId,
          })
        : [];
    yield put(actions.threadsLoadSuccess(threads as Thread[]));
  } catch (err) {
    yield put(actions.threadsLoadError('Failed to load threads', err as Error));
  }
};

export default function* (): Generator {
  return [yield takeEvery(THREAD_LIST_LOAD_REQUEST, loadThreads)];
}
