import { call, takeEvery, put } from 'redux-saga/effects';
import {
  DOCUMENTS_DRAFTS_LOAD_REQUEST,
  DocumentsDraftsLoadRequestAction,
  DocumentDraftFromResponse,
} from 'src/store/types';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadDocumentsDrafts = function* async({
  documentId,
}: DocumentsDraftsLoadRequestAction): Generator {
  try {
    const documentDrafts = yield call(api.getDocumentDraftList, documentId);
    yield put(
      actions.documentDraftsLoadSuccess(
        documentDrafts as DocumentDraftFromResponse[],
      ),
    );
  } catch (err) {
    yield put(
      actions.documentDraftsLoadError(
        'Failed to load document drafts',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [yield takeEvery(DOCUMENTS_DRAFTS_LOAD_REQUEST, loadDocumentsDrafts)];
}
