/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  RootState,
  TemplateState,
  TemplateListingIndexedItem,
  TemplateFilters,
  TemplatesListingIndexedInterface,
  FolderObject,
} from '../types';

const NoFolderLabel = '(No folder specified)';

const getIndexedTemplatesFromState = (
  state: RootState,
): TemplateListingIndexedItem => {
  return state.templateListing.templates;
};

export const getTemplatesTypesOptions = (state: RootState) => {
  const types = getTemplatesFromState(state).reduce<string[]>(
    (types, template) => {
      if (template.assessmentType) {
        return [...types, template.assessmentType?.label];
      }
      return types;
    },
    [],
  );
  return Array.from(new Set(types)).map((type: string) => ({
    text: type,
    value: type,
  }));
};

export const getTemplatesFromState = (state: RootState): TemplateState[] => {
  return Object.values(getIndexedTemplatesFromState(state));
};

const indexTemplatesByFolder = (
  templates: TemplateState[],
): TemplatesListingIndexedInterface => {
  const indexedFolders: TemplatesListingIndexedInterface = {};
  const emptyFolder: FolderObject = { label: '', templates: [] };
  templates.forEach((template) => {
    if (template.folder) {
      if (indexedFolders[template.folder]) {
        indexedFolders[template.folder].templates.push(template);
      } else {
        indexedFolders[template.folder] = {
          label: template.folder,
          templates: [template],
        } as FolderObject;
      }
    } else {
      emptyFolder.templates.push(template);
    }
  });
  if (emptyFolder.templates.length) {
    indexedFolders['(No folder specified)'] = emptyFolder;
  }
  return indexedFolders;
};

export const getTemplatesFolders = (
  state: RootState,
): TemplatesListingIndexedInterface => {
  return indexTemplatesByFolder(getTemplatesFromState(state));
};

export const getTemplateFoldersOptions = createSelector(
  (state: RootState) => getTemplatesFromState(state),
  (templates: TemplateState[]) => {
    const allTemplateFolders = templates.map(
      ({ folder }: TemplateState): string => {
        if (folder && folder.length) {
          return folder;
        }
        return NoFolderLabel;
      },
    );
    const uniqueNames = [...new Set<string>(allTemplateFolders)];
    return uniqueNames.map((folder: string) => ({
      text: folder,
      value: folder,
    }));
  },
);

export const getTemplatesTree = createSelector(
  (state: RootState) => getTemplatesFolders(state),
  (_: RootState, { title }: TemplateFilters) => title || '',
  (_: RootState, { folder }: TemplateFilters) => folder || '',
  (_: RootState, { type }: TemplateFilters) => type || '',
  (
    templates: TemplatesListingIndexedInterface,
    title: string,
    folder: string,
    type: string,
  ) => {
    let filteredTemplates: TemplateState[] = Object.values(templates).reduce<
      TemplateState[]
    >((allTemplates, folder) => {
      return [...allTemplates, ...folder.templates];
    }, []);

    if (folder && folder.length) {
      const capsFolder = folder.toUpperCase();
      if (folder === NoFolderLabel) {
        filteredTemplates = filteredTemplates.filter(
          (t: TemplateState) => t.folder === '',
        );
      } else {
        filteredTemplates = filteredTemplates.filter(
          (t: TemplateState) => t.folder?.toUpperCase() === capsFolder,
        );
      }
    }
    if (title) {
      const capsTitle = title.toUpperCase();
      filteredTemplates = filteredTemplates.filter((t: TemplateState) =>
        t.title.toUpperCase().includes(capsTitle),
      );
    }
    if (type) {
      filteredTemplates = filteredTemplates.filter((t: TemplateState) =>
        t.assessmentType ? t.assessmentType.label === type : false,
      );
    }

    return indexTemplatesByFolder(filteredTemplates);
  },
);
