import {
  Loader,
  DocumentDraftListingState,
  DocumentsDraftsListingActionTypes,
  DOCUMENTS_DRAFTS_LOAD_REQUEST,
  DOCUMENTS_DRAFTS_LOAD_SUCCESS,
  DocumentDraftListingIndexedItem,
  DocumentDraftState,
  DOCUMENT_DRAFT_UPDATE_SUCCESS,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const documentDraftListingInitialState: DocumentDraftListingState = {
  documentDrafts: {},
  loader: loaderInitialState,
};

export const documentDraftListingReducer = (
  state = documentDraftListingInitialState,
  action: DocumentsDraftsListingActionTypes,
): DocumentDraftListingState => {
  switch (action.type) {
    case DOCUMENTS_DRAFTS_LOAD_REQUEST: {
      return {
        ...state,
        loader: { ...loaderInitialState, loading: true },
      };
    }
    case DOCUMENTS_DRAFTS_LOAD_SUCCESS: {
      const list = action.documentDrafts.reduce(
        (
          list: DocumentDraftListingIndexedItem,
          documentDraft: DocumentDraftState,
        ) => {
          return {
            ...list,
            [documentDraft.id]: documentDraft,
          };
        },
        {},
      );
      return {
        documentDrafts: list,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case DOCUMENT_DRAFT_UPDATE_SUCCESS: {
      return {
        ...state,
        documentDrafts: {
          ...state.documentDrafts,
          [action.documentDraft.id]: action.documentDraft,
        },
      };
    }
    default:
      return state;
  }
};
