import styled from 'styled-components';
import React, { FC } from 'react';
import { ButtonProps } from 'antd';
import { useHistory } from 'react-router-dom';
import { AuthMethod, BodySMS } from 'src/types/common';
import { Button } from 'src/components/Button';
import {
  THIconSMS,
  THIconGoogleApp,
  THIcon1Password,
  THIconLastpass,
  THIconAuthy,
  THIconMicrosoftAuth,
} from 'src/theme/media';
import * as S from './TwoFASetupStyles';

export const AppButton: React.FC<ButtonProps> = styled(Button)`
  position: absolute;
  bottom: 26px;
  left: 15%;
  width: 186px;
`;

export interface TwoFASetupProps {
  onCancel?: () => void;
  onFinish?: (
    authMethod: AuthMethod,
    code: string,
    params?: Partial<BodySMS>,
  ) => void;
}

export const TwoFASetup: FC<TwoFASetupProps> = (props) => {
  const history = useHistory();

  return (
    <S.SetupContainer>
      <S.ContentContainer>
        <S.AuthMethodCard>
          <S.AuthMethodCardTitle>Verify with SMS</S.AuthMethodCardTitle>
          <S.AuthMethodDescriptor>
            When logging in we’ll send you a code via <b>SMS</b>.
          </S.AuthMethodDescriptor>
          <S.IconSMS src={THIconSMS} />
          <AppButton
            title="SMS verification"
            onClick={() => {
              history.push('/2fa/turn-on/sms');
            }}
          />
        </S.AuthMethodCard>
        <S.AuthMethodCard>
          <S.AuthMethodCardTitle>Verify with an App</S.AuthMethodCardTitle>
          <S.AuthMethodDescriptor>
            When logging in you’ll use an <b>authenticator</b> app.
          </S.AuthMethodDescriptor>
          <S.AppsListing>
            <li>
              <img src={THIconGoogleApp} />
            </li>
            <li>
              <img src={THIconLastpass} />
            </li>
            <li>
              <img src={THIconAuthy} />
            </li>
            <li>
              <img src={THIconMicrosoftAuth} />
            </li>
            <li>
              <img src={THIcon1Password} />
            </li>
          </S.AppsListing>
          <AppButton
            title="App verification"
            onClick={() => {
              history.push('/2fa/turn-on/app');
            }}
          />
        </S.AuthMethodCard>
      </S.ContentContainer>
    </S.SetupContainer>
  );
};
