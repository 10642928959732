/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Loader, RootState, CertificateListingResponse } from 'src/store/types';

export const getCertificateListingLoader = (state: RootState): Loader =>
  state.certificateListing.loader;
export const getCertificateListingResponse = (
  state: RootState,
): CertificateListingResponse =>
  state.certificateListing.certificateListingResponse;
