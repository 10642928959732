import { Loader, ShowErrorAction, User } from 'src/store/types';
import { TrainingPeriodStatus } from 'src/store/types/training';

export const TRAINING_PERIOD_CREATE_REQUEST = 'TRAINING_PERIOD_CREATE_REQUEST';
export const TRAINING_PERIOD_CREATE_SUCCESS = 'TRAINING_PERIOD_CREATE_SUCCESS';
export const TRAINING_PERIOD_CREATE_FAILED = 'TRAINING_PERIOD_CREATE_FAILED';

export type TrainingPeriodConfig = {
  releaseDate: Date;
  dueDate: Date;
  companyId: number;
  userId?: string;
};

export type TrainingPeriodResponse = {
  id: number;
  createdAt: Date;
  releaseDate: Date;
  dueDate: Date | null;
  status: TrainingPeriodStatus;
  expirationDate: Date;
  user?: User;
};

export type TrainingPeriodState = {
  trainingPeriod?: TrainingPeriodResponse;
  loader: Loader;
};

export interface TrainingPeriodCreateRequestAction {
  type: typeof TRAINING_PERIOD_CREATE_REQUEST;
  config: TrainingPeriodConfig;
}

export interface TrainingPeriodCreateSuccessAction {
  type: typeof TRAINING_PERIOD_CREATE_SUCCESS;
  trainingPeriod: TrainingPeriodResponse;
}

export type TrainingPeriodCreateErrorAction = ShowErrorAction<
  typeof TRAINING_PERIOD_CREATE_FAILED
>;

export const TRAINING_PERIOD_RESET = 'TRAINING_PERIOD_RESET';

export interface TrainingPeriodResetAction {
  type: typeof TRAINING_PERIOD_RESET;
}

export const TRAINING_PERIOD_LOAD_REQUEST = 'TRAINING_PERIOD_LOAD_REQUEST';
export const TRAINING_PERIOD_LOAD_SUCCESS = 'TRAINING_PERIOD_LOAD_SUCCESS';
export const TRAINING_PERIOD_LOAD_FAILED = 'TRAINING_PERIOD_LOAD_FAILED';

export interface TrainingPeriodLoadRequestAction {
  type: typeof TRAINING_PERIOD_LOAD_REQUEST;
  trainingPeriodId: number;
}

export interface TrainingPeriodLoadSuccessAction {
  type: typeof TRAINING_PERIOD_LOAD_SUCCESS;
  trainingPeriod: TrainingPeriodResponse;
}

export type TrainingPeriodLoadErrorAction = ShowErrorAction<
  typeof TRAINING_PERIOD_LOAD_FAILED
>;

export const TRAINING_PERIOD_UPDATE_REQUEST = 'TRAINING_PERIOD_UPDATE_REQUEST';
export const TRAINING_PERIOD_UPDATE_SUCCESS = 'TRAINING_PERIOD_UPDATE_SUCCESS';
export const TRAINING_PERIOD_UPDATE_FAILED = 'TRAINING_PERIOD_UPDATE_FAILED';

export interface TrainingPeriodUpdateRequestAction {
  type: typeof TRAINING_PERIOD_UPDATE_REQUEST;
  trainingPeriodId: number;
  config: TrainingPeriodConfig;
}

export interface TrainingPeriodUpdateSuccessAction {
  type: typeof TRAINING_PERIOD_UPDATE_SUCCESS;
  trainingPeriod: TrainingPeriodResponse;
}

export type TrainingPeriodUpdateErrorAction = ShowErrorAction<
  typeof TRAINING_PERIOD_UPDATE_FAILED
>;

export const TRAINING_PERIOD_COMPLETE_REQUEST =
  'TRAINING_PERIOD_COMPLETE_REQUEST';
export const TRAINING_PERIOD_COMPLETE_SUCCESS =
  'TRAINING_PERIOD_COMPLETE_SUCCESS';
export const TRAINING_PERIOD_COMPLETE_FAILED =
  'TRAINING_PERIOD_COMPLETE_FAILED';

export interface TrainingPeriodCompleteRequestAction {
  type: typeof TRAINING_PERIOD_COMPLETE_REQUEST;
  trainingPeriodId: number;
}

export interface TrainingPeriodCompleteSuccessAction {
  type: typeof TRAINING_PERIOD_COMPLETE_SUCCESS;
  trainingPeriod: TrainingPeriodResponse;
}

export type TrainingPeriodCompleteFailedAction = ShowErrorAction<
  typeof TRAINING_PERIOD_COMPLETE_FAILED
>;

export const TRAINING_PERIOD_COMPANY_LOAD_REQUEST =
  'TRAINING_PERIOD_COMPANY_LOAD_REQUEST';
export const TRAINING_PERIOD_COMPANY_LOAD_SUCCESS =
  'TRAINING_PERIOD_COMPANY_LOAD_SUCCESS';
export const TRAINING_PERIOD_COMPANY_LOAD_FAILED =
  'TRAINING_PERIOD_COMPANY_LOAD_FAILED';

export interface TrainingPeriodCompanyLoadRequest {
  type: typeof TRAINING_PERIOD_COMPANY_LOAD_REQUEST;
}

export interface TrainingPeriodCompanyLoadSuccess {
  type: typeof TRAINING_PERIOD_COMPANY_LOAD_SUCCESS;
  trainingPeriod: TrainingPeriodResponse;
}

export interface TrainingPeriodCompanyLoadFailed {
  type: typeof TRAINING_PERIOD_COMPANY_LOAD_FAILED;
  error: string;
}

export type TrainingPeriodTypes =
  | TrainingPeriodCreateRequestAction
  | TrainingPeriodCreateSuccessAction
  | TrainingPeriodCreateErrorAction
  | TrainingPeriodResetAction
  | TrainingPeriodLoadRequestAction
  | TrainingPeriodLoadSuccessAction
  | TrainingPeriodLoadErrorAction
  | TrainingPeriodUpdateRequestAction
  | TrainingPeriodUpdateSuccessAction
  | TrainingPeriodUpdateErrorAction
  | TrainingPeriodCompleteRequestAction
  | TrainingPeriodCompleteSuccessAction
  | TrainingPeriodCompleteFailedAction
  | TrainingPeriodCompanyLoadRequest
  | TrainingPeriodCompanyLoadSuccess
  | TrainingPeriodCompanyLoadFailed
  | TrainingPeriodCompanyLoadRequest
  | TrainingPeriodCompanyLoadSuccess
  | TrainingPeriodCompanyLoadFailed;
