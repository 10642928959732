import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';

import { Button } from 'src/components/Button';
import { TopNavbar } from 'src/components/TopNavbar';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { getUserLoader, getTrainingTypes } from 'src/store/selectors';
import {
  getAdminUsers as getAdminUsersSelector,
  getSelectedAdminUser,
} from 'src/store/selectors/userListing';
import { UserListingTable, FormCreateUser } from 'src/features/user/components';
import { Pagination } from 'src/components/Pagination';
import { RootState, TrainingTypesResponse } from 'src/store/types';
import actions from 'src/store/actions';
import { COMPANY_BASIC_ROLES } from 'src/constants/user';

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const UserManagementPage: FC = () => {
  const dispatch = useDispatch();
  const { isAdmin } = useAuth();
  const [formPanelVisible, setFormPanelVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);
  const [userForm] = Form.useForm();
  const userLoader = useSelector(getUserLoader);
  const selectedUser = useSelector((state: RootState) =>
    getSelectedAdminUser(state, selectedUserId),
  );
  const users = useSelector((state: RootState) => {
    return getAdminUsersSelector(state, {
      offset: (currentPage - 1) * pageSize,
      limit: currentPage * pageSize,
    });
  });
  const trainingTypes: TrainingTypesResponse = useSelector(getTrainingTypes);

  useEffect(() => {
    dispatch(actions.trainingTypesLoadRequest());
    dispatch(actions.getAdminUsers());
  }, []);

  useEffect(() => {
    if (userLoader.completed && userLoader.success) {
      setSelectedUserId(0);
      setFormPanelVisible(false);
      setLoading(false);
      userForm.resetFields();
    }
  }, [userLoader]);

  useEffect(() => {
    if (selectedUserId && selectedUserId !== 0) {
      const { role, trainingTypeId, attributes } = selectedUser;
      const { typeOfTrainings } = attributes;
      userForm.setFieldsValue({
        ...selectedUser,
        trainingTypeId,
        typeOfTrainings,
      });
      setFormPanelVisible(true);
      setUserRole(role);
    } else {
      userForm.resetFields();
      setFormPanelVisible(false);
    }
  }, [selectedUser]);

  const showEditUserPanel = (userId: number) => {
    setSelectedUserId(userId);
  };

  const handleOnSave = () => {
    userForm.submit();
  };

  const handleOnClose = () => {
    setFormPanelVisible(false);
    setSelectedUserId(0);
    setUserRole(undefined);
    userForm.resetFields();
  };

  const handleOnCreateAdminClick = () => {
    setFormPanelVisible(true);
  };

  const getTopNavbarButtons = () => {
    const buttons: JSX.Element[] = [];

    if (isAdmin) {
      buttons.push(
        <Button
          title="Add new user"
          onClick={handleOnCreateAdminClick}
          key={1}
        />,
      );
    }

    return buttons;
  };

  return (
    <>
      <TopNavbar
        title="Global Users Management"
        prevRoute={null}
        extraOptions={getTopNavbarButtons()}
      />
      <PageContentContainer>
        <SlidingPanel
          title={`${selectedUserId ? 'Edit' : 'Add'} user`}
          visible={formPanelVisible}
          saveLabel={`${selectedUserId !== 0 ? 'Update' : 'Save'}`}
          onClose={handleOnClose}
          onSave={handleOnSave}
          loading={loading}
          enabled
        >
          <FormCreateUser
            userForm={userForm}
            userId={selectedUserId}
            trainingTypes={trainingTypes}
            editOther
            excludeRoles={COMPANY_BASIC_ROLES}
            userRole={userRole}
            setUserRole={setUserRole}
          />
        </SlidingPanel>
        <UserListingTable
          users={users.data}
          showEditUserPanel={showEditUserPanel}
        />
        {users.totalItems > 10 && (
          <Pagination
            current={currentPage}
            items={users.totalItems}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={(size) => {
              setPageSize(size);
              setCurrentPage(1);
            }}
            sizes={[10, 25, 50]}
          />
        )}
      </PageContentContainer>
    </>
  );
};
