import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getConstantById } from 'src/store/selectors/constant';
import { RootState, ConstantState } from 'src/store/types';
import { TokenPreview } from './TokenPreview';

interface ConstantTokenPreviewProps {
  constantId: string | undefined;
}

export const ConstantTokenPreview: FC<ConstantTokenPreviewProps> = ({
  constantId,
}) => {
  const constant = useSelector<RootState, ConstantState | undefined>(
    (state: RootState) => getConstantById(state, Number(constantId)),
  );
  let tooltip = `Constant "${constantId}" was not found`;
  let text = `Constant #${constantId}`;
  if (constant) {
    tooltip = '';
    text = `C: ${constant.description}`;
  }

  return <TokenPreview tooltip={tooltip} text={text} error={!constant} />;
};

export const ConstantTokenPreviewMemoized = React.memo(ConstantTokenPreview);
