import { Loader } from './loader';
import { TemplateState } from './template';
import { ShowErrorAction } from './error';

export const TEMPLATES_LOAD_REQUEST = 'TEMPLATES_LOAD_REQUEST';
export const TEMPLATES_LOAD_SUCCESS = 'TEMPLATES_LOAD_SUCCESS';
export const TEMPLATES_LOAD_ERROR = 'TEMPLATES_LOAD_ERROR';

export const ADD_TEMPLATE_TO_LIST = 'ADD_TEMPLATE_TO_LIST';
export const REMOVE_TEMPLATE_FROM_LIST = 'REMOVE_TEMPLATE_FROM_LIST';

export interface TemplateListingState {
  templates: TemplateListingIndexedItem;
  loader: Loader;
}

export interface TemplateListingIndexedItem {
  [key: string]: TemplateState;
}

export interface FolderObject {
  label: string;
  templates: TemplateState[];
}

export interface TemplatesListingIndexedInterface {
  [key: string]: FolderObject;
}

export interface TemplatesLoadRequestAction {
  type: typeof TEMPLATES_LOAD_REQUEST;
  assessmentTypeId?: number;
}

export interface TemplatesLoadSuccessAction {
  type: typeof TEMPLATES_LOAD_SUCCESS;
  templates: TemplateState[];
}

export type TemplatesLoadErrorAction = ShowErrorAction<
  typeof TEMPLATES_LOAD_ERROR
>;

export interface AddTemplateToListAction {
  type: typeof ADD_TEMPLATE_TO_LIST;
  template: TemplateState;
}

export interface RemoveTemplateFromListAction {
  type: typeof REMOVE_TEMPLATE_FROM_LIST;
  templateId: number;
}

export type TemplateListingActionTypes =
  | TemplatesLoadRequestAction
  | TemplatesLoadSuccessAction
  | TemplatesLoadErrorAction
  | AddTemplateToListAction
  | RemoveTemplateFromListAction;
