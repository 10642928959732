import { HttpClient } from 'src/api/services/HttpClient';
import {
  ExamConfig,
  ExamListingResponse,
  QuizQuestion,
  Exam,
  ExamByUserResponse,
  ExamSubmission,
  CertificateListingResponse,
  ExamRawResponse,
} from 'src/store/types';

import { mapExamRawResponse } from '../utils';

class ExamService extends HttpClient {
  public constructor() {
    super(`${process.env.REACT_APP_API_URL}exam`);
  }

  public create = async (config: ExamConfig): Promise<Exam> => {
    const response = await this.instance.post<ExamRawResponse>('/', config, {
      withCredentials: true,
    });
    return mapExamRawResponse(response);
  };

  public update = async (examId: number, config: ExamConfig): Promise<void> => {
    await this.instance.put(`/${examId}`, config, { withCredentials: true });
  };

  public activate = async (examId: number): Promise<void> => {
    await this.instance.post(
      `/${examId}/activate`,
      {},
      { withCredentials: true },
    );
  };

  public archive = async (examId: number): Promise<void> => {
    await this.instance.post(
      `/${examId}/archive`,
      {},
      { withCredentials: true },
    );
  };

  public delete = async (examId: number): Promise<void> => {
    await this.instance.delete(`/${examId}`, { withCredentials: true });
  };

  public getAll = async (
    page: number,
    pageSize: number,
    name?: string,
  ): Promise<ExamListingResponse> => {
    let query = `?page=${page}&take=${pageSize}`;

    if (name) {
      query = `${query}&name=${encodeURIComponent(name)}`;
    }

    const response = await this.instance.get<{ data: ExamListingResponse }>(
      query,
      {
        withCredentials: true,
      },
    );
    return response.data;
  };

  public getById = async (examId: number, shuffled = false): Promise<Exam> => {
    const response = await this.instance.get<{ data: Exam }>(
      `/${examId}?shuffled=${shuffled}`,
      {
        withCredentials: true,
      },
    );

    return response.data;
  };

  public getByUser = async (): Promise<ExamByUserResponse> => {
    const response = await this.instance.get<{ data: ExamByUserResponse }>(
      `/user`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  };

  public createQuestion = async (examId: number): Promise<QuizQuestion> => {
    return await this.instance.post<QuizQuestion>(
      `/${examId}/quiz-question`,
      {},
      { withCredentials: true },
    );
  };

  public updateQuestion = async (
    examId: number,
    question: QuizQuestion,
  ): Promise<QuizQuestion> => {
    const response = await this.instance.put<{ data: QuizQuestion }>(
      `/${examId}/quiz-question/${question.id}`,
      {
        questionChoices: question.choices.map((choice) => ({
          ...choice,
          choiceIsCorrect: choice.isCorrect,
        })),
        questionText: question.questionText,
      },
      {
        withCredentials: true,
      },
    );
    return response.data;
  };

  public deleteQuestion = async (
    examId: number,
    questionId: number,
  ): Promise<QuizQuestion> => {
    const response = await this.instance.delete<{ data: QuizQuestion }>(
      `/${examId}/quiz-question/${questionId}`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  };

  public createSubmission = async (examId: number): Promise<ExamSubmission> => {
    return await this.instance.post<ExamSubmission>(
      `/${examId}/submission`,
      {},
      {
        withCredentials: true,
      },
    );
  };

  public createSubmissionResponse = async (
    examId: number,
    submissionId: number,
    questionId: number,
    choiceId: number,
  ): Promise<ExamSubmission> => {
    return await this.instance.post<ExamSubmission>(
      `/${examId}/submission/${submissionId}`,
      { questionId, choiceId },
      {
        withCredentials: true,
      },
    );
  };

  public computeSubmission = async (
    examId: number,
    submissionId: number,
  ): Promise<ExamSubmission> => {
    const response = await this.instance.post<{ data: ExamSubmission }>(
      `/${examId}/submission/${submissionId}/compute`,
      {},
      {
        withCredentials: true,
      },
    );
    return response.data;
  };

  public getPassedSubmissions =
    async (): Promise<CertificateListingResponse> => {
      const response = await this.instance.get<{
        data: CertificateListingResponse;
      }>(`/passed-submissions`, {
        withCredentials: true,
      });
      return response.data;
    };

  public cloneExam = async (examId: number) => {
    const response = await this.instance.post<{ data: any }>(
      `/${examId}/clone`,
      {},
      {
        withCredentials: true,
      },
    );

    return response.data;
  };

  public getSubmission = async (examId: number, submissionId: number) => {
    const response = await this.instance.get<{ data: any }>(
      `/${examId}/submission/${submissionId}`,
      {
        withCredentials: true,
      },
    );

    return response.data;
  };

  public getExamQuizByUser = async (): Promise<Exam> => {
    const response = await this.instance.get<{ data: Exam }>(`/quiz`, {
      withCredentials: true,
    });

    return response.data;
  };

  public getExamQuizById = async (examId: number): Promise<Exam> => {
    const response = await this.instance.get<{ data: Exam }>(
      `/${examId}/quiz`,
      {
        withCredentials: true,
      },
    );

    return response.data;
  };
}

export default new ExamService();
