/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState, useEffect } from 'react';
import { AutoComplete as AutoCompleteAntd } from 'antd';

interface AutocompleteProps {
  options: AutocompleteOption[];
  handleOnChange?: (value: string) => void;
  onSelect?: (selectValue: string) => void;
  placeholder?: string;
  initialTextValue?: string;
}

export interface AutocompleteOption {
  text: string;
  value: string;
}

export const AutoComplete: FC<AutocompleteProps> = ({
  options,
  handleOnChange = () => {},
  onSelect = () => {},
  placeholder = '',
  initialTextValue = '',
}) => {
  const [results, setResults] = useState<AutocompleteOption[]>([]);
  const [textValue, setTextValue] = useState<string>(initialTextValue);

  useEffect(() => {
    setTextValue(initialTextValue);
  }, [initialTextValue]);

  const { Option } = AutoCompleteAntd;

  const handleSearch = (valueToSearch: string) => {
    const filterResults = options.filter(({ value }: AutocompleteOption) =>
      value?.toLowerCase().includes(valueToSearch.toLowerCase()),
    );
    setResults(filterResults);
  };

  return (
    <AutoCompleteAntd
      placeholder={placeholder}
      onSearch={handleSearch}
      onSelect={onSelect}
      onChange={(value: string) => {
        setTextValue(value);
        handleOnChange(value);
      }}
      value={textValue}
    >
      {results.map(({ text, value }: AutocompleteOption) => (
        <Option key={value} value={value}>
          {text}
        </Option>
      ))}
    </AutoCompleteAntd>
  );
};
