import { useState } from 'react';
import { api } from 'src/api/services/ThApi';
import { BodySMS } from 'src/types/common';

export const use2faSMS = (
  cooldown = 60000,
): {
  sendSMS: (body: BodySMS) => void;
  available: boolean;
  sent: boolean;
  error: string;
} => {
  const [available, setAvailable] = useState<boolean>(true);
  const [sent, setSent] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const startCooldown = () => {
    setAvailable(false);
    setTimeout(() => {
      setAvailable(true);
    }, cooldown);
  };

  const sendSMS = async ({ dialCode, phoneNumber }: BodySMS) => {
    startCooldown();
    try {
      await api.send2faSMS(dialCode, phoneNumber);
      setSent(true);
    } catch {
      setError('An error occurred when sending the SMS');
    }
  };

  return {
    sendSMS,
    available,
    sent,
    error,
  };
};
