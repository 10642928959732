import { Loader } from './loader';
import { NotificationFromResponse, ShowErrorAction } from '../types';

export const NOTIFICATIONS_LOAD_REQUEST = 'NOTIFICATIONS_LOAD_REQUEST';
export const NOTIFICATIONS_LOAD_SUCCESS = 'NOTIFICATIONS_LOAD_SUCCESS';
export const NOTIFICATIONS_LOAD_ERROR = 'NOTIFICATIONS_LOAD_ERROR';

export const NOTIFICATIONS_READ_REQUEST = 'NOTIFICATIONS_READ_REQUEST';
export const NOTIFICATIONS_READ_SUCCESS = 'NOTIFICATIONS_READ_SUCCESS';
export const NOTIFICATIONS_READ_ERROR = 'NOTIFICATIONS_READ_ERROR';

export const NOTIFICATIONS_POLLING_START = 'NOTIFICATIONS_POLLING_START';

export interface NotificationListingState {
  notifications: NotificationFromResponse[];
  loader: Loader;
}

export interface NotificationsPollingStartAction {
  type: typeof NOTIFICATIONS_POLLING_START;
  userId: number;
}

export interface NotificationListingLoadRequestAction {
  type: typeof NOTIFICATIONS_LOAD_REQUEST;
  userId: number;
}

export interface NotificationListingLoadSuccessAction {
  type: typeof NOTIFICATIONS_LOAD_SUCCESS;
  notifications: NotificationFromResponse[];
}

export type NotificationListingLoadErrorAction = ShowErrorAction<
  typeof NOTIFICATIONS_LOAD_ERROR
>;

export interface NotificationsReadRequestAction {
  type: typeof NOTIFICATIONS_READ_REQUEST;
  ids: number[];
}

export interface NotificationsReadSuccessAction {
  type: typeof NOTIFICATIONS_READ_SUCCESS;
}

export type NotificationsReadErrorAction = ShowErrorAction<
  typeof NOTIFICATIONS_READ_ERROR
>;

export type NotificationListingActionTypes =
  | NotificationListingLoadRequestAction
  | NotificationListingLoadSuccessAction
  | NotificationListingLoadErrorAction
  | NotificationsReadRequestAction
  | NotificationsReadSuccessAction
  | NotificationsReadErrorAction;
