import React, { FC, useEffect, useState } from 'react';
import { Spin, Typography } from 'antd';
import styled from 'styled-components';
import JSONViewer from 'react-json-viewer';
import moment from 'moment';
import Modal from 'antd/lib/modal/Modal';
import { AssessmentAuditingFilters } from 'src/features/risk-assessment/components/AssessmentAuditingFilters/AssessmentAuditingFilters';
import { Pagination } from 'src/components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  answerLoadActivityLogRequest,
  interviewLoadActivityLogRequest,
} from 'src/store/actions/interview';
import { getSelectedInterview } from 'src/store/selectors';
import { ActivityLogRecordDiff } from 'src/store/types';

interface AssessmentAuditingTabProps {
  interviewId?: number;
}

const { Title: TitleAntD } = Typography;

const Title = styled(TitleAntD)`
  && {
    color: ${({ theme }) => theme.colors.darkBlue.main};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
  }
`;

const PageContentContainer = styled.div`
  margin-top: 40px;
`;

const DiffContainer = styled.div`
  margin-top: 20px;
  max-width: 100%;
  overflow-x: auto;
`;

const RecordContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const RecordUserCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: pink;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
`;

const defaultToDate = new Date();
const defaultFromDate = new Date().setDate(defaultToDate.getDate() - 3);

export const AssessmentAuditingTab: FC<AssessmentAuditingTabProps> = ({
  interviewId,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showDetail, setShowDetail] = useState(false);
  const [fromDate, setFromDate] = useState(moment(defaultFromDate));
  const [toDate, setToDate] = useState(moment(defaultToDate));
  const [username, setUsername] = useState('');
  const { loader, reconciliation } = useSelector(getSelectedInterview);
  const [selectedDiff, setSelectedDiff] = useState<
    ActivityLogRecordDiff | ActivityLogRecordDiff[]
  >();

  useEffect(() => {
    dispatch(
      interviewId
        ? answerLoadActivityLogRequest(
            interviewId,
            fromDate,
            toDate,
            username,
            currentPage,
            pageSize,
          )
        : interviewLoadActivityLogRequest(
            fromDate,
            toDate,
            username,
            currentPage,
            pageSize,
          ),
    );
  }, [fromDate, toDate, username, currentPage, pageSize]);

  return (
    <PageContentContainer>
      <Title>Audits</Title>
      <AssessmentAuditingFilters
        fromDate={fromDate}
        toDate={toDate}
        onFromDateChange={(date) =>
          setFromDate(date || moment(defaultFromDate))
        }
        onToDateChange={(date) => setToDate(date || moment(defaultToDate))}
        onUsernameChange={setUsername}
      />
      {loader.loading ? (
        <Spin size="large" />
      ) : (
        reconciliation.records.map(
          ({ id, author, createdAt, diff, event, description }) => (
            <RecordContainer key={id}>
              <RecordUserCircle>
                {author.substring(0, 1).toUpperCase()}
              </RecordUserCircle>
              <div>
                <div>
                  <b>{`${author}`}</b> at{' '}
                  <b>{moment(createdAt).format('DD/MM/YYYY hh:mm')}</b>
                  {` ${description}`}
                </div>
                <div>
                  <a
                    onClick={() => {
                      setSelectedDiff(diff);
                      setShowDetail(true);
                    }}
                  >
                    View details
                  </a>
                </div>
              </div>
            </RecordContainer>
          ),
        )
      )}
      <Pagination
        current={currentPage}
        items={loader.loading ? 0 : reconciliation.total}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
        onPageSizeChange={(size) => {
          setPageSize(size);
          setCurrentPage(1);
        }}
        sizes={[10, 25, 50]}
      />
      <Modal
        title="Diff details"
        open={showDetail}
        onOk={() => setShowDetail(false)}
        onCancel={() => setShowDetail(false)}
      >
        <DiffContainer>
          <JSONViewer json={selectedDiff} />
        </DiffContainer>
      </Modal>
    </PageContentContainer>
  );
};
