import { useEffect, useState } from 'react';
import { api } from 'src/api/services/ThApi';

export const use2faQR = (): [null | string, string] => {
  const [qrCode, setQrCode] = useState<null | string>(null);
  const [error, setError] = useState<string>('');

  const fetchQrCode = async (): Promise<void> => {
    const qrString = await api.get2FACode();
    setQrCode(qrString);
  };

  useEffect(() => {
    fetchQrCode().catch(() => {
      setError('An error occurred when getting the QR code');
    });
  }, []);

  return [qrCode, error];
};
