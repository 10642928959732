import { FormState } from './form';
import { ShowErrorAction } from './error';
import { Loader } from './loader';

export const ASSESSMENT_TYPES_LOAD_ALL_REQUEST =
  'ASSESSMENT_TYPES_LOAD_ALL_REQUEST';
export const ASSESSMENT_TYPES_LOAD_ALL_SUCCESS =
  'ASSESSMENT_TYPES_LOAD_ALL_SUCCESS';
export const ASSESSMENT_TYPES_LOAD_ALL_ERROR =
  'ASSESSMENT_TYPES_LOAD_ALL_ERROR';

export const ASSESSMENT_TYPES_LOAD_LABEL_REQUEST =
  'ASSESSMENT_TYPES_LOAD_LABEL_REQUEST';
export const ASSESSMENT_TYPES_LOAD_LABEL_SUCCESS =
  'ASSESSMENT_TYPES_LOAD_LABEL_SUCCESS';
export const ASSESSMENT_TYPES_LOAD_LABEL_FAILED =
  'ASSESSMENT_TYPES_LOAD_LABEL_FAILED';

export const ASSESSMENT_TYPES_LOAD_DELEGATE_REQUEST =
  'ASSESSMENT_TYPES_LOAD_DELEGATE_REQUEST';
export const ASSESSMENT_TYPES_LOAD_DELEGATE_SUCCESS =
  'ASSESSMENT_TYPES_LOAD_DELEGATE_SUCCESS';
export const ASSESSMENT_TYPES_LOAD_DELEGATE_FAILED =
  'ASSESSMENT_TYPES_LOAD_DELEGATE_FAILED';

export interface AssessmentTypeFromResponse {
  id: number;
  createdAt: string;
  updatedAt: string;
  label: string;
  forms?: FormState[];
}

export interface AssessmentTypeState {
  id: number;
  createdAt: string;
  updatedAt: string;
  label: string;
  formIds: number[];
}

export interface AssessmentTypeListingIndexedItem {
  [key: string]: AssessmentTypeState;
}

export interface AssessmentTypeListingState {
  assessmentTypes: AssessmentTypeListingIndexedItem;
}

export interface AssessmentTypeLabelsState {
  assessmentTypesLabels: AssessmentTypesLabelListingIndexedItem;
  assessmentTypesDelegates: AssessmentTypesDelegateListingIndexedItem;
}

export interface SelectedAssessmentTypeState {
  assessmentType: AssessmentTypeLabelsState;
  loader: Loader;
}

export interface AssessmentTypesLoadAllRequestAction {
  type: typeof ASSESSMENT_TYPES_LOAD_ALL_REQUEST;
}

export interface AssessmentTypesLoadAllSuccessAction {
  type: typeof ASSESSMENT_TYPES_LOAD_ALL_SUCCESS;
  assessmentTypes: AssessmentTypeFromResponse[];
}

export type AssessmentTypesLoadAllErrorAction = ShowErrorAction<
  typeof ASSESSMENT_TYPES_LOAD_ALL_ERROR
>;

export interface AssessmentTypesLoadLabelRequestAction {
  type: typeof ASSESSMENT_TYPES_LOAD_LABEL_REQUEST;
  typeId: number;
}

export interface AssessmentTypesLoadLabelSuccessAction {
  type: typeof ASSESSMENT_TYPES_LOAD_LABEL_SUCCESS;
  labels: AssessmentTypesLabelFromResponse[];
}

export type AssessmentTypesLoadLabelErrorAction = ShowErrorAction<
  typeof ASSESSMENT_TYPES_LOAD_LABEL_FAILED
>;

export interface AssessmentTypesLabelFromResponse {
  label: string;
  value: string;
}

export interface AssessmentTypesLabelState {
  label: string;
  value: string;
}

export interface AssessmentTypesLabelListingIndexedItem {
  [key: string]: AssessmentTypesLabelState;
}

export interface AssessmentTypesLabelListingState {
  labels: AssessmentTypesLabelListingIndexedItem;
  loader: Loader;
}

export enum AssessmentTypeLabel {
  REPORT_NAME = 'REPORT_NAME',
}

export interface AssessmentTypesDelegateState {
  id: number;
  label: string;
  value: string;
}

export interface AssessmentTypesLoadDelegateRequestAction {
  type: typeof ASSESSMENT_TYPES_LOAD_DELEGATE_REQUEST;
  typeId: number;
}

export interface AssessmentTypesLoadDelegateSuccessAction {
  type: typeof ASSESSMENT_TYPES_LOAD_DELEGATE_SUCCESS;
  delegates: AssessmentTypesDelegateFromResponse[];
}

export interface AssessmentTypesDelegateFromResponse {
  id: number;
  label: string;
  value: string;
}

export interface AssessmentTypesDelegateListingIndexedItem {
  [key: string]: AssessmentTypesDelegateState;
}

export type AssessmentTypesLoadDelegateErrorAction = ShowErrorAction<
  typeof ASSESSMENT_TYPES_LOAD_DELEGATE_FAILED
>;

export type AssessmentTypesActionTypes =
  | AssessmentTypesLoadAllRequestAction
  | AssessmentTypesLoadAllSuccessAction
  | AssessmentTypesLoadAllErrorAction
  | AssessmentTypesLoadLabelRequestAction
  | AssessmentTypesLoadLabelSuccessAction
  | AssessmentTypesLoadLabelErrorAction
  | AssessmentTypesLoadDelegateRequestAction
  | AssessmentTypesLoadDelegateSuccessAction
  | AssessmentTypesLoadDelegateErrorAction;
