import { Form } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { suggestFutureDate } from 'src/shared/utils/dates';
import { Button } from 'src/components/Button';
import { DatePicker } from 'src/components/DatePicker';
import actions from 'src/store/actions';
import { getTrainingPeriodLoader } from 'src/store/selectors';
import {
  BasicUser,
  TrainingPeriodConfig,
  TrainingPeriodListingElement,
} from 'src/store/types';

interface Props {
  companyId: number;
  trainingPeriod?: TrainingPeriodListingElement;
  user?: BasicUser;
}

const FormContainer = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;
    .ant-picker {
      width: 100%;
    }
  }
`;

const TrainingPeriodConfigForm = ({
  companyId,
  trainingPeriod,
  user,
}: Props) => {
  const dispatch = useDispatch();
  const loader = useSelector(getTrainingPeriodLoader);
  const [form] = Form.useForm();

  useEffect(() => {
    if (trainingPeriod) {
      const { releaseDate, dueDate } = trainingPeriod;
      const finalDueDate = dueDate
        ? moment(dueDate)
        : suggestFutureDate(releaseDate);
      form.setFieldsValue({
        releaseDate: moment(releaseDate),
        dueDate: finalDueDate,
      });
    }
  }, [trainingPeriod]);

  const handleSubmit = (values: TrainingPeriodConfig) => {
    if (trainingPeriod) {
      dispatch(
        actions.trainingPeriodUpdateRequest(trainingPeriod.id, {
          ...values,
          companyId,
        }),
      );
    } else {
      dispatch(
        actions.trainingPeriodCreateRequest({
          ...values,
          companyId,
          userId: user ? user?.id.toString() : undefined,
        }),
      );
    }
  };

  const handleOnChangeReleaseDate = (value: Moment | null) => {
    if (value) {
      const defaultDueDate = suggestFutureDate(value);

      const prevFieldsValue = form.getFieldsValue();
      form.setFieldsValue({ ...prevFieldsValue, dueDate: defaultDueDate });
    }
  };

  const isInvalidDueDateRage = (date: Moment) => {
    const today = moment();
    const releaseDate = moment(form.getFieldValue('releaseDate'));

    const inferiorLimit = releaseDate || today;
    if (date.isBefore(inferiorLimit)) {
      return true;
    }

    const superiorLimit = releaseDate.endOf('year');
    if (date.isAfter(superiorLimit)) {
      return true;
    }

    return false;
  };

  const disableInvalidDates = (currentDate: moment.Moment): boolean =>
    moment().add(-1, 'days') >= currentDate ||
    moment().year() < currentDate.year();

  const individualTrainingPeriodLabel = () => {
    if (user?.name) {
      return (
        <p>
          Set a training period for an individual who is unavailable during
          company training. Setting training period for:{' '}
          <strong>{trainingPeriod?.user?.name || user?.name}</strong>
        </p>
      );
    }
  };

  return (
    <div>
      <FormContainer
        form={form}
        onFinish={(values) => handleSubmit(values as TrainingPeriodConfig)}
      >
        {individualTrainingPeriodLabel()}
        <Form.Item
          name="releaseDate"
          rules={[{ required: true, message: 'Please select a release date' }]}
        >
          <DatePicker
            placeholder="Select the release date"
            format="MM/DD/YYYY"
            disabledDate={disableInvalidDates}
            onChange={handleOnChangeReleaseDate}
          />
        </Form.Item>
        <Form.Item
          name="dueDate"
          rules={[{ required: true, message: 'Please select a due date' }]}
        >
          <DatePicker
            placeholder="Select the due date"
            format="MM/DD/YYYY"
            disabledDate={isInvalidDueDateRage}
          />
        </Form.Item>
      </FormContainer>
      <Button
        title={user ? 'Set individual training period' : 'Save Training Period'}
        disabled={loader.loading}
        onClick={() => form.submit()}
      />
    </div>
  );
};

export default TrainingPeriodConfigForm;
