import React, { FC } from 'react';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import { SettingOutlined } from 'src/theme/icons';
import { CategoryState } from 'src/store/types';
import { confirmDialog } from 'src/shared/utils';

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  width: 180px;

  > span {
    color: ${({ theme }) => theme.colors.lightBlue.main};
    cursor: pointer;
    font-size: 20px;
  }
`;

interface CategorySettingsProps {
  category: CategoryState;
  deleteCategory: () => void;
  showUpdateCategoryPanel: () => void;
}

const settingsMenu = (
  category: CategoryState,
  deleteCategory: () => void,
  showUpdateCategoryPanel: () => void,
): JSX.Element => (
  <Menu>
    <Menu.Item key="0" onClick={showUpdateCategoryPanel}>
      <span>Change metadata</span>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item
      key="1"
      onClick={() => {
        confirmDialog({
          text: 'You are about to delete the category. Press Continue to proceed.',
          onOk: () => {
            deleteCategory();
          },
        });
      }}
      disabled={category.forms ? category.forms.length > 0 : false}
    >
      <span>Delete</span>
    </Menu.Item>
  </Menu>
);

export const CategorySettings: FC<CategorySettingsProps> = ({
  category,
  deleteCategory,
  showUpdateCategoryPanel,
}) => {
  return (
    <Dropdown
      overlay={settingsMenu(category, deleteCategory, showUpdateCategoryPanel)}
      trigger={['click']}
    >
      <DropdownIcon>
        <SettingOutlined />
      </DropdownIcon>
    </Dropdown>
  );
};
