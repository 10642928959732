import React, { FC } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { UserRole } from 'src/store/types';
import { UrlParams } from 'src/shared/enums';
import { REDIRECTION_EXCLUDED_PATHS } from 'src/shared/utils/routes';

interface ProtectedRouteProps extends RouteProps {
  children: any;
  requiredRole: UserRole;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  requiredRole = UserRole.anonymous,
  ...rest
}) => {
  const { pathname } = useLocation();
  const {
    isAdmin,
    isCompanyModerator,
    isOfficer,
    isUser,
    is2FAauthenticated,
    isTrainee,
    userLoader,
    hasTraineePendingOnboarding,
    hasOfficerPendingOnboarding,
  } = useAuth();

  const hasPermissions = (): boolean => {
    if (requiredRole === UserRole.admin) return isAdmin && is2FAauthenticated;
    if (requiredRole === UserRole.company_moderator)
      return isCompanyModerator && is2FAauthenticated;
    if (requiredRole === UserRole.officer)
      return isOfficer && is2FAauthenticated;
    if (requiredRole === UserRole.user) return isUser && is2FAauthenticated;
    if (requiredRole === UserRole.trainee)
      return isTrainee && is2FAauthenticated;
    return true;
  };

  if (!userLoader?.completed) {
    return null;
  }

  return (
    userLoader?.completed && (
      <Route
        {...rest}
        render={(props) => {
          if (is2FAauthenticated) {
            if (hasPermissions()) {
              if (hasTraineePendingOnboarding) {
                return (
                  <Redirect
                    to={{
                      pathname: '/onboarding/date',
                      state: {
                        from: props.location,
                      },
                    }}
                  />
                );
              }

              if (hasOfficerPendingOnboarding) {
                return (
                  <Redirect
                    to={{
                      pathname: '/onboarding/company_set_training_period',
                      state: {
                        from: props.location,
                      },
                    }}
                  />
                );
              }

              return children;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: '/not-found',
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          }

          let search: string | undefined = undefined;
          if (pathname && !REDIRECTION_EXCLUDED_PATHS.includes(pathname)) {
            search = `?${UrlParams.RETURN_URL}=${pathname}`;
          }
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
                search,
              }}
            />
          );
        }}
      />
    )
  );
};
