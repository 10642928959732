import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  InterviewQuestionFactory,
  QuestionFactoryType,
} from 'src/features/risk-assessment/components/InterviewQuestions';
import { SelectedFormState, Question } from 'src/store/types';
import ReactDragListView from 'react-drag-listview';
import { useDispatch } from 'react-redux';
import { formUpdateQuestions } from 'src/store/actions/form';

interface WorkbenchProps {
  form: SelectedFormState;
}

const WorkbenchContainer = styled.div`
  width: calc(100% - ${({ theme }) => `${theme.sidebar.width}px`});
  padding: 24px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 22px;
  font-weight: bold;
`;

const QuestionComponent = React.memo(
  ({ q, formId, length }: { q: Question; formId: number; length: number }) => {
    const questionFactory = InterviewQuestionFactory(
      q.type.key,
      QuestionFactoryType.workbench,
    );
    const question = React.createElement(questionFactory, {
      question: q,
      isLast: q.weight === length,
      key: q?.id || q?.internalId,
      formId: formId,
    });

    return <>{question}</>;
  },
);

export const QuestionWorkbench: FC<WorkbenchProps> = ({ form }) => {
  const questions = form.questions || [];
  const dispatch = useDispatch();

  const rearrangeQuestions = useCallback(
    (questions: Question[]) => {
      return questions
        .map((q: Question, index: number) => ({ ...q, weight: index + 1 }))
        .sort((a: Question, b: Question) => a.weight - b.weight);
    },
    [questions],
  );

  const onDragEnd = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (toIndex < 0) {
        return;
      }
      const newQuestions = [...questions];
      const question = newQuestions.splice(fromIndex, 1)[0];
      newQuestions.splice(toIndex, 0, question);
      const newRearrangedQuestions = rearrangeQuestions(newQuestions);
      dispatch(formUpdateQuestions(newRearrangedQuestions));
    },
    [questions],
  );

  const questionItems = () => {
    return questions.map((q: Question) => {
      if (q.id && form.metadata.deletedQuestions.indexOf(q.id) !== -1) {
        return null;
      }

      return (
        <QuestionComponent
          key={q.id}
          q={q}
          formId={form.form.id}
          length={questions.length}
        />
      );
    });
  };

  const renderWorkbenchQuestions = useMemo(() => {
    return (
      <WorkbenchContainer>
        <Title>{form.form.label}</Title>
        <ReactDragListView nodeSelector=".draggable" onDragEnd={onDragEnd}>
          {questionItems()}
        </ReactDragListView>
      </WorkbenchContainer>
    );
  }, [questions]);

  return renderWorkbenchQuestions;
};
