import styled from 'styled-components';
import { device } from 'src/theme/device';
import { remConvert } from 'src/theme/utils';

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media ${device.lg} {
    width: 443px;
  }

  p:first-of-type {
    margin-bottom: 22px;
  }

  p:nth-of-type(3) {
    width: 100%;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: left;
  }
`;

export const PageBottomContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${remConvert(10)};
`;

export const PageShieldImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  visibility: hidden;

  @media ${device.lg} {
    visibility: visible;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${remConvert(42)};
  margin-bottom: ${remConvert(42)};
  width: 100%;

  button {
    width: 100%;
  }

  @media ${device.lg} {
    width: ${remConvert(186)};
    button {
      width: ${remConvert(186)};
    }
  }
`;

export const PageTitle = styled.h1`
  font-family: ${({ theme }): string => theme.fontFamilies.primary};
  font-style: ${({ theme }): string => theme.fontStyles.normal};
  font-weight: ${({ theme }): number => theme.fontWeights.semiBold};
  font-size: 34px;
  text-align: center;
  letter-spacing: 0.1em;
  margin-top: ${remConvert(5)};
  margin-bottom: ${remConvert(2)};
`;

export const PageSubTitle = styled.h3`
  margin-bottom: ${remConvert(16)};
`;
