import { Loader, ShowErrorAction } from 'src/store/types';

export const TEST_TYPES_LOAD_REQUEST = 'TEST_TYPES_LOAD_REQUEST';
export const TEST_TYPES_LOAD_SUCCESS = 'TEST_TYPES_LOAD_SUCCESS';
export const TEST_TYPES_LOAD_FAILED = 'TEST_TYPES_LOAD_FAILED';

export type TestType = {
  id: number;
  name: string;
};

export type TestTypesResponse = TestType[];

export type TestTypesState = {
  testTypes: TestTypesResponse;
  loader: Loader;
};

export interface TestTypesLoadRequestAction {
  type: typeof TEST_TYPES_LOAD_REQUEST;
}

export interface TestTypesLoadSuccessAction {
  type: typeof TEST_TYPES_LOAD_SUCCESS;
  testTypes: TestTypesResponse;
}

export type TestTypesLoadErrorAction = ShowErrorAction<
  typeof TEST_TYPES_LOAD_FAILED
>;

export type TestTypeActionTypes =
  | TestTypesLoadRequestAction
  | TestTypesLoadSuccessAction
  | TestTypesLoadErrorAction;
