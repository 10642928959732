import { Loader } from 'src/store/types';
import {
  TrainingListingActionTypes,
  TrainingListingState,
  TRAINING_ACTIVATE_FAILED,
  TRAINING_ACTIVATE_REQUEST,
  TRAINING_ACTIVATE_SUCCESS,
  TRAINING_ARCHIVE_SUCCESS,
  TRAINING_DELETE_FAILED,
  TRAINING_DELETE_REQUEST,
  TRAINING_DELETE_SUCCESS,
  TRAINING_LISTING_LOAD_FAILED,
  TRAINING_LISTING_LOAD_REQUEST,
  TRAINING_LISTING_LOAD_SUCCESS,
  USER_TRAINING_LISTING_LOAD_FAILED,
  USER_TRAINING_LISTING_LOAD_REQUEST,
  USER_TRAINING_LISTING_LOAD_SUCCESS,
} from '../types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const trainingListingInitialState: TrainingListingState = {
  trainingListingResponse: {
    records: [],
    total: 0,
    page: 0,
    pageSize: 0,
  },
  loader: loaderInitialState,
};

export const trainingListingReducer = (
  state = trainingListingInitialState,
  action: TrainingListingActionTypes,
): TrainingListingState => {
  switch (action.type) {
    case TRAINING_LISTING_LOAD_REQUEST:
    case USER_TRAINING_LISTING_LOAD_REQUEST:
    case TRAINING_DELETE_REQUEST:
    case TRAINING_ACTIVATE_REQUEST: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          loading: true,
        },
      };
    }
    case TRAINING_LISTING_LOAD_SUCCESS:
    case USER_TRAINING_LISTING_LOAD_SUCCESS: {
      return {
        trainingListingResponse: action.trainingListing,
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_LISTING_LOAD_FAILED:
    case USER_TRAINING_LISTING_LOAD_FAILED: {
      return {
        ...trainingListingInitialState,
      };
    }
    case TRAINING_DELETE_SUCCESS: {
      const newRecords = state.trainingListingResponse.records.filter(
        (record) => record.id !== action.trainingId,
      );
      return {
        trainingListingResponse: {
          ...state.trainingListingResponse,
          records: newRecords,
        },
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_ACTIVATE_SUCCESS: {
      const activatedTraining = state.trainingListingResponse.records.find(
        (record) => record.id === action.trainingId,
      );
      const newRecords = state.trainingListingResponse.records.map((record) =>
        record.id === action.trainingId
          ? { ...record, status: 'ACTIVE' }
          : activatedTraining?.trainingTypeName === record.trainingTypeName &&
              activatedTraining?.testTypeName === record.testTypeName &&
              activatedTraining?.name === record.name
            ? { ...record, status: 'ARCHIVE' }
            : { ...record },
      );
      return {
        trainingListingResponse: {
          ...state.trainingListingResponse,
          records: newRecords,
        },
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_ARCHIVE_SUCCESS: {
      return {
        trainingListingResponse: {
          ...state.trainingListingResponse,
          records: state.trainingListingResponse.records.map((record) =>
            record.id === action.trainingId
              ? { ...record, status: 'ARCHIVE' }
              : record,
          ),
        },
        loader: {
          ...loaderInitialState,
          success: true,
          completed: true,
        },
      };
    }
    case TRAINING_DELETE_FAILED:
    case TRAINING_ACTIVATE_FAILED: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
        },
      };
    }
    default:
      return state;
  }
};
