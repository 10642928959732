import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import Form from 'antd/lib/form';
import { FormStatusFilter } from 'src/store/types/form';
import { Dropdown, Option } from 'src/components/Dropdown';
import { AssessmentTypeListingIndexedItem } from 'src/store/types';
import { SearchInput } from '../../../../components/SearchInput';

interface PaginationProps {
  assesmentTypes: AssessmentTypeListingIndexedItem;
  onStatusChange: (filter: FormStatusFilter) => void;
  onAssesmentChange: (filter: string) => void;
  onSearchFormChange: (filter: string) => void;
}

const FormContainer = styled(Form)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  && .ant-select-selector {
    border: none;
    min-width: 200px;
    padding-top: 5px;
    height: 40px;

    && .ant-select-selection-search-input {
      height: 40px;
    }
  }
`;

const filters = [
  FormStatusFilter.all,
  FormStatusFilter.active,
  FormStatusFilter.inactive,
];

export const FormFilters: FC<PaginationProps> = ({
  assesmentTypes,
  onStatusChange,
  onAssesmentChange,
  onSearchFormChange,
}) => {
  const statusOptions = useMemo(() => {
    return filters.reduce((arr: Option[], statusOption: FormStatusFilter) => {
      const option = statusOption.toString();
      arr.push({
        text: option,
        value: option,
      });
      return arr;
    }, []);
  }, []);

  const assesmentOptions = useMemo(() => {
    const options: Option[] = [];

    for (const type of Object.keys(assesmentTypes)) {
      const option = assesmentTypes[type].label;
      options.push({
        text: option,
        value: option,
      });
    }

    return options;
  }, [assesmentTypes]);

  return (
    <FormContainer>
      <Form layout="inline">
        <Form.Item label="Status">
          <Dropdown
            placeholder="Status"
            onChange={(value) => onStatusChange(value as FormStatusFilter)}
            options={statusOptions}
          />
        </Form.Item>
        <Form.Item label="Risk Assesment Type">
          <Dropdown
            placeholder="Risk Assesment Type"
            onChange={(value) => onAssesmentChange(value)}
            options={assesmentOptions}
          />
        </Form.Item>
        <Form.Item label="Search Forms">
          <SearchInput
            onChange={(e) => {
              onSearchFormChange(e.target.value);
            }}
            placeholder="Form name"
          />
        </Form.Item>
      </Form>
    </FormContainer>
  );
};
